export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 164.2,
    "out:Total Energy Use Post PV": 164.2,
    "out:Primary Energy": 119.716401,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 55.416162,
    "out:Total CO2/m2": 55.416162,
    "out:Total CO2 (tons)": 158434.7898,
    "out:Klimatpaverkan":0.001* 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 34023.2,
    "out:Running Cost over RSP/MSEK": 9.31167,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 2,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 100,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 0,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": 0
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 159.2,
    "out:Primary Energy": 116.586564,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 53.616242,
    "out:Total CO2/m2": 53.616242,
    "out:Total CO2 (tons)": 153288.8191,
    "out:Klimatpaverkan":0.001* -5145.970717,
    "out:Initial Cost/MSEK": 0.071474988,
    "out:Running cost/(Apt SEK y)": 32949.77143,
    "out:Running Cost over RSP/MSEK": 9.01765,
    "out:LCP/MSEK": 0.222545,
    "out:ROI% old": 58.765813,
    "out:Payback discounted": 2,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 3.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 53,
    "out:Return/kSEK/y": 38,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 12783.14468,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 11632.66,
    "out:DH kr savings": 11632.66166,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 71474.9875,
    "out:Seasonal Variation ROI (%)": 16,
    "out:Seasonal Variation Payback": 6.25
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 157.2,
    "out:Primary Energy": 117.091401,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.394544,
    "out:CO2 Operational/m2": 52.896274,
    "out:Total CO2/m2": 53.290818,
    "out:Total CO2 (tons)": 152358.432,
    "out:Klimatpaverkan":0.001* -6076.357831,
    "out:Initial Cost/MSEK": 0.136608,
    "out:Running cost/(Apt SEK y)": 32520.4,
    "out:Running Cost over RSP/MSEK": 8.90004,
    "out:LCP/MSEK": 0.275022,
    "out:ROI% old": 43.046004,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 4.5,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 39,
    "out:Return/kSEK/y": 53,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 18526.31797,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 16858.95,
    "out:DH kr savings": 16858.94935,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 136608,
    "out:Seasonal Variation ROI (%)": 12,
    "out:Seasonal Variation Payback": 8.333333
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 153.2,
    "out:Primary Energy": 113.961564,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.394544,
    "out:CO2 Operational/m2": 51.456338,
    "out:Total CO2/m2": 51.850882,
    "out:Total CO2 (tons)": 148241.6554,
    "out:Klimatpaverkan":0.001* -10193.1344,
    "out:Initial Cost/MSEK": 0.208082988,
    "out:Running cost/(Apt SEK y)": 31661.65714,
    "out:Running Cost over RSP/MSEK": 8.66483,
    "out:LCP/MSEK": 0.438757,
    "out:ROI% old": 44.408106,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 40,
    "out:Return/kSEK/y": 83,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 31309.46265,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 28491.61,
    "out:DH kr savings": 28491.61101,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 208082.9875,
    "out:Seasonal Variation ROI (%)": 14,
    "out:Seasonal Variation Payback": 7.142857
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 164.2,
    "out:Total Energy Use Post PV": 159.305876,
    "out:Primary Energy": 110.906978,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.88261,
    "out:CO2 Operational/m2": 34.404173,
    "out:Total CO2/m2": 44.286783,
    "out:Total CO2 (tons)": 126615.8987,
    "out:Klimatpaverkan":0.001* -31818.89108,
    "out:Initial Cost/MSEK": 0.475762396,
    "out:Running cost/(Apt SEK y)": 32922.02857,
    "out:Running Cost over RSP/MSEK": 9.01829,
    "out:LCP/MSEK": -0.182382,
    "out:ROI% old": 8.809319,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 3.1,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 8,
    "out:Return/kSEK/y": 39,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 38541.29,
    "out:DH kr savings": 0,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 475762.3956,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 154.305876,
    "out:Primary Energy": 107.777141,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.88261,
    "out:CO2 Operational/m2": 32.604253,
    "out:Total CO2/m2": 42.486863,
    "out:Total CO2 (tons)": 121469.928,
    "out:Klimatpaverkan":0.001* -36964.86179,
    "out:Initial Cost/MSEK": 0.547237383,
    "out:Running cost/(Apt SEK y)": 31848.6,
    "out:Running Cost over RSP/MSEK": 8.72427,
    "out:LCP/MSEK": 0.040163,
    "out:ROI% old": 15.334165,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 6.5,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 14,
    "out:Return/kSEK/y": 76,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 12783.14468,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 50173.95,
    "out:DH kr savings": 11632.66166,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 547237.3831,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 152.305876,
    "out:Primary Energy": 108.281978,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.277154,
    "out:CO2 Operational/m2": 31.884285,
    "out:Total CO2/m2": 42.161439,
    "out:Total CO2 (tons)": 120539.5409,
    "out:Klimatpaverkan":0.001* -37895.24891,
    "out:Initial Cost/MSEK": 0.612370396,
    "out:Running cost/(Apt SEK y)": 31419.22857,
    "out:Running Cost over RSP/MSEK": 8.60667,
    "out:LCP/MSEK": 0.09263,
    "out:ROI% old": 16.446629,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 7.9,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 15,
    "out:Return/kSEK/y": 91,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 18526.31797,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 55400.24,
    "out:DH kr savings": 16858.94935,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 612370.3956,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 148.305876,
    "out:Primary Energy": 105.152141,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.277154,
    "out:CO2 Operational/m2": 30.444349,
    "out:Total CO2/m2": 40.721503,
    "out:Total CO2 (tons)": 116422.7643,
    "out:Klimatpaverkan":0.001* -42012.02548,
    "out:Initial Cost/MSEK": 0.683845383,
    "out:Running cost/(Apt SEK y)": 30560.48571,
    "out:Running Cost over RSP/MSEK": 8.37145,
    "out:LCP/MSEK": 0.256375,
    "out:ROI% old": 19.641449,
    "out:Payback discounted": 6,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 10.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 18,
    "out:Return/kSEK/y": 121,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 31309.46265,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 67032.9,
    "out:DH kr savings": 28491.61101,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 683845.3831,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10
  },
  {
    "ID": "Z00000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 164.2,
    "out:Total Energy Use Post PV": 158.330546,
    "out:Primary Energy": 109.151384,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.76522,
    "out:CO2 Operational/m2": -6.231285,
    "out:Total CO2/m2": 13.533935,
    "out:Total CO2 (tons)": 38693.51593,
    "out:Klimatpaverkan":0.001* -119741.2739,
    "out:Initial Cost/MSEK": 0.951524791,
    "out:Running cost/(Apt SEK y)": 31956.88571,
    "out:Running Cost over RSP/MSEK": 8.76139,
    "out:LCP/MSEK": -0.401245,
    "out:ROI% old": 8.261627,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 3.8,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 8,
    "out:Return/kSEK/y": 72,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 72320.95,
    "out:DH kr savings": 0,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 951524.7913,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 159.2,
    "out:Total Energy Use Post PV": 153.330546,
    "out:Primary Energy": 106.021547,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.76522,
    "out:CO2 Operational/m2": -8.031205,
    "out:Total CO2/m2": 11.734015,
    "out:Total CO2 (tons)": 33547.54521,
    "out:Klimatpaverkan":0.001* -124887.2446,
    "out:Initial Cost/MSEK": 1.022999779,
    "out:Running cost/(Apt SEK y)": 30883.45714,
    "out:Running Cost over RSP/MSEK": 8.46737,
    "out:LCP/MSEK": -0.1787,
    "out:ROI% old": 11.790255,
    "out:Payback discounted": 10,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 11,
    "out:Return/kSEK/y": 110,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 12783.14468,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83953.62,
    "out:DH kr savings": 11632.66166,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1022999.779,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 157.2,
    "out:Total Energy Use Post PV": 151.330546,
    "out:Primary Energy": 106.526384,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.159764,
    "out:CO2 Operational/m2": -8.751173,
    "out:Total CO2/m2": 11.408591,
    "out:Total CO2 (tons)": 32617.1581,
    "out:Klimatpaverkan":0.001* -125817.6317,
    "out:Initial Cost/MSEK": 1.088132791,
    "out:Running cost/(Apt SEK y)": 30454.08571,
    "out:Running Cost over RSP/MSEK": 8.34976,
    "out:LCP/MSEK": -0.126223,
    "out:ROI% old": 12.628579,
    "out:Payback discounted": 10,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 8.6,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 11,
    "out:Return/kSEK/y": 125,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 18526.31797,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89179.9,
    "out:DH kr savings": 16858.94935,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1088132.791,
    "out:Seasonal Variation ROI (%)": 8,
    "out:Seasonal Variation Payback": 12.5
  },
  {
    "ID": "Z00000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 153.2,
    "out:Total Energy Use Post PV": 147.330546,
    "out:Primary Energy": 103.396547,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.159764,
    "out:CO2 Operational/m2": -10.191109,
    "out:Total CO2/m2": 9.968655,
    "out:Total CO2 (tons)": 28500.38153,
    "out:Klimatpaverkan":0.001* -129934.4083,
    "out:Initial Cost/MSEK": 1.159607779,
    "out:Running cost/(Apt SEK y)": 29595.34286,
    "out:Running Cost over RSP/MSEK": 8.11455,
    "out:LCP/MSEK": 0.037512,
    "out:ROI% old": 14.747844,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 11.6,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 13,
    "out:Return/kSEK/y": 155,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 31309.46265,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 100812.57,
    "out:DH kr savings": 28491.61101,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1159607.779,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 155.2,
    "out:Primary Energy": 116.216401,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 52.176306,
    "out:Total CO2/m2": 52.176306,
    "out:Total CO2 (tons)": 149172.0425,
    "out:Klimatpaverkan":0.001* -9262.74729,
    "out:Initial Cost/MSEK": 0.092375938,
    "out:Running cost/(Apt SEK y)": 32091.02857,
    "out:Running Cost over RSP/MSEK": 8.78243,
    "out:LCP/MSEK": 0.436864,
    "out:ROI% old": 81.84568,
    "out:Payback discounted": 2,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 5.8,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 73,
    "out:Return/kSEK/y": 68,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 24,
    "out:Payback Time": 4.166667,
    "out:DH kWh savings": 24701.7573,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 22478.6,
    "out:DH kr savings": 22478.59914,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 92375.9375,
    "out:Seasonal Variation ROI (%)": 24,
    "out:Seasonal Variation Payback": 4.166667
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 150.2,
    "out:Primary Energy": 113.086564,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 50.376386,
    "out:Total CO2/m2": 50.376386,
    "out:Total CO2 (tons)": 144026.0718,
    "out:Klimatpaverkan":0.001* -14408.71801,
    "out:Initial Cost/MSEK": 0.163850925,
    "out:Running cost/(Apt SEK y)": 31017.6,
    "out:Running Cost over RSP/MSEK": 8.48841,
    "out:LCP/MSEK": 0.659409,
    "out:ROI% old": 71.777789,
    "out:Payback discounted": 2,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 64,
    "out:Return/kSEK/y": 105,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 37484.90197,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 34111.26,
    "out:DH kr savings": 34111.26079,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 163850.925,
    "out:Seasonal Variation ROI (%)": 21,
    "out:Seasonal Variation Payback": 4.761905
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 150.2,
    "out:Primary Energy": 114.116401,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.394544,
    "out:CO2 Operational/m2": 50.376386,
    "out:Total CO2/m2": 50.77093,
    "out:Total CO2 (tons)": 145154.073,
    "out:Klimatpaverkan":0.001* -13280.71683,
    "out:Initial Cost/MSEK": 0.228983937,
    "out:Running cost/(Apt SEK y)": 31017.6,
    "out:Running Cost over RSP/MSEK": 8.48841,
    "out:LCP/MSEK": 0.594276,
    "out:ROI% old": 51.361057,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 46,
    "out:Return/kSEK/y": 105,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 39522.81167,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 35965.76,
    "out:DH kr savings": 35965.75862,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 228983.9375,
    "out:Seasonal Variation ROI (%)": 16,
    "out:Seasonal Variation Payback": 6.25
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 110.986564,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 0.394544,
    "out:CO2 Operational/m2": 48.576466,
    "out:Total CO2/m2": 48.97101,
    "out:Total CO2 (tons)": 140008.1023,
    "out:Klimatpaverkan":0.001* -18426.68755,
    "out:Initial Cost/MSEK": 0.300458925,
    "out:Running cost/(Apt SEK y)": 29944.17143,
    "out:Running Cost over RSP/MSEK": 8.19439,
    "out:LCP/MSEK": 0.816821,
    "out:ROI% old": 53.122545,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 48,
    "out:Return/kSEK/y": 143,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 52305.95635,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 47598.42,
    "out:DH kr savings": 47598.42028,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 300458.925,
    "out:Seasonal Variation ROI (%)": 16,
    "out:Seasonal Variation Payback": 6.25
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 150.305876,
    "out:Primary Energy": 107.406978,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.88261,
    "out:CO2 Operational/m2": 31.164317,
    "out:Total CO2/m2": 41.046927,
    "out:Total CO2 (tons)": 117353.1514,
    "out:Klimatpaverkan":0.001* -41081.63837,
    "out:Initial Cost/MSEK": 0.568138333,
    "out:Running cost/(Apt SEK y)": 30989.85714,
    "out:Running Cost over RSP/MSEK": 8.48906,
    "out:LCP/MSEK": 0.254472,
    "out:ROI% old": 20.684349,
    "out:Payback discounted": 6,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 9.3,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 19,
    "out:Return/kSEK/y": 106,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 24701.7573,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61019.89,
    "out:DH kr savings": 22478.59914,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 568138.3331,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 145.305876,
    "out:Primary Energy": 104.277141,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 9.88261,
    "out:CO2 Operational/m2": 29.364397,
    "out:Total CO2/m2": 39.247007,
    "out:Total CO2 (tons)": 112207.1807,
    "out:Klimatpaverkan":0.001* -46227.60908,
    "out:Initial Cost/MSEK": 0.639613321,
    "out:Running cost/(Apt SEK y)": 29916.42857,
    "out:Running Cost over RSP/MSEK": 8.19504,
    "out:LCP/MSEK": 0.477017,
    "out:ROI% old": 24.939845,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 22,
    "out:Return/kSEK/y": 144,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 37484.90197,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72652.55,
    "out:DH kr savings": 34111.26079,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 639613.3206,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 145.305876,
    "out:Primary Energy": 105.306978,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.277154,
    "out:CO2 Operational/m2": 29.364397,
    "out:Total CO2/m2": 39.641551,
    "out:Total CO2 (tons)": 113335.1819,
    "out:Klimatpaverkan":0.001* -45099.60791,
    "out:Initial Cost/MSEK": 0.704746333,
    "out:Running cost/(Apt SEK y)": 29916.42857,
    "out:Running Cost over RSP/MSEK": 8.19504,
    "out:LCP/MSEK": 0.411884,
    "out:ROI% old": 22.634892,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 20,
    "out:Return/kSEK/y": 144,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 39522.81167,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 74507.05,
    "out:DH kr savings": 35965.75862,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 704746.3331,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 140.305876,
    "out:Primary Energy": 102.177141,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.277154,
    "out:CO2 Operational/m2": 27.564477,
    "out:Total CO2/m2": 37.841631,
    "out:Total CO2 (tons)": 108189.2112,
    "out:Klimatpaverkan":0.001* -50245.57863,
    "out:Initial Cost/MSEK": 0.776221321,
    "out:Running cost/(Apt SEK y)": 28843,
    "out:Running Cost over RSP/MSEK": 7.90102,
    "out:LCP/MSEK": 0.634429,
    "out:ROI% old": 25.961852,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.1,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 23,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 52305.95635,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 86139.71,
    "out:DH kr savings": 47598.42028,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 776221.3206,
    "out:Seasonal Variation ROI (%)": 11,
    "out:Seasonal Variation Payback": 9.090909
  },
  {
    "ID": "Z00000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 155.2,
    "out:Total Energy Use Post PV": 149.330546,
    "out:Primary Energy": 105.651384,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.76522,
    "out:CO2 Operational/m2": -9.471141,
    "out:Total CO2/m2": 10.294079,
    "out:Total CO2 (tons)": 29430.76864,
    "out:Klimatpaverkan":0.001* -129004.0212,
    "out:Initial Cost/MSEK": 1.043900729,
    "out:Running cost/(Apt SEK y)": 30024.71429,
    "out:Running Cost over RSP/MSEK": 8.23215,
    "out:LCP/MSEK": 0.035619,
    "out:ROI% old": 14.773162,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 10.1,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 13,
    "out:Return/kSEK/y": 140,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 24701.7573,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 94799.55,
    "out:DH kr savings": 22478.59914,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1043900.729,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 144.330546,
    "out:Primary Energy": 102.521547,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.76522,
    "out:CO2 Operational/m2": -11.271061,
    "out:Total CO2/m2": 8.494159,
    "out:Total CO2 (tons)": 24284.79792,
    "out:Klimatpaverkan":0.001* -134149.9919,
    "out:Initial Cost/MSEK": 1.115375716,
    "out:Running cost/(Apt SEK y)": 28951.28571,
    "out:Running Cost over RSP/MSEK": 7.93814,
    "out:LCP/MSEK": 0.258154,
    "out:ROI% old": 17.59215,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 13.9,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 16,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 37484.90197,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106432.22,
    "out:DH kr savings": 34111.26079,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1115375.716,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10
  },
  {
    "ID": "Z00000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.366287,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 150.2,
    "out:Total Energy Use Post PV": 144.330546,
    "out:Primary Energy": 103.551384,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.159764,
    "out:CO2 Operational/m2": -11.271061,
    "out:Total CO2/m2": 8.888703,
    "out:Total CO2 (tons)": 25412.7991,
    "out:Klimatpaverkan":0.001* -133021.9907,
    "out:Initial Cost/MSEK": 1.180508729,
    "out:Running cost/(Apt SEK y)": 28951.28571,
    "out:Running Cost over RSP/MSEK": 7.93814,
    "out:LCP/MSEK": 0.193021,
    "out:ROI% old": 16.621527,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 13.9,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 15,
    "out:Return/kSEK/y": 178,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 39522.81167,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 108286.71,
    "out:DH kr savings": 35965.75862,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 298383.181,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 104.37,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1180508.729,
    "out:Seasonal Variation ROI (%)": 9,
    "out:Seasonal Variation Payback": 11.111111
  },
  {
    "ID": "Z00000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.895092,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.330546,
    "out:Primary Energy": 100.421547,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.159764,
    "out:CO2 Operational/m2": -13.070981,
    "out:Total CO2/m2": 7.088783,
    "out:Total CO2 (tons)": 20266.82838,
    "out:Klimatpaverkan":0.001* -138167.9614,
    "out:Initial Cost/MSEK": 1.252,
    "out:Running cost/(Apt SEK y)": 27877.85714,
    "out:Running Cost over RSP/MSEK": 7.64412,
    "out:LCP/MSEK": 0.41555,
    "out:ROI% old": 19.027271,
    "out:Payback discounted": 6,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 17,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 52305.95635,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119919.38,
    "out:DH kr savings": 47598.42028,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 285600.0363,
    "out:% savings (space heating)": 4.284137,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 99.9,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1252000,
    "out:Seasonal Variation ROI (%)": 10,
    "out:Seasonal Variation Payback": 10
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.865243,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.348301,
    "out:Electricity (inc PV)": 40.125135,
    "out:Total Energy Use Pre PV": 111.348301,
    "out:Total Energy Use Post PV": 109.125135,
    "out:Primary Energy": 112.826426,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.078639,
    "out:CO2 Operational/m2": 30.429211,
    "out:Total CO2/m2": 34.50785,
    "out:Total CO2 (tons)": 98657.93235,
    "out:Klimatpaverkan":0.001* -59776.85746,
    "out:Initial Cost/MSEK": 1.647875,
    "out:Running cost/(Apt SEK y)": 20694.08571,
    "out:Running Cost over RSP/MSEK": 5.64866,
    "out:LCP/MSEK": 2.015135,
    "out:ROI% old": 31.755269,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 50.5,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2964.38107,
    "out:Return %": 28,
    "out:Return/kSEK/y": 467,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.848801,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 225006.2593,
    "out:EL kWh savings": -68401.95348,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88472.38,
    "out:DH kr savings": 204755.696,
    "out:El kr savings": -116283.3209,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 196885.7082,
    "out:% savings (space heating)": 34.015816,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 58,
    "out:Etvv": 0,
    "out:Ef": 40.13,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1647875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.539929,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.304095,
    "out:Electricity (inc PV)": 40.05078,
    "out:Total Energy Use Pre PV": 107.304095,
    "out:Total Energy Use Post PV": 105.05078,
    "out:Primary Energy": 109.734511,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.078639,
    "out:CO2 Operational/m2": 28.983439,
    "out:Total CO2/m2": 33.062078,
    "out:Total CO2 (tons)": 94524.47065,
    "out:Klimatpaverkan":0.001* -63910.31916,
    "out:Initial Cost/MSEK": 1.719375,
    "out:Running cost/(Apt SEK y)": 19829.2,
    "out:Running Cost over RSP/MSEK": 5.41178,
    "out:LCP/MSEK": 2.180515,
    "out:ROI% old": 32.402887,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 56.2,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2961.28665,
    "out:Return %": 29,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.804595,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 237372.3307,
    "out:EL kWh savings": -68189.37256,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 100086.89,
    "out:DH kr savings": 216008.8209,
    "out:El kr savings": -115921.9333,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 184519.6368,
    "out:% savings (space heating)": 38.160175,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.78,
    "out:Etvv": 0,
    "out:Ef": 40.05,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1719375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.914217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.581945,
    "out:Electricity (inc PV)": 39.640038,
    "out:Total Energy Use Pre PV": 106.581945,
    "out:Total Energy Use Post PV": 104.640038,
    "out:Primary Energy": 110.448826,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.473183,
    "out:CO2 Operational/m2": 28.888109,
    "out:Total CO2/m2": 33.361292,
    "out:Total CO2 (tons)": 95379.92339,
    "out:Klimatpaverkan":0.001* -63054.86643,
    "out:Initial Cost/MSEK": 1.7845,
    "out:Running cost/(Apt SEK y)": 19728.91429,
    "out:Running Cost over RSP/MSEK": 5.38465,
    "out:LCP/MSEK": 2.14252,
    "out:ROI% old": 31.437538,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 56.2,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2910.73615,
    "out:Return %": 28,
    "out:Return/kSEK/y": 500,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.082445,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 236302.2414,
    "out:EL kWh savings": -67015.06131,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101109.44,
    "out:DH kr savings": 215035.0397,
    "out:El kr savings": -113925.6042,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 185589.7261,
    "out:% savings (space heating)": 37.801546,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 55.85,
    "out:Etvv": 0,
    "out:Ef": 39.64,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1784500,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.621153,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.527966,
    "out:Electricity (inc PV)": 39.559184,
    "out:Total Energy Use Pre PV": 102.527966,
    "out:Total Energy Use Post PV": 100.559184,
    "out:Primary Energy": 107.360225,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.473183,
    "out:CO2 Operational/m2": 27.441048,
    "out:Total CO2/m2": 31.914231,
    "out:Total CO2 (tons)": 91242.77644,
    "out:Klimatpaverkan":0.001* -67192.01337,
    "out:Initial Cost/MSEK": 1.856,
    "out:Running cost/(Apt SEK y)": 18862.68571,
    "out:Running Cost over RSP/MSEK": 5.14741,
    "out:LCP/MSEK": 2.30826,
    "out:ROI% old": 32.052494,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2906.95762,
    "out:Return %": 29,
    "out:Return/kSEK/y": 531,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.028466,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 248576.11,
    "out:EL kWh savings": -66783.89975,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112671.63,
    "out:DH kr savings": 226204.2601,
    "out:El kr savings": -113532.6296,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 173315.8575,
    "out:% savings (space heating)": 41.915004,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 51.65,
    "out:Etvv": 0,
    "out:Ef": 39.56,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1856000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.865243,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.348301,
    "out:Electricity (inc PV)": 32.021565,
    "out:Total Energy Use Pre PV": 111.348301,
    "out:Total Energy Use Post PV": 101.021565,
    "out:Primary Energy": 98.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.961249,
    "out:CO2 Operational/m2": 26.106182,
    "out:Total CO2/m2": 40.067431,
    "out:Total CO2 (tons)": 114552.7727,
    "out:Klimatpaverkan":0.001* -43882.01713,
    "out:Initial Cost/MSEK": 2.123625,
    "out:Running cost/(Apt SEK y)": 19476.02857,
    "out:Running Cost over RSP/MSEK": 5.32429,
    "out:LCP/MSEK": 1.863755,
    "out:ROI% old": 26.823272,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2412.409042,
    "out:Return %": 24,
    "out:Return/kSEK/y": 509,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.848801,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 1947.510176,
    "out:PV (% sold (El))": 7.618066,
    "out:PV (kWh self-consumed)": 23616.85444,
    "out:PV (ratio sold/self-consumed)": 0.082463,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 225006.2593,
    "out:EL kWh savings": -45233.84938,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130341.23,
    "out:DH kr savings": 204755.696,
    "out:El kr savings": -76897.54395,
    "out:El kr sold": 2483.075475,
    "out:El kr saved": 40148.65254,
    "out:El kr return": 42631.72802,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 196885.7082,
    "out:% savings (space heating)": 34.015816,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 58,
    "out:Etvv": 0,
    "out:Ef": 32.02,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2123625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.539929,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.304095,
    "out:Electricity (inc PV)": 31.952766,
    "out:Total Energy Use Pre PV": 107.304095,
    "out:Total Energy Use Post PV": 96.952766,
    "out:Primary Energy": 95.158086,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.961249,
    "out:CO2 Operational/m2": 24.646202,
    "out:Total CO2/m2": 38.607451,
    "out:Total CO2 (tons)": 110378.6903,
    "out:Klimatpaverkan":0.001* -48056.09949,
    "out:Initial Cost/MSEK": 2.195125,
    "out:Running cost/(Apt SEK y)": 18611.25714,
    "out:Running Cost over RSP/MSEK": 5.08744,
    "out:LCP/MSEK": 2.029105,
    "out:ROI% old": 27.490982,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 69.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2409.485915,
    "out:Return %": 25,
    "out:Return/kSEK/y": 539,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.804595,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 1955.870426,
    "out:PV (% sold (El))": 7.650769,
    "out:PV (kWh self-consumed)": 23608.49419,
    "out:PV (ratio sold/self-consumed)": 0.082846,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 237372.3307,
    "out:EL kWh savings": -45037.15306,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141939.4,
    "out:DH kr savings": 216008.8209,
    "out:El kr savings": -76563.16021,
    "out:El kr sold": 2493.734793,
    "out:El kr saved": 40134.44012,
    "out:El kr return": 42628.17491,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 184519.6368,
    "out:% savings (space heating)": 38.160175,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.78,
    "out:Etvv": 0,
    "out:Ef": 31.95,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2195125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.914217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.581945,
    "out:Electricity (inc PV)": 31.573106,
    "out:Total Energy Use Pre PV": 106.581945,
    "out:Total Energy Use Post PV": 96.573106,
    "out:Primary Energy": 95.928349,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.355793,
    "out:CO2 Operational/m2": 24.312883,
    "out:Total CO2/m2": 38.668676,
    "out:Total CO2 (tons)": 110553.7326,
    "out:Klimatpaverkan":0.001* -47881.05723,
    "out:Initial Cost/MSEK": 2.26025,
    "out:Running cost/(Apt SEK y)": 18512.68571,
    "out:Running Cost over RSP/MSEK": 5.06075,
    "out:LCP/MSEK": 1.99067,
    "out:ROI% old": 26.867572,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 69.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2361.807511,
    "out:Return %": 24,
    "out:Return/kSEK/y": 543,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.082445,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2096.366906,
    "out:PV (% sold (El))": 8.200348,
    "out:PV (kWh self-consumed)": 23467.99771,
    "out:PV (ratio sold/self-consumed)": 0.089329,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 236302.2414,
    "out:EL kWh savings": -43951.70524,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 142990.01,
    "out:DH kr savings": 215035.0397,
    "out:El kr savings": -74717.89891,
    "out:El kr sold": 2672.867805,
    "out:El kr saved": 39895.59611,
    "out:El kr return": 42568.46391,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 185589.7261,
    "out:% savings (space heating)": 37.801546,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 55.85,
    "out:Etvv": 0,
    "out:Ef": 31.57,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2260250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.621153,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.527966,
    "out:Electricity (inc PV)": 31.498418,
    "out:Total Energy Use Pre PV": 102.527966,
    "out:Total Energy Use Post PV": 92.498418,
    "out:Primary Energy": 92.850846,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.355793,
    "out:CO2 Operational/m2": 22.847599,
    "out:Total CO2/m2": 37.203392,
    "out:Total CO2 (tons)": 106364.4861,
    "out:Klimatpaverkan":0.001* -52070.30373,
    "out:Initial Cost/MSEK": 2.33175,
    "out:Running cost/(Apt SEK y)": 17646.57143,
    "out:Running Cost over RSP/MSEK": 4.82354,
    "out:LCP/MSEK": 2.15638,
    "out:ROI% old": 27.497006,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 78.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2358.249263,
    "out:Return %": 25,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.028466,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2107.145239,
    "out:PV (% sold (El))": 8.24251,
    "out:PV (kWh self-consumed)": 23457.21938,
    "out:PV (ratio sold/self-consumed)": 0.089829,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 248576.11,
    "out:EL kWh savings": -43738.17227,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154535.98,
    "out:DH kr savings": 226204.2601,
    "out:El kr savings": -74354.89287,
    "out:El kr sold": 2686.61018,
    "out:El kr saved": 39877.27294,
    "out:El kr return": 42563.88312,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 173315.8575,
    "out:% savings (space heating)": 41.915004,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 51.65,
    "out:Etvv": 0,
    "out:Ef": 31.5,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2331750,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.865243,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.348301,
    "out:Electricity (inc PV)": 28.877323,
    "out:Total Energy Use Pre PV": 111.348301,
    "out:Total Energy Use Post PV": 97.877323,
    "out:Primary Energy": 92.580364,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.843859,
    "out:CO2 Operational/m2": -2.377199,
    "out:Total CO2/m2": 21.46666,
    "out:Total CO2 (tons)": 61373.17422,
    "out:Klimatpaverkan":0.001* -97061.61559,
    "out:Initial Cost/MSEK": 2.599375,
    "out:Running cost/(Apt SEK y)": 18427.71429,
    "out:Running Cost over RSP/MSEK": 5.04479,
    "out:LCP/MSEK": 1.667505,
    "out:ROI% old": 23.450033,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 2171.400077,
    "out:Return %": 21,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.848801,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 17872.27708,
    "out:PV (% sold (El))": 34.955449,
    "out:PV (kWh self-consumed)": 33256.45233,
    "out:PV (ratio sold/self-consumed)": 0.537408,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 225006.2593,
    "out:EL kWh savings": -36244.46249,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165927.26,
    "out:DH kr savings": 204755.696,
    "out:El kr savings": -61615.58623,
    "out:El kr sold": 22787.15328,
    "out:El kr saved": 56535.96896,
    "out:El kr return": 79323.12223,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 196885.7082,
    "out:% savings (space heating)": 34.015816,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 58,
    "out:Etvv": 0,
    "out:Ef": 28.88,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2599375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.539929,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.304095,
    "out:Electricity (inc PV)": 28.816074,
    "out:Total Energy Use Pre PV": 107.304095,
    "out:Total Energy Use Post PV": 93.816074,
    "out:Primary Energy": 89.51204,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.843859,
    "out:CO2 Operational/m2": -3.860434,
    "out:Total CO2/m2": 19.983425,
    "out:Total CO2 (tons)": 57132.60582,
    "out:Klimatpaverkan":0.001* -101302.184,
    "out:Initial Cost/MSEK": 2.670875,
    "out:Running cost/(Apt SEK y)": 17563.08571,
    "out:Running Cost over RSP/MSEK": 4.80798,
    "out:LCP/MSEK": 1.832815,
    "out:ROI% old": 24.088895,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 74.5,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2168.808628,
    "out:Return %": 22,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.804595,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 17893.70046,
    "out:PV (% sold (El))": 34.99735,
    "out:PV (kWh self-consumed)": 33235.02894,
    "out:PV (ratio sold/self-consumed)": 0.538399,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 237372.3307,
    "out:EL kWh savings": -36069.35162,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177505.39,
    "out:DH kr savings": 216008.8209,
    "out:El kr savings": -61317.89775,
    "out:El kr sold": 22814.46809,
    "out:El kr saved": 56499.5492,
    "out:El kr return": 79314.0173,
    "out:% solar/total": 72,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 184519.6368,
    "out:% savings (space heating)": 38.160175,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.78,
    "out:Etvv": 0,
    "out:Ef": 28.82,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2670875,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.914217,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.581945,
    "out:Electricity (inc PV)": 28.477927,
    "out:Total Energy Use Pre PV": 106.581945,
    "out:Total Energy Use Post PV": 93.477927,
    "out:Primary Energy": 90.357027,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.238403,
    "out:CO2 Operational/m2": -4.571677,
    "out:Total CO2/m2": 19.666726,
    "out:Total CO2 (tons)": 56227.16348,
    "out:Klimatpaverkan":0.001* -102207.6263,
    "out:Initial Cost/MSEK": 2.736,
    "out:Running cost/(Apt SEK y)": 17467.08571,
    "out:Running Cost over RSP/MSEK": 4.78199,
    "out:LCP/MSEK": 1.79368,
    "out:ROI% old": 23.651211,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 76.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 2126.526384,
    "out:Return %": 21,
    "out:Return/kSEK/y": 579,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.082445,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18246.15443,
    "out:PV (% sold (El))": 35.686696,
    "out:PV (kWh self-consumed)": 32882.57497,
    "out:PV (ratio sold/self-consumed)": 0.554888,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 236302.2414,
    "out:EL kWh savings": -35102.58945,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178624.48,
    "out:DH kr savings": 215035.0397,
    "out:El kr savings": -59674.40207,
    "out:El kr sold": 23263.8469,
    "out:El kr saved": 55900.37745,
    "out:El kr return": 79164.22436,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 185589.7261,
    "out:% savings (space heating)": 37.801546,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 55.85,
    "out:Etvv": 0,
    "out:Ef": 28.48,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2736000,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.621153,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.527966,
    "out:Electricity (inc PV)": 28.411435,
    "out:Total Energy Use Pre PV": 102.527966,
    "out:Total Energy Use Post PV": 89.411435,
    "out:Primary Energy": 87.294277,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.238403,
    "out:CO2 Operational/m2": -6.065096,
    "out:Total CO2/m2": 18.173307,
    "out:Total CO2 (tons)": 51957.47903,
    "out:Klimatpaverkan":0.001* -106477.3108,
    "out:Initial Cost/MSEK": 2.8075,
    "out:Running cost/(Apt SEK y)": 16601.17143,
    "out:Running Cost over RSP/MSEK": 4.54484,
    "out:LCP/MSEK": 1.95933,
    "out:ROI% old": 24.255591,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2123.369853,
    "out:Return %": 22,
    "out:Return/kSEK/y": 610,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 23.028466,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18272.66557,
    "out:PV (% sold (El))": 35.738548,
    "out:PV (kWh self-consumed)": 32856.06384,
    "out:PV (ratio sold/self-consumed)": 0.556143,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 248576.11,
    "out:EL kWh savings": -34912.48884,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 190150.68,
    "out:DH kr savings": 226204.2601,
    "out:El kr savings": -59351.23103,
    "out:El kr sold": 23297.6486,
    "out:El kr saved": 55855.30853,
    "out:El kr return": 79152.95713,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 173315.8575,
    "out:% savings (space heating)": 41.915004,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 51.65,
    "out:Etvv": 0,
    "out:Ef": 28.41,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2807500,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.617244,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.320422,
    "out:Electricity (inc PV)": 39.476524,
    "out:Total Energy Use Pre PV": 105.320422,
    "out:Total Energy Use Post PV": 103.476524,
    "out:Primary Energy": 109.657219,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.078639,
    "out:CO2 Operational/m2": 28.493602,
    "out:Total CO2/m2": 32.572241,
    "out:Total CO2 (tons)": 93124.02682,
    "out:Klimatpaverkan":0.001* -65310.76299,
    "out:Initial Cost/MSEK": 1.74025,
    "out:Running cost/(Apt SEK y)": 19477.91429,
    "out:Running Cost over RSP/MSEK": 5.31602,
    "out:LCP/MSEK": 2.2554,
    "out:ROI% old": 32.800296,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 59.2,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2892.42954,
    "out:Return %": 29,
    "out:Return/kSEK/y": 509,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.820922,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 240010.2868,
    "out:EL kWh savings": -66547.57483,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105278.48,
    "out:DH kr savings": 218409.361,
    "out:El kr savings": -113130.8772,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 181881.6807,
    "out:% savings (space heating)": 39.044258,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 55.14,
    "out:Etvv": 0,
    "out:Ef": 39.48,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1740250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.335856,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.262905,
    "out:Electricity (inc PV)": 39.393534,
    "out:Total Energy Use Pre PV": 100.262905,
    "out:Total Energy Use Post PV": 98.393534,
    "out:Primary Energy": 106.569706,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.078639,
    "out:CO2 Operational/m2": 26.68609,
    "out:Total CO2/m2": 30.764729,
    "out:Total CO2 (tons)": 87956.35058,
    "out:Klimatpaverkan":0.001* -70478.43923,
    "out:Initial Cost/MSEK": 1.81175,
    "out:Running cost/(Apt SEK y)": 18396.48571,
    "out:Running Cost over RSP/MSEK": 5.01984,
    "out:LCP/MSEK": 2.48008,
    "out:ROI% old": 33.841235,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2888.40335,
    "out:Return %": 30,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.763405,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 252250.7738,
    "out:EL kWh savings": -66310.30645,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 116820.68,
    "out:DH kr savings": 229548.2041,
    "out:El kr savings": -112727.521,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 169641.1937,
    "out:% savings (space heating)": 43.14653,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.94,
    "out:Etvv": 0,
    "out:Ef": 39.39,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1811750,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.548158,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.679539,
    "out:Electricity (inc PV)": 39.080565,
    "out:Total Energy Use Pre PV": 101.679539,
    "out:Total Energy Use Post PV": 100.080565,
    "out:Primary Energy": 107.759157,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.473183,
    "out:CO2 Operational/m2": 27.329049,
    "out:Total CO2/m2": 31.802232,
    "out:Total CO2 (tons)": 90922.57133,
    "out:Klimatpaverkan":0.001* -67512.21848,
    "out:Initial Cost/MSEK": 1.876875,
    "out:Running cost/(Apt SEK y)": 18744.85714,
    "out:Running Cost over RSP/MSEK": 5.11553,
    "out:LCP/MSEK": 2.319265,
    "out:ROI% old": 31.938652,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2847.56773,
    "out:Return %": 28,
    "out:Return/kSEK/y": 535,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.180039,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 248784.8027,
    "out:EL kWh savings": -65415.52817,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 115187.77,
    "out:DH kr savings": 226394.1705,
    "out:El kr savings": -111206.3979,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 173107.1648,
    "out:% savings (space heating)": 41.984946,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.45,
    "out:Etvv": 0,
    "out:Ef": 39.08,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1876875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.295678,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.613262,
    "out:Electricity (inc PV)": 38.991196,
    "out:Total Energy Use Pre PV": 96.613262,
    "out:Total Energy Use Post PV": 94.991196,
    "out:Primary Energy": 104.6749,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.473183,
    "out:CO2 Operational/m2": 25.520379,
    "out:Total CO2/m2": 29.993562,
    "out:Total CO2 (tons)": 85751.58437,
    "out:Klimatpaverkan":0.001* -72683.20544,
    "out:Initial Cost/MSEK": 1.948375,
    "out:Running cost/(Apt SEK y)": 17662.22857,
    "out:Running Cost over RSP/MSEK": 4.81902,
    "out:LCP/MSEK": 2.544275,
    "out:ROI% old": 32.940637,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 72.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2842.92834,
    "out:Return %": 29,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.113762,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 260942.6417,
    "out:EL kWh savings": -65160.02223,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126685.77,
    "out:DH kr savings": 237457.804,
    "out:El kr savings": -110772.0378,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 160949.3258,
    "out:% savings (space heating)": 46.059518,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 49.27,
    "out:Etvv": 0,
    "out:Ef": 38.99,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1948375,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.617244,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.320422,
    "out:Electricity (inc PV)": 31.422089,
    "out:Total Energy Use Pre PV": 105.320422,
    "out:Total Energy Use Post PV": 95.422089,
    "out:Primary Energy": 95.159236,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.961249,
    "out:CO2 Operational/m2": 23.829556,
    "out:Total CO2/m2": 37.790805,
    "out:Total CO2 (tons)": 108043.8997,
    "out:Klimatpaverkan":0.001* -50390.89015,
    "out:Initial Cost/MSEK": 2.216,
    "out:Running cost/(Apt SEK y)": 18262.31429,
    "out:Running Cost over RSP/MSEK": 4.99228,
    "out:LCP/MSEK": 2.10339,
    "out:ROI% old": 27.845474,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 72.6,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2344.575565,
    "out:Return %": 25,
    "out:Return/kSEK/y": 552,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.820922,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2148.751827,
    "out:PV (% sold (El))": 8.405262,
    "out:PV (kWh self-consumed)": 23415.61279,
    "out:PV (ratio sold/self-consumed)": 0.091766,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 240010.2868,
    "out:EL kWh savings": -43519.94769,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147165.11,
    "out:DH kr savings": 218409.361,
    "out:El kr savings": -73983.91107,
    "out:El kr sold": 2739.65858,
    "out:El kr saved": 39806.54174,
    "out:El kr return": 42546.20032,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 181881.6807,
    "out:% savings (space heating)": 39.044258,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 55.14,
    "out:Etvv": 0,
    "out:Ef": 31.42,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2216000,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.335856,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.262905,
    "out:Electricity (inc PV)": 31.345496,
    "out:Total Energy Use Pre PV": 100.262905,
    "out:Total Energy Use Post PV": 90.345496,
    "out:Primary Energy": 92.083238,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.961249,
    "out:CO2 Operational/m2": 22.002331,
    "out:Total CO2/m2": 35.96358,
    "out:Total CO2 (tons)": 102819.864,
    "out:Klimatpaverkan":0.001* -55614.92585,
    "out:Initial Cost/MSEK": 2.2875,
    "out:Running cost/(Apt SEK y)": 17181.02857,
    "out:Running Cost over RSP/MSEK": 4.69614,
    "out:LCP/MSEK": 2.32803,
    "out:ROI% old": 28.824543,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 82.2,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2340.788183,
    "out:Return %": 26,
    "out:Return/kSEK/y": 589,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.763405,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2160.297164,
    "out:PV (% sold (El))": 8.450424,
    "out:PV (kWh self-consumed)": 23404.06745,
    "out:PV (ratio sold/self-consumed)": 0.092304,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 252250.7738,
    "out:EL kWh savings": -43300.96832,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158690.94,
    "out:DH kr savings": 229548.2041,
    "out:El kr savings": -73611.64615,
    "out:El kr sold": 2754.378884,
    "out:El kr saved": 39786.91467,
    "out:El kr return": 42541.29355,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 169641.1937,
    "out:% savings (space heating)": 43.14653,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.94,
    "out:Etvv": 0,
    "out:Ef": 31.35,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2287500,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.548158,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.679539,
    "out:Electricity (inc PV)": 31.057096,
    "out:Total Energy Use Pre PV": 101.679539,
    "out:Total Energy Use Post PV": 92.057096,
    "out:Primary Energy": 93.316913,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.355793,
    "out:CO2 Operational/m2": 22.441768,
    "out:Total CO2/m2": 36.797561,
    "out:Total CO2 (tons)": 105204.2154,
    "out:Klimatpaverkan":0.001* -53230.57443,
    "out:Initial Cost/MSEK": 2.352625,
    "out:Running cost/(Apt SEK y)": 17530.85714,
    "out:Running Cost over RSP/MSEK": 4.7922,
    "out:LCP/MSEK": 2.166845,
    "out:ROI% old": 27.443327,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 78.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2302.425978,
    "out:Return %": 25,
    "out:Return/kSEK/y": 577,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.180039,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2279.556822,
    "out:PV (% sold (El))": 8.916931,
    "out:PV (kWh self-consumed)": 23284.80779,
    "out:PV (ratio sold/self-consumed)": 0.097899,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 248784.8027,
    "out:EL kWh savings": -42476.43281,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157090.67,
    "out:DH kr savings": 226394.1705,
    "out:El kr savings": -72209.93578,
    "out:El kr sold": 2906.434948,
    "out:El kr saved": 39584.17325,
    "out:El kr return": 42490.60819,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 173107.1648,
    "out:% savings (space heating)": 41.984946,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.45,
    "out:Etvv": 0,
    "out:Ef": 31.06,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2352625,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.295678,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.613262,
    "out:Electricity (inc PV)": 30.974838,
    "out:Total Energy Use Pre PV": 96.613262,
    "out:Total Energy Use Post PV": 86.974838,
    "out:Primary Energy": 90.245455,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.355793,
    "out:CO2 Operational/m2": 20.60955,
    "out:Total CO2/m2": 34.965343,
    "out:Total CO2 (tons)": 99965.90469,
    "out:Klimatpaverkan":0.001* -58468.88512,
    "out:Initial Cost/MSEK": 2.424125,
    "out:Running cost/(Apt SEK y)": 16448.37143,
    "out:Running Cost over RSP/MSEK": 4.49574,
    "out:LCP/MSEK": 2.391805,
    "out:ROI% old": 28.380962,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 88.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2298.073556,
    "out:Return %": 25,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.113762,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2293.33565,
    "out:PV (% sold (El))": 8.97083,
    "out:PV (kWh self-consumed)": 23271.02897,
    "out:PV (ratio sold/self-consumed)": 0.098549,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 260942.6417,
    "out:EL kWh savings": -42241.25722,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168571.67,
    "out:DH kr savings": 237457.804,
    "out:El kr savings": -71810.13727,
    "out:El kr sold": 2924.002953,
    "out:El kr saved": 39560.74924,
    "out:El kr return": 42484.75219,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 160949.3258,
    "out:% savings (space heating)": 46.059518,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 49.27,
    "out:Etvv": 0,
    "out:Ef": 30.97,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2424125,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.617244,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.320422,
    "out:Electricity (inc PV)": 28.343465,
    "out:Total Energy Use Pre PV": 105.320422,
    "out:Total Energy Use Post PV": 92.343465,
    "out:Primary Energy": 89.617713,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.843859,
    "out:CO2 Operational/m2": -5.191125,
    "out:Total CO2/m2": 18.652734,
    "out:Total CO2 (tons)": 53328.16067,
    "out:Klimatpaverkan":0.001* -105106.6291,
    "out:Initial Cost/MSEK": 2.69175,
    "out:Running cost/(Apt SEK y)": 17217.65714,
    "out:Running Cost over RSP/MSEK": 4.71378,
    "out:LCP/MSEK": 1.90614,
    "out:ROI% old": 24.402022,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 78.3,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 2111.238047,
    "out:Return %": 22,
    "out:Return/kSEK/y": 588,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.820922,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18374.81999,
    "out:PV (% sold (El))": 35.938347,
    "out:PV (kWh self-consumed)": 32753.90942,
    "out:PV (ratio sold/self-consumed)": 0.560996,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 240010.2868,
    "out:EL kWh savings": -34718.16263,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 182816.38,
    "out:DH kr savings": 218409.361,
    "out:El kr savings": -59020.87648,
    "out:El kr sold": 23427.89548,
    "out:El kr saved": 55681.64601,
    "out:El kr return": 79109.5415,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 181881.6807,
    "out:% savings (space heating)": 39.044258,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 55.14,
    "out:Etvv": 0,
    "out:Ef": 28.34,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2691750,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 59.335856,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.262905,
    "out:Electricity (inc PV)": 28.275227,
    "out:Total Energy Use Pre PV": 100.262905,
    "out:Total Energy Use Post PV": 87.275227,
    "out:Primary Energy": 86.556754,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.843859,
    "out:CO2 Operational/m2": -7.048227,
    "out:Total CO2/m2": 16.795632,
    "out:Total CO2 (tons)": 48018.70663,
    "out:Klimatpaverkan":0.001* -110416.0832,
    "out:Initial Cost/MSEK": 2.76325,
    "out:Running cost/(Apt SEK y)": 16136.57143,
    "out:Running Cost over RSP/MSEK": 4.41769,
    "out:LCP/MSEK": 2.13073,
    "out:ROI% old": 25.301366,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 88.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 2107.87725,
    "out:Return %": 23,
    "out:Return/kSEK/y": 626,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.763405,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18403.1842,
    "out:PV (% sold (El))": 35.993823,
    "out:PV (kWh self-consumed)": 32725.54521,
    "out:PV (ratio sold/self-consumed)": 0.562349,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 252250.7738,
    "out:EL kWh savings": -34523.07021,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194323.04,
    "out:DH kr savings": 229548.2041,
    "out:El kr savings": -58689.21936,
    "out:El kr sold": 23464.05985,
    "out:El kr saved": 55633.42686,
    "out:El kr return": 79097.48671,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 169641.1937,
    "out:% savings (space heating)": 43.14653,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.94,
    "out:Etvv": 0,
    "out:Ef": 28.28,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2763250,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.548158,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.679539,
    "out:Electricity (inc PV)": 28.018078,
    "out:Total Energy Use Pre PV": 101.679539,
    "out:Total Energy Use Post PV": 89.018078,
    "out:Primary Energy": 87.84668,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.238403,
    "out:CO2 Operational/m2": -6.910994,
    "out:Total CO2/m2": 17.327409,
    "out:Total CO2 (tons)": 49539.05691,
    "out:Klimatpaverkan":0.001* -108895.7329,
    "out:Initial Cost/MSEK": 2.828375,
    "out:Running cost/(Apt SEK y)": 16488.45714,
    "out:Running Cost over RSP/MSEK": 4.51432,
    "out:LCP/MSEK": 1.968975,
    "out:ROI% old": 24.230723,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 2073.824383,
    "out:Return %": 22,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.180039,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18692.97507,
    "out:PV (% sold (El))": 36.560609,
    "out:PV (kWh self-consumed)": 32435.75434,
    "out:PV (ratio sold/self-consumed)": 0.576308,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 248784.8027,
    "out:EL kWh savings": -33787.8813,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192788.32,
    "out:DH kr savings": 226394.1705,
    "out:El kr savings": -57439.39822,
    "out:El kr sold": 23833.54321,
    "out:El kr saved": 55140.78238,
    "out:El kr return": 78974.32559,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 173107.1648,
    "out:% savings (space heating)": 41.984946,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.45,
    "out:Etvv": 0,
    "out:Ef": 28.02,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2828375,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.295678,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.613262,
    "out:Electricity (inc PV)": 27.944744,
    "out:Total Energy Use Pre PV": 96.613262,
    "out:Total Energy Use Post PV": 83.944744,
    "out:Primary Energy": 84.791286,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.238403,
    "out:CO2 Operational/m2": -8.777455,
    "out:Total CO2/m2": 15.460948,
    "out:Total CO2 (tons)": 44202.84549,
    "out:Klimatpaverkan":0.001* -114231.9443,
    "out:Initial Cost/MSEK": 2.899875,
    "out:Running cost/(Apt SEK y)": 15406.22857,
    "out:Running Cost over RSP/MSEK": 4.21792,
    "out:LCP/MSEK": 2.193875,
    "out:ROI% old": 25.093446,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 95.2,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 2069.959521,
    "out:Return %": 22,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 22.113762,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18726.11561,
    "out:PV (% sold (El))": 36.625427,
    "out:PV (kWh self-consumed)": 32402.6138,
    "out:PV (ratio sold/self-consumed)": 0.57792,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 260942.6417,
    "out:EL kWh savings": -33578.21942,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204250.63,
    "out:DH kr savings": 237457.804,
    "out:El kr savings": -57082.97301,
    "out:El kr sold": 23875.7974,
    "out:El kr saved": 55084.44346,
    "out:El kr return": 78960.24086,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 160949.3258,
    "out:% savings (space heating)": 46.059518,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 49.27,
    "out:Etvv": 0,
    "out:Ef": 27.94,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2899875,
    "out:Seasonal Variation ROI (%)": 7,
    "out:Seasonal Variation Payback": 14.285714
  },
  {
    "ID": "Z00000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.245299,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.475557,
    "out:Electricity (inc PV)": 52.887128,
    "out:Total Energy Use Pre PV": 78.475557,
    "out:Total Energy Use Post PV": 74.887128,
    "out:Primary Energy": 106.31781,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.583256,
    "out:CO2 Operational/m2": 15.374873,
    "out:Total CO2/m2": 19.958129,
    "out:Total CO2 (tons)": 57060.28456,
    "out:Klimatpaverkan":0.001* -101374.5052,
    "out:Initial Cost/MSEK": 2.973875,
    "out:Running cost/(Apt SEK y)": 12565.62857,
    "out:Running Cost over RSP/MSEK": 3.4157,
    "out:LCP/MSEK": 2.922095,
    "out:ROI% old": 28.322691,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 118.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3953.28899,
    "out:Return %": 25,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.976057,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 358292.6646,
    "out:EL kWh savings": -104888.4875,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147735.9,
    "out:DH kr savings": 326046.3248,
    "out:El kr savings": -178310.4287,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 63599.30288,
    "out:% savings (space heating)": 78.685359,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 15.89,
    "out:Etvv": 0,
    "out:Ef": 52.89,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2973875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.993201,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.106133,
    "out:Electricity (inc PV)": 52.401747,
    "out:Total Energy Use Pre PV": 75.106133,
    "out:Total Energy Use Post PV": 71.401747,
    "out:Primary Energy": 103.435518,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.583256,
    "out:CO2 Operational/m2": 14.246154,
    "out:Total CO2/m2": 18.82941,
    "out:Total CO2 (tons)": 53833.2773,
    "out:Klimatpaverkan":0.001* -104601.5125,
    "out:Initial Cost/MSEK": 3.045375,
    "out:Running cost/(Apt SEK y)": 11870.25714,
    "out:Running Cost over RSP/MSEK": 3.22541,
    "out:LCP/MSEK": 3.040885,
    "out:ROI% old": 28.550366,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3927.42931,
    "out:Return %": 25,
    "out:Return/kSEK/y": 775,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.606633,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 367590.4118,
    "out:EL kWh savings": -103500.7833,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158555.94,
    "out:DH kr savings": 334507.2747,
    "out:El kr savings": -175951.3317,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 54301.55571,
    "out:% savings (space heating)": 81.801402,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 13.02,
    "out:Etvv": 0,
    "out:Ef": 52.4,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3045375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.873209,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.230735,
    "out:Electricity (inc PV)": 52.108803,
    "out:Total Energy Use Pre PV": 75.230735,
    "out:Total Energy Use Post PV": 72.108803,
    "out:Primary Energy": 104.089756,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.9778,
    "out:CO2 Operational/m2": 14.490579,
    "out:Total CO2/m2": 19.468379,
    "out:Total CO2 (tons)": 55660.08947,
    "out:Klimatpaverkan":0.001* -102774.7003,
    "out:Initial Cost/MSEK": 3.1105,
    "out:Running cost/(Apt SEK y)": 11963.37143,
    "out:Running Cost over RSP/MSEK": 3.25132,
    "out:LCP/MSEK": 2.94985,
    "out:ROI% old": 27.833605,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 127.8,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3866.15145,
    "out:Return %": 25,
    "out:Return/kSEK/y": 772,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.731235,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 365074.4692,
    "out:EL kWh savings": -102663.2565,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 157690.23,
    "out:DH kr savings": 332217.767,
    "out:El kr savings": -174527.5361,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 56817.49831,
    "out:% savings (space heating)": 80.95821,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 14.71,
    "out:Etvv": 0,
    "out:Ef": 52.11,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3110500,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.739472,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.825445,
    "out:Electricity (inc PV)": 51.594747,
    "out:Total Energy Use Pre PV": 71.825445,
    "out:Total Energy Use Post PV": 68.594747,
    "out:Primary Energy": 101.222088,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.9778,
    "out:CO2 Operational/m2": 13.357125,
    "out:Total CO2/m2": 18.334925,
    "out:Total CO2 (tons)": 52419.54484,
    "out:Klimatpaverkan":0.001* -106015.245,
    "out:Initial Cost/MSEK": 3.182,
    "out:Running cost/(Apt SEK y)": 11263.02857,
    "out:Running Cost over RSP/MSEK": 3.05968,
    "out:LCP/MSEK": 3.06999,
    "out:ROI% old": 28.068555,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 137.7,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3837.78115,
    "out:Return %": 25,
    "out:Return/kSEK/y": 797,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.325945,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 374033.8223,
    "out:EL kWh savings": -101193.5706,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 168341.71,
    "out:DH kr savings": 340370.7783,
    "out:El kr savings": -172029.07,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 47858.14521,
    "out:% savings (space heating)": 83.960844,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 11.93,
    "out:Etvv": 0,
    "out:Ef": 51.59,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3182000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.245299,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.475557,
    "out:Electricity (inc PV)": 44.126383,
    "out:Total Energy Use Pre PV": 78.475557,
    "out:Total Energy Use Post PV": 66.126383,
    "out:Primary Energy": 90.548469,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.465866,
    "out:CO2 Operational/m2": 13.861205,
    "out:Total CO2/m2": 28.327071,
    "out:Total CO2 (tons)": 80987.08712,
    "out:Klimatpaverkan":0.001* -77447.70269,
    "out:Initial Cost/MSEK": 3.449625,
    "out:Running cost/(Apt SEK y)": 11327,
    "out:Running Cost over RSP/MSEK": 3.08612,
    "out:LCP/MSEK": 2.775925,
    "out:ROI% old": 25.781477,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 148.5,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3370.328506,
    "out:Return %": 23,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.976057,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 253.187895,
    "out:PV (% sold (El))": 0.990394,
    "out:PV (kWh self-consumed)": 25311.17672,
    "out:PV (ratio sold/self-consumed)": 0.010003,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 358292.6646,
    "out:EL kWh savings": -79841.52026,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 190638.55,
    "out:DH kr savings": 326046.3248,
    "out:El kr savings": -135730.5844,
    "out:El kr sold": 322.8145656,
    "out:El kr saved": 43029.00042,
    "out:El kr return": 43351.81499,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 63599.30288,
    "out:% savings (space heating)": 78.685359,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 15.89,
    "out:Etvv": 0,
    "out:Ef": 44.13,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3449625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.993201,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.106133,
    "out:Electricity (inc PV)": 43.655745,
    "out:Total Energy Use Pre PV": 75.106133,
    "out:Total Energy Use Post PV": 62.655745,
    "out:Primary Energy": 87.692714,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.465866,
    "out:CO2 Operational/m2": 12.697565,
    "out:Total CO2/m2": 27.163431,
    "out:Total CO2 (tons)": 77660.24073,
    "out:Klimatpaverkan":0.001* -80774.54909,
    "out:Initial Cost/MSEK": 3.521125,
    "out:Running cost/(Apt SEK y)": 10631.91429,
    "out:Running Cost over RSP/MSEK": 2.89589,
    "out:LCP/MSEK": 2.894655,
    "out:ROI% old": 26.029749,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 160.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3344.751827,
    "out:Return %": 23,
    "out:Return/kSEK/y": 819,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.606633,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 275.74838,
    "out:PV (% sold (El))": 1.078644,
    "out:PV (kWh self-consumed)": 25288.61623,
    "out:PV (ratio sold/self-consumed)": 0.010904,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 367590.4118,
    "out:EL kWh savings": -78495.96636,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201415.71,
    "out:DH kr savings": 334507.2747,
    "out:El kr savings": -133443.1428,
    "out:El kr sold": 351.5791848,
    "out:El kr saved": 42990.6476,
    "out:El kr return": 43342.22678,
    "out:% solar/total": 55,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 54301.55571,
    "out:% savings (space heating)": 81.801402,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 13.02,
    "out:Etvv": 0,
    "out:Ef": 43.66,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3521125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.873209,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.230735,
    "out:Electricity (inc PV)": 43.37203,
    "out:Total Energy Use Pre PV": 75.230735,
    "out:Total Energy Use Post PV": 63.37203,
    "out:Primary Energy": 88.363565,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.86041,
    "out:CO2 Operational/m2": 12.850617,
    "out:Total CO2/m2": 27.711027,
    "out:Total CO2 (tons)": 79225.81752,
    "out:Klimatpaverkan":0.001* -79208.97229,
    "out:Initial Cost/MSEK": 3.58625,
    "out:Running cost/(Apt SEK y)": 10725.74286,
    "out:Running Cost over RSP/MSEK": 2.92197,
    "out:LCP/MSEK": 2.80345,
    "out:ROI% old": 25.453169,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 160.3,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 3284.240215,
    "out:Return %": 23,
    "out:Return/kSEK/y": 815,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.731235,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 334.743372,
    "out:PV (% sold (El))": 1.309414,
    "out:PV (kWh self-consumed)": 25229.62124,
    "out:PV (ratio sold/self-consumed)": 0.013268,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 365074.4692,
    "out:EL kWh savings": -77684.82527,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200580.36,
    "out:DH kr savings": 332217.767,
    "out:El kr savings": -132064.203,
    "out:El kr sold": 426.797799,
    "out:El kr saved": 42890.35611,
    "out:El kr return": 43317.15391,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 56817.49831,
    "out:% savings (space heating)": 80.95821,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 14.71,
    "out:Etvv": 0,
    "out:Ef": 43.37,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3586250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.739472,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.825445,
    "out:Electricity (inc PV)": 42.874805,
    "out:Total Energy Use Pre PV": 71.825445,
    "out:Total Energy Use Post PV": 59.874805,
    "out:Primary Energy": 85.526192,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.86041,
    "out:CO2 Operational/m2": 11.670918,
    "out:Total CO2/m2": 26.531328,
    "out:Total CO2 (tons)": 75853.05845,
    "out:Klimatpaverkan":0.001* -82581.73137,
    "out:Initial Cost/MSEK": 3.65775,
    "out:Running cost/(Apt SEK y)": 10025.77143,
    "out:Running Cost over RSP/MSEK": 2.73042,
    "out:LCP/MSEK": 2.9235,
    "out:ROI% old": 25.703741,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 173.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3256.272976,
    "out:Return %": 23,
    "out:Return/kSEK/y": 840,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.325945,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 363.880782,
    "out:PV (% sold (El))": 1.423391,
    "out:PV (kWh self-consumed)": 25200.48383,
    "out:PV (ratio sold/self-consumed)": 0.014439,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 374033.8223,
    "out:EL kWh savings": -76263.25915,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211187.19,
    "out:DH kr savings": 340370.7783,
    "out:El kr savings": -129647.5405,
    "out:El kr sold": 463.9479966,
    "out:El kr saved": 42840.82251,
    "out:El kr return": 43304.77051,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 47858.14521,
    "out:% savings (space heating)": 83.960844,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 11.93,
    "out:Etvv": 0,
    "out:Ef": 42.87,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3657750,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 22.245299,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.475557,
    "out:Electricity (inc PV)": 39.605682,
    "out:Total Energy Use Pre PV": 78.475557,
    "out:Total Energy Use Post PV": 61.605682,
    "out:Primary Energy": 82.411208,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.348476,
    "out:CO2 Operational/m2": -6.796837,
    "out:Total CO2/m2": 17.551639,
    "out:Total CO2 (tons)": 50180.13041,
    "out:Klimatpaverkan":0.001* -108254.6594,
    "out:Initial Cost/MSEK": 3.925375,
    "out:Running cost/(Apt SEK y)": 10225.05714,
    "out:Running Cost over RSP/MSEK": 2.79207,
    "out:LCP/MSEK": 2.594225,
    "out:ROI% old": 23.726941,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 164.5,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 4,
    "out:DH+ DHW/kWh/m2": 22,
    "out:El bought/kWh/m2": 3020.103956,
    "out:Return %": 21,
    "out:Return/kSEK/y": 833,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.976057,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 11762.87003,
    "out:PV (% sold (El))": 23.00638,
    "out:PV (kWh self-consumed)": 39365.85938,
    "out:PV (ratio sold/self-consumed)": 0.298809,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 358292.6646,
    "out:EL kWh savings": -66916.83751,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227285.36,
    "out:DH kr savings": 326046.3248,
    "out:El kr savings": -113758.6238,
    "out:El kr sold": 14997.65928,
    "out:El kr saved": 66921.96095,
    "out:El kr return": 81919.62023,
    "out:% solar/total": 85,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 63599.30288,
    "out:% savings (space heating)": 78.685359,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 15.89,
    "out:Etvv": 0,
    "out:Ef": 39.61,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3925375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 18.993201,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 56.106133,
    "out:Electricity (inc PV)": 39.189059,
    "out:Total Energy Use Pre PV": 75.106133,
    "out:Total Energy Use Post PV": 58.189059,
    "out:Primary Energy": 79.652679,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.348476,
    "out:CO2 Operational/m2": -8.16653,
    "out:Total CO2/m2": 16.181946,
    "out:Total CO2 (tons)": 46264.17855,
    "out:Klimatpaverkan":0.001* -112170.6113,
    "out:Initial Cost/MSEK": 3.996875,
    "out:Running cost/(Apt SEK y)": 9531.4,
    "out:Running Cost over RSP/MSEK": 2.60223,
    "out:LCP/MSEK": 2.712565,
    "out:ROI% old": 23.981021,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 182.8,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 2997.40412,
    "out:Return %": 21,
    "out:Return/kSEK/y": 857,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 37.606633,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 11902.03465,
    "out:PV (% sold (El))": 23.278565,
    "out:PV (kWh self-consumed)": 39226.69476,
    "out:PV (ratio sold/self-consumed)": 0.303417,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 367590.4118,
    "out:EL kWh savings": -65725.71249,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237948.66,
    "out:DH kr savings": 334507.2747,
    "out:El kr savings": -111733.7112,
    "out:El kr sold": 15175.09417,
    "out:El kr saved": 66685.38109,
    "out:El kr return": 81860.47527,
    "out:% solar/total": 85,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 54301.55571,
    "out:% savings (space heating)": 81.801402,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 13.02,
    "out:Etvv": 0,
    "out:Ef": 39.19,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3996875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.873209,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 55.230735,
    "out:Electricity (inc PV)": 38.93808,
    "out:Total Energy Use Pre PV": 75.230735,
    "out:Total Energy Use Post PV": 58.93808,
    "out:Primary Energy": 80.382455,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.74302,
    "out:CO2 Operational/m2": -8.5001,
    "out:Total CO2/m2": 16.24292,
    "out:Total CO2 (tons)": 46438.5032,
    "out:Klimatpaverkan":0.001* -111996.2866,
    "out:Initial Cost/MSEK": 4.062,
    "out:Running cost/(Apt SEK y)": 9628.542857,
    "out:Running Cost over RSP/MSEK": 2.62921,
    "out:LCP/MSEK": 2.62046,
    "out:ROI% old": 23.501653,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 178,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 20,
    "out:El bought/kWh/m2": 2943.714184,
    "out:Return %": 21,
    "out:Return/kSEK/y": 854,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.731235,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12235.01265,
    "out:PV (% sold (El))": 23.929819,
    "out:PV (kWh self-consumed)": 38893.71675,
    "out:PV (ratio sold/self-consumed)": 0.314576,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 365074.4692,
    "out:EL kWh savings": -65008.1636,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 237303.53,
    "out:DH kr savings": 332217.767,
    "out:El kr savings": -110513.8781,
    "out:El kr sold": 15599.64113,
    "out:El kr saved": 66119.31848,
    "out:El kr return": 81718.95961,
    "out:% solar/total": 84,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 56817.49831,
    "out:% savings (space heating)": 80.95821,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 14.71,
    "out:Etvv": 0,
    "out:Ef": 38.94,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4062000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.739472,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.825445,
    "out:Electricity (inc PV)": 38.498398,
    "out:Total Energy Use Pre PV": 71.825445,
    "out:Total Energy Use Post PV": 55.498398,
    "out:Primary Energy": 77.648659,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.74302,
    "out:CO2 Operational/m2": -9.904673,
    "out:Total CO2/m2": 14.838347,
    "out:Total CO2 (tons)": 42422.82943,
    "out:Klimatpaverkan":0.001* -116011.9604,
    "out:Initial Cost/MSEK": 4.1335,
    "out:Running cost/(Apt SEK y)": 8930.114286,
    "out:Running Cost over RSP/MSEK": 2.43807,
    "out:LCP/MSEK": 2.7401,
    "out:ROI% old": 23.755724,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 198.2,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2918.904012,
    "out:Return %": 21,
    "out:Return/kSEK/y": 878,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 36.325945,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12391.33525,
    "out:PV (% sold (El))": 24.235563,
    "out:PV (kWh self-consumed)": 38737.39416,
    "out:PV (ratio sold/self-consumed)": 0.31988,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 374033.8223,
    "out:EL kWh savings": -63751.1129,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247792.84,
    "out:DH kr savings": 340370.7783,
    "out:El kr savings": -108376.8919,
    "out:El kr sold": 15798.95244,
    "out:El kr saved": 65853.57007,
    "out:El kr return": 81652.52251,
    "out:% solar/total": 84,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 47858.14521,
    "out:% savings (space heating)": 83.960844,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 11.93,
    "out:Etvv": 0,
    "out:Ef": 38.5,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4133500,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.108959,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.807781,
    "out:Electricity (inc PV)": 51.846119,
    "out:Total Energy Use Pre PV": 73.807781,
    "out:Total Energy Use Post PV": 70.846119,
    "out:Primary Energy": 103.348725,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.583256,
    "out:CO2 Operational/m2": 14.074762,
    "out:Total CO2/m2": 18.658018,
    "out:Total CO2 (tons)": 53343.26762,
    "out:Klimatpaverkan":0.001* -105091.5222,
    "out:Initial Cost/MSEK": 3.06625,
    "out:Running cost/(Apt SEK y)": 11689.97143,
    "out:Running Cost over RSP/MSEK": 3.17662,
    "out:LCP/MSEK": 3.0688,
    "out:ROI% old": 28.583309,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3836.54467,
    "out:Return %": 25,
    "out:Return/kSEK/y": 782,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 36.308281,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 367259.4597,
    "out:EL kWh savings": -101912.2431,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160955.3,
    "out:DH kr savings": 334206.1083,
    "out:El kr savings": -173250.8132,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 54632.5078,
    "out:% savings (space heating)": 81.690487,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 14.32,
    "out:Etvv": 0,
    "out:Ef": 51.85,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3066250,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.016424,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.390005,
    "out:Electricity (inc PV)": 51.322371,
    "out:Total Energy Use Pre PV": 70.390005,
    "out:Total Energy Use Post PV": 67.322371,
    "out:Primary Energy": 100.486001,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.583256,
    "out:CO2 Operational/m2": 12.93966,
    "out:Total CO2/m2": 17.522916,
    "out:Total CO2 (tons)": 50098.01136,
    "out:Klimatpaverkan":0.001* -108336.7785,
    "out:Initial Cost/MSEK": 3.13775,
    "out:Running cost/(Apt SEK y)": 10987.88571,
    "out:Running Cost over RSP/MSEK": 2.98451,
    "out:LCP/MSEK": 3.18941,
    "out:ROI% old": 28.806629,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 144.8,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3807.30035,
    "out:Return %": 26,
    "out:Return/kSEK/y": 806,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.890505,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 376101.0163,
    "out:EL kWh savings": -100414.8477,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 171546.68,
    "out:DH kr savings": 342251.9248,
    "out:El kr savings": -170705.2411,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 45790.9512,
    "out:% savings (space heating)": 84.653642,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 11.58,
    "out:Etvv": 0,
    "out:Ef": 51.32,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3137750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.325933,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.777183,
    "out:Electricity (inc PV)": 51.209153,
    "out:Total Energy Use Pre PV": 70.777183,
    "out:Total Energy Use Post PV": 68.209153,
    "out:Primary Energy": 101.573577,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 4.9778,
    "out:CO2 Operational/m2": 13.218746,
    "out:Total CO2/m2": 18.196546,
    "out:Total CO2 (tons)": 52023.91932,
    "out:Klimatpaverkan":0.001* -106410.8705,
    "out:Initial Cost/MSEK": 3.202875,
    "out:Running cost/(Apt SEK y)": 11117.48571,
    "out:Running Cost over RSP/MSEK": 3.02029,
    "out:LCP/MSEK": 3.088505,
    "out:ROI% old": 28.061307,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 141.2,
    "out:Op energy cost/MSEK": 21,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3764.40281,
    "out:Return %": 25,
    "out:Return/kSEK/y": 802,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 35.277683,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 372357.1305,
    "out:EL kWh savings": -100091.1575,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 168690.02,
    "out:DH kr savings": 338844.9887,
    "out:El kr savings": -170154.9677,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 49534.83702,
    "out:% savings (space heating)": 83.398918,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 13.42,
    "out:Etvv": 0,
    "out:Ef": 51.21,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3202875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.3499,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.324103,
    "out:Electricity (inc PV)": 50.66022,
    "out:Total Energy Use Pre PV": 67.324103,
    "out:Total Energy Use Post PV": 64.66022,
    "out:Primary Energy": 98.723696,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 4.9778,
    "out:CO2 Operational/m2": 12.078984,
    "out:Total CO2/m2": 17.056784,
    "out:Total CO2 (tons)": 48765.34012,
    "out:Klimatpaverkan":0.001* -109669.4497,
    "out:Initial Cost/MSEK": 3.274375,
    "out:Running cost/(Apt SEK y)": 10410.48571,
    "out:Running Cost over RSP/MSEK": 2.82685,
    "out:LCP/MSEK": 3.210445,
    "out:ROI% old": 28.292509,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 152.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3732.68721,
    "out:Return %": 25,
    "out:Return/kSEK/y": 826,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.824603,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 380865.6079,
    "out:EL kWh savings": -98521.75819,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 179100.71,
    "out:DH kr savings": 346587.7032,
    "out:El kr savings": -167486.9889,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 41026.35961,
    "out:% savings (space heating)": 86.250445,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 10.76,
    "out:Etvv": 0,
    "out:Ef": 50.66,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3274375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.108959,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.807781,
    "out:Electricity (inc PV)": 43.117849,
    "out:Total Energy Use Pre PV": 73.807781,
    "out:Total Energy Use Post PV": 62.117849,
    "out:Primary Energy": 87.637839,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.465866,
    "out:CO2 Operational/m2": 12.386481,
    "out:Total CO2/m2": 26.852347,
    "out:Total CO2 (tons)": 76770.85167,
    "out:Klimatpaverkan":0.001* -81663.93815,
    "out:Initial Cost/MSEK": 3.542,
    "out:Running cost/(Apt SEK y)": 10452.68571,
    "out:Running Cost over RSP/MSEK": 2.84737,
    "out:LCP/MSEK": 2.9223,
    "out:ROI% old": 26.072034,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 164.5,
    "out:Op energy cost/MSEK": 20,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 3255.054752,
    "out:Return %": 23,
    "out:Return/kSEK/y": 825,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 36.308281,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 365.189759,
    "out:PV (% sold (El))": 1.428511,
    "out:PV (kWh self-consumed)": 25199.17486,
    "out:PV (ratio sold/self-consumed)": 0.014492,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 367259.4597,
    "out:EL kWh savings": -76958.12187,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 203842.92,
    "out:DH kr savings": 334206.1083,
    "out:El kr savings": -130828.8072,
    "out:El kr sold": 465.6169432,
    "out:El kr saved": 42838.59725,
    "out:El kr return": 43304.2142,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 54632.5078,
    "out:% savings (space heating)": 81.690487,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 14.32,
    "out:Etvv": 0,
    "out:Ef": 43.12,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3542000,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.016424,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.390005,
    "out:Electricity (inc PV)": 42.611781,
    "out:Total Energy Use Pre PV": 70.390005,
    "out:Total Energy Use Post PV": 58.611781,
    "out:Primary Energy": 84.806939,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.465866,
    "out:CO2 Operational/m2": 11.201139,
    "out:Total CO2/m2": 25.667005,
    "out:Total CO2 (tons)": 73381.95926,
    "out:Klimatpaverkan":0.001* -85052.83055,
    "out:Initial Cost/MSEK": 3.6135,
    "out:Running cost/(Apt SEK y)": 9751,
    "out:Running Cost over RSP/MSEK": 2.65535,
    "out:LCP/MSEK": 3.04282,
    "out:ROI% old": 26.315286,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 178,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 3226.260308,
    "out:Return %": 24,
    "out:Return/kSEK/y": 850,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.890505,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 396.438246,
    "out:PV (% sold (El))": 1.550746,
    "out:PV (kWh self-consumed)": 25167.92637,
    "out:PV (ratio sold/self-consumed)": 0.015752,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 376101.0163,
    "out:EL kWh savings": -75511.27361,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214388.22,
    "out:DH kr savings": 342251.9248,
    "out:El kr savings": -128369.1651,
    "out:El kr sold": 505.4587634,
    "out:El kr saved": 42785.47483,
    "out:El kr return": 43290.93359,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 45790.9512,
    "out:% savings (space heating)": 84.653642,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 11.58,
    "out:Etvv": 0,
    "out:Ef": 42.61,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3613500,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.325933,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.777183,
    "out:Electricity (inc PV)": 42.502507,
    "out:Total Energy Use Pre PV": 70.777183,
    "out:Total Energy Use Post PV": 59.502507,
    "out:Primary Energy": 85.901615,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.86041,
    "out:CO2 Operational/m2": 11.402356,
    "out:Total CO2/m2": 26.262766,
    "out:Total CO2 (tons)": 75085.23977,
    "out:Klimatpaverkan":0.001* -83349.55004,
    "out:Initial Cost/MSEK": 3.678625,
    "out:Running cost/(Apt SEK y)": 9881.171429,
    "out:Running Cost over RSP/MSEK": 2.69127,
    "out:LCP/MSEK": 2.941775,
    "out:ROI% old": 25.709917,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 173.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 3184.084237,
    "out:Return %": 23,
    "out:Return/kSEK/y": 845,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 35.277683,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 444.05829,
    "out:PV (% sold (El))": 1.737021,
    "out:PV (kWh self-consumed)": 25120.30632,
    "out:PV (ratio sold/self-consumed)": 0.017677,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 372357.1305,
    "out:EL kWh savings": -75198.85928,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 211573.1,
    "out:DH kr savings": 338844.9887,
    "out:El kr savings": -127838.0608,
    "out:El kr sold": 566.1743203,
    "out:El kr saved": 42704.52075,
    "out:El kr return": 43270.69507,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 49534.83702,
    "out:% savings (space heating)": 83.398918,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 13.42,
    "out:Etvv": 0,
    "out:Ef": 42.5,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3678625,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.3499,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.324103,
    "out:Electricity (inc PV)": 41.973329,
    "out:Total Energy Use Pre PV": 67.324103,
    "out:Total Energy Use Post PV": 55.973329,
    "out:Primary Energy": 83.087292,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.86041,
    "out:CO2 Operational/m2": 10.202038,
    "out:Total CO2/m2": 25.062448,
    "out:Total CO2 (tons)": 71653.53099,
    "out:Klimatpaverkan":0.001* -86781.25883,
    "out:Initial Cost/MSEK": 3.750125,
    "out:Running cost/(Apt SEK y)": 9174.628571,
    "out:Running Cost over RSP/MSEK": 2.49795,
    "out:LCP/MSEK": 3.063595,
    "out:ROI% old": 25.956163,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 192.9,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 3152.950974,
    "out:Return %": 23,
    "out:Return/kSEK/y": 870,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.824603,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 480.866015,
    "out:PV (% sold (El))": 1.881001,
    "out:PV (kWh self-consumed)": 25083.4986,
    "out:PV (ratio sold/self-consumed)": 0.019171,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 380865.6079,
    "out:EL kWh savings": -73685.93954,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221934.71,
    "out:DH kr savings": 346587.7032,
    "out:El kr savings": -125266.0972,
    "out:El kr sold": 613.1041686,
    "out:El kr saved": 42641.94762,
    "out:El kr return": 43255.05179,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 41026.35961,
    "out:% savings (space heating)": 86.250445,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 10.76,
    "out:Etvv": 0,
    "out:Ef": 41.97,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3750125,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 19.108959,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.807781,
    "out:Electricity (inc PV)": 38.713272,
    "out:Total Energy Use Pre PV": 73.807781,
    "out:Total Energy Use Post PV": 57.713272,
    "out:Primary Energy": 79.709601,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.348476,
    "out:CO2 Operational/m2": -9.198906,
    "out:Total CO2/m2": 15.14957,
    "out:Total CO2 (tons)": 43312.61589,
    "out:Klimatpaverkan":0.001* -115122.1739,
    "out:Initial Cost/MSEK": 4.01775,
    "out:Running cost/(Apt SEK y)": 9357.114286,
    "out:Running Cost over RSP/MSEK": 2.55504,
    "out:LCP/MSEK": 2.73888,
    "out:ROI% old": 24.024214,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 182.8,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 19,
    "out:El bought/kWh/m2": 2917.823356,
    "out:Return %": 21,
    "out:Return/kSEK/y": 863,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 36.308281,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12398.1783,
    "out:PV (% sold (El))": 24.248947,
    "out:PV (kWh self-consumed)": 38730.55111,
    "out:PV (ratio sold/self-consumed)": 0.320114,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 367259.4597,
    "out:EL kWh savings": -64365.4376,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 240592.54,
    "out:DH kr savings": 334206.1083,
    "out:El kr savings": -109421.2439,
    "out:El kr sold": 15807.67733,
    "out:El kr saved": 65841.93688,
    "out:El kr return": 81649.61421,
    "out:% solar/total": 84,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 54632.5078,
    "out:% savings (space heating)": 81.690487,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 14.32,
    "out:Etvv": 0,
    "out:Ef": 38.71,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4017750,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 16.016424,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.390005,
    "out:Electricity (inc PV)": 38.265788,
    "out:Total Energy Use Pre PV": 70.390005,
    "out:Total Energy Use Post PV": 54.265788,
    "out:Primary Energy": 76.984151,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.348476,
    "out:CO2 Operational/m2": -10.616003,
    "out:Total CO2/m2": 13.732473,
    "out:Total CO2 (tons)": 39261.13601,
    "out:Klimatpaverkan":0.001* -119173.6538,
    "out:Initial Cost/MSEK": 4.08925,
    "out:Running cost/(Apt SEK y)": 8657.028571,
    "out:Running Cost over RSP/MSEK": 2.36345,
    "out:LCP/MSEK": 2.85897,
    "out:ROI% old": 24.27347,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 203.7,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 16,
    "out:El bought/kWh/m2": 2892.280369,
    "out:Return %": 22,
    "out:Return/kSEK/y": 888,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 35.890505,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12560.67136,
    "out:PV (% sold (El))": 24.566758,
    "out:PV (kWh self-consumed)": 38568.05805,
    "out:PV (ratio sold/self-consumed)": 0.325675,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 376101.0163,
    "out:EL kWh savings": -63086.08099,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 251020.44,
    "out:DH kr savings": 342251.9248,
    "out:El kr savings": -107246.3377,
    "out:El kr sold": 16014.85598,
    "out:El kr saved": 65565.69868,
    "out:El kr return": 81580.55466,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 45790.9512,
    "out:% savings (space heating)": 84.653642,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 11.58,
    "out:Etvv": 0,
    "out:Ef": 38.27,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4089250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 17.325933,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.777183,
    "out:Electricity (inc PV)": 38.169207,
    "out:Total Energy Use Pre PV": 70.777183,
    "out:Total Energy Use Post PV": 55.169207,
    "out:Primary Energy": 78.101675,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.74302,
    "out:CO2 Operational/m2": -10.75486,
    "out:Total CO2/m2": 13.98816,
    "out:Total CO2 (tons)": 39992.14506,
    "out:Klimatpaverkan":0.001* -118442.6448,
    "out:Initial Cost/MSEK": 4.154375,
    "out:Running cost/(Apt SEK y)": 8789.514286,
    "out:Running Cost over RSP/MSEK": 2.40001,
    "out:LCP/MSEK": 2.757285,
    "out:ROI% old": 23.767233,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 198.2,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 3,
    "out:DH+ DHW/kWh/m2": 17,
    "out:El bought/kWh/m2": 2854.868392,
    "out:Return %": 21,
    "out:Return/kSEK/y": 883,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 35.277683,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12801.61756,
    "out:PV (% sold (El))": 25.038012,
    "out:PV (kWh self-consumed)": 38327.11185,
    "out:PV (ratio sold/self-consumed)": 0.334009,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 372357.1305,
    "out:EL kWh savings": -62809.95594,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 248390.13,
    "out:DH kr savings": 338844.9887,
    "out:El kr savings": -106776.9251,
    "out:El kr sold": 16322.06239,
    "out:El kr saved": 65156.09014,
    "out:El kr return": 81478.15253,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 49534.83702,
    "out:% savings (space heating)": 83.398918,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 13.42,
    "out:Etvv": 0,
    "out:Ef": 38.17,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4154375,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z00000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 14.3499,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.324103,
    "out:Electricity (inc PV)": 37.701374,
    "out:Total Energy Use Pre PV": 67.324103,
    "out:Total Energy Use Post PV": 51.701374,
    "out:Primary Energy": 75.397773,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.74302,
    "out:CO2 Operational/m2": -12.207038,
    "out:Total CO2/m2": 12.535982,
    "out:Total CO2 (tons)": 35840.36861,
    "out:Klimatpaverkan":0.001* -122594.4212,
    "out:Initial Cost/MSEK": 4.225875,
    "out:Running cost/(Apt SEK y)": 8084.742857,
    "out:Running Cost over RSP/MSEK": 2.20715,
    "out:LCP/MSEK": 2.878645,
    "out:ROI% old": 24.017072,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 215.4,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 14,
    "out:El bought/kWh/m2": 2827.251893,
    "out:Return %": 21,
    "out:Return/kSEK/y": 908,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 34.824603,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12981.63462,
    "out:PV (% sold (El))": 25.390098,
    "out:PV (kWh self-consumed)": 38147.09479,
    "out:PV (ratio sold/self-consumed)": 0.340305,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 380865.6079,
    "out:EL kWh savings": -61472.42154,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258636.17,
    "out:DH kr savings": 346587.7032,
    "out:El kr savings": -104503.1166,
    "out:El kr sold": 16551.58414,
    "out:El kr saved": 64850.06113,
    "out:El kr return": 81401.64528,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 41026.35961,
    "out:% savings (space heating)": 86.250445,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 10.76,
    "out:Etvv": 0,
    "out:Ef": 37.7,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4225875,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 148.2,
    "out:Primary Energy": 109.041649,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.281666,
    "out:CO2 Operational/m2": 49.656418,
    "out:Total CO2/m2": 59.938084,
    "out:Total CO2 (tons)": 171362.9634,
    "out:Klimatpaverkan":0.001* 12928.17358,
    "out:Initial Cost/MSEK": 2.107875,
    "out:Running cost/(Apt SEK y)": 30588.22857,
    "out:Running Cost over RSP/MSEK": 8.37081,
    "out:LCP/MSEK": -1.167015,
    "out:ROI% old": 6.376496,
    "out:Payback discounted": 19,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 10.8,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 6,
    "out:Return/kSEK/y": 120,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 43598.72828,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 39674.84,
    "out:DH kr savings": 39674.84274,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2107875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 145.2,
    "out:Primary Energy": 106.397029,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.281666,
    "out:CO2 Operational/m2": 48.576466,
    "out:Total CO2/m2": 58.858132,
    "out:Total CO2 (tons)": 168275.381,
    "out:Klimatpaverkan":0.001* 9840.591152,
    "out:Initial Cost/MSEK": 2.179375,
    "out:Running cost/(Apt SEK y)": 29944.17143,
    "out:Running Cost over RSP/MSEK": 8.19439,
    "out:LCP/MSEK": -1.062095,
    "out:ROI% old": 7.323725,
    "out:Payback discounted": 16,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 13.1,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 143,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 54400.11258,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 49504.1,
    "out:DH kr savings": 49504.10245,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2179375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 106.416649,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.67621,
    "out:CO2 Operational/m2": 47.496514,
    "out:Total CO2/m2": 58.172724,
    "out:Total CO2 (tons)": 166315.7997,
    "out:Klimatpaverkan":0.001* 7881.009895,
    "out:Initial Cost/MSEK": 2.2445,
    "out:Running cost/(Apt SEK y)": 29300.11429,
    "out:Running Cost over RSP/MSEK": 8.01798,
    "out:LCP/MSEK": -0.95081,
    "out:ROI% old": 8.234032,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 62125.04625,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56533.79,
    "out:DH kr savings": 56533.79209,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2244500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 103.772029,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.67621,
    "out:CO2 Operational/m2": 46.056578,
    "out:Total CO2/m2": 56.732788,
    "out:Total CO2 (tons)": 162199.0231,
    "out:Klimatpaverkan":0.001* 3764.233322,
    "out:Initial Cost/MSEK": 2.316,
    "out:Running cost/(Apt SEK y)": 28441.37143,
    "out:Running Cost over RSP/MSEK": 7.78277,
    "out:LCP/MSEK": -0.7871,
    "out:ROI% old": 9.430669,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 18.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 195,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 72926.43055,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 66363.05,
    "out:DH kr savings": 66363.0518,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2316000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 143.305876,
    "out:Primary Energy": 100.232226,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.164276,
    "out:CO2 Operational/m2": 28.644429,
    "out:Total CO2/m2": 48.808705,
    "out:Total CO2 (tons)": 139544.0723,
    "out:Klimatpaverkan":0.001* -18890.7175,
    "out:Initial Cost/MSEK": 2.583625,
    "out:Running cost/(Apt SEK y)": 29487.05714,
    "out:Running Cost over RSP/MSEK": 8.07743,
    "out:LCP/MSEK": -1.349385,
    "out:ROI% old": 6.82452,
    "out:Payback discounted": 18,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 14.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 6,
    "out:Return/kSEK/y": 159,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 43598.72828,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 78216.14,
    "out:DH kr savings": 39674.84274,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2583625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 140.305876,
    "out:Primary Energy": 97.587606,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.164276,
    "out:CO2 Operational/m2": 27.564477,
    "out:Total CO2/m2": 47.728753,
    "out:Total CO2 (tons)": 136456.4899,
    "out:Klimatpaverkan":0.001* -21978.29993,
    "out:Initial Cost/MSEK": 2.655125,
    "out:Running cost/(Apt SEK y)": 28843,
    "out:Running Cost over RSP/MSEK": 7.90102,
    "out:LCP/MSEK": -1.244475,
    "out:ROI% old": 7.589904,
    "out:Payback discounted": 16,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.1,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 7,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 54400.11258,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88045.39,
    "out:DH kr savings": 49504.10245,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2655125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 137.305876,
    "out:Primary Energy": 97.607226,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.55882,
    "out:CO2 Operational/m2": 26.484525,
    "out:Total CO2/m2": 47.043345,
    "out:Total CO2 (tons)": 134496.9086,
    "out:Klimatpaverkan":0.001* -23937.88118,
    "out:Initial Cost/MSEK": 2.72025,
    "out:Running cost/(Apt SEK y)": 28198.91429,
    "out:Running Cost over RSP/MSEK": 7.72461,
    "out:LCP/MSEK": -1.13319,
    "out:ROI% old": 8.334633,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 7,
    "out:Return/kSEK/y": 204,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 62125.04625,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95075.08,
    "out:DH kr savings": 56533.79209,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2720250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 133.305876,
    "out:Primary Energy": 94.962606,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.55882,
    "out:CO2 Operational/m2": 25.044589,
    "out:Total CO2/m2": 45.603409,
    "out:Total CO2 (tons)": 130380.1321,
    "out:Klimatpaverkan":0.001* -28054.65776,
    "out:Initial Cost/MSEK": 2.79175,
    "out:Running cost/(Apt SEK y)": 27340.17143,
    "out:Running Cost over RSP/MSEK": 7.48939,
    "out:LCP/MSEK": -0.96947,
    "out:ROI% old": 9.324822,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 23.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 8,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 72926.43055,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104904.34,
    "out:DH kr savings": 66363.0518,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2791750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 148.2,
    "out:Total Energy Use Post PV": 142.330546,
    "out:Primary Energy": 98.476632,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.046886,
    "out:CO2 Operational/m2": -11.991029,
    "out:Total CO2/m2": 18.055857,
    "out:Total CO2 (tons)": 51621.68951,
    "out:Klimatpaverkan":0.001* -106813.1003,
    "out:Initial Cost/MSEK": 3.059375,
    "out:Running cost/(Apt SEK y)": 28521.91429,
    "out:Running Cost over RSP/MSEK": 7.82053,
    "out:LCP/MSEK": -1.568235,
    "out:ROI% old": 6.96286,
    "out:Payback discounted": 18,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 6,
    "out:Return/kSEK/y": 193,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 43598.72828,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 111995.8,
    "out:DH kr savings": 39674.84274,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3059375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 145.2,
    "out:Total Energy Use Post PV": 139.330546,
    "out:Primary Energy": 95.832012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.046886,
    "out:CO2 Operational/m2": -13.070981,
    "out:Total CO2/m2": 16.975905,
    "out:Total CO2 (tons)": 48534.10708,
    "out:Klimatpaverkan":0.001* -109900.6827,
    "out:Initial Cost/MSEK": 3.130875,
    "out:Running cost/(Apt SEK y)": 27877.85714,
    "out:Running Cost over RSP/MSEK": 7.64412,
    "out:LCP/MSEK": -1.463325,
    "out:ROI% old": 7.608781,
    "out:Payback discounted": 16,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 18,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 7,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 54400.11258,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121825.06,
    "out:DH kr savings": 49504.10245,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3130875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 136.330546,
    "out:Primary Energy": 95.851632,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.44143,
    "out:CO2 Operational/m2": -14.150933,
    "out:Total CO2/m2": 16.290497,
    "out:Total CO2 (tons)": 46574.52582,
    "out:Klimatpaverkan":0.001* -111860.264,
    "out:Initial Cost/MSEK": 3.196,
    "out:Running cost/(Apt SEK y)": 27233.8,
    "out:Running Cost over RSP/MSEK": 7.46771,
    "out:LCP/MSEK": -1.35204,
    "out:ROI% old": 8.242267,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 7,
    "out:Return/kSEK/y": 238,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 62125.04625,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128854.75,
    "out:DH kr savings": 56533.79209,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3196000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.330546,
    "out:Primary Energy": 93.207012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.44143,
    "out:CO2 Operational/m2": -15.590869,
    "out:Total CO2/m2": 14.850561,
    "out:Total CO2 (tons)": 42457.74925,
    "out:Klimatpaverkan":0.001* -115977.0406,
    "out:Initial Cost/MSEK": 3.2675,
    "out:Running cost/(Apt SEK y)": 26375.05714,
    "out:Running Cost over RSP/MSEK": 7.23249,
    "out:LCP/MSEK": -1.18832,
    "out:ROI% old": 9.090305,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 24.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 8,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 72926.43055,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138684.01,
    "out:DH kr savings": 66363.0518,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3267500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 140.2,
    "out:Primary Energy": 105.541649,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.281666,
    "out:CO2 Operational/m2": 46.776546,
    "out:Total CO2/m2": 57.058212,
    "out:Total CO2 (tons)": 163129.4102,
    "out:Klimatpaverkan":0.001* 4694.620436,
    "out:Initial Cost/MSEK": 2.20025,
    "out:Running cost/(Apt SEK y)": 28870.74286,
    "out:Running Cost over RSP/MSEK": 7.90037,
    "out:LCP/MSEK": -0.78895,
    "out:ROI% old": 9.163244,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.1,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 180,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 68300.48558,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62153.44,
    "out:DH kr savings": 62153.44187,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2200250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 136.2,
    "out:Primary Energy": 102.897029,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.281666,
    "out:CO2 Operational/m2": 45.33661,
    "out:Total CO2/m2": 55.618276,
    "out:Total CO2 (tons)": 159012.6337,
    "out:Klimatpaverkan":0.001* 577.843862,
    "out:Initial Cost/MSEK": 2.27175,
    "out:Running cost/(Apt SEK y)": 28012,
    "out:Running Cost over RSP/MSEK": 7.66516,
    "out:LCP/MSEK": -0.62524,
    "out:ROI% old": 10.353944,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 210,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 79101.86988,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 71982.7,
    "out:DH kr savings": 71982.70159,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2271750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 134.2,
    "out:Primary Energy": 103.441649,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 10.67621,
    "out:CO2 Operational/m2": 44.616642,
    "out:Total CO2/m2": 55.292852,
    "out:Total CO2 (tons)": 158082.2466,
    "out:Klimatpaverkan":0.001* -352.543252,
    "out:Initial Cost/MSEK": 2.336875,
    "out:Running cost/(Apt SEK y)": 27582.62857,
    "out:Running Cost over RSP/MSEK": 7.54755,
    "out:LCP/MSEK": -0.572755,
    "out:ROI% old": 10.784366,
    "out:Payback discounted": 11,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 22.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 10,
    "out:Return/kSEK/y": 225,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 83121.53995,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 75640.6,
    "out:DH kr savings": 75640.60136,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2336875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 131.2,
    "out:Primary Energy": 100.797029,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.67621,
    "out:CO2 Operational/m2": 43.53669,
    "out:Total CO2/m2": 54.2129,
    "out:Total CO2 (tons)": 154994.6641,
    "out:Klimatpaverkan":0.001* -3440.125682,
    "out:Initial Cost/MSEK": 2.408375,
    "out:Running cost/(Apt SEK y)": 26938.54286,
    "out:Running Cost over RSP/MSEK": 7.37114,
    "out:LCP/MSEK": -0.467845,
    "out:ROI% old": 11.510607,
    "out:Payback discounted": 10,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 10,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 93922.92425,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85469.86,
    "out:DH kr savings": 85469.86107,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2408375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 135.305876,
    "out:Primary Energy": 96.732226,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.164276,
    "out:CO2 Operational/m2": 25.764557,
    "out:Total CO2/m2": 45.928833,
    "out:Total CO2 (tons)": 131310.5192,
    "out:Klimatpaverkan":0.001* -27124.27064,
    "out:Initial Cost/MSEK": 2.676,
    "out:Running cost/(Apt SEK y)": 27769.54286,
    "out:Running Cost over RSP/MSEK": 7.607,
    "out:LCP/MSEK": -0.97133,
    "out:ROI% old": 9.10031,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 21.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 8,
    "out:Return/kSEK/y": 219,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68300.48558,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 100694.73,
    "out:DH kr savings": 62153.44187,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2676000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 131.305876,
    "out:Primary Energy": 94.087606,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.164276,
    "out:CO2 Operational/m2": 24.324621,
    "out:Total CO2/m2": 44.488897,
    "out:Total CO2 (tons)": 127193.7426,
    "out:Klimatpaverkan":0.001* -31241.04722,
    "out:Initial Cost/MSEK": 2.7475,
    "out:Running cost/(Apt SEK y)": 26910.8,
    "out:Running Cost over RSP/MSEK": 7.37179,
    "out:LCP/MSEK": -0.80762,
    "out:ROI% old": 10.086468,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 9,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 79101.86988,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 110523.99,
    "out:DH kr savings": 71982.70159,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2747500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 129.305876,
    "out:Primary Energy": 94.632226,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.55882,
    "out:CO2 Operational/m2": 23.604653,
    "out:Total CO2/m2": 44.163473,
    "out:Total CO2 (tons)": 126263.3555,
    "out:Klimatpaverkan":0.001* -32171.43433,
    "out:Initial Cost/MSEK": 2.812625,
    "out:Running cost/(Apt SEK y)": 26481.42857,
    "out:Running Cost over RSP/MSEK": 7.25418,
    "out:LCP/MSEK": -0.755135,
    "out:ROI% old": 10.450278,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 9,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83121.53995,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114181.89,
    "out:DH kr savings": 75640.60136,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2812625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 126.305876,
    "out:Primary Energy": 91.987606,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.55882,
    "out:CO2 Operational/m2": 22.524701,
    "out:Total CO2/m2": 43.083521,
    "out:Total CO2 (tons)": 123175.7731,
    "out:Klimatpaverkan":0.001* -35259.01676,
    "out:Initial Cost/MSEK": 2.884125,
    "out:Running cost/(Apt SEK y)": 25837.37143,
    "out:Running Cost over RSP/MSEK": 7.07777,
    "out:LCP/MSEK": -0.650225,
    "out:ROI% old": 11.065005,
    "out:Payback discounted": 11,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 10,
    "out:Return/kSEK/y": 287,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 93922.92425,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124011.15,
    "out:DH kr savings": 85469.86107,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2884125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 140.2,
    "out:Total Energy Use Post PV": 134.330546,
    "out:Primary Energy": 94.976632,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.046886,
    "out:CO2 Operational/m2": -14.870901,
    "out:Total CO2/m2": 15.175985,
    "out:Total CO2 (tons)": 43388.13637,
    "out:Klimatpaverkan":0.001* -115046.6534,
    "out:Initial Cost/MSEK": 3.15175,
    "out:Running cost/(Apt SEK y)": 26804.42857,
    "out:Running Cost over RSP/MSEK": 7.3501,
    "out:LCP/MSEK": -1.19018,
    "out:ROI% old": 8.89107,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 22.4,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 8,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 68300.48558,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134474.4,
    "out:DH kr savings": 62153.44187,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3151750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 136.2,
    "out:Total Energy Use Post PV": 130.330546,
    "out:Primary Energy": 92.332012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.046886,
    "out:CO2 Operational/m2": -16.310837,
    "out:Total CO2/m2": 13.736049,
    "out:Total CO2 (tons)": 39271.35979,
    "out:Klimatpaverkan":0.001* -119163.43,
    "out:Initial Cost/MSEK": 3.22325,
    "out:Running cost/(Apt SEK y)": 25945.68571,
    "out:Running Cost over RSP/MSEK": 7.11488,
    "out:LCP/MSEK": -1.02646,
    "out:ROI% old": 9.736357,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 9,
    "out:Return/kSEK/y": 283,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 79101.86988,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144303.66,
    "out:DH kr savings": 71982.70159,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3223250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.116642,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 134.2,
    "out:Total Energy Use Post PV": 128.330546,
    "out:Primary Energy": 92.876632,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.44143,
    "out:CO2 Operational/m2": -17.030805,
    "out:Total CO2/m2": 13.410625,
    "out:Total CO2 (tons)": 38340.97268,
    "out:Klimatpaverkan":0.001* -120093.8171,
    "out:Initial Cost/MSEK": 3.288375,
    "out:Running cost/(Apt SEK y)": 25516.31429,
    "out:Running Cost over RSP/MSEK": 6.99728,
    "out:LCP/MSEK": -0.973985,
    "out:ROI% old": 10.054423,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 9,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83121.53995,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 147961.56,
    "out:DH kr savings": 75640.60136,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 254784.4527,
    "out:% savings (space heating)": 14.611657,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 89.12,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3288375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.338613,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 131.2,
    "out:Total Energy Use Post PV": 125.330546,
    "out:Primary Energy": 90.232012,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.44143,
    "out:CO2 Operational/m2": -18.110757,
    "out:Total CO2/m2": 12.330673,
    "out:Total CO2 (tons)": 35253.39025,
    "out:Klimatpaverkan":0.001* -123181.3996,
    "out:Initial Cost/MSEK": 3.359875,
    "out:Running cost/(Apt SEK y)": 24872.25714,
    "out:Running Cost over RSP/MSEK": 6.82086,
    "out:LCP/MSEK": -0.869065,
    "out:ROI% old": 10.590573,
    "out:Payback discounted": 11,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 31.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 10,
    "out:Return/kSEK/y": 320,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 93922.92425,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157790.82,
    "out:DH kr savings": 85469.86107,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 243983.0684,
    "out:% savings (space heating)": 18.231628,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 85.34,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3359875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.69129,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.022334,
    "out:Electricity (inc PV)": 39.644105,
    "out:Total Energy Use Pre PV": 97.022334,
    "out:Total Energy Use Post PV": 94.644105,
    "out:Primary Energy": 102.397001,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.360305,
    "out:CO2 Operational/m2": 25.346404,
    "out:Total CO2/m2": 39.706709,
    "out:Total CO2 (tons)": 113521.4686,
    "out:Klimatpaverkan":0.001* -44913.32121,
    "out:Initial Cost/MSEK": 3.75575,
    "out:Running cost/(Apt SEK y)": 17643.2,
    "out:Running Cost over RSP/MSEK": 4.81316,
    "out:LCP/MSEK": 0.74276,
    "out:ROI% old": 17.110944,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 72.6,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2941.56338,
    "out:Return %": 15,
    "out:Return/kSEK/y": 573,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.522834,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 265529.5865,
    "out:EL kWh savings": -67026.68886,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127686.55,
    "out:DH kr savings": 241631.9237,
    "out:El kr savings": -113945.3711,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 156362.381,
    "out:% savings (space heating)": 47.596785,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 44.34,
    "out:Etvv": 0,
    "out:Ef": 39.64,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3755750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.069196,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.975081,
    "out:Electricity (inc PV)": 39.568352,
    "out:Total Energy Use Pre PV": 92.975081,
    "out:Total Energy Use Post PV": 90.568352,
    "out:Primary Energy": 99.791014,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.360305,
    "out:CO2 Operational/m2": 23.90023,
    "out:Total CO2/m2": 38.260535,
    "out:Total CO2 (tons)": 109386.8576,
    "out:Klimatpaverkan":0.001* -49047.93222,
    "out:Initial Cost/MSEK": 3.82725,
    "out:Running cost/(Apt SEK y)": 16777.91429,
    "out:Running Cost over RSP/MSEK": 4.57617,
    "out:LCP/MSEK": 0.90825,
    "out:ROI% old": 17.675877,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 80.2,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2938.25567,
    "out:Return %": 16,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.475581,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 275885.1521,
    "out:EL kWh savings": -66810.11105,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 137478.3,
    "out:DH kr savings": 251055.4884,
    "out:El kr savings": -113577.1888,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 146006.8154,
    "out:% savings (space heating)": 51.067344,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 40.81,
    "out:Etvv": 0,
    "out:Ef": 39.57,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3827250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.926979,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.199398,
    "out:Electricity (inc PV)": 39.127295,
    "out:Total Energy Use Pre PV": 92.199398,
    "out:Total Energy Use Post PV": 90.127295,
    "out:Primary Energy": 100.035575,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.754849,
    "out:CO2 Operational/m2": 23.797834,
    "out:Total CO2/m2": 38.552683,
    "out:Total CO2 (tons)": 110222.1086,
    "out:Klimatpaverkan":0.001* -48212.68118,
    "out:Initial Cost/MSEK": 3.892375,
    "out:Running cost/(Apt SEK y)": 16670.2,
    "out:Running Cost over RSP/MSEK": 4.54702,
    "out:LCP/MSEK": 0.872275,
    "out:ROI% old": 17.48712,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 82.2,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2883.95786,
    "out:Return %": 16,
    "out:Return/kSEK/y": 607,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.699898,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 276291.7505,
    "out:EL kWh savings": -65549.12923,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 139991.97,
    "out:DH kr savings": 251425.4929,
    "out:El kr savings": -111433.5197,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 145600.217,
    "out:% savings (space heating)": 51.203611,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 42.29,
    "out:Etvv": 0,
    "out:Ef": 39.13,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3892375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.333223,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.143558,
    "out:Electricity (inc PV)": 39.043793,
    "out:Total Energy Use Pre PV": 88.143558,
    "out:Total Energy Use Post PV": 86.043793,
    "out:Primary Energy": 97.43354,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.754849,
    "out:CO2 Operational/m2": 22.350527,
    "out:Total CO2/m2": 37.105376,
    "out:Total CO2 (tons)": 106084.2584,
    "out:Klimatpaverkan":0.001* -52350.53144,
    "out:Initial Cost/MSEK": 3.963875,
    "out:Running cost/(Apt SEK y)": 15803.68571,
    "out:Running Cost over RSP/MSEK": 4.30971,
    "out:LCP/MSEK": 1.038085,
    "out:ROI% old": 18.026949,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 90.7,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2880.04906,
    "out:Return %": 16,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.644058,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 286566.2977,
    "out:EL kWh savings": -65310.39704,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149747.66,
    "out:DH kr savings": 260775.3309,
    "out:El kr savings": -111027.675,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 135325.6697,
    "out:% savings (space heating)": 54.647018,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 38.79,
    "out:Etvv": 0,
    "out:Ef": 39.04,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3963875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.69129,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.022334,
    "out:Electricity (inc PV)": 31.576863,
    "out:Total Energy Use Pre PV": 97.022334,
    "out:Total Energy Use Post PV": 86.576863,
    "out:Primary Energy": 87.875965,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.242915,
    "out:CO2 Operational/m2": 20.917617,
    "out:Total CO2/m2": 45.160532,
    "out:Total CO2 (tons)": 129113.9469,
    "out:Klimatpaverkan":0.001* -29320.84296,
    "out:Initial Cost/MSEK": 4.2315,
    "out:Running cost/(Apt SEK y)": 16425.91429,
    "out:Running Cost over RSP/MSEK": 4.48898,
    "out:LCP/MSEK": 0.59119,
    "out:ROI% old": 16.281596,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 88.5,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2390.866684,
    "out:Return %": 15,
    "out:Return/kSEK/y": 616,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.522834,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2009.924905,
    "out:PV (% sold (El))": 7.862213,
    "out:PV (kWh self-consumed)": 23554.43971,
    "out:PV (ratio sold/self-consumed)": 0.085331,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265529.5865,
    "out:EL kWh savings": -43962.4465,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169458.42,
    "out:DH kr savings": 241631.9237,
    "out:El kr savings": -74736.15906,
    "out:El kr sold": 2562.654254,
    "out:El kr saved": 40042.54751,
    "out:El kr return": 42605.20176,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 156362.381,
    "out:% savings (space heating)": 47.596785,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 44.34,
    "out:Etvv": 0,
    "out:Ef": 31.58,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4231500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.069196,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.975081,
    "out:Electricity (inc PV)": 31.506886,
    "out:Total Energy Use Pre PV": 92.975081,
    "out:Total Energy Use Post PV": 82.506886,
    "out:Primary Energy": 85.280375,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.242915,
    "out:CO2 Operational/m2": 19.455922,
    "out:Total CO2/m2": 43.698837,
    "out:Total CO2 (tons)": 124934.9613,
    "out:Klimatpaverkan":0.001* -33499.82851,
    "out:Initial Cost/MSEK": 4.303,
    "out:Running cost/(Apt SEK y)": 15560.71429,
    "out:Running Cost over RSP/MSEK": 4.25202,
    "out:LCP/MSEK": 0.75665,
    "out:ROI% old": 16.797749,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 97.6,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2387.746164,
    "out:Return %": 15,
    "out:Return/kSEK/y": 646,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.475581,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2019.106117,
    "out:PV (% sold (El))": 7.898128,
    "out:PV (kWh self-consumed)": 23545.2585,
    "out:PV (ratio sold/self-consumed)": 0.085754,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 275885.1521,
    "out:EL kWh savings": -43762.38228,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 179233.8,
    "out:DH kr savings": 251055.4884,
    "out:El kr savings": -74396.04988,
    "out:El kr sold": 2574.360299,
    "out:El kr saved": 40026.93944,
    "out:El kr return": 42601.29974,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 146006.8154,
    "out:% savings (space heating)": 51.067344,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 40.81,
    "out:Etvv": 0,
    "out:Ef": 31.51,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4303000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.926979,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.199398,
    "out:Electricity (inc PV)": 31.100129,
    "out:Total Energy Use Pre PV": 92.199398,
    "out:Total Energy Use Post PV": 82.100129,
    "out:Primary Energy": 85.586676,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.637459,
    "out:CO2 Operational/m2": 19.092238,
    "out:Total CO2/m2": 43.729697,
    "out:Total CO2 (tons)": 125023.19,
    "out:Klimatpaverkan":0.001* -33411.59978,
    "out:Initial Cost/MSEK": 4.368125,
    "out:Running cost/(Apt SEK y)": 15454.88571,
    "out:Running Cost over RSP/MSEK": 4.22336,
    "out:LCP/MSEK": 0.720185,
    "out:ROI% old": 16.64104,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2336.607446,
    "out:Return %": 15,
    "out:Return/kSEK/y": 650,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.699898,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2173.098534,
    "out:PV (% sold (El))": 8.500499,
    "out:PV (kWh self-consumed)": 23391.26608,
    "out:PV (ratio sold/self-consumed)": 0.092902,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 276291.7505,
    "out:EL kWh savings": -42599.46415,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181777.1,
    "out:DH kr savings": 251425.4929,
    "out:El kr savings": -72419.08905,
    "out:El kr sold": 2770.700631,
    "out:El kr saved": 39765.15234,
    "out:El kr return": 42535.85297,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 145600.217,
    "out:% savings (space heating)": 51.203611,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 42.29,
    "out:Etvv": 0,
    "out:Ef": 31.1,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4368125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.333223,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.143558,
    "out:Electricity (inc PV)": 31.023239,
    "out:Total Energy Use Pre PV": 88.143558,
    "out:Total Energy Use Post PV": 78.023239,
    "out:Primary Energy": 82.996543,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.637459,
    "out:CO2 Operational/m2": 17.625665,
    "out:Total CO2/m2": 42.263124,
    "out:Total CO2 (tons)": 120830.2583,
    "out:Klimatpaverkan":0.001* -37604.53153,
    "out:Initial Cost/MSEK": 4.439625,
    "out:Running cost/(Apt SEK y)": 14588.51429,
    "out:Running Cost over RSP/MSEK": 3.98608,
    "out:LCP/MSEK": 0.885965,
    "out:ROI% old": 17.13655,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 110.3,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2332.932317,
    "out:Return %": 15,
    "out:Return/kSEK/y": 680,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.644058,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2184.384522,
    "out:PV (% sold (El))": 8.544646,
    "out:PV (kWh self-consumed)": 23379.98009,
    "out:PV (ratio sold/self-consumed)": 0.09343,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 286566.2977,
    "out:EL kWh savings": -42379.63566,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191515.04,
    "out:DH kr savings": 260775.3309,
    "out:El kr savings": -72045.38062,
    "out:El kr sold": 2785.090266,
    "out:El kr saved": 39745.96616,
    "out:El kr return": 42531.05642,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 135325.6697,
    "out:% savings (space heating)": 54.647018,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 38.79,
    "out:Etvv": 0,
    "out:Ef": 31.02,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4439625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 54.69129,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 42.022334,
    "out:Electricity (inc PV)": 28.481271,
    "out:Total Energy Use Pre PV": 97.022334,
    "out:Total Energy Use Post PV": 83.481271,
    "out:Primary Energy": 82.3039,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.125525,
    "out:CO2 Operational/m2": -7.736884,
    "out:Total CO2/m2": 26.388641,
    "out:Total CO2 (tons)": 75445.11636,
    "out:Klimatpaverkan":0.001* -82989.67345,
    "out:Initial Cost/MSEK": 4.70725,
    "out:Running cost/(Apt SEK y)": 15378.77143,
    "out:Running Cost over RSP/MSEK": 4.2098,
    "out:LCP/MSEK": 0.39462,
    "out:ROI% old": 15.48332,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 97.6,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 9,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 2152.299794,
    "out:Return %": 14,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.522834,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18030.70712,
    "out:PV (% sold (El))": 35.265314,
    "out:PV (kWh self-consumed)": 33098.02229,
    "out:PV (ratio sold/self-consumed)": 0.544767,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 265529.5865,
    "out:EL kWh savings": -35112.14995,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204930.42,
    "out:DH kr savings": 241631.9237,
    "out:El kr savings": -59690.65491,
    "out:El kr sold": 22989.15158,
    "out:El kr saved": 56266.63789,
    "out:El kr return": 79255.78946,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 156362.381,
    "out:% savings (space heating)": 47.596785,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 44.34,
    "out:Etvv": 0,
    "out:Ef": 28.48,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4707250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 51.069196,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.975081,
    "out:Electricity (inc PV)": 28.418974,
    "out:Total Energy Use Pre PV": 92.975081,
    "out:Total Energy Use Post PV": 79.418974,
    "out:Primary Energy": 79.722133,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.125525,
    "out:CO2 Operational/m2": -9.223323,
    "out:Total CO2/m2": 24.902202,
    "out:Total CO2 (tons)": 71195.38772,
    "out:Klimatpaverkan":0.001* -87239.40209,
    "out:Initial Cost/MSEK": 4.77875,
    "out:Running cost/(Apt SEK y)": 14513.74286,
    "out:Running Cost over RSP/MSEK": 3.97289,
    "out:LCP/MSEK": 0.56003,
    "out:ROI% old": 15.959882,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 107.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2149.532663,
    "out:Return %": 14,
    "out:Return/kSEK/y": 683,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.475581,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18053.72355,
    "out:PV (% sold (El))": 35.310331,
    "out:PV (kWh self-consumed)": 33075.00586,
    "out:PV (ratio sold/self-consumed)": 0.545842,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 275885.1521,
    "out:EL kWh savings": -34934.04284,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214686.11,
    "out:DH kr savings": 251055.4884,
    "out:El kr savings": -59387.87283,
    "out:El kr sold": 23018.49752,
    "out:El kr saved": 56227.50996,
    "out:El kr return": 79246.00749,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 146006.8154,
    "out:% savings (space heating)": 51.067344,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 40.81,
    "out:Etvv": 0,
    "out:Ef": 28.42,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4778750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.926979,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.199398,
    "out:Electricity (inc PV)": 28.05645,
    "out:Total Energy Use Pre PV": 92.199398,
    "out:Total Energy Use Post PV": 79.05645,
    "out:Primary Energy": 80.108054,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.520069,
    "out:CO2 Operational/m2": -9.991283,
    "out:Total CO2/m2": 24.528786,
    "out:Total CO2 (tons)": 70127.7915,
    "out:Klimatpaverkan":0.001* -88306.99832,
    "out:Initial Cost/MSEK": 4.843875,
    "out:Running cost/(Apt SEK y)": 14410.62857,
    "out:Running Cost over RSP/MSEK": 3.94497,
    "out:LCP/MSEK": 0.522825,
    "out:ROI% old": 15.827647,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 107.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 51,
    "out:El bought/kWh/m2": 2104.167138,
    "out:Return %": 14,
    "out:Return/kSEK/y": 686,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.699898,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18434.51226,
    "out:PV (% sold (El))": 36.055096,
    "out:PV (kWh self-consumed)": 32694.21714,
    "out:PV (ratio sold/self-consumed)": 0.563846,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 276291.7505,
    "out:EL kWh savings": -33897.58684,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217303.6,
    "out:DH kr savings": 251425.4929,
    "out:El kr savings": -57625.89763,
    "out:El kr sold": 23504.00314,
    "out:El kr saved": 55580.16914,
    "out:El kr return": 79084.17228,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 145600.217,
    "out:% savings (space heating)": 51.203611,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 42.29,
    "out:Etvv": 0,
    "out:Ef": 28.06,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4843875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 47.333223,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.143558,
    "out:Electricity (inc PV)": 27.987896,
    "out:Total Energy Use Pre PV": 88.143558,
    "out:Total Energy Use Post PV": 74.987896,
    "out:Primary Energy": 77.532926,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.520069,
    "out:CO2 Operational/m2": -11.48682,
    "out:Total CO2/m2": 23.033249,
    "out:Total CO2 (tons)": 65852.05168,
    "out:Klimatpaverkan":0.001* -92582.73813,
    "out:Initial Cost/MSEK": 4.915375,
    "out:Running cost/(Apt SEK y)": 13544.48571,
    "out:Running Cost over RSP/MSEK": 3.70775,
    "out:LCP/MSEK": 0.688545,
    "out:ROI% old": 16.286855,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 118.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2100.905578,
    "out:Return %": 15,
    "out:Return/kSEK/y": 717,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.644058,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18462.08205,
    "out:PV (% sold (El))": 36.109018,
    "out:PV (kWh self-consumed)": 32666.64736,
    "out:PV (ratio sold/self-consumed)": 0.565166,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 286566.2977,
    "out:EL kWh savings": -33701.59097,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227021.78,
    "out:DH kr savings": 260775.3309,
    "out:El kr savings": -57292.70466,
    "out:El kr sold": 23539.15461,
    "out:El kr saved": 55533.30051,
    "out:El kr return": 79072.45512,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 135325.6697,
    "out:% savings (space heating)": 54.647018,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 38.79,
    "out:Etvv": 0,
    "out:Ef": 27.99,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4915375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.691337,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.91929,
    "out:Electricity (inc PV)": 38.952772,
    "out:Total Energy Use Pre PV": 90.91929,
    "out:Total Energy Use Post PV": 88.952772,
    "out:Primary Energy": 99.249582,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.360305,
    "out:CO2 Operational/m2": 23.400874,
    "out:Total CO2/m2": 37.761179,
    "out:Total CO2 (tons)": 107959.1989,
    "out:Klimatpaverkan":0.001* -50475.59087,
    "out:Initial Cost/MSEK": 3.848125,
    "out:Running cost/(Apt SEK y)": 16416.6,
    "out:Running Cost over RSP/MSEK": 4.47769,
    "out:LCP/MSEK": 0.985855,
    "out:ROI% old": 17.945586,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 84.3,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2864.3503,
    "out:Return %": 16,
    "out:Return/kSEK/y": 616,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.41979,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 279824.4506,
    "out:EL kWh savings": -65050.16803,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144054.96,
    "out:DH kr savings": 254640.25,
    "out:El kr savings": -110585.2856,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 142067.5169,
    "out:% savings (space heating)": 52.387559,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 41.62,
    "out:Etvv": 0,
    "out:Ef": 38.95,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3848125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.108475,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.860148,
    "out:Electricity (inc PV)": 38.866621,
    "out:Total Energy Use Pre PV": 86.860148,
    "out:Total Energy Use Post PV": 84.866621,
    "out:Primary Energy": 96.648897,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.360305,
    "out:CO2 Operational/m2": 21.95313,
    "out:Total CO2/m2": 36.313435,
    "out:Total CO2 (tons)": 103820.0993,
    "out:Klimatpaverkan":0.001* -54614.69051,
    "out:Initial Cost/MSEK": 3.919625,
    "out:Running cost/(Apt SEK y)": 15549.65714,
    "out:Running Cost over RSP/MSEK": 4.24026,
    "out:LCP/MSEK": 1.151785,
    "out:ROI% old": 18.483583,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 92.9,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2860.21036,
    "out:Return %": 16,
    "out:Return/kSEK/y": 647,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.360648,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 290067.8519,
    "out:EL kWh savings": -64803.86234,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153795.18,
    "out:DH kr savings": 263961.7452,
    "out:El kr savings": -110166.566,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 131824.1156,
    "out:% savings (space heating)": 55.820527,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 38.13,
    "out:Etvv": 0,
    "out:Ef": 38.87,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3919625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.766156,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.234799,
    "out:Electricity (inc PV)": 38.529847,
    "out:Total Energy Use Pre PV": 87.234799,
    "out:Total Energy Use Post PV": 85.529847,
    "out:Primary Energy": 97.365274,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.754849,
    "out:CO2 Operational/m2": 22.230563,
    "out:Total CO2/m2": 36.985412,
    "out:Total CO2 (tons)": 105741.2813,
    "out:Klimatpaverkan":0.001* -52693.50848,
    "out:Initial Cost/MSEK": 3.98475,
    "out:Running cost/(Apt SEK y)": 15677.48571,
    "out:Running Cost over RSP/MSEK": 4.27556,
    "out:LCP/MSEK": 1.05136,
    "out:ROI% old": 18.054942,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 90.7,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2816.43593,
    "out:Return %": 16,
    "out:Return/kSEK/y": 642,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.735299,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288187.5421,
    "out:EL kWh savings": -63841.02558,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 153720.92,
    "out:DH kr savings": 262250.6633,
    "out:El kr savings": -108529.7435,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133704.4254,
    "out:% savings (space heating)": 55.190361,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 40.02,
    "out:Etvv": 0,
    "out:Ef": 38.53,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3984750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.210796,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.167323,
    "out:Electricity (inc PV)": 38.435898,
    "out:Total Energy Use Pre PV": 83.167323,
    "out:Total Energy Use Post PV": 81.435898,
    "out:Primary Energy": 94.768566,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.754849,
    "out:CO2 Operational/m2": 20.78172,
    "out:Total CO2/m2": 35.536569,
    "out:Total CO2 (tons)": 101599.0396,
    "out:Klimatpaverkan":0.001* -56835.75017,
    "out:Initial Cost/MSEK": 4.05625,
    "out:Running cost/(Apt SEK y)": 14809.37143,
    "out:Running Cost over RSP/MSEK": 4.03781,
    "out:LCP/MSEK": 1.21761,
    "out:ROI% old": 18.574017,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 102.5,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2811.71261,
    "out:Return %": 17,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.667823,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 298352.3152,
    "out:EL kWh savings": -63572.42542,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163427.48,
    "out:DH kr savings": 271500.6069,
    "out:El kr savings": -108073.1232,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 123539.6522,
    "out:% savings (space heating)": 58.596979,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 36.55,
    "out:Etvv": 0,
    "out:Ef": 38.44,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4056250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.691337,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.91929,
    "out:Electricity (inc PV)": 30.93948,
    "out:Total Energy Use Pre PV": 90.91929,
    "out:Total Energy Use Post PV": 80.93948,
    "out:Primary Energy": 84.825656,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.242915,
    "out:CO2 Operational/m2": 18.598044,
    "out:Total CO2/m2": 42.840959,
    "out:Total CO2 (tons)": 122482.2884,
    "out:Klimatpaverkan":0.001* -35952.50144,
    "out:Initial Cost/MSEK": 4.323875,
    "out:Running cost/(Apt SEK y)": 15201.97143,
    "out:Running Cost over RSP/MSEK": 4.15421,
    "out:LCP/MSEK": 0.833585,
    "out:ROI% old": 17.039808,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 102.5,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2318.180612,
    "out:Return %": 15,
    "out:Return/kSEK/y": 659,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.41979,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2230.022778,
    "out:PV (% sold (El))": 8.723169,
    "out:PV (kWh self-consumed)": 23334.34184,
    "out:PV (ratio sold/self-consumed)": 0.095568,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 279824.4506,
    "out:EL kWh savings": -42140.16871,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185845.24,
    "out:DH kr savings": 254640.25,
    "out:El kr savings": -71638.2868,
    "out:El kr sold": 2843.279042,
    "out:El kr saved": 39668.38112,
    "out:El kr return": 42511.66016,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 142067.5169,
    "out:% savings (space heating)": 52.387559,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 41.62,
    "out:Etvv": 0,
    "out:Ef": 30.94,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4323875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.108475,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.860148,
    "out:Electricity (inc PV)": 30.860205,
    "out:Total Energy Use Pre PV": 86.860148,
    "out:Total Energy Use Post PV": 76.860205,
    "out:Primary Energy": 82.237348,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.242915,
    "out:CO2 Operational/m2": 17.129575,
    "out:Total CO2/m2": 41.37249,
    "out:Total CO2 (tons)": 118283.936,
    "out:Klimatpaverkan":0.001* -40150.85385,
    "out:Initial Cost/MSEK": 4.395375,
    "out:Running cost/(Apt SEK y)": 14335.17143,
    "out:Running Cost over RSP/MSEK": 3.91681,
    "out:LCP/MSEK": 0.999485,
    "out:ROI% old": 17.53421,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 113,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2314.292752,
    "out:Return %": 16,
    "out:Return/kSEK/y": 689,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.360648,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2242.193768,
    "out:PV (% sold (El))": 8.770778,
    "out:PV (kWh self-consumed)": 23322.17085,
    "out:PV (ratio sold/self-consumed)": 0.09614,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 290067.8519,
    "out:EL kWh savings": -41913.52151,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195567.56,
    "out:DH kr savings": 263961.7452,
    "out:El kr savings": -71252.98656,
    "out:El kr sold": 2858.797054,
    "out:El kr saved": 39647.69044,
    "out:El kr return": 42506.48749,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 131824.1156,
    "out:% savings (space heating)": 55.820527,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 38.13,
    "out:Etvv": 0,
    "out:Ef": 30.86,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4395375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.766156,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.234799,
    "out:Electricity (inc PV)": 30.550604,
    "out:Total Energy Use Pre PV": 87.234799,
    "out:Total Energy Use Post PV": 77.550604,
    "out:Primary Energy": 83.002636,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.637459,
    "out:CO2 Operational/m2": 17.183711,
    "out:Total CO2/m2": 41.82117,
    "out:Total CO2 (tons)": 119566.7119,
    "out:Klimatpaverkan":0.001* -38868.07787,
    "out:Initial Cost/MSEK": 4.4605,
    "out:Running cost/(Apt SEK y)": 14464.6,
    "out:Running Cost over RSP/MSEK": 3.95254,
    "out:LCP/MSEK": 0.89863,
    "out:ROI% old": 17.163771,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 110.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2273.243569,
    "out:Return %": 15,
    "out:Return/kSEK/y": 685,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.735299,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2372.950231,
    "out:PV (% sold (El))": 9.282258,
    "out:PV (kWh self-consumed)": 23191.41438,
    "out:PV (ratio sold/self-consumed)": 0.10232,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 288187.5421,
    "out:EL kWh savings": -41028.37234,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195527.94,
    "out:DH kr savings": 262250.6633,
    "out:El kr savings": -69748.23299,
    "out:El kr sold": 3025.511544,
    "out:El kr saved": 39425.40445,
    "out:El kr return": 42450.916,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133704.4254,
    "out:% savings (space heating)": 55.190361,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 40.02,
    "out:Etvv": 0,
    "out:Ef": 30.55,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4460500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.210796,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.167323,
    "out:Electricity (inc PV)": 30.464342,
    "out:Total Energy Use Pre PV": 83.167323,
    "out:Total Energy Use Post PV": 73.464342,
    "out:Primary Energy": 80.419766,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.637459,
    "out:CO2 Operational/m2": 15.710347,
    "out:Total CO2/m2": 40.347806,
    "out:Total CO2 (tons)": 115354.3647,
    "out:Klimatpaverkan":0.001* -43080.42509,
    "out:Initial Cost/MSEK": 4.532,
    "out:Running cost/(Apt SEK y)": 13596.65714,
    "out:Running Cost over RSP/MSEK": 3.71483,
    "out:LCP/MSEK": 1.06484,
    "out:ROI% old": 17.64229,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 124.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2268.820852,
    "out:Return %": 16,
    "out:Return/kSEK/y": 715,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.667823,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2387.283054,
    "out:PV (% sold (El))": 9.338323,
    "out:PV (kWh self-consumed)": 23177.08156,
    "out:PV (ratio sold/self-consumed)": 0.103002,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 298352.3152,
    "out:EL kWh savings": -40781.74931,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205215.42,
    "out:DH kr savings": 271500.6069,
    "out:El kr savings": -69328.97383,
    "out:El kr sold": 3043.785894,
    "out:El kr saved": 39401.03865,
    "out:El kr return": 42444.82455,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 123539.6522,
    "out:% savings (space heating)": 58.596979,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 36.55,
    "out:Etvv": 0,
    "out:Ef": 30.46,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4532000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 49.691337,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.91929,
    "out:Electricity (inc PV)": 27.913228,
    "out:Total Energy Use Pre PV": 90.91929,
    "out:Total Energy Use Post PV": 77.913228,
    "out:Primary Energy": 79.378402,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.125525,
    "out:CO2 Operational/m2": -10.630675,
    "out:Total CO2/m2": 23.49485,
    "out:Total CO2 (tons)": 67171.7688,
    "out:Klimatpaverkan":0.001* -91263.02102,
    "out:Initial Cost/MSEK": 4.799625,
    "out:Running cost/(Apt SEK y)": 14158.74286,
    "out:Running Cost over RSP/MSEK": 3.8761,
    "out:LCP/MSEK": 0.635945,
    "out:ROI% old": 16.178556,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 110.3,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 50,
    "out:El bought/kWh/m2": 2087.811821,
    "out:Return %": 14,
    "out:Return/kSEK/y": 695,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.41979,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18573.39468,
    "out:PV (% sold (El))": 36.326728,
    "out:PV (kWh self-consumed)": 32555.33473,
    "out:PV (ratio sold/self-consumed)": 0.570518,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 279824.4506,
    "out:EL kWh savings": -33488.11519,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221391.53,
    "out:DH kr savings": 254640.25,
    "out:El kr savings": -56929.79582,
    "out:El kr sold": 23681.07821,
    "out:El kr saved": 55344.06904,
    "out:El kr return": 79025.14725,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 142067.5169,
    "out:% savings (space heating)": 52.387559,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 41.62,
    "out:Etvv": 0,
    "out:Ef": 27.91,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4799625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.108475,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.860148,
    "out:Electricity (inc PV)": 27.842576,
    "out:Total Energy Use Pre PV": 86.860148,
    "out:Total Energy Use Post PV": 73.842576,
    "out:Primary Energy": 76.805616,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.125525,
    "out:CO2 Operational/m2": -12.129753,
    "out:Total CO2/m2": 21.995772,
    "out:Total CO2 (tons)": 62885.90526,
    "out:Klimatpaverkan":0.001* -95548.88455,
    "out:Initial Cost/MSEK": 4.871125,
    "out:Running cost/(Apt SEK y)": 13292.14286,
    "out:Running Cost over RSP/MSEK": 3.63876,
    "out:LCP/MSEK": 0.801785,
    "out:ROI% old": 16.637136,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 121.6,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 46,
    "out:El bought/kWh/m2": 2084.36033,
    "out:Return %": 15,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.360648,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18602.79844,
    "out:PV (% sold (El))": 36.384238,
    "out:PV (kWh self-consumed)": 32525.93097,
    "out:PV (ratio sold/self-consumed)": 0.571937,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 290067.8519,
    "out:EL kWh savings": -33286.12114,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231093.91,
    "out:DH kr savings": 263961.7452,
    "out:El kr savings": -56586.40594,
    "out:El kr sold": 23718.568,
    "out:El kr saved": 55294.08265,
    "out:El kr return": 79012.65066,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 131824.1156,
    "out:% savings (space heating)": 55.820527,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 38.13,
    "out:Etvv": 0,
    "out:Ef": 27.84,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4871125,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 46.766156,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.234799,
    "out:Electricity (inc PV)": 27.56682,
    "out:Total Energy Use Pre PV": 87.234799,
    "out:Total Energy Use Post PV": 74.56682,
    "out:Primary Energy": 77.631825,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.520069,
    "out:CO2 Operational/m2": -12.398707,
    "out:Total CO2/m2": 22.121362,
    "out:Total CO2 (tons)": 63244.96703,
    "out:Klimatpaverkan":0.001* -95189.82278,
    "out:Initial Cost/MSEK": 4.93625,
    "out:Running cost/(Apt SEK y)": 13423.8,
    "out:Running Cost over RSP/MSEK": 3.67508,
    "out:LCP/MSEK": 0.70034,
    "out:ROI% old": 16.312528,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 118.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 47,
    "out:El bought/kWh/m2": 2047.906085,
    "out:Return %": 15,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.735299,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18916.00833,
    "out:PV (% sold (El))": 36.996828,
    "out:PV (kWh self-consumed)": 32212.72108,
    "out:PV (ratio sold/self-consumed)": 0.587222,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 288187.5421,
    "out:EL kWh savings": -32497.73482,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231122.42,
    "out:DH kr savings": 262250.6633,
    "out:El kr savings": -55246.1492,
    "out:El kr sold": 24117.91062,
    "out:El kr saved": 54761.62583,
    "out:El kr return": 78879.53645,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133704.4254,
    "out:% savings (space heating)": 55.190361,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 40.02,
    "out:Etvv": 0,
    "out:Ef": 27.57,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4936250,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.210796,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.167323,
    "out:Electricity (inc PV)": 27.489976,
    "out:Total Energy Use Pre PV": 83.167323,
    "out:Total Energy Use Post PV": 70.489976,
    "out:Primary Energy": 75.065907,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.520069,
    "out:CO2 Operational/m2": -13.906874,
    "out:Total CO2/m2": 20.613195,
    "out:Total CO2 (tons)": 58933.11805,
    "out:Klimatpaverkan":0.001* -99501.67176,
    "out:Initial Cost/MSEK": 5.00775,
    "out:Running cost/(Apt SEK y)": 12556.08571,
    "out:Running Cost over RSP/MSEK": 3.43744,
    "out:LCP/MSEK": 0.86648,
    "out:ROI% old": 16.75754,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 134.3,
    "out:Op energy cost/MSEK": 25,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 43,
    "out:El bought/kWh/m2": 2043.976981,
    "out:Return %": 15,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.667823,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18949.98848,
    "out:PV (% sold (El))": 37.063288,
    "out:PV (kWh self-consumed)": 32178.74093,
    "out:PV (ratio sold/self-consumed)": 0.588898,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 298352.3152,
    "out:EL kWh savings": -32278.03785,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 240789.18,
    "out:DH kr savings": 271500.6069,
    "out:El kr savings": -54872.66435,
    "out:El kr sold": 24161.23531,
    "out:El kr saved": 54703.85958,
    "out:El kr return": 78865.09489,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 123539.6522,
    "out:% savings (space heating)": 58.596979,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 36.55,
    "out:Etvv": 0,
    "out:Ef": 27.49,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5007750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.421937,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.831229,
    "out:Electricity (inc PV)": 50.779165,
    "out:Total Energy Use Pre PV": 66.831229,
    "out:Total Energy Use Post PV": 62.779165,
    "out:Primary Energy": 96.71812,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.864922,
    "out:CO2 Operational/m2": 11.557969,
    "out:Total CO2/m2": 26.422891,
    "out:Total CO2 (tons)": 75543.0371,
    "out:Klimatpaverkan":0.001* -82891.75271,
    "out:Initial Cost/MSEK": 5.08175,
    "out:Running cost/(Apt SEK y)": 10190.4,
    "out:Running Cost over RSP/MSEK": 2.76588,
    "out:LCP/MSEK": 1.46404,
    "out:ROI% old": 18.401394,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 160.3,
    "out:Op energy cost/MSEK": 19,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3838.18603,
    "out:Return %": 16,
    "out:Return/kSEK/y": 834,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.331729,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 386377.6535,
    "out:EL kWh savings": -98861.82191,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183538.57,
    "out:DH kr savings": 351603.6647,
    "out:El kr savings": -168065.0972,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 35514.314,
    "out:% savings (space heating)": 88.097749,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 7.59,
    "out:Etvv": 0,
    "out:Ef": 50.78,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5081750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.087455,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.39379,
    "out:Electricity (inc PV)": 50.180045,
    "out:Total Energy Use Pre PV": 64.39379,
    "out:Total Energy Use Post PV": 60.180045,
    "out:Primary Energy": 94.379052,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.864922,
    "out:CO2 Operational/m2": 10.780255,
    "out:Total CO2/m2": 25.645177,
    "out:Total CO2 (tons)": 73319.55302,
    "out:Klimatpaverkan":0.001* -85115.2368,
    "out:Initial Cost/MSEK": 5.15325,
    "out:Running cost/(Apt SEK y)": 9700.285714,
    "out:Running Cost over RSP/MSEK": 2.63183,
    "out:LCP/MSEK": 1.52659,
    "out:ROI% old": 18.51769,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 173.3,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3807.5653,
    "out:Return %": 17,
    "out:Return/kSEK/y": 851,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.89429,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 393051.9368,
    "out:EL kWh savings": -97148.93802,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192524.07,
    "out:DH kr savings": 357677.2625,
    "out:El kr savings": -165153.1946,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 28840.03069,
    "out:% savings (space heating)": 90.334566,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 5.79,
    "out:Etvv": 0,
    "out:Ef": 50.18,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5153250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.548867,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.43519,
    "out:Electricity (inc PV)": 49.891863,
    "out:Total Energy Use Pre PV": 64.43519,
    "out:Total Energy Use Post PV": 60.891863,
    "out:Primary Energy": 94.545645,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.259466,
    "out:CO2 Operational/m2": 11.013697,
    "out:Total CO2/m2": 26.273163,
    "out:Total CO2 (tons)": 75114.96479,
    "out:Klimatpaverkan":0.001* -83319.82502,
    "out:Initial Cost/MSEK": 5.218375,
    "out:Running cost/(Apt SEK y)": 9781.857143,
    "out:Running Cost over RSP/MSEK": 2.65462,
    "out:LCP/MSEK": 1.438675,
    "out:ROI% old": 18.224201,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 168.9,
    "out:Op energy cost/MSEK": 18,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3740.4633,
    "out:Return %": 16,
    "out:Return/kSEK/y": 848,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.93569,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 391732.76,
    "out:EL kWh savings": -96325.02577,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192724.27,
    "out:DH kr savings": 356476.8116,
    "out:El kr savings": -163752.5438,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 30159.20745,
    "out:% savings (space heating)": 89.892457,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 6.77,
    "out:Etvv": 0,
    "out:Ef": 49.89,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5218375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.385933,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.945767,
    "out:Electricity (inc PV)": 49.245897,
    "out:Total Energy Use Pre PV": 60.945767,
    "out:Total Energy Use Post PV": 57.245897,
    "out:Primary Energy": 92.230467,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.259466,
    "out:CO2 Operational/m2": 9.869137,
    "out:Total CO2/m2": 25.128603,
    "out:Total CO2 (tons)": 71842.66811,
    "out:Klimatpaverkan":0.001* -86592.1217,
    "out:Initial Cost/MSEK": 5.289875,
    "out:Running cost/(Apt SEK y)": 9069.828571,
    "out:Running Cost over RSP/MSEK": 2.45982,
    "out:LCP/MSEK": 1.561975,
    "out:ROI% old": 18.503948,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3706.20369,
    "out:Return %": 17,
    "out:Return/kSEK/y": 873,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.446267,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 397916.5877,
    "out:EL kWh savings": -94478.20918,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201491.14,
    "out:DH kr savings": 362104.0948,
    "out:El kr savings": -160612.9556,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 23975.37982,
    "out:% savings (space heating)": 91.964902,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 5.13,
    "out:Etvv": 0,
    "out:Ef": 49.25,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5289875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.421937,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.831229,
    "out:Electricity (inc PV)": 42.087908,
    "out:Total Energy Use Pre PV": 66.831229,
    "out:Total Energy Use Post PV": 54.087908,
    "out:Primary Energy": 81.073857,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.747532,
    "out:CO2 Operational/m2": 9.87244,
    "out:Total CO2/m2": 34.619972,
    "out:Total CO2 (tons)": 98978.48911,
    "out:Klimatpaverkan":0.001* -59456.3007,
    "out:Initial Cost/MSEK": 5.5575,
    "out:Running cost/(Apt SEK y)": 8953.142857,
    "out:Running Cost over RSP/MSEK": 2.43662,
    "out:LCP/MSEK": 1.31755,
    "out:ROI% old": 17.672515,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 54,
    "out:Energy use kWh/m2": 54,
    "out:Energy savings %": 203.7,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 3256.67189,
    "out:Return %": 16,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.331729,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 363.452268,
    "out:PV (% sold (El))": 1.421714,
    "out:PV (kWh self-consumed)": 25200.91235,
    "out:PV (ratio sold/self-consumed)": 0.014422,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 386377.6535,
    "out:EL kWh savings": -74013.52087,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 226244.08,
    "out:DH kr savings": 351603.6647,
    "out:El kr savings": -125822.9855,
    "out:El kr sold": 463.4016423,
    "out:El kr saved": 42841.55099,
    "out:El kr return": 43304.95263,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 35514.314,
    "out:% savings (space heating)": 88.097749,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 7.59,
    "out:Etvv": 0,
    "out:Ef": 42.09,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5557500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.087455,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.39379,
    "out:Electricity (inc PV)": 41.511168,
    "out:Total Energy Use Pre PV": 64.39379,
    "out:Total Energy Use Post PV": 51.511168,
    "out:Primary Energy": 78.775073,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.747532,
    "out:CO2 Operational/m2": 9.042197,
    "out:Total CO2/m2": 33.789729,
    "out:Total CO2 (tons)": 96604.82464,
    "out:Klimatpaverkan":0.001* -61829.96518,
    "out:Initial Cost/MSEK": 5.629,
    "out:Running cost/(Apt SEK y)": 8463.4,
    "out:Running Cost over RSP/MSEK": 2.30267,
    "out:LCP/MSEK": 1.38,
    "out:ROI% old": 17.787986,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 215.4,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3226.521031,
    "out:Return %": 16,
    "out:Return/kSEK/y": 895,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.89429,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 396.152889,
    "out:PV (% sold (El))": 1.549629,
    "out:PV (kWh self-consumed)": 25168.21173,
    "out:PV (ratio sold/self-consumed)": 0.01574,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 393051.9368,
    "out:EL kWh savings": -72364.62139,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 235162.5,
    "out:DH kr savings": 357677.2625,
    "out:El kr savings": -123019.8564,
    "out:El kr sold": 505.0949337,
    "out:El kr saved": 42785.95993,
    "out:El kr return": 43291.05487,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 28840.03069,
    "out:% savings (space heating)": 90.334566,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 5.79,
    "out:Etvv": 0,
    "out:Ef": 41.51,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5629000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.548867,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.43519,
    "out:Electricity (inc PV)": 41.234267,
    "out:Total Energy Use Pre PV": 64.43519,
    "out:Total Energy Use Post PV": 52.234267,
    "out:Primary Energy": 78.961973,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.142076,
    "out:CO2 Operational/m2": 9.151827,
    "out:Total CO2/m2": 34.293903,
    "out:Total CO2 (tons)": 98046.25794,
    "out:Klimatpaverkan":0.001* -60388.53187,
    "out:Initial Cost/MSEK": 5.694125,
    "out:Running cost/(Apt SEK y)": 8545.885714,
    "out:Running Cost over RSP/MSEK": 2.32568,
    "out:LCP/MSEK": 1.291865,
    "out:ROI% old": 17.526812,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 52,
    "out:Energy use kWh/m2": 52,
    "out:Energy savings %": 215.4,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 3160.580321,
    "out:Return %": 16,
    "out:Return/kSEK/y": 892,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.93569,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 471.739297,
    "out:PV (% sold (El))": 1.8453,
    "out:PV (kWh self-consumed)": 25092.62532,
    "out:PV (ratio sold/self-consumed)": 0.0188,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 391732.76,
    "out:EL kWh savings": -71572.96152,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 235404.24,
    "out:DH kr savings": 356476.8116,
    "out:El kr savings": -121674.0346,
    "out:El kr sold": 601.4676038,
    "out:El kr saved": 42657.46304,
    "out:El kr return": 43258.93064,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 30159.20745,
    "out:% savings (space heating)": 89.892457,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 6.77,
    "out:Etvv": 0,
    "out:Ef": 41.23,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5694125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.385933,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.945767,
    "out:Electricity (inc PV)": 40.614658,
    "out:Total Energy Use Pre PV": 60.945767,
    "out:Total Energy Use Post PV": 48.614658,
    "out:Primary Energy": 76.694236,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.142076,
    "out:CO2 Operational/m2": 7.939951,
    "out:Total CO2/m2": 33.082027,
    "out:Total CO2 (tons)": 94581.50484,
    "out:Klimatpaverkan":0.001* -63853.28498,
    "out:Initial Cost/MSEK": 5.765625,
    "out:Running cost/(Apt SEK y)": 7834.371429,
    "out:Running Cost over RSP/MSEK": 2.13101,
    "out:LCP/MSEK": 1.415035,
    "out:ROI% old": 17.791802,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 234.7,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3126.985828,
    "out:Return %": 16,
    "out:Return/kSEK/y": 917,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.446267,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 512.37095,
    "out:PV (% sold (El))": 2.004239,
    "out:PV (kWh self-consumed)": 25051.99366,
    "out:PV (ratio sold/self-consumed)": 0.020452,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 397916.5877,
    "out:EL kWh savings": -69801.49958,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 244094.82,
    "out:DH kr savings": 362104.0948,
    "out:El kr savings": -118662.5493,
    "out:El kr sold": 653.2729615,
    "out:El kr saved": 42588.38923,
    "out:El kr return": 43241.66219,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 23975.37982,
    "out:% savings (space heating)": 91.964902,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 5.13,
    "out:Etvv": 0,
    "out:Ef": 40.61,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5765625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 12.421937,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.831229,
    "out:Electricity (inc PV)": 37.80265,
    "out:Total Energy Use Pre PV": 66.831229,
    "out:Total Energy Use Post PV": 49.80265,
    "out:Primary Energy": 73.360393,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.630142,
    "out:CO2 Operational/m2": -11.699942,
    "out:Total CO2/m2": 22.9302,
    "out:Total CO2 (tons)": 65557.43462,
    "out:Klimatpaverkan":0.001* -92877.35519,
    "out:Initial Cost/MSEK": 6.03325,
    "out:Running cost/(Apt SEK y)": 7857.457143,
    "out:Running Cost over RSP/MSEK": 2.14427,
    "out:LCP/MSEK": 1.13415,
    "out:ROI% old": 16.971189,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 228,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 12,
    "out:El bought/kWh/m2": 2919.257875,
    "out:Return %": 15,
    "out:Return/kSEK/y": 916,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 36.331729,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12389.09452,
    "out:PV (% sold (El))": 24.23118,
    "out:PV (kWh self-consumed)": 38739.63489,
    "out:PV (ratio sold/self-consumed)": 0.319804,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 386377.6535,
    "out:EL kWh savings": -61761.96959,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262404.41,
    "out:DH kr savings": 351603.6647,
    "out:El kr savings": -104995.3483,
    "out:El kr sold": 15796.09552,
    "out:El kr saved": 65857.3793,
    "out:El kr return": 81653.47482,
    "out:% solar/total": 84,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 35514.314,
    "out:% savings (space heating)": 88.097749,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 7.59,
    "out:Etvv": 0,
    "out:Ef": 37.8,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6033250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.087455,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.39379,
    "out:Electricity (inc PV)": 37.293088,
    "out:Total Energy Use Pre PV": 64.39379,
    "out:Total Energy Use Post PV": 47.293088,
    "out:Primary Energy": 71.182529,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.630142,
    "out:CO2 Operational/m2": -12.77284,
    "out:Total CO2/m2": 21.857302,
    "out:Total CO2 (tons)": 62490.01958,
    "out:Klimatpaverkan":0.001* -95944.77024,
    "out:Initial Cost/MSEK": 6.10475,
    "out:Running cost/(Apt SEK y)": 7369.4,
    "out:Running Cost over RSP/MSEK": 2.01077,
    "out:LCP/MSEK": 1.19615,
    "out:ROI% old": 17.084823,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 248.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2892.511641,
    "out:Return %": 15,
    "out:Return/kSEK/y": 933,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.89429,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12559.19393,
    "out:PV (% sold (El))": 24.563869,
    "out:PV (kWh self-consumed)": 38569.53548,
    "out:PV (ratio sold/self-consumed)": 0.325625,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 393051.9368,
    "out:EL kWh savings": -60305.13199,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 271171.51,
    "out:DH kr savings": 357677.2625,
    "out:El kr savings": -102518.7244,
    "out:El kr sold": 16012.97226,
    "out:El kr saved": 65568.21032,
    "out:El kr return": 81581.18257,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 28840.03069,
    "out:% savings (space heating)": 90.334566,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 5.79,
    "out:Etvv": 0,
    "out:Ef": 37.29,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6104750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 10.548867,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.43519,
    "out:Electricity (inc PV)": 37.048338,
    "out:Total Energy Use Pre PV": 64.43519,
    "out:Total Energy Use Post PV": 48.048338,
    "out:Primary Energy": 71.4273,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.024686,
    "out:CO2 Operational/m2": -13.195459,
    "out:Total CO2/m2": 21.829227,
    "out:Total CO2 (tons)": 62409.75316,
    "out:Klimatpaverkan":0.001* -96025.03665,
    "out:Initial Cost/MSEK": 6.169875,
    "out:Running cost/(Apt SEK y)": 7455.571429,
    "out:Running Cost over RSP/MSEK": 2.03477,
    "out:LCP/MSEK": 1.107025,
    "out:ROI% old": 16.848917,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 241.7,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 11,
    "out:El bought/kWh/m2": 2834.019579,
    "out:Return %": 15,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.93569,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12937.34819,
    "out:PV (% sold (El))": 25.303481,
    "out:PV (kWh self-consumed)": 38191.38122,
    "out:PV (ratio sold/self-consumed)": 0.33875,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 391732.76,
    "out:EL kWh savings": -59605.39182,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 271642.76,
    "out:DH kr savings": 356476.8116,
    "out:El kr savings": -101329.1661,
    "out:El kr sold": 16495.11894,
    "out:El kr saved": 64925.34808,
    "out:El kr return": 81420.46701,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 30159.20745,
    "out:% savings (space heating)": 89.892457,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 6.77,
    "out:Etvv": 0,
    "out:Ef": 37.05,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6169875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.385933,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.945767,
    "out:Electricity (inc PV)": 36.500395,
    "out:Total Energy Use Pre PV": 60.945767,
    "out:Total Energy Use Post PV": 44.500395,
    "out:Primary Energy": 69.288563,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.024686,
    "out:CO2 Operational/m2": -14.680142,
    "out:Total CO2/m2": 20.344544,
    "out:Total CO2 (tons)": 58165.04493,
    "out:Klimatpaverkan":0.001* -100269.7449,
    "out:Initial Cost/MSEK": 6.241375,
    "out:Running cost/(Apt SEK y)": 6745.914286,
    "out:Running Cost over RSP/MSEK": 1.8406,
    "out:LCP/MSEK": 1.229695,
    "out:ROI% old": 17.10033,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 264.4,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2804.220287,
    "out:Return %": 15,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.446267,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 13133.45568,
    "out:PV (% sold (El))": 25.687037,
    "out:PV (kWh self-consumed)": 37995.27373,
    "out:PV (ratio sold/self-consumed)": 0.34566,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 397916.5877,
    "out:EL kWh savings": -58038.82295,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280183.25,
    "out:DH kr savings": 362104.0948,
    "out:El kr savings": -98665.99902,
    "out:El kr sold": 16745.15599,
    "out:El kr saved": 64591.96533,
    "out:El kr return": 81337.12133,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 23975.37982,
    "out:% savings (space heating)": 91.964902,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 5.13,
    "out:Etvv": 0,
    "out:Ef": 36.5,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6241375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.962707,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.958268,
    "out:Electricity (inc PV)": 49.590948,
    "out:Total Energy Use Pre PV": 62.958268,
    "out:Total Energy Use Post PV": 59.590948,
    "out:Primary Energy": 93.824112,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.864922,
    "out:CO2 Operational/m2": 10.590755,
    "out:Total CO2/m2": 25.455677,
    "out:Total CO2 (tons)": 72777.77258,
    "out:Klimatpaverkan":0.001* -85657.01724,
    "out:Initial Cost/MSEK": 5.174125,
    "out:Running cost/(Apt SEK y)": 9500.942857,
    "out:Running Cost over RSP/MSEK": 2.57789,
    "out:LCP/MSEK": 1.559655,
    "out:ROI% old": 18.591908,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 173.3,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3707.07876,
    "out:Return %": 17,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.458768,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 393408.5913,
    "out:EL kWh savings": -95464.70988,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195711.81,
    "out:DH kr savings": 358001.8181,
    "out:El kr savings": -162290.0068,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 28483.3762,
    "out:% savings (space heating)": 90.454095,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 6.51,
    "out:Etvv": 0,
    "out:Ef": 49.59,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5174125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.858204,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.451139,
    "out:Electricity (inc PV)": 48.929154,
    "out:Total Energy Use Pre PV": 60.451139,
    "out:Total Energy Use Post PV": 56.929154,
    "out:Primary Energy": 91.517005,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.864922,
    "out:CO2 Operational/m2": 9.803842,
    "out:Total CO2/m2": 24.668764,
    "out:Total CO2 (tons)": 70527.98856,
    "out:Klimatpaverkan":0.001* -87906.80126,
    "out:Initial Cost/MSEK": 5.245625,
    "out:Running cost/(Apt SEK y)": 9001.142857,
    "out:Running Cost over RSP/MSEK": 2.44123,
    "out:LCP/MSEK": 1.624815,
    "out:ROI% old": 18.710667,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 57,
    "out:Energy use kWh/m2": 57,
    "out:Energy savings %": 187.7,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3671.57973,
    "out:Return %": 17,
    "out:Return/kSEK/y": 876,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.951639,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 399425.3647,
    "out:EL kWh savings": -93572.64104,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204403.59,
    "out:DH kr savings": 363477.0819,
    "out:El kr savings": -159073.4898,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 22466.60278,
    "out:% savings (space heating)": 92.470553,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.92,
    "out:Etvv": 0,
    "out:Ef": 48.93,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5245625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.630557,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.791041,
    "out:Electricity (inc PV)": 48.857772,
    "out:Total Energy Use Pre PV": 60.791041,
    "out:Total Energy Use Post PV": 57.857772,
    "out:Primary Energy": 92.098032,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.259466,
    "out:CO2 Operational/m2": 10.076688,
    "out:Total CO2/m2": 25.336154,
    "out:Total CO2 (tons)": 72436.05636,
    "out:Klimatpaverkan":0.001* -85998.73346,
    "out:Initial Cost/MSEK": 5.31075,
    "out:Running cost/(Apt SEK y)": 9124.171429,
    "out:Running Cost over RSP/MSEK": 2.47523,
    "out:LCP/MSEK": 1.52569,
    "out:ROI% old": 18.389762,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 58,
    "out:Energy use kWh/m2": 58,
    "out:Energy savings %": 182.8,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3625.37287,
    "out:Return %": 16,
    "out:Return/kSEK/y": 871,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 33.291541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 397217.2077,
    "out:EL kWh savings": -93368.55993,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202741.11,
    "out:DH kr savings": 361467.659,
    "out:El kr savings": -158726.5519,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 24674.75976,
    "out:% savings (space heating)": 91.730513,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 5.93,
    "out:Etvv": 0,
    "out:Ef": 48.86,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5310750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.677963,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.237879,
    "out:Electricity (inc PV)": 48.158908,
    "out:Total Energy Use Pre PV": 58.237879,
    "out:Total Energy Use Post PV": 55.158908,
    "out:Primary Energy": 89.809831,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 15.259466,
    "out:CO2 Operational/m2": 9.283698,
    "out:Total CO2/m2": 24.543164,
    "out:Total CO2 (tons)": 70168.89819,
    "out:Klimatpaverkan":0.001* -88265.89162,
    "out:Initial Cost/MSEK": 5.38225,
    "out:Running cost/(Apt SEK y)": 8618,
    "out:Running Cost over RSP/MSEK": 2.33684,
    "out:LCP/MSEK": 1.59258,
    "out:ROI% old": 18.512783,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 198.2,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3586.65153,
    "out:Return %": 17,
    "out:Return/kSEK/y": 889,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.738379,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402799.6734,
    "out:EL kWh savings": -91370.50797,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211217.84,
    "out:DH kr savings": 366547.7028,
    "out:El kr savings": -155329.8635,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 19092.29413,
    "out:% savings (space heating)": 93.601417,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.46,
    "out:Etvv": 0,
    "out:Ef": 48.16,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5382250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.962707,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.958268,
    "out:Electricity (inc PV)": 40.94542,
    "out:Total Energy Use Pre PV": 62.958268,
    "out:Total Energy Use Post PV": 50.94542,
    "out:Primary Energy": 78.262162,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.747532,
    "out:CO2 Operational/m2": 8.663323,
    "out:Total CO2/m2": 33.410855,
    "out:Total CO2 (tons)": 95521.62399,
    "out:Klimatpaverkan":0.001* -62913.16583,
    "out:Initial Cost/MSEK": 5.649875,
    "out:Running cost/(Apt SEK y)": 8265.457143,
    "out:Running Cost over RSP/MSEK": 2.24908,
    "out:LCP/MSEK": 1.412715,
    "out:ROI% old": 17.857766,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 221.6,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 3127.843299,
    "out:Return %": 16,
    "out:Return/kSEK/y": 902,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.458768,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 511.302003,
    "out:PV (% sold (El))": 2.000058,
    "out:PV (kWh self-consumed)": 25053.06261,
    "out:PV (ratio sold/self-consumed)": 0.020409,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 393408.5913,
    "out:EL kWh savings": -70747.14804,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 238383.58,
    "out:DH kr savings": 358001.8181,
    "out:El kr savings": -120270.1517,
    "out:El kr sold": 651.9100544,
    "out:El kr saved": 42590.20644,
    "out:El kr return": 43242.11649,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 28483.3762,
    "out:% savings (space heating)": 90.454095,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 6.51,
    "out:Etvv": 0,
    "out:Ef": 40.95,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5649875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.858204,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.451139,
    "out:Electricity (inc PV)": 40.311364,
    "out:Total Energy Use Pre PV": 60.451139,
    "out:Total Energy Use Post PV": 48.311364,
    "out:Primary Energy": 76.004983,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.747532,
    "out:CO2 Operational/m2": 7.803906,
    "out:Total CO2/m2": 32.551438,
    "out:Total CO2 (tons)": 93064.55105,
    "out:Klimatpaverkan":0.001* -65370.23876,
    "out:Initial Cost/MSEK": 5.721375,
    "out:Running cost/(Apt SEK y)": 7766.2,
    "out:Running Cost over RSP/MSEK": 2.11255,
    "out:LCP/MSEK": 1.477745,
    "out:ROI% old": 17.975499,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 48,
    "out:Energy use kWh/m2": 48,
    "out:Energy savings %": 241.7,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 3093.083433,
    "out:Return %": 16,
    "out:Return/kSEK/y": 919,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.951639,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 555.186799,
    "out:PV (% sold (El))": 2.171721,
    "out:PV (kWh self-consumed)": 25009.17782,
    "out:PV (ratio sold/self-consumed)": 0.022199,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 399425.3647,
    "out:EL kWh savings": -68934.38213,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 246996.5,
    "out:DH kr savings": 363477.0819,
    "out:El kr savings": -117188.4496,
    "out:El kr sold": 707.8631686,
    "out:El kr saved": 42515.60229,
    "out:El kr return": 43223.46545,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 22466.60278,
    "out:% savings (space heating)": 92.470553,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.92,
    "out:Etvv": 0,
    "out:Ef": 40.31,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5721375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.630557,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.791041,
    "out:Electricity (inc PV)": 40.243065,
    "out:Total Energy Use Pre PV": 60.791041,
    "out:Total Energy Use Post PV": 49.243065,
    "out:Primary Energy": 76.591559,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.142076,
    "out:CO2 Operational/m2": 7.977755,
    "out:Total CO2/m2": 33.119831,
    "out:Total CO2 (tons)": 94689.58646,
    "out:Klimatpaverkan":0.001* -63745.20335,
    "out:Initial Cost/MSEK": 5.7865,
    "out:Running cost/(Apt SEK y)": 7889.942857,
    "out:Running Cost over RSP/MSEK": 2.14674,
    "out:LCP/MSEK": 1.37843,
    "out:ROI% old": 17.688783,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 49,
    "out:Energy use kWh/m2": 49,
    "out:Energy savings %": 234.7,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3047.91571,
    "out:Return %": 16,
    "out:Return/kSEK/y": 915,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 33.291541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 615.390558,
    "out:PV (% sold (El))": 2.40722,
    "out:PV (kWh self-consumed)": 24948.97406,
    "out:PV (ratio sold/self-consumed)": 0.024666,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 397217.2077,
    "out:EL kWh savings": -68739.11531,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 245395.79,
    "out:DH kr savings": 361467.659,
    "out:El kr savings": -116856.496,
    "out:El kr sold": 784.6229614,
    "out:El kr saved": 42413.2559,
    "out:El kr return": 43197.87886,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 24674.75976,
    "out:% savings (space heating)": 91.730513,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 5.93,
    "out:Etvv": 0,
    "out:Ef": 40.24,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5786500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.677963,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.237879,
    "out:Electricity (inc PV)": 39.575482,
    "out:Total Energy Use Pre PV": 58.237879,
    "out:Total Energy Use Post PV": 46.575482,
    "out:Primary Energy": 74.359665,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 25.142076,
    "out:CO2 Operational/m2": 7.097261,
    "out:Total CO2/m2": 32.239337,
    "out:Total CO2 (tons)": 92172.25439,
    "out:Klimatpaverkan":0.001* -66262.53542,
    "out:Initial Cost/MSEK": 5.858,
    "out:Running cost/(Apt SEK y)": 7384.428571,
    "out:Running Cost over RSP/MSEK": 2.00851,
    "out:LCP/MSEK": 1.44516,
    "out:ROI% old": 17.809979,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 248.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3010.132446,
    "out:Return %": 16,
    "out:Return/kSEK/y": 932,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.738379,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 669.187678,
    "out:PV (% sold (El))": 2.617658,
    "out:PV (kWh self-consumed)": 24895.17694,
    "out:PV (ratio sold/self-consumed)": 0.02688,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402799.6734,
    "out:EL kWh savings": -66830.49572,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 253789.07,
    "out:DH kr savings": 366547.7028,
    "out:El kr savings": -113611.8427,
    "out:El kr sold": 853.21429,
    "out:El kr saved": 42321.80079,
    "out:El kr return": 43175.01508,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 19092.29413,
    "out:% savings (space heating)": 93.601417,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.46,
    "out:Etvv": 0,
    "out:Ef": 39.58,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5858000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 9.962707,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.958268,
    "out:Electricity (inc PV)": 36.793013,
    "out:Total Energy Use Pre PV": 62.958268,
    "out:Total Energy Use Post PV": 46.793013,
    "out:Primary Energy": 70.787829,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.630142,
    "out:CO2 Operational/m2": -13.94979,
    "out:Total CO2/m2": 20.680352,
    "out:Total CO2 (tons)": 59125.1199,
    "out:Klimatpaverkan":0.001* -99309.66992,
    "out:Initial Cost/MSEK": 6.125625,
    "out:Running cost/(Apt SEK y)": 7176.971429,
    "out:Running Cost over RSP/MSEK": 1.95866,
    "out:LCP/MSEK": 1.227385,
    "out:ROI% old": 17.148128,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 248.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 2,
    "out:DH+ DHW/kWh/m2": 10,
    "out:El bought/kWh/m2": 2804.980868,
    "out:Return %": 15,
    "out:Return/kSEK/y": 940,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 34.458768,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 13128.42103,
    "out:PV (% sold (El))": 25.67719,
    "out:PV (kWh self-consumed)": 38000.30838,
    "out:PV (ratio sold/self-consumed)": 0.345482,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 393408.5913,
    "out:EL kWh savings": -58875.41772,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 274652.34,
    "out:DH kr savings": 358001.8181,
    "out:El kr savings": -100088.2101,
    "out:El kr sold": 16738.73681,
    "out:El kr saved": 64600.52425,
    "out:El kr return": 81339.26106,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 28483.3762,
    "out:% savings (space heating)": 90.454095,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 6.51,
    "out:Etvv": 0,
    "out:Ef": 36.79,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6125625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 7.858204,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.451139,
    "out:Electricity (inc PV)": 36.232084,
    "out:Total Energy Use Pre PV": 60.451139,
    "out:Total Energy Use Post PV": 44.232084,
    "out:Primary Energy": 68.662279,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 34.630142,
    "out:CO2 Operational/m2": -15.092737,
    "out:Total CO2/m2": 19.537405,
    "out:Total CO2 (tons)": 55857.43478,
    "out:Klimatpaverkan":0.001* -102577.355,
    "out:Initial Cost/MSEK": 6.197125,
    "out:Running cost/(Apt SEK y)": 6679.657143,
    "out:Running Cost over RSP/MSEK": 1.82266,
    "out:LCP/MSEK": 1.291885,
    "out:ROI% old": 17.263789,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 272.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 8,
    "out:El bought/kWh/m2": 2774.148814,
    "out:Return %": 15,
    "out:Return/kSEK/y": 957,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.951639,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 13333.53163,
    "out:PV (% sold (El))": 26.078355,
    "out:PV (kWh self-consumed)": 37795.19778,
    "out:PV (ratio sold/self-consumed)": 0.352784,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 399425.3647,
    "out:EL kWh savings": -57271.72189,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283115.41,
    "out:DH kr savings": 363477.0819,
    "out:El kr savings": -97361.92721,
    "out:El kr sold": 17000.25283,
    "out:El kr saved": 64251.83622,
    "out:El kr return": 81252.08905,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 22466.60278,
    "out:% savings (space heating)": 92.470553,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.92,
    "out:Etvv": 0,
    "out:Ef": 36.23,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6197125,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z01000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.630557,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.791041,
    "out:Electricity (inc PV)": 36.171675,
    "out:Total Energy Use Pre PV": 60.791041,
    "out:Total Energy Use Post PV": 45.171675,
    "out:Primary Energy": 69.263057,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.024686,
    "out:CO2 Operational/m2": -15.289438,
    "out:Total CO2/m2": 19.735248,
    "out:Total CO2 (tons)": 56423.06786,
    "out:Klimatpaverkan":0.001* -102011.722,
    "out:Initial Cost/MSEK": 6.26225,
    "out:Running cost/(Apt SEK y)": 6805.971429,
    "out:Running Cost over RSP/MSEK": 1.85754,
    "out:LCP/MSEK": 1.19188,
    "out:ROI% old": 17.004682,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 45,
    "out:Energy use kWh/m2": 45,
    "out:Energy savings %": 264.4,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2734.091042,
    "out:Return %": 15,
    "out:Return/kSEK/y": 953,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 33.291541,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 13604.71705,
    "out:PV (% sold (El))": 26.608752,
    "out:PV (kWh self-consumed)": 37524.01236,
    "out:PV (ratio sold/self-consumed)": 0.36256,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 397217.2077,
    "out:EL kWh savings": -57099.01257,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 281745.35,
    "out:DH kr savings": 361467.659,
    "out:El kr savings": -97068.32138,
    "out:El kr sold": 17346.01424,
    "out:El kr saved": 63790.82101,
    "out:El kr return": 81136.83525,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 24674.75976,
    "out:% savings (space heating)": 91.730513,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 5.93,
    "out:Etvv": 0,
    "out:Ef": 36.17,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6262250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z01000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.677963,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.237879,
    "out:Electricity (inc PV)": 35.580895,
    "out:Total Energy Use Pre PV": 58.237879,
    "out:Total Energy Use Post PV": 42.580895,
    "out:Primary Energy": 67.169408,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 35.024686,
    "out:CO2 Operational/m2": -16.481614,
    "out:Total CO2/m2": 18.543072,
    "out:Total CO2 (tons)": 53014.63704,
    "out:Klimatpaverkan":0.001* -105420.1528,
    "out:Initial Cost/MSEK": 6.33375,
    "out:Running cost/(Apt SEK y)": 6302.6,
    "out:Running Cost over RSP/MSEK": 1.71989,
    "out:LCP/MSEK": 1.25803,
    "out:ROI% old": 17.123189,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 281.4,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2700.590693,
    "out:Return %": 15,
    "out:Return/kSEK/y": 970,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.738379,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 13835.45151,
    "out:PV (% sold (El))": 27.060034,
    "out:PV (kWh self-consumed)": 37293.27789,
    "out:PV (ratio sold/self-consumed)": 0.37099,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 402799.6734,
    "out:EL kWh savings": -55409.97274,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289990.95,
    "out:DH kr savings": 366547.7028,
    "out:El kr savings": -94196.95366,
    "out:El kr sold": 17640.20068,
    "out:El kr saved": 63398.57242,
    "out:El kr return": 81038.7731,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 19092.29413,
    "out:% savings (space heating)": 93.601417,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.46,
    "out:Etvv": 0,
    "out:Ef": 35.58,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6333750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z02000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 142.2,
    "out:Primary Energy": 104.468061,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.604533,
    "out:CO2 Operational/m2": 47.496514,
    "out:Total CO2/m2": 60.101047,
    "out:Total CO2 (tons)": 171828.8746,
    "out:Klimatpaverkan":0.001* 13394.08475,
    "out:Initial Cost/MSEK": 2.589125,
    "out:Running cost/(Apt SEK y)": 29300.11429,
    "out:Running Cost over RSP/MSEK": 8.01798,
    "out:LCP/MSEK": -1.295435,
    "out:ROI% old": 7.138043,
    "out:Payback discounted": 17,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 6,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 62278.56739,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56673.5,
    "out:DH kr savings": 56673.49632,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2589125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 138.2,
    "out:Primary Energy": 102.031331,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.604533,
    "out:CO2 Operational/m2": 46.056578,
    "out:Total CO2/m2": 58.661111,
    "out:Total CO2 (tons)": 167712.098,
    "out:Klimatpaverkan":0.001* 9277.308175,
    "out:Initial Cost/MSEK": 2.660625,
    "out:Running cost/(Apt SEK y)": 28441.37143,
    "out:Running Cost over RSP/MSEK": 7.78277,
    "out:LCP/MSEK": -1.131725,
    "out:ROI% old": 8.209135,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 18.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 7,
    "out:Return/kSEK/y": 195,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 72230.86809,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 65730.09,
    "out:DH kr savings": 65730.08996,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2660625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 135.2,
    "out:Primary Energy": 101.843061,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.999077,
    "out:CO2 Operational/m2": 44.976626,
    "out:Total CO2/m2": 57.975703,
    "out:Total CO2 (tons)": 165752.5167,
    "out:Klimatpaverkan":0.001* 7317.726918,
    "out:Initial Cost/MSEK": 2.72575,
    "out:Running cost/(Apt SEK y)": 27797.31429,
    "out:Running Cost over RSP/MSEK": 7.60636,
    "out:LCP/MSEK": -1.02044,
    "out:ROI% old": 8.937566,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 21.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 8,
    "out:Return/kSEK/y": 218,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 80804.88536,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 73532.45,
    "out:DH kr savings": 73532.44568,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2725750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 132.2,
    "out:Primary Energy": 99.406331,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.999077,
    "out:CO2 Operational/m2": 43.896674,
    "out:Total CO2/m2": 56.895751,
    "out:Total CO2 (tons)": 162664.9343,
    "out:Klimatpaverkan":0.001* 4230.144488,
    "out:Initial Cost/MSEK": 2.79725,
    "out:Running cost/(Apt SEK y)": 27153.25714,
    "out:Running Cost over RSP/MSEK": 7.42994,
    "out:LCP/MSEK": -0.91552,
    "out:ROI% old": 9.610102,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 24.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 90757.18606,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 82589.04,
    "out:DH kr savings": 82589.03932,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 21.25,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2797250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 137.305876,
    "out:Primary Energy": 95.658638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.487143,
    "out:CO2 Operational/m2": 26.484525,
    "out:Total CO2/m2": 48.971668,
    "out:Total CO2 (tons)": 140009.9835,
    "out:Klimatpaverkan":0.001* -18424.80633,
    "out:Initial Cost/MSEK": 3.064875,
    "out:Running cost/(Apt SEK y)": 28198.91429,
    "out:Running Cost over RSP/MSEK": 7.72461,
    "out:LCP/MSEK": -1.477815,
    "out:ROI% old": 7.397459,
    "out:Payback discounted": 16,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 19.7,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 7,
    "out:Return/kSEK/y": 204,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 62278.56739,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95214.79,
    "out:DH kr savings": 56673.49632,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3064875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 133.305876,
    "out:Primary Energy": 93.221908,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.487143,
    "out:CO2 Operational/m2": 25.044589,
    "out:Total CO2/m2": 47.531732,
    "out:Total CO2 (tons)": 135893.2069,
    "out:Klimatpaverkan":0.001* -22541.5829,
    "out:Initial Cost/MSEK": 3.136375,
    "out:Running cost/(Apt SEK y)": 27340.17143,
    "out:Running Cost over RSP/MSEK": 7.48939,
    "out:LCP/MSEK": -1.314095,
    "out:ROI% old": 8.30021,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 23.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 7,
    "out:Return/kSEK/y": 234,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 72230.86809,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104271.38,
    "out:DH kr savings": 65730.08996,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3136375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 130.305876,
    "out:Primary Energy": 93.033638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.881687,
    "out:CO2 Operational/m2": 23.964637,
    "out:Total CO2/m2": 46.846324,
    "out:Total CO2 (tons)": 133933.6257,
    "out:Klimatpaverkan":0.001* -24501.16416,
    "out:Initial Cost/MSEK": 3.2015,
    "out:Running cost/(Apt SEK y)": 26696.11429,
    "out:Running Cost over RSP/MSEK": 7.31298,
    "out:LCP/MSEK": -1.20281,
    "out:ROI% old": 8.918543,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 8,
    "out:Return/kSEK/y": 256,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80804.88536,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 112073.74,
    "out:DH kr savings": 73532.44568,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3201500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 127.305876,
    "out:Primary Energy": 90.596908,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.881687,
    "out:CO2 Operational/m2": 22.884685,
    "out:Total CO2/m2": 45.766372,
    "out:Total CO2 (tons)": 130846.0432,
    "out:Klimatpaverkan":0.001* -27588.74659,
    "out:Initial Cost/MSEK": 3.273,
    "out:Running cost/(Apt SEK y)": 26052.05714,
    "out:Running Cost over RSP/MSEK": 7.13657,
    "out:LCP/MSEK": -1.0979,
    "out:ROI% old": 9.493693,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 9,
    "out:Return/kSEK/y": 279,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90757.18606,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121130.33,
    "out:DH kr savings": 82589.03932,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 21.25,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3273000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 142.2,
    "out:Total Energy Use Post PV": 136.330546,
    "out:Primary Energy": 93.903044,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.369753,
    "out:CO2 Operational/m2": -14.150933,
    "out:Total CO2/m2": 18.21882,
    "out:Total CO2 (tons)": 52087.60068,
    "out:Klimatpaverkan":0.001* -106347.1891,
    "out:Initial Cost/MSEK": 3.540625,
    "out:Running cost/(Apt SEK y)": 27233.8,
    "out:Running Cost over RSP/MSEK": 7.46771,
    "out:LCP/MSEK": -1.696665,
    "out:ROI% old": 7.44001,
    "out:Payback discounted": 16,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 20.6,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 7,
    "out:Return/kSEK/y": 238,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 62278.56739,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128994.45,
    "out:DH kr savings": 56673.49632,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3540625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 138.2,
    "out:Total Energy Use Post PV": 132.330546,
    "out:Primary Energy": 91.466314,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.369753,
    "out:CO2 Operational/m2": -15.590869,
    "out:Total CO2/m2": 16.778884,
    "out:Total CO2 (tons)": 47970.8241,
    "out:Klimatpaverkan":0.001* -110463.9657,
    "out:Initial Cost/MSEK": 3.612125,
    "out:Running cost/(Apt SEK y)": 26375.05714,
    "out:Running Cost over RSP/MSEK": 7.23249,
    "out:LCP/MSEK": -1.532945,
    "out:ROI% old": 8.223019,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 24.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 7,
    "out:Return/kSEK/y": 268,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 72230.86809,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138051.04,
    "out:DH kr savings": 65730.08996,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3612125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 135.2,
    "out:Total Energy Use Post PV": 129.330546,
    "out:Primary Energy": 91.278044,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.764297,
    "out:CO2 Operational/m2": -16.670821,
    "out:Total CO2/m2": 16.093476,
    "out:Total CO2 (tons)": 46011.24285,
    "out:Klimatpaverkan":0.001* -112423.547,
    "out:Initial Cost/MSEK": 3.67725,
    "out:Running cost/(Apt SEK y)": 25731,
    "out:Running Cost over RSP/MSEK": 7.05608,
    "out:LCP/MSEK": -1.42166,
    "out:ROI% old": 8.762721,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 8,
    "out:Return/kSEK/y": 290,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80804.88536,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145853.4,
    "out:DH kr savings": 73532.44568,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3677250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 132.2,
    "out:Total Energy Use Post PV": 126.330546,
    "out:Primary Energy": 88.841314,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.764297,
    "out:CO2 Operational/m2": -17.750773,
    "out:Total CO2/m2": 15.013524,
    "out:Total CO2 (tons)": 42923.66042,
    "out:Klimatpaverkan":0.001* -115511.1294,
    "out:Initial Cost/MSEK": 3.74875,
    "out:Running cost/(Apt SEK y)": 25086.94286,
    "out:Running Cost over RSP/MSEK": 6.87967,
    "out:LCP/MSEK": -1.31675,
    "out:ROI% old": 9.267851,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 8,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 90757.18606,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154909.99,
    "out:DH kr savings": 82589.03932,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 21.25,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3748750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 133.2,
    "out:Primary Energy": 100.968061,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.604533,
    "out:CO2 Operational/m2": 44.256658,
    "out:Total CO2/m2": 56.861191,
    "out:Total CO2 (tons)": 162566.1273,
    "out:Klimatpaverkan":0.001* 4131.337458,
    "out:Initial Cost/MSEK": 2.6815,
    "out:Running cost/(Apt SEK y)": 27367.94286,
    "out:Running Cost over RSP/MSEK": 7.48875,
    "out:LCP/MSEK": -0.85858,
    "out:ROI% old": 9.711622,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 23.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 86980.32468,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 79152.1,
    "out:DH kr savings": 79152.09546,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2681500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 130.2,
    "out:Primary Energy": 98.531331,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.604533,
    "out:CO2 Operational/m2": 43.176706,
    "out:Total CO2/m2": 55.781239,
    "out:Total CO2 (tons)": 159478.5448,
    "out:Klimatpaverkan":0.001* 1043.755028,
    "out:Initial Cost/MSEK": 2.753,
    "out:Running cost/(Apt SEK y)": 26723.85714,
    "out:Running Cost over RSP/MSEK": 7.31234,
    "out:LCP/MSEK": -0.75367,
    "out:ROI% old": 10.374812,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 26.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 9,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 96932.62539,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88208.69,
    "out:DH kr savings": 88208.6891,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 20,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2753000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 128.2,
    "out:Primary Energy": 98.868061,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.999077,
    "out:CO2 Operational/m2": 42.456738,
    "out:Total CO2/m2": 55.455815,
    "out:Total CO2 (tons)": 158548.1577,
    "out:Klimatpaverkan":0.001* 113.367914,
    "out:Initial Cost/MSEK": 2.818125,
    "out:Running cost/(Apt SEK y)": 26294.48571,
    "out:Running Cost over RSP/MSEK": 7.19473,
    "out:LCP/MSEK": -0.701185,
    "out:ROI% old": 10.731249,
    "out:Payback discounted": 11,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 10,
    "out:Return/kSEK/y": 271,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 101801.3791,
    "out:EL kWh savings": 3.05e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92639.25,
    "out:DH kr savings": 92639.25495,
    "out:El kr savings": 5.18e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2818125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 16.2,
    "out:Total Energy Use Pre PV": 124.2,
    "out:Total Energy Use Post PV": 124.2,
    "out:Primary Energy": 96.431331,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 12.999077,
    "out:CO2 Operational/m2": 41.016802,
    "out:Total CO2/m2": 54.015879,
    "out:Total CO2 (tons)": 154431.3812,
    "out:Klimatpaverkan":0.001* -4003.408659,
    "out:Initial Cost/MSEK": 2.889625,
    "out:Running cost/(Apt SEK y)": 25435.74286,
    "out:Running Cost over RSP/MSEK": 6.95951,
    "out:LCP/MSEK": -0.537465,
    "out:ROI% old": 11.628597,
    "out:Payback discounted": 10,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 32.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1134,
    "out:Return %": 10,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 111753.6798,
    "out:EL kWh savings": -1.02e-11,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 101695.85,
    "out:DH kr savings": 101695.8486,
    "out:El kr savings": -1.73e-11,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 17,
    "out:Ef": 16.2,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2889625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 128.305876,
    "out:Primary Energy": 92.158638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.487143,
    "out:CO2 Operational/m2": 23.244669,
    "out:Total CO2/m2": 45.731812,
    "out:Total CO2 (tons)": 130747.2362,
    "out:Klimatpaverkan":0.001* -27687.55362,
    "out:Initial Cost/MSEK": 3.15725,
    "out:Running cost/(Apt SEK y)": 26266.74286,
    "out:Running Cost over RSP/MSEK": 7.19537,
    "out:LCP/MSEK": -1.04095,
    "out:ROI% old": 9.575693,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 9,
    "out:Return/kSEK/y": 271,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86980.32468,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 117693.39,
    "out:DH kr savings": 79152.09546,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3157250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 125.305876,
    "out:Primary Energy": 89.721908,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.487143,
    "out:CO2 Operational/m2": 22.164717,
    "out:Total CO2/m2": 44.65186,
    "out:Total CO2 (tons)": 127659.6538,
    "out:Klimatpaverkan":0.001* -30775.13605,
    "out:Initial Cost/MSEK": 3.22875,
    "out:Running cost/(Apt SEK y)": 25622.68571,
    "out:Running Cost over RSP/MSEK": 7.01896,
    "out:LCP/MSEK": -0.93604,
    "out:ROI% old": 10.144173,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 31.2,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 9,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 96932.62539,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126749.98,
    "out:DH kr savings": 88208.6891,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 20,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3228750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 123.305876,
    "out:Primary Energy": 90.058638,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.881687,
    "out:CO2 Operational/m2": 21.444749,
    "out:Total CO2/m2": 44.326436,
    "out:Total CO2 (tons)": 126729.2667,
    "out:Klimatpaverkan":0.001* -31705.52316,
    "out:Initial Cost/MSEK": 3.293875,
    "out:Running cost/(Apt SEK y)": 25193.31429,
    "out:Running Cost over RSP/MSEK": 6.90136,
    "out:LCP/MSEK": -0.883565,
    "out:ROI% old": 10.453645,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 9,
    "out:Return/kSEK/y": 309,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 101801.3791,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131180.55,
    "out:DH kr savings": 92639.25495,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3293875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 11.305876,
    "out:Total Energy Use Pre PV": 124.2,
    "out:Total Energy Use Post PV": 119.305876,
    "out:Primary Energy": 87.621908,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 22.881687,
    "out:CO2 Operational/m2": 20.004813,
    "out:Total CO2/m2": 42.8865,
    "out:Total CO2 (tons)": 122612.4901,
    "out:Klimatpaverkan":0.001* -35822.29974,
    "out:Initial Cost/MSEK": 3.365375,
    "out:Running cost/(Apt SEK y)": 24334.57143,
    "out:Running Cost over RSP/MSEK": 6.66614,
    "out:LCP/MSEK": -0.719845,
    "out:ROI% old": 11.230037,
    "out:Payback discounted": 11,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 37.8,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 799.264192,
    "out:Return %": 10,
    "out:Return/kSEK/y": 339,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 11572.06589,
    "out:PV (% sold (El))": 45.266394,
    "out:PV (kWh self-consumed)": 13992.29872,
    "out:PV (ratio sold/self-consumed)": 0.827031,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 111753.6798,
    "out:EL kWh savings": 13992.29898,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140237.14,
    "out:DH kr savings": 101695.8486,
    "out:El kr savings": 23786.90827,
    "out:El kr sold": 14754.38401,
    "out:El kr saved": 23786.90782,
    "out:El kr return": 38541.29184,
    "out:% solar/total": 30,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 17,
    "out:Ef": 11.31,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3365375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 133.2,
    "out:Total Energy Use Post PV": 127.330546,
    "out:Primary Energy": 90.403044,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.369753,
    "out:CO2 Operational/m2": -17.390789,
    "out:Total CO2/m2": 14.978964,
    "out:Total CO2 (tons)": 42824.85339,
    "out:Klimatpaverkan":0.001* -115609.9364,
    "out:Initial Cost/MSEK": 3.633,
    "out:Running cost/(Apt SEK y)": 25301.62857,
    "out:Running Cost over RSP/MSEK": 6.93847,
    "out:LCP/MSEK": -1.2598,
    "out:ROI% old": 9.331918,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.1,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 8,
    "out:Return/kSEK/y": 305,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86980.32468,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151473.05,
    "out:DH kr savings": 79152.09546,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3633000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 130.2,
    "out:Total Energy Use Post PV": 124.330546,
    "out:Primary Energy": 87.966314,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.369753,
    "out:CO2 Operational/m2": -18.470741,
    "out:Total CO2/m2": 13.899012,
    "out:Total CO2 (tons)": 39737.27096,
    "out:Klimatpaverkan":0.001* -118697.5189,
    "out:Initial Cost/MSEK": 3.7045,
    "out:Running cost/(Apt SEK y)": 24657.57143,
    "out:Running Cost over RSP/MSEK": 6.76206,
    "out:LCP/MSEK": -1.15489,
    "out:ROI% old": 9.832096,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 32.3,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 9,
    "out:Return/kSEK/y": 328,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 96932.62539,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160529.64,
    "out:DH kr savings": 88208.6891,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 20,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3704500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.582945,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 128.2,
    "out:Total Energy Use Post PV": 122.330546,
    "out:Primary Energy": 88.303044,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.764297,
    "out:CO2 Operational/m2": -19.190709,
    "out:Total CO2/m2": 13.573588,
    "out:Total CO2 (tons)": 38806.88384,
    "out:Klimatpaverkan":0.001* -119627.906,
    "out:Initial Cost/MSEK": 3.769625,
    "out:Running cost/(Apt SEK y)": 24228.17143,
    "out:Running Cost over RSP/MSEK": 6.64445,
    "out:LCP/MSEK": -1.102405,
    "out:ROI% old": 10.10794,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 34.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 9,
    "out:Return/kSEK/y": 343,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 101801.3791,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164960.21,
    "out:DH kr savings": 92639.25495,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 236104.6136,
    "out:% savings (space heating)": 20.87201,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 82.58,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3769625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.101902,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 16.2,
    "out:Electricity (inc PV)": 10.330546,
    "out:Total Energy Use Pre PV": 124.2,
    "out:Total Energy Use Post PV": 118.330546,
    "out:Primary Energy": 85.866314,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.764297,
    "out:CO2 Operational/m2": -20.630645,
    "out:Total CO2/m2": 12.133652,
    "out:Total CO2 (tons)": 34690.10727,
    "out:Klimatpaverkan":0.001* -123744.6825,
    "out:Initial Cost/MSEK": 3.841125,
    "out:Running cost/(Apt SEK y)": 23369.42857,
    "out:Running Cost over RSP/MSEK": 6.40924,
    "out:LCP/MSEK": -0.938695,
    "out:ROI% old": 10.794568,
    "out:Payback discounted": 11,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FT",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 728.936758,
    "out:Return %": 10,
    "out:Return/kSEK/y": 373,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 34347.96132,
    "out:PV (% sold (El))": 67.179376,
    "out:PV (kWh self-consumed)": 16780.76809,
    "out:PV (ratio sold/self-consumed)": 2.046865,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 111753.6798,
    "out:EL kWh savings": 16780.76715,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174016.8,
    "out:DH kr savings": 101695.8486,
    "out:El kr savings": 28527.30415,
    "out:El kr sold": 43793.65068,
    "out:El kr saved": 28527.30575,
    "out:El kr return": 72320.95643,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 226152.3129,
    "out:% savings (space heating)": 24.207419,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.1,
    "out:Etvv": 17,
    "out:Ef": 10.33,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3841125,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z02000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.476913,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.92557,
    "out:Electricity (inc PV)": 39.486595,
    "out:Total Energy Use Pre PV": 89.92557,
    "out:Total Energy Use Post PV": 87.486595,
    "out:Primary Energy": 97.903743,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.683172,
    "out:CO2 Operational/m2": 22.813743,
    "out:Total CO2/m2": 39.496915,
    "out:Total CO2 (tons)": 112921.6676,
    "out:Klimatpaverkan":0.001* -45513.12219,
    "out:Initial Cost/MSEK": 4.237,
    "out:Running cost/(Apt SEK y)": 16126.97143,
    "out:Running Cost over RSP/MSEK": 4.3979,
    "out:LCP/MSEK": 0.67677,
    "out:ROI% old": 16.567551,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 88.5,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2934.7899,
    "out:Return %": 15,
    "out:Return/kSEK/y": 626,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.42607,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 283296.4884,
    "out:EL kWh savings": -66576.36782,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144619.98,
    "out:DH kr savings": 257799.8044,
    "out:El kr savings": -113179.8253,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 138595.4791,
    "out:% savings (space heating)": 53.551176,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 38.33,
    "out:Etvv": 0,
    "out:Ef": 39.49,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4237000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.1554,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.877228,
    "out:Electricity (inc PV)": 39.406613,
    "out:Total Energy Use Pre PV": 86.877228,
    "out:Total Energy Use Post PV": 84.406613,
    "out:Primary Energy": 95.507803,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.683172,
    "out:CO2 Operational/m2": 21.727409,
    "out:Total CO2/m2": 38.410581,
    "out:Total CO2 (tons)": 109815.8391,
    "out:Klimatpaverkan":0.001* -48618.95076,
    "out:Initial Cost/MSEK": 4.3085,
    "out:Running cost/(Apt SEK y)": 15476.2,
    "out:Running Cost over RSP/MSEK": 4.21967,
    "out:LCP/MSEK": 0.7835,
    "out:ROI% old": 16.883569,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 95.2,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2931.40596,
    "out:Return %": 15,
    "out:Return/kSEK/y": 649,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.377728,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 292792.693,
    "out:EL kWh savings": -66347.6993,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153650.26,
    "out:DH kr savings": 266441.3506,
    "out:El kr savings": -112791.0888,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 129099.2745,
    "out:% savings (space heating)": 56.733729,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 35.11,
    "out:Etvv": 0,
    "out:Ef": 39.41,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4308500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.779376,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.0824,
    "out:Electricity (inc PV)": 38.954085,
    "out:Total Energy Use Pre PV": 86.0824,
    "out:Total Energy Use Post PV": 83.954085,
    "out:Primary Energy": 95.550322,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.077716,
    "out:CO2 Operational/m2": 21.622485,
    "out:Total CO2/m2": 38.700201,
    "out:Total CO2 (tons)": 110643.8625,
    "out:Klimatpaverkan":0.001* -47790.92727,
    "out:Initial Cost/MSEK": 4.373625,
    "out:Running cost/(Apt SEK y)": 15365.82857,
    "out:Running Cost over RSP/MSEK": 4.1898,
    "out:LCP/MSEK": 0.748245,
    "out:ROI% old": 16.729731,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 95.2,
    "out:Op energy cost/MSEK": 30,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2875.768,
    "out:Return %": 15,
    "out:Return/kSEK/y": 653,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.5829,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293867.7455,
    "out:EL kWh savings": -65053.92189,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156827.98,
    "out:DH kr savings": 267419.6484,
    "out:El kr savings": -110591.6672,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 128024.222,
    "out:% savings (space heating)": 57.094022,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 36.33,
    "out:Etvv": 0,
    "out:Ef": 38.95,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4373625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.489271,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.02454,
    "out:Electricity (inc PV)": 38.863942,
    "out:Total Energy Use Pre PV": 82.02454,
    "out:Total Energy Use Post PV": 79.863942,
    "out:Primary Energy": 93.159565,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.077716,
    "out:CO2 Operational/m2": 20.174911,
    "out:Total CO2/m2": 37.252627,
    "out:Total CO2 (tons)": 106505.2489,
    "out:Klimatpaverkan":0.001* -51929.54088,
    "out:Initial Cost/MSEK": 4.445125,
    "out:Running cost/(Apt SEK y)": 14499.02857,
    "out:Running Cost over RSP/MSEK": 3.95241,
    "out:LCP/MSEK": 0.914135,
    "out:ROI% old": 17.223556,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 105,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2871.7178,
    "out:Return %": 15,
    "out:Return/kSEK/y": 683,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.52504,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303274.1547,
    "out:EL kWh savings": -64796.20308,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165825.94,
    "out:DH kr savings": 275979.4808,
    "out:El kr savings": -110153.5452,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 118617.8128,
    "out:% savings (space heating)": 60.246482,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 33.15,
    "out:Etvv": 0,
    "out:Ef": 38.86,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4445125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.476913,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.92557,
    "out:Electricity (inc PV)": 31.431387,
    "out:Total Energy Use Pre PV": 89.92557,
    "out:Total Energy Use Post PV": 79.431387,
    "out:Primary Energy": 83.404369,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.565782,
    "out:CO2 Operational/m2": 18.353123,
    "out:Total CO2/m2": 44.918905,
    "out:Total CO2 (tons)": 128423.1353,
    "out:Klimatpaverkan":0.001* -30011.65448,
    "out:Initial Cost/MSEK": 4.71275,
    "out:Running cost/(Apt SEK y)": 14909.91429,
    "out:Running Cost over RSP/MSEK": 4.07378,
    "out:LCP/MSEK": 0.52514,
    "out:ROI% old": 15.877566,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 107.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2384.477158,
    "out:Return %": 14,
    "out:Return/kSEK/y": 669,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.42607,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2028.761428,
    "out:PV (% sold (El))": 7.935896,
    "out:PV (kWh self-consumed)": 23535.60319,
    "out:PV (ratio sold/self-consumed)": 0.0862,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283296.4884,
    "out:EL kWh savings": -43546.53067,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186357.37,
    "out:DH kr savings": 257799.8044,
    "out:El kr savings": -74029.10213,
    "out:El kr sold": 2586.670821,
    "out:El kr saved": 40010.52542,
    "out:El kr return": 42597.19624,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 138595.4791,
    "out:% savings (space heating)": 53.551176,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 38.33,
    "out:Etvv": 0,
    "out:Ef": 31.43,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4712750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.1554,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.877228,
    "out:Electricity (inc PV)": 31.357565,
    "out:Total Energy Use Pre PV": 86.877228,
    "out:Total Energy Use Post PV": 76.357565,
    "out:Primary Energy": 81.019517,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.565782,
    "out:CO2 Operational/m2": 17.250806,
    "out:Total CO2/m2": 43.816588,
    "out:Total CO2 (tons)": 125271.6114,
    "out:Klimatpaverkan":0.001* -33163.17844,
    "out:Initial Cost/MSEK": 4.78425,
    "out:Running cost/(Apt SEK y)": 14259.25714,
    "out:Running Cost over RSP/MSEK": 3.89558,
    "out:LCP/MSEK": 0.63184,
    "out:ROI% old": 16.172381,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 115.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2381.286034,
    "out:Return %": 14,
    "out:Return/kSEK/y": 692,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.377728,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2038.209948,
    "out:PV (% sold (El))": 7.972856,
    "out:PV (kWh self-consumed)": 23526.15467,
    "out:PV (ratio sold/self-consumed)": 0.086636,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 292792.693,
    "out:EL kWh savings": -43335.47359,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195369.76,
    "out:DH kr savings": 266441.3506,
    "out:El kr savings": -73670.3051,
    "out:El kr sold": 2598.717684,
    "out:El kr saved": 39994.46293,
    "out:El kr return": 42593.18062,
    "out:% solar/total": 51,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 129099.2745,
    "out:% savings (space heating)": 56.733729,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 35.11,
    "out:Etvv": 0,
    "out:Ef": 31.36,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4784250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.779376,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.0824,
    "out:Electricity (inc PV)": 30.940689,
    "out:Total Energy Use Pre PV": 86.0824,
    "out:Total Energy Use Post PV": 75.940689,
    "out:Primary Energy": 81.126209,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.960326,
    "out:CO2 Operational/m2": 16.876456,
    "out:Total CO2/m2": 43.836782,
    "out:Total CO2 (tons)": 125329.346,
    "out:Klimatpaverkan":0.001* -33105.4438,
    "out:Initial Cost/MSEK": 4.849375,
    "out:Running cost/(Apt SEK y)": 14150.8,
    "out:Running Cost over RSP/MSEK": 3.86622,
    "out:LCP/MSEK": 0.596075,
    "out:ROI% old": 16.041684,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 115.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2328.908117,
    "out:Return %": 14,
    "out:Return/kSEK/y": 696,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.5829,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2196.782893,
    "out:PV (% sold (El))": 8.593145,
    "out:PV (kWh self-consumed)": 23367.58172,
    "out:PV (ratio sold/self-consumed)": 0.09401,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293867.7455,
    "out:EL kWh savings": -42143.62524,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 198576.38,
    "out:DH kr savings": 267419.6484,
    "out:El kr savings": -71644.1629,
    "out:El kr sold": 2800.898188,
    "out:El kr saved": 39724.88893,
    "out:El kr return": 42525.78711,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 128024.222,
    "out:% savings (space heating)": 57.094022,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 36.33,
    "out:Etvv": 0,
    "out:Ef": 30.94,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4849375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.489271,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.02454,
    "out:Electricity (inc PV)": 30.85774,
    "out:Total Energy Use Pre PV": 82.02454,
    "out:Total Energy Use Post PV": 71.85774,
    "out:Primary Energy": 78.748401,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.960326,
    "out:CO2 Operational/m2": 15.408791,
    "out:Total CO2/m2": 42.369117,
    "out:Total CO2 (tons)": 121133.2922,
    "out:Klimatpaverkan":0.001* -37301.49757,
    "out:Initial Cost/MSEK": 4.920875,
    "out:Running cost/(Apt SEK y)": 13284.17143,
    "out:Running Cost over RSP/MSEK": 3.62887,
    "out:LCP/MSEK": 0.761925,
    "out:ROI% old": 16.497647,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 127.8,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2325.101882,
    "out:Return %": 15,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.52504,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2208.535801,
    "out:PV (% sold (El))": 8.639119,
    "out:PV (kWh self-consumed)": 23355.82881,
    "out:PV (ratio sold/self-consumed)": 0.09456,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 303274.1547,
    "out:EL kWh savings": -41906.47407,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207554.36,
    "out:DH kr savings": 275979.4808,
    "out:El kr savings": -71241.00592,
    "out:El kr sold": 2815.883147,
    "out:El kr saved": 39704.90898,
    "out:El kr return": 42520.79213,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 118617.8128,
    "out:% savings (space heating)": 60.246482,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 33.15,
    "out:Etvv": 0,
    "out:Ef": 30.86,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4920875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 48.476913,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.92557,
    "out:Electricity (inc PV)": 28.351746,
    "out:Total Energy Use Pre PV": 89.92557,
    "out:Total Energy Use Post PV": 76.351746,
    "out:Primary Energy": 77.861015,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.448392,
    "out:CO2 Operational/m2": -10.352024,
    "out:Total CO2/m2": 26.096368,
    "out:Total CO2 (tons)": 74609.50794,
    "out:Klimatpaverkan":0.001* -83825.28187,
    "out:Initial Cost/MSEK": 5.1885,
    "out:Running cost/(Apt SEK y)": 13863.08571,
    "out:Running Cost over RSP/MSEK": 3.79469,
    "out:LCP/MSEK": 0.32848,
    "out:ROI% old": 15.190132,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 115.8,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 8,
    "out:DH+ DHW/kWh/m2": 48,
    "out:El bought/kWh/m2": 2146.633699,
    "out:Return %": 14,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.42607,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18077.88013,
    "out:PV (% sold (El))": 35.357578,
    "out:PV (kWh self-consumed)": 33050.84928,
    "out:PV (ratio sold/self-consumed)": 0.546972,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283296.4884,
    "out:EL kWh savings": -34741.83801,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 221787.98,
    "out:DH kr savings": 257799.8044,
    "out:El kr savings": -59061.12462,
    "out:El kr sold": 23049.29716,
    "out:El kr saved": 56186.44378,
    "out:El kr return": 79235.74094,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 138595.4791,
    "out:% savings (space heating)": 53.551176,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 38.33,
    "out:Etvv": 0,
    "out:Ef": 28.35,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5188500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 45.1554,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.877228,
    "out:Electricity (inc PV)": 28.28598,
    "out:Total Energy Use Pre PV": 86.877228,
    "out:Total Energy Use Post PV": 73.28598,
    "out:Primary Energy": 75.490664,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.448392,
    "out:CO2 Operational/m2": -11.479608,
    "out:Total CO2/m2": 24.968784,
    "out:Total CO2 (tons)": 71385.74564,
    "out:Klimatpaverkan":0.001* -87049.04417,
    "out:Initial Cost/MSEK": 5.26,
    "out:Running cost/(Apt SEK y)": 13212.6,
    "out:Running Cost over RSP/MSEK": 3.61654,
    "out:LCP/MSEK": 0.43513,
    "out:ROI% old": 15.46749,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 124.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2143.803565,
    "out:Return %": 14,
    "out:Return/kSEK/y": 728,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 23.377728,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18101.48723,
    "out:PV (% sold (El))": 35.403749,
    "out:PV (kWh self-consumed)": 33027.24218,
    "out:PV (ratio sold/self-consumed)": 0.548077,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 292792.693,
    "out:EL kWh savings": -34553.81304,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 230779.26,
    "out:DH kr savings": 266441.3506,
    "out:El kr savings": -58741.48216,
    "out:El kr sold": 23079.39622,
    "out:El kr saved": 56146.3117,
    "out:El kr return": 79225.70792,
    "out:% solar/total": 71,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 129099.2745,
    "out:% savings (space heating)": 56.733729,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 35.11,
    "out:Etvv": 0,
    "out:Ef": 28.29,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5260000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 44.779376,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.0824,
    "out:Electricity (inc PV)": 27.914305,
    "out:Total Energy Use Pre PV": 86.0824,
    "out:Total Energy Use Post PV": 72.914305,
    "out:Primary Energy": 75.678718,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.842936,
    "out:CO2 Operational/m2": -12.267754,
    "out:Total CO2/m2": 24.575182,
    "out:Total CO2 (tons)": 70260.43765,
    "out:Klimatpaverkan":0.001* -88174.35217,
    "out:Initial Cost/MSEK": 5.325125,
    "out:Running cost/(Apt SEK y)": 13106.97143,
    "out:Running Cost over RSP/MSEK": 3.58794,
    "out:LCP/MSEK": 0.398605,
    "out:ROI% old": 15.355052,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 124.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 45,
    "out:El bought/kWh/m2": 2097.334009,
    "out:Return %": 14,
    "out:Return/kSEK/y": 732,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.5829,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18492.38121,
    "out:PV (% sold (El))": 36.168278,
    "out:PV (kWh self-consumed)": 32636.3482,
    "out:PV (ratio sold/self-consumed)": 0.566619,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293867.7455,
    "out:EL kWh savings": -33491.19433,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 234062.4,
    "out:DH kr savings": 267419.6484,
    "out:El kr savings": -56935.03036,
    "out:El kr sold": 23577.78604,
    "out:El kr saved": 55481.79194,
    "out:El kr return": 79059.57798,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 128024.222,
    "out:% savings (space heating)": 57.094022,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 36.33,
    "out:Etvv": 0,
    "out:Ef": 27.91,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5325125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 41.489271,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 41.02454,
    "out:Electricity (inc PV)": 27.84038,
    "out:Total Energy Use Pre PV": 82.02454,
    "out:Total Energy Use Post PV": 68.84038,
    "out:Primary Energy": 73.317153,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.842936,
    "out:CO2 Operational/m2": -13.765411,
    "out:Total CO2/m2": 23.077525,
    "out:Total CO2 (tons)": 65978.63675,
    "out:Klimatpaverkan":0.001* -92456.15306,
    "out:Initial Cost/MSEK": 5.396625,
    "out:Running cost/(Apt SEK y)": 12240.54286,
    "out:Running Cost over RSP/MSEK": 3.35065,
    "out:LCP/MSEK": 0.564395,
    "out:ROI% old": 15.779757,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 137.7,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2093.955622,
    "out:Return %": 14,
    "out:Return/kSEK/y": 762,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 22.52504,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18521.09634,
    "out:PV (% sold (El))": 36.224441,
    "out:PV (kWh self-consumed)": 32607.63307,
    "out:PV (ratio sold/self-consumed)": 0.567999,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 303274.1547,
    "out:EL kWh savings": -33279.84278,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243018.15,
    "out:DH kr savings": 275979.4808,
    "out:El kr savings": -56575.73272,
    "out:El kr sold": 23614.39784,
    "out:El kr saved": 55432.97621,
    "out:El kr return": 79047.37405,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 118617.8128,
    "out:% savings (space heating)": 60.246482,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 33.15,
    "out:Etvv": 0,
    "out:Ef": 27.84,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5396625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z02000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.56711,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.795208,
    "out:Electricity (inc PV)": 38.773778,
    "out:Total Energy Use Pre PV": 84.795208,
    "out:Total Energy Use Post PV": 82.773778,
    "out:Primary Energy": 94.76728,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.683172,
    "out:CO2 Operational/m2": 21.22459,
    "out:Total CO2/m2": 37.907762,
    "out:Total CO2 (tons)": 108378.2797,
    "out:Klimatpaverkan":0.001* -50056.51012,
    "out:Initial Cost/MSEK": 4.329375,
    "out:Running cost/(Apt SEK y)": 15111.25714,
    "out:Running Cost over RSP/MSEK": 4.12021,
    "out:LCP/MSEK": 0.862085,
    "out:ROI% old": 17.130351,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 97.6,
    "out:Op energy cost/MSEK": 29,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2855.66456,
    "out:Return %": 15,
    "out:Return/kSEK/y": 662,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.295708,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 297333.6136,
    "out:EL kWh savings": -64538.42424,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 160858.27,
    "out:DH kr savings": 270573.5884,
    "out:El kr savings": -109715.3212,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 124558.3539,
    "out:% savings (space heating)": 58.255571,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 35.68,
    "out:Etvv": 0,
    "out:Ef": 38.77,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4329375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.287796,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.734078,
    "out:Electricity (inc PV)": 38.679949,
    "out:Total Energy Use Pre PV": 80.734078,
    "out:Total Energy Use Post PV": 78.679949,
    "out:Primary Energy": 92.378401,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 16.683172,
    "out:CO2 Operational/m2": 19.776584,
    "out:Total CO2/m2": 36.459756,
    "out:Total CO2 (tons)": 104238.431,
    "out:Klimatpaverkan":0.001* -54196.35882,
    "out:Initial Cost/MSEK": 4.400875,
    "out:Running cost/(Apt SEK y)": 14244.02857,
    "out:Running Cost over RSP/MSEK": 3.8827,
    "out:LCP/MSEK": 1.028095,
    "out:ROI% old": 17.623021,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 107.6,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2851.38546,
    "out:Return %": 16,
    "out:Return/kSEK/y": 692,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.234578,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 306709.1713,
    "out:EL kWh savings": -64270.16716,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169846.06,
    "out:DH kr savings": 279105.3459,
    "out:El kr savings": -109259.2842,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 115182.7962,
    "out:% savings (space heating)": 61.397691,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 32.51,
    "out:Etvv": 0,
    "out:Ef": 38.68,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4400875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.697369,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.093917,
    "out:Electricity (inc PV)": 38.335069,
    "out:Total Energy Use Pre PV": 81.093917,
    "out:Total Energy Use Post PV": 79.335069,
    "out:Primary Energy": 92.89102,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.077716,
    "out:CO2 Operational/m2": 20.052062,
    "out:Total CO2/m2": 37.129778,
    "out:Total CO2 (tons)": 106154.0237,
    "out:Klimatpaverkan":0.001* -52280.76613,
    "out:Initial Cost/MSEK": 4.466,
    "out:Running cost/(Apt SEK y)": 14369.8,
    "out:Running Cost over RSP/MSEK": 3.91745,
    "out:LCP/MSEK": 0.92822,
    "out:ROI% old": 17.254878,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 107.6,
    "out:Op energy cost/MSEK": 28,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2806.57419,
    "out:Return %": 15,
    "out:Return/kSEK/y": 688,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.594417,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 305538.2023,
    "out:EL kWh savings": -63284.15534,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170456.7,
    "out:DH kr savings": 278039.7641,
    "out:El kr savings": -107583.0641,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 116353.7652,
    "out:% savings (space heating)": 61.005253,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 34.13,
    "out:Etvv": 0,
    "out:Ef": 38.34,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4466000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.448481,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.023567,
    "out:Electricity (inc PV)": 38.231026,
    "out:Total Energy Use Pre PV": 77.023567,
    "out:Total Energy Use Post PV": 75.231026,
    "out:Primary Energy": 90.507354,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.077716,
    "out:CO2 Operational/m2": 18.602839,
    "out:Total CO2/m2": 35.680555,
    "out:Total CO2 (tons)": 102010.6956,
    "out:Klimatpaverkan":0.001* -56424.09424,
    "out:Initial Cost/MSEK": 4.5375,
    "out:Running cost/(Apt SEK y)": 13501.28571,
    "out:Running Cost over RSP/MSEK": 3.67959,
    "out:LCP/MSEK": 1.09458,
    "out:ROI% old": 17.731854,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 118.7,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 2801.64969,
    "out:Return %": 16,
    "out:Return/kSEK/y": 718,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.524067,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314826.772,
    "out:EL kWh savings": -62986.69644,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 179414.98,
    "out:DH kr savings": 286492.3625,
    "out:El kr savings": -107077.3839,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 107065.1955,
    "out:% savings (space heating)": 64.11822,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 30.99,
    "out:Etvv": 0,
    "out:Ef": 38.23,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4537500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.56711,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.795208,
    "out:Electricity (inc PV)": 30.774798,
    "out:Total Energy Use Pre PV": 84.795208,
    "out:Total Energy Use Post PV": 74.774798,
    "out:Primary Energy": 80.369116,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.565782,
    "out:CO2 Operational/m2": 16.378191,
    "out:Total CO2/m2": 42.943973,
    "out:Total CO2 (tons)": 122776.8054,
    "out:Klimatpaverkan":0.001* -35657.98445,
    "out:Initial Cost/MSEK": 4.805125,
    "out:Running cost/(Apt SEK y)": 13896.94286,
    "out:Running Cost over RSP/MSEK": 3.79681,
    "out:LCP/MSEK": 0.709735,
    "out:ROI% old": 16.395768,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 118.7,
    "out:Op energy cost/MSEK": 27,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2310.024883,
    "out:Return %": 15,
    "out:Return/kSEK/y": 704,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.295708,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2255.609465,
    "out:PV (% sold (El))": 8.823257,
    "out:PV (kWh self-consumed)": 23308.75515,
    "out:PV (ratio sold/self-consumed)": 0.096771,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 297333.6136,
    "out:EL kWh savings": -41669.34292,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202611.61,
    "out:DH kr savings": 270573.5884,
    "out:El kr savings": -70837.88296,
    "out:El kr sold": 2875.902068,
    "out:El kr saved": 39624.88375,
    "out:El kr return": 42500.78582,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 124558.3539,
    "out:% savings (space heating)": 58.255571,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 35.68,
    "out:Etvv": 0,
    "out:Ef": 30.77,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4805125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.287796,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.734078,
    "out:Electricity (inc PV)": 30.688528,
    "out:Total Energy Use Pre PV": 80.734078,
    "out:Total Energy Use Post PV": 70.688528,
    "out:Primary Energy": 77.993843,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.565782,
    "out:CO2 Operational/m2": 14.908614,
    "out:Total CO2/m2": 41.474396,
    "out:Total CO2 (tons)": 118575.2852,
    "out:Klimatpaverkan":0.001* -39859.50463,
    "out:Initial Cost/MSEK": 4.876625,
    "out:Running cost/(Apt SEK y)": 13029.85714,
    "out:Running Cost over RSP/MSEK": 3.55934,
    "out:LCP/MSEK": 0.875705,
    "out:ROI% old": 16.851028,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2306.008476,
    "out:Return %": 15,
    "out:Return/kSEK/y": 735,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.234578,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2268.258392,
    "out:PV (% sold (El))": 8.872735,
    "out:PV (kWh self-consumed)": 23296.10622,
    "out:PV (ratio sold/self-consumed)": 0.097366,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 306709.1713,
    "out:EL kWh savings": -41422.69702,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 211578.79,
    "out:DH kr savings": 279105.3459,
    "out:El kr savings": -70418.58493,
    "out:El kr sold": 2892.029449,
    "out:El kr saved": 39603.38058,
    "out:El kr return": 42495.41003,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 115182.7962,
    "out:% savings (space heating)": 61.397691,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 32.51,
    "out:Etvv": 0,
    "out:Ef": 30.69,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4876625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.697369,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.093917,
    "out:Electricity (inc PV)": 30.371815,
    "out:Total Energy Use Pre PV": 81.093917,
    "out:Total Energy Use Post PV": 71.371815,
    "out:Primary Energy": 78.557163,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.960326,
    "out:CO2 Operational/m2": 14.953915,
    "out:Total CO2/m2": 41.914241,
    "out:Total CO2 (tons)": 119832.8019,
    "out:Klimatpaverkan":0.001* -38601.98791,
    "out:Initial Cost/MSEK": 4.94175,
    "out:Running cost/(Apt SEK y)": 13157.28571,
    "out:Running Cost over RSP/MSEK": 3.59452,
    "out:LCP/MSEK": 0.7754,
    "out:ROI% old": 16.527257,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 18,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2264.010897,
    "out:Return %": 15,
    "out:Return/kSEK/y": 730,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.594417,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2402.924892,
    "out:PV (% sold (El))": 9.39951,
    "out:PV (kWh self-consumed)": 23161.43972,
    "out:PV (ratio sold/self-consumed)": 0.103747,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 305538.2023,
    "out:EL kWh savings": -40517.21465,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 212224.23,
    "out:DH kr savings": 278039.7641,
    "out:El kr savings": -68879.2649,
    "out:El kr sold": 3063.729237,
    "out:El kr saved": 39374.44753,
    "out:El kr return": 42438.17676,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 116353.7652,
    "out:% savings (space heating)": 61.005253,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 34.13,
    "out:Etvv": 0,
    "out:Ef": 30.37,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4941750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.448481,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.023567,
    "out:Electricity (inc PV)": 30.276381,
    "out:Total Energy Use Pre PV": 77.023567,
    "out:Total Energy Use Post PV": 67.276381,
    "out:Primary Energy": 76.188993,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.960326,
    "out:CO2 Operational/m2": 13.478933,
    "out:Total CO2/m2": 40.439259,
    "out:Total CO2 (tons)": 115615.8288,
    "out:Klimatpaverkan":0.001* -42818.96099,
    "out:Initial Cost/MSEK": 5.01325,
    "out:Running cost/(Apt SEK y)": 12288.97143,
    "out:Running Cost over RSP/MSEK": 3.35671,
    "out:LCP/MSEK": 0.94171,
    "out:ROI% old": 16.969203,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 144.8,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 2259.402557,
    "out:Return %": 15,
    "out:Return/kSEK/y": 761,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.524067,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 2417.981463,
    "out:PV (% sold (El))": 9.458406,
    "out:PV (kWh self-consumed)": 23146.38315,
    "out:PV (ratio sold/self-consumed)": 0.104465,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 314826.772,
    "out:EL kWh savings": -40244.36887,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 221159.86,
    "out:DH kr savings": 286492.3625,
    "out:El kr savings": -68415.42708,
    "out:El kr sold": 3082.926366,
    "out:El kr saved": 39348.85136,
    "out:El kr return": 42431.77772,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 107065.1955,
    "out:% savings (space heating)": 64.11822,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 30.99,
    "out:Etvv": 0,
    "out:Ef": 30.28,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5013250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000001120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 43.56711,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.795208,
    "out:Electricity (inc PV)": 27.766508,
    "out:Total Energy Use Pre PV": 84.795208,
    "out:Total Energy Use Post PV": 71.766508,
    "out:Primary Energy": 74.954194,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.448392,
    "out:CO2 Operational/m2": -12.914736,
    "out:Total CO2/m2": 23.533656,
    "out:Total CO2 (tons)": 67282.71514,
    "out:Klimatpaverkan":0.001* -91152.07468,
    "out:Initial Cost/MSEK": 5.280875,
    "out:Running cost/(Apt SEK y)": 12854.14286,
    "out:Running Cost over RSP/MSEK": 3.51881,
    "out:LCP/MSEK": 0.511985,
    "out:ROI% old": 15.670726,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 127.8,
    "out:Op energy cost/MSEK": 26,
    "out:El price/MSEK": 17,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 44,
    "out:El bought/kWh/m2": 2080.571265,
    "out:Return %": 14,
    "out:Return/kSEK/y": 741,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.295708,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18635.166,
    "out:PV (% sold (El))": 36.447544,
    "out:PV (kWh self-consumed)": 32493.56341,
    "out:PV (ratio sold/self-consumed)": 0.573503,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 297333.6136,
    "out:EL kWh savings": -33068.64275,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 238116.73,
    "out:DH kr savings": 270573.5884,
    "out:El kr savings": -56216.69268,
    "out:El kr sold": 23759.83664,
    "out:El kr saved": 55239.0578,
    "out:El kr return": 78998.89444,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 124558.3539,
    "out:% savings (space heating)": 58.255571,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 35.68,
    "out:Etvv": 0,
    "out:Ef": 27.77,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5280875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z02000001120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.287796,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.734078,
    "out:Electricity (inc PV)": 27.689677,
    "out:Total Energy Use Pre PV": 80.734078,
    "out:Total Energy Use Post PV": 67.689677,
    "out:Primary Energy": 72.595912,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.448392,
    "out:CO2 Operational/m2": -14.415938,
    "out:Total CO2/m2": 22.032454,
    "out:Total CO2 (tons)": 62990.77909,
    "out:Klimatpaverkan":0.001* -95444.01072,
    "out:Initial Cost/MSEK": 5.352375,
    "out:Running cost/(Apt SEK y)": 11987.28571,
    "out:Running Cost over RSP/MSEK": 3.2814,
    "out:LCP/MSEK": 0.677895,
    "out:ROI% old": 16.095045,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 141.2,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 40,
    "out:El bought/kWh/m2": 2077.005311,
    "out:Return %": 14,
    "out:Return/kSEK/y": 771,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 22.234578,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18665.70565,
    "out:PV (% sold (El))": 36.507275,
    "out:PV (kWh self-consumed)": 32463.02376,
    "out:PV (ratio sold/self-consumed)": 0.574984,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 306709.1713,
    "out:EL kWh savings": -32848.98295,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247060.85,
    "out:DH kr savings": 279105.3459,
    "out:El kr savings": -55843.27101,
    "out:El kr sold": 23798.7747,
    "out:El kr saved": 55187.1404,
    "out:El kr return": 78985.91509,
    "out:% solar/total": 70,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 115182.7962,
    "out:% savings (space heating)": 61.397691,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 32.51,
    "out:Etvv": 0,
    "out:Ef": 27.69,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5352375,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z02000001120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 40.697369,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.093917,
    "out:Electricity (inc PV)": 27.407532,
    "out:Total Energy Use Pre PV": 81.093917,
    "out:Total Energy Use Post PV": 68.407532,
    "out:Primary Energy": 73.221454,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.842936,
    "out:CO2 Operational/m2": -14.701162,
    "out:Total CO2/m2": 22.141774,
    "out:Total CO2 (tons)": 63303.32494,
    "out:Klimatpaverkan":0.001* -95131.46488,
    "out:Initial Cost/MSEK": 5.4175,
    "out:Running cost/(Apt SEK y)": 12116.97143,
    "out:Running Cost over RSP/MSEK": 3.31719,
    "out:LCP/MSEK": 0.57698,
    "out:ROI% old": 15.807186,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 141.2,
    "out:Op energy cost/MSEK": 24,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 7,
    "out:DH+ DHW/kWh/m2": 41,
    "out:El bought/kWh/m2": 2039.703656,
    "out:Return %": 14,
    "out:Return/kSEK/y": 767,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.594417,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 18986.96911,
    "out:PV (% sold (El))": 37.135617,
    "out:PV (kWh self-consumed)": 32141.7603,
    "out:PV (ratio sold/self-consumed)": 0.590726,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 305538.2023,
    "out:EL kWh savings": -32042.33048,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 247776.19,
    "out:DH kr savings": 278039.7641,
    "out:El kr savings": -54471.96182,
    "out:El kr sold": 24208.38561,
    "out:El kr saved": 54640.99251,
    "out:El kr return": 78849.37812,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 116353.7652,
    "out:% savings (space heating)": 61.005253,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 34.13,
    "out:Etvv": 0,
    "out:Ef": 27.41,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5417500,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z02000001120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 37.448481,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 40.023567,
    "out:Electricity (inc PV)": 27.322498,
    "out:Total Energy Use Pre PV": 77.023567,
    "out:Total Energy Use Post PV": 64.322498,
    "out:Primary Energy": 70.872003,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.842936,
    "out:CO2 Operational/m2": -16.21239,
    "out:Total CO2/m2": 20.630546,
    "out:Total CO2 (tons)": 58982.72456,
    "out:Klimatpaverkan":0.001* -99452.06526,
    "out:Initial Cost/MSEK": 5.489,
    "out:Running cost/(Apt SEK y)": 11248.88571,
    "out:Running Cost over RSP/MSEK": 3.07945,
    "out:LCP/MSEK": 0.74322,
    "out:ROI% old": 16.220024,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 156.2,
    "out:Op energy cost/MSEK": 22,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 6,
    "out:DH+ DHW/kWh/m2": 37,
    "out:El bought/kWh/m2": 2035.609059,
    "out:Return %": 15,
    "out:Return/kSEK/y": 797,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 739500,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 36975,
    "out:EAHP (replacement cost)": 555960.7717,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 21.524067,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 19022.429,
    "out:PV (% sold (El))": 37.204971,
    "out:PV (kWh self-consumed)": 32106.30041,
    "out:PV (ratio sold/self-consumed)": 0.592483,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 314826.772,
    "out:EL kWh savings": -31799.2183,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 256687.29,
    "out:DH kr savings": 286492.3625,
    "out:El kr savings": -54058.67111,
    "out:El kr sold": 24253.59698,
    "out:El kr saved": 54580.71069,
    "out:El kr return": 78834.30767,
    "out:% solar/total": 69,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 107065.1955,
    "out:% savings (space heating)": 64.11822,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 30.99,
    "out:Etvv": 0,
    "out:Ef": 27.32,
    "out:heatPumpQuantity": 5,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5489000,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z02000002000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.558428,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.022081,
    "out:Electricity (inc PV)": 49.660648,
    "out:Total Energy Use Pre PV": 63.022081,
    "out:Total Energy Use Post PV": 58.660648,
    "out:Primary Energy": 92.714977,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.187789,
    "out:CO2 Operational/m2": 10.371203,
    "out:Total CO2/m2": 27.558992,
    "out:Total CO2 (tons)": 78791.1495,
    "out:Klimatpaverkan":0.001* -79643.64031,
    "out:Initial Cost/MSEK": 5.563,
    "out:Running cost/(Apt SEK y)": 9434,
    "out:Running Cost over RSP/MSEK": 2.55906,
    "out:LCP/MSEK": 1.18961,
    "out:ROI% old": 17.340618,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 59,
    "out:Energy use kWh/m2": 59,
    "out:Energy savings %": 178,
    "out:Op energy cost/MSEK": 17,
    "out:El price/MSEK": 16,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3781.54567,
    "out:Return %": 15,
    "out:Return/kSEK/y": 861,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.522581,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 397423.4245,
    "out:EL kWh savings": -95663.98216,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199026.55,
    "out:DH kr savings": 361655.3163,
    "out:El kr savings": -162628.7697,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 24468.54297,
    "out:% savings (space heating)": 91.799624,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.75,
    "out:Etvv": 0,
    "out:Ef": 49.66,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5563000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.694887,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.531929,
    "out:Electricity (inc PV)": 48.966605,
    "out:Total Energy Use Pre PV": 60.531929,
    "out:Total Energy Use Post PV": 55.966605,
    "out:Primary Energy": 90.632208,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.187789,
    "out:CO2 Operational/m2": 9.586531,
    "out:Total CO2/m2": 26.77432,
    "out:Total CO2 (tons)": 76547.7725,
    "out:Klimatpaverkan":0.001* -81887.01731,
    "out:Initial Cost/MSEK": 5.6345,
    "out:Running cost/(Apt SEK y)": 8936.542857,
    "out:Running Cost over RSP/MSEK": 2.42304,
    "out:LCP/MSEK": 1.25413,
    "out:ROI% old": 17.465436,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 192.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3747.23503,
    "out:Return %": 16,
    "out:Return/kSEK/y": 878,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.032429,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402751.2877,
    "out:EL kWh savings": -93679.71344,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207248.16,
    "out:DH kr savings": 366503.6718,
    "out:El kr savings": -159255.5128,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 19140.67984,
    "out:% savings (space heating)": 93.585201,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 3.56,
    "out:Etvv": 0,
    "out:Ef": 48.97,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5634500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.998182,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.531246,
    "out:Electricity (inc PV)": 48.692647,
    "out:Total Energy Use Pre PV": 59.531246,
    "out:Total Energy Use Post PV": 55.692647,
    "out:Primary Energy": 90.583658,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.582333,
    "out:CO2 Operational/m2": 9.454433,
    "out:Total CO2/m2": 27.036766,
    "out:Total CO2 (tons)": 77298.10553,
    "out:Klimatpaverkan":0.001* -81136.68428,
    "out:Initial Cost/MSEK": 5.699625,
    "out:Running cost/(Apt SEK y)": 8797.6,
    "out:Running Cost over RSP/MSEK": 2.38544,
    "out:LCP/MSEK": 1.226605,
    "out:ROI% old": 17.360115,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 56,
    "out:Energy use kWh/m2": 56,
    "out:Energy savings %": 192.9,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3677.18722,
    "out:Return %": 15,
    "out:Return/kSEK/y": 883,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.031746,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401884.1673,
    "out:EL kWh savings": -92896.4676,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 207790.6,
    "out:DH kr savings": 365714.5923,
    "out:El kr savings": -157923.9949,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 20007.80015,
    "out:% savings (space heating)": 93.294595,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.2,
    "out:Etvv": 0,
    "out:Ef": 48.69,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5699625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.352649,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.975031,
    "out:Electricity (inc PV)": 47.964828,
    "out:Total Energy Use Pre PV": 56.975031,
    "out:Total Energy Use Post PV": 52.964828,
    "out:Primary Energy": 88.518115,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.582333,
    "out:CO2 Operational/m2": 8.66104,
    "out:Total CO2/m2": 26.243373,
    "out:Total CO2 (tons)": 75029.79519,
    "out:Klimatpaverkan":0.001* -83404.99462,
    "out:Initial Cost/MSEK": 5.771125,
    "out:Running cost/(Apt SEK y)": 8290.971429,
    "out:Running Cost over RSP/MSEK": 2.24693,
    "out:LCP/MSEK": 1.293615,
    "out:ROI% old": 17.4879,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 209.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3638.25217,
    "out:Return %": 16,
    "out:Return/kSEK/y": 901,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.475531,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406588.7457,
    "out:EL kWh savings": -90815.63331,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215609.18,
    "out:DH kr savings": 369995.7586,
    "out:El kr savings": -154386.5766,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 15303.22182,
    "out:% savings (space heating)": 94.871285,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 3.12,
    "out:Etvv": 0,
    "out:Ef": 47.96,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5771125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.558428,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.022081,
    "out:Electricity (inc PV)": 41.012297,
    "out:Total Energy Use Pre PV": 63.022081,
    "out:Total Energy Use Post PV": 50.012297,
    "out:Primary Energy": 77.147946,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.070399,
    "out:CO2 Operational/m2": 8.586539,
    "out:Total CO2/m2": 35.656938,
    "out:Total CO2 (tons)": 101943.1746,
    "out:Klimatpaverkan":0.001* -56491.61523,
    "out:Initial Cost/MSEK": 6.03875,
    "out:Running cost/(Apt SEK y)": 8197.457143,
    "out:Running Cost over RSP/MSEK": 2.22999,
    "out:LCP/MSEK": 1.04293,
    "out:ROI% old": 16.752947,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 50,
    "out:Energy use kWh/m2": 50,
    "out:Energy savings %": 228,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 3200.929721,
    "out:Return %": 15,
    "out:Return/kSEK/y": 904,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.522581,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 424.604769,
    "out:PV (% sold (El))": 1.660924,
    "out:PV (kWh self-consumed)": 25139.75985,
    "out:PV (ratio sold/self-consumed)": 0.01689,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 397423.4245,
    "out:EL kWh savings": -70938.34936,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 241601.49,
    "out:DH kr savings": 361655.3163,
    "out:El kr savings": -120595.1939,
    "out:El kr sold": 541.3710809,
    "out:El kr saved": 42737.59174,
    "out:El kr return": 43278.96282,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 24468.54297,
    "out:% savings (space heating)": 91.799624,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.75,
    "out:Etvv": 0,
    "out:Ef": 41.01,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6038750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.694887,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.531929,
    "out:Electricity (inc PV)": 40.3472,
    "out:Total Energy Use Pre PV": 60.531929,
    "out:Total Energy Use Post PV": 47.3472,
    "out:Primary Energy": 75.117279,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.070399,
    "out:CO2 Operational/m2": 7.737661,
    "out:Total CO2/m2": 34.80806,
    "out:Total CO2 (tons)": 99516.23265,
    "out:Klimatpaverkan":0.001* -58918.55717,
    "out:Initial Cost/MSEK": 6.11025,
    "out:Running cost/(Apt SEK y)": 7700.485714,
    "out:Running Cost over RSP/MSEK": 2.09408,
    "out:LCP/MSEK": 1.10734,
    "out:ROI% old": 16.874666,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 248.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3167.226452,
    "out:Return %": 15,
    "out:Return/kSEK/y": 921,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.032429,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 463.822448,
    "out:PV (% sold (El))": 1.814332,
    "out:PV (kWh self-consumed)": 25100.54217,
    "out:PV (ratio sold/self-consumed)": 0.018479,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402751.2877,
    "out:EL kWh savings": -69036.83724,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249732.42,
    "out:DH kr savings": 366503.6718,
    "out:El kr savings": -117362.6233,
    "out:El kr sold": 591.3736217,
    "out:El kr saved": 42670.92168,
    "out:El kr return": 43262.2953,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 19140.67984,
    "out:% savings (space heating)": 93.585201,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 3.56,
    "out:Etvv": 0,
    "out:Ef": 40.35,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6110250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.998182,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.531246,
    "out:Electricity (inc PV)": 40.08515,
    "out:Total Energy Use Pre PV": 59.531246,
    "out:Total Energy Use Post PV": 47.08515,
    "out:Primary Energy": 75.090163,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.464943,
    "out:CO2 Operational/m2": 7.466127,
    "out:Total CO2/m2": 34.93107,
    "out:Total CO2 (tons)": 99867.9182,
    "out:Klimatpaverkan":0.001* -58566.87162,
    "out:Initial Cost/MSEK": 6.175375,
    "out:Running cost/(Apt SEK y)": 7562.542857,
    "out:Running Cost over RSP/MSEK": 2.05674,
    "out:LCP/MSEK": 1.079555,
    "out:ROI% old": 16.783087,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 248.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3098.570746,
    "out:Return %": 15,
    "out:Return/kSEK/y": 926,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.031746,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 548.163446,
    "out:PV (% sold (El))": 2.144248,
    "out:PV (kWh self-consumed)": 25016.20117,
    "out:PV (ratio sold/self-consumed)": 0.021912,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401884.1673,
    "out:EL kWh savings": -68287.63637,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 250324.52,
    "out:DH kr savings": 365714.5923,
    "out:El kr savings": -116088.9818,
    "out:El kr sold": 698.9083931,
    "out:El kr saved": 42527.54199,
    "out:El kr return": 43226.45038,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 20007.80015,
    "out:% savings (space heating)": 93.294595,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.2,
    "out:Etvv": 0,
    "out:Ef": 40.09,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6175375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.352649,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.975031,
    "out:Electricity (inc PV)": 39.390365,
    "out:Total Energy Use Pre PV": 56.975031,
    "out:Total Energy Use Post PV": 44.390365,
    "out:Primary Energy": 73.084082,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.464943,
    "out:CO2 Operational/m2": 6.590253,
    "out:Total CO2/m2": 34.055196,
    "out:Total CO2 (tons)": 97363.79471,
    "out:Klimatpaverkan":0.001* -61070.99511,
    "out:Initial Cost/MSEK": 6.246875,
    "out:Running cost/(Apt SEK y)": 7056.542857,
    "out:Running Cost over RSP/MSEK": 1.91838,
    "out:LCP/MSEK": 1.146415,
    "out:ROI% old": 16.907402,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 272.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3060.496713,
    "out:Return %": 15,
    "out:Return/kSEK/y": 944,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.475531,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 598.287205,
    "out:PV (% sold (El))": 2.340317,
    "out:PV (kWh self-consumed)": 24966.07741,
    "out:PV (ratio sold/self-consumed)": 0.023964,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406588.7457,
    "out:EL kWh savings": -66301.24628,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 258046.46,
    "out:DH kr savings": 369995.7586,
    "out:El kr savings": -112712.1187,
    "out:El kr sold": 762.8161859,
    "out:El kr saved": 42442.3316,
    "out:El kr return": 43205.14778,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 15303.22182,
    "out:% savings (space heating)": 94.871285,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 3.12,
    "out:Etvv": 0,
    "out:Ef": 39.39,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6246875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 8.558428,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 54.022081,
    "out:Electricity (inc PV)": 36.852139,
    "out:Total Energy Use Pre PV": 63.022081,
    "out:Total Energy Use Post PV": 45.852139,
    "out:Primary Energy": 69.659661,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.953009,
    "out:CO2 Operational/m2": -13.434758,
    "out:Total CO2/m2": 23.518251,
    "out:Total CO2 (tons)": 67238.67225,
    "out:Klimatpaverkan":0.001* -91196.11757,
    "out:Initial Cost/MSEK": 6.5145,
    "out:Running cost/(Apt SEK y)": 7104.857143,
    "out:Running Cost over RSP/MSEK": 1.93847,
    "out:LCP/MSEK": 0.8587,
    "out:ROI% old": 16.168766,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 46,
    "out:Energy use kWh/m2": 46,
    "out:Energy savings %": 256.5,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 9,
    "out:El bought/kWh/m2": 2869.811076,
    "out:Return %": 14,
    "out:Return/kSEK/y": 942,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.522581,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12704.93785,
    "out:PV (% sold (El))": 24.848922,
    "out:PV (kWh self-consumed)": 38423.79156,
    "out:PV (ratio sold/self-consumed)": 0.330653,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 397423.4245,
    "out:EL kWh savings": -59044.45894,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 277478.53,
    "out:DH kr savings": 361655.3163,
    "out:El kr savings": -100375.5802,
    "out:El kr sold": 16198.79575,
    "out:El kr saved": 65320.44565,
    "out:El kr return": 81519.24141,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 24468.54297,
    "out:% savings (space heating)": 91.799624,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.75,
    "out:Etvv": 0,
    "out:Ef": 36.85,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6514500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.694887,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 53.531929,
    "out:Electricity (inc PV)": 36.263788,
    "out:Total Energy Use Pre PV": 60.531929,
    "out:Total Energy Use Post PV": 43.263788,
    "out:Primary Energy": 67.767137,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.953009,
    "out:CO2 Operational/m2": -14.555818,
    "out:Total CO2/m2": 22.397191,
    "out:Total CO2 (tons)": 64033.56206,
    "out:Klimatpaverkan":0.001* -94401.22775,
    "out:Initial Cost/MSEK": 6.586,
    "out:Running cost/(Apt SEK y)": 6609.8,
    "out:Running Cost over RSP/MSEK": 1.80307,
    "out:LCP/MSEK": 0.9226,
    "out:ROI% old": 16.286929,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 281.4,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2839.914897,
    "out:Return %": 15,
    "out:Return/kSEK/y": 959,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 35.032429,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 12898.83006,
    "out:PV (% sold (El))": 25.228145,
    "out:PV (kWh self-consumed)": 38229.89935,
    "out:PV (ratio sold/self-consumed)": 0.337402,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 402751.2877,
    "out:EL kWh savings": -57362.36361,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 285433.66,
    "out:DH kr savings": 366503.6718,
    "out:El kr savings": -97516.01814,
    "out:El kr sold": 16446.00833,
    "out:El kr saved": 64990.82889,
    "out:El kr return": 81436.83722,
    "out:% solar/total": 83,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 19140.67984,
    "out:% savings (space heating)": 93.585201,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 3.56,
    "out:Etvv": 0,
    "out:Ef": 36.26,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6586000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.998182,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.531246,
    "out:Electricity (inc PV)": 36.031998,
    "out:Total Energy Use Pre PV": 59.531246,
    "out:Total Energy Use Post PV": 43.031998,
    "out:Primary Energy": 67.794489,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.347553,
    "out:CO2 Operational/m2": -15.38565,
    "out:Total CO2/m2": 21.961903,
    "out:Total CO2 (tons)": 62789.0738,
    "out:Klimatpaverkan":0.001* -95645.71601,
    "out:Initial Cost/MSEK": 6.651125,
    "out:Running cost/(Apt SEK y)": 6475.714286,
    "out:Running Cost over RSP/MSEK": 1.76677,
    "out:LCP/MSEK": 0.893775,
    "out:ROI% old": 16.205422,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 281.4,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2779.015842,
    "out:Return %": 14,
    "out:Return/kSEK/y": 964,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 34.031746,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 13300.99939,
    "out:PV (% sold (El))": 26.014727,
    "out:PV (kWh self-consumed)": 37827.73001,
    "out:PV (ratio sold/self-consumed)": 0.35162,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 401884.1673,
    "out:EL kWh savings": -56699.67608,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 286283.92,
    "out:DH kr savings": 365714.5923,
    "out:El kr savings": -96389.44933,
    "out:El kr sold": 16958.77423,
    "out:El kr saved": 64307.14102,
    "out:El kr return": 81265.91525,
    "out:% solar/total": 82,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 20007.80015,
    "out:% savings (space heating)": 93.294595,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.2,
    "out:Etvv": 0,
    "out:Ef": 36.03,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6651125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002020001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.352649,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.975031,
    "out:Electricity (inc PV)": 35.416972,
    "out:Total Energy Use Pre PV": 56.975031,
    "out:Total Energy Use Post PV": 40.416972,
    "out:Primary Energy": 65.931975,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.347553,
    "out:CO2 Operational/m2": -16.573469,
    "out:Total CO2/m2": 20.774084,
    "out:Total CO2 (tons)": 59393.09965,
    "out:Klimatpaverkan":0.001* -99041.69016,
    "out:Initial Cost/MSEK": 6.722625,
    "out:Running cost/(Apt SEK y)": 5971.857143,
    "out:Running Cost over RSP/MSEK": 1.62899,
    "out:LCP/MSEK": 0.960055,
    "out:ROI% old": 16.325851,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 310,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2745.247653,
    "out:Return %": 15,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 33.475531,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 13528.67443,
    "out:PV (% sold (El))": 26.460025,
    "out:PV (kWh self-consumed)": 37600.05498,
    "out:PV (ratio sold/self-consumed)": 0.359805,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406588.7457,
    "out:EL kWh savings": -54941.31693,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 293844.58,
    "out:DH kr savings": 369995.7586,
    "out:El kr savings": -93400.23879,
    "out:El kr sold": 17249.05989,
    "out:El kr saved": 63920.09347,
    "out:El kr return": 81169.15336,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 15303.22182,
    "out:% savings (space heating)": 94.871285,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 3.12,
    "out:Etvv": 0,
    "out:Ef": 35.42,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6722625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.521631,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.02111,
    "out:Electricity (inc PV)": 48.365588,
    "out:Total Energy Use Pre PV": 59.02111,
    "out:Total Energy Use Post PV": 55.365588,
    "out:Primary Energy": 89.875456,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.187789,
    "out:CO2 Operational/m2": 9.387091,
    "out:Total CO2/m2": 26.57488,
    "out:Total CO2 (tons)": 75977.5736,
    "out:Klimatpaverkan":0.001* -82457.21621,
    "out:Initial Cost/MSEK": 5.655375,
    "out:Running cost/(Apt SEK y)": 8726.742857,
    "out:Running Cost over RSP/MSEK": 2.36627,
    "out:LCP/MSEK": 1.290025,
    "out:ROI% old": 17.544372,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 55,
    "out:Energy use kWh/m2": 55,
    "out:Energy savings %": 198.2,
    "out:Op energy cost/MSEK": 16,
    "out:El price/MSEK": 15,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3641.4777,
    "out:Return %": 16,
    "out:Return/kSEK/y": 885,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.52161,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 403246.6265,
    "out:EL kWh savings": -91961.40602,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210620.04,
    "out:DH kr savings": 366954.4301,
    "out:El kr savings": -156334.3902,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 18645.34099,
    "out:% savings (space heating)": 93.751209,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.02,
    "out:Etvv": 0,
    "out:Ef": 48.37,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5655375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.965552,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.437788,
    "out:Electricity (inc PV)": 47.627736,
    "out:Total Energy Use Pre PV": 56.437788,
    "out:Total Energy Use Post PV": 52.627736,
    "out:Primary Energy": 87.815031,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.187789,
    "out:CO2 Operational/m2": 8.59012,
    "out:Total CO2/m2": 25.777909,
    "out:Total CO2 (tons)": 73699.03376,
    "out:Klimatpaverkan":0.001* -84735.75605,
    "out:Initial Cost/MSEK": 5.726875,
    "out:Running cost/(Apt SEK y)": 8216.371429,
    "out:Running Cost over RSP/MSEK": 2.22674,
    "out:LCP/MSEK": 1.358055,
    "out:ROI% old": 17.673388,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 209.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3600.64516,
    "out:Return %": 16,
    "out:Return/kSEK/y": 903,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.938288,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 407695.4559,
    "out:EL kWh savings": -89851.88739,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218254.66,
    "out:DH kr savings": 371002.8648,
    "out:El kr savings": -152748.2086,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 14196.51161,
    "out:% savings (space heating)": 95.242188,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 2.98,
    "out:Etvv": 0,
    "out:Ef": 47.63,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5726875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.493071,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.761886,
    "out:Electricity (inc PV)": 47.573029,
    "out:Total Energy Use Pre PV": 55.761886,
    "out:Total Energy Use Post PV": 52.573029,
    "out:Primary Energy": 88.179661,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.582333,
    "out:CO2 Operational/m2": 8.500895,
    "out:Total CO2/m2": 26.083228,
    "out:Total CO2 (tons)": 74571.94069,
    "out:Klimatpaverkan":0.001* -83862.84913,
    "out:Initial Cost/MSEK": 5.792,
    "out:Running cost/(Apt SEK y)": 8122.514286,
    "out:Running Cost over RSP/MSEK": 2.20135,
    "out:LCP/MSEK": 1.31832,
    "out:ROI% old": 17.537293,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 53,
    "out:Energy use kWh/m2": 53,
    "out:Energy savings %": 209.4,
    "out:Op energy cost/MSEK": 15,
    "out:El price/MSEK": 14,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3553.33202,
    "out:Return %": 16,
    "out:Return/kSEK/y": 907,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.262386,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406187.2792,
    "out:EL kWh savings": -89695.48009,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217148.11,
    "out:DH kr savings": 369630.4241,
    "out:El kr savings": -152482.3162,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 15704.68827,
    "out:% savings (space heating)": 94.736738,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 3.64,
    "out:Etvv": 0,
    "out:Ef": 47.57,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5792000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.147828,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.114674,
    "out:Electricity (inc PV)": 46.812488,
    "out:Total Energy Use Pre PV": 54.114674,
    "out:Total Energy Use Post PV": 50.812488,
    "out:Primary Energy": 86.130807,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.582333,
    "out:CO2 Operational/m2": 8.055474,
    "out:Total CO2/m2": 25.637807,
    "out:Total CO2 (tons)": 73298.48219,
    "out:Klimatpaverkan":0.001* -85136.30763,
    "out:Initial Cost/MSEK": 5.8635,
    "out:Running cost/(Apt SEK y)": 7817.942857,
    "out:Running Cost over RSP/MSEK": 2.11823,
    "out:LCP/MSEK": 1.32994,
    "out:ROI% old": 17.525954,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 51,
    "out:Energy use kWh/m2": 51,
    "out:Energy savings %": 221.6,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 3508.02718,
    "out:Return %": 16,
    "out:Return/kSEK/y": 917,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.615174,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 410033.3285,
    "out:EL kWh savings": -87521.09361,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 224344.47,
    "out:DH kr savings": 373130.329,
    "out:El kr savings": -148785.8591,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 11858.63895,
    "out:% savings (space heating)": 96.025701,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 2.67,
    "out:Etvv": 0,
    "out:Ef": 46.81,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5863500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.521631,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.02111,
    "out:Electricity (inc PV)": 39.772712,
    "out:Total Energy Use Pre PV": 59.02111,
    "out:Total Energy Use Post PV": 46.772712,
    "out:Primary Energy": 74.40828,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.070399,
    "out:CO2 Operational/m2": 7.323284,
    "out:Total CO2/m2": 34.393683,
    "out:Total CO2 (tons)": 98331.52893,
    "out:Klimatpaverkan":0.001* -60103.26088,
    "out:Initial Cost/MSEK": 6.131125,
    "out:Running cost/(Apt SEK y)": 7492.257143,
    "out:Running Cost over RSP/MSEK": 2.03771,
    "out:LCP/MSEK": 1.142835,
    "out:ROI% old": 16.948556,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 47,
    "out:Energy use kWh/m2": 47,
    "out:Energy savings %": 248.9,
    "out:Op energy cost/MSEK": 14,
    "out:El price/MSEK": 13,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 3063.648613,
    "out:Return %": 15,
    "out:Return/kSEK/y": 929,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.52161,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 594.031226,
    "out:PV (% sold (El))": 2.323669,
    "out:PV (kWh self-consumed)": 24970.33339,
    "out:PV (ratio sold/self-consumed)": 0.023789,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 403246.6265,
    "out:EL kWh savings": -67394.37623,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 253141.38,
    "out:DH kr savings": 366954.4301,
    "out:El kr savings": -114570.4396,
    "out:El kr sold": 757.3898126,
    "out:El kr saved": 42449.56676,
    "out:El kr return": 43206.95657,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 18645.34099,
    "out:% savings (space heating)": 93.751209,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.02,
    "out:Etvv": 0,
    "out:Ef": 39.77,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6131125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.965552,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.437788,
    "out:Electricity (inc PV)": 39.069201,
    "out:Total Energy Use Pre PV": 56.437788,
    "out:Total Energy Use Post PV": 44.069201,
    "out:Primary Energy": 72.409668,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.070399,
    "out:CO2 Operational/m2": 6.435832,
    "out:Total CO2/m2": 33.506231,
    "out:Total CO2 (tons)": 95794.30394,
    "out:Klimatpaverkan":0.001* -62640.48587,
    "out:Initial Cost/MSEK": 6.202625,
    "out:Running cost/(Apt SEK y)": 6982.571429,
    "out:Running Cost over RSP/MSEK": 1.89835,
    "out:LCP/MSEK": 1.210695,
    "out:ROI% old": 17.074154,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 272.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 3023.779817,
    "out:Return %": 15,
    "out:Return/kSEK/y": 946,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.938288,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 649.366023,
    "out:PV (% sold (El))": 2.540122,
    "out:PV (kWh self-consumed)": 24914.99859,
    "out:PV (ratio sold/self-consumed)": 0.026063,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 407695.4559,
    "out:EL kWh savings": -65383.0385,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 260679.64,
    "out:DH kr savings": 371002.8648,
    "out:El kr savings": -111151.1655,
    "out:El kr sold": 827.9416795,
    "out:El kr saved": 42355.4976,
    "out:El kr return": 43183.43928,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 14196.51161,
    "out:% savings (space heating)": 95.242188,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 2.98,
    "out:Etvv": 0,
    "out:Ef": 39.07,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6202625,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.493071,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.761886,
    "out:Electricity (inc PV)": 39.017107,
    "out:Total Energy Use Pre PV": 55.761886,
    "out:Total Energy Use Post PV": 44.017107,
    "out:Primary Energy": 72.779002,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.464943,
    "out:CO2 Operational/m2": 6.235297,
    "out:Total CO2/m2": 33.70024,
    "out:Total CO2 (tons)": 96348.97561,
    "out:Klimatpaverkan":0.001* -62085.8142,
    "out:Initial Cost/MSEK": 6.26775,
    "out:Running cost/(Apt SEK y)": 6889.542857,
    "out:Running Cost over RSP/MSEK": 1.87316,
    "out:LCP/MSEK": 1.17076,
    "out:ROI% old": 16.954159,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 44,
    "out:Energy use kWh/m2": 44,
    "out:Energy savings %": 272.7,
    "out:Op energy cost/MSEK": 13,
    "out:El price/MSEK": 12,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2977.670312,
    "out:Return %": 15,
    "out:Return/kSEK/y": 950,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.262386,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 717.872483,
    "out:PV (% sold (El))": 2.808098,
    "out:PV (kWh self-consumed)": 24846.49213,
    "out:PV (ratio sold/self-consumed)": 0.028892,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406187.2792,
    "out:EL kWh savings": -65234.10177,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 259647.74,
    "out:DH kr savings": 369630.4241,
    "out:El kr savings": -110897.973,
    "out:El kr sold": 915.2874158,
    "out:El kr saved": 42239.03662,
    "out:El kr return": 43154.32404,
    "out:% solar/total": 54,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 15704.68827,
    "out:% savings (space heating)": 94.736738,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 3.64,
    "out:Etvv": 0,
    "out:Ef": 39.02,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6267750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.147828,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.114674,
    "out:Electricity (inc PV)": 38.293676,
    "out:Total Energy Use Pre PV": 54.114674,
    "out:Total Energy Use Post PV": 42.293676,
    "out:Primary Energy": 70.796946,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 27.464943,
    "out:CO2 Operational/m2": 5.6762,
    "out:Total CO2/m2": 33.141143,
    "out:Total CO2 (tons)": 94750.51746,
    "out:Klimatpaverkan":0.001* -63684.27235,
    "out:Initial Cost/MSEK": 6.33925,
    "out:Running cost/(Apt SEK y)": 6585.828571,
    "out:Running Cost over RSP/MSEK": 1.79025,
    "out:LCP/MSEK": 1.18217,
    "out:ROI% old": 16.949774,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 42,
    "out:Energy use kWh/m2": 42,
    "out:Energy savings %": 290.5,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2933.607246,
    "out:Return %": 15,
    "out:Return/kSEK/y": 960,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.615174,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 787.271905,
    "out:PV (% sold (El))": 3.079568,
    "out:PV (kWh self-consumed)": 24777.09271,
    "out:PV (ratio sold/self-consumed)": 0.031774,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 410033.3285,
    "out:EL kWh savings": -63165.81277,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 266752.22,
    "out:DH kr savings": 373130.329,
    "out:El kr savings": -107381.8817,
    "out:El kr sold": 1003.771679,
    "out:El kr saved": 42121.05761,
    "out:El kr return": 43124.82928,
    "out:% solar/total": 53,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 11858.63895,
    "out:% savings (space heating)": 96.025701,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 2.67,
    "out:Etvv": 0,
    "out:Ef": 38.29,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6339250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 6.521631,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 52.02111,
    "out:Electricity (inc PV)": 35.755527,
    "out:Total Energy Use Pre PV": 59.02111,
    "out:Total Energy Use Post PV": 42.755527,
    "out:Primary Energy": 67.177347,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.953009,
    "out:CO2 Operational/m2": -15.814567,
    "out:Total CO2/m2": 21.138442,
    "out:Total CO2 (tons)": 60434.79906,
    "out:Klimatpaverkan":0.001* -97999.99075,
    "out:Initial Cost/MSEK": 6.606875,
    "out:Running cost/(Apt SEK y)": 6407.4,
    "out:Running Cost over RSP/MSEK": 1.74827,
    "out:LCP/MSEK": 0.956525,
    "out:ROI% old": 16.35396,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 43,
    "out:Energy use kWh/m2": 43,
    "out:Energy savings %": 281.4,
    "out:Op energy cost/MSEK": 12,
    "out:El price/MSEK": 11,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 7,
    "out:El bought/kWh/m2": 2748.042817,
    "out:Return %": 15,
    "out:Return/kSEK/y": 967,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 33.52161,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 13509.70603,
    "out:PV (% sold (El))": 26.422925,
    "out:PV (kWh self-consumed)": 37619.02338,
    "out:PV (ratio sold/self-consumed)": 0.359119,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 403246.6265,
    "out:EL kWh savings": -55909.24557,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 289133.59,
    "out:DH kr savings": 366954.4301,
    "out:El kr savings": -95045.71747,
    "out:El kr sold": 17224.87519,
    "out:El kr saved": 63952.33974,
    "out:El kr return": 81177.21493,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 18645.34099,
    "out:% savings (space heating)": 93.751209,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 4.02,
    "out:Etvv": 0,
    "out:Ef": 35.76,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6606875,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.965552,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 51.437788,
    "out:Electricity (inc PV)": 35.132577,
    "out:Total Energy Use Pre PV": 56.437788,
    "out:Total Energy Use Post PV": 40.132577,
    "out:Primary Energy": 65.323745,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 36.953009,
    "out:CO2 Operational/m2": -17.030291,
    "out:Total CO2/m2": 19.922718,
    "out:Total CO2 (tons)": 56959.04453,
    "out:Klimatpaverkan":0.001* -101475.7453,
    "out:Initial Cost/MSEK": 6.678375,
    "out:Running cost/(Apt SEK y)": 5899.971429,
    "out:Running Cost over RSP/MSEK": 1.60952,
    "out:LCP/MSEK": 1.023775,
    "out:ROI% old": 16.475672,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 310,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2712.690162,
    "out:Return %": 15,
    "out:Return/kSEK/y": 984,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 32.938288,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 13751.72031,
    "out:PV (% sold (El))": 26.896268,
    "out:PV (kWh self-consumed)": 37377.0091,
    "out:PV (ratio sold/self-consumed)": 0.367919,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 407695.4559,
    "out:EL kWh savings": -54128.23172,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 296518.31,
    "out:DH kr savings": 371002.8648,
    "out:El kr savings": -92017.99392,
    "out:El kr sold": 17533.44339,
    "out:El kr saved": 63540.91547,
    "out:El kr return": 81074.35886,
    "out:% solar/total": 81,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 14196.51161,
    "out:% savings (space heating)": 95.242188,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 2.98,
    "out:Etvv": 0,
    "out:Ef": 35.13,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6678375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 5.493071,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.761886,
    "out:Electricity (inc PV)": 35.086471,
    "out:Total Energy Use Pre PV": 55.761886,
    "out:Total Energy Use Post PV": 40.086471,
    "out:Primary Energy": 65.703857,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.347553,
    "out:CO2 Operational/m2": -17.612202,
    "out:Total CO2/m2": 19.735351,
    "out:Total CO2 (tons)": 56423.36233,
    "out:Klimatpaverkan":0.001* -102011.4275,
    "out:Initial Cost/MSEK": 6.7435,
    "out:Running cost/(Apt SEK y)": 5809.571429,
    "out:Running Cost over RSP/MSEK": 1.58504,
    "out:LCP/MSEK": 0.98313,
    "out:ROI% old": 16.368418,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 40,
    "out:Energy use kWh/m2": 40,
    "out:Energy savings %": 310,
    "out:Op energy cost/MSEK": 11,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 5,
    "out:El bought/kWh/m2": 2671.815439,
    "out:Return %": 15,
    "out:Return/kSEK/y": 987,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 32.262386,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 14036.20571,
    "out:PV (% sold (El))": 27.452679,
    "out:PV (kWh self-consumed)": 37092.5237,
    "out:PV (ratio sold/self-consumed)": 0.378411,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 406187.2792,
    "out:EL kWh savings": -53996.41468,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 295732.68,
    "out:DH kr savings": 369630.4241,
    "out:El kr savings": -91793.90495,
    "out:El kr sold": 17896.16228,
    "out:El kr saved": 63057.29028,
    "out:El kr return": 80953.45256,
    "out:% solar/total": 80,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 15704.68827,
    "out:% savings (space heating)": 94.736738,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 3.64,
    "out:Etvv": 0,
    "out:Ef": 35.09,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6743500,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000002120001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 2,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 4.147828,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 50.114674,
    "out:Electricity (inc PV)": 34.446636,
    "out:Total Energy Use Pre PV": 54.114674,
    "out:Total Energy Use Post PV": 38.446636,
    "out:Primary Energy": 63.872274,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 37.347553,
    "out:CO2 Operational/m2": -18.53712,
    "out:Total CO2/m2": 18.810433,
    "out:Total CO2 (tons)": 53779.02206,
    "out:Klimatpaverkan":0.001* -104655.7678,
    "out:Initial Cost/MSEK": 6.815,
    "out:Running cost/(Apt SEK y)": 5508.371429,
    "out:Running Cost over RSP/MSEK": 1.50281,
    "out:LCP/MSEK": 0.99386,
    "out:ROI% old": 16.36906,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 38,
    "out:Energy use kWh/m2": 38,
    "out:Energy savings %": 331.6,
    "out:Op energy cost/MSEK": 10,
    "out:El price/MSEK": 10,
    "out:Heating price/MSEK": 1,
    "out:DH+ DHW/kWh/m2": 4,
    "out:El bought/kWh/m2": 2632.768962,
    "out:Return %": 15,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 1479000,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 73950,
    "out:EAHP (replacement cost)": 1105420.165,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 31.615174,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 14313.05639,
    "out:PV (% sold (El))": 27.994156,
    "out:PV (kWh self-consumed)": 36815.67302,
    "out:PV (ratio sold/self-consumed)": 0.388776,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 410033.3285,
    "out:EL kWh savings": -52167.12661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 302695.36,
    "out:DH kr savings": 373130.329,
    "out:El kr savings": -88684.11524,
    "out:El kr sold": 18249.14689,
    "out:El kr saved": 62586.64414,
    "out:El kr return": 80835.79103,
    "out:% solar/total": 79,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 11858.63895,
    "out:% savings (space heating)": 96.025701,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 2.67,
    "out:Etvv": 0,
    "out:Ef": 34.45,
    "out:heatPumpQuantity": 10,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6815000,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 139.799,
    "out:Total Energy Use Post PV": 139.799,
    "out:Primary Energy": 107.782826,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.114571,
    "out:CO2 Operational/m2": 45.58373,
    "out:Total CO2/m2": 46.698301,
    "out:Total CO2 (tons)": 133510.4279,
    "out:Klimatpaverkan":0.001* -24924.36187,
    "out:Initial Cost/MSEK": 0.463280336,
    "out:Running cost/(Apt SEK y)": 28435.94286,
    "out:Running Cost over RSP/MSEK": 7.77916,
    "out:LCP/MSEK": 1.06923,
    "out:ROI% old": 47.256484,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 17.1,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 42,
    "out:Return/kSEK/y": 196,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 82550.65546,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 52768.58,
    "out:DH kr savings": 75121.09647,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 463280.3359,
    "out:Seasonal Variation ROI (%)": -5,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 134.799,
    "out:Total Energy Use Post PV": 134.799,
    "out:Primary Energy": 104.65299,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.114571,
    "out:CO2 Operational/m2": 43.78381,
    "out:Total CO2/m2": 44.898381,
    "out:Total CO2 (tons)": 128364.4572,
    "out:Klimatpaverkan":0.001* -30070.33259,
    "out:Initial Cost/MSEK": 0.534755323,
    "out:Running cost/(Apt SEK y)": 27362.51429,
    "out:Running Cost over RSP/MSEK": 7.48514,
    "out:LCP/MSEK": 1.291775,
    "out:ROI% old": 48.794812,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 21.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 44,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 95333.80013,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 64401.24,
    "out:DH kr savings": 86753.75812,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 534755.3234,
    "out:Seasonal Variation ROI (%)": -2,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 132.799,
    "out:Total Energy Use Post PV": 132.799,
    "out:Primary Energy": 105.157826,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.509115,
    "out:CO2 Operational/m2": 43.063842,
    "out:Total CO2/m2": 44.572957,
    "out:Total CO2 (tons)": 127434.0701,
    "out:Klimatpaverkan":0.001* -31000.7197,
    "out:Initial Cost/MSEK": 0.599888336,
    "out:Running cost/(Apt SEK y)": 26933.14286,
    "out:Running Cost over RSP/MSEK": 7.36753,
    "out:LCP/MSEK": 1.344252,
    "out:ROI% old": 46.297664,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 23.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 41,
    "out:Return/kSEK/y": 248,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 101076.9734,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 69627.53,
    "out:DH kr savings": 91980.04582,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 599888.3359,
    "out:Seasonal Variation ROI (%)": -1,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 128.799,
    "out:Total Energy Use Post PV": 128.799,
    "out:Primary Energy": 102.02799,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.509115,
    "out:CO2 Operational/m2": 41.623906,
    "out:Total CO2/m2": 43.133021,
    "out:Total CO2 (tons)": 123317.2935,
    "out:Klimatpaverkan":0.001* -35117.49628,
    "out:Initial Cost/MSEK": 0.671363323,
    "out:Running cost/(Apt SEK y)": 26074.4,
    "out:Running Cost over RSP/MSEK": 7.13231,
    "out:LCP/MSEK": 1.507997,
    "out:ROI% old": 46.373868,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 41,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 113860.1181,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 81260.19,
    "out:DH kr savings": 103612.7075,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 671363.3234,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 139.799,
    "out:Total Energy Use Post PV": 134.046908,
    "out:Primary Energy": 97.42906,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.997181,
    "out:CO2 Operational/m2": 28.860726,
    "out:Total CO2/m2": 39.857907,
    "out:Total CO2 (tons)": 113953.7436,
    "out:Klimatpaverkan":0.001* -44481.04618,
    "out:Initial Cost/MSEK": 0.939042732,
    "out:Running cost/(Apt SEK y)": 27304.97143,
    "out:Running Cost over RSP/MSEK": 7.47782,
    "out:LCP/MSEK": 0.894807,
    "out:ROI% old": 27.898472,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 22.4,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 25,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 82550.65546,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92352.37,
    "out:DH kr savings": 75121.09647,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 939042.7315,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 134.799,
    "out:Total Energy Use Post PV": 129.046908,
    "out:Primary Energy": 94.299224,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.997181,
    "out:CO2 Operational/m2": 27.060806,
    "out:Total CO2/m2": 38.057987,
    "out:Total CO2 (tons)": 108807.7729,
    "out:Klimatpaverkan":0.001* -49627.01689,
    "out:Initial Cost/MSEK": 1.010517719,
    "out:Running cost/(Apt SEK y)": 26231.54286,
    "out:Running Cost over RSP/MSEK": 7.1838,
    "out:LCP/MSEK": 1.117352,
    "out:ROI% old": 30.081751,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 27.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 27,
    "out:Return/kSEK/y": 273,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 95333.80013,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 103985.03,
    "out:DH kr savings": 86753.75812,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1010517.719,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 132.799,
    "out:Total Energy Use Post PV": 127.046908,
    "out:Primary Energy": 94.80406,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.391725,
    "out:CO2 Operational/m2": 26.340838,
    "out:Total CO2/m2": 37.732563,
    "out:Total CO2 (tons)": 107877.3858,
    "out:Klimatpaverkan":0.001* -50557.40401,
    "out:Initial Cost/MSEK": 1.075650732,
    "out:Running cost/(Apt SEK y)": 25802.17143,
    "out:Running Cost over RSP/MSEK": 7.06619,
    "out:LCP/MSEK": 1.169829,
    "out:ROI% old": 29.822213,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 27,
    "out:Return/kSEK/y": 288,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 101076.9734,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109211.32,
    "out:DH kr savings": 91980.04582,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1075650.732,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 128.799,
    "out:Total Energy Use Post PV": 123.046908,
    "out:Primary Energy": 91.674224,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.391725,
    "out:CO2 Operational/m2": 24.900902,
    "out:Total CO2/m2": 36.292627,
    "out:Total CO2 (tons)": 103760.6092,
    "out:Klimatpaverkan":0.001* -54674.18058,
    "out:Initial Cost/MSEK": 1.147125719,
    "out:Running cost/(Apt SEK y)": 24943.42857,
    "out:Running Cost over RSP/MSEK": 6.83097,
    "out:LCP/MSEK": 1.333574,
    "out:ROI% old": 30.893363,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 33.3,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 28,
    "out:Return/kSEK/y": 318,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 113860.1181,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120843.98,
    "out:DH kr savings": 103612.7075,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1147125.719,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 139.799,
    "out:Total Energy Use Post PV": 132.669833,
    "out:Primary Energy": 94.950325,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.879791,
    "out:CO2 Operational/m2": -9.748936,
    "out:Total CO2/m2": 11.130855,
    "out:Total CO2 (tons)": 31823.11096,
    "out:Klimatpaverkan":0.001* -126611.6789,
    "out:Initial Cost/MSEK": 1.41475,
    "out:Running cost/(Apt SEK y)": 26325.91429,
    "out:Running Cost over RSP/MSEK": 7.21715,
    "out:LCP/MSEK": 0.67977,
    "out:ROI% old": 21.149825,
    "out:Payback discounted": 6,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 23.3,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 19,
    "out:Return/kSEK/y": 269,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 82550.65546,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126620.18,
    "out:DH kr savings": 75121.09647,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1414750,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 134.799,
    "out:Total Energy Use Post PV": 127.669833,
    "out:Primary Energy": 91.820489,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.879791,
    "out:CO2 Operational/m2": -11.548856,
    "out:Total CO2/m2": 9.330935,
    "out:Total CO2 (tons)": 26677.14024,
    "out:Klimatpaverkan":0.001* -131757.6496,
    "out:Initial Cost/MSEK": 1.48625,
    "out:Running cost/(Apt SEK y)": 25252.45714,
    "out:Running Cost over RSP/MSEK": 6.92312,
    "out:LCP/MSEK": 0.9023,
    "out:ROI% old": 22.958549,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 28.1,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 21,
    "out:Return/kSEK/y": 307,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 95333.80013,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138252.84,
    "out:DH kr savings": 86753.75812,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1486250,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 132.799,
    "out:Total Energy Use Post PV": 125.669833,
    "out:Primary Energy": 92.325325,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.274335,
    "out:CO2 Operational/m2": -12.268824,
    "out:Total CO2/m2": 9.005511,
    "out:Total CO2 (tons)": 25746.75313,
    "out:Klimatpaverkan":0.001* -132688.0367,
    "out:Initial Cost/MSEK": 1.551375,
    "out:Running cost/(Apt SEK y)": 24823.08571,
    "out:Running Cost over RSP/MSEK": 6.80552,
    "out:LCP/MSEK": 0.954775,
    "out:ROI% old": 23.077685,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 21,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 101076.9734,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143479.13,
    "out:DH kr savings": 91980.04582,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1551375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 128.799,
    "out:Total Energy Use Post PV": 121.669833,
    "out:Primary Energy": 89.195489,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.274335,
    "out:CO2 Operational/m2": -13.70876,
    "out:Total CO2/m2": 7.565575,
    "out:Total CO2 (tons)": 21629.97656,
    "out:Klimatpaverkan":0.001* -136804.8133,
    "out:Initial Cost/MSEK": 1.622875,
    "out:Running cost/(Apt SEK y)": 23964.34286,
    "out:Running Cost over RSP/MSEK": 6.5703,
    "out:LCP/MSEK": 1.118495,
    "out:ROI% old": 24.131513,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 34.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 108,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 22,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 113860.1181,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 155111.79,
    "out:DH kr savings": 103612.7075,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1622875,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 130.799,
    "out:Total Energy Use Post PV": 130.799,
    "out:Primary Energy": 104.282826,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.114571,
    "out:CO2 Operational/m2": 42.343874,
    "out:Total CO2/m2": 43.458445,
    "out:Total CO2 (tons)": 124247.6807,
    "out:Klimatpaverkan":0.001* -34187.10916,
    "out:Initial Cost/MSEK": 0.555656273,
    "out:Running cost/(Apt SEK y)": 26503.77143,
    "out:Running Cost over RSP/MSEK": 7.24992,
    "out:LCP/MSEK": 1.506094,
    "out:ROI% old": 53.006819,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 47,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 107252.4128,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 75247.18,
    "out:DH kr savings": 97599.6956,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 555656.2734,
    "out:Seasonal Variation ROI (%)": 0,
    "out:Seasonal Variation Payback": -999
  },
  {
    "ID": "Z00000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 126.799,
    "out:Total Energy Use Post PV": 126.799,
    "out:Primary Energy": 101.15299,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 1.114571,
    "out:CO2 Operational/m2": 40.903938,
    "out:Total CO2/m2": 42.018509,
    "out:Total CO2 (tons)": 120130.9041,
    "out:Klimatpaverkan":0.001* -38303.88573,
    "out:Initial Cost/MSEK": 0.627131261,
    "out:Running cost/(Apt SEK y)": 25645.02857,
    "out:Running Cost over RSP/MSEK": 7.01471,
    "out:LCP/MSEK": 1.669829,
    "out:ROI% old": 52.323519,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 29.1,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 47,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 120035.5574,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 86879.84,
    "out:DH kr savings": 109232.3573,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 627131.2609,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 125.799,
    "out:Primary Energy": 102.182826,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 1.509115,
    "out:CO2 Operational/m2": 40.543954,
    "out:Total CO2/m2": 42.053069,
    "out:Total CO2 (tons)": 120229.7111,
    "out:Klimatpaverkan":0.001* -38205.07871,
    "out:Initial Cost/MSEK": 0.692264273,
    "out:Running cost/(Apt SEK y)": 25430.34286,
    "out:Running Cost over RSP/MSEK": 6.95591,
    "out:LCP/MSEK": 1.663496,
    "out:ROI% old": 48.61397,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 43,
    "out:Return/kSEK/y": 301,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 122073.4671,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88734.34,
    "out:DH kr savings": 111086.8551,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 692264.2734,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 120.799,
    "out:Total Energy Use Post PV": 120.799,
    "out:Primary Energy": 99.05299,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 1.509115,
    "out:CO2 Operational/m2": 38.744034,
    "out:Total CO2/m2": 40.253149,
    "out:Total CO2 (tons)": 115083.7404,
    "out:Klimatpaverkan":0.001* -43351.04942,
    "out:Initial Cost/MSEK": 0.763739261,
    "out:Running cost/(Apt SEK y)": 24356.91429,
    "out:Running Cost over RSP/MSEK": 6.66189,
    "out:LCP/MSEK": 1.886041,
    "out:ROI% old": 49.564036,
    "out:Payback discounted": 3,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 44,
    "out:Return/kSEK/y": 338,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 134856.6118,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 100367,
    "out:DH kr savings": 122719.5167,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 763739.2609,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z00000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 130.799,
    "out:Total Energy Use Post PV": 125.046908,
    "out:Primary Energy": 93.92906,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.997181,
    "out:CO2 Operational/m2": 25.62087,
    "out:Total CO2/m2": 36.618051,
    "out:Total CO2 (tons)": 104690.9963,
    "out:Klimatpaverkan":0.001* -53743.79347,
    "out:Initial Cost/MSEK": 1.031418669,
    "out:Running cost/(Apt SEK y)": 25372.8,
    "out:Running Cost over RSP/MSEK": 6.94858,
    "out:LCP/MSEK": 1.331671,
    "out:ROI% old": 32.730093,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 31.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 29,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 107252.4128,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114830.97,
    "out:DH kr savings": 97599.6956,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1031418.669,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 126.799,
    "out:Total Energy Use Post PV": 121.046908,
    "out:Primary Energy": 90.799224,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 10.997181,
    "out:CO2 Operational/m2": 24.180934,
    "out:Total CO2/m2": 35.178115,
    "out:Total CO2 (tons)": 100574.2198,
    "out:Klimatpaverkan":0.001* -57860.57004,
    "out:Initial Cost/MSEK": 1.102893657,
    "out:Running cost/(Apt SEK y)": 24514.05714,
    "out:Running Cost over RSP/MSEK": 6.71337,
    "out:LCP/MSEK": 1.495406,
    "out:ROI% old": 33.655621,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 30,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 120035.5574,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 126463.63,
    "out:DH kr savings": 109232.3573,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1102893.657,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 120.046908,
    "out:Primary Energy": 91.82906,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.391725,
    "out:CO2 Operational/m2": 23.82095,
    "out:Total CO2/m2": 35.212675,
    "out:Total CO2 (tons)": 100673.0268,
    "out:Klimatpaverkan":0.001* -57761.76301,
    "out:Initial Cost/MSEK": 1.168026669,
    "out:Running cost/(Apt SEK y)": 24299.37143,
    "out:Running Cost over RSP/MSEK": 6.65456,
    "out:LCP/MSEK": 1.489083,
    "out:ROI% old": 32.498157,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 36.7,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 29,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 122073.4671,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128318.13,
    "out:DH kr savings": 111086.8551,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1168026.669,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 120.799,
    "out:Total Energy Use Post PV": 115.046908,
    "out:Primary Energy": 88.699224,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.391725,
    "out:CO2 Operational/m2": 22.02103,
    "out:Total CO2/m2": 33.412755,
    "out:Total CO2 (tons)": 95527.05609,
    "out:Klimatpaverkan":0.001* -62907.73373,
    "out:Initial Cost/MSEK": 1.239501657,
    "out:Running cost/(Apt SEK y)": 23225.94286,
    "out:Running Cost over RSP/MSEK": 6.36054,
    "out:LCP/MSEK": 1.711628,
    "out:ROI% old": 34.012863,
    "out:Payback discounted": 4,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 30,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 134856.6118,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 139950.79,
    "out:DH kr savings": 122719.5167,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1239501.657,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 130.799,
    "out:Total Energy Use Post PV": 123.669833,
    "out:Primary Energy": 91.450325,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.879791,
    "out:CO2 Operational/m2": -12.988792,
    "out:Total CO2/m2": 7.890999,
    "out:Total CO2 (tons)": 22560.36367,
    "out:Klimatpaverkan":0.001* -135874.4261,
    "out:Initial Cost/MSEK": 1.507125,
    "out:Running cost/(Apt SEK y)": 24393.71429,
    "out:Running Cost over RSP/MSEK": 6.68791,
    "out:LCP/MSEK": 1.116635,
    "out:ROI% old": 24.870058,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 32.3,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 22,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 107252.4128,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149098.78,
    "out:DH kr savings": 97599.6956,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1507125,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 126.799,
    "out:Total Energy Use Post PV": 119.669833,
    "out:Primary Energy": 88.320489,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.879791,
    "out:CO2 Operational/m2": -14.428728,
    "out:Total CO2/m2": 6.451063,
    "out:Total CO2 (tons)": 18443.5871,
    "out:Klimatpaverkan":0.001* -139991.2027,
    "out:Initial Cost/MSEK": 1.578625,
    "out:Running cost/(Apt SEK y)": 23534.97143,
    "out:Running Cost over RSP/MSEK": 6.45269,
    "out:LCP/MSEK": 1.280355,
    "out:ROI% old": 25.872244,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 36.7,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 23,
    "out:Return/kSEK/y": 367,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 120035.5574,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160731.44,
    "out:DH kr savings": 109232.3573,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1578625,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.492323,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 125.799,
    "out:Total Energy Use Post PV": 118.669833,
    "out:Primary Energy": 89.350325,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.274335,
    "out:CO2 Operational/m2": -14.788712,
    "out:Total CO2/m2": 6.485623,
    "out:Total CO2 (tons)": 18542.39413,
    "out:Klimatpaverkan":0.001* -139892.3957,
    "out:Initial Cost/MSEK": 1.64375,
    "out:Running cost/(Apt SEK y)": 23320.28571,
    "out:Running Cost over RSP/MSEK": 6.39389,
    "out:LCP/MSEK": 1.27403,
    "out:ROI% old": 25.358218,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 37.8,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 23,
    "out:Return/kSEK/y": 375,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 122073.4671,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 162585.94,
    "out:DH kr savings": 111086.8551,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 215832.527,
    "out:% savings (space heating)": 27.665988,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.49,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1643750,
    "out:Seasonal Variation ROI (%)": 5,
    "out:Seasonal Variation Payback": 20
  },
  {
    "ID": "Z00000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.021128,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 120.799,
    "out:Total Energy Use Post PV": 113.669833,
    "out:Primary Energy": 86.220489,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.274335,
    "out:CO2 Operational/m2": -16.588632,
    "out:Total CO2/m2": 4.685703,
    "out:Total CO2 (tons)": 13396.42341,
    "out:Klimatpaverkan":0.001* -145038.3664,
    "out:Initial Cost/MSEK": 1.71525,
    "out:Running cost/(Apt SEK y)": 22246.85714,
    "out:Running Cost over RSP/MSEK": 6.09987,
    "out:LCP/MSEK": 1.49655,
    "out:ROI% old": 26.749953,
    "out:Payback discounted": 5,
    "out:Atemp": 2859,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 43.9,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 24,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.74,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 134856.6118,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174218.6,
    "out:DH kr savings": 122719.5167,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 203049.3823,
    "out:% savings (space heating)": 31.950125,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 71.02,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 1715250,
    "out:Seasonal Variation ROI (%)": 6,
    "out:Seasonal Variation Payback": 16.666667
  },
  {
    "ID": "Z01000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 123.799,
    "out:Total Energy Use Post PV": 123.799,
    "out:Primary Energy": 97.108075,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.396237,
    "out:CO2 Operational/m2": 39.823986,
    "out:Total CO2/m2": 51.220223,
    "out:Total CO2 (tons)": 146438.6015,
    "out:Klimatpaverkan":0.001* -11996.18829,
    "out:Initial Cost/MSEK": 2.571125,
    "out:Running cost/(Apt SEK y)": 25000.97143,
    "out:Running Cost over RSP/MSEK": 6.8383,
    "out:LCP/MSEK": -0.097755,
    "out:ROI% old": 13.742567,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 32.3,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 316,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126149.3837,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 92443.42,
    "out:DH kr savings": 114795.9392,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2571125,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 120.799,
    "out:Total Energy Use Post PV": 120.799,
    "out:Primary Energy": 94.463454,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.396237,
    "out:CO2 Operational/m2": 38.744034,
    "out:Total CO2/m2": 50.140271,
    "out:Total CO2 (tons)": 143351.0191,
    "out:Klimatpaverkan":0.001* -15083.77072,
    "out:Initial Cost/MSEK": 2.642625,
    "out:Running cost/(Apt SEK y)": 24356.91429,
    "out:Running Cost over RSP/MSEK": 6.66189,
    "out:LCP/MSEK": 0.007155,
    "out:ROI% old": 14.324393,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 35.5,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 338,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 136950.768,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 102272.68,
    "out:DH kr savings": 124625.1989,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2642625,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 117.799,
    "out:Total Energy Use Post PV": 117.799,
    "out:Primary Energy": 94.483075,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.790781,
    "out:CO2 Operational/m2": 37.664082,
    "out:Total CO2/m2": 49.454863,
    "out:Total CO2 (tons)": 141391.4378,
    "out:Klimatpaverkan":0.001* -17043.35198,
    "out:Initial Cost/MSEK": 2.70775,
    "out:Running cost/(Apt SEK y)": 23712.85714,
    "out:Running Cost over RSP/MSEK": 6.48547,
    "out:LCP/MSEK": 0.11845,
    "out:ROI% old": 14.91064,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144675.7017,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109302.37,
    "out:DH kr savings": 131654.8886,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2707750,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 113.799,
    "out:Primary Energy": 91.838454,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.790781,
    "out:CO2 Operational/m2": 36.224146,
    "out:Total CO2/m2": 48.014927,
    "out:Total CO2 (tons)": 137274.6613,
    "out:Klimatpaverkan":0.001* -21160.12855,
    "out:Initial Cost/MSEK": 2.77925,
    "out:Running cost/(Apt SEK y)": 22854.11429,
    "out:Running Cost over RSP/MSEK": 6.25026,
    "out:LCP/MSEK": 0.28216,
    "out:ROI% old": 15.736054,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 43.9,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 155477.086,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119131.63,
    "out:DH kr savings": 141484.1483,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2779250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 123.799,
    "out:Total Energy Use Post PV": 118.046908,
    "out:Primary Energy": 86.754309,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.278847,
    "out:CO2 Operational/m2": 23.100982,
    "out:Total CO2/m2": 44.379829,
    "out:Total CO2 (tons)": 126881.9172,
    "out:Klimatpaverkan":0.001* -31552.87259,
    "out:Initial Cost/MSEK": 3.046875,
    "out:Running cost/(Apt SEK y)": 23870,
    "out:Running Cost over RSP/MSEK": 6.53696,
    "out:LCP/MSEK": -0.272165,
    "out:ROI% old": 13.009629,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 12,
    "out:Return/kSEK/y": 355,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 126149.3837,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132027.21,
    "out:DH kr savings": 114795.9392,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3046875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 120.799,
    "out:Total Energy Use Post PV": 115.046908,
    "out:Primary Energy": 84.109688,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.278847,
    "out:CO2 Operational/m2": 22.02103,
    "out:Total CO2/m2": 43.299877,
    "out:Total CO2 (tons)": 123794.3348,
    "out:Klimatpaverkan":0.001* -34640.45502,
    "out:Initial Cost/MSEK": 3.118375,
    "out:Running cost/(Apt SEK y)": 23225.94286,
    "out:Running Cost over RSP/MSEK": 6.36054,
    "out:LCP/MSEK": -0.167245,
    "out:ROI% old": 13.519541,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 12,
    "out:Return/kSEK/y": 378,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 136950.768,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 141856.47,
    "out:DH kr savings": 124625.1989,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3118375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 117.799,
    "out:Total Energy Use Post PV": 112.046908,
    "out:Primary Energy": 84.129309,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.673391,
    "out:CO2 Operational/m2": 20.941078,
    "out:Total CO2/m2": 42.614469,
    "out:Total CO2 (tons)": 121834.7535,
    "out:Klimatpaverkan":0.001* -36600.03628,
    "out:Initial Cost/MSEK": 3.1835,
    "out:Running cost/(Apt SEK y)": 22581.88571,
    "out:Running Cost over RSP/MSEK": 6.18413,
    "out:LCP/MSEK": -0.05596,
    "out:ROI% old": 14.034598,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 13,
    "out:Return/kSEK/y": 400,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144675.7017,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148886.16,
    "out:DH kr savings": 131654.8886,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3183500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 108.046908,
    "out:Primary Energy": 81.484688,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.673391,
    "out:CO2 Operational/m2": 19.501142,
    "out:Total CO2/m2": 41.174533,
    "out:Total CO2 (tons)": 117717.977,
    "out:Klimatpaverkan":0.001* -40716.81285,
    "out:Initial Cost/MSEK": 3.255,
    "out:Running cost/(Apt SEK y)": 21723.14286,
    "out:Running Cost over RSP/MSEK": 5.94892,
    "out:LCP/MSEK": 0.10775,
    "out:ROI% old": 14.758613,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 51.9,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 13,
    "out:Return/kSEK/y": 431,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 155477.086,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158715.42,
    "out:DH kr savings": 141484.1483,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3255000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 123.799,
    "out:Total Energy Use Post PV": 116.669833,
    "out:Primary Energy": 84.275574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.161457,
    "out:CO2 Operational/m2": -15.50868,
    "out:Total CO2/m2": 15.652777,
    "out:Total CO2 (tons)": 44751.28454,
    "out:Klimatpaverkan":0.001* -113683.5053,
    "out:Initial Cost/MSEK": 3.522625,
    "out:Running cost/(Apt SEK y)": 22890.91429,
    "out:Running Cost over RSP/MSEK": 6.27628,
    "out:LCP/MSEK": -0.487235,
    "out:ROI% old": 12.309773,
    "out:Payback discounted": 10,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 40.2,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 11,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 126149.3837,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 166295.02,
    "out:DH kr savings": 114795.9392,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3522625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 120.799,
    "out:Total Energy Use Post PV": 113.669833,
    "out:Primary Energy": 81.630953,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.161457,
    "out:CO2 Operational/m2": -16.588632,
    "out:Total CO2/m2": 14.572825,
    "out:Total CO2 (tons)": 41663.70211,
    "out:Klimatpaverkan":0.001* -116771.0877,
    "out:Initial Cost/MSEK": 3.594125,
    "out:Running cost/(Apt SEK y)": 22246.85714,
    "out:Running Cost over RSP/MSEK": 6.09987,
    "out:LCP/MSEK": -0.382325,
    "out:ROI% old": 12.766072,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 43.9,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 11,
    "out:Return/kSEK/y": 412,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 136950.768,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 176124.28,
    "out:DH kr savings": 124625.1989,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3594125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 117.799,
    "out:Total Energy Use Post PV": 110.669833,
    "out:Primary Energy": 81.650574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.556001,
    "out:CO2 Operational/m2": -17.668584,
    "out:Total CO2/m2": 13.887417,
    "out:Total CO2 (tons)": 39704.12086,
    "out:Klimatpaverkan":0.001* -118730.669,
    "out:Initial Cost/MSEK": 3.65925,
    "out:Running cost/(Apt SEK y)": 21602.8,
    "out:Running Cost over RSP/MSEK": 5.92346,
    "out:LCP/MSEK": -0.27104,
    "out:ROI% old": 13.227574,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 12,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144675.7017,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183153.97,
    "out:DH kr savings": 131654.8886,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3659250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 106.669833,
    "out:Primary Energy": 79.005953,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.556001,
    "out:CO2 Operational/m2": -19.10852,
    "out:Total CO2/m2": 12.447481,
    "out:Total CO2 (tons)": 35587.34428,
    "out:Klimatpaverkan":0.001* -122847.4455,
    "out:Initial Cost/MSEK": 3.73075,
    "out:Running cost/(Apt SEK y)": 20744.05714,
    "out:Running Cost over RSP/MSEK": 5.68825,
    "out:LCP/MSEK": -0.10733,
    "out:ROI% old": 13.874728,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 12,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 155477.086,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192983.23,
    "out:DH kr savings": 141484.1483,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3730750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 115.799,
    "out:Primary Energy": 93.608075,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.396237,
    "out:CO2 Operational/m2": 36.944114,
    "out:Total CO2/m2": 48.340351,
    "out:Total CO2 (tons)": 138205.0484,
    "out:Klimatpaverkan":0.001* -20229.74144,
    "out:Initial Cost/MSEK": 2.6635,
    "out:Running cost/(Apt SEK y)": 23283.48571,
    "out:Running Cost over RSP/MSEK": 6.36787,
    "out:LCP/MSEK": 0.2803,
    "out:ROI% old": 15.789107,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 41.4,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 150851.141,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 114922.02,
    "out:DH kr savings": 137274.5383,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2663500,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z01000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 111.799,
    "out:Primary Energy": 90.963454,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.396237,
    "out:CO2 Operational/m2": 35.504178,
    "out:Total CO2/m2": 46.900415,
    "out:Total CO2 (tons)": 134088.2718,
    "out:Klimatpaverkan":0.001* -24346.51801,
    "out:Initial Cost/MSEK": 2.735,
    "out:Running cost/(Apt SEK y)": 22424.71429,
    "out:Running Cost over RSP/MSEK": 6.13265,
    "out:LCP/MSEK": 0.44402,
    "out:ROI% old": 16.604962,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 15,
    "out:Return/kSEK/y": 406,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 161652.5253,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124751.28,
    "out:DH kr savings": 147103.7981,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2735000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 110.799,
    "out:Primary Energy": 91.508075,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.790781,
    "out:CO2 Operational/m2": 35.144194,
    "out:Total CO2/m2": 46.934975,
    "out:Total CO2 (tons)": 134187.0788,
    "out:Klimatpaverkan":0.001* -24247.71098,
    "out:Initial Cost/MSEK": 2.800125,
    "out:Running cost/(Apt SEK y)": 22210.02857,
    "out:Running Cost over RSP/MSEK": 6.07385,
    "out:LCP/MSEK": 0.437695,
    "out:ROI% old": 16.518752,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 15,
    "out:Return/kSEK/y": 413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 165672.1954,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128409.18,
    "out:DH kr savings": 150761.6978,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2800125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 106.799,
    "out:Primary Energy": 88.863454,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 11.790781,
    "out:CO2 Operational/m2": 33.704258,
    "out:Total CO2/m2": 45.495039,
    "out:Total CO2 (tons)": 130070.3023,
    "out:Klimatpaverkan":0.001* -28364.48755,
    "out:Initial Cost/MSEK": 2.871625,
    "out:Running cost/(Apt SEK y)": 21351.28571,
    "out:Running Cost over RSP/MSEK": 5.83863,
    "out:LCP/MSEK": 0.601415,
    "out:ROI% old": 17.277624,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 15,
    "out:Return/kSEK/y": 444,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 176473.5797,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 138238.44,
    "out:DH kr savings": 160590.9575,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 2871625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z01000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 110.046908,
    "out:Primary Energy": 83.254309,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.278847,
    "out:CO2 Operational/m2": 20.22111,
    "out:Total CO2/m2": 41.499957,
    "out:Total CO2 (tons)": 118648.3641,
    "out:Klimatpaverkan":0.001* -39786.42574,
    "out:Initial Cost/MSEK": 3.13925,
    "out:Running cost/(Apt SEK y)": 22152.51429,
    "out:Running Cost over RSP/MSEK": 6.06652,
    "out:LCP/MSEK": 0.1059,
    "out:ROI% old": 14.767631,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 49.1,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 13,
    "out:Return/kSEK/y": 415,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150851.141,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154505.81,
    "out:DH kr savings": 137274.5383,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3139250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 106.046908,
    "out:Primary Energy": 80.609688,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.278847,
    "out:CO2 Operational/m2": 18.781174,
    "out:Total CO2/m2": 40.060021,
    "out:Total CO2 (tons)": 114531.5875,
    "out:Klimatpaverkan":0.001* -43903.20231,
    "out:Initial Cost/MSEK": 3.21075,
    "out:Running cost/(Apt SEK y)": 21293.77143,
    "out:Running Cost over RSP/MSEK": 5.83131,
    "out:LCP/MSEK": 0.26961,
    "out:ROI% old": 15.4853,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 54.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 14,
    "out:Return/kSEK/y": 446,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 161652.5253,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164335.07,
    "out:DH kr savings": 147103.7981,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3210750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 105.046908,
    "out:Primary Energy": 81.154309,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.673391,
    "out:CO2 Operational/m2": 18.42119,
    "out:Total CO2/m2": 40.094581,
    "out:Total CO2 (tons)": 114630.3945,
    "out:Klimatpaverkan":0.001* -43804.39528,
    "out:Initial Cost/MSEK": 3.275875,
    "out:Running cost/(Apt SEK y)": 21079.08571,
    "out:Running Cost over RSP/MSEK": 5.7725,
    "out:LCP/MSEK": 0.263295,
    "out:ROI% old": 15.433914,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 56.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 14,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 165672.1954,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 167992.97,
    "out:DH kr savings": 150761.6978,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3275875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 101.046908,
    "out:Primary Energy": 78.509688,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.673391,
    "out:CO2 Operational/m2": 16.981254,
    "out:Total CO2/m2": 38.654645,
    "out:Total CO2 (tons)": 110513.618,
    "out:Klimatpaverkan":0.001* -47921.17186,
    "out:Initial Cost/MSEK": 3.347375,
    "out:Running cost/(Apt SEK y)": 20220.34286,
    "out:Running Cost over RSP/MSEK": 5.53729,
    "out:LCP/MSEK": 0.427005,
    "out:ROI% old": 16.108059,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 14,
    "out:Return/kSEK/y": 483,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 176473.5797,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 177822.23,
    "out:DH kr savings": 160590.9575,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3347375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 115.799,
    "out:Total Energy Use Post PV": 108.669833,
    "out:Primary Energy": 80.775574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.161457,
    "out:CO2 Operational/m2": -18.388552,
    "out:Total CO2/m2": 12.772905,
    "out:Total CO2 (tons)": 36517.7314,
    "out:Klimatpaverkan":0.001* -121917.0584,
    "out:Initial Cost/MSEK": 3.615,
    "out:Running cost/(Apt SEK y)": 21173.42857,
    "out:Running Cost over RSP/MSEK": 5.80585,
    "out:LCP/MSEK": -0.10918,
    "out:ROI% old": 13.854258,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 50.5,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 12,
    "out:Return/kSEK/y": 450,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 150851.141,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188773.62,
    "out:DH kr savings": 137274.5383,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3615000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 111.799,
    "out:Total Energy Use Post PV": 104.669833,
    "out:Primary Energy": 78.130953,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.161457,
    "out:CO2 Operational/m2": -19.828488,
    "out:Total CO2/m2": 11.332969,
    "out:Total CO2 (tons)": 32400.95482,
    "out:Klimatpaverkan":0.001* -126033.835,
    "out:Initial Cost/MSEK": 3.6865,
    "out:Running cost/(Apt SEK y)": 20314.68571,
    "out:Running Cost over RSP/MSEK": 5.57064,
    "out:LCP/MSEK": 0.05453,
    "out:ROI% old": 14.497026,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 56.2,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 13,
    "out:Return/kSEK/y": 480,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 161652.5253,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198602.88,
    "out:DH kr savings": 147103.7981,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3686500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.242678,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 103.669833,
    "out:Primary Energy": 78.675574,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.556001,
    "out:CO2 Operational/m2": -20.188472,
    "out:Total CO2/m2": 11.367529,
    "out:Total CO2 (tons)": 32499.76185,
    "out:Klimatpaverkan":0.001* -125935.028,
    "out:Initial Cost/MSEK": 3.751625,
    "out:Running cost/(Apt SEK y)": 20100,
    "out:Running Cost over RSP/MSEK": 5.51183,
    "out:LCP/MSEK": 0.048215,
    "out:ROI% old": 14.469311,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 13,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 165672.1954,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 202260.78,
    "out:DH kr savings": 150761.6978,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 172233.7987,
    "out:% savings (space heating)": 42.277645,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 60.24,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3751625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z01000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 56.464649,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 106.799,
    "out:Total Energy Use Post PV": 99.669833,
    "out:Primary Energy": 76.030953,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 31.556001,
    "out:CO2 Operational/m2": -21.628408,
    "out:Total CO2/m2": 9.927593,
    "out:Total CO2 (tons)": 28382.98528,
    "out:Klimatpaverkan":0.001* -130051.8045,
    "out:Initial Cost/MSEK": 3.823125,
    "out:Running cost/(Apt SEK y)": 19241.25714,
    "out:Running Cost over RSP/MSEK": 5.27662,
    "out:LCP/MSEK": 0.211925,
    "out:ROI% old": 15.077606,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.45,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 14,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.35,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 1787.6125,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 176473.5797,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212090.04,
    "out:DH kr savings": 160590.9575,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 161432.4144,
    "out:% savings (space heating)": 45.897616,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 56.46,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3823125,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z02000000000000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 117.799,
    "out:Total Energy Use Post PV": 117.799,
    "out:Primary Energy": 92.534487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.719104,
    "out:CO2 Operational/m2": 37.664082,
    "out:Total CO2/m2": 51.383186,
    "out:Total CO2 (tons)": 146904.5127,
    "out:Klimatpaverkan":0.001* -11530.27712,
    "out:Initial Cost/MSEK": 3.052375,
    "out:Running cost/(Apt SEK y)": 23712.85714,
    "out:Running Cost over RSP/MSEK": 6.48547,
    "out:LCP/MSEK": -0.226175,
    "out:ROI% old": 13.227171,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 39,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 12,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144829.2228,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109442.08,
    "out:DH kr savings": 131794.5928,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3052375,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z02000000000000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 113.799,
    "out:Primary Energy": 90.097757,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.719104,
    "out:CO2 Operational/m2": 36.224146,
    "out:Total CO2/m2": 49.94325,
    "out:Total CO2 (tons)": 142787.7361,
    "out:Klimatpaverkan":0.001* -15647.0537,
    "out:Initial Cost/MSEK": 3.123875,
    "out:Running cost/(Apt SEK y)": 22854.11429,
    "out:Running Cost over RSP/MSEK": 6.25026,
    "out:LCP/MSEK": -0.062465,
    "out:ROI% old": 14.000057,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 43.9,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154781.5235,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118498.67,
    "out:DH kr savings": 140851.1864,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3123875,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z02000000000001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 110.799,
    "out:Primary Energy": 89.909487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.113648,
    "out:CO2 Operational/m2": 35.144194,
    "out:Total CO2/m2": 49.257842,
    "out:Total CO2 (tons)": 140828.1549,
    "out:Klimatpaverkan":0.001* -17606.63495,
    "out:Initial Cost/MSEK": 3.189,
    "out:Running cost/(Apt SEK y)": 22210.02857,
    "out:Running Cost over RSP/MSEK": 6.07385,
    "out:LCP/MSEK": 0.04882,
    "out:ROI% old": 14.504412,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 163355.5408,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 126301.02,
    "out:DH kr savings": 148653.5421,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3189000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000000001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 107.799,
    "out:Primary Energy": 87.472757,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.113648,
    "out:CO2 Operational/m2": 34.064242,
    "out:Total CO2/m2": 48.17789,
    "out:Total CO2 (tons)": 137740.5724,
    "out:Klimatpaverkan":0.001* -20694.21738,
    "out:Initial Cost/MSEK": 3.2605,
    "out:Running cost/(Apt SEK y)": 21565.97143,
    "out:Running Cost over RSP/MSEK": 5.89743,
    "out:LCP/MSEK": 0.15374,
    "out:ROI% old": 14.959318,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 51.9,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 13,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 173307.8415,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 135357.62,
    "out:DH kr savings": 157710.1358,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3260500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 117.799,
    "out:Total Energy Use Post PV": 112.046908,
    "out:Primary Energy": 82.180721,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.601714,
    "out:CO2 Operational/m2": 20.941078,
    "out:Total CO2/m2": 44.542792,
    "out:Total CO2 (tons)": 127347.8284,
    "out:Klimatpaverkan":0.001* -31086.96143,
    "out:Initial Cost/MSEK": 3.528125,
    "out:Running cost/(Apt SEK y)": 22581.88571,
    "out:Running Cost over RSP/MSEK": 6.18413,
    "out:LCP/MSEK": -0.400585,
    "out:ROI% old": 12.663707,
    "out:Payback discounted": 10,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 46.4,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 11,
    "out:Return/kSEK/y": 400,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 144829.2228,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149025.86,
    "out:DH kr savings": 131794.5928,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3528125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 108.046908,
    "out:Primary Energy": 79.743991,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.601714,
    "out:CO2 Operational/m2": 19.501142,
    "out:Total CO2/m2": 43.102856,
    "out:Total CO2 (tons)": 123231.0518,
    "out:Klimatpaverkan":0.001* -35203.738,
    "out:Initial Cost/MSEK": 3.599625,
    "out:Running cost/(Apt SEK y)": 21723.14286,
    "out:Running Cost over RSP/MSEK": 5.94892,
    "out:LCP/MSEK": -0.236875,
    "out:ROI% old": 13.345636,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 51.9,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 12,
    "out:Return/kSEK/y": 431,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 154781.5235,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 158082.46,
    "out:DH kr savings": 140851.1864,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3599625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 105.046908,
    "out:Primary Energy": 79.555721,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.996258,
    "out:CO2 Operational/m2": 18.42119,
    "out:Total CO2/m2": 42.417448,
    "out:Total CO2 (tons)": 121271.4706,
    "out:Klimatpaverkan":0.001* -37163.31926,
    "out:Initial Cost/MSEK": 3.66475,
    "out:Running cost/(Apt SEK y)": 21079.08571,
    "out:Running Cost over RSP/MSEK": 5.7725,
    "out:LCP/MSEK": -0.12558,
    "out:ROI% old": 13.796186,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 56.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 12,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163355.5408,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 165884.81,
    "out:DH kr savings": 148653.5421,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3664750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000010001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 102.046908,
    "out:Primary Energy": 77.118991,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.996258,
    "out:CO2 Operational/m2": 17.341238,
    "out:Total CO2/m2": 41.337496,
    "out:Total CO2 (tons)": 118183.8881,
    "out:Klimatpaverkan":0.001* -40250.90169,
    "out:Initial Cost/MSEK": 3.73625,
    "out:Running cost/(Apt SEK y)": 20435.02857,
    "out:Running Cost over RSP/MSEK": 5.59609,
    "out:LCP/MSEK": -0.02067,
    "out:ROI% old": 14.206682,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 60.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 13,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 173307.8415,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 174941.41,
    "out:DH kr savings": 157710.1358,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3736250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 117.799,
    "out:Total Energy Use Post PV": 110.669833,
    "out:Primary Energy": 79.701986,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.484324,
    "out:CO2 Operational/m2": -17.668584,
    "out:Total CO2/m2": 15.81574,
    "out:Total CO2 (tons)": 45217.19571,
    "out:Klimatpaverkan":0.001* -113217.5941,
    "out:Initial Cost/MSEK": 4.003875,
    "out:Running cost/(Apt SEK y)": 21602.8,
    "out:Running Cost over RSP/MSEK": 5.92346,
    "out:LCP/MSEK": -0.615665,
    "out:ROI% old": 12.089039,
    "out:Payback discounted": 10,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 11,
    "out:Return/kSEK/y": 435,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 144829.2228,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183293.68,
    "out:DH kr savings": 131794.5928,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4003875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 106.669833,
    "out:Primary Energy": 77.265256,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.484324,
    "out:CO2 Operational/m2": -19.10852,
    "out:Total CO2/m2": 14.375804,
    "out:Total CO2 (tons)": 41100.41914,
    "out:Klimatpaverkan":0.001* -117334.3707,
    "out:Initial Cost/MSEK": 4.075375,
    "out:Running cost/(Apt SEK y)": 20744.05714,
    "out:Running Cost over RSP/MSEK": 5.68825,
    "out:LCP/MSEK": -0.451955,
    "out:ROI% old": 12.701443,
    "out:Payback discounted": 10,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 11,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 154781.5235,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 192350.27,
    "out:DH kr savings": 140851.1864,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4075375,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 103.669833,
    "out:Primary Energy": 77.076986,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.878868,
    "out:CO2 Operational/m2": -20.188472,
    "out:Total CO2/m2": 13.690396,
    "out:Total CO2 (tons)": 39140.83788,
    "out:Klimatpaverkan":0.001* -119293.9519,
    "out:Initial Cost/MSEK": 4.1405,
    "out:Running cost/(Apt SEK y)": 20100,
    "out:Running Cost over RSP/MSEK": 5.51183,
    "out:LCP/MSEK": -0.34066,
    "out:ROI% old": 13.110356,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 12,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 163355.5408,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 200152.63,
    "out:DH kr savings": 148653.5421,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4140500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000020001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 100.669833,
    "out:Primary Energy": 74.640256,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.878868,
    "out:CO2 Operational/m2": -21.268424,
    "out:Total CO2/m2": 12.610444,
    "out:Total CO2 (tons)": 36053.25545,
    "out:Klimatpaverkan":0.001* -122381.5344,
    "out:Initial Cost/MSEK": 4.212,
    "out:Running cost/(Apt SEK y)": 19455.94286,
    "out:Running Cost over RSP/MSEK": 5.33542,
    "out:LCP/MSEK": -0.23575,
    "out:ROI% old": 13.486128,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 12,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 173307.8415,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 209209.22,
    "out:DH kr savings": 157710.1358,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4212000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000100000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 108.799,
    "out:Primary Energy": 89.034487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.719104,
    "out:CO2 Operational/m2": 34.424226,
    "out:Total CO2/m2": 48.14333,
    "out:Total CO2 (tons)": 137641.7654,
    "out:Klimatpaverkan":0.001* -20793.02441,
    "out:Initial Cost/MSEK": 3.14475,
    "out:Running cost/(Apt SEK y)": 21780.65714,
    "out:Running Cost over RSP/MSEK": 5.95624,
    "out:LCP/MSEK": 0.21068,
    "out:ROI% old": 15.242774,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 50.5,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 169530.9801,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131920.67,
    "out:DH kr savings": 154273.1919,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3144750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 105.799,
    "out:Primary Energy": 86.597757,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 13.719104,
    "out:CO2 Operational/m2": 33.344274,
    "out:Total CO2/m2": 47.063378,
    "out:Total CO2 (tons)": 134554.183,
    "out:Klimatpaverkan":0.001* -23880.60684,
    "out:Initial Cost/MSEK": 3.21625,
    "out:Running cost/(Apt SEK y)": 21136.6,
    "out:Running Cost over RSP/MSEK": 5.77983,
    "out:LCP/MSEK": 0.31559,
    "out:ROI% old": 15.68748,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 54.7,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 179483.2808,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 140977.27,
    "out:DH kr savings": 163329.7856,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3216250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 103.799,
    "out:Primary Energy": 86.934487,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.113648,
    "out:CO2 Operational/m2": 32.624306,
    "out:Total CO2/m2": 46.737954,
    "out:Total CO2 (tons)": 133623.7959,
    "out:Klimatpaverkan":0.001* -24810.99396,
    "out:Initial Cost/MSEK": 3.281375,
    "out:Running cost/(Apt SEK y)": 20707.22857,
    "out:Running Cost over RSP/MSEK": 5.66222,
    "out:LCP/MSEK": 0.368075,
    "out:ROI% old": 15.888157,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 14,
    "out:Return/kSEK/y": 466,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 184352.0345,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 145407.83,
    "out:DH kr savings": 167760.3514,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3281375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000100001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 100.799,
    "out:Primary Energy": 84.497757,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 14.113648,
    "out:CO2 Operational/m2": 31.544354,
    "out:Total CO2/m2": 45.658002,
    "out:Total CO2 (tons)": 130536.2134,
    "out:Klimatpaverkan":0.001* -27898.57639,
    "out:Initial Cost/MSEK": 3.352875,
    "out:Running cost/(Apt SEK y)": 20063.17143,
    "out:Running Cost over RSP/MSEK": 5.48581,
    "out:LCP/MSEK": 0.472985,
    "out:ROI% old": 16.300978,
    "out:Payback discounted": 7,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 15,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 194304.3352,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154464.43,
    "out:DH kr savings": 176816.9451,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3352875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z02000000110000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 103.046908,
    "out:Primary Energy": 78.680721,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.601714,
    "out:CO2 Operational/m2": 17.701222,
    "out:Total CO2/m2": 41.302936,
    "out:Total CO2 (tons)": 118085.0811,
    "out:Klimatpaverkan":0.001* -40349.70872,
    "out:Initial Cost/MSEK": 3.6205,
    "out:Running cost/(Apt SEK y)": 20649.71429,
    "out:Running Cost over RSP/MSEK": 5.6549,
    "out:LCP/MSEK": 0.03627,
    "out:ROI% old": 14.428828,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 59.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 13,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 169530.9801,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 171504.46,
    "out:DH kr savings": 154273.1919,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3620500,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 100.046908,
    "out:Primary Energy": 76.243991,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.601714,
    "out:CO2 Operational/m2": 16.62127,
    "out:Total CO2/m2": 40.222984,
    "out:Total CO2 (tons)": 114997.4987,
    "out:Klimatpaverkan":0.001* -43437.29115,
    "out:Initial Cost/MSEK": 3.692,
    "out:Running cost/(Apt SEK y)": 20005.65714,
    "out:Running Cost over RSP/MSEK": 5.47849,
    "out:LCP/MSEK": 0.14118,
    "out:ROI% old": 14.831992,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 13,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 179483.2808,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 180561.06,
    "out:DH kr savings": 163329.7856,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3692000,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 98.046908,
    "out:Primary Energy": 76.580721,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.996258,
    "out:CO2 Operational/m2": 15.901302,
    "out:Total CO2/m2": 39.89756,
    "out:Total CO2 (tons)": 114067.1116,
    "out:Klimatpaverkan":0.001* -44367.67826,
    "out:Initial Cost/MSEK": 3.757125,
    "out:Running cost/(Apt SEK y)": 19576.25714,
    "out:Running Cost over RSP/MSEK": 5.36088,
    "out:LCP/MSEK": 0.193665,
    "out:ROI% old": 15.022087,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 13,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 184352.0345,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 184991.62,
    "out:DH kr savings": 167760.3514,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3757125,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000110001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 95.046908,
    "out:Primary Energy": 74.143991,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 23.996258,
    "out:CO2 Operational/m2": 14.82135,
    "out:Total CO2/m2": 38.817608,
    "out:Total CO2 (tons)": 110979.5291,
    "out:Klimatpaverkan":0.001* -47455.26069,
    "out:Initial Cost/MSEK": 3.828625,
    "out:Running cost/(Apt SEK y)": 18932.2,
    "out:Running Cost over RSP/MSEK": 5.18446,
    "out:LCP/MSEK": 0.298585,
    "out:ROI% old": 15.399822,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 72.6,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 14,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 194304.3352,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194048.22,
    "out:DH kr savings": 176816.9451,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 3828625,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120000010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 108.799,
    "out:Total Energy Use Post PV": 101.669833,
    "out:Primary Energy": 76.201986,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.484324,
    "out:CO2 Operational/m2": -20.90844,
    "out:Total CO2/m2": 12.575884,
    "out:Total CO2 (tons)": 35954.44842,
    "out:Klimatpaverkan":0.001* -122480.3414,
    "out:Initial Cost/MSEK": 4.09625,
    "out:Running cost/(Apt SEK y)": 19670.62857,
    "out:Running Cost over RSP/MSEK": 5.39423,
    "out:LCP/MSEK": -0.17881,
    "out:ROI% old": 13.662113,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 60.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 12,
    "out:Return/kSEK/y": 502,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 169530.9801,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 205772.28,
    "out:DH kr savings": 154273.1919,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4096250,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120000110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 98.669833,
    "out:Primary Energy": 73.765256,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.484324,
    "out:CO2 Operational/m2": -21.988392,
    "out:Total CO2/m2": 11.495932,
    "out:Total CO2 (tons)": 32866.86599,
    "out:Klimatpaverkan":0.001* -125567.9238,
    "out:Initial Cost/MSEK": 4.16775,
    "out:Running cost/(Apt SEK y)": 19026.57143,
    "out:Running Cost over RSP/MSEK": 5.21782,
    "out:LCP/MSEK": -0.0739,
    "out:ROI% old": 14.032409,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 13,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 179483.2808,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 214828.87,
    "out:DH kr savings": 163329.7856,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4167750,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120001010",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 53.708981,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 103.799,
    "out:Total Energy Use Post PV": 96.669833,
    "out:Primary Energy": 74.101986,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.878868,
    "out:CO2 Operational/m2": -22.70836,
    "out:Total CO2/m2": 11.170508,
    "out:Total CO2 (tons)": 31936.47888,
    "out:Klimatpaverkan":0.001* -126498.3109,
    "out:Initial Cost/MSEK": 4.232875,
    "out:Running cost/(Apt SEK y)": 18597.2,
    "out:Running Cost over RSP/MSEK": 5.10021,
    "out:LCP/MSEK": -0.021415,
    "out:ROI% old": 14.21344,
    "out:Payback discounted": 9,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 69.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 13,
    "out:Return/kSEK/y": 540,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 184352.0345,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 219259.44,
    "out:DH kr savings": 167760.3514,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 153553.9596,
    "out:% savings (space heating)": 48.537998,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 53.71,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4232875,
    "out:Seasonal Variation ROI (%)": 3,
    "out:Seasonal Variation Payback": 33.333333
  },
  {
    "ID": "Z02000000120001110",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 2,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 1,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 50.227938,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 93.669833,
    "out:Primary Energy": 71.665256,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 33.878868,
    "out:CO2 Operational/m2": -23.788312,
    "out:Total CO2/m2": 10.090556,
    "out:Total CO2 (tons)": 28848.89645,
    "out:Klimatpaverkan":0.001* -129585.8934,
    "out:Initial Cost/MSEK": 4.304375,
    "out:Running cost/(Apt SEK y)": 17953.14286,
    "out:Running Cost over RSP/MSEK": 4.9238,
    "out:LCP/MSEK": 0.083495,
    "out:ROI% old": 14.562824,
    "out:Payback discounted": 8,
    "out:Atemp": 2859,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 74.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 13,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 0,
    "out:FTX (Dampers)": 0,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.17,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.1,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.6875,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 194304.3352,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 228316.03,
    "out:DH kr savings": 176816.9451,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143601.6589,
    "out:% savings (space heating)": 51.873407,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.23,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 4304375,
    "out:Seasonal Variation ROI (%)": 4,
    "out:Seasonal Variation Payback": 25
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 113.799,
    "out:Primary Energy": 90.055997,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.690677,
    "out:CO2 Operational/m2": 36.224146,
    "out:Total CO2/m2": 69.914823,
    "out:Total CO2 (tons)": 199886.4571,
    "out:Klimatpaverkan":0.001* 41451.66726,
    "out:Initial Cost/MSEK": 5.3995,
    "out:Running cost/(Apt SEK y)": 22854.11429,
    "out:Running Cost over RSP/MSEK": 6.25026,
    "out:LCP/MSEK": -2.33809,
    "out:ROI% old": 8.099718,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 43.9,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 7,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 154952.0793,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 118653.87,
    "out:DH kr savings": 141006.3922,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5399500,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 110.799,
    "out:Primary Energy": 87.731926,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.690677,
    "out:CO2 Operational/m2": 35.144194,
    "out:Total CO2/m2": 68.834871,
    "out:Total CO2 (tons)": 196798.8746,
    "out:Klimatpaverkan":0.001* 38364.08483,
    "out:Initial Cost/MSEK": 5.471,
    "out:Running cost/(Apt SEK y)": 22210.02857,
    "out:Running Cost over RSP/MSEK": 6.07385,
    "out:LCP/MSEK": -2.23318,
    "out:ROI% old": 8.4545,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 47.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 413,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 164444.2502,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 127291.75,
    "out:DH kr savings": 149644.2677,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5471000,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 107.799,
    "out:Primary Energy": 87.430997,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.085221,
    "out:CO2 Operational/m2": 34.064242,
    "out:Total CO2/m2": 68.149463,
    "out:Total CO2 (tons)": 194839.2934,
    "out:Klimatpaverkan":0.001* 36404.50357,
    "out:Initial Cost/MSEK": 5.536125,
    "out:Running cost/(Apt SEK y)": 21565.97143,
    "out:Running Cost over RSP/MSEK": 5.89743,
    "out:LCP/MSEK": -2.121885,
    "out:ROI% old": 8.810288,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 51.9,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 436,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 173478.3973,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135512.82,
    "out:DH kr savings": 157865.3415,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5536125,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 104.799,
    "out:Total Energy Use Post PV": 104.799,
    "out:Primary Energy": 85.106926,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.085221,
    "out:CO2 Operational/m2": 32.98429,
    "out:Total CO2/m2": 67.069511,
    "out:Total CO2 (tons)": 191751.711,
    "out:Klimatpaverkan":0.001* 33316.92114,
    "out:Initial Cost/MSEK": 5.607625,
    "out:Running cost/(Apt SEK y)": 20921.91429,
    "out:Running Cost over RSP/MSEK": 5.72102,
    "out:LCP/MSEK": -2.016975,
    "out:ROI% old": 9.147366,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 56.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182970.5682,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 144150.7,
    "out:DH kr savings": 166503.217,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 21.25,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5607625,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 108.046908,
    "out:Primary Energy": 79.702231,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.573287,
    "out:CO2 Operational/m2": 19.501142,
    "out:Total CO2/m2": 63.074429,
    "out:Total CO2 (tons)": 180329.7728,
    "out:Klimatpaverkan":0.001* 21894.98296,
    "out:Initial Cost/MSEK": 5.87525,
    "out:Running cost/(Apt SEK y)": 21723.14286,
    "out:Running Cost over RSP/MSEK": 5.94892,
    "out:LCP/MSEK": -2.5125,
    "out:ROI% old": 8.176552,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 51.9,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 7,
    "out:Return/kSEK/y": 431,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952.0793,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 158237.66,
    "out:DH kr savings": 141006.3922,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5875250,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 105.046908,
    "out:Primary Energy": 77.37816,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.573287,
    "out:CO2 Operational/m2": 18.42119,
    "out:Total CO2/m2": 61.994477,
    "out:Total CO2 (tons)": 177242.1903,
    "out:Klimatpaverkan":0.001* 18807.40053,
    "out:Initial Cost/MSEK": 5.94675,
    "out:Running cost/(Apt SEK y)": 21079.08571,
    "out:Running Cost over RSP/MSEK": 5.7725,
    "out:LCP/MSEK": -2.40758,
    "out:ROI% old": 8.502051,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 56.2,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 8,
    "out:Return/kSEK/y": 453,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164444.2502,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 166875.54,
    "out:DH kr savings": 149644.2677,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5946750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 102.046908,
    "out:Primary Energy": 77.077231,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.967831,
    "out:CO2 Operational/m2": 17.341238,
    "out:Total CO2/m2": 61.309069,
    "out:Total CO2 (tons)": 175282.6091,
    "out:Klimatpaverkan":0.001* 16847.81927,
    "out:Initial Cost/MSEK": 6.011875,
    "out:Running cost/(Apt SEK y)": 20435.02857,
    "out:Running Cost over RSP/MSEK": 5.59609,
    "out:LCP/MSEK": -2.296295,
    "out:ROI% old": 8.829145,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 60.8,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 8,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173478.3973,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175096.61,
    "out:DH kr savings": 157865.3415,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6011875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 104.799,
    "out:Total Energy Use Post PV": 99.046908,
    "out:Primary Energy": 74.75316,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.967831,
    "out:CO2 Operational/m2": 16.261286,
    "out:Total CO2/m2": 60.229117,
    "out:Total CO2 (tons)": 172195.0267,
    "out:Klimatpaverkan":0.001* 13760.23684,
    "out:Initial Cost/MSEK": 6.083375,
    "out:Running cost/(Apt SEK y)": 19790.97143,
    "out:Running Cost over RSP/MSEK": 5.41968,
    "out:LCP/MSEK": -2.191385,
    "out:ROI% old": 9.13964,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 8,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182970.5682,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183734.49,
    "out:DH kr savings": 166503.217,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 21.25,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6083375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 113.799,
    "out:Total Energy Use Post PV": 106.669833,
    "out:Primary Energy": 77.223496,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 53.455897,
    "out:CO2 Operational/m2": -19.10852,
    "out:Total CO2/m2": 34.347377,
    "out:Total CO2 (tons)": 98199.14009,
    "out:Klimatpaverkan":0.001* -60235.64972,
    "out:Initial Cost/MSEK": 6.351,
    "out:Running cost/(Apt SEK y)": 20744.05714,
    "out:Running Cost over RSP/MSEK": 5.68825,
    "out:LCP/MSEK": -2.72758,
    "out:ROI% old": 8.150393,
    "out:Payback discounted": 15,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 53.3,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 7,
    "out:Return/kSEK/y": 465,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 154952.0793,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192505.48,
    "out:DH kr savings": 141006.3922,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6351000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 43.2,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 110.799,
    "out:Total Energy Use Post PV": 103.669833,
    "out:Primary Energy": 74.899425,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.455897,
    "out:CO2 Operational/m2": -20.188472,
    "out:Total CO2/m2": 33.267425,
    "out:Total CO2 (tons)": 95111.55766,
    "out:Klimatpaverkan":0.001* -63323.23215,
    "out:Initial Cost/MSEK": 6.4225,
    "out:Running cost/(Apt SEK y)": 20100,
    "out:Running Cost over RSP/MSEK": 5.51183,
    "out:LCP/MSEK": -2.62266,
    "out:ROI% old": 8.452071,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 8,
    "out:Return/kSEK/y": 487,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 43.2,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 164444.2502,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201143.35,
    "out:DH kr savings": 149644.2677,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 123508.7865,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6422500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 107.799,
    "out:Total Energy Use Post PV": 100.669833,
    "out:Primary Energy": 74.598496,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.850441,
    "out:CO2 Operational/m2": -21.268424,
    "out:Total CO2/m2": 32.582017,
    "out:Total CO2 (tons)": 93151.97641,
    "out:Klimatpaverkan":0.001* -65282.81341,
    "out:Initial Cost/MSEK": 6.487625,
    "out:Running cost/(Apt SEK y)": 19455.94286,
    "out:Running Cost over RSP/MSEK": 5.33542,
    "out:LCP/MSEK": -2.511375,
    "out:ROI% old": 8.75568,
    "out:Payback discounted": 14,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 8,
    "out:Return/kSEK/y": 510,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 173478.3973,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209364.43,
    "out:DH kr savings": 157865.3415,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6487625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000020001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 36.72,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 104.799,
    "out:Total Energy Use Post PV": 97.669833,
    "out:Primary Energy": 72.274425,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.850441,
    "out:CO2 Operational/m2": -22.348376,
    "out:Total CO2/m2": 31.502065,
    "out:Total CO2 (tons)": 90064.39398,
    "out:Klimatpaverkan":0.001* -68370.39584,
    "out:Initial Cost/MSEK": 6.559125,
    "out:Running cost/(Apt SEK y)": 18811.88571,
    "out:Running Cost over RSP/MSEK": 5.15901,
    "out:LCP/MSEK": -2.406465,
    "out:ROI% old": 9.044456,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 67.3,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 8,
    "out:Return/kSEK/y": 532,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 36.72,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 182970.5682,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218002.3,
    "out:DH kr savings": 166503.217,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 104982.4685,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 21.25,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6559125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 105.799,
    "out:Primary Energy": 86.555997,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.690677,
    "out:CO2 Operational/m2": 33.344274,
    "out:Total CO2/m2": 67.034951,
    "out:Total CO2 (tons)": 191652.9039,
    "out:Klimatpaverkan":0.001* 33218.11411,
    "out:Initial Cost/MSEK": 5.491875,
    "out:Running cost/(Apt SEK y)": 21136.6,
    "out:Running Cost over RSP/MSEK": 5.77983,
    "out:LCP/MSEK": -1.960035,
    "out:ROI% old": 9.187182,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 54.7,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 8,
    "out:Return/kSEK/y": 451,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 179653.8366,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141132.47,
    "out:DH kr savings": 163484.9913,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5491875,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 101.799,
    "out:Primary Energy": 84.231926,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.690677,
    "out:CO2 Operational/m2": 31.904338,
    "out:Total CO2/m2": 65.595015,
    "out:Total CO2 (tons)": 187536.1274,
    "out:Klimatpaverkan":0.001* 29101.33754,
    "out:Initial Cost/MSEK": 5.563375,
    "out:Running cost/(Apt SEK y)": 20277.85714,
    "out:Running Cost over RSP/MSEK": 5.54461,
    "out:LCP/MSEK": -1.796315,
    "out:ROI% old": 9.673111,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 60.8,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 481,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 189146.0075,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149770.35,
    "out:DH kr savings": 172122.8668,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 20,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5563375,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 100.799,
    "out:Primary Energy": 84.455997,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.085221,
    "out:CO2 Operational/m2": 31.544354,
    "out:Total CO2/m2": 65.629575,
    "out:Total CO2 (tons)": 187634.9344,
    "out:Klimatpaverkan":0.001* 29200.14457,
    "out:Initial Cost/MSEK": 5.6285,
    "out:Running cost/(Apt SEK y)": 20063.17143,
    "out:Running Cost over RSP/MSEK": 5.48581,
    "out:LCP/MSEK": -1.80264,
    "out:ROI% old": 9.710428,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 62.4,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 489,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 194474.891,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 154619.63,
    "out:DH kr savings": 176972.1508,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5628500,
    "out:Seasonal Variation ROI (%)": 1,
    "out:Seasonal Variation Payback": 100
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 20.799,
    "out:Total Energy Use Pre PV": 96.799,
    "out:Total Energy Use Post PV": 96.799,
    "out:Primary Energy": 82.131926,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 34.085221,
    "out:CO2 Operational/m2": 30.104418,
    "out:Total CO2/m2": 64.189639,
    "out:Total CO2 (tons)": 183518.1578,
    "out:Klimatpaverkan":0.001* 25083.368,
    "out:Initial Cost/MSEK": 5.7,
    "out:Running cost/(Apt SEK y)": 19204.42857,
    "out:Running Cost over RSP/MSEK": 5.25059,
    "out:LCP/MSEK": -1.63892,
    "out:ROI% old": 10.178145,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 69.1,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 1455.93,
    "out:Return %": 9,
    "out:Return/kSEK/y": 519,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 203967.0619,
    "out:EL kWh savings": -13148.53956,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163257.51,
    "out:DH kr savings": 185610.0263,
    "out:El kr savings": -22352.51725,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 17,
    "out:Ef": 20.8,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5700000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 100.046908,
    "out:Primary Energy": 76.202231,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 43.573287,
    "out:CO2 Operational/m2": 16.62127,
    "out:Total CO2/m2": 60.194557,
    "out:Total CO2 (tons)": 172096.2196,
    "out:Klimatpaverkan":0.001* 13661.42981,
    "out:Initial Cost/MSEK": 5.967625,
    "out:Running cost/(Apt SEK y)": 20005.65714,
    "out:Running Cost over RSP/MSEK": 5.47849,
    "out:LCP/MSEK": -2.134445,
    "out:ROI% old": 9.176132,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 64,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 8,
    "out:Return/kSEK/y": 491,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 179653.8366,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 180716.26,
    "out:DH kr savings": 163484.9913,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 5967625,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 96.046908,
    "out:Primary Energy": 73.87816,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.573287,
    "out:CO2 Operational/m2": 15.181334,
    "out:Total CO2/m2": 58.754621,
    "out:Total CO2 (tons)": 167979.443,
    "out:Klimatpaverkan":0.001* 9544.653236,
    "out:Initial Cost/MSEK": 6.039125,
    "out:Running cost/(Apt SEK y)": 19146.88571,
    "out:Running Cost over RSP/MSEK": 5.24327,
    "out:LCP/MSEK": -1.970725,
    "out:ROI% old": 9.623911,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 70.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 9,
    "out:Return/kSEK/y": 521,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 189146.0075,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189354.14,
    "out:DH kr savings": 172122.8668,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 20,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6039125,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 95.046908,
    "out:Primary Energy": 74.102231,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.967831,
    "out:CO2 Operational/m2": 14.82135,
    "out:Total CO2/m2": 58.789181,
    "out:Total CO2 (tons)": 168078.2501,
    "out:Klimatpaverkan":0.001* 9643.460265,
    "out:Initial Cost/MSEK": 6.10425,
    "out:Running cost/(Apt SEK y)": 18932.2,
    "out:Running Cost over RSP/MSEK": 5.18446,
    "out:LCP/MSEK": -1.97704,
    "out:ROI% old": 9.658868,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 72.6,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 9,
    "out:Return/kSEK/y": 528,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 194474.891,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 194203.42,
    "out:DH kr savings": 176972.1508,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6104250,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 15.046908,
    "out:Total Energy Use Pre PV": 96.799,
    "out:Total Energy Use Post PV": 91.046908,
    "out:Primary Energy": 71.77816,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 43.967831,
    "out:CO2 Operational/m2": 13.381414,
    "out:Total CO2/m2": 57.349245,
    "out:Total CO2 (tons)": 163961.4735,
    "out:Klimatpaverkan":0.001* 5526.683692,
    "out:Initial Cost/MSEK": 6.17575,
    "out:Running cost/(Apt SEK y)": 18073.45714,
    "out:Running Cost over RSP/MSEK": 4.94925,
    "out:LCP/MSEK": -1.81333,
    "out:ROI% old": 10.091128,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 80.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 1063.732668,
    "out:Return %": 9,
    "out:Return/kSEK/y": 558,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 27.246267,
    "out:PV (m2 panels)": 142.949984,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 25564.36461,
    "out:PV (kWh sold)": 9119.136769,
    "out:PV (% sold (El))": 35.671283,
    "out:PV (kWh self-consumed)": 16445.22785,
    "out:PV (ratio sold/self-consumed)": 0.554516,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 203967.0619,
    "out:EL kWh savings": 3296.689667,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202841.3,
    "out:DH kr savings": 185610.0263,
    "out:El kr savings": 5604.372434,
    "out:El kr sold": 11626.89938,
    "out:El kr saved": 27956.88734,
    "out:El kr return": 39583.78672,
    "out:% solar/total": 36,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 17,
    "out:Ef": 15.05,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6175750,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 105.799,
    "out:Total Energy Use Post PV": 98.669833,
    "out:Primary Energy": 73.723496,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.455897,
    "out:CO2 Operational/m2": -21.988392,
    "out:Total CO2/m2": 31.467505,
    "out:Total CO2 (tons)": 89965.58695,
    "out:Klimatpaverkan":0.001* -68469.20287,
    "out:Initial Cost/MSEK": 6.443375,
    "out:Running cost/(Apt SEK y)": 19026.57143,
    "out:Running Cost over RSP/MSEK": 5.21782,
    "out:LCP/MSEK": -2.349525,
    "out:ROI% old": 9.076543,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 65.7,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 8,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 179653.8366,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214984.08,
    "out:DH kr savings": 163484.9913,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6443375,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 34.56,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 101.799,
    "out:Total Energy Use Post PV": 94.669833,
    "out:Primary Energy": 71.399425,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.455897,
    "out:CO2 Operational/m2": -23.428328,
    "out:Total CO2/m2": 30.027569,
    "out:Total CO2 (tons)": 85848.81037,
    "out:Klimatpaverkan":0.001* -72585.97944,
    "out:Initial Cost/MSEK": 6.514875,
    "out:Running cost/(Apt SEK y)": 18167.82857,
    "out:Running Cost over RSP/MSEK": 4.9826,
    "out:LCP/MSEK": -2.185805,
    "out:ROI% old": 9.492716,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 72.6,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 9,
    "out:Return/kSEK/y": 555,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 34.56,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 189146.0075,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223621.95,
    "out:DH kr savings": 172122.8668,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 98807.02918,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 20,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6514875,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 50.168282,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 100.799,
    "out:Total Energy Use Post PV": 93.669833,
    "out:Primary Energy": 71.623496,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.850441,
    "out:CO2 Operational/m2": -23.788312,
    "out:Total CO2/m2": 30.062129,
    "out:Total CO2 (tons)": 85947.6174,
    "out:Klimatpaverkan":0.001* -72487.17241,
    "out:Initial Cost/MSEK": 6.58,
    "out:Running cost/(Apt SEK y)": 17953.14286,
    "out:Running Cost over RSP/MSEK": 4.9238,
    "out:LCP/MSEK": -2.19213,
    "out:ROI% old": 9.526422,
    "out:Payback discounted": 13,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 74.5,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 9,
    "out:Return/kSEK/y": 562,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 194474.891,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228471.24,
    "out:DH kr savings": 176972.1508,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 143431.1031,
    "out:% savings (space heating)": 51.930567,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 50.17,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6580000,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  },
  {
    "ID": "Z00000000120001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 2,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 46.84818,
    "out:DHW (DH)": 29.376,
    "out:Property Electricity": 20.799,
    "out:Electricity (inc PV)": 13.669833,
    "out:Total Energy Use Pre PV": 96.799,
    "out:Total Energy Use Post PV": 89.669833,
    "out:Primary Energy": 69.299425,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 53.850441,
    "out:CO2 Operational/m2": -25.228248,
    "out:Total CO2/m2": 28.622193,
    "out:Total CO2 (tons)": 81830.84083,
    "out:Klimatpaverkan":0.001* -76603.94899,
    "out:Initial Cost/MSEK": 6.6515,
    "out:Running cost/(Apt SEK y)": 17094.4,
    "out:Running Cost over RSP/MSEK": 4.68858,
    "out:LCP/MSEK": -2.02841,
    "out:ROI% old": 9.92921,
    "out:Payback discounted": 12,
    "out:Atemp": 2859,
    "out:Umean": 0.35,
    "out:Roof surface/m2": 571.7999374,
    "out:Facade surface/m2": 1179.168159,
    "out:Window surface/m2": 377.4024746,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 82.2,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 965.462893,
    "out:Return %": 9,
    "out:Return/kSEK/y": 593,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 1.3,
    "out:Windows (U-value after)": 1.3,
    "out:EAHP (Heatpump)": 0,
    "out:EAHP (1000ltanks)": 74526.08805,
    "out:EAHP (pipework)": 74298.25437,
    "out:EAHP (natural ventilation grills)": 166621.875,
    "out:EAHP (installation)": 0,
    "out:EAHP (replacement cost)": 6501.378888,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 29.376,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56256,
    "out:PV (kWp installed)": 54.492534,
    "out:PV (m2 panels)": 285.899969,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 51128.72941,
    "out:PV (kWh sold)": 30746.44326,
    "out:PV (% sold (El))": 60.135356,
    "out:PV (kWh self-consumed)": 20382.28615,
    "out:PV (ratio sold/self-consumed)": 1.508488,
    "out:FTX (AHU)": 310207.5,
    "out:FTX (Ductwork)": 997443.1173,
    "out:FTX (Diffusers)": 167882.8125,
    "out:FTX (Dampers)": 65234.375,
    "out:FTX (Replacement Cost)": 153072.8359,
    "out:FTX (kWh heat recovery efficiency)": 28.873964,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639.3125,
    "out:IMD (#apts)": 35,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 34.56,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 432250,
    "out:DH (pipework)": 481250,
    "out:Facade U-value (before)": 0.74,
    "out:Facade U-value (after)": 0.16,
    "out:Roof U-value (before)": 0.1,
    "out:Roof U-value (after)": 0.05,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.275,
    "out:FTX (Roof)": 482756.3918,
    "out:FTX (Facade)": 3222843.454,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 203967.0619,
    "out:EL kWh savings": 7233.746661,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 71474.99,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237109.11,
    "out:DH kr savings": 185610.0263,
    "out:El kr savings": 12297.36932,
    "out:El kr sold": 39201.71515,
    "out:El kr saved": 34649.88646,
    "out:El kr return": 73851.60161,
    "out:% solar/total": 44,
    "out:Total_El consumption (without PV)": 46315.79493,
    "out:Total_Space Heating (DH)": 133938.9322,
    "out:% savings (space heating)": 55.111769,
    "out:Total_Water Heating (DH)": 83985.97481,
    "out:Uppvarmning (kr/kWh)": 0.91,
    "out:El (kr/kWh)": 1.7,
    "out:Sold El (kr/kWh)": 1.275,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 46.85,
    "out:Etvv": 17,
    "out:Ef": 13.67,
    "out:heatPumpQuantity": 0,
    "out:raised_roof": 0,
    "out:Initial Cost/SEK": 6651500,
    "out:Seasonal Variation ROI (%)": 2,
    "out:Seasonal Variation Payback": 50
  }
]