export const InstallationItems = {
  nuläge: "Nuläge",
  imdTappvarmvatten: "IMD (tappvarmvatten)",
  solceller: "Solceller",
  takisolering: "Takisolering",
  fönster: "Fönster",
  fasadisolering: "Fasadisolering",
  avloppsvärmeväxlare: "Avloppsvärmeväxlare",
  injustering: "Injustering",
  frånluftsvärmepump: "Frånluftsvärmepump",
  improvedahu: "Ny aggregat",
  ftx: "FTX",
  ftxFasadintegrerad: "FTX (fasadintegrerad)",
} as const;

export const installationItems = Object.values(InstallationItems);
