export const InstallationOptions: Record<
  string,
  Readonly<Readonly<[string, number]>[]> | undefined
> = {
  "in:EAHP": [
    ["-", 0],
    ["25%", 1],
    ["50%", 2],
  ],
  "in:Facade_Insulation_Thickness": [
    ["-", 0],
    ["100mm", 1],
    ["200mm", 2],
  ],

  "in:Facade_Insulation_Type": [
    ["EPS", 0],
    ["Glasull", 1],
    ["Stenull", 2],
  ],

  "in:Roof_Insulation_Type": [
    ["EPS", 0],
    ["Glasull", 1],
    ["Stenull", 2],
  ],

  "in:Roof_Insulation_Thickness": [
    ["-", 0],
    ["100mm", 1],
    ["200mm", 2],
    ["300mm", 3],
  ],

  "in:HVAC System": [
    ["-", 0],
    ["Frånluftsvärmepump", 1],
    ["FTX (fasadintegrerad)", 2],
  ],

  "in:Window_Type": [
    ["-", 0],
    ["Tilläggsruta", 1],
    ["3-glas", 2],
  ],

  "in:DHW individual metering": [
    ["-", 0],
    ["IMD (tappvarmvatten)", 1],
  ],

  "in:PV_System_Roof_Coverage": [
    ["-", 0],
    ["25% täckning", 1],
    ["50% täckning", 2],
  ],

  "in:Sewage heat recovery": [
    ["-", 0],
    ["Avloppsvärmeväxlare", 1],
  ],

  "in:Injustering av varmesystem": [
    ["-", 0],
    ["Injustering", 1],
  ],
} as const;
