import styled from "@emotion/styled";
import MuiAccordion, { type AccordionProps } from "@mui/material/Accordion";

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters={true} elevation={0} square={true} {...props} />
))(() => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
