import type { SvgIconComponent } from "@mui/icons-material";
import BoltIcon from "@mui/icons-material/BoltTwoTone";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleTwoTone";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownTwoTone";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpTwoTone";
import LayersOutlinedIcon from "@mui/icons-material/LayersTwoTone";
import PaymentsIcon from "@mui/icons-material/PaymentsTwoTone";
import SolarPowerOutlinedIcon from "@mui/icons-material/SolarPowerTwoTone";
import TextureIcon from "@mui/icons-material/TextureTwoTone";
import type { ReactNode } from "react";
import type { InstallationItem } from "../installations";
import { InstallationItems } from "../installations";
import {
  formatCurrency as Currency,
  formatIntegers as Integer,
  formatNumber as Decimal,
} from "../utils";

export type NameValuePair = [string, string] | [string, string, boolean];

export interface FieldMap {
  filedName: string;
  label: ReactNode;
  icon: SvgIconComponent;
  format?: (value: number | string) => string;
  breakdown?: boolean;
}

const kWhSavings: FieldMap[] = [
  {
    filedName: "out:DH kWh savings",
    label: "kWh besparing Fjärrvärme",
    icon: BoltIcon,
    format: Decimal,
  },
  {
    filedName: "out:EL kWh savings",
    label: "kWh besparing (El)",
    icon: BoltIcon,
    format: Decimal,
  },
];

export const installationBreakdownFieldMapping: Record<
  InstallationItem,
  {
    cost: {
      preview: FieldMap[];
      breakdown: NameValuePair[];
    };
    energy: {
      preview: FieldMap[];
      breakdown: NameValuePair[];
    };
  }
> = {
  [InstallationItems.takisolering]: {
    energy: {
      preview: [
        {
          filedName: "out:DH kr savings",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:DH kWh savings",
          label: "Total besparing (kWh)",
          icon: LayersOutlinedIcon,
          format: Decimal,
          breakdown: true,
        },
      ],
      breakdown: [
        ["% total", "out:% savings (space heating)"],
        ["Total uppvärmning yta (kWh)", "out:Total_Space Heating (DH)"],
        ["U-värde innan", "out:Roof U-value (before)"],
        ["U-värde efter", "out:Roof U-value (after)"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:Roof surface/m2",
          label: (
            <>
              Takyta (m<sup>2</sup>)
            </>
          ),
          icon: TextureIcon,
          format: Integer,
        },
        {
          filedName: "out:Takisolering (kr/m2)",
          label: (
            <>
              kr/m<sup>2</sup>
            </>
          ),
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [
        ["Total kostnad (kr/m2)", "out:Takisolering Kostnad", true],
        ["Material (kr/m2)", "out:Takisolering Materialkostnad", true],
        ["Isolering (kr/m2)", "out:Takisolering Materialkostnad (kr/m2)"],
        [
          "Plasterboard/panel (kr/m2)",
          "out:Takisolering Kostnad Plasterboard/panel (kr/m2)",
        ],
        [
          "Arbetskostnad (Brutto) (kr/m2)",
          "out:Takisolering Arbetskostnad brutto",
          true,
        ],
        [
          "Arbetskostnad (Tillägg) (kr/m2)",
          "out:Takisolering Arbetskostnad tillägg",
        ],
        [
          "Extrakostnad på arbete",
          "out:Takisolering Extrakostnad på arbete (%)",
        ],
        [
          "Arbetskostnad (Netto) (kr/m2)",
          "out:Takisolering Arbetskostnad netto",
        ],
        [
          "Arbetstimmar (timme/m2)",
          "out:Takisolering Arbetskostnad netto (timmar/m2)2",
        ],
        ["Arbetskostnad (kr/timme)", "out:Takisolering (kr/timme)"],
      ],
    },
  },
  [InstallationItems.nuläge]: {
    energy: {
      preview: [...kWhSavings],
      breakdown: [],
    },
    cost: {
      preview: [
        {
          filedName: "out:Roof surface",
          label: (
            <>
              Yta (m<sup>2</sup>)
            </>
          ),
          icon: TextureIcon,
          format: Decimal,
        },
        {
          filedName: "out:Takisolering Kostnad",
          label: (
            <>
              Kostnad (kr/m<sup>2</sup>)
            </>
          ),
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [],
    },
  },
  [InstallationItems.imdTappvarmvatten]: {
    energy: {
      preview: [
        {
          filedName: "out:DH kr savings",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:DH kWh savings",
          label: "Total besparing (kWh)",
          icon: BoltIcon,
          format: Decimal,
          breakdown: true,
        },
      ],
      breakdown: [
        ["% besparing antagit", "out:IMD (% savings assumed)"],
        ["Total tvv (kWh)", "out:Total_Water Heating (DH)"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:IMD (#apts)",
          label: "antal lägenheter",
          icon: TextureIcon,
          format: Decimal,
        },
        {
          filedName: "out:IMD (kr/apt)",
          label: "kr/lgh",
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [],
    },
  },
  [InstallationItems.solceller]: {
    energy: {
      preview: [
        {
          filedName: "out:El kr return",
          label: "Total avkastning (kr)",
          icon: SolarPowerOutlinedIcon,
          format: Currency,
        },
        {
          filedName: "out:El kr saved",
          label: "Total besparing (kr)",
          icon: KeyboardDoubleArrowUpOutlinedIcon,
          format: Currency,
        },
        {
          filedName: "out:El kr sold",
          label: "Total såld (kr)",
          icon: KeyboardDoubleArrowDownOutlinedIcon,
          format: Currency,
        },
        {
          filedName: "out:PV (kWh produced)",
          label: "Total producerad (kWh)",
          icon: ChangeCircleOutlinedIcon,
          format: Decimal,
          breakdown: true,
        },
      ],
      breakdown: [
        ["Egenanvändning (kWh)", "out:PV (kWh self-consumed)"],
        ["Täckningsgrad (%)", "out:% solar/total"],
        ["Total elförbrukning (kWh)", "out:Total_El consumption (without PV)"],
        ["Såld el (kWh)", "out:PV (kWh sold)"],
        ["Andel såld av producerad (%)", "out:PV (% sold (El))"],
        ["m2 installerad", "out:PV (m2 panels)"],
        ["kWh/m2", "out:PV (kWh/m2)"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:PV (kWp installed)",
          label: "kWp installerad",
          icon: TextureIcon,
          format: Decimal,
        },
        {
          filedName: "out:PV (kr/kWp)",
          label: "kr/kWp",
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [],
    },
  },
  [InstallationItems.fönster]: {
    energy: {
      preview: [
        {
          filedName: "out:DH kr savings",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:DH kWh savings",
          label: "Total besparing (kWh)",
          icon: LayersOutlinedIcon,
          format: Decimal,
          breakdown: true,
        },
      ],
      breakdown: [
        ["% total", "out:% savings (space heating)"],
        ["Total uppvärmning yta (kWh)", "out:Total_Space Heating (DH)"],
        ["U-värde innan", "out:Windows (U-value before)"],
        ["U-värde efter", "out:Windows (U-value after)"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:Window surface/m2",
          label: (
            <>
              Fönsteryta (m<sup>2</sup>)
            </>
          ),
          icon: TextureIcon,
          format: Integer,
        },
        {
          filedName: "out:Windows(kr/m2)",
          label: (
            <>
              kr/m<sup>2</sup>
            </>
          ),
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [],
    },
  },
  [InstallationItems.fasadisolering]: {
    energy: {
      preview: [
        {
          filedName: "out:DH kr savings",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:DH kWh savings",
          label: "Total besparing (kWh)",
          icon: LayersOutlinedIcon,
          format: Decimal,
          breakdown: true,
        },
      ],
      breakdown: [
        ["% total", "out:% savings (space heating)"],
        ["Total uppvärmning yta (kWh)", "out:Total_Space Heating (DH)"],
        ["U-värde innan", "out:Facade U-value (before)"],
        ["U-värde efter", "out:Facade U-value (after)"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:Facade surface/m2",
          label: (
            <>
              Fasadyta (m<sup>2</sup>)
            </>
          ),
          icon: TextureIcon,
          format: Decimal,
        },
        {
          filedName: "out:Fasadisolering (kr/m2)",
          label: (
            <>
              kr/m<sup>2</sup>
            </>
          ),
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [
        ["Total kostnad (kr/m2)", "out:Fasadisolering Kostnad", true],
        ["Material (kr/m2)", "out:Fasadisolering Materialkostnad", true],
        ["Isolering (kr/m2)", "out:Fasadisolering Materialkostnad (kr/m2)"],
        ["Ställning (kr/m2)", "out:Fasadisolering Ställning"],
        [
          "Arbetskostnad (Brutto) (kr/m2)",
          "out:Fasadisolering Arbetskostnad brutto",
          true,
        ],
        [
          "Arbetskostnad (Tillägg) (kr/m2)",
          "out:Fasadisolering Arbetskostnad tillägg",
        ],
        [
          "Extrakostnad på arbete",
          "out:Fasadisolering Extrakostnad på arbete (%)",
        ],
        [
          "Arbetskostnad (Netto) (kr/m2)",
          "out:Fasadisolering Arbetskostnad netto",
        ],
        [
          "Arbetstimmar (timme/m2)",
          "out:Fasadisolering Arbetskostnad netto (timmar/m2)",
        ],
        ["Arbetskostnad (kr/timme)", "out:Fasadisolering (kr/timme)"],
      ],
    },
  },
  [InstallationItems.avloppsvärmeväxlare]: {
    energy: {
      preview: [
        {
          filedName: "out:DH kr savings",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:DH kWh savings",
          label: "Total besparing (kWh)",
          icon: LayersOutlinedIcon,
          format: Decimal,
          breakdown: true,
        },
      ],
      breakdown: [
        ["% besparing antagit", "out:Sewage heat recovery (% savings assumed)"],
        ["Total uppvärmning tvv (kWh)", "out:Total_Water Heating (DH)"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:number of heat exchangers",
          label: "antal värmeväxlare",
          icon: TextureIcon,
          format: Decimal,
        },
        {
          filedName: "out:kr/heat exchanger",
          label: "kr/värmeväxlare",
          icon: PaymentsIcon,
          format: Currency,
          breakdown: true,
        },
      ],
      breakdown: [],
    },
  },
  [InstallationItems.improvedahu]: {
    energy: {
      preview: [
        {
          filedName: "out:DH kr savings",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:DH kWh savings",
          label: "Total besparing (kWh)",
          icon: LayersOutlinedIcon,
          format: Decimal,
          breakdown: true,
        },
      ],
      breakdown: [],
    },
    cost: {
      preview: [
        {
          filedName: "out:New AHU Cost/SEK",
          label: <>kr</>,
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [],
    },
  },
  [InstallationItems.injustering]: {
    energy: {
      preview: [
        {
          filedName: "out:DH kr savings",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:DH kWh savings",
          label: "Total besparing (kWh)",
          icon: LayersOutlinedIcon,
          format: Decimal,
          breakdown: true,
        },
      ],
      breakdown: [
        ["% total", "out:% savings (space heating)"],
        ["Total uppvärmning yta (kWh)", "out:Total_Space Heating (DH)"],
        ["Regleringsförlust efter", "out:Regulation losses (%)"],
        ["Regleringsförlust innan", "out:Regulation losses_default"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:Atemp",
          label: (
            <>
              (m<sup>2</sup>) Atemp
            </>
          ),
          icon: TextureIcon,
          format: Currency,
        },
        {
          filedName: "out:cost/m2 injustering",
          label: (
            <>
              kr/(m<sup>2</sup>)
            </>
          ),
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [],
    },
  },
  [InstallationItems.ftxFasadintegrerad]: {
    energy: {
      preview: [
        {
          filedName: "out:Total besparing (kr)",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
          breakdown: true,
        },
        {
          filedName: "out:DH kr savings",
          label: "Besparing fjärrvärme (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:El kr savings",
          label: "Kostnadsökning El (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [
        ["% återvinning", "out:FTX (%heat recovery efficiency)"],
        ["Fjärrvärme (kWh)", "out:DH kWh savings"],
        ["El (kWh)", "out:EL kWh savings"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:FTX (AHU)",
          label: "Ventilationsaggregat",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Ductwork)",
          label: "Ventilationskanaler",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Diffusers)",
          label: "Tilluftsdon",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Dampers)",
          label: "Brandspjäl",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Roof)",
          label: "Takisolering",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Facade)",
          label: "Fasadisolering",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:cost_raised roof",
          label: "Taklyft",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (S to F improvement cost)",
          label: "Uppgradering från S till F",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Replacement Cost)",
          label: "Ny aggregat",
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [
        ["Taklyft", "out:cost_raised roof"],
        ["Ventilationsaggregat", "out:FTX (AHU)"],
        ["Ventilationskanaler", "out:FTX (Ductwork)"],
        ["Tilluftsdon", "out:FTX (Diffusers)"],
        ["Brandspjäl", "out:FTX (Dampers)"],
        ["Takisolering", "out:FTX (Roof)"],
        ["Fasadisolering", "out:FTX (Façade)"],
      ],
    },
  },
  [InstallationItems.ftx]: {
    energy: {
      preview: [
        {
          filedName: "out:Total besparing (kr)",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
          breakdown: true,
        },
        {
          filedName: "out:DH kr savings",
          label: "Besparing fjärrvärme (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:El kr savings",
          label: "Kostnadsökning El (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [
        ["% återvinning", "out:FTX (%heat recovery efficiency)"],
        ["Fjärrvärme (kWh)", "out:DH kWh savings"],
        ["El (kWh)", "out:EL kWh savings"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:FTX (AHU)",
          label: "Ventilationsaggregat",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Ductwork)",
          label: "Ventilationskanaler",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Diffusers)",
          label: "Tilluftsdon",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Dampers)",
          label: "Brandspjäl",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:cost_raised roof",
          label: "Taklyft",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (S to F improvement cost)",
          label: "Uppgradering från S till F",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:FTX (Replacement Cost)",
          label: "Ny aggregat",
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [
        ["Taklyft", "out:cost_raised roof"],
        ["Ventilationsaggregat", "out:FTX (AHU)"],
        ["Ventilationskanaler", "out:FTX (Ductwork)"],
        ["Tilluftsdon", "out:FTX (Diffusers)"],
        ["Brandspjäl", "out:FTX (Dampers)"],
        ["Takisolering", "out:FTX (Roof)"],
        ["Fasadisolering", "out:FTX (Façade)"],
      ],
    },
  },
  [InstallationItems.frånluftsvärmepump]: {
    energy: {
      preview: [
        {
          filedName: "out:Total besparing (kr)",
          label: "Total besparing (kr)",
          icon: PaymentsIcon,
          format: Currency,
          breakdown: true,
        },
        {
          filedName: "out:DH kr savings",
          label: "Besparing fjärrvärme (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:El kr savings",
          label: "Kostnadsökning El (kr)",
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [
        ["SCOP", "out:EAHP (SCOP of heatpump)"],
        ["Fjärrvärme (kWh)", "out:DH kWh savings"],
        ["El (kWh)", "out:EL kWh savings"],
      ],
    },
    cost: {
      preview: [
        {
          filedName: "out:EAHP (Heatpump)",
          label: "Värmepump",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:EAHP (1000ltanks)",
          label: "Ackumulatortank",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:EAHP (pipework)",
          label: "Rörledningar",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:EAHP (natural ventilation grills)",
          label: "Ventilationsgaller",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:cost_raised roof",
          label: "Taklyft",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:EAHP (installation)",
          label: "Installationskostnad",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:EAHP (replacement cost)",
          label: "Ny aggregat",
          icon: PaymentsIcon,
          format: Currency,
        },
        {
          filedName: "out:EAHP (S to F improvement cost)",
          label: "Uppgradering från S till F",
          icon: PaymentsIcon,
          format: Currency,
        },
      ],
      breakdown: [
        ["Taklyft", "out:cost_raised roof"],
        ["Värmepump", "out:EAHP (Heatpump)"],
        ["Ackumulatortank", "out:EAHP (1000ltanks)"],
        ["Rörledningar", "out:EAHP (pipework)"],
        ["Ventilationsgaller", "out:EAHP (natural ventilation grills)"],
      ],
    },
  },
};
