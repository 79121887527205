import {
  BarElement,
  CategoryScale,
  Chart as chartJs,
  type ChartData,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { type ReadFieldValue, useFieldValue } from "packages/dataAccess";
import { EnergyClassColorMap } from "packages/energy-class";
import type {
  InstallationDataset,
  InstallationItem,
} from "packages/installations";
import { formatNumber } from "packages/utils";
import { type ComponentProps, type ReactElement, useMemo } from "react";
import { Bar } from "react-chartjs-2";

const MAX_BAR_THICKNESS = 200;

chartJs.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options: ComponentProps<typeof Bar>["options"] = {
  plugins: {
    title: {
      display: false,
    },

    tooltip: {
      callbacks: {
        label: (context) => {
          // Retrieving the original data for tooltips
          const index =
            typeof context.raw === "number"
              ? formatNumber(context.raw)
              : String(context.raw);

          return ` ${context.dataset.label}: ${index}`;
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        // forces step size to be 50 units
        stepSize: 50,
      },
    },
  },
};

interface GetDataParameters {
  readFieldValue: ReadFieldValue;
  installationDataset: InstallationDataset;
  listOfImprovements: string[];
}

export const getData = ({
  readFieldValue,
  installationDataset,
  listOfImprovements,
}: GetDataParameters): ChartData<
  "bar",
  Array<number | [number, number]>,
  unknown
> => ({
  labels: listOfImprovements,
  datasets: [
    {
      label: "Uppvärmning",
      data: installationDataset.map(([, raw]) => {
        return Number(readFieldValue(raw, "out:Space Heating (DH)"));
      }),
      maxBarThickness: MAX_BAR_THICKNESS,
      backgroundColor: (context) => {
        const [, raw] = installationDataset[context.dataIndex];

        const energyClass = String(readFieldValue(raw, "out:Energy Class"));

        return `rgb(${EnergyClassColorMap.get(energyClass)})`;
      },
    },
    {
      label: "Tappvarmvatten",
      data: installationDataset.map(([, raw]) => {
        return Number(readFieldValue(raw, "out:DHW (DH)"));
      }),
      maxBarThickness: MAX_BAR_THICKNESS,
      backgroundColor: (context) => {
        const [, raw] = installationDataset[context.dataIndex];

        return `rgba(${EnergyClassColorMap.get(
          String(readFieldValue(raw, "out:Energy Class")),
        )}, 0.7)`;
      },
    },
    {
      label: "El",
      data: installationDataset.map(([, raw]) => {
        return (
          Number(readFieldValue(raw, "out:Property Electricity")) -
          Number(readFieldValue(raw, "out:Total Energy Use Pre PV")) +
          Number(readFieldValue(raw, "out:Total Energy Use Post PV"))
        );
      }),
      maxBarThickness: MAX_BAR_THICKNESS,
      backgroundColor: (context) => {
        const [, raw] = installationDataset[context.dataIndex];

        return `rgba(${EnergyClassColorMap.get(
          String(readFieldValue(raw, "out:Energy Class")),
        )}, 0.3)`;
      },
    },
  ],
});

export interface EnergyConsumptionChartProps {
  selectedInstallationDataset: InstallationDataset;
  selectedInstallations: InstallationItem[];
}

export function EnergyConsumptionChart({
  selectedInstallationDataset,
  selectedInstallations,
}: EnergyConsumptionChartProps): ReactElement {
  const readFieldValue = useFieldValue();

  const chartData = useMemo(
    () =>
      getData({
        readFieldValue,
        installationDataset: selectedInstallationDataset,
        listOfImprovements: selectedInstallations,
      }),
    [selectedInstallations, selectedInstallationDataset, readFieldValue],
  );

  return (
    <div
      style={{
        width: "100%",
        height: "400px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Bar options={options} data={chartData} />
    </div>
  );
}
