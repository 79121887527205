export const formatNumber = (
  value: number | string,
  maximumFractionDigits = 0,
): string => {
  if (value === undefined || typeof value === "string") {
    return value;
  }
  return value.toLocaleString("sv-SE", {
    maximumFractionDigits,
  });
};

export const formatIntegers = (value: number | string): string =>
  formatNumber(value, 0);
