export default [
  {
    "ID": "Z00000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 193.6,
    "out:Total Energy Use Post PV": 193.6,
    "out:Primary Energy": 185.52,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 67.96,
    "out:Total CO2/m2": 67.96,
    "out:Total CO2 (tons)": 218.83,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 51602,
    "out:Running Cost over RSP/MSEK": 14.526,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 0,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 194,
    "out:Energy use kWh/m2": 194,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 186,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 25,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 185.6,
    "out:Total Energy Use Post PV": 185.6,
    "out:Primary Energy": 178.74,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 65.08,
    "out:Total CO2/m2": 65.08,
    "out:Total CO2 (tons)": 209.56,
    "out:Klimatpaverkan": -9.27,
    "out:Initial Cost/MSEK": 0.081,
    "out:Running cost/(Apt SEK y)": 49441,
    "out:Running Cost over RSP/MSEK": 13.917,
    "out:LCP/MSEK": 0.528,
    "out:ROI% old": 107.98,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 186,
    "out:Energy use kWh/m2": 186,
    "out:Energy savings %": 4.3,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 178,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 97,
    "out:Return/kSEK/y": 78,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 32,
    "out:Payback Time": 3.125,
    "out:DH kWh savings": 25760,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 25760,
    "out:DH kr savings": 25760,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 192.6,
    "out:Total Energy Use Post PV": 192.6,
    "out:Primary Energy": 182.89,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0.35,
    "out:CO2 Operational/m2": 67.6,
    "out:Total CO2/m2": 67.95,
    "out:Total CO2 (tons)": 218.8,
    "out:Klimatpaverkan": -0.03,
    "out:Initial Cost/MSEK": 0.137,
    "out:Running cost/(Apt SEK y)": 51332,
    "out:Running Cost over RSP/MSEK": 14.45,
    "out:LCP/MSEK": -0.061,
    "out:ROI% old": 7.95,
    "out:Payback discounted": 15,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 193,
    "out:Energy use kWh/m2": 193,
    "out:Energy savings %": 0.5,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 185,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 7,
    "out:Return/kSEK/y": 10,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 3220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 3220,
    "out:DH kr savings": 3220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 184.6,
    "out:Total Energy Use Post PV": 184.6,
    "out:Primary Energy": 176.11,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.35,
    "out:CO2 Operational/m2": 64.72,
    "out:Total CO2/m2": 65.07,
    "out:Total CO2 (tons)": 209.53,
    "out:Klimatpaverkan": -9.3,
    "out:Initial Cost/MSEK": 0.217,
    "out:Running cost/(Apt SEK y)": 49171,
    "out:Running Cost over RSP/MSEK": 13.841,
    "out:LCP/MSEK": 0.467,
    "out:ROI% old": 45.04,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 185,
    "out:Energy use kWh/m2": 185,
    "out:Energy savings %": 4.9,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 177,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 40,
    "out:Return/kSEK/y": 88,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 28980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 28980,
    "out:DH kr savings": 28980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 193.6,
    "out:Total Energy Use Post PV": 190.91,
    "out:Primary Energy": 180.68,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 8.24,
    "out:CO2 Operational/m2": 43.48,
    "out:Total CO2/m2": 51.72,
    "out:Total CO2 (tons)": 166.53,
    "out:Klimatpaverkan": -52.3,
    "out:Initial Cost/MSEK": 0.447,
    "out:Running cost/(Apt SEK y)": 50482,
    "out:Running Cost over RSP/MSEK": 14.219,
    "out:LCP/MSEK": -0.14,
    "out:ROI% old": 9.81,
    "out:Payback discounted": 12,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 191,
    "out:Energy use kWh/m2": 191,
    "out:Energy savings %": 1.6,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 186,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 40,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 40316,
    "out:DH kr savings": 0,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 185.6,
    "out:Total Energy Use Post PV": 182.91,
    "out:Primary Energy": 173.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.24,
    "out:CO2 Operational/m2": 40.6,
    "out:Total CO2/m2": 48.84,
    "out:Total CO2 (tons)": 157.26,
    "out:Klimatpaverkan": -61.57,
    "out:Initial Cost/MSEK": 0.527,
    "out:Running cost/(Apt SEK y)": 48321,
    "out:Running Cost over RSP/MSEK": 13.611,
    "out:LCP/MSEK": 0.388,
    "out:ROI% old": 24.81,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 6,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 178,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 118,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 25760,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 66076,
    "out:DH kr savings": 25760,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 192.6,
    "out:Total Energy Use Post PV": 189.91,
    "out:Primary Energy": 178.05,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 8.59,
    "out:CO2 Operational/m2": 43.12,
    "out:Total CO2/m2": 51.71,
    "out:Total CO2 (tons)": 166.5,
    "out:Klimatpaverkan": -52.33,
    "out:Initial Cost/MSEK": 0.583,
    "out:Running cost/(Apt SEK y)": 50212,
    "out:Running Cost over RSP/MSEK": 14.143,
    "out:LCP/MSEK": -0.2,
    "out:ROI% old": 9.38,
    "out:Payback discounted": 13,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 190,
    "out:Energy use kWh/m2": 190,
    "out:Energy savings %": 2.1,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 185,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 50,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 3220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 43536,
    "out:DH kr savings": 3220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 184.6,
    "out:Total Energy Use Post PV": 181.91,
    "out:Primary Energy": 171.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.59,
    "out:CO2 Operational/m2": 40.24,
    "out:Total CO2/m2": 48.83,
    "out:Total CO2 (tons)": 157.22,
    "out:Klimatpaverkan": -61.61,
    "out:Initial Cost/MSEK": 0.664,
    "out:Running cost/(Apt SEK y)": 48051,
    "out:Running Cost over RSP/MSEK": 13.535,
    "out:LCP/MSEK": 0.328,
    "out:ROI% old": 21.34,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 6.6,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 177,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 128,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 28980,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69296,
    "out:DH kr savings": 28980,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 191.6,
    "out:Total Energy Use Post PV": 191.6,
    "out:Primary Energy": 182.02,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 67.24,
    "out:Total CO2/m2": 67.24,
    "out:Total CO2 (tons)": 216.51,
    "out:Klimatpaverkan": -2.32,
    "out:Initial Cost/MSEK": 0.095,
    "out:Running cost/(Apt SEK y)": 51062,
    "out:Running Cost over RSP/MSEK": 14.374,
    "out:LCP/MSEK": 0.057,
    "out:ROI% old": 22.87,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 192,
    "out:Energy use kWh/m2": 192,
    "out:Energy savings %": 1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 184,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 20,
    "out:Return/kSEK/y": 19,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 6440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 6440,
    "out:DH kr savings": 6440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 183.6,
    "out:Total Energy Use Post PV": 183.6,
    "out:Primary Energy": 175.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 64.36,
    "out:Total CO2/m2": 64.36,
    "out:Total CO2 (tons)": 207.24,
    "out:Klimatpaverkan": -11.59,
    "out:Initial Cost/MSEK": 0.176,
    "out:Running cost/(Apt SEK y)": 48901,
    "out:Running Cost over RSP/MSEK": 13.765,
    "out:LCP/MSEK": 0.585,
    "out:ROI% old": 61.91,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 184,
    "out:Energy use kWh/m2": 184,
    "out:Energy savings %": 5.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 176,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 55,
    "out:Return/kSEK/y": 97,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 32200,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 32200,
    "out:DH kr savings": 32200,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 190.6,
    "out:Total Energy Use Post PV": 190.6,
    "out:Primary Energy": 179.92,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0.35,
    "out:CO2 Operational/m2": 66.88,
    "out:Total CO2/m2": 67.23,
    "out:Total CO2 (tons)": 216.48,
    "out:Klimatpaverkan": -2.35,
    "out:Initial Cost/MSEK": 0.232,
    "out:Running cost/(Apt SEK y)": 50792,
    "out:Running Cost over RSP/MSEK": 14.298,
    "out:LCP/MSEK": -0.003,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 191,
    "out:Energy use kWh/m2": 191,
    "out:Energy savings %": 1.6,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 183,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 29,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 9660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 9660,
    "out:DH kr savings": 9660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 182.6,
    "out:Total Energy Use Post PV": 182.6,
    "out:Primary Energy": 173.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.35,
    "out:CO2 Operational/m2": 64,
    "out:Total CO2/m2": 64.35,
    "out:Total CO2 (tons)": 207.21,
    "out:Klimatpaverkan": -11.62,
    "out:Initial Cost/MSEK": 0.312,
    "out:Running cost/(Apt SEK y)": 48631,
    "out:Running Cost over RSP/MSEK": 13.689,
    "out:LCP/MSEK": 0.525,
    "out:ROI% old": 38.29,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 6,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 175,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 34,
    "out:Return/kSEK/y": 107,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 35420,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 35420,
    "out:DH kr savings": 35420,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 191.6,
    "out:Total Energy Use Post PV": 188.91,
    "out:Primary Energy": 177.18,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 8.24,
    "out:CO2 Operational/m2": 42.76,
    "out:Total CO2/m2": 51,
    "out:Total CO2 (tons)": 164.21,
    "out:Klimatpaverkan": -54.62,
    "out:Initial Cost/MSEK": 0.542,
    "out:Running cost/(Apt SEK y)": 49942,
    "out:Running Cost over RSP/MSEK": 14.067,
    "out:LCP/MSEK": -0.083,
    "out:ROI% old": 12.11,
    "out:Payback discounted": 10,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 189,
    "out:Energy use kWh/m2": 189,
    "out:Energy savings %": 2.6,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 184,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 60,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 6440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46756,
    "out:DH kr savings": 6440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 183.6,
    "out:Total Energy Use Post PV": 180.91,
    "out:Primary Energy": 170.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.24,
    "out:CO2 Operational/m2": 39.88,
    "out:Total CO2/m2": 48.12,
    "out:Total CO2 (tons)": 154.94,
    "out:Klimatpaverkan": -63.89,
    "out:Initial Cost/MSEK": 0.622,
    "out:Running cost/(Apt SEK y)": 47781,
    "out:Running Cost over RSP/MSEK": 13.458,
    "out:LCP/MSEK": 0.445,
    "out:ROI% old": 24.51,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 176,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 138,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 32200,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72516,
    "out:DH kr savings": 32200,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 190.6,
    "out:Total Energy Use Post PV": 187.91,
    "out:Primary Energy": 175.08,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.59,
    "out:CO2 Operational/m2": 42.4,
    "out:Total CO2/m2": 50.99,
    "out:Total CO2 (tons)": 164.18,
    "out:Klimatpaverkan": -54.65,
    "out:Initial Cost/MSEK": 0.678,
    "out:Running cost/(Apt SEK y)": 49672,
    "out:Running Cost over RSP/MSEK": 13.991,
    "out:LCP/MSEK": -0.143,
    "out:ROI% old": 11.27,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 188,
    "out:Energy use kWh/m2": 188,
    "out:Energy savings %": 3.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 183,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 69,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 9660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49976,
    "out:DH kr savings": 9660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 182.6,
    "out:Total Energy Use Post PV": 179.91,
    "out:Primary Energy": 168.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.59,
    "out:CO2 Operational/m2": 39.52,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 154.91,
    "out:Klimatpaverkan": -63.92,
    "out:Initial Cost/MSEK": 0.759,
    "out:Running cost/(Apt SEK y)": 47511,
    "out:Running Cost over RSP/MSEK": 13.382,
    "out:LCP/MSEK": 0.385,
    "out:ROI% old": 21.53,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 180,
    "out:Energy use kWh/m2": 180,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 175,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 147,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 35420,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 75736,
    "out:DH kr savings": 35420,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 169.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 184.84,
    "out:Total Energy Use Post PV": 184.85,
    "out:Primary Energy": 187.13,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.26,
    "out:CO2 Operational/m2": 63.16,
    "out:Total CO2/m2": 66.42,
    "out:Total CO2 (tons)": 213.87,
    "out:Klimatpaverkan": -4.96,
    "out:Initial Cost/MSEK": 0.611,
    "out:Running cost/(Apt SEK y)": 48576,
    "out:Running Cost over RSP/MSEK": 13.669,
    "out:LCP/MSEK": 0.246,
    "out:ROI% old": 20.03,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 185,
    "out:Energy use kWh/m2": 185,
    "out:Energy savings %": 4.9,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 18,
    "out:Return/kSEK/y": 109,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 1,
    "out:Payback Time": 100,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 4857,
    "out:DH kr savings": 51520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 546347,
    "out:% savings (space heating)": 3.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 161.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 176.84,
    "out:Total Energy Use Post PV": 176.85,
    "out:Primary Energy": 180.36,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.26,
    "out:CO2 Operational/m2": 60.28,
    "out:Total CO2/m2": 63.54,
    "out:Total CO2 (tons)": 204.6,
    "out:Klimatpaverkan": -14.23,
    "out:Initial Cost/MSEK": 0.691,
    "out:Running cost/(Apt SEK y)": 46415,
    "out:Running Cost over RSP/MSEK": 13.061,
    "out:LCP/MSEK": 0.774,
    "out:ROI% old": 30.28,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 9.6,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 27,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77280,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 30617,
    "out:DH kr savings": 77280,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 521409,
    "out:% savings (space heating)": 8.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 182.83,
    "out:Total Energy Use Post PV": 182.85,
    "out:Primary Energy": 184.51,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.61,
    "out:CO2 Operational/m2": 62.44,
    "out:Total CO2/m2": 66.05,
    "out:Total CO2 (tons)": 212.68,
    "out:Klimatpaverkan": -6.15,
    "out:Initial Cost/MSEK": 0.747,
    "out:Running cost/(Apt SEK y)": 48034,
    "out:Running Cost over RSP/MSEK": 13.517,
    "out:LCP/MSEK": 0.262,
    "out:ROI% old": 19.29,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 6,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 128,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 11297,
    "out:DH kr savings": 57960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 541828,
    "out:% savings (space heating)": 4.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 175.83,
    "out:Total Energy Use Post PV": 175.85,
    "out:Primary Energy": 177.73,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.61,
    "out:CO2 Operational/m2": 59.92,
    "out:Total CO2/m2": 63.53,
    "out:Total CO2 (tons)": 204.56,
    "out:Klimatpaverkan": -14.27,
    "out:Initial Cost/MSEK": 0.828,
    "out:Running cost/(Apt SEK y)": 46143,
    "out:Running Cost over RSP/MSEK": 12.984,
    "out:LCP/MSEK": 0.714,
    "out:ROI% old": 26.6,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 24,
    "out:Return/kSEK/y": 197,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80500,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 33837,
    "out:DH kr savings": 80500,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516893,
    "out:% savings (space heating)": 8.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 169.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 184.84,
    "out:Total Energy Use Post PV": 180.5,
    "out:Primary Energy": 179.32,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 46.96,
    "out:Total CO2/m2": 58.46,
    "out:Total CO2 (tons)": 188.25,
    "out:Klimatpaverkan": -30.58,
    "out:Initial Cost/MSEK": 1.057,
    "out:Running cost/(Apt SEK y)": 47382,
    "out:Running Cost over RSP/MSEK": 13.342,
    "out:LCP/MSEK": 0.126,
    "out:ROI% old": 15.99,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.43,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 47842,
    "out:DH kr savings": 51520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 546347,
    "out:% savings (space heating)": 3.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 161.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 176.84,
    "out:Total Energy Use Post PV": 172.5,
    "out:Primary Energy": 172.54,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 44.08,
    "out:Total CO2/m2": 55.58,
    "out:Total CO2 (tons)": 178.98,
    "out:Klimatpaverkan": -39.85,
    "out:Initial Cost/MSEK": 1.138,
    "out:Running cost/(Apt SEK y)": 45221,
    "out:Running Cost over RSP/MSEK": 12.734,
    "out:LCP/MSEK": 0.654,
    "out:ROI% old": 22.5,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 12.1,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 230,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.45,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.17,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 77280,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73602,
    "out:DH kr savings": 77280,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 521409,
    "out:% savings (space heating)": 8.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 182.83,
    "out:Total Energy Use Post PV": 178.5,
    "out:Primary Energy": 176.69,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 11.85,
    "out:CO2 Operational/m2": 46.23,
    "out:Total CO2/m2": 58.08,
    "out:Total CO2 (tons)": 187.02,
    "out:Klimatpaverkan": -31.81,
    "out:Initial Cost/MSEK": 1.194,
    "out:Running cost/(Apt SEK y)": 46840,
    "out:Running Cost over RSP/MSEK": 13.19,
    "out:LCP/MSEK": 0.142,
    "out:ROI% old": 15.99,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 8.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.25,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.36,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54292,
    "out:DH kr savings": 57960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 541828,
    "out:% savings (space heating)": 4.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 175.83,
    "out:Total Energy Use Post PV": 171.5,
    "out:Primary Energy": 169.91,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.85,
    "out:CO2 Operational/m2": 43.71,
    "out:Total CO2/m2": 55.56,
    "out:Total CO2 (tons)": 178.91,
    "out:Klimatpaverkan": -39.92,
    "out:Initial Cost/MSEK": 1.274,
    "out:Running cost/(Apt SEK y)": 44949,
    "out:Running Cost over RSP/MSEK": 12.657,
    "out:LCP/MSEK": 0.594,
    "out:ROI% old": 20.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.46,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.16,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 80500,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 76832,
    "out:DH kr savings": 80500,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516893,
    "out:% savings (space heating)": 8.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 167.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 182.83,
    "out:Total Energy Use Post PV": 182.85,
    "out:Primary Energy": 183.63,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.26,
    "out:CO2 Operational/m2": 62.43,
    "out:Total CO2/m2": 65.7,
    "out:Total CO2 (tons)": 211.55,
    "out:Klimatpaverkan": -7.28,
    "out:Initial Cost/MSEK": 0.706,
    "out:Running cost/(Apt SEK y)": 48033,
    "out:Running Cost over RSP/MSEK": 13.516,
    "out:LCP/MSEK": 0.304,
    "out:ROI% old": 20.43,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 6,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 18,
    "out:Return/kSEK/y": 129,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 11297,
    "out:DH kr savings": 57960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 540338,
    "out:% savings (space heating)": 4.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 174.83,
    "out:Total Energy Use Post PV": 174.85,
    "out:Primary Energy": 176.86,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.26,
    "out:CO2 Operational/m2": 59.55,
    "out:Total CO2/m2": 62.82,
    "out:Total CO2 (tons)": 202.27,
    "out:Klimatpaverkan": -16.56,
    "out:Initial Cost/MSEK": 0.786,
    "out:Running cost/(Apt SEK y)": 45872,
    "out:Running Cost over RSP/MSEK": 12.908,
    "out:LCP/MSEK": 0.832,
    "out:ROI% old": 29.4,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 26,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 37057,
    "out:DH kr savings": 83720,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 515406,
    "out:% savings (space heating)": 9.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 166.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 181.81,
    "out:Total Energy Use Post PV": 181.85,
    "out:Primary Energy": 181.53,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.61,
    "out:CO2 Operational/m2": 62.07,
    "out:Total CO2/m2": 65.69,
    "out:Total CO2 (tons)": 211.51,
    "out:Klimatpaverkan": -7.32,
    "out:Initial Cost/MSEK": 0.842,
    "out:Running cost/(Apt SEK y)": 47760,
    "out:Running Cost over RSP/MSEK": 13.44,
    "out:LCP/MSEK": 0.244,
    "out:ROI% old": 18.42,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 6.6,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 167,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 138,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 61180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 14517,
    "out:DH kr savings": 61180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 536811,
    "out:% savings (space heating)": 5.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 158.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 173.81,
    "out:Total Energy Use Post PV": 173.85,
    "out:Primary Energy": 174.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.61,
    "out:CO2 Operational/m2": 59.19,
    "out:Total CO2/m2": 62.81,
    "out:Total CO2 (tons)": 202.23,
    "out:Klimatpaverkan": -16.6,
    "out:Initial Cost/MSEK": 0.923,
    "out:Running cost/(Apt SEK y)": 45598,
    "out:Running Cost over RSP/MSEK": 12.831,
    "out:LCP/MSEK": 0.772,
    "out:ROI% old": 26.24,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 11.5,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 40277,
    "out:DH kr savings": 86940,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 511890,
    "out:% savings (space heating)": 9.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 167.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 182.83,
    "out:Total Energy Use Post PV": 178.5,
    "out:Primary Energy": 175.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 46.23,
    "out:Total CO2/m2": 57.72,
    "out:Total CO2 (tons)": 185.87,
    "out:Klimatpaverkan": -32.96,
    "out:Initial Cost/MSEK": 1.152,
    "out:Running cost/(Apt SEK y)": 46839,
    "out:Running Cost over RSP/MSEK": 13.189,
    "out:LCP/MSEK": 0.184,
    "out:ROI% old": 16.57,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 8.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.49,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54297,
    "out:DH kr savings": 57960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 540338,
    "out:% savings (space heating)": 4.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 174.83,
    "out:Total Energy Use Post PV": 170.5,
    "out:Primary Energy": 169.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 43.35,
    "out:Total CO2/m2": 54.84,
    "out:Total CO2 (tons)": 176.6,
    "out:Klimatpaverkan": -42.23,
    "out:Initial Cost/MSEK": 1.233,
    "out:Running cost/(Apt SEK y)": 44678,
    "out:Running Cost over RSP/MSEK": 12.581,
    "out:LCP/MSEK": 0.712,
    "out:ROI% old": 22.54,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 13.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.84,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.78,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80057,
    "out:DH kr savings": 83720,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 515406,
    "out:% savings (space heating)": 9.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 166.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 181.81,
    "out:Total Energy Use Post PV": 177.5,
    "out:Primary Energy": 173.72,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.85,
    "out:CO2 Operational/m2": 45.85,
    "out:Total CO2/m2": 57.7,
    "out:Total CO2 (tons)": 185.78,
    "out:Klimatpaverkan": -33.05,
    "out:Initial Cost/MSEK": 1.289,
    "out:Running cost/(Apt SEK y)": 46566,
    "out:Running Cost over RSP/MSEK": 13.112,
    "out:LCP/MSEK": 0.125,
    "out:ROI% old": 15.67,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 9,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 167,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.03,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.59,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57533,
    "out:DH kr savings": 61180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 536811,
    "out:% savings (space heating)": 5.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 158.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 173.81,
    "out:Total Energy Use Post PV": 169.5,
    "out:Primary Energy": 166.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.85,
    "out:CO2 Operational/m2": 42.97,
    "out:Total CO2/m2": 54.82,
    "out:Total CO2 (tons)": 176.5,
    "out:Klimatpaverkan": -42.33,
    "out:Initial Cost/MSEK": 1.369,
    "out:Running cost/(Apt SEK y)": 44405,
    "out:Running Cost over RSP/MSEK": 12.504,
    "out:LCP/MSEK": 0.653,
    "out:ROI% old": 21.09,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 14.1,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.62,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83294,
    "out:DH kr savings": 86940,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 511890,
    "out:% savings (space heating)": 9.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 180.6,
    "out:Total Energy Use Post PV": 180.6,
    "out:Primary Energy": 174.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.52,
    "out:CO2 Operational/m2": 63.28,
    "out:Total CO2/m2": 65.8,
    "out:Total CO2 (tons)": 211.87,
    "out:Klimatpaverkan": -6.96,
    "out:Initial Cost/MSEK": 0.607,
    "out:Running cost/(Apt SEK y)": 48091,
    "out:Running Cost over RSP/MSEK": 13.537,
    "out:LCP/MSEK": 0.382,
    "out:ROI% old": 23.26,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 173,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 126,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 41860,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 41860,
    "out:DH kr savings": 41860,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 173.6,
    "out:Total Energy Use Post PV": 173.6,
    "out:Primary Energy": 167.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.52,
    "out:CO2 Operational/m2": 60.76,
    "out:Total CO2/m2": 63.28,
    "out:Total CO2 (tons)": 203.76,
    "out:Klimatpaverkan": -15.07,
    "out:Initial Cost/MSEK": 0.688,
    "out:Running cost/(Apt SEK y)": 46200,
    "out:Running Cost over RSP/MSEK": 13.005,
    "out:LCP/MSEK": 0.833,
    "out:ROI% old": 31.6,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 11.5,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 28,
    "out:Return/kSEK/y": 194,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 64400,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 64400,
    "out:DH kr savings": 64400,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 179.6,
    "out:Total Energy Use Post PV": 179.6,
    "out:Primary Energy": 171.54,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.87,
    "out:CO2 Operational/m2": 62.92,
    "out:Total CO2/m2": 65.79,
    "out:Total CO2 (tons)": 211.84,
    "out:Klimatpaverkan": -6.99,
    "out:Initial Cost/MSEK": 0.744,
    "out:Running cost/(Apt SEK y)": 47821,
    "out:Running Cost over RSP/MSEK": 13.461,
    "out:LCP/MSEK": 0.321,
    "out:ROI% old": 20.45,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 180,
    "out:Energy use kWh/m2": 180,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 136,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 45080,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 45080,
    "out:DH kr savings": 45080,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 171.6,
    "out:Total Energy Use Post PV": 171.6,
    "out:Primary Energy": 165.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.87,
    "out:CO2 Operational/m2": 60.04,
    "out:Total CO2/m2": 62.91,
    "out:Total CO2 (tons)": 202.57,
    "out:Klimatpaverkan": -16.26,
    "out:Initial Cost/MSEK": 0.824,
    "out:Running cost/(Apt SEK y)": 45660,
    "out:Running Cost over RSP/MSEK": 12.852,
    "out:LCP/MSEK": 0.849,
    "out:ROI% old": 29,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 26,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 70840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 70840,
    "out:DH kr savings": 70840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 180.6,
    "out:Total Energy Use Post PV": 177.91,
    "out:Primary Energy": 169.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.75,
    "out:CO2 Operational/m2": 38.8,
    "out:Total CO2/m2": 49.56,
    "out:Total CO2 (tons)": 159.57,
    "out:Klimatpaverkan": -59.26,
    "out:Initial Cost/MSEK": 1.054,
    "out:Running cost/(Apt SEK y)": 46971,
    "out:Running Cost over RSP/MSEK": 13.23,
    "out:LCP/MSEK": 0.242,
    "out:ROI% old": 17.56,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 9,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 173,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 41860,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82176,
    "out:DH kr savings": 41860,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 173.6,
    "out:Total Energy Use Post PV": 170.91,
    "out:Primary Energy": 163.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.75,
    "out:CO2 Operational/m2": 36.28,
    "out:Total CO2/m2": 47.04,
    "out:Total CO2 (tons)": 151.45,
    "out:Klimatpaverkan": -67.38,
    "out:Initial Cost/MSEK": 1.134,
    "out:Running cost/(Apt SEK y)": 45080,
    "out:Running Cost over RSP/MSEK": 12.698,
    "out:LCP/MSEK": 0.694,
    "out:ROI% old": 23.02,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 13.5,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 64400,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104716,
    "out:DH kr savings": 64400,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 179.6,
    "out:Total Energy Use Post PV": 176.91,
    "out:Primary Energy": 166.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 38.44,
    "out:Total CO2/m2": 49.55,
    "out:Total CO2 (tons)": 159.54,
    "out:Klimatpaverkan": -59.29,
    "out:Initial Cost/MSEK": 1.19,
    "out:Running cost/(Apt SEK y)": 46701,
    "out:Running Cost over RSP/MSEK": 13.154,
    "out:LCP/MSEK": 0.181,
    "out:ROI% old": 16.46,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 9.6,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 176,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 45080,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85396,
    "out:DH kr savings": 45080,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 171.6,
    "out:Total Energy Use Post PV": 168.91,
    "out:Primary Energy": 160.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 35.56,
    "out:Total CO2/m2": 46.67,
    "out:Total CO2 (tons)": 150.26,
    "out:Klimatpaverkan": -68.57,
    "out:Initial Cost/MSEK": 1.271,
    "out:Running cost/(Apt SEK y)": 44540,
    "out:Running Cost over RSP/MSEK": 12.546,
    "out:LCP/MSEK": 0.709,
    "out:ROI% old": 22.26,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 70840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111156,
    "out:DH kr savings": 70840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 178.6,
    "out:Total Energy Use Post PV": 178.6,
    "out:Primary Energy": 170.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.52,
    "out:CO2 Operational/m2": 62.56,
    "out:Total CO2/m2": 65.08,
    "out:Total CO2 (tons)": 209.55,
    "out:Klimatpaverkan": -9.28,
    "out:Initial Cost/MSEK": 0.702,
    "out:Running cost/(Apt SEK y)": 47550,
    "out:Running Cost over RSP/MSEK": 13.385,
    "out:LCP/MSEK": 0.439,
    "out:ROI% old": 23.21,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 8.4,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 146,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 48300,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 48300,
    "out:DH kr savings": 48300,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 171.6,
    "out:Total Energy Use Post PV": 171.6,
    "out:Primary Energy": 164.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.52,
    "out:CO2 Operational/m2": 60.04,
    "out:Total CO2/m2": 62.56,
    "out:Total CO2 (tons)": 201.44,
    "out:Klimatpaverkan": -17.39,
    "out:Initial Cost/MSEK": 0.783,
    "out:Running cost/(Apt SEK y)": 45660,
    "out:Running Cost over RSP/MSEK": 12.852,
    "out:LCP/MSEK": 0.891,
    "out:ROI% old": 30.54,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 27,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 70840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 70840,
    "out:DH kr savings": 70840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 177.6,
    "out:Total Energy Use Post PV": 177.6,
    "out:Primary Energy": 168.56,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.87,
    "out:CO2 Operational/m2": 62.2,
    "out:Total CO2/m2": 65.07,
    "out:Total CO2 (tons)": 209.52,
    "out:Klimatpaverkan": -9.31,
    "out:Initial Cost/MSEK": 0.839,
    "out:Running cost/(Apt SEK y)": 47280,
    "out:Running Cost over RSP/MSEK": 13.309,
    "out:LCP/MSEK": 0.378,
    "out:ROI% old": 20.73,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 9,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 156,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51520,
    "out:DH kr savings": 51520,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 170.6,
    "out:Total Energy Use Post PV": 170.6,
    "out:Primary Energy": 162.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.87,
    "out:CO2 Operational/m2": 59.68,
    "out:Total CO2/m2": 62.55,
    "out:Total CO2 (tons)": 201.41,
    "out:Klimatpaverkan": -17.42,
    "out:Initial Cost/MSEK": 0.919,
    "out:Running cost/(Apt SEK y)": 45390,
    "out:Running Cost over RSP/MSEK": 12.776,
    "out:LCP/MSEK": 0.83,
    "out:ROI% old": 27.18,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 13.5,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 24,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 74060,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74060,
    "out:DH kr savings": 74060,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 178.6,
    "out:Total Energy Use Post PV": 175.91,
    "out:Primary Energy": 165.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.75,
    "out:CO2 Operational/m2": 38.08,
    "out:Total CO2/m2": 48.84,
    "out:Total CO2 (tons)": 157.25,
    "out:Klimatpaverkan": -61.58,
    "out:Initial Cost/MSEK": 1.149,
    "out:Running cost/(Apt SEK y)": 46431,
    "out:Running Cost over RSP/MSEK": 13.078,
    "out:LCP/MSEK": 0.299,
    "out:ROI% old": 18,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 48300,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88616,
    "out:DH kr savings": 48300,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 171.6,
    "out:Total Energy Use Post PV": 168.91,
    "out:Primary Energy": 159.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.75,
    "out:CO2 Operational/m2": 35.56,
    "out:Total CO2/m2": 46.32,
    "out:Total CO2 (tons)": 149.14,
    "out:Klimatpaverkan": -69.69,
    "out:Initial Cost/MSEK": 1.229,
    "out:Running cost/(Apt SEK y)": 44540,
    "out:Running Cost over RSP/MSEK": 12.546,
    "out:LCP/MSEK": 0.751,
    "out:ROI% old": 23.01,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 70840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111156,
    "out:DH kr savings": 70840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 177.6,
    "out:Total Energy Use Post PV": 174.91,
    "out:Primary Energy": 163.73,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 37.72,
    "out:Total CO2/m2": 48.83,
    "out:Total CO2 (tons)": 157.22,
    "out:Klimatpaverkan": -61.61,
    "out:Initial Cost/MSEK": 1.285,
    "out:Running cost/(Apt SEK y)": 46160,
    "out:Running Cost over RSP/MSEK": 13.002,
    "out:LCP/MSEK": 0.238,
    "out:ROI% old": 16.94,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 196,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91836,
    "out:DH kr savings": 51520,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 170.6,
    "out:Total Energy Use Post PV": 167.91,
    "out:Primary Energy": 157.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 35.2,
    "out:Total CO2/m2": 46.31,
    "out:Total CO2 (tons)": 149.1,
    "out:Klimatpaverkan": -69.73,
    "out:Initial Cost/MSEK": 1.366,
    "out:Running cost/(Apt SEK y)": 44270,
    "out:Running Cost over RSP/MSEK": 12.47,
    "out:LCP/MSEK": 0.69,
    "out:ROI% old": 21.51,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 74060,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114376,
    "out:DH kr savings": 74060,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 171.84,
    "out:Total Energy Use Post PV": 171.85,
    "out:Primary Energy": 175.78,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.78,
    "out:CO2 Operational/m2": 58.48,
    "out:Total CO2/m2": 64.26,
    "out:Total CO2 (tons)": 206.91,
    "out:Klimatpaverkan": -11.92,
    "out:Initial Cost/MSEK": 1.218,
    "out:Running cost/(Apt SEK y)": 45064,
    "out:Running Cost over RSP/MSEK": 12.681,
    "out:LCP/MSEK": 0.627,
    "out:ROI% old": 21.64,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46717,
    "out:DH kr savings": 93380,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 504574,
    "out:% savings (space heating)": 11.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 164.84,
    "out:Total Energy Use Post PV": 164.85,
    "out:Primary Energy": 169.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.78,
    "out:CO2 Operational/m2": 55.96,
    "out:Total CO2/m2": 61.74,
    "out:Total CO2 (tons)": 198.8,
    "out:Klimatpaverkan": -20.03,
    "out:Initial Cost/MSEK": 1.298,
    "out:Running cost/(Apt SEK y)": 43173,
    "out:Running Cost over RSP/MSEK": 12.148,
    "out:LCP/MSEK": 1.079,
    "out:ROI% old": 26.16,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69257,
    "out:DH kr savings": 115920,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481535,
    "out:% savings (space heating)": 15.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 155.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 169.83,
    "out:Total Energy Use Post PV": 169.85,
    "out:Primary Energy": 173.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.13,
    "out:CO2 Operational/m2": 57.76,
    "out:Total CO2/m2": 63.89,
    "out:Total CO2 (tons)": 205.72,
    "out:Klimatpaverkan": -13.11,
    "out:Initial Cost/MSEK": 1.355,
    "out:Running cost/(Apt SEK y)": 44522,
    "out:Running Cost over RSP/MSEK": 12.528,
    "out:LCP/MSEK": 0.643,
    "out:ROI% old": 21.07,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 14.1,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99820,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53157,
    "out:DH kr savings": 99820,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 500058,
    "out:% savings (space heating)": 11.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 148.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 162.83,
    "out:Total Energy Use Post PV": 162.85,
    "out:Primary Energy": 166.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.13,
    "out:CO2 Operational/m2": 55.24,
    "out:Total CO2/m2": 61.37,
    "out:Total CO2 (tons)": 197.6,
    "out:Klimatpaverkan": -21.23,
    "out:Initial Cost/MSEK": 1.435,
    "out:Running cost/(Apt SEK y)": 42631,
    "out:Running Cost over RSP/MSEK": 11.995,
    "out:LCP/MSEK": 1.095,
    "out:ROI% old": 25.19,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 75697,
    "out:DH kr savings": 122360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 477023,
    "out:% savings (space heating)": 16.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 171.84,
    "out:Total Energy Use Post PV": 167.5,
    "out:Primary Energy": 167.96,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.02,
    "out:CO2 Operational/m2": 42.28,
    "out:Total CO2/m2": 56.3,
    "out:Total CO2 (tons)": 181.29,
    "out:Klimatpaverkan": -37.54,
    "out:Initial Cost/MSEK": 1.665,
    "out:Running cost/(Apt SEK y)": 43870,
    "out:Running Cost over RSP/MSEK": 12.353,
    "out:LCP/MSEK": 0.508,
    "out:ROI% old": 18.64,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.48,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89702,
    "out:DH kr savings": 93380,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 504574,
    "out:% savings (space heating)": 11.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 164.84,
    "out:Total Energy Use Post PV": 160.5,
    "out:Primary Energy": 161.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.02,
    "out:CO2 Operational/m2": 39.76,
    "out:Total CO2/m2": 53.78,
    "out:Total CO2 (tons)": 173.18,
    "out:Klimatpaverkan": -45.65,
    "out:Initial Cost/MSEK": 1.745,
    "out:Running cost/(Apt SEK y)": 41980,
    "out:Running Cost over RSP/MSEK": 11.821,
    "out:LCP/MSEK": 0.96,
    "out:ROI% old": 22.14,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.5,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112242,
    "out:DH kr savings": 115920,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481535,
    "out:% savings (space heating)": 15.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 155.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 169.83,
    "out:Total Energy Use Post PV": 165.5,
    "out:Primary Energy": 165.34,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 41.55,
    "out:Total CO2/m2": 55.92,
    "out:Total CO2 (tons)": 180.06,
    "out:Klimatpaverkan": -38.77,
    "out:Initial Cost/MSEK": 1.801,
    "out:Running cost/(Apt SEK y)": 43328,
    "out:Running Cost over RSP/MSEK": 12.201,
    "out:LCP/MSEK": 0.524,
    "out:ROI% old": 18.44,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.49,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99820,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96152,
    "out:DH kr savings": 99820,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 500058,
    "out:% savings (space heating)": 11.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 148.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 162.83,
    "out:Total Energy Use Post PV": 158.5,
    "out:Primary Energy": 159.08,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 39.03,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 171.94,
    "out:Klimatpaverkan": -46.89,
    "out:Initial Cost/MSEK": 1.882,
    "out:Running cost/(Apt SEK y)": 41437,
    "out:Running Cost over RSP/MSEK": 11.668,
    "out:LCP/MSEK": 0.976,
    "out:ROI% old": 21.69,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 366,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.7,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13972.92,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 122360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118693,
    "out:DH kr savings": 122360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 477023,
    "out:% savings (space heating)": 16.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 154.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 169.83,
    "out:Total Energy Use Post PV": 169.85,
    "out:Primary Energy": 172.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.78,
    "out:CO2 Operational/m2": 57.75,
    "out:Total CO2/m2": 63.54,
    "out:Total CO2 (tons)": 204.58,
    "out:Klimatpaverkan": -14.25,
    "out:Initial Cost/MSEK": 1.313,
    "out:Running cost/(Apt SEK y)": 44521,
    "out:Running Cost over RSP/MSEK": 12.528,
    "out:LCP/MSEK": 0.685,
    "out:ROI% old": 21.74,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 14.1,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99820,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53157,
    "out:DH kr savings": 99820,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 498570,
    "out:% savings (space heating)": 12.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 147.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 162.82,
    "out:Total Energy Use Post PV": 162.85,
    "out:Primary Energy": 166.02,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.78,
    "out:CO2 Operational/m2": 55.23,
    "out:Total CO2/m2": 61.02,
    "out:Total CO2 (tons)": 196.47,
    "out:Klimatpaverkan": -22.36,
    "out:Initial Cost/MSEK": 1.394,
    "out:Running cost/(Apt SEK y)": 42630,
    "out:Running Cost over RSP/MSEK": 11.995,
    "out:LCP/MSEK": 1.137,
    "out:ROI% old": 25.94,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 23,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 75697,
    "out:DH kr savings": 122360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 475538,
    "out:% savings (space heating)": 16.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 168.81,
    "out:Total Energy Use Post PV": 168.85,
    "out:Primary Energy": 170.18,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.13,
    "out:CO2 Operational/m2": 57.39,
    "out:Total CO2/m2": 63.52,
    "out:Total CO2 (tons)": 204.55,
    "out:Klimatpaverkan": -14.28,
    "out:Initial Cost/MSEK": 1.45,
    "out:Running cost/(Apt SEK y)": 44248,
    "out:Running Cost over RSP/MSEK": 12.451,
    "out:LCP/MSEK": 0.626,
    "out:ROI% old": 20.45,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 103040,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56377,
    "out:DH kr savings": 103040,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 495050,
    "out:% savings (space heating)": 12.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 146.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 161.81,
    "out:Total Energy Use Post PV": 161.85,
    "out:Primary Energy": 163.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.13,
    "out:CO2 Operational/m2": 54.87,
    "out:Total CO2/m2": 61,
    "out:Total CO2 (tons)": 196.43,
    "out:Klimatpaverkan": -22.4,
    "out:Initial Cost/MSEK": 1.53,
    "out:Running cost/(Apt SEK y)": 42357,
    "out:Running Cost over RSP/MSEK": 11.918,
    "out:LCP/MSEK": 1.078,
    "out:ROI% old": 24.35,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1036.4,
    "out:Return %": 22,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 125580,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78917,
    "out:DH kr savings": 125580,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 472028,
    "out:% savings (space heating)": 16.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 154.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 169.83,
    "out:Total Energy Use Post PV": 165.5,
    "out:Primary Energy": 164.46,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.02,
    "out:CO2 Operational/m2": 41.55,
    "out:Total CO2/m2": 55.56,
    "out:Total CO2 (tons)": 178.91,
    "out:Klimatpaverkan": -39.92,
    "out:Initial Cost/MSEK": 1.76,
    "out:Running cost/(Apt SEK y)": 43327,
    "out:Running Cost over RSP/MSEK": 12.201,
    "out:LCP/MSEK": 0.566,
    "out:ROI% old": 18.88,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.82,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.79,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99820,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96157,
    "out:DH kr savings": 99820,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 498570,
    "out:% savings (space heating)": 12.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 147.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 162.82,
    "out:Total Energy Use Post PV": 158.5,
    "out:Primary Energy": 158.2,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.02,
    "out:CO2 Operational/m2": 39.03,
    "out:Total CO2/m2": 53.04,
    "out:Total CO2 (tons)": 170.8,
    "out:Klimatpaverkan": -48.03,
    "out:Initial Cost/MSEK": 1.84,
    "out:Running cost/(Apt SEK y)": 41436,
    "out:Running Cost over RSP/MSEK": 11.668,
    "out:LCP/MSEK": 1.018,
    "out:ROI% old": 22.19,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 366,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.18,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.44,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 122360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118698,
    "out:DH kr savings": 122360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 475538,
    "out:% savings (space heating)": 16.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 168.81,
    "out:Total Energy Use Post PV": 164.5,
    "out:Primary Energy": 162.36,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 41.17,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 178.82,
    "out:Klimatpaverkan": -40.01,
    "out:Initial Cost/MSEK": 1.896,
    "out:Running cost/(Apt SEK y)": 43054,
    "out:Running Cost over RSP/MSEK": 12.124,
    "out:LCP/MSEK": 0.506,
    "out:ROI% old": 18.1,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.73,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.89,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 103040,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99394,
    "out:DH kr savings": 103040,
    "out:El kr savings": -18698,
    "out:El kr sold": 15052,
    "out:El kr saved": 27918,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 495050,
    "out:% savings (space heating)": 12.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 146.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 161.81,
    "out:Total Energy Use Post PV": 157.5,
    "out:Primary Energy": 156.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 38.65,
    "out:Total CO2/m2": 53.01,
    "out:Total CO2 (tons)": 170.7,
    "out:Klimatpaverkan": -48.13,
    "out:Initial Cost/MSEK": 1.977,
    "out:Running cost/(Apt SEK y)": 41163,
    "out:Running Cost over RSP/MSEK": 11.591,
    "out:LCP/MSEK": 0.958,
    "out:ROI% old": 21.21,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.74,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.87,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 125580,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121935,
    "out:DH kr savings": 125580,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27916,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 472028,
    "out:% savings (space heating)": 16.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 177.6,
    "out:Total Energy Use Post PV": 177.6,
    "out:Primary Energy": 171.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 62.2,
    "out:Total CO2/m2": 71.62,
    "out:Total CO2 (tons)": 230.63,
    "out:Klimatpaverkan": 11.8,
    "out:Initial Cost/MSEK": 2.094,
    "out:Running cost/(Apt SEK y)": 47280,
    "out:Running Cost over RSP/MSEK": 13.309,
    "out:LCP/MSEK": -0.877,
    "out:ROI% old": 8.3,
    "out:Payback discounted": 15,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 9,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 7,
    "out:Return/kSEK/y": 156,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51520,
    "out:DH kr savings": 51520,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 170.6,
    "out:Total Energy Use Post PV": 170.6,
    "out:Primary Energy": 165.43,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 59.68,
    "out:Total CO2/m2": 69.11,
    "out:Total CO2 (tons)": 222.52,
    "out:Klimatpaverkan": 3.69,
    "out:Initial Cost/MSEK": 2.175,
    "out:Running cost/(Apt SEK y)": 45390,
    "out:Running Cost over RSP/MSEK": 12.776,
    "out:LCP/MSEK": -0.425,
    "out:ROI% old": 11.49,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 13.5,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 10,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 74060,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74060,
    "out:DH kr savings": 74060,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 176.6,
    "out:Total Energy Use Post PV": 176.6,
    "out:Primary Energy": 168.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 61.84,
    "out:Total CO2/m2": 71.62,
    "out:Total CO2 (tons)": 230.6,
    "out:Klimatpaverkan": 11.77,
    "out:Initial Cost/MSEK": 2.231,
    "out:Running cost/(Apt SEK y)": 47010,
    "out:Running Cost over RSP/MSEK": 13.233,
    "out:LCP/MSEK": -0.938,
    "out:ROI% old": 8.28,
    "out:Payback discounted": 15,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 9.6,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 169,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 7,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 54740,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54740,
    "out:DH kr savings": 54740,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 169.6,
    "out:Total Energy Use Post PV": 169.6,
    "out:Primary Energy": 162.81,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 59.32,
    "out:Total CO2/m2": 69.1,
    "out:Total CO2 (tons)": 222.49,
    "out:Klimatpaverkan": 3.66,
    "out:Initial Cost/MSEK": 2.311,
    "out:Running cost/(Apt SEK y)": 45119,
    "out:Running Cost over RSP/MSEK": 12.7,
    "out:LCP/MSEK": -0.486,
    "out:ROI% old": 11.28,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 14.1,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 10,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77280,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77280,
    "out:DH kr savings": 77280,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 177.6,
    "out:Total Energy Use Post PV": 174.91,
    "out:Primary Energy": 166.74,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.66,
    "out:CO2 Operational/m2": 37.72,
    "out:Total CO2/m2": 55.38,
    "out:Total CO2 (tons)": 178.33,
    "out:Klimatpaverkan": -40.5,
    "out:Initial Cost/MSEK": 2.541,
    "out:Running cost/(Apt SEK y)": 46160,
    "out:Running Cost over RSP/MSEK": 13.002,
    "out:LCP/MSEK": -1.017,
    "out:ROI% old": 8.57,
    "out:Payback discounted": 14,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 196,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91836,
    "out:DH kr savings": 51520,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 170.6,
    "out:Total Energy Use Post PV": 167.91,
    "out:Primary Energy": 160.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.66,
    "out:CO2 Operational/m2": 35.2,
    "out:Total CO2/m2": 52.86,
    "out:Total CO2 (tons)": 170.21,
    "out:Klimatpaverkan": -48.62,
    "out:Initial Cost/MSEK": 2.621,
    "out:Running cost/(Apt SEK y)": 44270,
    "out:Running Cost over RSP/MSEK": 12.47,
    "out:LCP/MSEK": -0.565,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 74060,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114376,
    "out:DH kr savings": 74060,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 176.6,
    "out:Total Energy Use Post PV": 173.91,
    "out:Primary Energy": 164.11,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.01,
    "out:CO2 Operational/m2": 37.36,
    "out:Total CO2/m2": 55.37,
    "out:Total CO2 (tons)": 178.3,
    "out:Klimatpaverkan": -40.53,
    "out:Initial Cost/MSEK": 2.677,
    "out:Running cost/(Apt SEK y)": 45890,
    "out:Running Cost over RSP/MSEK": 12.926,
    "out:LCP/MSEK": -1.077,
    "out:ROI% old": 8.54,
    "out:Payback discounted": 14,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 11.5,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 169,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 54740,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95056,
    "out:DH kr savings": 54740,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 169.6,
    "out:Total Energy Use Post PV": 166.91,
    "out:Primary Energy": 157.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.01,
    "out:CO2 Operational/m2": 34.84,
    "out:Total CO2/m2": 52.85,
    "out:Total CO2 (tons)": 170.18,
    "out:Klimatpaverkan": -48.65,
    "out:Initial Cost/MSEK": 2.758,
    "out:Running cost/(Apt SEK y)": 44000,
    "out:Running Cost over RSP/MSEK": 12.394,
    "out:LCP/MSEK": -0.625,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77280,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117596,
    "out:DH kr savings": 77280,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 175.6,
    "out:Total Energy Use Post PV": 175.6,
    "out:Primary Energy": 168.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 61.48,
    "out:Total CO2/m2": 70.9,
    "out:Total CO2 (tons)": 228.31,
    "out:Klimatpaverkan": 9.48,
    "out:Initial Cost/MSEK": 2.189,
    "out:Running cost/(Apt SEK y)": 46740,
    "out:Running Cost over RSP/MSEK": 13.157,
    "out:LCP/MSEK": -0.82,
    "out:ROI% old": 8.93,
    "out:Payback discounted": 13,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 8,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57960,
    "out:DH kr savings": 57960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 168.6,
    "out:Total Energy Use Post PV": 168.6,
    "out:Primary Energy": 161.93,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 58.96,
    "out:Total CO2/m2": 68.39,
    "out:Total CO2 (tons)": 220.2,
    "out:Klimatpaverkan": 1.37,
    "out:Initial Cost/MSEK": 2.27,
    "out:Running cost/(Apt SEK y)": 44849,
    "out:Running Cost over RSP/MSEK": 12.624,
    "out:LCP/MSEK": -0.368,
    "out:ROI% old": 11.97,
    "out:Payback discounted": 10,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 11,
    "out:Return/kSEK/y": 243,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80500,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80500,
    "out:DH kr savings": 80500,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 174.6,
    "out:Total Energy Use Post PV": 174.6,
    "out:Primary Energy": 165.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 61.12,
    "out:Total CO2/m2": 70.9,
    "out:Total CO2 (tons)": 228.28,
    "out:Klimatpaverkan": 9.45,
    "out:Initial Cost/MSEK": 2.326,
    "out:Running cost/(Apt SEK y)": 46470,
    "out:Running Cost over RSP/MSEK": 13.081,
    "out:LCP/MSEK": -0.881,
    "out:ROI% old": 8.88,
    "out:Payback discounted": 14,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 167,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 8,
    "out:Return/kSEK/y": 185,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 61180,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61180,
    "out:DH kr savings": 61180,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 167.6,
    "out:Total Energy Use Post PV": 167.6,
    "out:Primary Energy": 159.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 58.6,
    "out:Total CO2/m2": 68.38,
    "out:Total CO2 (tons)": 220.17,
    "out:Klimatpaverkan": 1.34,
    "out:Initial Cost/MSEK": 2.406,
    "out:Running cost/(Apt SEK y)": 44579,
    "out:Running Cost over RSP/MSEK": 12.548,
    "out:LCP/MSEK": -0.429,
    "out:ROI% old": 11.74,
    "out:Payback discounted": 10,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 11,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83720,
    "out:DH kr savings": 83720,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 175.6,
    "out:Total Energy Use Post PV": 172.91,
    "out:Primary Energy": 163.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.66,
    "out:CO2 Operational/m2": 37,
    "out:Total CO2/m2": 54.66,
    "out:Total CO2 (tons)": 176.01,
    "out:Klimatpaverkan": -42.82,
    "out:Initial Cost/MSEK": 2.636,
    "out:Running cost/(Apt SEK y)": 45620,
    "out:Running Cost over RSP/MSEK": 12.85,
    "out:LCP/MSEK": -0.96,
    "out:ROI% old": 9.08,
    "out:Payback discounted": 13,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 12.1,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98276,
    "out:DH kr savings": 57960,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 168.6,
    "out:Total Energy Use Post PV": 165.91,
    "out:Primary Energy": 157.09,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.66,
    "out:CO2 Operational/m2": 34.48,
    "out:Total CO2/m2": 52.14,
    "out:Total CO2 (tons)": 167.9,
    "out:Klimatpaverkan": -50.93,
    "out:Initial Cost/MSEK": 2.716,
    "out:Running cost/(Apt SEK y)": 43729,
    "out:Running Cost over RSP/MSEK": 12.318,
    "out:LCP/MSEK": -0.508,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 283,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80500,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120816,
    "out:DH kr savings": 80500,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 174.6,
    "out:Total Energy Use Post PV": 171.91,
    "out:Primary Energy": 161.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.01,
    "out:CO2 Operational/m2": 36.64,
    "out:Total CO2/m2": 54.65,
    "out:Total CO2 (tons)": 175.98,
    "out:Klimatpaverkan": -42.85,
    "out:Initial Cost/MSEK": 2.772,
    "out:Running cost/(Apt SEK y)": 45350,
    "out:Running Cost over RSP/MSEK": 12.774,
    "out:LCP/MSEK": -1.02,
    "out:ROI% old": 9.03,
    "out:Payback discounted": 13,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 167,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 225,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61180,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101496,
    "out:DH kr savings": 61180,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 167.6,
    "out:Total Energy Use Post PV": 164.91,
    "out:Primary Energy": 154.99,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.01,
    "out:CO2 Operational/m2": 34.12,
    "out:Total CO2/m2": 52.13,
    "out:Total CO2 (tons)": 167.87,
    "out:Klimatpaverkan": -50.96,
    "out:Initial Cost/MSEK": 2.853,
    "out:Running cost/(Apt SEK y)": 43459,
    "out:Running Cost over RSP/MSEK": 12.241,
    "out:LCP/MSEK": -0.568,
    "out:ROI% old": 11.44,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124036,
    "out:DH kr savings": 83720,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 168.84,
    "out:Total Energy Use Post PV": 168.85,
    "out:Primary Energy": 173.19,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.69,
    "out:CO2 Operational/m2": 57.4,
    "out:Total CO2/m2": 70.08,
    "out:Total CO2 (tons)": 225.67,
    "out:Klimatpaverkan": 6.84,
    "out:Initial Cost/MSEK": 2.705,
    "out:Running cost/(Apt SEK y)": 44254,
    "out:Running Cost over RSP/MSEK": 12.452,
    "out:LCP/MSEK": -0.631,
    "out:ROI% old": 10.95,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 10,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 103040,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56377,
    "out:DH kr savings": 103040,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 495039,
    "out:% savings (space heating)": 12.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 146.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 161.84,
    "out:Total Energy Use Post PV": 161.85,
    "out:Primary Energy": 167.05,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.69,
    "out:CO2 Operational/m2": 54.88,
    "out:Total CO2/m2": 67.56,
    "out:Total CO2 (tons)": 217.56,
    "out:Klimatpaverkan": -1.27,
    "out:Initial Cost/MSEK": 2.785,
    "out:Running cost/(Apt SEK y)": 42363,
    "out:Running Cost over RSP/MSEK": 11.92,
    "out:LCP/MSEK": -0.179,
    "out:ROI% old": 13.37,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 12,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 125580,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78917,
    "out:DH kr savings": 125580,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 472434,
    "out:% savings (space heating)": 16.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 152.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 166.83,
    "out:Total Energy Use Post PV": 166.85,
    "out:Primary Energy": 170.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.04,
    "out:CO2 Operational/m2": 56.68,
    "out:Total CO2/m2": 69.71,
    "out:Total CO2 (tons)": 224.48,
    "out:Klimatpaverkan": 5.65,
    "out:Initial Cost/MSEK": 2.841,
    "out:Running cost/(Apt SEK y)": 43712,
    "out:Running Cost over RSP/MSEK": 12.3,
    "out:LCP/MSEK": -0.615,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 10,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62817,
    "out:DH kr savings": 109480,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 490520,
    "out:% savings (space heating)": 13.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 145.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 159.83,
    "out:Total Energy Use Post PV": 159.85,
    "out:Primary Energy": 164.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.04,
    "out:CO2 Operational/m2": 54.16,
    "out:Total CO2/m2": 67.19,
    "out:Total CO2 (tons)": 216.36,
    "out:Klimatpaverkan": -2.47,
    "out:Initial Cost/MSEK": 2.922,
    "out:Running cost/(Apt SEK y)": 41821,
    "out:Running Cost over RSP/MSEK": 11.767,
    "out:LCP/MSEK": -0.163,
    "out:ROI% old": 13.49,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85357,
    "out:DH kr savings": 132020,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 467918,
    "out:% savings (space heating)": 17.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 168.84,
    "out:Total Energy Use Post PV": 164.5,
    "out:Primary Energy": 165.37,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 41.2,
    "out:Total CO2/m2": 62.13,
    "out:Total CO2 (tons)": 200.05,
    "out:Klimatpaverkan": -18.78,
    "out:Initial Cost/MSEK": 3.151,
    "out:Running cost/(Apt SEK y)": 43060,
    "out:Running Cost over RSP/MSEK": 12.125,
    "out:LCP/MSEK": -0.751,
    "out:ROI% old": 10.88,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.41,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103040,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99362,
    "out:DH kr savings": 103040,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 495039,
    "out:% savings (space heating)": 12.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 146.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 161.84,
    "out:Total Energy Use Post PV": 157.5,
    "out:Primary Energy": 159.23,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 38.68,
    "out:Total CO2/m2": 59.61,
    "out:Total CO2 (tons)": 191.94,
    "out:Klimatpaverkan": -26.89,
    "out:Initial Cost/MSEK": 3.232,
    "out:Running cost/(Apt SEK y)": 41169,
    "out:Running Cost over RSP/MSEK": 11.593,
    "out:LCP/MSEK": -0.299,
    "out:ROI% old": 12.97,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.42,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 125580,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121902,
    "out:DH kr savings": 125580,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 472434,
    "out:% savings (space heating)": 16.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 152.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 166.83,
    "out:Total Energy Use Post PV": 162.5,
    "out:Primary Energy": 162.75,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.27,
    "out:CO2 Operational/m2": 40.47,
    "out:Total CO2/m2": 61.75,
    "out:Total CO2 (tons)": 198.82,
    "out:Klimatpaverkan": -20.01,
    "out:Initial Cost/MSEK": 3.288,
    "out:Running cost/(Apt SEK y)": 42518,
    "out:Running Cost over RSP/MSEK": 11.973,
    "out:LCP/MSEK": -0.735,
    "out:ROI% old": 11.09,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 327,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.2,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.42,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105812,
    "out:DH kr savings": 109480,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 490520,
    "out:% savings (space heating)": 13.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 145.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 159.83,
    "out:Total Energy Use Post PV": 155.5,
    "out:Primary Energy": 156.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.27,
    "out:CO2 Operational/m2": 37.95,
    "out:Total CO2/m2": 59.23,
    "out:Total CO2 (tons)": 190.71,
    "out:Klimatpaverkan": -28.12,
    "out:Initial Cost/MSEK": 3.368,
    "out:Running cost/(Apt SEK y)": 40627,
    "out:Running Cost over RSP/MSEK": 11.44,
    "out:LCP/MSEK": -0.283,
    "out:ROI% old": 13.09,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.41,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128352,
    "out:DH kr savings": 132020,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 467918,
    "out:% savings (space heating)": 17.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 151.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 166.83,
    "out:Total Energy Use Post PV": 166.85,
    "out:Primary Energy": 169.69,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.69,
    "out:CO2 Operational/m2": 56.67,
    "out:Total CO2/m2": 69.36,
    "out:Total CO2 (tons)": 223.35,
    "out:Klimatpaverkan": 4.52,
    "out:Initial Cost/MSEK": 2.8,
    "out:Running cost/(Apt SEK y)": 43711,
    "out:Running Cost over RSP/MSEK": 12.3,
    "out:LCP/MSEK": -0.573,
    "out:ROI% old": 11.36,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 10,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62817,
    "out:DH kr savings": 109480,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 489030,
    "out:% savings (space heating)": 13.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 144.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 159.83,
    "out:Total Energy Use Post PV": 159.85,
    "out:Primary Energy": 163.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.69,
    "out:CO2 Operational/m2": 54.15,
    "out:Total CO2/m2": 66.84,
    "out:Total CO2 (tons)": 215.23,
    "out:Klimatpaverkan": -3.6,
    "out:Initial Cost/MSEK": 2.88,
    "out:Running cost/(Apt SEK y)": 41820,
    "out:Running Cost over RSP/MSEK": 11.767,
    "out:LCP/MSEK": -0.121,
    "out:ROI% old": 13.68,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85357,
    "out:DH kr savings": 132020,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 17.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 165.81,
    "out:Total Energy Use Post PV": 165.85,
    "out:Primary Energy": 167.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.04,
    "out:CO2 Operational/m2": 56.31,
    "out:Total CO2/m2": 69.35,
    "out:Total CO2 (tons)": 223.31,
    "out:Klimatpaverkan": 4.48,
    "out:Initial Cost/MSEK": 2.936,
    "out:Running cost/(Apt SEK y)": 43438,
    "out:Running Cost over RSP/MSEK": 12.223,
    "out:LCP/MSEK": -0.633,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 10,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66037,
    "out:DH kr savings": 112700,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 485505,
    "out:% savings (space heating)": 14.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 158.81,
    "out:Total Energy Use Post PV": 158.85,
    "out:Primary Energy": 161.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.04,
    "out:CO2 Operational/m2": 53.79,
    "out:Total CO2/m2": 66.83,
    "out:Total CO2 (tons)": 215.19,
    "out:Klimatpaverkan": -3.64,
    "out:Initial Cost/MSEK": 3.017,
    "out:Running cost/(Apt SEK y)": 41546,
    "out:Running Cost over RSP/MSEK": 11.69,
    "out:LCP/MSEK": -0.181,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88577,
    "out:DH kr savings": 135240,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462916,
    "out:% savings (space heating)": 18.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 151.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 166.83,
    "out:Total Energy Use Post PV": 162.5,
    "out:Primary Energy": 161.87,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 40.47,
    "out:Total CO2/m2": 61.39,
    "out:Total CO2 (tons)": 197.67,
    "out:Klimatpaverkan": -21.16,
    "out:Initial Cost/MSEK": 3.246,
    "out:Running cost/(Apt SEK y)": 42517,
    "out:Running Cost over RSP/MSEK": 11.972,
    "out:LCP/MSEK": -0.693,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 327,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.44,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.18,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105817,
    "out:DH kr savings": 109480,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 489030,
    "out:% savings (space heating)": 13.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 144.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 159.83,
    "out:Total Energy Use Post PV": 155.5,
    "out:Primary Energy": 155.73,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 37.95,
    "out:Total CO2/m2": 58.87,
    "out:Total CO2 (tons)": 189.56,
    "out:Klimatpaverkan": -29.27,
    "out:Initial Cost/MSEK": 3.327,
    "out:Running cost/(Apt SEK y)": 40626,
    "out:Running Cost over RSP/MSEK": 11.44,
    "out:LCP/MSEK": -0.241,
    "out:ROI% old": 13.25,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.79,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.82,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128357,
    "out:DH kr savings": 132020,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 17.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 165.81,
    "out:Total Energy Use Post PV": 161.5,
    "out:Primary Energy": 159.77,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.27,
    "out:CO2 Operational/m2": 40.09,
    "out:Total CO2/m2": 61.36,
    "out:Total CO2 (tons)": 197.58,
    "out:Klimatpaverkan": -21.25,
    "out:Initial Cost/MSEK": 3.383,
    "out:Running cost/(Apt SEK y)": 42244,
    "out:Running Cost over RSP/MSEK": 11.895,
    "out:LCP/MSEK": -0.753,
    "out:ROI% old": 11.11,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109053,
    "out:DH kr savings": 112700,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 485505,
    "out:% savings (space heating)": 14.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 158.81,
    "out:Total Energy Use Post PV": 154.5,
    "out:Primary Energy": 153.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.27,
    "out:CO2 Operational/m2": 37.57,
    "out:Total CO2/m2": 58.84,
    "out:Total CO2 (tons)": 189.46,
    "out:Klimatpaverkan": -29.37,
    "out:Initial Cost/MSEK": 3.463,
    "out:Running cost/(Apt SEK y)": 40353,
    "out:Running Cost over RSP/MSEK": 11.363,
    "out:LCP/MSEK": -0.3,
    "out:ROI% old": 13.05,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131594,
    "out:DH kr savings": 135240,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462916,
    "out:% savings (space heating)": 18.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.18,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 167.6,
    "out:Total Energy Use Post PV": 167.6,
    "out:Primary Energy": 162.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.53,
    "out:CO2 Operational/m2": 58.6,
    "out:Total CO2/m2": 60.13,
    "out:Total CO2 (tons)": 193.62,
    "out:Klimatpaverkan": -25.21,
    "out:Initial Cost/MSEK": 0.145,
    "out:Running cost/(Apt SEK y)": 44579,
    "out:Running Cost over RSP/MSEK": 12.548,
    "out:LCP/MSEK": 1.832,
    "out:ROI% old": 194.38,
    "out:Payback discounted": 1,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 174,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 58,
    "out:Payback Time": 1.724138,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83720,
    "out:DH kr savings": 83720,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 483595,
    "out:% savings (space heating)": 14.85,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.63,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 160.6,
    "out:Total Energy Use Post PV": 160.6,
    "out:Primary Energy": 156.86,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.53,
    "out:CO2 Operational/m2": 56.08,
    "out:Total CO2/m2": 57.61,
    "out:Total CO2 (tons)": 185.51,
    "out:Klimatpaverkan": -33.32,
    "out:Initial Cost/MSEK": 0.226,
    "out:Running cost/(Apt SEK y)": 42688,
    "out:Running Cost over RSP/MSEK": 12.016,
    "out:LCP/MSEK": 2.284,
    "out:ROI% old": 158.77,
    "out:Payback discounted": 1,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 142,
    "out:Return/kSEK/y": 321,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 47,
    "out:Payback Time": 2.12766,
    "out:DH kWh savings": 106260,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106260,
    "out:DH kr savings": 106260,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462491,
    "out:% savings (space heating)": 18.57,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.18,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 165.6,
    "out:Primary Energy": 159.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.88,
    "out:CO2 Operational/m2": 57.88,
    "out:Total CO2/m2": 59.76,
    "out:Total CO2 (tons)": 192.43,
    "out:Klimatpaverkan": -26.4,
    "out:Initial Cost/MSEK": 0.282,
    "out:Running cost/(Apt SEK y)": 44039,
    "out:Running Cost over RSP/MSEK": 12.396,
    "out:LCP/MSEK": 1.848,
    "out:ROI% old": 107.91,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 97,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 32,
    "out:Payback Time": 3.125,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90160,
    "out:DH kr savings": 90160,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 483595,
    "out:% savings (space heating)": 14.85,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.63,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 159.6,
    "out:Total Energy Use Post PV": 159.6,
    "out:Primary Energy": 154.23,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.88,
    "out:CO2 Operational/m2": 55.72,
    "out:Total CO2/m2": 57.6,
    "out:Total CO2 (tons)": 185.48,
    "out:Klimatpaverkan": -33.35,
    "out:Initial Cost/MSEK": 0.362,
    "out:Running cost/(Apt SEK y)": 42418,
    "out:Running Cost over RSP/MSEK": 11.94,
    "out:LCP/MSEK": 2.224,
    "out:ROI% old": 101.93,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 91,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 30,
    "out:Payback Time": 3.333333,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109480,
    "out:DH kr savings": 109480,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462491,
    "out:% savings (space heating)": 18.57,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.18,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 167.6,
    "out:Total Energy Use Post PV": 164.91,
    "out:Primary Energy": 157.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 34.12,
    "out:Total CO2/m2": 43.89,
    "out:Total CO2 (tons)": 141.32,
    "out:Klimatpaverkan": -77.51,
    "out:Initial Cost/MSEK": 0.592,
    "out:Running cost/(Apt SEK y)": 43459,
    "out:Running Cost over RSP/MSEK": 12.241,
    "out:LCP/MSEK": 1.693,
    "out:ROI% old": 55.14,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 50,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124036,
    "out:DH kr savings": 83720,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 483595,
    "out:% savings (space heating)": 14.85,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.63,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 160.6,
    "out:Total Energy Use Post PV": 157.91,
    "out:Primary Energy": 152.02,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 41.37,
    "out:Total CO2 (tons)": 133.2,
    "out:Klimatpaverkan": -85.63,
    "out:Initial Cost/MSEK": 0.672,
    "out:Running cost/(Apt SEK y)": 41568,
    "out:Running Cost over RSP/MSEK": 11.709,
    "out:LCP/MSEK": 2.144,
    "out:ROI% old": 59.85,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 54,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 106260,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146576,
    "out:DH kr savings": 106260,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462491,
    "out:% savings (space heating)": 18.57,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.18,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 162.91,
    "out:Primary Energy": 155.13,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.12,
    "out:CO2 Operational/m2": 33.4,
    "out:Total CO2/m2": 43.52,
    "out:Total CO2 (tons)": 140.13,
    "out:Klimatpaverkan": -78.7,
    "out:Initial Cost/MSEK": 0.728,
    "out:Running cost/(Apt SEK y)": 42919,
    "out:Running Cost over RSP/MSEK": 12.089,
    "out:LCP/MSEK": 1.708,
    "out:ROI% old": 47.78,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 43,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130476,
    "out:DH kr savings": 90160,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 483595,
    "out:% savings (space heating)": 14.85,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.63,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 159.6,
    "out:Total Energy Use Post PV": 156.91,
    "out:Primary Energy": 149.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.12,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 41.36,
    "out:Total CO2 (tons)": 133.17,
    "out:Klimatpaverkan": -85.66,
    "out:Initial Cost/MSEK": 0.809,
    "out:Running cost/(Apt SEK y)": 41298,
    "out:Running Cost over RSP/MSEK": 11.633,
    "out:LCP/MSEK": 2.084,
    "out:ROI% old": 51.08,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 46,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149796,
    "out:DH kr savings": 109480,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462491,
    "out:% savings (space heating)": 18.57,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.18,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 165.6,
    "out:Primary Energy": 159.09,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.53,
    "out:CO2 Operational/m2": 57.88,
    "out:Total CO2/m2": 59.41,
    "out:Total CO2 (tons)": 191.3,
    "out:Klimatpaverkan": -27.53,
    "out:Initial Cost/MSEK": 0.24,
    "out:Running cost/(Apt SEK y)": 44039,
    "out:Running Cost over RSP/MSEK": 12.396,
    "out:LCP/MSEK": 1.889,
    "out:ROI% old": 126.58,
    "out:Payback discounted": 1,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 113,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 38,
    "out:Payback Time": 2.631579,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90160,
    "out:DH kr savings": 90160,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 483595,
    "out:% savings (space heating)": 14.85,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.63,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 158.6,
    "out:Primary Energy": 153.36,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.53,
    "out:CO2 Operational/m2": 55.36,
    "out:Total CO2/m2": 56.89,
    "out:Total CO2 (tons)": 183.19,
    "out:Klimatpaverkan": -35.64,
    "out:Initial Cost/MSEK": 0.321,
    "out:Running cost/(Apt SEK y)": 42148,
    "out:Running Cost over RSP/MSEK": 11.864,
    "out:LCP/MSEK": 2.341,
    "out:ROI% old": 118.53,
    "out:Payback discounted": 1,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 106,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 35,
    "out:Payback Time": 2.857143,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112700,
    "out:DH kr savings": 112700,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462491,
    "out:% savings (space heating)": 18.57,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.18,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 164.6,
    "out:Total Energy Use Post PV": 164.6,
    "out:Primary Energy": 156.99,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.88,
    "out:CO2 Operational/m2": 57.52,
    "out:Total CO2/m2": 59.4,
    "out:Total CO2 (tons)": 191.27,
    "out:Klimatpaverkan": -27.56,
    "out:Initial Cost/MSEK": 0.377,
    "out:Running cost/(Apt SEK y)": 43769,
    "out:Running Cost over RSP/MSEK": 12.32,
    "out:LCP/MSEK": 1.829,
    "out:ROI% old": 83.59,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 75,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 25,
    "out:Payback Time": 4,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93380,
    "out:DH kr savings": 93380,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 483595,
    "out:% savings (space heating)": 14.85,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.63,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 157.6,
    "out:Total Energy Use Post PV": 157.6,
    "out:Primary Energy": 151.26,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.88,
    "out:CO2 Operational/m2": 55,
    "out:Total CO2/m2": 56.88,
    "out:Total CO2 (tons)": 183.16,
    "out:Klimatpaverkan": -35.67,
    "out:Initial Cost/MSEK": 0.457,
    "out:Running cost/(Apt SEK y)": 41878,
    "out:Running Cost over RSP/MSEK": 11.788,
    "out:LCP/MSEK": 2.281,
    "out:ROI% old": 85.51,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 77,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 25,
    "out:Payback Time": 4,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115920,
    "out:DH kr savings": 115920,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462491,
    "out:% savings (space heating)": 18.57,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.18,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 162.91,
    "out:Primary Energy": 154.26,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 33.4,
    "out:Total CO2/m2": 43.17,
    "out:Total CO2 (tons)": 139,
    "out:Klimatpaverkan": -79.83,
    "out:Initial Cost/MSEK": 0.687,
    "out:Running cost/(Apt SEK y)": 42919,
    "out:Running Cost over RSP/MSEK": 12.089,
    "out:LCP/MSEK": 1.75,
    "out:ROI% old": 50.67,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 46,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130476,
    "out:DH kr savings": 90160,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 483595,
    "out:% savings (space heating)": 14.85,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.63,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 155.91,
    "out:Primary Energy": 148.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 40.65,
    "out:Total CO2 (tons)": 130.88,
    "out:Klimatpaverkan": -87.95,
    "out:Initial Cost/MSEK": 0.767,
    "out:Running cost/(Apt SEK y)": 41028,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": 2.202,
    "out:ROI% old": 55.27,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 50,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153016,
    "out:DH kr savings": 112700,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462491,
    "out:% savings (space heating)": 18.57,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.18,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 164.6,
    "out:Total Energy Use Post PV": 161.91,
    "out:Primary Energy": 152.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.12,
    "out:CO2 Operational/m2": 33.04,
    "out:Total CO2/m2": 43.16,
    "out:Total CO2 (tons)": 138.97,
    "out:Klimatpaverkan": -79.86,
    "out:Initial Cost/MSEK": 0.823,
    "out:Running cost/(Apt SEK y)": 42649,
    "out:Running Cost over RSP/MSEK": 12.013,
    "out:LCP/MSEK": 1.689,
    "out:ROI% old": 43.59,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 39,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133696,
    "out:DH kr savings": 93380,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 483595,
    "out:% savings (space heating)": 14.85,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.63,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 157.6,
    "out:Total Energy Use Post PV": 154.91,
    "out:Primary Energy": 146.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.12,
    "out:CO2 Operational/m2": 30.52,
    "out:Total CO2/m2": 40.64,
    "out:Total CO2 (tons)": 130.85,
    "out:Klimatpaverkan": -87.98,
    "out:Initial Cost/MSEK": 0.904,
    "out:Running cost/(Apt SEK y)": 40758,
    "out:Running Cost over RSP/MSEK": 11.481,
    "out:LCP/MSEK": 2.141,
    "out:ROI% old": 48.12,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 43,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156236,
    "out:DH kr savings": 115920,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462491,
    "out:% savings (space heating)": 18.57,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 157.84,
    "out:Total Energy Use Post PV": 157.85,
    "out:Primary Energy": 164.21,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.79,
    "out:CO2 Operational/m2": 53.44,
    "out:Total CO2/m2": 58.23,
    "out:Total CO2 (tons)": 187.5,
    "out:Klimatpaverkan": -31.33,
    "out:Initial Cost/MSEK": 0.756,
    "out:Running cost/(Apt SEK y)": 41283,
    "out:Running Cost over RSP/MSEK": 11.616,
    "out:LCP/MSEK": 2.154,
    "out:ROI% old": 54.99,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 49,
    "out:Return/kSEK/y": 372,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91797,
    "out:DH kr savings": 138460,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 461986,
    "out:% savings (space heating)": 18.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 151.84,
    "out:Total Energy Use Post PV": 151.85,
    "out:Primary Energy": 158.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.79,
    "out:CO2 Operational/m2": 51.28,
    "out:Total CO2/m2": 56.07,
    "out:Total CO2 (tons)": 180.55,
    "out:Klimatpaverkan": -38.28,
    "out:Initial Cost/MSEK": 0.837,
    "out:Running cost/(Apt SEK y)": 39662,
    "out:Running Cost over RSP/MSEK": 11.159,
    "out:LCP/MSEK": 2.53,
    "out:ROI% old": 57.49,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 51,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111117,
    "out:DH kr savings": 157780,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440883,
    "out:% savings (space heating)": 22.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 156.85,
    "out:Primary Energy": 161.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.14,
    "out:CO2 Operational/m2": 53.08,
    "out:Total CO2/m2": 58.22,
    "out:Total CO2 (tons)": 187.46,
    "out:Klimatpaverkan": -31.37,
    "out:Initial Cost/MSEK": 0.893,
    "out:Running cost/(Apt SEK y)": 41010,
    "out:Running Cost over RSP/MSEK": 11.539,
    "out:LCP/MSEK": 2.094,
    "out:ROI% old": 47.8,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 43,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95017,
    "out:DH kr savings": 141680,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 457467,
    "out:% savings (space heating)": 19.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 150.83,
    "out:Total Energy Use Post PV": 150.85,
    "out:Primary Energy": 155.85,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.14,
    "out:CO2 Operational/m2": 50.92,
    "out:Total CO2/m2": 56.06,
    "out:Total CO2 (tons)": 180.51,
    "out:Klimatpaverkan": -38.32,
    "out:Initial Cost/MSEK": 0.973,
    "out:Running cost/(Apt SEK y)": 39390,
    "out:Running Cost over RSP/MSEK": 11.083,
    "out:LCP/MSEK": 2.47,
    "out:ROI% old": 50.54,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 45,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114337,
    "out:DH kr savings": 161000,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 436367,
    "out:% savings (space heating)": 23.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 157.84,
    "out:Total Energy Use Post PV": 153.5,
    "out:Primary Energy": 156.39,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.03,
    "out:CO2 Operational/m2": 37.24,
    "out:Total CO2/m2": 50.27,
    "out:Total CO2 (tons)": 161.88,
    "out:Klimatpaverkan": -56.95,
    "out:Initial Cost/MSEK": 1.203,
    "out:Running cost/(Apt SEK y)": 40089,
    "out:Running Cost over RSP/MSEK": 11.289,
    "out:LCP/MSEK": 2.035,
    "out:ROI% old": 38.46,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 34,
    "out:Return/kSEK/y": 414,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.43,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134782,
    "out:DH kr savings": 138460,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 461986,
    "out:% savings (space heating)": 18.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 151.84,
    "out:Total Energy Use Post PV": 147.5,
    "out:Primary Energy": 150.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.03,
    "out:CO2 Operational/m2": 35.08,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 154.92,
    "out:Klimatpaverkan": -63.91,
    "out:Initial Cost/MSEK": 1.283,
    "out:Running cost/(Apt SEK y)": 38468,
    "out:Running Cost over RSP/MSEK": 10.832,
    "out:LCP/MSEK": 2.411,
    "out:ROI% old": 41.13,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 37,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.45,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.17,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154102,
    "out:DH kr savings": 157780,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440883,
    "out:% savings (space heating)": 22.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.07,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 152.5,
    "out:Primary Energy": 153.77,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.38,
    "out:CO2 Operational/m2": 36.87,
    "out:Total CO2/m2": 50.25,
    "out:Total CO2 (tons)": 161.81,
    "out:Klimatpaverkan": -57.02,
    "out:Initial Cost/MSEK": 1.339,
    "out:Running cost/(Apt SEK y)": 39817,
    "out:Running Cost over RSP/MSEK": 11.212,
    "out:LCP/MSEK": 1.975,
    "out:ROI% old": 35.35,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 26.8,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 32,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.25,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.37,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138012,
    "out:DH kr savings": 141680,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 457467,
    "out:% savings (space heating)": 19.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 150.83,
    "out:Total Energy Use Post PV": 146.5,
    "out:Primary Energy": 148.03,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.38,
    "out:CO2 Operational/m2": 34.71,
    "out:Total CO2/m2": 48.09,
    "out:Total CO2 (tons)": 154.85,
    "out:Klimatpaverkan": -63.98,
    "out:Initial Cost/MSEK": 1.42,
    "out:Running cost/(Apt SEK y)": 38196,
    "out:Running Cost over RSP/MSEK": 10.756,
    "out:LCP/MSEK": 2.351,
    "out:ROI% old": 37.94,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 34,
    "out:Return/kSEK/y": 483,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.46,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.16,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157332,
    "out:DH kr savings": 161000,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 436367,
    "out:% savings (space heating)": 23.17,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 156.85,
    "out:Primary Energy": 160.71,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.79,
    "out:CO2 Operational/m2": 53.07,
    "out:Total CO2/m2": 57.87,
    "out:Total CO2 (tons)": 186.33,
    "out:Klimatpaverkan": -32.5,
    "out:Initial Cost/MSEK": 0.851,
    "out:Running cost/(Apt SEK y)": 41010,
    "out:Running Cost over RSP/MSEK": 11.539,
    "out:LCP/MSEK": 2.136,
    "out:ROI% old": 50.14,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 45,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95017,
    "out:DH kr savings": 141680,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 455977,
    "out:% savings (space heating)": 19.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 149.85,
    "out:Primary Energy": 154.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.79,
    "out:CO2 Operational/m2": 50.55,
    "out:Total CO2/m2": 55.35,
    "out:Total CO2 (tons)": 178.22,
    "out:Klimatpaverkan": -40.61,
    "out:Initial Cost/MSEK": 0.932,
    "out:Running cost/(Apt SEK y)": 39119,
    "out:Running Cost over RSP/MSEK": 11.006,
    "out:LCP/MSEK": 2.588,
    "out:ROI% old": 53.97,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 48,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117557,
    "out:DH kr savings": 164220,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 434880,
    "out:% savings (space heating)": 23.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 140.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 155.81,
    "out:Total Energy Use Post PV": 155.85,
    "out:Primary Energy": 158.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.14,
    "out:CO2 Operational/m2": 52.71,
    "out:Total CO2/m2": 57.86,
    "out:Total CO2 (tons)": 186.3,
    "out:Klimatpaverkan": -32.53,
    "out:Initial Cost/MSEK": 0.988,
    "out:Running cost/(Apt SEK y)": 40736,
    "out:Running Cost over RSP/MSEK": 11.462,
    "out:LCP/MSEK": 2.076,
    "out:ROI% old": 44.32,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 40,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98237,
    "out:DH kr savings": 144900,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 452450,
    "out:% savings (space heating)": 20.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 148.81,
    "out:Total Energy Use Post PV": 148.85,
    "out:Primary Energy": 152.87,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.14,
    "out:CO2 Operational/m2": 50.19,
    "out:Total CO2/m2": 55.34,
    "out:Total CO2 (tons)": 178.18,
    "out:Klimatpaverkan": -40.65,
    "out:Initial Cost/MSEK": 1.068,
    "out:Running cost/(Apt SEK y)": 38845,
    "out:Running Cost over RSP/MSEK": 10.929,
    "out:LCP/MSEK": 2.528,
    "out:ROI% old": 48.1,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 43,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120777,
    "out:DH kr savings": 167440,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 431364,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 152.5,
    "out:Primary Energy": 152.89,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.03,
    "out:CO2 Operational/m2": 36.87,
    "out:Total CO2/m2": 49.9,
    "out:Total CO2 (tons)": 160.66,
    "out:Klimatpaverkan": -58.17,
    "out:Initial Cost/MSEK": 1.298,
    "out:Running cost/(Apt SEK y)": 39816,
    "out:Running Cost over RSP/MSEK": 11.212,
    "out:LCP/MSEK": 2.016,
    "out:ROI% old": 36.49,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 26.8,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 33,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.49,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138017,
    "out:DH kr savings": 141680,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 455977,
    "out:% savings (space heating)": 19.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 145.5,
    "out:Primary Energy": 147.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.03,
    "out:CO2 Operational/m2": 34.35,
    "out:Total CO2/m2": 47.37,
    "out:Total CO2 (tons)": 152.55,
    "out:Klimatpaverkan": -66.28,
    "out:Initial Cost/MSEK": 1.378,
    "out:Running cost/(Apt SEK y)": 37925,
    "out:Running Cost over RSP/MSEK": 10.679,
    "out:LCP/MSEK": 2.468,
    "out:ROI% old": 39.87,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 36,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.84,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.78,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160557,
    "out:DH kr savings": 164220,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 434880,
    "out:% savings (space heating)": 23.43,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 140.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 155.81,
    "out:Total Energy Use Post PV": 151.5,
    "out:Primary Energy": 150.79,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.38,
    "out:CO2 Operational/m2": 36.49,
    "out:Total CO2/m2": 49.87,
    "out:Total CO2 (tons)": 160.57,
    "out:Klimatpaverkan": -58.26,
    "out:Initial Cost/MSEK": 1.434,
    "out:Running cost/(Apt SEK y)": 39543,
    "out:Running Cost over RSP/MSEK": 11.135,
    "out:LCP/MSEK": 1.957,
    "out:ROI% old": 33.78,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 30,
    "out:Return/kSEK/y": 434,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.03,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.59,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141253,
    "out:DH kr savings": 144900,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 452450,
    "out:% savings (space heating)": 20.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.18,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 148.81,
    "out:Total Energy Use Post PV": 144.5,
    "out:Primary Energy": 145.06,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.38,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 47.35,
    "out:Total CO2 (tons)": 152.45,
    "out:Klimatpaverkan": -66.38,
    "out:Initial Cost/MSEK": 1.515,
    "out:Running cost/(Apt SEK y)": 37652,
    "out:Running Cost over RSP/MSEK": 10.602,
    "out:LCP/MSEK": 2.409,
    "out:ROI% old": 37.01,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 33,
    "out:Return/kSEK/y": 502,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.61,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163794,
    "out:DH kr savings": 167440,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 431364,
    "out:% savings (space heating)": 24.05,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.63,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.51,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 153.6,
    "out:Total Energy Use Post PV": 153.6,
    "out:Primary Energy": 150.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.05,
    "out:CO2 Operational/m2": 53.56,
    "out:Total CO2/m2": 57.61,
    "out:Total CO2 (tons)": 185.5,
    "out:Klimatpaverkan": -33.33,
    "out:Initial Cost/MSEK": 0.753,
    "out:Running cost/(Apt SEK y)": 40797,
    "out:Running Cost over RSP/MSEK": 11.483,
    "out:LCP/MSEK": 2.29,
    "out:ROI% old": 57.75,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 52,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 128800,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128800,
    "out:DH kr savings": 128800,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439572,
    "out:% savings (space heating)": 22.6,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.58,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 147.6,
    "out:Primary Energy": 145.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.05,
    "out:CO2 Operational/m2": 51.4,
    "out:Total CO2/m2": 55.45,
    "out:Total CO2 (tons)": 178.55,
    "out:Klimatpaverkan": -40.28,
    "out:Initial Cost/MSEK": 0.833,
    "out:Running cost/(Apt SEK y)": 39177,
    "out:Running Cost over RSP/MSEK": 11.027,
    "out:LCP/MSEK": 2.666,
    "out:ROI% old": 60,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 54,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148120,
    "out:DH kr savings": 148120,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 420470,
    "out:% savings (space heating)": 25.97,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.51,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 152.6,
    "out:Total Energy Use Post PV": 152.6,
    "out:Primary Energy": 148,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.4,
    "out:CO2 Operational/m2": 53.2,
    "out:Total CO2/m2": 57.6,
    "out:Total CO2 (tons)": 185.47,
    "out:Klimatpaverkan": -33.36,
    "out:Initial Cost/MSEK": 0.889,
    "out:Running cost/(Apt SEK y)": 40527,
    "out:Running Cost over RSP/MSEK": 11.407,
    "out:LCP/MSEK": 2.229,
    "out:ROI% old": 50.1,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 26.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 45,
    "out:Return/kSEK/y": 399,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132020,
    "out:DH kr savings": 132020,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439572,
    "out:% savings (space heating)": 22.6,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.58,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 146.6,
    "out:Primary Energy": 142.81,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.4,
    "out:CO2 Operational/m2": 51.04,
    "out:Total CO2/m2": 55.44,
    "out:Total CO2 (tons)": 178.52,
    "out:Klimatpaverkan": -40.31,
    "out:Initial Cost/MSEK": 0.97,
    "out:Running cost/(Apt SEK y)": 38907,
    "out:Running Cost over RSP/MSEK": 10.951,
    "out:LCP/MSEK": 2.605,
    "out:ROI% old": 52.67,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 47,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151340,
    "out:DH kr savings": 151340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 420470,
    "out:% savings (space heating)": 25.97,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.51,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 153.6,
    "out:Total Energy Use Post PV": 150.91,
    "out:Primary Energy": 145.79,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.28,
    "out:CO2 Operational/m2": 29.08,
    "out:Total CO2/m2": 41.37,
    "out:Total CO2 (tons)": 133.2,
    "out:Klimatpaverkan": -85.63,
    "out:Initial Cost/MSEK": 1.199,
    "out:Running cost/(Apt SEK y)": 39678,
    "out:Running Cost over RSP/MSEK": 11.177,
    "out:LCP/MSEK": 2.15,
    "out:ROI% old": 39.9,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 36,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 128800,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169116,
    "out:DH kr savings": 128800,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439572,
    "out:% savings (space heating)": 22.6,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.58,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 144.91,
    "out:Primary Energy": 140.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.28,
    "out:CO2 Operational/m2": 26.92,
    "out:Total CO2/m2": 39.21,
    "out:Total CO2 (tons)": 126.24,
    "out:Klimatpaverkan": -92.59,
    "out:Initial Cost/MSEK": 1.28,
    "out:Running cost/(Apt SEK y)": 38057,
    "out:Running Cost over RSP/MSEK": 10.72,
    "out:LCP/MSEK": 2.526,
    "out:ROI% old": 42.49,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 38,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188436,
    "out:DH kr savings": 148120,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 420470,
    "out:% savings (space heating)": 25.97,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.51,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 152.6,
    "out:Total Energy Use Post PV": 149.91,
    "out:Primary Energy": 143.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.63,
    "out:CO2 Operational/m2": 28.72,
    "out:Total CO2/m2": 41.36,
    "out:Total CO2 (tons)": 133.17,
    "out:Klimatpaverkan": -85.66,
    "out:Initial Cost/MSEK": 1.336,
    "out:Running cost/(Apt SEK y)": 39407,
    "out:Running Cost over RSP/MSEK": 11.101,
    "out:LCP/MSEK": 2.09,
    "out:ROI% old": 36.63,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172336,
    "out:DH kr savings": 132020,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439572,
    "out:% savings (space heating)": 22.6,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.58,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 143.91,
    "out:Primary Energy": 137.98,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.63,
    "out:CO2 Operational/m2": 26.56,
    "out:Total CO2/m2": 39.2,
    "out:Total CO2 (tons)": 126.21,
    "out:Klimatpaverkan": -92.62,
    "out:Initial Cost/MSEK": 1.416,
    "out:Running cost/(Apt SEK y)": 37787,
    "out:Running Cost over RSP/MSEK": 10.644,
    "out:LCP/MSEK": 2.465,
    "out:ROI% old": 39.15,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 35,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 151340,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 420470,
    "out:% savings (space heating)": 25.97,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.51,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 151.6,
    "out:Primary Energy": 147.13,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.05,
    "out:CO2 Operational/m2": 52.84,
    "out:Total CO2/m2": 56.89,
    "out:Total CO2 (tons)": 183.18,
    "out:Klimatpaverkan": -35.65,
    "out:Initial Cost/MSEK": 0.848,
    "out:Running cost/(Apt SEK y)": 40257,
    "out:Running Cost over RSP/MSEK": 11.331,
    "out:LCP/MSEK": 2.347,
    "out:ROI% old": 53.84,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 48,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135240,
    "out:DH kr savings": 135240,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439572,
    "out:% savings (space heating)": 22.6,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.58,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 145.6,
    "out:Primary Energy": 141.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.05,
    "out:CO2 Operational/m2": 50.68,
    "out:Total CO2/m2": 54.73,
    "out:Total CO2 (tons)": 176.23,
    "out:Klimatpaverkan": -42.6,
    "out:Initial Cost/MSEK": 0.928,
    "out:Running cost/(Apt SEK y)": 38636,
    "out:Running Cost over RSP/MSEK": 10.875,
    "out:LCP/MSEK": 2.723,
    "out:ROI% old": 56.2,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 50,
    "out:Return/kSEK/y": 467,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154560,
    "out:DH kr savings": 154560,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 420470,
    "out:% savings (space heating)": 25.97,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.51,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 150.6,
    "out:Primary Energy": 145.03,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.4,
    "out:CO2 Operational/m2": 52.48,
    "out:Total CO2/m2": 56.88,
    "out:Total CO2 (tons)": 183.15,
    "out:Klimatpaverkan": -35.68,
    "out:Initial Cost/MSEK": 0.984,
    "out:Running cost/(Apt SEK y)": 39987,
    "out:Running Cost over RSP/MSEK": 11.255,
    "out:LCP/MSEK": 2.286,
    "out:ROI% old": 47.47,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 42,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138460,
    "out:DH kr savings": 138460,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439572,
    "out:% savings (space heating)": 22.6,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.58,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 144.6,
    "out:Primary Energy": 139.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.4,
    "out:CO2 Operational/m2": 50.32,
    "out:Total CO2/m2": 54.72,
    "out:Total CO2 (tons)": 176.2,
    "out:Klimatpaverkan": -42.63,
    "out:Initial Cost/MSEK": 1.065,
    "out:Running cost/(Apt SEK y)": 38366,
    "out:Running Cost over RSP/MSEK": 10.799,
    "out:LCP/MSEK": 2.662,
    "out:ROI% old": 50.01,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 45,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157780,
    "out:DH kr savings": 157780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 420470,
    "out:% savings (space heating)": 25.97,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.51,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 148.91,
    "out:Primary Energy": 142.29,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.28,
    "out:CO2 Operational/m2": 28.36,
    "out:Total CO2/m2": 40.65,
    "out:Total CO2 (tons)": 130.88,
    "out:Klimatpaverkan": -87.95,
    "out:Initial Cost/MSEK": 1.294,
    "out:Running cost/(Apt SEK y)": 39137,
    "out:Running Cost over RSP/MSEK": 11.024,
    "out:LCP/MSEK": 2.207,
    "out:ROI% old": 38.65,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 35,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175556,
    "out:DH kr savings": 135240,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439572,
    "out:% savings (space heating)": 22.6,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.58,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 142.91,
    "out:Primary Energy": 137.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.28,
    "out:CO2 Operational/m2": 26.2,
    "out:Total CO2/m2": 38.49,
    "out:Total CO2 (tons)": 123.92,
    "out:Klimatpaverkan": -94.91,
    "out:Initial Cost/MSEK": 1.375,
    "out:Running cost/(Apt SEK y)": 37517,
    "out:Running Cost over RSP/MSEK": 10.568,
    "out:LCP/MSEK": 2.583,
    "out:ROI% old": 41.13,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 37,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194876,
    "out:DH kr savings": 154560,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 420470,
    "out:% savings (space heating)": 25.97,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.51,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 147.91,
    "out:Primary Energy": 140.19,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.63,
    "out:CO2 Operational/m2": 28,
    "out:Total CO2/m2": 40.64,
    "out:Total CO2 (tons)": 130.85,
    "out:Klimatpaverkan": -87.98,
    "out:Initial Cost/MSEK": 1.431,
    "out:Running cost/(Apt SEK y)": 38867,
    "out:Running Cost over RSP/MSEK": 10.948,
    "out:LCP/MSEK": 2.147,
    "out:ROI% old": 35.72,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 32,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178776,
    "out:DH kr savings": 138460,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439572,
    "out:% savings (space heating)": 22.6,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.58,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 141.91,
    "out:Primary Energy": 135,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.63,
    "out:CO2 Operational/m2": 25.84,
    "out:Total CO2/m2": 38.48,
    "out:Total CO2 (tons)": 123.89,
    "out:Klimatpaverkan": -94.94,
    "out:Initial Cost/MSEK": 1.511,
    "out:Running cost/(Apt SEK y)": 37246,
    "out:Running Cost over RSP/MSEK": 10.492,
    "out:LCP/MSEK": 2.523,
    "out:ROI% old": 38.13,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 34,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198096,
    "out:DH kr savings": 157780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 420470,
    "out:% savings (space heating)": 25.97,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 144.84,
    "out:Total Energy Use Post PV": 144.85,
    "out:Primary Energy": 152.25,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 48.76,
    "out:Total CO2/m2": 56.07,
    "out:Total CO2 (tons)": 180.54,
    "out:Klimatpaverkan": -38.29,
    "out:Initial Cost/MSEK": 1.363,
    "out:Running cost/(Apt SEK y)": 37771,
    "out:Running Cost over RSP/MSEK": 10.627,
    "out:LCP/MSEK": 2.536,
    "out:ROI% old": 40.86,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 37,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 180320,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133657,
    "out:DH kr savings": 180320,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 417964,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 138.84,
    "out:Total Energy Use Post PV": 138.85,
    "out:Primary Energy": 147.05,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 46.6,
    "out:Total CO2/m2": 53.91,
    "out:Total CO2 (tons)": 173.58,
    "out:Klimatpaverkan": -45.25,
    "out:Initial Cost/MSEK": 1.444,
    "out:Running cost/(Apt SEK y)": 36150,
    "out:Running Cost over RSP/MSEK": 10.171,
    "out:LCP/MSEK": 2.912,
    "out:ROI% old": 43.09,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 39,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152977,
    "out:DH kr savings": 199640,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 398862,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 142.85,
    "out:Primary Energy": 149.62,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.66,
    "out:CO2 Operational/m2": 48.04,
    "out:Total CO2/m2": 55.7,
    "out:Total CO2 (tons)": 179.34,
    "out:Klimatpaverkan": -39.49,
    "out:Initial Cost/MSEK": 1.5,
    "out:Running cost/(Apt SEK y)": 37229,
    "out:Running Cost over RSP/MSEK": 10.474,
    "out:LCP/MSEK": 2.552,
    "out:ROI% old": 38.59,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 34,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140097,
    "out:DH kr savings": 186760,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 413448,
    "out:% savings (space heating)": 27.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 136.83,
    "out:Total Energy Use Post PV": 136.85,
    "out:Primary Energy": 144.43,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.66,
    "out:CO2 Operational/m2": 45.88,
    "out:Total CO2/m2": 53.54,
    "out:Total CO2 (tons)": 172.39,
    "out:Klimatpaverkan": -46.44,
    "out:Initial Cost/MSEK": 1.58,
    "out:Running cost/(Apt SEK y)": 35608,
    "out:Running Cost over RSP/MSEK": 10.018,
    "out:LCP/MSEK": 2.928,
    "out:ROI% old": 40.75,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 36,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159417,
    "out:DH kr savings": 206080,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 394350,
    "out:% savings (space heating)": 30.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 144.84,
    "out:Total Energy Use Post PV": 140.5,
    "out:Primary Energy": 144.43,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.55,
    "out:CO2 Operational/m2": 32.56,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 154.92,
    "out:Klimatpaverkan": -63.91,
    "out:Initial Cost/MSEK": 1.81,
    "out:Running cost/(Apt SEK y)": 36577,
    "out:Running Cost over RSP/MSEK": 10.3,
    "out:LCP/MSEK": 2.416,
    "out:ROI% old": 33.36,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 541,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.48,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.14,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 180320,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176642,
    "out:DH kr savings": 180320,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 417964,
    "out:% savings (space heating)": 26.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 138.84,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 139.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.55,
    "out:CO2 Operational/m2": 30.4,
    "out:Total CO2/m2": 45.95,
    "out:Total CO2 (tons)": 147.96,
    "out:Klimatpaverkan": -70.87,
    "out:Initial Cost/MSEK": 1.89,
    "out:Running cost/(Apt SEK y)": 34956,
    "out:Running Cost over RSP/MSEK": 9.843,
    "out:LCP/MSEK": 2.792,
    "out:ROI% old": 35.39,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 32,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.5,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195962,
    "out:DH kr savings": 199640,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 398862,
    "out:% savings (space heating)": 29.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.4,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 141.8,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.9,
    "out:CO2 Operational/m2": 31.83,
    "out:Total CO2/m2": 47.73,
    "out:Total CO2 (tons)": 153.69,
    "out:Klimatpaverkan": -65.14,
    "out:Initial Cost/MSEK": 1.946,
    "out:Running cost/(Apt SEK y)": 36035,
    "out:Running Cost over RSP/MSEK": 10.147,
    "out:LCP/MSEK": 2.432,
    "out:ROI% old": 32.14,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 29,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.49,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183092,
    "out:DH kr savings": 186760,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 413448,
    "out:% savings (space heating)": 27.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 136.83,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 136.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.9,
    "out:CO2 Operational/m2": 29.67,
    "out:Total CO2/m2": 45.57,
    "out:Total CO2 (tons)": 146.73,
    "out:Klimatpaverkan": -72.1,
    "out:Initial Cost/MSEK": 2.027,
    "out:Running cost/(Apt SEK y)": 34414,
    "out:Running Cost over RSP/MSEK": 9.691,
    "out:LCP/MSEK": 2.808,
    "out:ROI% old": 34.08,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 31,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.7,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13972.92,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202413,
    "out:DH kr savings": 206080,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 394350,
    "out:% savings (space heating)": 30.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 142.85,
    "out:Primary Energy": 148.75,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 48.04,
    "out:Total CO2/m2": 55.35,
    "out:Total CO2 (tons)": 178.21,
    "out:Klimatpaverkan": -40.62,
    "out:Initial Cost/MSEK": 1.458,
    "out:Running cost/(Apt SEK y)": 37228,
    "out:Running Cost over RSP/MSEK": 10.474,
    "out:LCP/MSEK": 2.594,
    "out:ROI% old": 39.69,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 35,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140097,
    "out:DH kr savings": 186760,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411960,
    "out:% savings (space heating)": 27.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 136.82,
    "out:Total Energy Use Post PV": 136.85,
    "out:Primary Energy": 143.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 45.88,
    "out:Total CO2/m2": 53.19,
    "out:Total CO2 (tons)": 171.26,
    "out:Klimatpaverkan": -47.57,
    "out:Initial Cost/MSEK": 1.539,
    "out:Running cost/(Apt SEK y)": 35607,
    "out:Running Cost over RSP/MSEK": 10.018,
    "out:LCP/MSEK": 2.969,
    "out:ROI% old": 41.85,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 37,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159417,
    "out:DH kr savings": 206080,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 392864,
    "out:% savings (space heating)": 30.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 141.81,
    "out:Total Energy Use Post PV": 141.85,
    "out:Primary Energy": 146.65,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.66,
    "out:CO2 Operational/m2": 47.67,
    "out:Total CO2/m2": 55.33,
    "out:Total CO2 (tons)": 178.18,
    "out:Klimatpaverkan": -40.65,
    "out:Initial Cost/MSEK": 1.595,
    "out:Running cost/(Apt SEK y)": 36954,
    "out:Running Cost over RSP/MSEK": 10.397,
    "out:LCP/MSEK": 2.534,
    "out:ROI% old": 36.98,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 33,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143317,
    "out:DH kr savings": 189980,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408439,
    "out:% savings (space heating)": 28.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 135.81,
    "out:Total Energy Use Post PV": 135.85,
    "out:Primary Energy": 141.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.66,
    "out:CO2 Operational/m2": 45.51,
    "out:Total CO2/m2": 53.17,
    "out:Total CO2 (tons)": 171.22,
    "out:Klimatpaverkan": -47.61,
    "out:Initial Cost/MSEK": 1.675,
    "out:Running cost/(Apt SEK y)": 35333,
    "out:Running Cost over RSP/MSEK": 9.941,
    "out:LCP/MSEK": 2.91,
    "out:ROI% old": 39.1,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1036.4,
    "out:Return %": 35,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162637,
    "out:DH kr savings": 209300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 389355,
    "out:% savings (space heating)": 31.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 140.93,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.55,
    "out:CO2 Operational/m2": 31.83,
    "out:Total CO2/m2": 47.37,
    "out:Total CO2 (tons)": 152.54,
    "out:Klimatpaverkan": -66.29,
    "out:Initial Cost/MSEK": 1.905,
    "out:Running cost/(Apt SEK y)": 36034,
    "out:Running Cost over RSP/MSEK": 10.147,
    "out:LCP/MSEK": 2.474,
    "out:ROI% old": 32.84,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 29,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.82,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.79,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183097,
    "out:DH kr savings": 186760,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411960,
    "out:% savings (space heating)": 27.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 136.82,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 135.74,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.55,
    "out:CO2 Operational/m2": 29.67,
    "out:Total CO2/m2": 45.21,
    "out:Total CO2 (tons)": 145.58,
    "out:Klimatpaverkan": -73.25,
    "out:Initial Cost/MSEK": 1.985,
    "out:Running cost/(Apt SEK y)": 34413,
    "out:Running Cost over RSP/MSEK": 9.69,
    "out:LCP/MSEK": 2.85,
    "out:ROI% old": 34.79,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 31,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.18,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.43,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202418,
    "out:DH kr savings": 206080,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 392864,
    "out:% savings (space heating)": 30.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 141.81,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 138.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.9,
    "out:CO2 Operational/m2": 31.45,
    "out:Total CO2/m2": 47.34,
    "out:Total CO2 (tons)": 152.45,
    "out:Klimatpaverkan": -66.38,
    "out:Initial Cost/MSEK": 2.041,
    "out:Running cost/(Apt SEK y)": 35761,
    "out:Running Cost over RSP/MSEK": 10.07,
    "out:LCP/MSEK": 2.415,
    "out:ROI% old": 31.18,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 28,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.73,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.88,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186334,
    "out:DH kr savings": 189980,
    "out:El kr savings": -18698,
    "out:El kr sold": 15052,
    "out:El kr saved": 27918,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408439,
    "out:% savings (space heating)": 28.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.51,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 135.81,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 133.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.9,
    "out:CO2 Operational/m2": 29.29,
    "out:Total CO2/m2": 45.18,
    "out:Total CO2 (tons)": 145.49,
    "out:Klimatpaverkan": -73.34,
    "out:Initial Cost/MSEK": 2.122,
    "out:Running cost/(Apt SEK y)": 34140,
    "out:Running Cost over RSP/MSEK": 9.613,
    "out:LCP/MSEK": 2.79,
    "out:ROI% old": 33.07,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 30,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.75,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.87,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205655,
    "out:DH kr savings": 209300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27916,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 389355,
    "out:% savings (space heating)": 31.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.58,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.4,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 150.6,
    "out:Primary Energy": 147.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.95,
    "out:CO2 Operational/m2": 52.48,
    "out:Total CO2/m2": 63.44,
    "out:Total CO2 (tons)": 204.26,
    "out:Klimatpaverkan": -14.57,
    "out:Initial Cost/MSEK": 2.24,
    "out:Running cost/(Apt SEK y)": 39987,
    "out:Running Cost over RSP/MSEK": 11.255,
    "out:LCP/MSEK": 1.031,
    "out:ROI% old": 20.86,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138460,
    "out:DH kr savings": 138460,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429542,
    "out:% savings (space heating)": 24.37,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.61,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 144.6,
    "out:Primary Energy": 142.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.95,
    "out:CO2 Operational/m2": 50.32,
    "out:Total CO2/m2": 61.28,
    "out:Total CO2 (tons)": 197.31,
    "out:Klimatpaverkan": -21.52,
    "out:Initial Cost/MSEK": 2.32,
    "out:Running cost/(Apt SEK y)": 38366,
    "out:Running Cost over RSP/MSEK": 10.799,
    "out:LCP/MSEK": 1.407,
    "out:ROI% old": 22.95,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157780,
    "out:DH kr savings": 157780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410895,
    "out:% savings (space heating)": 27.65,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.4,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 149.6,
    "out:Primary Energy": 145.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.3,
    "out:CO2 Operational/m2": 52.12,
    "out:Total CO2/m2": 63.43,
    "out:Total CO2 (tons)": 204.23,
    "out:Klimatpaverkan": -14.6,
    "out:Initial Cost/MSEK": 2.376,
    "out:Running cost/(Apt SEK y)": 39717,
    "out:Running Cost over RSP/MSEK": 11.179,
    "out:LCP/MSEK": 0.971,
    "out:ROI% old": 20.12,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141680,
    "out:DH kr savings": 141680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429542,
    "out:% savings (space heating)": 24.37,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.61,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 143.6,
    "out:Primary Energy": 140.21,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.3,
    "out:CO2 Operational/m2": 49.96,
    "out:Total CO2/m2": 61.27,
    "out:Total CO2 (tons)": 197.28,
    "out:Klimatpaverkan": -21.55,
    "out:Initial Cost/MSEK": 2.457,
    "out:Running cost/(Apt SEK y)": 38096,
    "out:Running Cost over RSP/MSEK": 10.723,
    "out:LCP/MSEK": 1.347,
    "out:ROI% old": 22.12,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 20,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161000,
    "out:DH kr savings": 161000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410895,
    "out:% savings (space heating)": 27.65,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.4,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 147.91,
    "out:Primary Energy": 143.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.19,
    "out:CO2 Operational/m2": 28,
    "out:Total CO2/m2": 47.19,
    "out:Total CO2 (tons)": 151.96,
    "out:Klimatpaverkan": -66.87,
    "out:Initial Cost/MSEK": 2.686,
    "out:Running cost/(Apt SEK y)": 38867,
    "out:Running Cost over RSP/MSEK": 10.948,
    "out:LCP/MSEK": 0.891,
    "out:ROI% old": 19.03,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178776,
    "out:DH kr savings": 138460,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429542,
    "out:% savings (space heating)": 24.37,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.61,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 141.91,
    "out:Primary Energy": 138,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.19,
    "out:CO2 Operational/m2": 25.84,
    "out:Total CO2/m2": 45.03,
    "out:Total CO2 (tons)": 145,
    "out:Klimatpaverkan": -73.83,
    "out:Initial Cost/MSEK": 2.767,
    "out:Running cost/(Apt SEK y)": 37246,
    "out:Running Cost over RSP/MSEK": 10.492,
    "out:LCP/MSEK": 1.267,
    "out:ROI% old": 20.83,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198096,
    "out:DH kr savings": 157780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410895,
    "out:% savings (space heating)": 27.65,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.4,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 146.91,
    "out:Primary Energy": 140.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.54,
    "out:CO2 Operational/m2": 27.64,
    "out:Total CO2/m2": 47.18,
    "out:Total CO2 (tons)": 151.93,
    "out:Klimatpaverkan": -66.9,
    "out:Initial Cost/MSEK": 2.823,
    "out:Running cost/(Apt SEK y)": 38597,
    "out:Running Cost over RSP/MSEK": 10.872,
    "out:LCP/MSEK": 0.831,
    "out:ROI% old": 18.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181996,
    "out:DH kr savings": 141680,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429542,
    "out:% savings (space heating)": 24.37,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.61,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 140.91,
    "out:Primary Energy": 135.38,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.54,
    "out:CO2 Operational/m2": 25.48,
    "out:Total CO2/m2": 45.02,
    "out:Total CO2 (tons)": 144.97,
    "out:Klimatpaverkan": -73.86,
    "out:Initial Cost/MSEK": 2.903,
    "out:Running cost/(Apt SEK y)": 36976,
    "out:Running Cost over RSP/MSEK": 10.416,
    "out:LCP/MSEK": 1.207,
    "out:ROI% old": 20.22,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201316,
    "out:DH kr savings": 161000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410895,
    "out:% savings (space heating)": 27.65,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.4,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 148.6,
    "out:Total Energy Use Post PV": 148.6,
    "out:Primary Energy": 144.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.95,
    "out:CO2 Operational/m2": 51.76,
    "out:Total CO2/m2": 62.72,
    "out:Total CO2 (tons)": 201.94,
    "out:Klimatpaverkan": -16.89,
    "out:Initial Cost/MSEK": 2.335,
    "out:Running cost/(Apt SEK y)": 39447,
    "out:Running Cost over RSP/MSEK": 11.103,
    "out:LCP/MSEK": 1.088,
    "out:ROI% old": 20.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 438,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144900,
    "out:DH kr savings": 144900,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429542,
    "out:% savings (space heating)": 24.37,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.61,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 142.6,
    "out:Primary Energy": 139.34,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.95,
    "out:CO2 Operational/m2": 49.6,
    "out:Total CO2/m2": 60.56,
    "out:Total CO2 (tons)": 194.99,
    "out:Klimatpaverkan": -23.84,
    "out:Initial Cost/MSEK": 2.415,
    "out:Running cost/(Apt SEK y)": 37826,
    "out:Running Cost over RSP/MSEK": 10.647,
    "out:LCP/MSEK": 1.464,
    "out:ROI% old": 22.95,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164220,
    "out:DH kr savings": 164220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410895,
    "out:% savings (space heating)": 27.65,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.4,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 147.6,
    "out:Primary Energy": 142.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.3,
    "out:CO2 Operational/m2": 51.4,
    "out:Total CO2/m2": 62.71,
    "out:Total CO2 (tons)": 201.91,
    "out:Klimatpaverkan": -16.92,
    "out:Initial Cost/MSEK": 2.471,
    "out:Running cost/(Apt SEK y)": 39177,
    "out:Running Cost over RSP/MSEK": 11.027,
    "out:LCP/MSEK": 1.028,
    "out:ROI% old": 20.23,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148120,
    "out:DH kr savings": 148120,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429542,
    "out:% savings (space heating)": 24.37,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.61,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 141.6,
    "out:Total Energy Use Post PV": 141.6,
    "out:Primary Energy": 137.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.3,
    "out:CO2 Operational/m2": 49.24,
    "out:Total CO2/m2": 60.55,
    "out:Total CO2 (tons)": 194.96,
    "out:Klimatpaverkan": -23.87,
    "out:Initial Cost/MSEK": 2.552,
    "out:Running cost/(Apt SEK y)": 37556,
    "out:Running Cost over RSP/MSEK": 10.571,
    "out:LCP/MSEK": 1.404,
    "out:ROI% old": 22.14,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 20,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167440,
    "out:DH kr savings": 167440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410895,
    "out:% savings (space heating)": 27.65,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00001200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.4,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 148.6,
    "out:Total Energy Use Post PV": 145.91,
    "out:Primary Energy": 139.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.19,
    "out:CO2 Operational/m2": 27.28,
    "out:Total CO2/m2": 46.47,
    "out:Total CO2 (tons)": 149.64,
    "out:Klimatpaverkan": -69.19,
    "out:Initial Cost/MSEK": 2.781,
    "out:Running cost/(Apt SEK y)": 38327,
    "out:Running Cost over RSP/MSEK": 10.796,
    "out:LCP/MSEK": 0.949,
    "out:ROI% old": 19.16,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185216,
    "out:DH kr savings": 144900,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429542,
    "out:% savings (space heating)": 24.37,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.61,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 139.91,
    "out:Primary Energy": 134.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.19,
    "out:CO2 Operational/m2": 25.12,
    "out:Total CO2/m2": 44.31,
    "out:Total CO2 (tons)": 142.68,
    "out:Klimatpaverkan": -76.15,
    "out:Initial Cost/MSEK": 2.862,
    "out:Running cost/(Apt SEK y)": 36706,
    "out:Running Cost over RSP/MSEK": 10.34,
    "out:LCP/MSEK": 1.324,
    "out:ROI% old": 20.9,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204536,
    "out:DH kr savings": 164220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410895,
    "out:% savings (space heating)": 27.65,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.4,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 144.91,
    "out:Primary Energy": 137.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.54,
    "out:CO2 Operational/m2": 26.92,
    "out:Total CO2/m2": 46.46,
    "out:Total CO2 (tons)": 149.61,
    "out:Klimatpaverkan": -69.22,
    "out:Initial Cost/MSEK": 2.918,
    "out:Running cost/(Apt SEK y)": 38057,
    "out:Running Cost over RSP/MSEK": 10.72,
    "out:LCP/MSEK": 0.888,
    "out:ROI% old": 18.63,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188436,
    "out:DH kr savings": 148120,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429542,
    "out:% savings (space heating)": 24.37,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.61,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 141.6,
    "out:Total Energy Use Post PV": 138.91,
    "out:Primary Energy": 132.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.54,
    "out:CO2 Operational/m2": 24.76,
    "out:Total CO2/m2": 44.3,
    "out:Total CO2 (tons)": 142.65,
    "out:Klimatpaverkan": -76.18,
    "out:Initial Cost/MSEK": 2.998,
    "out:Running cost/(Apt SEK y)": 36436,
    "out:Running Cost over RSP/MSEK": 10.264,
    "out:LCP/MSEK": 1.264,
    "out:ROI% old": 20.31,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207756,
    "out:DH kr savings": 167440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410895,
    "out:% savings (space heating)": 27.65,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00001201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 141.84,
    "out:Total Energy Use Post PV": 141.85,
    "out:Primary Energy": 149.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 47.68,
    "out:Total CO2/m2": 61.89,
    "out:Total CO2 (tons)": 199.3,
    "out:Klimatpaverkan": -19.53,
    "out:Initial Cost/MSEK": 2.85,
    "out:Running cost/(Apt SEK y)": 36961,
    "out:Running Cost over RSP/MSEK": 10.399,
    "out:LCP/MSEK": 1.277,
    "out:ROI% old": 20.69,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 18,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143317,
    "out:DH kr savings": 189980,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 407932,
    "out:% savings (space heating)": 28.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 135.84,
    "out:Total Energy Use Post PV": 135.85,
    "out:Primary Energy": 144.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 45.52,
    "out:Total CO2/m2": 59.73,
    "out:Total CO2 (tons)": 192.35,
    "out:Klimatpaverkan": -26.48,
    "out:Initial Cost/MSEK": 2.931,
    "out:Running cost/(Apt SEK y)": 35340,
    "out:Running Cost over RSP/MSEK": 9.942,
    "out:LCP/MSEK": 1.653,
    "out:ROI% old": 22.34,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 20,
    "out:Return/kSEK/y": 585,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162637,
    "out:DH kr savings": 209300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 389286,
    "out:% savings (space heating)": 31.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 139.85,
    "out:Primary Energy": 146.89,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.57,
    "out:CO2 Operational/m2": 46.96,
    "out:Total CO2/m2": 61.52,
    "out:Total CO2 (tons)": 198.11,
    "out:Klimatpaverkan": -20.72,
    "out:Initial Cost/MSEK": 2.987,
    "out:Running cost/(Apt SEK y)": 36418,
    "out:Running Cost over RSP/MSEK": 10.246,
    "out:LCP/MSEK": 1.293,
    "out:ROI% old": 20.47,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 18,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149757,
    "out:DH kr savings": 196420,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 403413,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 133.85,
    "out:Primary Energy": 141.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.57,
    "out:CO2 Operational/m2": 44.8,
    "out:Total CO2/m2": 59.36,
    "out:Total CO2 (tons)": 191.15,
    "out:Klimatpaverkan": -27.68,
    "out:Initial Cost/MSEK": 3.067,
    "out:Running cost/(Apt SEK y)": 34798,
    "out:Running Cost over RSP/MSEK": 9.79,
    "out:LCP/MSEK": 1.669,
    "out:ROI% old": 22.06,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169077,
    "out:DH kr savings": 215740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 384770,
    "out:% savings (space heating)": 32.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 141.84,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 141.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 31.49,
    "out:Total CO2/m2": 53.94,
    "out:Total CO2 (tons)": 173.68,
    "out:Klimatpaverkan": -45.15,
    "out:Initial Cost/MSEK": 3.297,
    "out:Running cost/(Apt SEK y)": 35767,
    "out:Running Cost over RSP/MSEK": 10.072,
    "out:LCP/MSEK": 1.158,
    "out:ROI% old": 19.3,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.41,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186302,
    "out:DH kr savings": 189980,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 407932,
    "out:% savings (space heating)": 28.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 135.84,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 136.64,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 29.33,
    "out:Total CO2/m2": 51.78,
    "out:Total CO2 (tons)": 166.72,
    "out:Klimatpaverkan": -52.11,
    "out:Initial Cost/MSEK": 3.377,
    "out:Running cost/(Apt SEK y)": 34146,
    "out:Running Cost over RSP/MSEK": 9.615,
    "out:LCP/MSEK": 1.533,
    "out:ROI% old": 20.77,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.42,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205622,
    "out:DH kr savings": 209300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 389286,
    "out:% savings (space heating)": 31.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 139.08,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.8,
    "out:CO2 Operational/m2": 30.75,
    "out:Total CO2/m2": 53.56,
    "out:Total CO2 (tons)": 172.45,
    "out:Klimatpaverkan": -46.38,
    "out:Initial Cost/MSEK": 3.433,
    "out:Running cost/(Apt SEK y)": 35225,
    "out:Running Cost over RSP/MSEK": 9.919,
    "out:LCP/MSEK": 1.174,
    "out:ROI% old": 19.17,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 17,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.2,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.42,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192752,
    "out:DH kr savings": 196420,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 403413,
    "out:% savings (space heating)": 28.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 134.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.8,
    "out:CO2 Operational/m2": 28.59,
    "out:Total CO2/m2": 51.4,
    "out:Total CO2 (tons)": 165.49,
    "out:Klimatpaverkan": -53.34,
    "out:Initial Cost/MSEK": 3.514,
    "out:Running cost/(Apt SEK y)": 33604,
    "out:Running Cost over RSP/MSEK": 9.463,
    "out:LCP/MSEK": 1.55,
    "out:ROI% old": 20.59,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 648,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.41,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212072,
    "out:DH kr savings": 215740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 384770,
    "out:% savings (space heating)": 32.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 139.85,
    "out:Primary Energy": 146.02,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 46.96,
    "out:Total CO2/m2": 61.17,
    "out:Total CO2 (tons)": 196.98,
    "out:Klimatpaverkan": -21.85,
    "out:Initial Cost/MSEK": 2.945,
    "out:Running cost/(Apt SEK y)": 36417,
    "out:Running Cost over RSP/MSEK": 10.246,
    "out:LCP/MSEK": 1.335,
    "out:ROI% old": 20.76,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149757,
    "out:DH kr savings": 196420,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 401923,
    "out:% savings (space heating)": 29.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 133.85,
    "out:Primary Energy": 140.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 44.8,
    "out:Total CO2/m2": 59.01,
    "out:Total CO2 (tons)": 190.02,
    "out:Klimatpaverkan": -28.81,
    "out:Initial Cost/MSEK": 3.026,
    "out:Running cost/(Apt SEK y)": 34797,
    "out:Running Cost over RSP/MSEK": 9.789,
    "out:LCP/MSEK": 1.711,
    "out:ROI% old": 22.36,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169077,
    "out:DH kr savings": 215740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 383283,
    "out:% savings (space heating)": 32.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 138.81,
    "out:Total Energy Use Post PV": 138.85,
    "out:Primary Energy": 143.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.57,
    "out:CO2 Operational/m2": 46.59,
    "out:Total CO2/m2": 61.16,
    "out:Total CO2 (tons)": 196.94,
    "out:Klimatpaverkan": -21.89,
    "out:Initial Cost/MSEK": 3.082,
    "out:Running cost/(Apt SEK y)": 36144,
    "out:Running Cost over RSP/MSEK": 10.169,
    "out:LCP/MSEK": 1.275,
    "out:ROI% old": 20.2,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152977,
    "out:DH kr savings": 199640,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 398398,
    "out:% savings (space heating)": 29.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 132.81,
    "out:Total Energy Use Post PV": 132.85,
    "out:Primary Energy": 138.85,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.57,
    "out:CO2 Operational/m2": 44.43,
    "out:Total CO2/m2": 59,
    "out:Total CO2 (tons)": 189.98,
    "out:Klimatpaverkan": -28.85,
    "out:Initial Cost/MSEK": 3.162,
    "out:Running cost/(Apt SEK y)": 34523,
    "out:Running Cost over RSP/MSEK": 9.712,
    "out:LCP/MSEK": 1.651,
    "out:ROI% old": 21.74,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172297,
    "out:DH kr savings": 218960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 379769,
    "out:% savings (space heating)": 33.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00001201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 138.2,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 30.75,
    "out:Total CO2/m2": 53.2,
    "out:Total CO2 (tons)": 171.3,
    "out:Klimatpaverkan": -47.53,
    "out:Initial Cost/MSEK": 3.392,
    "out:Running cost/(Apt SEK y)": 35224,
    "out:Running Cost over RSP/MSEK": 9.919,
    "out:LCP/MSEK": 1.216,
    "out:ROI% old": 19.41,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.44,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.18,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192757,
    "out:DH kr savings": 196420,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 401923,
    "out:% savings (space heating)": 29.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 133.14,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 28.59,
    "out:Total CO2/m2": 51.04,
    "out:Total CO2 (tons)": 164.35,
    "out:Klimatpaverkan": -54.48,
    "out:Initial Cost/MSEK": 3.472,
    "out:Running cost/(Apt SEK y)": 33603,
    "out:Running Cost over RSP/MSEK": 9.462,
    "out:LCP/MSEK": 1.591,
    "out:ROI% old": 20.83,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 648,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.79,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.82,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212077,
    "out:DH kr savings": 215740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 383283,
    "out:% savings (space heating)": 32.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 138.81,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 136.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.8,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 53.17,
    "out:Total CO2 (tons)": 171.21,
    "out:Klimatpaverkan": -47.62,
    "out:Initial Cost/MSEK": 3.528,
    "out:Running cost/(Apt SEK y)": 34951,
    "out:Running Cost over RSP/MSEK": 9.842,
    "out:LCP/MSEK": 1.156,
    "out:ROI% old": 18.97,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195993,
    "out:DH kr savings": 199640,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 398398,
    "out:% savings (space heating)": 29.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.4,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00001201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 132.81,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 131.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.8,
    "out:CO2 Operational/m2": 28.21,
    "out:Total CO2/m2": 51.01,
    "out:Total CO2 (tons)": 164.25,
    "out:Klimatpaverkan": -54.58,
    "out:Initial Cost/MSEK": 3.609,
    "out:Running cost/(Apt SEK y)": 33330,
    "out:Running Cost over RSP/MSEK": 9.385,
    "out:LCP/MSEK": 1.532,
    "out:ROI% old": 20.35,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215314,
    "out:DH kr savings": 218960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 379769,
    "out:% savings (space heating)": 33.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.61,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.45,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 166.6,
    "out:Total Energy Use Post PV": 166.6,
    "out:Primary Energy": 161.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.91,
    "out:CO2 Operational/m2": 58.24,
    "out:Total CO2/m2": 60.15,
    "out:Total CO2 (tons)": 193.69,
    "out:Klimatpaverkan": -25.14,
    "out:Initial Cost/MSEK": 0.186,
    "out:Running cost/(Apt SEK y)": 44309,
    "out:Running Cost over RSP/MSEK": 12.472,
    "out:LCP/MSEK": 1.868,
    "out:ROI% old": 158.08,
    "out:Payback discounted": 1,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 141,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 47,
    "out:Payback Time": 2.12766,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86940,
    "out:DH kr savings": 86940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481241,
    "out:% savings (space heating)": 15.27,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.93,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 160.6,
    "out:Total Energy Use Post PV": 160.6,
    "out:Primary Energy": 156.25,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.91,
    "out:CO2 Operational/m2": 56.08,
    "out:Total CO2/m2": 57.99,
    "out:Total CO2 (tons)": 186.73,
    "out:Klimatpaverkan": -32.1,
    "out:Initial Cost/MSEK": 0.266,
    "out:Running cost/(Apt SEK y)": 42688,
    "out:Running Cost over RSP/MSEK": 12.016,
    "out:LCP/MSEK": 2.244,
    "out:ROI% old": 134.76,
    "out:Payback discounted": 1,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 121,
    "out:Return/kSEK/y": 321,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 40,
    "out:Payback Time": 2.5,
    "out:DH kWh savings": 106260,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106260,
    "out:DH kr savings": 106260,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460245,
    "out:% savings (space heating)": 18.96,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.45,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 165.6,
    "out:Primary Energy": 159.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.26,
    "out:CO2 Operational/m2": 57.88,
    "out:Total CO2/m2": 60.14,
    "out:Total CO2 (tons)": 193.66,
    "out:Klimatpaverkan": -25.17,
    "out:Initial Cost/MSEK": 0.322,
    "out:Running cost/(Apt SEK y)": 44039,
    "out:Running Cost over RSP/MSEK": 12.396,
    "out:LCP/MSEK": 1.808,
    "out:ROI% old": 94.43,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 85,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 28,
    "out:Payback Time": 3.571429,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90160,
    "out:DH kr savings": 90160,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481241,
    "out:% savings (space heating)": 15.27,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.93,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 158.6,
    "out:Primary Energy": 153.62,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.26,
    "out:CO2 Operational/m2": 55.36,
    "out:Total CO2/m2": 57.62,
    "out:Total CO2 (tons)": 185.54,
    "out:Klimatpaverkan": -33.29,
    "out:Initial Cost/MSEK": 0.403,
    "out:Running cost/(Apt SEK y)": 42148,
    "out:Running Cost over RSP/MSEK": 11.864,
    "out:LCP/MSEK": 2.259,
    "out:ROI% old": 94.44,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 85,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 28,
    "out:Payback Time": 3.571429,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112700,
    "out:DH kr savings": 112700,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460245,
    "out:% savings (space heating)": 18.96,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.45,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 166.6,
    "out:Total Energy Use Post PV": 163.91,
    "out:Primary Energy": 157.12,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.15,
    "out:CO2 Operational/m2": 33.76,
    "out:Total CO2/m2": 43.91,
    "out:Total CO2 (tons)": 141.39,
    "out:Klimatpaverkan": -77.44,
    "out:Initial Cost/MSEK": 0.632,
    "out:Running cost/(Apt SEK y)": 43189,
    "out:Running Cost over RSP/MSEK": 12.165,
    "out:LCP/MSEK": 1.728,
    "out:ROI% old": 53.34,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 18.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 48,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127256,
    "out:DH kr savings": 86940,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481241,
    "out:% savings (space heating)": 15.27,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.93,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 160.6,
    "out:Total Energy Use Post PV": 157.91,
    "out:Primary Energy": 151.41,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.15,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 41.75,
    "out:Total CO2 (tons)": 134.43,
    "out:Klimatpaverkan": -84.4,
    "out:Initial Cost/MSEK": 0.713,
    "out:Running cost/(Apt SEK y)": 41568,
    "out:Running Cost over RSP/MSEK": 11.709,
    "out:LCP/MSEK": 2.104,
    "out:ROI% old": 56.47,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 51,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 106260,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146576,
    "out:DH kr savings": 106260,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460245,
    "out:% savings (space heating)": 18.96,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.45,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 162.91,
    "out:Primary Energy": 154.49,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.5,
    "out:CO2 Operational/m2": 33.4,
    "out:Total CO2/m2": 43.9,
    "out:Total CO2 (tons)": 141.36,
    "out:Klimatpaverkan": -77.47,
    "out:Initial Cost/MSEK": 0.769,
    "out:Running cost/(Apt SEK y)": 42919,
    "out:Running Cost over RSP/MSEK": 12.089,
    "out:LCP/MSEK": 1.668,
    "out:ROI% old": 45.28,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 41,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130476,
    "out:DH kr savings": 90160,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481241,
    "out:% savings (space heating)": 15.27,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.93,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 155.91,
    "out:Primary Energy": 148.79,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.5,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 41.38,
    "out:Total CO2 (tons)": 133.24,
    "out:Klimatpaverkan": -85.59,
    "out:Initial Cost/MSEK": 0.849,
    "out:Running cost/(Apt SEK y)": 41028,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": 2.12,
    "out:ROI% old": 49.94,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 45,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153016,
    "out:DH kr savings": 112700,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460245,
    "out:% savings (space heating)": 18.96,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.45,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 164.6,
    "out:Total Energy Use Post PV": 164.6,
    "out:Primary Energy": 158.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.91,
    "out:CO2 Operational/m2": 57.52,
    "out:Total CO2/m2": 59.43,
    "out:Total CO2 (tons)": 191.37,
    "out:Klimatpaverkan": -27.46,
    "out:Initial Cost/MSEK": 0.281,
    "out:Running cost/(Apt SEK y)": 43769,
    "out:Running Cost over RSP/MSEK": 12.32,
    "out:LCP/MSEK": 1.925,
    "out:ROI% old": 112.3,
    "out:Payback discounted": 1,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 100,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 33,
    "out:Payback Time": 3.030303,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93380,
    "out:DH kr savings": 93380,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481241,
    "out:% savings (space heating)": 15.27,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.93,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 158.6,
    "out:Primary Energy": 152.75,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.91,
    "out:CO2 Operational/m2": 55.36,
    "out:Total CO2/m2": 57.27,
    "out:Total CO2 (tons)": 184.42,
    "out:Klimatpaverkan": -34.41,
    "out:Initial Cost/MSEK": 0.361,
    "out:Running cost/(Apt SEK y)": 42148,
    "out:Running Cost over RSP/MSEK": 11.864,
    "out:LCP/MSEK": 2.301,
    "out:ROI% old": 105.32,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 94,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 31,
    "out:Payback Time": 3.225806,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112700,
    "out:DH kr savings": 112700,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460245,
    "out:% savings (space heating)": 18.96,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.45,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 163.6,
    "out:Total Energy Use Post PV": 163.6,
    "out:Primary Energy": 156.35,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.26,
    "out:CO2 Operational/m2": 57.16,
    "out:Total CO2/m2": 59.42,
    "out:Total CO2 (tons)": 191.34,
    "out:Klimatpaverkan": -27.49,
    "out:Initial Cost/MSEK": 0.417,
    "out:Running cost/(Apt SEK y)": 43499,
    "out:Running Cost over RSP/MSEK": 12.244,
    "out:LCP/MSEK": 1.865,
    "out:ROI% old": 78.13,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 18.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 70,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 23,
    "out:Payback Time": 4.347826,
    "out:DH kWh savings": 96600,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96600,
    "out:DH kr savings": 96600,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481241,
    "out:% savings (space heating)": 15.27,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.93,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 156.6,
    "out:Total Energy Use Post PV": 156.6,
    "out:Primary Energy": 150.65,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.26,
    "out:CO2 Operational/m2": 54.64,
    "out:Total CO2/m2": 56.9,
    "out:Total CO2 (tons)": 183.23,
    "out:Klimatpaverkan": -35.6,
    "out:Initial Cost/MSEK": 0.498,
    "out:Running cost/(Apt SEK y)": 41608,
    "out:Running Cost over RSP/MSEK": 11.712,
    "out:LCP/MSEK": 2.317,
    "out:ROI% old": 80.78,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 72,
    "out:Return/kSEK/y": 360,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 24,
    "out:Payback Time": 4.166667,
    "out:DH kWh savings": 119140,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 119140,
    "out:DH kr savings": 119140,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460245,
    "out:% savings (space heating)": 18.96,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.45,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 164.6,
    "out:Total Energy Use Post PV": 161.91,
    "out:Primary Energy": 153.62,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.15,
    "out:CO2 Operational/m2": 33.04,
    "out:Total CO2/m2": 43.19,
    "out:Total CO2 (tons)": 139.07,
    "out:Klimatpaverkan": -79.76,
    "out:Initial Cost/MSEK": 0.727,
    "out:Running cost/(Apt SEK y)": 42649,
    "out:Running Cost over RSP/MSEK": 12.013,
    "out:LCP/MSEK": 1.785,
    "out:ROI% old": 49.36,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 44,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133696,
    "out:DH kr savings": 93380,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481241,
    "out:% savings (space heating)": 15.27,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.93,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 155.91,
    "out:Primary Energy": 147.91,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.15,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 41.03,
    "out:Total CO2 (tons)": 132.11,
    "out:Klimatpaverkan": -86.72,
    "out:Initial Cost/MSEK": 0.808,
    "out:Running cost/(Apt SEK y)": 41028,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": 2.161,
    "out:ROI% old": 52.52,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 47,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153016,
    "out:DH kr savings": 112700,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460245,
    "out:% savings (space heating)": 18.96,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.45,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 163.6,
    "out:Total Energy Use Post PV": 160.91,
    "out:Primary Energy": 151.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.5,
    "out:CO2 Operational/m2": 32.68,
    "out:Total CO2/m2": 43.18,
    "out:Total CO2 (tons)": 139.04,
    "out:Klimatpaverkan": -79.79,
    "out:Initial Cost/MSEK": 0.864,
    "out:Running cost/(Apt SEK y)": 42379,
    "out:Running Cost over RSP/MSEK": 11.937,
    "out:LCP/MSEK": 1.725,
    "out:ROI% old": 42.81,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 38,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 96600,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136916,
    "out:DH kr savings": 96600,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481241,
    "out:% savings (space heating)": 15.27,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.93,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 156.6,
    "out:Total Energy Use Post PV": 153.91,
    "out:Primary Energy": 145.81,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.5,
    "out:CO2 Operational/m2": 30.16,
    "out:Total CO2/m2": 40.66,
    "out:Total CO2 (tons)": 130.92,
    "out:Klimatpaverkan": -87.91,
    "out:Initial Cost/MSEK": 0.944,
    "out:Running cost/(Apt SEK y)": 40488,
    "out:Running Cost over RSP/MSEK": 11.405,
    "out:LCP/MSEK": 2.177,
    "out:ROI% old": 47.22,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 149,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 42,
    "out:Return/kSEK/y": 400,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 119140,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159456,
    "out:DH kr savings": 119140,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460245,
    "out:% savings (space heating)": 18.96,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 157.84,
    "out:Total Energy Use Post PV": 157.85,
    "out:Primary Energy": 163.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.17,
    "out:CO2 Operational/m2": 53.44,
    "out:Total CO2/m2": 58.61,
    "out:Total CO2 (tons)": 188.73,
    "out:Klimatpaverkan": -30.1,
    "out:Initial Cost/MSEK": 0.796,
    "out:Running cost/(Apt SEK y)": 41283,
    "out:Running Cost over RSP/MSEK": 11.616,
    "out:LCP/MSEK": 2.114,
    "out:ROI% old": 52.21,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 47,
    "out:Return/kSEK/y": 372,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91797,
    "out:DH kr savings": 138460,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 459632,
    "out:% savings (space heating)": 19.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 150.84,
    "out:Total Energy Use Post PV": 150.85,
    "out:Primary Energy": 157.86,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.17,
    "out:CO2 Operational/m2": 50.92,
    "out:Total CO2/m2": 56.09,
    "out:Total CO2 (tons)": 180.61,
    "out:Klimatpaverkan": -38.22,
    "out:Initial Cost/MSEK": 0.877,
    "out:Running cost/(Apt SEK y)": 39392,
    "out:Running Cost over RSP/MSEK": 11.083,
    "out:LCP/MSEK": 2.566,
    "out:ROI% old": 56.09,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 50,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114337,
    "out:DH kr savings": 161000,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 438636,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 155.83,
    "out:Total Energy Use Post PV": 155.85,
    "out:Primary Energy": 160.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.52,
    "out:CO2 Operational/m2": 52.72,
    "out:Total CO2/m2": 58.24,
    "out:Total CO2 (tons)": 187.53,
    "out:Klimatpaverkan": -31.3,
    "out:Initial Cost/MSEK": 0.933,
    "out:Running cost/(Apt SEK y)": 40740,
    "out:Running Cost over RSP/MSEK": 11.463,
    "out:LCP/MSEK": 2.13,
    "out:ROI% old": 46.9,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 42,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98237,
    "out:DH kr savings": 144900,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 455113,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 149.85,
    "out:Primary Energy": 155.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.52,
    "out:CO2 Operational/m2": 50.56,
    "out:Total CO2/m2": 56.08,
    "out:Total CO2 (tons)": 180.58,
    "out:Klimatpaverkan": -38.25,
    "out:Initial Cost/MSEK": 1.013,
    "out:Running cost/(Apt SEK y)": 39120,
    "out:Running Cost over RSP/MSEK": 11.007,
    "out:LCP/MSEK": 2.506,
    "out:ROI% old": 49.61,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 44,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117557,
    "out:DH kr savings": 164220,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 434120,
    "out:% savings (space heating)": 23.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 157.84,
    "out:Total Energy Use Post PV": 153.5,
    "out:Primary Energy": 155.75,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.41,
    "out:CO2 Operational/m2": 37.24,
    "out:Total CO2/m2": 50.65,
    "out:Total CO2 (tons)": 163.11,
    "out:Klimatpaverkan": -55.72,
    "out:Initial Cost/MSEK": 1.243,
    "out:Running cost/(Apt SEK y)": 40089,
    "out:Running Cost over RSP/MSEK": 11.289,
    "out:LCP/MSEK": 1.994,
    "out:ROI% old": 37.21,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 33,
    "out:Return/kSEK/y": 414,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.43,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134782,
    "out:DH kr savings": 138460,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 459632,
    "out:% savings (space heating)": 19.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 150.84,
    "out:Total Energy Use Post PV": 146.5,
    "out:Primary Energy": 150.05,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.41,
    "out:CO2 Operational/m2": 34.72,
    "out:Total CO2/m2": 48.13,
    "out:Total CO2 (tons)": 154.99,
    "out:Klimatpaverkan": -63.84,
    "out:Initial Cost/MSEK": 1.323,
    "out:Running cost/(Apt SEK y)": 38198,
    "out:Running Cost over RSP/MSEK": 10.756,
    "out:LCP/MSEK": 2.446,
    "out:ROI% old": 40.7,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 36,
    "out:Return/kSEK/y": 483,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.45,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.17,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157322,
    "out:DH kr savings": 161000,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 438636,
    "out:% savings (space heating)": 22.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 155.83,
    "out:Total Energy Use Post PV": 151.5,
    "out:Primary Energy": 153.13,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.76,
    "out:CO2 Operational/m2": 36.51,
    "out:Total CO2/m2": 50.27,
    "out:Total CO2 (tons)": 161.88,
    "out:Klimatpaverkan": -56.95,
    "out:Initial Cost/MSEK": 1.379,
    "out:Running cost/(Apt SEK y)": 39547,
    "out:Running Cost over RSP/MSEK": 11.136,
    "out:LCP/MSEK": 2.011,
    "out:ROI% old": 35.11,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 31,
    "out:Return/kSEK/y": 434,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.25,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.37,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141232,
    "out:DH kr savings": 144900,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 455113,
    "out:% savings (space heating)": 19.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 145.5,
    "out:Primary Energy": 147.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.76,
    "out:CO2 Operational/m2": 34.35,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 154.92,
    "out:Klimatpaverkan": -63.91,
    "out:Initial Cost/MSEK": 1.46,
    "out:Running cost/(Apt SEK y)": 37926,
    "out:Running Cost over RSP/MSEK": 10.68,
    "out:LCP/MSEK": 2.386,
    "out:ROI% old": 37.64,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 34,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.46,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.16,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160552,
    "out:DH kr savings": 164220,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 434120,
    "out:% savings (space heating)": 23.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 140.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 155.83,
    "out:Total Energy Use Post PV": 155.85,
    "out:Primary Energy": 160.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.17,
    "out:CO2 Operational/m2": 52.71,
    "out:Total CO2/m2": 57.89,
    "out:Total CO2 (tons)": 186.4,
    "out:Klimatpaverkan": -32.43,
    "out:Initial Cost/MSEK": 0.891,
    "out:Running cost/(Apt SEK y)": 40739,
    "out:Running Cost over RSP/MSEK": 11.463,
    "out:LCP/MSEK": 2.172,
    "out:ROI% old": 49.09,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 44,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98237,
    "out:DH kr savings": 144900,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 453624,
    "out:% savings (space heating)": 20.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 148.83,
    "out:Total Energy Use Post PV": 148.85,
    "out:Primary Energy": 154.36,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.17,
    "out:CO2 Operational/m2": 50.19,
    "out:Total CO2/m2": 55.37,
    "out:Total CO2 (tons)": 178.29,
    "out:Klimatpaverkan": -40.54,
    "out:Initial Cost/MSEK": 0.972,
    "out:Running cost/(Apt SEK y)": 38848,
    "out:Running Cost over RSP/MSEK": 10.93,
    "out:LCP/MSEK": 2.624,
    "out:ROI% old": 52.85,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 47,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120777,
    "out:DH kr savings": 167440,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 432633,
    "out:% savings (space heating)": 23.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 139.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 154.81,
    "out:Total Energy Use Post PV": 154.85,
    "out:Primary Energy": 157.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.52,
    "out:CO2 Operational/m2": 52.35,
    "out:Total CO2/m2": 57.88,
    "out:Total CO2 (tons)": 186.36,
    "out:Klimatpaverkan": -32.47,
    "out:Initial Cost/MSEK": 1.028,
    "out:Running cost/(Apt SEK y)": 40466,
    "out:Running Cost over RSP/MSEK": 11.386,
    "out:LCP/MSEK": 2.112,
    "out:ROI% old": 43.64,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 39,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101457,
    "out:DH kr savings": 148120,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 450096,
    "out:% savings (space heating)": 20.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 147.81,
    "out:Total Energy Use Post PV": 147.85,
    "out:Primary Energy": 152.26,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.52,
    "out:CO2 Operational/m2": 49.83,
    "out:Total CO2/m2": 55.36,
    "out:Total CO2 (tons)": 178.25,
    "out:Klimatpaverkan": -40.58,
    "out:Initial Cost/MSEK": 1.108,
    "out:Running cost/(Apt SEK y)": 38575,
    "out:Running Cost over RSP/MSEK": 10.853,
    "out:LCP/MSEK": 2.564,
    "out:ROI% old": 47.33,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 42,
    "out:Return/kSEK/y": 469,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123997,
    "out:DH kr savings": 170660,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429117,
    "out:% savings (space heating)": 24.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 140.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 155.83,
    "out:Total Energy Use Post PV": 151.5,
    "out:Primary Energy": 152.25,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.41,
    "out:CO2 Operational/m2": 36.51,
    "out:Total CO2/m2": 49.92,
    "out:Total CO2 (tons)": 160.73,
    "out:Klimatpaverkan": -58.1,
    "out:Initial Cost/MSEK": 1.338,
    "out:Running cost/(Apt SEK y)": 39546,
    "out:Running Cost over RSP/MSEK": 11.136,
    "out:LCP/MSEK": 2.052,
    "out:ROI% old": 36.2,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 32,
    "out:Return/kSEK/y": 434,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.49,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141237,
    "out:DH kr savings": 144900,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 453624,
    "out:% savings (space heating)": 20.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 148.83,
    "out:Total Energy Use Post PV": 144.5,
    "out:Primary Energy": 146.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.41,
    "out:CO2 Operational/m2": 33.99,
    "out:Total CO2/m2": 47.4,
    "out:Total CO2 (tons)": 152.62,
    "out:Klimatpaverkan": -66.21,
    "out:Initial Cost/MSEK": 1.418,
    "out:Running cost/(Apt SEK y)": 37655,
    "out:Running Cost over RSP/MSEK": 10.603,
    "out:LCP/MSEK": 2.504,
    "out:ROI% old": 39.51,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 35,
    "out:Return/kSEK/y": 502,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.84,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.78,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163777,
    "out:DH kr savings": 167440,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 432633,
    "out:% savings (space heating)": 23.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 139.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 154.81,
    "out:Total Energy Use Post PV": 150.5,
    "out:Primary Energy": 150.15,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.76,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 49.89,
    "out:Total CO2 (tons)": 160.64,
    "out:Klimatpaverkan": -58.19,
    "out:Initial Cost/MSEK": 1.474,
    "out:Running cost/(Apt SEK y)": 39273,
    "out:Running Cost over RSP/MSEK": 11.059,
    "out:LCP/MSEK": 1.993,
    "out:ROI% old": 33.59,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 30,
    "out:Return/kSEK/y": 444,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.03,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.59,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144473,
    "out:DH kr savings": 148120,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 450096,
    "out:% savings (space heating)": 20.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.45,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 147.81,
    "out:Total Energy Use Post PV": 143.5,
    "out:Primary Energy": 144.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.76,
    "out:CO2 Operational/m2": 33.61,
    "out:Total CO2/m2": 47.37,
    "out:Total CO2 (tons)": 152.52,
    "out:Klimatpaverkan": -66.31,
    "out:Initial Cost/MSEK": 1.555,
    "out:Running cost/(Apt SEK y)": 37382,
    "out:Running Cost over RSP/MSEK": 10.526,
    "out:LCP/MSEK": 2.445,
    "out:ROI% old": 36.75,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 33,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.61,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167014,
    "out:DH kr savings": 170660,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429117,
    "out:% savings (space heating)": 24.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 142.93,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.76,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 152.6,
    "out:Total Energy Use Post PV": 152.6,
    "out:Primary Energy": 149.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.43,
    "out:CO2 Operational/m2": 53.2,
    "out:Total CO2/m2": 57.63,
    "out:Total CO2 (tons)": 185.57,
    "out:Klimatpaverkan": -33.26,
    "out:Initial Cost/MSEK": 0.793,
    "out:Running cost/(Apt SEK y)": 40527,
    "out:Running Cost over RSP/MSEK": 11.407,
    "out:LCP/MSEK": 2.326,
    "out:ROI% old": 56.19,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 26.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 50,
    "out:Return/kSEK/y": 399,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132020,
    "out:DH kr savings": 132020,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437142,
    "out:% savings (space heating)": 23.03,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.86,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 146.6,
    "out:Primary Energy": 144.81,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.43,
    "out:CO2 Operational/m2": 51.04,
    "out:Total CO2/m2": 55.47,
    "out:Total CO2 (tons)": 178.62,
    "out:Klimatpaverkan": -40.21,
    "out:Initial Cost/MSEK": 0.873,
    "out:Running cost/(Apt SEK y)": 38907,
    "out:Running Cost over RSP/MSEK": 10.951,
    "out:LCP/MSEK": 2.702,
    "out:ROI% old": 58.48,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 52,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151340,
    "out:DH kr savings": 151340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418150,
    "out:% savings (space heating)": 26.38,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.76,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 151.6,
    "out:Primary Energy": 147.34,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.78,
    "out:CO2 Operational/m2": 52.84,
    "out:Total CO2/m2": 57.62,
    "out:Total CO2 (tons)": 185.54,
    "out:Klimatpaverkan": -33.29,
    "out:Initial Cost/MSEK": 0.929,
    "out:Running cost/(Apt SEK y)": 40257,
    "out:Running Cost over RSP/MSEK": 11.331,
    "out:LCP/MSEK": 2.265,
    "out:ROI% old": 49.1,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 44,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135240,
    "out:DH kr savings": 135240,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437142,
    "out:% savings (space heating)": 23.03,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.86,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 145.6,
    "out:Primary Energy": 142.18,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.78,
    "out:CO2 Operational/m2": 50.68,
    "out:Total CO2/m2": 55.46,
    "out:Total CO2 (tons)": 178.58,
    "out:Klimatpaverkan": -40.25,
    "out:Initial Cost/MSEK": 1.01,
    "out:Running cost/(Apt SEK y)": 38636,
    "out:Running Cost over RSP/MSEK": 10.875,
    "out:LCP/MSEK": 2.641,
    "out:ROI% old": 51.64,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 46,
    "out:Return/kSEK/y": 467,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154560,
    "out:DH kr savings": 154560,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418150,
    "out:% savings (space heating)": 26.38,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.76,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 152.6,
    "out:Total Energy Use Post PV": 149.91,
    "out:Primary Energy": 145.13,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 28.72,
    "out:Total CO2/m2": 41.39,
    "out:Total CO2 (tons)": 133.27,
    "out:Klimatpaverkan": -85.56,
    "out:Initial Cost/MSEK": 1.239,
    "out:Running cost/(Apt SEK y)": 39407,
    "out:Running Cost over RSP/MSEK": 11.101,
    "out:LCP/MSEK": 2.186,
    "out:ROI% old": 39.48,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 35,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172336,
    "out:DH kr savings": 132020,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437142,
    "out:% savings (space heating)": 23.03,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.86,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 143.91,
    "out:Primary Energy": 139.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 26.56,
    "out:Total CO2/m2": 39.23,
    "out:Total CO2 (tons)": 126.31,
    "out:Klimatpaverkan": -92.52,
    "out:Initial Cost/MSEK": 1.32,
    "out:Running cost/(Apt SEK y)": 37787,
    "out:Running Cost over RSP/MSEK": 10.644,
    "out:LCP/MSEK": 2.562,
    "out:ROI% old": 42.01,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 38,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 151340,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418150,
    "out:% savings (space heating)": 26.38,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.76,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 148.91,
    "out:Primary Energy": 142.51,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.02,
    "out:CO2 Operational/m2": 28.36,
    "out:Total CO2/m2": 41.38,
    "out:Total CO2 (tons)": 133.24,
    "out:Klimatpaverkan": -85.59,
    "out:Initial Cost/MSEK": 1.376,
    "out:Running cost/(Apt SEK y)": 39137,
    "out:Running Cost over RSP/MSEK": 11.024,
    "out:LCP/MSEK": 2.125,
    "out:ROI% old": 36.35,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175556,
    "out:DH kr savings": 135240,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437142,
    "out:% savings (space heating)": 23.03,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.86,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 142.91,
    "out:Primary Energy": 137.35,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.02,
    "out:CO2 Operational/m2": 26.2,
    "out:Total CO2/m2": 39.22,
    "out:Total CO2 (tons)": 126.28,
    "out:Klimatpaverkan": -92.55,
    "out:Initial Cost/MSEK": 1.457,
    "out:Running cost/(Apt SEK y)": 37517,
    "out:Running Cost over RSP/MSEK": 10.568,
    "out:LCP/MSEK": 2.501,
    "out:ROI% old": 38.82,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 35,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194876,
    "out:DH kr savings": 154560,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418150,
    "out:% savings (space heating)": 26.38,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.76,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 150.6,
    "out:Primary Energy": 146.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.43,
    "out:CO2 Operational/m2": 52.48,
    "out:Total CO2/m2": 56.91,
    "out:Total CO2 (tons)": 183.25,
    "out:Klimatpaverkan": -35.58,
    "out:Initial Cost/MSEK": 0.888,
    "out:Running cost/(Apt SEK y)": 39987,
    "out:Running Cost over RSP/MSEK": 11.255,
    "out:LCP/MSEK": 2.383,
    "out:ROI% old": 52.63,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 47,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138460,
    "out:DH kr savings": 138460,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437142,
    "out:% savings (space heating)": 23.03,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.86,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 145.6,
    "out:Primary Energy": 141.31,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.43,
    "out:CO2 Operational/m2": 50.68,
    "out:Total CO2/m2": 55.11,
    "out:Total CO2 (tons)": 177.46,
    "out:Klimatpaverkan": -41.37,
    "out:Initial Cost/MSEK": 0.968,
    "out:Running cost/(Apt SEK y)": 38636,
    "out:Running Cost over RSP/MSEK": 10.875,
    "out:LCP/MSEK": 2.683,
    "out:ROI% old": 53.86,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 48,
    "out:Return/kSEK/y": 467,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154560,
    "out:DH kr savings": 154560,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418150,
    "out:% savings (space heating)": 26.38,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.76,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 149.6,
    "out:Primary Energy": 144.37,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.78,
    "out:CO2 Operational/m2": 52.12,
    "out:Total CO2/m2": 56.9,
    "out:Total CO2 (tons)": 183.22,
    "out:Klimatpaverkan": -35.61,
    "out:Initial Cost/MSEK": 1.024,
    "out:Running cost/(Apt SEK y)": 39717,
    "out:Running Cost over RSP/MSEK": 11.179,
    "out:LCP/MSEK": 2.322,
    "out:ROI% old": 46.67,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 42,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141680,
    "out:DH kr savings": 141680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437142,
    "out:% savings (space heating)": 23.03,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.86,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 143.6,
    "out:Primary Energy": 139.21,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.78,
    "out:CO2 Operational/m2": 49.96,
    "out:Total CO2/m2": 54.74,
    "out:Total CO2 (tons)": 176.27,
    "out:Klimatpaverkan": -42.56,
    "out:Initial Cost/MSEK": 1.105,
    "out:Running cost/(Apt SEK y)": 38096,
    "out:Running Cost over RSP/MSEK": 10.723,
    "out:LCP/MSEK": 2.698,
    "out:ROI% old": 49.17,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 44,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161000,
    "out:DH kr savings": 161000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418150,
    "out:% savings (space heating)": 26.38,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.76,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 147.91,
    "out:Primary Energy": 141.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 28,
    "out:Total CO2/m2": 40.67,
    "out:Total CO2 (tons)": 130.95,
    "out:Klimatpaverkan": -87.88,
    "out:Initial Cost/MSEK": 1.334,
    "out:Running cost/(Apt SEK y)": 38867,
    "out:Running Cost over RSP/MSEK": 10.948,
    "out:LCP/MSEK": 2.243,
    "out:ROI% old": 38.3,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 34,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178776,
    "out:DH kr savings": 138460,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437142,
    "out:% savings (space heating)": 23.03,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.86,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 142.91,
    "out:Primary Energy": 136.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.67,
    "out:CO2 Operational/m2": 26.2,
    "out:Total CO2/m2": 38.87,
    "out:Total CO2 (tons)": 125.15,
    "out:Klimatpaverkan": -93.68,
    "out:Initial Cost/MSEK": 1.415,
    "out:Running cost/(Apt SEK y)": 37517,
    "out:Running Cost over RSP/MSEK": 10.568,
    "out:LCP/MSEK": 2.543,
    "out:ROI% old": 39.96,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 36,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194876,
    "out:DH kr savings": 154560,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418150,
    "out:% savings (space heating)": 26.38,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.76,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 146.91,
    "out:Primary Energy": 139.53,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.02,
    "out:CO2 Operational/m2": 27.64,
    "out:Total CO2/m2": 40.66,
    "out:Total CO2 (tons)": 130.92,
    "out:Klimatpaverkan": -87.91,
    "out:Initial Cost/MSEK": 1.471,
    "out:Running cost/(Apt SEK y)": 38597,
    "out:Running Cost over RSP/MSEK": 10.872,
    "out:LCP/MSEK": 2.182,
    "out:ROI% old": 35.48,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 32,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181996,
    "out:DH kr savings": 141680,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437142,
    "out:% savings (space heating)": 23.03,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.86,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 140.91,
    "out:Primary Energy": 134.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 13.02,
    "out:CO2 Operational/m2": 25.48,
    "out:Total CO2/m2": 38.5,
    "out:Total CO2 (tons)": 123.96,
    "out:Klimatpaverkan": -94.87,
    "out:Initial Cost/MSEK": 1.552,
    "out:Running cost/(Apt SEK y)": 36976,
    "out:Running Cost over RSP/MSEK": 10.416,
    "out:LCP/MSEK": 2.558,
    "out:ROI% old": 37.84,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 34,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201316,
    "out:DH kr savings": 161000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418150,
    "out:% savings (space heating)": 26.38,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 143.84,
    "out:Total Energy Use Post PV": 143.85,
    "out:Primary Energy": 151.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.69,
    "out:CO2 Operational/m2": 48.4,
    "out:Total CO2/m2": 56.09,
    "out:Total CO2 (tons)": 180.61,
    "out:Klimatpaverkan": -38.22,
    "out:Initial Cost/MSEK": 1.404,
    "out:Running cost/(Apt SEK y)": 37501,
    "out:Running Cost over RSP/MSEK": 10.551,
    "out:LCP/MSEK": 2.571,
    "out:ROI% old": 40.46,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 36,
    "out:Return/kSEK/y": 508,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136877,
    "out:DH kr savings": 183540,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 415534,
    "out:% savings (space heating)": 26.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 137.84,
    "out:Total Energy Use Post PV": 137.85,
    "out:Primary Energy": 146.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.69,
    "out:CO2 Operational/m2": 46.24,
    "out:Total CO2/m2": 53.93,
    "out:Total CO2 (tons)": 173.65,
    "out:Klimatpaverkan": -45.18,
    "out:Initial Cost/MSEK": 1.484,
    "out:Running cost/(Apt SEK y)": 35880,
    "out:Running Cost over RSP/MSEK": 10.095,
    "out:LCP/MSEK": 2.947,
    "out:ROI% old": 42.66,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 38,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156197,
    "out:DH kr savings": 202860,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 396542,
    "out:% savings (space heating)": 30.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 142.85,
    "out:Primary Energy": 148.96,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.04,
    "out:CO2 Operational/m2": 48.04,
    "out:Total CO2/m2": 56.08,
    "out:Total CO2 (tons)": 180.57,
    "out:Klimatpaverkan": -38.26,
    "out:Initial Cost/MSEK": 1.54,
    "out:Running cost/(Apt SEK y)": 37229,
    "out:Running Cost over RSP/MSEK": 10.474,
    "out:LCP/MSEK": 2.511,
    "out:ROI% old": 37.58,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 34,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140097,
    "out:DH kr savings": 186760,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411018,
    "out:% savings (space heating)": 27.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 136.83,
    "out:Total Energy Use Post PV": 136.85,
    "out:Primary Energy": 143.8,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.04,
    "out:CO2 Operational/m2": 45.88,
    "out:Total CO2/m2": 53.92,
    "out:Total CO2 (tons)": 173.62,
    "out:Klimatpaverkan": -45.21,
    "out:Initial Cost/MSEK": 1.621,
    "out:Running cost/(Apt SEK y)": 35608,
    "out:Running Cost over RSP/MSEK": 10.018,
    "out:LCP/MSEK": 2.887,
    "out:ROI% old": 39.74,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 36,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159417,
    "out:DH kr savings": 206080,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 392030,
    "out:% savings (space heating)": 30.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 143.84,
    "out:Total Energy Use Post PV": 139.5,
    "out:Primary Energy": 143.77,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.93,
    "out:CO2 Operational/m2": 32.2,
    "out:Total CO2/m2": 48.13,
    "out:Total CO2 (tons)": 154.99,
    "out:Klimatpaverkan": -63.84,
    "out:Initial Cost/MSEK": 1.85,
    "out:Running cost/(Apt SEK y)": 36307,
    "out:Running Cost over RSP/MSEK": 10.224,
    "out:LCP/MSEK": 2.452,
    "out:ROI% old": 33.22,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.48,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.14,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179862,
    "out:DH kr savings": 183540,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 415534,
    "out:% savings (space heating)": 26.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 137.84,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 138.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.93,
    "out:CO2 Operational/m2": 30.04,
    "out:Total CO2/m2": 45.97,
    "out:Total CO2 (tons)": 148.03,
    "out:Klimatpaverkan": -70.8,
    "out:Initial Cost/MSEK": 1.931,
    "out:Running cost/(Apt SEK y)": 34686,
    "out:Running Cost over RSP/MSEK": 9.767,
    "out:LCP/MSEK": 2.828,
    "out:ROI% old": 35.21,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 32,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.5,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199182,
    "out:DH kr savings": 202860,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 396542,
    "out:% savings (space heating)": 30.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.65,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 141.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 16.28,
    "out:CO2 Operational/m2": 31.83,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 154.92,
    "out:Klimatpaverkan": -63.91,
    "out:Initial Cost/MSEK": 1.987,
    "out:Running cost/(Apt SEK y)": 36035,
    "out:Running Cost over RSP/MSEK": 10.147,
    "out:LCP/MSEK": 2.392,
    "out:ROI% old": 31.49,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 28,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.49,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183092,
    "out:DH kr savings": 186760,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411018,
    "out:% savings (space heating)": 27.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 136.83,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 135.98,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 16.28,
    "out:CO2 Operational/m2": 29.67,
    "out:Total CO2/m2": 45.95,
    "out:Total CO2 (tons)": 147.96,
    "out:Klimatpaverkan": -70.87,
    "out:Initial Cost/MSEK": 2.067,
    "out:Running cost/(Apt SEK y)": 34414,
    "out:Running Cost over RSP/MSEK": 9.691,
    "out:LCP/MSEK": 2.768,
    "out:ROI% old": 33.42,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 30,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.7,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13972.92,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202413,
    "out:DH kr savings": 206080,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 392030,
    "out:% savings (space heating)": 30.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 141.83,
    "out:Total Energy Use Post PV": 141.85,
    "out:Primary Energy": 148.08,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.69,
    "out:CO2 Operational/m2": 47.68,
    "out:Total CO2/m2": 55.37,
    "out:Total CO2 (tons)": 178.28,
    "out:Klimatpaverkan": -40.55,
    "out:Initial Cost/MSEK": 1.499,
    "out:Running cost/(Apt SEK y)": 36958,
    "out:Running Cost over RSP/MSEK": 10.398,
    "out:LCP/MSEK": 2.629,
    "out:ROI% old": 39.35,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 35,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143317,
    "out:DH kr savings": 189980,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409530,
    "out:% savings (space heating)": 27.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 135.82,
    "out:Total Energy Use Post PV": 135.85,
    "out:Primary Energy": 142.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.69,
    "out:CO2 Operational/m2": 45.52,
    "out:Total CO2/m2": 53.21,
    "out:Total CO2 (tons)": 171.33,
    "out:Klimatpaverkan": -47.5,
    "out:Initial Cost/MSEK": 1.579,
    "out:Running cost/(Apt SEK y)": 35337,
    "out:Running Cost over RSP/MSEK": 9.942,
    "out:LCP/MSEK": 3.005,
    "out:ROI% old": 41.48,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 37,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162637,
    "out:DH kr savings": 209300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 390544,
    "out:% savings (space heating)": 31.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 140.81,
    "out:Total Energy Use Post PV": 140.85,
    "out:Primary Energy": 145.98,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.04,
    "out:CO2 Operational/m2": 47.31,
    "out:Total CO2/m2": 55.36,
    "out:Total CO2 (tons)": 178.24,
    "out:Klimatpaverkan": -40.59,
    "out:Initial Cost/MSEK": 1.635,
    "out:Running cost/(Apt SEK y)": 36684,
    "out:Running Cost over RSP/MSEK": 10.321,
    "out:LCP/MSEK": 2.57,
    "out:ROI% old": 36.74,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 33,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146537,
    "out:DH kr savings": 193200,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 406009,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 134.81,
    "out:Total Energy Use Post PV": 134.85,
    "out:Primary Energy": 140.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.04,
    "out:CO2 Operational/m2": 45.15,
    "out:Total CO2/m2": 53.2,
    "out:Total CO2 (tons)": 171.29,
    "out:Klimatpaverkan": -47.54,
    "out:Initial Cost/MSEK": 1.716,
    "out:Running cost/(Apt SEK y)": 35063,
    "out:Running Cost over RSP/MSEK": 9.865,
    "out:LCP/MSEK": 2.946,
    "out:ROI% old": 38.81,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1036.4,
    "out:Return %": 35,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165857,
    "out:DH kr savings": 212520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 387035,
    "out:% savings (space heating)": 31.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 141.83,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 140.27,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.93,
    "out:CO2 Operational/m2": 31.47,
    "out:Total CO2/m2": 47.39,
    "out:Total CO2 (tons)": 152.61,
    "out:Klimatpaverkan": -66.22,
    "out:Initial Cost/MSEK": 1.945,
    "out:Running cost/(Apt SEK y)": 35764,
    "out:Running Cost over RSP/MSEK": 10.071,
    "out:LCP/MSEK": 2.51,
    "out:ROI% old": 32.72,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 29,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.82,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.79,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186317,
    "out:DH kr savings": 189980,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409530,
    "out:% savings (space heating)": 27.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 135.82,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 135.11,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.93,
    "out:CO2 Operational/m2": 29.31,
    "out:Total CO2/m2": 45.23,
    "out:Total CO2 (tons)": 145.65,
    "out:Klimatpaverkan": -73.18,
    "out:Initial Cost/MSEK": 2.026,
    "out:Running cost/(Apt SEK y)": 34143,
    "out:Running Cost over RSP/MSEK": 9.614,
    "out:LCP/MSEK": 2.886,
    "out:ROI% old": 34.64,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 31,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.18,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.43,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205638,
    "out:DH kr savings": 209300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 390544,
    "out:% savings (space heating)": 31.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.09,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 140.81,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 138.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 16.28,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 47.37,
    "out:Total CO2 (tons)": 152.52,
    "out:Klimatpaverkan": -66.31,
    "out:Initial Cost/MSEK": 2.082,
    "out:Running cost/(Apt SEK y)": 35491,
    "out:Running Cost over RSP/MSEK": 9.994,
    "out:LCP/MSEK": 2.45,
    "out:ROI% old": 31.1,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 28,
    "out:Return/kSEK/y": 580,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.73,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.88,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189554,
    "out:DH kr savings": 193200,
    "out:El kr savings": -18698,
    "out:El kr sold": 15052,
    "out:El kr saved": 27918,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 406009,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.76,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 134.81,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 133.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.28,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 45.2,
    "out:Total CO2 (tons)": 145.56,
    "out:Klimatpaverkan": -73.27,
    "out:Initial Cost/MSEK": 2.162,
    "out:Running cost/(Apt SEK y)": 33870,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": 2.826,
    "out:ROI% old": 32.96,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 48.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 30,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.75,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.87,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208875,
    "out:DH kr savings": 212520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27916,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 387035,
    "out:% savings (space heating)": 31.85,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 129.86,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.64,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 149.6,
    "out:Primary Energy": 147.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.34,
    "out:CO2 Operational/m2": 52.12,
    "out:Total CO2/m2": 63.46,
    "out:Total CO2 (tons)": 204.33,
    "out:Klimatpaverkan": -14.5,
    "out:Initial Cost/MSEK": 2.28,
    "out:Running cost/(Apt SEK y)": 39717,
    "out:Running Cost over RSP/MSEK": 11.179,
    "out:LCP/MSEK": 1.067,
    "out:ROI% old": 20.97,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141680,
    "out:DH kr savings": 141680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427093,
    "out:% savings (space heating)": 24.8,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.88,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 143.6,
    "out:Primary Energy": 142.2,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.34,
    "out:CO2 Operational/m2": 49.96,
    "out:Total CO2/m2": 61.3,
    "out:Total CO2 (tons)": 197.38,
    "out:Klimatpaverkan": -21.45,
    "out:Initial Cost/MSEK": 2.36,
    "out:Running cost/(Apt SEK y)": 38096,
    "out:Running Cost over RSP/MSEK": 10.723,
    "out:LCP/MSEK": 1.443,
    "out:ROI% old": 23.02,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161000,
    "out:DH kr savings": 161000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408558,
    "out:% savings (space heating)": 28.07,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.64,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 148.6,
    "out:Total Energy Use Post PV": 148.6,
    "out:Primary Energy": 144.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.69,
    "out:CO2 Operational/m2": 51.76,
    "out:Total CO2/m2": 63.45,
    "out:Total CO2 (tons)": 204.3,
    "out:Klimatpaverkan": -14.53,
    "out:Initial Cost/MSEK": 2.416,
    "out:Running cost/(Apt SEK y)": 39447,
    "out:Running Cost over RSP/MSEK": 11.103,
    "out:LCP/MSEK": 1.007,
    "out:ROI% old": 20.24,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 438,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144900,
    "out:DH kr savings": 144900,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427093,
    "out:% savings (space heating)": 24.8,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.88,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 142.6,
    "out:Primary Energy": 139.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.69,
    "out:CO2 Operational/m2": 49.6,
    "out:Total CO2/m2": 61.29,
    "out:Total CO2 (tons)": 197.34,
    "out:Klimatpaverkan": -21.49,
    "out:Initial Cost/MSEK": 2.497,
    "out:Running cost/(Apt SEK y)": 37826,
    "out:Running Cost over RSP/MSEK": 10.647,
    "out:LCP/MSEK": 1.382,
    "out:ROI% old": 22.2,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 20,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164220,
    "out:DH kr savings": 164220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408558,
    "out:% savings (space heating)": 28.07,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.64,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 146.91,
    "out:Primary Energy": 142.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.57,
    "out:CO2 Operational/m2": 27.64,
    "out:Total CO2/m2": 47.21,
    "out:Total CO2 (tons)": 152.03,
    "out:Klimatpaverkan": -66.8,
    "out:Initial Cost/MSEK": 2.726,
    "out:Running cost/(Apt SEK y)": 38597,
    "out:Running Cost over RSP/MSEK": 10.872,
    "out:LCP/MSEK": 0.927,
    "out:ROI% old": 19.14,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181996,
    "out:DH kr savings": 141680,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427093,
    "out:% savings (space heating)": 24.8,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.88,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 140.91,
    "out:Primary Energy": 137.37,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.57,
    "out:CO2 Operational/m2": 25.48,
    "out:Total CO2/m2": 45.05,
    "out:Total CO2 (tons)": 145.07,
    "out:Klimatpaverkan": -73.76,
    "out:Initial Cost/MSEK": 2.807,
    "out:Running cost/(Apt SEK y)": 36976,
    "out:Running Cost over RSP/MSEK": 10.416,
    "out:LCP/MSEK": 1.303,
    "out:ROI% old": 20.92,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201316,
    "out:DH kr savings": 161000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408558,
    "out:% savings (space heating)": 28.07,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.64,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 148.6,
    "out:Total Energy Use Post PV": 145.91,
    "out:Primary Energy": 139.78,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.92,
    "out:CO2 Operational/m2": 27.28,
    "out:Total CO2/m2": 47.2,
    "out:Total CO2 (tons)": 152,
    "out:Klimatpaverkan": -66.83,
    "out:Initial Cost/MSEK": 2.863,
    "out:Running cost/(Apt SEK y)": 38327,
    "out:Running Cost over RSP/MSEK": 10.796,
    "out:LCP/MSEK": 0.867,
    "out:ROI% old": 18.61,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185216,
    "out:DH kr savings": 144900,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427093,
    "out:% savings (space heating)": 24.8,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.88,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 139.91,
    "out:Primary Energy": 134.74,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.92,
    "out:CO2 Operational/m2": 25.12,
    "out:Total CO2/m2": 45.04,
    "out:Total CO2 (tons)": 145.04,
    "out:Klimatpaverkan": -73.79,
    "out:Initial Cost/MSEK": 2.943,
    "out:Running cost/(Apt SEK y)": 36706,
    "out:Running Cost over RSP/MSEK": 10.34,
    "out:LCP/MSEK": 1.243,
    "out:ROI% old": 20.32,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204536,
    "out:DH kr savings": 164220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408558,
    "out:% savings (space heating)": 28.07,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.64,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 147.6,
    "out:Primary Energy": 143.74,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.34,
    "out:CO2 Operational/m2": 51.4,
    "out:Total CO2/m2": 62.74,
    "out:Total CO2 (tons)": 202.01,
    "out:Klimatpaverkan": -16.82,
    "out:Initial Cost/MSEK": 2.375,
    "out:Running cost/(Apt SEK y)": 39177,
    "out:Running Cost over RSP/MSEK": 11.027,
    "out:LCP/MSEK": 1.124,
    "out:ROI% old": 21.05,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148120,
    "out:DH kr savings": 148120,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427093,
    "out:% savings (space heating)": 24.8,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.88,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 142.6,
    "out:Primary Energy": 138.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.34,
    "out:CO2 Operational/m2": 49.6,
    "out:Total CO2/m2": 60.94,
    "out:Total CO2 (tons)": 196.22,
    "out:Klimatpaverkan": -22.61,
    "out:Initial Cost/MSEK": 2.455,
    "out:Running cost/(Apt SEK y)": 37826,
    "out:Running Cost over RSP/MSEK": 10.647,
    "out:LCP/MSEK": 1.424,
    "out:ROI% old": 22.57,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 20,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164220,
    "out:DH kr savings": 164220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408558,
    "out:% savings (space heating)": 28.07,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.64,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 146.6,
    "out:Primary Energy": 141.64,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.69,
    "out:CO2 Operational/m2": 51.04,
    "out:Total CO2/m2": 62.73,
    "out:Total CO2 (tons)": 201.98,
    "out:Klimatpaverkan": -16.85,
    "out:Initial Cost/MSEK": 2.511,
    "out:Running cost/(Apt SEK y)": 38907,
    "out:Running Cost over RSP/MSEK": 10.951,
    "out:LCP/MSEK": 1.064,
    "out:ROI% old": 20.34,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151340,
    "out:DH kr savings": 151340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427093,
    "out:% savings (space heating)": 24.8,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.88,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 140.6,
    "out:Primary Energy": 136.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.69,
    "out:CO2 Operational/m2": 48.88,
    "out:Total CO2/m2": 60.57,
    "out:Total CO2 (tons)": 195.03,
    "out:Klimatpaverkan": -23.8,
    "out:Initial Cost/MSEK": 2.592,
    "out:Running cost/(Apt SEK y)": 37286,
    "out:Running Cost over RSP/MSEK": 10.495,
    "out:LCP/MSEK": 1.44,
    "out:ROI% old": 22.22,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 20,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 170660,
    "out:DH kr savings": 170660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408558,
    "out:% savings (space heating)": 28.07,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00002200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.64,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 144.91,
    "out:Primary Energy": 138.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.57,
    "out:CO2 Operational/m2": 26.92,
    "out:Total CO2/m2": 46.49,
    "out:Total CO2 (tons)": 149.71,
    "out:Klimatpaverkan": -69.12,
    "out:Initial Cost/MSEK": 2.821,
    "out:Running cost/(Apt SEK y)": 38057,
    "out:Running Cost over RSP/MSEK": 10.72,
    "out:LCP/MSEK": 0.984,
    "out:ROI% old": 19.27,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188436,
    "out:DH kr savings": 148120,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427093,
    "out:% savings (space heating)": 24.8,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.88,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 139.91,
    "out:Primary Energy": 133.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.57,
    "out:CO2 Operational/m2": 25.12,
    "out:Total CO2/m2": 44.69,
    "out:Total CO2 (tons)": 143.91,
    "out:Klimatpaverkan": -74.92,
    "out:Initial Cost/MSEK": 2.902,
    "out:Running cost/(Apt SEK y)": 36706,
    "out:Running Cost over RSP/MSEK": 10.34,
    "out:LCP/MSEK": 1.284,
    "out:ROI% old": 20.61,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204536,
    "out:DH kr savings": 164220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408558,
    "out:% savings (space heating)": 28.07,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.64,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 143.91,
    "out:Primary Energy": 136.8,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.92,
    "out:CO2 Operational/m2": 26.56,
    "out:Total CO2/m2": 46.48,
    "out:Total CO2 (tons)": 149.68,
    "out:Klimatpaverkan": -69.15,
    "out:Initial Cost/MSEK": 2.958,
    "out:Running cost/(Apt SEK y)": 37787,
    "out:Running Cost over RSP/MSEK": 10.644,
    "out:LCP/MSEK": 0.924,
    "out:ROI% old": 18.75,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 151340,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427093,
    "out:% savings (space heating)": 24.8,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.88,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 137.91,
    "out:Primary Energy": 131.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.92,
    "out:CO2 Operational/m2": 24.4,
    "out:Total CO2/m2": 44.32,
    "out:Total CO2 (tons)": 142.72,
    "out:Klimatpaverkan": -76.11,
    "out:Initial Cost/MSEK": 3.038,
    "out:Running cost/(Apt SEK y)": 36166,
    "out:Running Cost over RSP/MSEK": 10.188,
    "out:LCP/MSEK": 1.3,
    "out:ROI% old": 20.4,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 210976,
    "out:DH kr savings": 170660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408558,
    "out:% savings (space heating)": 28.07,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00002201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 140.84,
    "out:Total Energy Use Post PV": 140.85,
    "out:Primary Energy": 148.85,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.6,
    "out:CO2 Operational/m2": 47.32,
    "out:Total CO2/m2": 61.92,
    "out:Total CO2 (tons)": 199.37,
    "out:Klimatpaverkan": -19.46,
    "out:Initial Cost/MSEK": 2.89,
    "out:Running cost/(Apt SEK y)": 36691,
    "out:Running Cost over RSP/MSEK": 10.323,
    "out:LCP/MSEK": 1.313,
    "out:ROI% old": 20.77,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 19,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146537,
    "out:DH kr savings": 193200,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 405484,
    "out:% savings (space heating)": 28.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 134.84,
    "out:Total Energy Use Post PV": 134.85,
    "out:Primary Energy": 143.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.6,
    "out:CO2 Operational/m2": 45.16,
    "out:Total CO2/m2": 59.76,
    "out:Total CO2 (tons)": 192.41,
    "out:Klimatpaverkan": -26.42,
    "out:Initial Cost/MSEK": 2.971,
    "out:Running cost/(Apt SEK y)": 35070,
    "out:Running Cost over RSP/MSEK": 9.866,
    "out:LCP/MSEK": 1.689,
    "out:ROI% old": 22.41,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 20,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165857,
    "out:DH kr savings": 212520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 386949,
    "out:% savings (space heating)": 31.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 139.85,
    "out:Primary Energy": 146.23,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.95,
    "out:CO2 Operational/m2": 46.96,
    "out:Total CO2/m2": 61.9,
    "out:Total CO2 (tons)": 199.33,
    "out:Klimatpaverkan": -19.5,
    "out:Initial Cost/MSEK": 3.027,
    "out:Running cost/(Apt SEK y)": 36418,
    "out:Running Cost over RSP/MSEK": 10.246,
    "out:LCP/MSEK": 1.253,
    "out:ROI% old": 20.2,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 18,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149757,
    "out:DH kr savings": 196420,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 400964,
    "out:% savings (space heating)": 29.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 133.85,
    "out:Primary Energy": 141.19,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.95,
    "out:CO2 Operational/m2": 44.8,
    "out:Total CO2/m2": 59.74,
    "out:Total CO2 (tons)": 192.38,
    "out:Klimatpaverkan": -26.45,
    "out:Initial Cost/MSEK": 3.108,
    "out:Running cost/(Apt SEK y)": 34798,
    "out:Running Cost over RSP/MSEK": 9.79,
    "out:LCP/MSEK": 1.629,
    "out:ROI% old": 21.77,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169077,
    "out:DH kr savings": 215740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 382433,
    "out:% savings (space heating)": 32.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 140.84,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 141.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.83,
    "out:CO2 Operational/m2": 31.13,
    "out:Total CO2/m2": 53.96,
    "out:Total CO2 (tons)": 173.75,
    "out:Klimatpaverkan": -45.08,
    "out:Initial Cost/MSEK": 3.337,
    "out:Running cost/(Apt SEK y)": 35497,
    "out:Running Cost over RSP/MSEK": 9.996,
    "out:LCP/MSEK": 1.193,
    "out:ROI% old": 19.4,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 580,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.41,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189522,
    "out:DH kr savings": 193200,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 405484,
    "out:% savings (space heating)": 28.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 134.84,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 136,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.83,
    "out:CO2 Operational/m2": 28.97,
    "out:Total CO2/m2": 51.8,
    "out:Total CO2 (tons)": 166.79,
    "out:Klimatpaverkan": -52.04,
    "out:Initial Cost/MSEK": 3.417,
    "out:Running cost/(Apt SEK y)": 33876,
    "out:Running Cost over RSP/MSEK": 9.539,
    "out:LCP/MSEK": 1.569,
    "out:ROI% old": 20.85,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 48.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.42,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208842,
    "out:DH kr savings": 212520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 386949,
    "out:% savings (space heating)": 31.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 138.41,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 23.18,
    "out:CO2 Operational/m2": 30.75,
    "out:Total CO2/m2": 53.94,
    "out:Total CO2 (tons)": 173.68,
    "out:Klimatpaverkan": -45.15,
    "out:Initial Cost/MSEK": 3.474,
    "out:Running cost/(Apt SEK y)": 35225,
    "out:Running Cost over RSP/MSEK": 9.919,
    "out:LCP/MSEK": 1.133,
    "out:ROI% old": 18.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 17,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.2,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.42,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192752,
    "out:DH kr savings": 196420,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 400964,
    "out:% savings (space heating)": 29.4,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.77,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 133.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.18,
    "out:CO2 Operational/m2": 28.59,
    "out:Total CO2/m2": 51.78,
    "out:Total CO2 (tons)": 166.72,
    "out:Klimatpaverkan": -52.11,
    "out:Initial Cost/MSEK": 3.554,
    "out:Running cost/(Apt SEK y)": 33604,
    "out:Running Cost over RSP/MSEK": 9.463,
    "out:LCP/MSEK": 1.509,
    "out:ROI% old": 20.35,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 648,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.41,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212072,
    "out:DH kr savings": 215740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 382433,
    "out:% savings (space heating)": 32.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 138.83,
    "out:Total Energy Use Post PV": 138.85,
    "out:Primary Energy": 145.35,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.6,
    "out:CO2 Operational/m2": 46.6,
    "out:Total CO2/m2": 61.19,
    "out:Total CO2 (tons)": 197.04,
    "out:Klimatpaverkan": -21.79,
    "out:Initial Cost/MSEK": 2.985,
    "out:Running cost/(Apt SEK y)": 36147,
    "out:Running Cost over RSP/MSEK": 10.17,
    "out:LCP/MSEK": 1.371,
    "out:ROI% old": 20.84,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152977,
    "out:DH kr savings": 199640,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 399475,
    "out:% savings (space heating)": 29.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 132.83,
    "out:Total Energy Use Post PV": 132.85,
    "out:Primary Energy": 140.32,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.6,
    "out:CO2 Operational/m2": 44.44,
    "out:Total CO2/m2": 59.03,
    "out:Total CO2 (tons)": 190.09,
    "out:Klimatpaverkan": -28.74,
    "out:Initial Cost/MSEK": 3.066,
    "out:Running cost/(Apt SEK y)": 34527,
    "out:Running Cost over RSP/MSEK": 9.713,
    "out:LCP/MSEK": 1.747,
    "out:ROI% old": 22.42,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 20,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172297,
    "out:DH kr savings": 218960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 380946,
    "out:% savings (space heating)": 32.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 137.81,
    "out:Total Energy Use Post PV": 137.85,
    "out:Primary Energy": 143.25,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.95,
    "out:CO2 Operational/m2": 46.23,
    "out:Total CO2/m2": 61.18,
    "out:Total CO2 (tons)": 197.01,
    "out:Klimatpaverkan": -21.82,
    "out:Initial Cost/MSEK": 3.122,
    "out:Running cost/(Apt SEK y)": 35874,
    "out:Running Cost over RSP/MSEK": 10.093,
    "out:LCP/MSEK": 1.311,
    "out:ROI% old": 20.28,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156197,
    "out:DH kr savings": 202860,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 395950,
    "out:% savings (space heating)": 30.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 131.81,
    "out:Total Energy Use Post PV": 131.85,
    "out:Primary Energy": 138.22,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.95,
    "out:CO2 Operational/m2": 44.07,
    "out:Total CO2/m2": 59.02,
    "out:Total CO2 (tons)": 190.05,
    "out:Klimatpaverkan": -28.78,
    "out:Initial Cost/MSEK": 3.203,
    "out:Running cost/(Apt SEK y)": 34253,
    "out:Running Cost over RSP/MSEK": 9.636,
    "out:LCP/MSEK": 1.687,
    "out:ROI% old": 21.81,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175517,
    "out:DH kr savings": 222180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 377432,
    "out:% savings (space heating)": 33.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00002201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 138.83,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 137.54,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.83,
    "out:CO2 Operational/m2": 30.39,
    "out:Total CO2/m2": 53.22,
    "out:Total CO2 (tons)": 171.37,
    "out:Klimatpaverkan": -47.46,
    "out:Initial Cost/MSEK": 3.432,
    "out:Running cost/(Apt SEK y)": 34954,
    "out:Running Cost over RSP/MSEK": 9.843,
    "out:LCP/MSEK": 1.251,
    "out:ROI% old": 19.49,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.44,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.18,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195977,
    "out:DH kr savings": 199640,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 399475,
    "out:% savings (space heating)": 29.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 132.83,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 132.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.83,
    "out:CO2 Operational/m2": 28.23,
    "out:Total CO2/m2": 51.06,
    "out:Total CO2 (tons)": 164.42,
    "out:Klimatpaverkan": -54.41,
    "out:Initial Cost/MSEK": 3.512,
    "out:Running cost/(Apt SEK y)": 33333,
    "out:Running Cost over RSP/MSEK": 9.386,
    "out:LCP/MSEK": 1.627,
    "out:ROI% old": 20.9,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.79,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.82,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215297,
    "out:DH kr savings": 218960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 380946,
    "out:% savings (space heating)": 32.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 137.81,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 135.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 23.18,
    "out:CO2 Operational/m2": 30.01,
    "out:Total CO2/m2": 53.19,
    "out:Total CO2 (tons)": 171.28,
    "out:Klimatpaverkan": -47.55,
    "out:Initial Cost/MSEK": 3.569,
    "out:Running cost/(Apt SEK y)": 34681,
    "out:Running Cost over RSP/MSEK": 9.766,
    "out:LCP/MSEK": 1.192,
    "out:ROI% old": 19.06,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199213,
    "out:DH kr savings": 202860,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 395950,
    "out:% savings (space heating)": 30.29,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.64,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00002201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 131.81,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 130.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.18,
    "out:CO2 Operational/m2": 27.85,
    "out:Total CO2/m2": 51.03,
    "out:Total CO2 (tons)": 164.32,
    "out:Klimatpaverkan": -54.51,
    "out:Initial Cost/MSEK": 3.649,
    "out:Running cost/(Apt SEK y)": 33060,
    "out:Running Cost over RSP/MSEK": 9.309,
    "out:LCP/MSEK": 1.568,
    "out:ROI% old": 20.42,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218534,
    "out:DH kr savings": 222180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 377432,
    "out:% savings (space heating)": 33.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 126.88,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 193.6,
    "out:Total Energy Use Post PV": 193.6,
    "out:Primary Energy": 185.52,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 67.96,
    "out:Total CO2/m2": 67.96,
    "out:Total CO2 (tons)": 218.83,
    "out:Klimatpaverkan": 0,
    "out:Initial Cost/MSEK": 0,
    "out:Running cost/(Apt SEK y)": 51602,
    "out:Running Cost over RSP/MSEK": 14.526,
    "out:LCP/MSEK": 0,
    "out:ROI% old": 0,
    "out:Payback discounted": 0,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 194,
    "out:Energy use kWh/m2": 194,
    "out:Energy savings %": 0,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 64,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 186,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 25,
    "out:Return/kSEK/y": 0,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 0,
    "out:Payback Time": 0,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 0,
    "out:DH kr savings": 0,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 185.6,
    "out:Total Energy Use Post PV": 185.6,
    "out:Primary Energy": 178.74,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 65.08,
    "out:Total CO2/m2": 65.08,
    "out:Total CO2 (tons)": 209.56,
    "out:Klimatpaverkan": -9.27,
    "out:Initial Cost/MSEK": 0.081,
    "out:Running cost/(Apt SEK y)": 49441,
    "out:Running Cost over RSP/MSEK": 13.917,
    "out:LCP/MSEK": 0.528,
    "out:ROI% old": 107.98,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 186,
    "out:Energy use kWh/m2": 186,
    "out:Energy savings %": 4.3,
    "out:Op energy cost/MSEK": 102,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 178,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 97,
    "out:Return/kSEK/y": 78,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 32,
    "out:Payback Time": 3.125,
    "out:DH kWh savings": 25760,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 25760,
    "out:DH kr savings": 25760,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 192.6,
    "out:Total Energy Use Post PV": 192.6,
    "out:Primary Energy": 182.89,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0.35,
    "out:CO2 Operational/m2": 67.6,
    "out:Total CO2/m2": 67.95,
    "out:Total CO2 (tons)": 218.8,
    "out:Klimatpaverkan": -0.03,
    "out:Initial Cost/MSEK": 0.137,
    "out:Running cost/(Apt SEK y)": 51332,
    "out:Running Cost over RSP/MSEK": 14.45,
    "out:LCP/MSEK": -0.061,
    "out:ROI% old": 7.95,
    "out:Payback discounted": 15,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 193,
    "out:Energy use kWh/m2": 193,
    "out:Energy savings %": 0.5,
    "out:Op energy cost/MSEK": 106,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 185,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 7,
    "out:Return/kSEK/y": 10,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 3220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 3220,
    "out:DH kr savings": 3220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 184.6,
    "out:Total Energy Use Post PV": 184.6,
    "out:Primary Energy": 176.11,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.35,
    "out:CO2 Operational/m2": 64.72,
    "out:Total CO2/m2": 65.07,
    "out:Total CO2 (tons)": 209.53,
    "out:Klimatpaverkan": -9.3,
    "out:Initial Cost/MSEK": 0.217,
    "out:Running cost/(Apt SEK y)": 49171,
    "out:Running Cost over RSP/MSEK": 13.841,
    "out:LCP/MSEK": 0.467,
    "out:ROI% old": 45.04,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 185,
    "out:Energy use kWh/m2": 185,
    "out:Energy savings %": 4.9,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 177,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 40,
    "out:Return/kSEK/y": 88,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 28980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 28980,
    "out:DH kr savings": 28980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 193.6,
    "out:Total Energy Use Post PV": 190.91,
    "out:Primary Energy": 180.68,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 8.24,
    "out:CO2 Operational/m2": 43.48,
    "out:Total CO2/m2": 51.72,
    "out:Total CO2 (tons)": 166.53,
    "out:Klimatpaverkan": -52.3,
    "out:Initial Cost/MSEK": 0.447,
    "out:Running cost/(Apt SEK y)": 50482,
    "out:Running Cost over RSP/MSEK": 14.219,
    "out:LCP/MSEK": -0.14,
    "out:ROI% old": 9.81,
    "out:Payback discounted": 12,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 191,
    "out:Energy use kWh/m2": 191,
    "out:Energy savings %": 1.6,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 37,
    "out:DH+ DHW/kWh/m2": 186,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 40,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 0,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 40316,
    "out:DH kr savings": 0,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 185.6,
    "out:Total Energy Use Post PV": 182.91,
    "out:Primary Energy": 173.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.24,
    "out:CO2 Operational/m2": 40.6,
    "out:Total CO2/m2": 48.84,
    "out:Total CO2 (tons)": 157.26,
    "out:Klimatpaverkan": -61.57,
    "out:Initial Cost/MSEK": 0.527,
    "out:Running cost/(Apt SEK y)": 48321,
    "out:Running Cost over RSP/MSEK": 13.611,
    "out:LCP/MSEK": 0.388,
    "out:ROI% old": 24.81,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 6,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 178,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 118,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 25760,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 66076,
    "out:DH kr savings": 25760,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 192.6,
    "out:Total Energy Use Post PV": 189.91,
    "out:Primary Energy": 178.05,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 8.59,
    "out:CO2 Operational/m2": 43.12,
    "out:Total CO2/m2": 51.71,
    "out:Total CO2 (tons)": 166.5,
    "out:Klimatpaverkan": -52.33,
    "out:Initial Cost/MSEK": 0.583,
    "out:Running cost/(Apt SEK y)": 50212,
    "out:Running Cost over RSP/MSEK": 14.143,
    "out:LCP/MSEK": -0.2,
    "out:ROI% old": 9.38,
    "out:Payback discounted": 13,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 190,
    "out:Energy use kWh/m2": 190,
    "out:Energy savings %": 2.1,
    "out:Op energy cost/MSEK": 104,
    "out:El price/MSEK": 62,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 185,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 9,
    "out:Return/kSEK/y": 50,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 3220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 43536,
    "out:DH kr savings": 3220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 184.6,
    "out:Total Energy Use Post PV": 181.91,
    "out:Primary Energy": 171.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.59,
    "out:CO2 Operational/m2": 40.24,
    "out:Total CO2/m2": 48.83,
    "out:Total CO2 (tons)": 157.22,
    "out:Klimatpaverkan": -61.61,
    "out:Initial Cost/MSEK": 0.664,
    "out:Running cost/(Apt SEK y)": 48051,
    "out:Running Cost over RSP/MSEK": 13.535,
    "out:LCP/MSEK": 0.328,
    "out:ROI% old": 21.34,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 6.6,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 177,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 128,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 28980,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69296,
    "out:DH kr savings": 28980,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 191.6,
    "out:Total Energy Use Post PV": 191.6,
    "out:Primary Energy": 182.02,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 67.24,
    "out:Total CO2/m2": 67.24,
    "out:Total CO2 (tons)": 216.51,
    "out:Klimatpaverkan": -2.32,
    "out:Initial Cost/MSEK": 0.095,
    "out:Running cost/(Apt SEK y)": 51062,
    "out:Running Cost over RSP/MSEK": 14.374,
    "out:LCP/MSEK": 0.057,
    "out:ROI% old": 22.87,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 192,
    "out:Energy use kWh/m2": 192,
    "out:Energy savings %": 1,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 184,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 20,
    "out:Return/kSEK/y": 19,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 6440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 6440,
    "out:DH kr savings": 6440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 183.6,
    "out:Total Energy Use Post PV": 183.6,
    "out:Primary Energy": 175.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0,
    "out:CO2 Operational/m2": 64.36,
    "out:Total CO2/m2": 64.36,
    "out:Total CO2 (tons)": 207.24,
    "out:Klimatpaverkan": -11.59,
    "out:Initial Cost/MSEK": 0.176,
    "out:Running cost/(Apt SEK y)": 48901,
    "out:Running Cost over RSP/MSEK": 13.765,
    "out:LCP/MSEK": 0.585,
    "out:ROI% old": 61.91,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 184,
    "out:Energy use kWh/m2": 184,
    "out:Energy savings %": 5.4,
    "out:Op energy cost/MSEK": 101,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 176,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 55,
    "out:Return/kSEK/y": 97,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 32200,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 32200,
    "out:DH kr savings": 32200,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 190.6,
    "out:Total Energy Use Post PV": 190.6,
    "out:Primary Energy": 179.92,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 0.35,
    "out:CO2 Operational/m2": 66.88,
    "out:Total CO2/m2": 67.23,
    "out:Total CO2 (tons)": 216.48,
    "out:Klimatpaverkan": -2.35,
    "out:Initial Cost/MSEK": 0.232,
    "out:Running cost/(Apt SEK y)": 50792,
    "out:Running Cost over RSP/MSEK": 14.298,
    "out:LCP/MSEK": -0.003,
    "out:ROI% old": 14.07,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 191,
    "out:Energy use kWh/m2": 191,
    "out:Energy savings %": 1.6,
    "out:Op energy cost/MSEK": 105,
    "out:El price/MSEK": 63,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 183,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 29,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 9660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 9660,
    "out:DH kr savings": 9660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 182.6,
    "out:Total Energy Use Post PV": 182.6,
    "out:Primary Energy": 173.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 0.35,
    "out:CO2 Operational/m2": 64,
    "out:Total CO2/m2": 64.35,
    "out:Total CO2 (tons)": 207.21,
    "out:Klimatpaverkan": -11.62,
    "out:Initial Cost/MSEK": 0.312,
    "out:Running cost/(Apt SEK y)": 48631,
    "out:Running Cost over RSP/MSEK": 13.689,
    "out:LCP/MSEK": 0.525,
    "out:ROI% old": 38.29,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 6,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 175,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 34,
    "out:Return/kSEK/y": 107,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 35420,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 35420,
    "out:DH kr savings": 35420,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 191.6,
    "out:Total Energy Use Post PV": 188.91,
    "out:Primary Energy": 177.18,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 8.24,
    "out:CO2 Operational/m2": 42.76,
    "out:Total CO2/m2": 51,
    "out:Total CO2 (tons)": 164.21,
    "out:Klimatpaverkan": -54.62,
    "out:Initial Cost/MSEK": 0.542,
    "out:Running cost/(Apt SEK y)": 49942,
    "out:Running Cost over RSP/MSEK": 14.067,
    "out:LCP/MSEK": -0.083,
    "out:ROI% old": 12.11,
    "out:Payback discounted": 10,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 189,
    "out:Energy use kWh/m2": 189,
    "out:Energy savings %": 2.6,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 184,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 11,
    "out:Return/kSEK/y": 60,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 6440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46756,
    "out:DH kr savings": 6440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 183.6,
    "out:Total Energy Use Post PV": 180.91,
    "out:Primary Energy": 170.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.24,
    "out:CO2 Operational/m2": 39.88,
    "out:Total CO2/m2": 48.12,
    "out:Total CO2 (tons)": 154.94,
    "out:Klimatpaverkan": -63.89,
    "out:Initial Cost/MSEK": 0.622,
    "out:Running cost/(Apt SEK y)": 47781,
    "out:Running Cost over RSP/MSEK": 13.458,
    "out:LCP/MSEK": 0.445,
    "out:ROI% old": 24.51,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 35,
    "out:DH+ DHW/kWh/m2": 176,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 138,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 32200,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 72516,
    "out:DH kr savings": 32200,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 176.38,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 190.6,
    "out:Total Energy Use Post PV": 187.91,
    "out:Primary Energy": 175.08,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.59,
    "out:CO2 Operational/m2": 42.4,
    "out:Total CO2/m2": 50.99,
    "out:Total CO2 (tons)": 164.18,
    "out:Klimatpaverkan": -54.65,
    "out:Initial Cost/MSEK": 0.678,
    "out:Running cost/(Apt SEK y)": 49672,
    "out:Running Cost over RSP/MSEK": 13.991,
    "out:LCP/MSEK": -0.143,
    "out:ROI% old": 11.27,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 188,
    "out:Energy use kWh/m2": 188,
    "out:Energy savings %": 3.2,
    "out:Op energy cost/MSEK": 103,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 36,
    "out:DH+ DHW/kWh/m2": 183,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 69,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 9660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 49976,
    "out:DH kr savings": 9660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 567956,
    "out:% savings (space heating)": 0,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.64,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 182.6,
    "out:Total Energy Use Post PV": 179.91,
    "out:Primary Energy": 168.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 8.59,
    "out:CO2 Operational/m2": 39.52,
    "out:Total CO2/m2": 48.11,
    "out:Total CO2 (tons)": 154.91,
    "out:Klimatpaverkan": -63.92,
    "out:Initial Cost/MSEK": 0.759,
    "out:Running cost/(Apt SEK y)": 47511,
    "out:Running Cost over RSP/MSEK": 13.382,
    "out:LCP/MSEK": 0.385,
    "out:ROI% old": 21.53,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 180,
    "out:Energy use kWh/m2": 180,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 175,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 147,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 35420,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 75736,
    "out:DH kr savings": 35420,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 543018,
    "out:% savings (space heating)": 4.39,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 169.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 184.84,
    "out:Total Energy Use Post PV": 184.85,
    "out:Primary Energy": 187.13,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.26,
    "out:CO2 Operational/m2": 63.16,
    "out:Total CO2/m2": 66.42,
    "out:Total CO2 (tons)": 213.87,
    "out:Klimatpaverkan": -4.96,
    "out:Initial Cost/MSEK": 0.611,
    "out:Running cost/(Apt SEK y)": 48576,
    "out:Running Cost over RSP/MSEK": 13.669,
    "out:LCP/MSEK": 0.246,
    "out:ROI% old": 20.03,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 185,
    "out:Energy use kWh/m2": 185,
    "out:Energy savings %": 4.9,
    "out:Op energy cost/MSEK": 100,
    "out:El price/MSEK": 61,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 18,
    "out:Return/kSEK/y": 109,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 1,
    "out:Payback Time": 100,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 4857,
    "out:DH kr savings": 51520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 546347,
    "out:% savings (space heating)": 3.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 161.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 176.84,
    "out:Total Energy Use Post PV": 176.85,
    "out:Primary Energy": 180.36,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.26,
    "out:CO2 Operational/m2": 60.28,
    "out:Total CO2/m2": 63.54,
    "out:Total CO2 (tons)": 204.6,
    "out:Klimatpaverkan": -14.23,
    "out:Initial Cost/MSEK": 0.691,
    "out:Running cost/(Apt SEK y)": 46415,
    "out:Running Cost over RSP/MSEK": 13.061,
    "out:LCP/MSEK": 0.774,
    "out:ROI% old": 30.28,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 9.6,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 27,
    "out:Return/kSEK/y": 187,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77280,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 30617,
    "out:DH kr savings": 77280,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 521409,
    "out:% savings (space heating)": 8.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 182.83,
    "out:Total Energy Use Post PV": 182.85,
    "out:Primary Energy": 184.51,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.61,
    "out:CO2 Operational/m2": 62.44,
    "out:Total CO2/m2": 66.05,
    "out:Total CO2 (tons)": 212.68,
    "out:Klimatpaverkan": -6.15,
    "out:Initial Cost/MSEK": 0.747,
    "out:Running cost/(Apt SEK y)": 48034,
    "out:Running Cost over RSP/MSEK": 13.517,
    "out:LCP/MSEK": 0.262,
    "out:ROI% old": 19.29,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 6,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 128,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 11297,
    "out:DH kr savings": 57960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 541828,
    "out:% savings (space heating)": 4.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 175.83,
    "out:Total Energy Use Post PV": 175.85,
    "out:Primary Energy": 177.73,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.61,
    "out:CO2 Operational/m2": 59.92,
    "out:Total CO2/m2": 63.53,
    "out:Total CO2 (tons)": 204.56,
    "out:Klimatpaverkan": -14.27,
    "out:Initial Cost/MSEK": 0.828,
    "out:Running cost/(Apt SEK y)": 46143,
    "out:Running Cost over RSP/MSEK": 12.984,
    "out:LCP/MSEK": 0.714,
    "out:ROI% old": 26.6,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 24,
    "out:Return/kSEK/y": 197,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80500,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 33837,
    "out:DH kr savings": 80500,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516893,
    "out:% savings (space heating)": 8.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 169.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 184.84,
    "out:Total Energy Use Post PV": 180.5,
    "out:Primary Energy": 179.32,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 46.96,
    "out:Total CO2/m2": 58.46,
    "out:Total CO2 (tons)": 188.25,
    "out:Klimatpaverkan": -30.58,
    "out:Initial Cost/MSEK": 1.057,
    "out:Running cost/(Apt SEK y)": 47382,
    "out:Running Cost over RSP/MSEK": 13.342,
    "out:LCP/MSEK": 0.126,
    "out:ROI% old": 15.99,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 14,
    "out:Return/kSEK/y": 152,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.43,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 47842,
    "out:DH kr savings": 51520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 546347,
    "out:% savings (space heating)": 3.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 161.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 176.84,
    "out:Total Energy Use Post PV": 172.5,
    "out:Primary Energy": 172.54,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 44.08,
    "out:Total CO2/m2": 55.58,
    "out:Total CO2 (tons)": 178.98,
    "out:Klimatpaverkan": -39.85,
    "out:Initial Cost/MSEK": 1.138,
    "out:Running cost/(Apt SEK y)": 45221,
    "out:Running Cost over RSP/MSEK": 12.734,
    "out:LCP/MSEK": 0.654,
    "out:ROI% old": 22.5,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 12.1,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 230,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.45,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.17,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 77280,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 73602,
    "out:DH kr savings": 77280,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 521409,
    "out:% savings (space heating)": 8.2,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 168.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 182.83,
    "out:Total Energy Use Post PV": 178.5,
    "out:Primary Energy": 176.69,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 11.85,
    "out:CO2 Operational/m2": 46.23,
    "out:Total CO2/m2": 58.08,
    "out:Total CO2 (tons)": 187.02,
    "out:Klimatpaverkan": -31.81,
    "out:Initial Cost/MSEK": 1.194,
    "out:Running cost/(Apt SEK y)": 46840,
    "out:Running Cost over RSP/MSEK": 13.19,
    "out:LCP/MSEK": 0.142,
    "out:ROI% old": 15.99,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 8.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.25,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.36,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54292,
    "out:DH kr savings": 57960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 541828,
    "out:% savings (space heating)": 4.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 175.83,
    "out:Total Energy Use Post PV": 171.5,
    "out:Primary Energy": 169.91,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.85,
    "out:CO2 Operational/m2": 43.71,
    "out:Total CO2/m2": 55.56,
    "out:Total CO2 (tons)": 178.91,
    "out:Klimatpaverkan": -39.92,
    "out:Initial Cost/MSEK": 1.274,
    "out:Running cost/(Apt SEK y)": 44949,
    "out:Running Cost over RSP/MSEK": 12.657,
    "out:LCP/MSEK": 0.594,
    "out:ROI% old": 20.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 240,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.46,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.16,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 80500,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 76832,
    "out:DH kr savings": 80500,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516893,
    "out:% savings (space heating)": 8.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 167.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 182.83,
    "out:Total Energy Use Post PV": 182.85,
    "out:Primary Energy": 183.63,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.26,
    "out:CO2 Operational/m2": 62.43,
    "out:Total CO2/m2": 65.7,
    "out:Total CO2 (tons)": 211.55,
    "out:Klimatpaverkan": -7.28,
    "out:Initial Cost/MSEK": 0.706,
    "out:Running cost/(Apt SEK y)": 48033,
    "out:Running Cost over RSP/MSEK": 13.516,
    "out:LCP/MSEK": 0.304,
    "out:ROI% old": 20.43,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 183,
    "out:Energy use kWh/m2": 183,
    "out:Energy savings %": 6,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 18,
    "out:Return/kSEK/y": 129,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 11297,
    "out:DH kr savings": 57960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 540338,
    "out:% savings (space heating)": 4.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 174.83,
    "out:Total Energy Use Post PV": 174.85,
    "out:Primary Energy": 176.86,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.26,
    "out:CO2 Operational/m2": 59.55,
    "out:Total CO2/m2": 62.82,
    "out:Total CO2 (tons)": 202.27,
    "out:Klimatpaverkan": -16.56,
    "out:Initial Cost/MSEK": 0.786,
    "out:Running cost/(Apt SEK y)": 45872,
    "out:Running Cost over RSP/MSEK": 12.908,
    "out:LCP/MSEK": 0.832,
    "out:ROI% old": 29.4,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 26,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 37057,
    "out:DH kr savings": 83720,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 515406,
    "out:% savings (space heating)": 9.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 166.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 181.81,
    "out:Total Energy Use Post PV": 181.85,
    "out:Primary Energy": 181.53,
    "out:Energy Class": "G",
    "out:CO2 Embodied/m2": 3.61,
    "out:CO2 Operational/m2": 62.07,
    "out:Total CO2/m2": 65.69,
    "out:Total CO2 (tons)": 211.51,
    "out:Klimatpaverkan": -7.32,
    "out:Initial Cost/MSEK": 0.842,
    "out:Running cost/(Apt SEK y)": 47760,
    "out:Running Cost over RSP/MSEK": 13.44,
    "out:LCP/MSEK": 0.244,
    "out:ROI% old": 18.42,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 182,
    "out:Energy use kWh/m2": 182,
    "out:Energy savings %": 6.6,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 60,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 167,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 138,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 61180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 14517,
    "out:DH kr savings": 61180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 536811,
    "out:% savings (space heating)": 5.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 158.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 173.81,
    "out:Total Energy Use Post PV": 173.85,
    "out:Primary Energy": 174.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.61,
    "out:CO2 Operational/m2": 59.19,
    "out:Total CO2/m2": 62.81,
    "out:Total CO2 (tons)": 202.23,
    "out:Klimatpaverkan": -16.6,
    "out:Initial Cost/MSEK": 0.923,
    "out:Running cost/(Apt SEK y)": 45598,
    "out:Running Cost over RSP/MSEK": 12.831,
    "out:LCP/MSEK": 0.772,
    "out:ROI% old": 26.24,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 11.5,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 216,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 40277,
    "out:DH kr savings": 86940,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 511890,
    "out:% savings (space heating)": 9.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 167.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 182.83,
    "out:Total Energy Use Post PV": 178.5,
    "out:Primary Energy": 175.82,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 46.23,
    "out:Total CO2/m2": 57.72,
    "out:Total CO2 (tons)": 185.87,
    "out:Klimatpaverkan": -32.96,
    "out:Initial Cost/MSEK": 1.152,
    "out:Running cost/(Apt SEK y)": 46839,
    "out:Running Cost over RSP/MSEK": 13.189,
    "out:LCP/MSEK": 0.184,
    "out:ROI% old": 16.57,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 8.4,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 15,
    "out:Return/kSEK/y": 171,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.49,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54297,
    "out:DH kr savings": 57960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 540338,
    "out:% savings (space heating)": 4.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 174.83,
    "out:Total Energy Use Post PV": 170.5,
    "out:Primary Energy": 169.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.5,
    "out:CO2 Operational/m2": 43.35,
    "out:Total CO2/m2": 54.84,
    "out:Total CO2 (tons)": 176.6,
    "out:Klimatpaverkan": -42.23,
    "out:Initial Cost/MSEK": 1.233,
    "out:Running cost/(Apt SEK y)": 44678,
    "out:Running Cost over RSP/MSEK": 12.581,
    "out:LCP/MSEK": 0.712,
    "out:ROI% old": 22.54,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 13.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 249,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.84,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.78,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80057,
    "out:DH kr savings": 83720,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 515406,
    "out:% savings (space heating)": 9.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 166.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 181.81,
    "out:Total Energy Use Post PV": 177.5,
    "out:Primary Energy": 173.72,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.85,
    "out:CO2 Operational/m2": 45.85,
    "out:Total CO2/m2": 57.7,
    "out:Total CO2 (tons)": 185.78,
    "out:Klimatpaverkan": -33.05,
    "out:Initial Cost/MSEK": 1.289,
    "out:Running cost/(Apt SEK y)": 46566,
    "out:Running Cost over RSP/MSEK": 13.112,
    "out:LCP/MSEK": 0.125,
    "out:ROI% old": 15.67,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 9,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 167,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 14,
    "out:Return/kSEK/y": 181,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.03,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.59,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57533,
    "out:DH kr savings": 61180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 536811,
    "out:% savings (space heating)": 5.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 176.38,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 158.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 173.81,
    "out:Total Energy Use Post PV": 169.5,
    "out:Primary Energy": 166.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.85,
    "out:CO2 Operational/m2": 42.97,
    "out:Total CO2/m2": 54.82,
    "out:Total CO2 (tons)": 176.5,
    "out:Klimatpaverkan": -42.33,
    "out:Initial Cost/MSEK": 1.369,
    "out:Running cost/(Apt SEK y)": 44405,
    "out:Running Cost over RSP/MSEK": 12.504,
    "out:LCP/MSEK": 0.653,
    "out:ROI% old": 21.09,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.97,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 14.1,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 259,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.62,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83294,
    "out:DH kr savings": 86940,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 511890,
    "out:% savings (space heating)": 9.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 168.64,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 180.6,
    "out:Total Energy Use Post PV": 180.6,
    "out:Primary Energy": 174.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.52,
    "out:CO2 Operational/m2": 63.28,
    "out:Total CO2/m2": 65.8,
    "out:Total CO2 (tons)": 211.87,
    "out:Klimatpaverkan": -6.96,
    "out:Initial Cost/MSEK": 0.607,
    "out:Running cost/(Apt SEK y)": 48091,
    "out:Running Cost over RSP/MSEK": 13.537,
    "out:LCP/MSEK": 0.382,
    "out:ROI% old": 23.26,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 181,
    "out:Energy use kWh/m2": 181,
    "out:Energy savings %": 7.2,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 173,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 126,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 41860,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 41860,
    "out:DH kr savings": 41860,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 173.6,
    "out:Total Energy Use Post PV": 173.6,
    "out:Primary Energy": 167.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.52,
    "out:CO2 Operational/m2": 60.76,
    "out:Total CO2/m2": 63.28,
    "out:Total CO2 (tons)": 203.76,
    "out:Klimatpaverkan": -15.07,
    "out:Initial Cost/MSEK": 0.688,
    "out:Running cost/(Apt SEK y)": 46200,
    "out:Running Cost over RSP/MSEK": 13.005,
    "out:LCP/MSEK": 0.833,
    "out:ROI% old": 31.6,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 11.5,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 28,
    "out:Return/kSEK/y": 194,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 64400,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 64400,
    "out:DH kr savings": 64400,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 179.6,
    "out:Total Energy Use Post PV": 179.6,
    "out:Primary Energy": 171.54,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.87,
    "out:CO2 Operational/m2": 62.92,
    "out:Total CO2/m2": 65.79,
    "out:Total CO2 (tons)": 211.84,
    "out:Klimatpaverkan": -6.99,
    "out:Initial Cost/MSEK": 0.744,
    "out:Running cost/(Apt SEK y)": 47821,
    "out:Running Cost over RSP/MSEK": 13.461,
    "out:LCP/MSEK": 0.321,
    "out:ROI% old": 20.45,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 180,
    "out:Energy use kWh/m2": 180,
    "out:Energy savings %": 7.8,
    "out:Op energy cost/MSEK": 99,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 136,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 45080,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 45080,
    "out:DH kr savings": 45080,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 171.6,
    "out:Total Energy Use Post PV": 171.6,
    "out:Primary Energy": 165.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.87,
    "out:CO2 Operational/m2": 60.04,
    "out:Total CO2/m2": 62.91,
    "out:Total CO2 (tons)": 202.57,
    "out:Klimatpaverkan": -16.26,
    "out:Initial Cost/MSEK": 0.824,
    "out:Running cost/(Apt SEK y)": 45660,
    "out:Running Cost over RSP/MSEK": 12.852,
    "out:LCP/MSEK": 0.849,
    "out:ROI% old": 29,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 26,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 70840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 70840,
    "out:DH kr savings": 70840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 180.6,
    "out:Total Energy Use Post PV": 177.91,
    "out:Primary Energy": 169.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.75,
    "out:CO2 Operational/m2": 38.8,
    "out:Total CO2/m2": 49.56,
    "out:Total CO2 (tons)": 159.57,
    "out:Klimatpaverkan": -59.26,
    "out:Initial Cost/MSEK": 1.054,
    "out:Running cost/(Apt SEK y)": 46971,
    "out:Running Cost over RSP/MSEK": 13.23,
    "out:LCP/MSEK": 0.242,
    "out:ROI% old": 17.56,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 9,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 173,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 167,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 41860,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 82176,
    "out:DH kr savings": 41860,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 173.6,
    "out:Total Energy Use Post PV": 170.91,
    "out:Primary Energy": 163.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.75,
    "out:CO2 Operational/m2": 36.28,
    "out:Total CO2/m2": 47.04,
    "out:Total CO2 (tons)": 151.45,
    "out:Klimatpaverkan": -67.38,
    "out:Initial Cost/MSEK": 1.134,
    "out:Running cost/(Apt SEK y)": 45080,
    "out:Running Cost over RSP/MSEK": 12.698,
    "out:LCP/MSEK": 0.694,
    "out:ROI% old": 23.02,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 13.5,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 166,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 64400,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 104716,
    "out:DH kr savings": 64400,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 179.6,
    "out:Total Energy Use Post PV": 176.91,
    "out:Primary Energy": 166.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 38.44,
    "out:Total CO2/m2": 49.55,
    "out:Total CO2 (tons)": 159.54,
    "out:Klimatpaverkan": -59.29,
    "out:Initial Cost/MSEK": 1.19,
    "out:Running cost/(Apt SEK y)": 46701,
    "out:Running Cost over RSP/MSEK": 13.154,
    "out:LCP/MSEK": 0.181,
    "out:ROI% old": 16.46,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 9.6,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 172,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 176,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 45080,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 85396,
    "out:DH kr savings": 45080,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 171.6,
    "out:Total Energy Use Post PV": 168.91,
    "out:Primary Energy": 160.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 35.56,
    "out:Total CO2/m2": 46.67,
    "out:Total CO2 (tons)": 150.26,
    "out:Klimatpaverkan": -68.57,
    "out:Initial Cost/MSEK": 1.271,
    "out:Running cost/(Apt SEK y)": 44540,
    "out:Running Cost over RSP/MSEK": 12.546,
    "out:LCP/MSEK": 0.709,
    "out:ROI% old": 22.26,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 70840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111156,
    "out:DH kr savings": 70840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 178.6,
    "out:Total Energy Use Post PV": 178.6,
    "out:Primary Energy": 170.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.52,
    "out:CO2 Operational/m2": 62.56,
    "out:Total CO2/m2": 65.08,
    "out:Total CO2 (tons)": 209.55,
    "out:Klimatpaverkan": -9.28,
    "out:Initial Cost/MSEK": 0.702,
    "out:Running cost/(Apt SEK y)": 47550,
    "out:Running Cost over RSP/MSEK": 13.385,
    "out:LCP/MSEK": 0.439,
    "out:ROI% old": 23.21,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 179,
    "out:Energy use kWh/m2": 179,
    "out:Energy savings %": 8.4,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 59,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 146,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 48300,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 48300,
    "out:DH kr savings": 48300,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 171.6,
    "out:Total Energy Use Post PV": 171.6,
    "out:Primary Energy": 164.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.52,
    "out:CO2 Operational/m2": 60.04,
    "out:Total CO2/m2": 62.56,
    "out:Total CO2 (tons)": 201.44,
    "out:Klimatpaverkan": -17.39,
    "out:Initial Cost/MSEK": 0.783,
    "out:Running cost/(Apt SEK y)": 45660,
    "out:Running Cost over RSP/MSEK": 12.852,
    "out:LCP/MSEK": 0.891,
    "out:ROI% old": 30.54,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 27,
    "out:Return/kSEK/y": 214,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 70840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 70840,
    "out:DH kr savings": 70840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 177.6,
    "out:Total Energy Use Post PV": 177.6,
    "out:Primary Energy": 168.56,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.87,
    "out:CO2 Operational/m2": 62.2,
    "out:Total CO2/m2": 65.07,
    "out:Total CO2 (tons)": 209.52,
    "out:Klimatpaverkan": -9.31,
    "out:Initial Cost/MSEK": 0.839,
    "out:Running cost/(Apt SEK y)": 47280,
    "out:Running Cost over RSP/MSEK": 13.309,
    "out:LCP/MSEK": 0.378,
    "out:ROI% old": 20.73,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 9,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 156,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51520,
    "out:DH kr savings": 51520,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 170.6,
    "out:Total Energy Use Post PV": 170.6,
    "out:Primary Energy": 162.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 2.87,
    "out:CO2 Operational/m2": 59.68,
    "out:Total CO2/m2": 62.55,
    "out:Total CO2 (tons)": 201.41,
    "out:Klimatpaverkan": -17.42,
    "out:Initial Cost/MSEK": 0.919,
    "out:Running cost/(Apt SEK y)": 45390,
    "out:Running Cost over RSP/MSEK": 12.776,
    "out:LCP/MSEK": 0.83,
    "out:ROI% old": 27.18,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 13.5,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 24,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 74060,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74060,
    "out:DH kr savings": 74060,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 178.6,
    "out:Total Energy Use Post PV": 175.91,
    "out:Primary Energy": 165.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.75,
    "out:CO2 Operational/m2": 38.08,
    "out:Total CO2/m2": 48.84,
    "out:Total CO2 (tons)": 157.25,
    "out:Klimatpaverkan": -61.58,
    "out:Initial Cost/MSEK": 1.149,
    "out:Running cost/(Apt SEK y)": 46431,
    "out:Running Cost over RSP/MSEK": 13.078,
    "out:LCP/MSEK": 0.299,
    "out:ROI% old": 18,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 171,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 186,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 48300,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88616,
    "out:DH kr savings": 48300,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 171.6,
    "out:Total Energy Use Post PV": 168.91,
    "out:Primary Energy": 159.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.75,
    "out:CO2 Operational/m2": 35.56,
    "out:Total CO2/m2": 46.32,
    "out:Total CO2 (tons)": 149.14,
    "out:Klimatpaverkan": -69.69,
    "out:Initial Cost/MSEK": 1.229,
    "out:Running cost/(Apt SEK y)": 44540,
    "out:Running Cost over RSP/MSEK": 12.546,
    "out:LCP/MSEK": 0.751,
    "out:ROI% old": 23.01,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 164,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 254,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 70840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111156,
    "out:DH kr savings": 70840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 163.41,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 177.6,
    "out:Total Energy Use Post PV": 174.91,
    "out:Primary Energy": 163.73,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 37.72,
    "out:Total CO2/m2": 48.83,
    "out:Total CO2 (tons)": 157.22,
    "out:Klimatpaverkan": -61.61,
    "out:Initial Cost/MSEK": 1.285,
    "out:Running cost/(Apt SEK y)": 46160,
    "out:Running Cost over RSP/MSEK": 13.002,
    "out:LCP/MSEK": 0.238,
    "out:ROI% old": 16.94,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 196,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91836,
    "out:DH kr savings": 51520,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 526182,
    "out:% savings (space heating)": 7.36,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.26,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 170.6,
    "out:Total Energy Use Post PV": 167.91,
    "out:Primary Energy": 157.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.1,
    "out:CO2 Operational/m2": 35.2,
    "out:Total CO2/m2": 46.31,
    "out:Total CO2 (tons)": 149.1,
    "out:Klimatpaverkan": -69.73,
    "out:Initial Cost/MSEK": 1.366,
    "out:Running cost/(Apt SEK y)": 44270,
    "out:Running Cost over RSP/MSEK": 12.47,
    "out:LCP/MSEK": 0.69,
    "out:ROI% old": 21.51,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 74060,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114376,
    "out:DH kr savings": 74060,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 503143,
    "out:% savings (space heating)": 11.41,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 171.84,
    "out:Total Energy Use Post PV": 171.85,
    "out:Primary Energy": 175.78,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.78,
    "out:CO2 Operational/m2": 58.48,
    "out:Total CO2/m2": 64.26,
    "out:Total CO2 (tons)": 206.91,
    "out:Klimatpaverkan": -11.92,
    "out:Initial Cost/MSEK": 1.218,
    "out:Running cost/(Apt SEK y)": 45064,
    "out:Running Cost over RSP/MSEK": 12.681,
    "out:LCP/MSEK": 0.627,
    "out:ROI% old": 21.64,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 235,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 46717,
    "out:DH kr savings": 93380,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 504574,
    "out:% savings (space heating)": 11.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 164.84,
    "out:Total Energy Use Post PV": 164.85,
    "out:Primary Energy": 169.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.78,
    "out:CO2 Operational/m2": 55.96,
    "out:Total CO2/m2": 61.74,
    "out:Total CO2 (tons)": 198.8,
    "out:Klimatpaverkan": -20.03,
    "out:Initial Cost/MSEK": 1.298,
    "out:Running cost/(Apt SEK y)": 43173,
    "out:Running Cost over RSP/MSEK": 12.148,
    "out:LCP/MSEK": 1.079,
    "out:ROI% old": 26.16,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 69257,
    "out:DH kr savings": 115920,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481535,
    "out:% savings (space heating)": 15.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 155.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 169.83,
    "out:Total Energy Use Post PV": 169.85,
    "out:Primary Energy": 173.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.13,
    "out:CO2 Operational/m2": 57.76,
    "out:Total CO2/m2": 63.89,
    "out:Total CO2 (tons)": 205.72,
    "out:Klimatpaverkan": -13.11,
    "out:Initial Cost/MSEK": 1.355,
    "out:Running cost/(Apt SEK y)": 44522,
    "out:Running Cost over RSP/MSEK": 12.528,
    "out:LCP/MSEK": 0.643,
    "out:ROI% old": 21.07,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 14.1,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 19,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99820,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53157,
    "out:DH kr savings": 99820,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 500058,
    "out:% savings (space heating)": 11.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 148.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 162.83,
    "out:Total Energy Use Post PV": 162.85,
    "out:Primary Energy": 166.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.13,
    "out:CO2 Operational/m2": 55.24,
    "out:Total CO2/m2": 61.37,
    "out:Total CO2 (tons)": 197.6,
    "out:Klimatpaverkan": -21.23,
    "out:Initial Cost/MSEK": 1.435,
    "out:Running cost/(Apt SEK y)": 42631,
    "out:Running Cost over RSP/MSEK": 11.995,
    "out:LCP/MSEK": 1.095,
    "out:ROI% old": 25.19,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 23,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 75697,
    "out:DH kr savings": 122360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 477023,
    "out:% savings (space heating)": 16.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 156.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 171.84,
    "out:Total Energy Use Post PV": 167.5,
    "out:Primary Energy": 167.96,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.02,
    "out:CO2 Operational/m2": 42.28,
    "out:Total CO2/m2": 56.3,
    "out:Total CO2 (tons)": 181.29,
    "out:Klimatpaverkan": -37.54,
    "out:Initial Cost/MSEK": 1.665,
    "out:Running cost/(Apt SEK y)": 43870,
    "out:Running Cost over RSP/MSEK": 12.353,
    "out:LCP/MSEK": 0.508,
    "out:ROI% old": 18.64,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 278,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.48,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 89702,
    "out:DH kr savings": 93380,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 504574,
    "out:% savings (space heating)": 11.16,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 164.84,
    "out:Total Energy Use Post PV": 160.5,
    "out:Primary Energy": 161.7,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.02,
    "out:CO2 Operational/m2": 39.76,
    "out:Total CO2/m2": 53.78,
    "out:Total CO2 (tons)": 173.18,
    "out:Klimatpaverkan": -45.65,
    "out:Initial Cost/MSEK": 1.745,
    "out:Running cost/(Apt SEK y)": 41980,
    "out:Running Cost over RSP/MSEK": 11.821,
    "out:LCP/MSEK": 0.96,
    "out:ROI% old": 22.14,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 346,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.5,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112242,
    "out:DH kr savings": 115920,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481535,
    "out:% savings (space heating)": 15.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 155.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 169.83,
    "out:Total Energy Use Post PV": 165.5,
    "out:Primary Energy": 165.34,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 41.55,
    "out:Total CO2/m2": 55.92,
    "out:Total CO2 (tons)": 180.06,
    "out:Klimatpaverkan": -38.77,
    "out:Initial Cost/MSEK": 1.801,
    "out:Running cost/(Apt SEK y)": 43328,
    "out:Running Cost over RSP/MSEK": 12.201,
    "out:LCP/MSEK": 0.524,
    "out:ROI% old": 18.44,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.49,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99820,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96152,
    "out:DH kr savings": 99820,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 500058,
    "out:% savings (space heating)": 11.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 148.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 162.83,
    "out:Total Energy Use Post PV": 158.5,
    "out:Primary Energy": 159.08,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 39.03,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 171.94,
    "out:Klimatpaverkan": -46.89,
    "out:Initial Cost/MSEK": 1.882,
    "out:Running cost/(Apt SEK y)": 41437,
    "out:Running Cost over RSP/MSEK": 11.668,
    "out:LCP/MSEK": 0.976,
    "out:ROI% old": 21.69,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 19,
    "out:Return/kSEK/y": 366,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.7,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13972.92,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 122360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118693,
    "out:DH kr savings": 122360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 477023,
    "out:% savings (space heating)": 16.01,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 154.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 169.83,
    "out:Total Energy Use Post PV": 169.85,
    "out:Primary Energy": 172.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.78,
    "out:CO2 Operational/m2": 57.75,
    "out:Total CO2/m2": 63.54,
    "out:Total CO2 (tons)": 204.58,
    "out:Klimatpaverkan": -14.25,
    "out:Initial Cost/MSEK": 1.313,
    "out:Running cost/(Apt SEK y)": 44521,
    "out:Running Cost over RSP/MSEK": 12.528,
    "out:LCP/MSEK": 0.685,
    "out:ROI% old": 21.74,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 14.1,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 255,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 99820,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 53157,
    "out:DH kr savings": 99820,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 498570,
    "out:% savings (space heating)": 12.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 147.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 162.82,
    "out:Total Energy Use Post PV": 162.85,
    "out:Primary Energy": 166.02,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 5.78,
    "out:CO2 Operational/m2": 55.23,
    "out:Total CO2/m2": 61.02,
    "out:Total CO2 (tons)": 196.47,
    "out:Klimatpaverkan": -22.36,
    "out:Initial Cost/MSEK": 1.394,
    "out:Running cost/(Apt SEK y)": 42630,
    "out:Running Cost over RSP/MSEK": 11.995,
    "out:LCP/MSEK": 1.137,
    "out:ROI% old": 25.94,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 23,
    "out:Return/kSEK/y": 323,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 122360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 75697,
    "out:DH kr savings": 122360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 475538,
    "out:% savings (space heating)": 16.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 168.81,
    "out:Total Energy Use Post PV": 168.85,
    "out:Primary Energy": 170.18,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.13,
    "out:CO2 Operational/m2": 57.39,
    "out:Total CO2/m2": 63.52,
    "out:Total CO2 (tons)": 204.55,
    "out:Klimatpaverkan": -14.28,
    "out:Initial Cost/MSEK": 1.45,
    "out:Running cost/(Apt SEK y)": 44248,
    "out:Running Cost over RSP/MSEK": 12.451,
    "out:LCP/MSEK": 0.626,
    "out:ROI% old": 20.45,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 18,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 103040,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56377,
    "out:DH kr savings": 103040,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 495050,
    "out:% savings (space heating)": 12.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 146.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 161.81,
    "out:Total Energy Use Post PV": 161.85,
    "out:Primary Energy": 163.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.13,
    "out:CO2 Operational/m2": 54.87,
    "out:Total CO2/m2": 61,
    "out:Total CO2 (tons)": 196.43,
    "out:Klimatpaverkan": -22.4,
    "out:Initial Cost/MSEK": 1.53,
    "out:Running cost/(Apt SEK y)": 42357,
    "out:Running Cost over RSP/MSEK": 11.918,
    "out:LCP/MSEK": 1.078,
    "out:ROI% old": 24.35,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1036.4,
    "out:Return %": 22,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 125580,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78917,
    "out:DH kr savings": 125580,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 472028,
    "out:% savings (space heating)": 16.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 154.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 169.83,
    "out:Total Energy Use Post PV": 165.5,
    "out:Primary Energy": 164.46,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.02,
    "out:CO2 Operational/m2": 41.55,
    "out:Total CO2/m2": 55.56,
    "out:Total CO2 (tons)": 178.91,
    "out:Klimatpaverkan": -39.92,
    "out:Initial Cost/MSEK": 1.76,
    "out:Running cost/(Apt SEK y)": 43327,
    "out:Running Cost over RSP/MSEK": 12.201,
    "out:LCP/MSEK": 0.566,
    "out:ROI% old": 18.88,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 155,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 298,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.82,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.79,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 99820,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96157,
    "out:DH kr savings": 99820,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 498570,
    "out:% savings (space heating)": 12.22,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 147.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 162.82,
    "out:Total Energy Use Post PV": 158.5,
    "out:Primary Energy": 158.2,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.02,
    "out:CO2 Operational/m2": 39.03,
    "out:Total CO2/m2": 53.04,
    "out:Total CO2 (tons)": 170.8,
    "out:Klimatpaverkan": -48.03,
    "out:Initial Cost/MSEK": 1.84,
    "out:Running cost/(Apt SEK y)": 41436,
    "out:Running Cost over RSP/MSEK": 11.668,
    "out:LCP/MSEK": 1.018,
    "out:ROI% old": 22.19,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 148,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 366,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.18,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.44,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 122360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 118698,
    "out:DH kr savings": 122360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 475538,
    "out:% savings (space heating)": 16.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 168.81,
    "out:Total Energy Use Post PV": 164.5,
    "out:Primary Energy": 162.36,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 41.17,
    "out:Total CO2/m2": 55.53,
    "out:Total CO2 (tons)": 178.82,
    "out:Klimatpaverkan": -40.01,
    "out:Initial Cost/MSEK": 1.896,
    "out:Running cost/(Apt SEK y)": 43054,
    "out:Running Cost over RSP/MSEK": 12.124,
    "out:LCP/MSEK": 0.506,
    "out:ROI% old": 18.1,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.73,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.89,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 103040,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99394,
    "out:DH kr savings": 103040,
    "out:El kr savings": -18698,
    "out:El kr sold": 15052,
    "out:El kr saved": 27918,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 495050,
    "out:% savings (space heating)": 12.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 163.41,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 146.59,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 161.81,
    "out:Total Energy Use Post PV": 157.5,
    "out:Primary Energy": 156.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.37,
    "out:CO2 Operational/m2": 38.65,
    "out:Total CO2/m2": 53.01,
    "out:Total CO2 (tons)": 170.7,
    "out:Klimatpaverkan": -48.13,
    "out:Initial Cost/MSEK": 1.977,
    "out:Running cost/(Apt SEK y)": 41163,
    "out:Running Cost over RSP/MSEK": 11.591,
    "out:LCP/MSEK": 0.958,
    "out:ROI% old": 21.21,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.74,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.87,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 125580,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121935,
    "out:DH kr savings": 125580,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27916,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 472028,
    "out:% savings (space heating)": 16.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 156.26,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 177.6,
    "out:Total Energy Use Post PV": 177.6,
    "out:Primary Energy": 171.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 62.2,
    "out:Total CO2/m2": 71.62,
    "out:Total CO2 (tons)": 230.63,
    "out:Klimatpaverkan": 11.8,
    "out:Initial Cost/MSEK": 2.094,
    "out:Running cost/(Apt SEK y)": 47280,
    "out:Running Cost over RSP/MSEK": 13.309,
    "out:LCP/MSEK": -0.877,
    "out:ROI% old": 8.3,
    "out:Payback discounted": 15,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 178,
    "out:Energy use kWh/m2": 178,
    "out:Energy savings %": 9,
    "out:Op energy cost/MSEK": 98,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 7,
    "out:Return/kSEK/y": 156,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 51520,
    "out:DH kr savings": 51520,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 170.6,
    "out:Total Energy Use Post PV": 170.6,
    "out:Primary Energy": 165.43,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 59.68,
    "out:Total CO2/m2": 69.11,
    "out:Total CO2 (tons)": 222.52,
    "out:Klimatpaverkan": 3.69,
    "out:Initial Cost/MSEK": 2.175,
    "out:Running cost/(Apt SEK y)": 45390,
    "out:Running Cost over RSP/MSEK": 12.776,
    "out:LCP/MSEK": -0.425,
    "out:ROI% old": 11.49,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 171,
    "out:Energy use kWh/m2": 171,
    "out:Energy savings %": 13.5,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 10,
    "out:Return/kSEK/y": 224,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 74060,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 74060,
    "out:DH kr savings": 74060,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 176.6,
    "out:Total Energy Use Post PV": 176.6,
    "out:Primary Energy": 168.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 61.84,
    "out:Total CO2/m2": 71.62,
    "out:Total CO2 (tons)": 230.6,
    "out:Klimatpaverkan": 11.77,
    "out:Initial Cost/MSEK": 2.231,
    "out:Running cost/(Apt SEK y)": 47010,
    "out:Running Cost over RSP/MSEK": 13.233,
    "out:LCP/MSEK": -0.938,
    "out:ROI% old": 8.28,
    "out:Payback discounted": 15,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 177,
    "out:Energy use kWh/m2": 177,
    "out:Energy savings %": 9.6,
    "out:Op energy cost/MSEK": 97,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 169,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 7,
    "out:Return/kSEK/y": 165,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 54740,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 54740,
    "out:DH kr savings": 54740,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 169.6,
    "out:Total Energy Use Post PV": 169.6,
    "out:Primary Energy": 162.81,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 59.32,
    "out:Total CO2/m2": 69.1,
    "out:Total CO2 (tons)": 222.49,
    "out:Klimatpaverkan": 3.66,
    "out:Initial Cost/MSEK": 2.311,
    "out:Running cost/(Apt SEK y)": 45119,
    "out:Running Cost over RSP/MSEK": 12.7,
    "out:LCP/MSEK": -0.486,
    "out:ROI% old": 11.28,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 170,
    "out:Energy use kWh/m2": 170,
    "out:Energy savings %": 14.1,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 10,
    "out:Return/kSEK/y": 233,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 77280,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 77280,
    "out:DH kr savings": 77280,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 177.6,
    "out:Total Energy Use Post PV": 174.91,
    "out:Primary Energy": 166.74,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.66,
    "out:CO2 Operational/m2": 37.72,
    "out:Total CO2/m2": 55.38,
    "out:Total CO2 (tons)": 178.33,
    "out:Klimatpaverkan": -40.5,
    "out:Initial Cost/MSEK": 2.541,
    "out:Running cost/(Apt SEK y)": 46160,
    "out:Running Cost over RSP/MSEK": 13.002,
    "out:LCP/MSEK": -1.017,
    "out:ROI% old": 8.57,
    "out:Payback discounted": 14,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 34,
    "out:DH+ DHW/kWh/m2": 170,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 196,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 51520,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91836,
    "out:DH kr savings": 51520,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 170.6,
    "out:Total Energy Use Post PV": 167.91,
    "out:Primary Energy": 160.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.66,
    "out:CO2 Operational/m2": 35.2,
    "out:Total CO2/m2": 52.86,
    "out:Total CO2 (tons)": 170.21,
    "out:Klimatpaverkan": -48.62,
    "out:Initial Cost/MSEK": 2.621,
    "out:Running cost/(Apt SEK y)": 44270,
    "out:Running Cost over RSP/MSEK": 12.47,
    "out:LCP/MSEK": -0.565,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 163,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 264,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 74060,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114376,
    "out:DH kr savings": 74060,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 176.6,
    "out:Total Energy Use Post PV": 173.91,
    "out:Primary Energy": 164.11,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.01,
    "out:CO2 Operational/m2": 37.36,
    "out:Total CO2/m2": 55.37,
    "out:Total CO2 (tons)": 178.3,
    "out:Klimatpaverkan": -40.53,
    "out:Initial Cost/MSEK": 2.677,
    "out:Running cost/(Apt SEK y)": 45890,
    "out:Running Cost over RSP/MSEK": 12.926,
    "out:LCP/MSEK": -1.077,
    "out:ROI% old": 8.54,
    "out:Payback discounted": 14,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 174,
    "out:Energy use kWh/m2": 174,
    "out:Energy savings %": 11.5,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 169,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 206,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 54740,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95056,
    "out:DH kr savings": 54740,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 169.6,
    "out:Total Energy Use Post PV": 166.91,
    "out:Primary Energy": 157.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.01,
    "out:CO2 Operational/m2": 34.84,
    "out:Total CO2/m2": 52.85,
    "out:Total CO2 (tons)": 170.18,
    "out:Klimatpaverkan": -48.65,
    "out:Initial Cost/MSEK": 2.758,
    "out:Running cost/(Apt SEK y)": 44000,
    "out:Running Cost over RSP/MSEK": 12.394,
    "out:LCP/MSEK": -0.625,
    "out:ROI% old": 11.05,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 162,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 274,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 77280,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117596,
    "out:DH kr savings": 77280,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 175.6,
    "out:Total Energy Use Post PV": 175.6,
    "out:Primary Energy": 168.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 61.48,
    "out:Total CO2/m2": 70.9,
    "out:Total CO2 (tons)": 228.31,
    "out:Klimatpaverkan": 9.48,
    "out:Initial Cost/MSEK": 2.189,
    "out:Running cost/(Apt SEK y)": 46740,
    "out:Running Cost over RSP/MSEK": 13.157,
    "out:LCP/MSEK": -0.82,
    "out:ROI% old": 8.93,
    "out:Payback discounted": 13,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 176,
    "out:Energy use kWh/m2": 176,
    "out:Energy savings %": 10.2,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 58,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 8,
    "out:Return/kSEK/y": 175,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 57960,
    "out:DH kr savings": 57960,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 168.6,
    "out:Total Energy Use Post PV": 168.6,
    "out:Primary Energy": 161.93,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 58.96,
    "out:Total CO2/m2": 68.39,
    "out:Total CO2 (tons)": 220.2,
    "out:Klimatpaverkan": 1.37,
    "out:Initial Cost/MSEK": 2.27,
    "out:Running cost/(Apt SEK y)": 44849,
    "out:Running Cost over RSP/MSEK": 12.624,
    "out:LCP/MSEK": -0.368,
    "out:ROI% old": 11.97,
    "out:Payback discounted": 10,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 93,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 11,
    "out:Return/kSEK/y": 243,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80500,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 80500,
    "out:DH kr savings": 80500,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 174.6,
    "out:Total Energy Use Post PV": 174.6,
    "out:Primary Energy": 165.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 61.12,
    "out:Total CO2/m2": 70.9,
    "out:Total CO2 (tons)": 228.28,
    "out:Klimatpaverkan": 9.45,
    "out:Initial Cost/MSEK": 2.326,
    "out:Running cost/(Apt SEK y)": 46470,
    "out:Running Cost over RSP/MSEK": 13.081,
    "out:LCP/MSEK": -0.881,
    "out:ROI% old": 8.88,
    "out:Payback discounted": 14,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 175,
    "out:Energy use kWh/m2": 175,
    "out:Energy savings %": 10.9,
    "out:Op energy cost/MSEK": 96,
    "out:El price/MSEK": 57,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 167,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 8,
    "out:Return/kSEK/y": 185,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 61180,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 61180,
    "out:DH kr savings": 61180,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 167.6,
    "out:Total Energy Use Post PV": 167.6,
    "out:Primary Energy": 159.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 58.6,
    "out:Total CO2/m2": 68.38,
    "out:Total CO2 (tons)": 220.17,
    "out:Klimatpaverkan": 1.34,
    "out:Initial Cost/MSEK": 2.406,
    "out:Running cost/(Apt SEK y)": 44579,
    "out:Running Cost over RSP/MSEK": 12.548,
    "out:LCP/MSEK": -0.429,
    "out:ROI% old": 11.74,
    "out:Payback discounted": 10,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 11,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 83720,
    "out:DH kr savings": 83720,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00010200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 175.6,
    "out:Total Energy Use Post PV": 172.91,
    "out:Primary Energy": 163.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.66,
    "out:CO2 Operational/m2": 37,
    "out:Total CO2/m2": 54.66,
    "out:Total CO2 (tons)": 176.01,
    "out:Klimatpaverkan": -42.82,
    "out:Initial Cost/MSEK": 2.636,
    "out:Running cost/(Apt SEK y)": 45620,
    "out:Running Cost over RSP/MSEK": 12.85,
    "out:LCP/MSEK": -0.96,
    "out:ROI% old": 9.08,
    "out:Payback discounted": 13,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 173,
    "out:Energy use kWh/m2": 173,
    "out:Energy savings %": 12.1,
    "out:Op energy cost/MSEK": 95,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 168,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 215,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 57960,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98276,
    "out:DH kr savings": 57960,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 168.6,
    "out:Total Energy Use Post PV": 165.91,
    "out:Primary Energy": 157.09,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.66,
    "out:CO2 Operational/m2": 34.48,
    "out:Total CO2/m2": 52.14,
    "out:Total CO2 (tons)": 167.9,
    "out:Klimatpaverkan": -50.93,
    "out:Initial Cost/MSEK": 2.716,
    "out:Running cost/(Apt SEK y)": 43729,
    "out:Running Cost over RSP/MSEK": 12.318,
    "out:LCP/MSEK": -0.508,
    "out:ROI% old": 11.61,
    "out:Payback discounted": 10,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 161,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 283,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 80500,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120816,
    "out:DH kr savings": 80500,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 160.45,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 174.6,
    "out:Total Energy Use Post PV": 171.91,
    "out:Primary Energy": 161.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.01,
    "out:CO2 Operational/m2": 36.64,
    "out:Total CO2/m2": 54.65,
    "out:Total CO2 (tons)": 175.98,
    "out:Klimatpaverkan": -42.85,
    "out:Initial Cost/MSEK": 2.772,
    "out:Running cost/(Apt SEK y)": 45350,
    "out:Running Cost over RSP/MSEK": 12.774,
    "out:LCP/MSEK": -1.02,
    "out:ROI% old": 9.03,
    "out:Payback discounted": 13,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 172,
    "out:Energy use kWh/m2": 172,
    "out:Energy savings %": 12.8,
    "out:Op energy cost/MSEK": 94,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 33,
    "out:DH+ DHW/kWh/m2": 167,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 8,
    "out:Return/kSEK/y": 225,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 61180,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101496,
    "out:DH kr savings": 61180,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 516649,
    "out:% savings (space heating)": 9.03,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.43,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 167.6,
    "out:Total Energy Use Post PV": 164.91,
    "out:Primary Energy": 154.99,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.01,
    "out:CO2 Operational/m2": 34.12,
    "out:Total CO2/m2": 52.13,
    "out:Total CO2 (tons)": 167.87,
    "out:Klimatpaverkan": -50.96,
    "out:Initial Cost/MSEK": 2.853,
    "out:Running cost/(Apt SEK y)": 43459,
    "out:Running Cost over RSP/MSEK": 12.241,
    "out:LCP/MSEK": -0.568,
    "out:ROI% old": 11.44,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 10,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 124036,
    "out:DH kr savings": 83720,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 494043,
    "out:% savings (space heating)": 13.01,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00010201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 168.84,
    "out:Total Energy Use Post PV": 168.85,
    "out:Primary Energy": 173.19,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.69,
    "out:CO2 Operational/m2": 57.4,
    "out:Total CO2/m2": 70.08,
    "out:Total CO2 (tons)": 225.67,
    "out:Klimatpaverkan": 6.84,
    "out:Initial Cost/MSEK": 2.705,
    "out:Running cost/(Apt SEK y)": 44254,
    "out:Running Cost over RSP/MSEK": 12.452,
    "out:LCP/MSEK": -0.631,
    "out:ROI% old": 10.95,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 169,
    "out:Energy use kWh/m2": 169,
    "out:Energy savings %": 14.8,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 56,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 10,
    "out:Return/kSEK/y": 265,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 103040,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 56377,
    "out:DH kr savings": 103040,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 495039,
    "out:% savings (space heating)": 12.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 146.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 161.84,
    "out:Total Energy Use Post PV": 161.85,
    "out:Primary Energy": 167.05,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.69,
    "out:CO2 Operational/m2": 54.88,
    "out:Total CO2/m2": 67.56,
    "out:Total CO2 (tons)": 217.56,
    "out:Klimatpaverkan": -1.27,
    "out:Initial Cost/MSEK": 2.785,
    "out:Running cost/(Apt SEK y)": 42363,
    "out:Running Cost over RSP/MSEK": 11.92,
    "out:LCP/MSEK": -0.179,
    "out:ROI% old": 13.37,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 12,
    "out:Return/kSEK/y": 333,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 125580,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 78917,
    "out:DH kr savings": 125580,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 472434,
    "out:% savings (space heating)": 16.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 152.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 166.83,
    "out:Total Energy Use Post PV": 166.85,
    "out:Primary Energy": 170.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.04,
    "out:CO2 Operational/m2": 56.68,
    "out:Total CO2/m2": 69.71,
    "out:Total CO2 (tons)": 224.48,
    "out:Klimatpaverkan": 5.65,
    "out:Initial Cost/MSEK": 2.841,
    "out:Running cost/(Apt SEK y)": 43712,
    "out:Running Cost over RSP/MSEK": 12.3,
    "out:LCP/MSEK": -0.615,
    "out:ROI% old": 11.19,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 10,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62817,
    "out:DH kr savings": 109480,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 490520,
    "out:% savings (space heating)": 13.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 145.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 159.83,
    "out:Total Energy Use Post PV": 159.85,
    "out:Primary Energy": 164.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.04,
    "out:CO2 Operational/m2": 54.16,
    "out:Total CO2/m2": 67.19,
    "out:Total CO2 (tons)": 216.36,
    "out:Klimatpaverkan": -2.47,
    "out:Initial Cost/MSEK": 2.922,
    "out:Running cost/(Apt SEK y)": 41821,
    "out:Running Cost over RSP/MSEK": 11.767,
    "out:LCP/MSEK": -0.163,
    "out:ROI% old": 13.49,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 12,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85357,
    "out:DH kr savings": 132020,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 467918,
    "out:% savings (space heating)": 17.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 153.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 168.84,
    "out:Total Energy Use Post PV": 164.5,
    "out:Primary Energy": 165.37,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 41.2,
    "out:Total CO2/m2": 62.13,
    "out:Total CO2 (tons)": 200.05,
    "out:Klimatpaverkan": -18.78,
    "out:Initial Cost/MSEK": 3.151,
    "out:Running cost/(Apt SEK y)": 43060,
    "out:Running Cost over RSP/MSEK": 12.125,
    "out:LCP/MSEK": -0.751,
    "out:ROI% old": 10.88,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 154,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 10,
    "out:Return/kSEK/y": 308,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.41,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 103040,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 99362,
    "out:DH kr savings": 103040,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 495039,
    "out:% savings (space heating)": 12.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 146.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 161.84,
    "out:Total Energy Use Post PV": 157.5,
    "out:Primary Energy": 159.23,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 38.68,
    "out:Total CO2/m2": 59.61,
    "out:Total CO2 (tons)": 191.94,
    "out:Klimatpaverkan": -26.89,
    "out:Initial Cost/MSEK": 3.232,
    "out:Running cost/(Apt SEK y)": 41169,
    "out:Running Cost over RSP/MSEK": 11.593,
    "out:LCP/MSEK": -0.299,
    "out:ROI% old": 12.97,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 147,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 376,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.42,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 125580,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 121902,
    "out:DH kr savings": 125580,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 472434,
    "out:% savings (space heating)": 16.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 152.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 166.83,
    "out:Total Energy Use Post PV": 162.5,
    "out:Primary Energy": 162.75,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.27,
    "out:CO2 Operational/m2": 40.47,
    "out:Total CO2/m2": 61.75,
    "out:Total CO2 (tons)": 198.82,
    "out:Klimatpaverkan": -20.01,
    "out:Initial Cost/MSEK": 3.288,
    "out:Running cost/(Apt SEK y)": 42518,
    "out:Running Cost over RSP/MSEK": 11.973,
    "out:LCP/MSEK": -0.735,
    "out:ROI% old": 11.09,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 10,
    "out:Return/kSEK/y": 327,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.2,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.42,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105812,
    "out:DH kr savings": 109480,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 490520,
    "out:% savings (space heating)": 13.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 145.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 159.83,
    "out:Total Energy Use Post PV": 155.5,
    "out:Primary Energy": 156.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.27,
    "out:CO2 Operational/m2": 37.95,
    "out:Total CO2/m2": 59.23,
    "out:Total CO2 (tons)": 190.71,
    "out:Klimatpaverkan": -28.12,
    "out:Initial Cost/MSEK": 3.368,
    "out:Running cost/(Apt SEK y)": 40627,
    "out:Running Cost over RSP/MSEK": 11.44,
    "out:LCP/MSEK": -0.283,
    "out:ROI% old": 13.09,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 12,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.41,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128352,
    "out:DH kr savings": 132020,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 467918,
    "out:% savings (space heating)": 17.61,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 151.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 166.83,
    "out:Total Energy Use Post PV": 166.85,
    "out:Primary Energy": 169.69,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.69,
    "out:CO2 Operational/m2": 56.67,
    "out:Total CO2/m2": 69.36,
    "out:Total CO2 (tons)": 223.35,
    "out:Klimatpaverkan": 4.52,
    "out:Initial Cost/MSEK": 2.8,
    "out:Running cost/(Apt SEK y)": 43711,
    "out:Running Cost over RSP/MSEK": 12.3,
    "out:LCP/MSEK": -0.573,
    "out:ROI% old": 11.36,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 10,
    "out:Return/kSEK/y": 284,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 62817,
    "out:DH kr savings": 109480,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 489030,
    "out:% savings (space heating)": 13.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 144.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 159.83,
    "out:Total Energy Use Post PV": 159.85,
    "out:Primary Energy": 163.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.69,
    "out:CO2 Operational/m2": 54.15,
    "out:Total CO2/m2": 66.84,
    "out:Total CO2 (tons)": 215.23,
    "out:Klimatpaverkan": -3.6,
    "out:Initial Cost/MSEK": 2.88,
    "out:Running cost/(Apt SEK y)": 41820,
    "out:Running Cost over RSP/MSEK": 11.767,
    "out:LCP/MSEK": -0.121,
    "out:ROI% old": 13.68,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 12,
    "out:Return/kSEK/y": 352,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 85357,
    "out:DH kr savings": 132020,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 17.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 165.81,
    "out:Total Energy Use Post PV": 165.85,
    "out:Primary Energy": 167.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.04,
    "out:CO2 Operational/m2": 56.31,
    "out:Total CO2/m2": 69.35,
    "out:Total CO2 (tons)": 223.31,
    "out:Klimatpaverkan": 4.48,
    "out:Initial Cost/MSEK": 2.936,
    "out:Running cost/(Apt SEK y)": 43438,
    "out:Running Cost over RSP/MSEK": 12.223,
    "out:LCP/MSEK": -0.633,
    "out:ROI% old": 11.21,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 10,
    "out:Return/kSEK/y": 294,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 2,
    "out:Payback Time": 50,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 66037,
    "out:DH kr savings": 112700,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 485505,
    "out:% savings (space heating)": 14.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 158.81,
    "out:Total Energy Use Post PV": 158.85,
    "out:Primary Energy": 161.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.04,
    "out:CO2 Operational/m2": 53.79,
    "out:Total CO2/m2": 66.83,
    "out:Total CO2 (tons)": 215.19,
    "out:Klimatpaverkan": -3.64,
    "out:Initial Cost/MSEK": 3.017,
    "out:Running cost/(Apt SEK y)": 41546,
    "out:Running Cost over RSP/MSEK": 11.69,
    "out:LCP/MSEK": -0.181,
    "out:ROI% old": 13.43,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 12,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 88577,
    "out:DH kr savings": 135240,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462916,
    "out:% savings (space heating)": 18.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00010201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 151.87,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 166.83,
    "out:Total Energy Use Post PV": 162.5,
    "out:Primary Energy": 161.87,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 40.47,
    "out:Total CO2/m2": 61.39,
    "out:Total CO2 (tons)": 197.67,
    "out:Klimatpaverkan": -21.16,
    "out:Initial Cost/MSEK": 3.246,
    "out:Running cost/(Apt SEK y)": 42517,
    "out:Running Cost over RSP/MSEK": 11.972,
    "out:LCP/MSEK": -0.693,
    "out:ROI% old": 11.24,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 327,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.44,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.18,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 105817,
    "out:DH kr savings": 109480,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 489030,
    "out:% savings (space heating)": 13.9,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 144.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 159.83,
    "out:Total Energy Use Post PV": 155.5,
    "out:Primary Energy": 155.73,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 20.92,
    "out:CO2 Operational/m2": 37.95,
    "out:Total CO2/m2": 58.87,
    "out:Total CO2 (tons)": 189.56,
    "out:Klimatpaverkan": -29.27,
    "out:Initial Cost/MSEK": 3.327,
    "out:Running cost/(Apt SEK y)": 40626,
    "out:Running Cost over RSP/MSEK": 11.44,
    "out:LCP/MSEK": -0.241,
    "out:ROI% old": 13.25,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 395,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.79,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.82,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 128357,
    "out:DH kr savings": 132020,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 466431,
    "out:% savings (space heating)": 17.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 165.81,
    "out:Total Energy Use Post PV": 161.5,
    "out:Primary Energy": 159.77,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.27,
    "out:CO2 Operational/m2": 40.09,
    "out:Total CO2/m2": 61.36,
    "out:Total CO2 (tons)": 197.58,
    "out:Klimatpaverkan": -21.25,
    "out:Initial Cost/MSEK": 3.383,
    "out:Running cost/(Apt SEK y)": 42244,
    "out:Running Cost over RSP/MSEK": 11.895,
    "out:LCP/MSEK": -0.753,
    "out:ROI% old": 11.11,
    "out:Payback discounted": 11,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 10,
    "out:Return/kSEK/y": 337,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 3,
    "out:Payback Time": 33.333333,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 109053,
    "out:DH kr savings": 112700,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 485505,
    "out:% savings (space heating)": 14.52,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 160.45,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00010201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 158.81,
    "out:Total Energy Use Post PV": 154.5,
    "out:Primary Energy": 153.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.27,
    "out:CO2 Operational/m2": 37.57,
    "out:Total CO2/m2": 58.84,
    "out:Total CO2 (tons)": 189.46,
    "out:Klimatpaverkan": -29.37,
    "out:Initial Cost/MSEK": 3.463,
    "out:Running cost/(Apt SEK y)": 40353,
    "out:Running Cost over RSP/MSEK": 11.363,
    "out:LCP/MSEK": -0.3,
    "out:ROI% old": 13.05,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 1.69,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 12,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 131594,
    "out:DH kr savings": 135240,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462916,
    "out:% savings (space heating)": 18.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 153.43,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.42,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 167.6,
    "out:Total Energy Use Post PV": 167.6,
    "out:Primary Energy": 162.79,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.04,
    "out:CO2 Operational/m2": 58.6,
    "out:Total CO2/m2": 59.64,
    "out:Total CO2 (tons)": 192.05,
    "out:Klimatpaverkan": -26.78,
    "out:Initial Cost/MSEK": 0.29,
    "out:Running cost/(Apt SEK y)": 44579,
    "out:Running Cost over RSP/MSEK": 12.548,
    "out:LCP/MSEK": 1.687,
    "out:ROI% old": 97.29,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 168,
    "out:Energy use kWh/m2": 168,
    "out:Energy savings %": 15.5,
    "out:Op energy cost/MSEK": 92,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 87,
    "out:Return/kSEK/y": 253,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 29,
    "out:Payback Time": 3.448276,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 83720,
    "out:DH kr savings": 83720,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 484342,
    "out:% savings (space heating)": 14.72,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.85,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 160.6,
    "out:Total Energy Use Post PV": 160.6,
    "out:Primary Energy": 157.05,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.04,
    "out:CO2 Operational/m2": 56.08,
    "out:Total CO2/m2": 57.12,
    "out:Total CO2 (tons)": 183.94,
    "out:Klimatpaverkan": -34.89,
    "out:Initial Cost/MSEK": 0.371,
    "out:Running cost/(Apt SEK y)": 42688,
    "out:Running Cost over RSP/MSEK": 12.016,
    "out:LCP/MSEK": 2.139,
    "out:ROI% old": 96.68,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 87,
    "out:Return/kSEK/y": 321,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 29,
    "out:Payback Time": 3.448276,
    "out:DH kWh savings": 106260,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106260,
    "out:DH kr savings": 106260,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 463205,
    "out:% savings (space heating)": 18.44,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.42,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 166.6,
    "out:Total Energy Use Post PV": 166.6,
    "out:Primary Energy": 160.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.39,
    "out:CO2 Operational/m2": 58.24,
    "out:Total CO2/m2": 59.63,
    "out:Total CO2 (tons)": 192.02,
    "out:Klimatpaverkan": -26.81,
    "out:Initial Cost/MSEK": 0.427,
    "out:Running cost/(Apt SEK y)": 44309,
    "out:Running Cost over RSP/MSEK": 12.472,
    "out:LCP/MSEK": 1.627,
    "out:ROI% old": 68.71,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 61,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86940,
    "out:DH kr savings": 86940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 484342,
    "out:% savings (space heating)": 14.72,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.85,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 159.6,
    "out:Total Energy Use Post PV": 159.6,
    "out:Primary Energy": 154.43,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.39,
    "out:CO2 Operational/m2": 55.72,
    "out:Total CO2/m2": 57.11,
    "out:Total CO2 (tons)": 183.91,
    "out:Klimatpaverkan": -34.92,
    "out:Initial Cost/MSEK": 0.507,
    "out:Running cost/(Apt SEK y)": 42418,
    "out:Running Cost over RSP/MSEK": 11.94,
    "out:LCP/MSEK": 2.079,
    "out:ROI% old": 72.8,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 65,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 22,
    "out:Payback Time": 4.545455,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109480,
    "out:DH kr savings": 109480,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 463205,
    "out:% savings (space heating)": 18.44,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.42,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 167.6,
    "out:Total Energy Use Post PV": 164.91,
    "out:Primary Energy": 157.96,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.28,
    "out:CO2 Operational/m2": 34.12,
    "out:Total CO2/m2": 43.4,
    "out:Total CO2 (tons)": 139.75,
    "out:Klimatpaverkan": -79.08,
    "out:Initial Cost/MSEK": 0.737,
    "out:Running cost/(Apt SEK y)": 43459,
    "out:Running Cost over RSP/MSEK": 12.241,
    "out:LCP/MSEK": 1.547,
    "out:ROI% old": 44.28,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 32,
    "out:DH+ DHW/kWh/m2": 160,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 40,
    "out:Return/kSEK/y": 293,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 83720,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 124036,
    "out:DH kr savings": 83720,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 484342,
    "out:% savings (space heating)": 14.72,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.85,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 160.6,
    "out:Total Energy Use Post PV": 157.91,
    "out:Primary Energy": 152.21,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.28,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 40.88,
    "out:Total CO2 (tons)": 131.63,
    "out:Klimatpaverkan": -87.2,
    "out:Initial Cost/MSEK": 0.817,
    "out:Running cost/(Apt SEK y)": 41568,
    "out:Running Cost over RSP/MSEK": 11.709,
    "out:LCP/MSEK": 1.999,
    "out:ROI% old": 49.23,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 44,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 106260,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146576,
    "out:DH kr savings": 106260,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 463205,
    "out:% savings (space heating)": 18.44,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.42,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 166.6,
    "out:Total Energy Use Post PV": 163.91,
    "out:Primary Energy": 155.33,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.63,
    "out:CO2 Operational/m2": 33.76,
    "out:Total CO2/m2": 43.39,
    "out:Total CO2 (tons)": 139.72,
    "out:Klimatpaverkan": -79.11,
    "out:Initial Cost/MSEK": 0.874,
    "out:Running cost/(Apt SEK y)": 43189,
    "out:Running Cost over RSP/MSEK": 12.165,
    "out:LCP/MSEK": 1.487,
    "out:ROI% old": 38.6,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 18.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 35,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127256,
    "out:DH kr savings": 86940,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 484342,
    "out:% savings (space heating)": 14.72,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.85,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 159.6,
    "out:Total Energy Use Post PV": 156.91,
    "out:Primary Energy": 149.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.63,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 40.87,
    "out:Total CO2 (tons)": 131.6,
    "out:Klimatpaverkan": -87.23,
    "out:Initial Cost/MSEK": 0.954,
    "out:Running cost/(Apt SEK y)": 41298,
    "out:Running Cost over RSP/MSEK": 11.633,
    "out:LCP/MSEK": 1.939,
    "out:ROI% old": 43.32,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 39,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149796,
    "out:DH kr savings": 109480,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 463205,
    "out:% savings (space heating)": 18.44,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.42,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 165.6,
    "out:Primary Energy": 159.29,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.04,
    "out:CO2 Operational/m2": 57.88,
    "out:Total CO2/m2": 58.92,
    "out:Total CO2 (tons)": 189.73,
    "out:Klimatpaverkan": -29.1,
    "out:Initial Cost/MSEK": 0.385,
    "out:Running cost/(Apt SEK y)": 44039,
    "out:Running Cost over RSP/MSEK": 12.396,
    "out:LCP/MSEK": 1.744,
    "out:ROI% old": 78.94,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 71,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 23,
    "out:Payback Time": 4.347826,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90160,
    "out:DH kr savings": 90160,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 484342,
    "out:% savings (space heating)": 14.72,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.85,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 159.6,
    "out:Total Energy Use Post PV": 159.6,
    "out:Primary Energy": 153.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.04,
    "out:CO2 Operational/m2": 55.72,
    "out:Total CO2/m2": 56.76,
    "out:Total CO2 (tons)": 182.78,
    "out:Klimatpaverkan": -36.05,
    "out:Initial Cost/MSEK": 0.466,
    "out:Running cost/(Apt SEK y)": 42418,
    "out:Running Cost over RSP/MSEK": 11.94,
    "out:LCP/MSEK": 2.12,
    "out:ROI% old": 79.3,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 160,
    "out:Energy use kWh/m2": 160,
    "out:Energy savings %": 21.2,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 71,
    "out:Return/kSEK/y": 331,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 23,
    "out:Payback Time": 4.347826,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 109480,
    "out:DH kr savings": 109480,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 463205,
    "out:% savings (space heating)": 18.44,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.42,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 164.6,
    "out:Total Energy Use Post PV": 164.6,
    "out:Primary Energy": 157.19,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.39,
    "out:CO2 Operational/m2": 57.52,
    "out:Total CO2/m2": 58.91,
    "out:Total CO2 (tons)": 189.7,
    "out:Klimatpaverkan": -29.13,
    "out:Initial Cost/MSEK": 0.522,
    "out:Running cost/(Apt SEK y)": 43769,
    "out:Running Cost over RSP/MSEK": 12.32,
    "out:LCP/MSEK": 1.684,
    "out:ROI% old": 60.37,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 54,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93380,
    "out:DH kr savings": 93380,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 484342,
    "out:% savings (space heating)": 14.72,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.85,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 157.6,
    "out:Total Energy Use Post PV": 157.6,
    "out:Primary Energy": 151.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.39,
    "out:CO2 Operational/m2": 55,
    "out:Total CO2/m2": 56.39,
    "out:Total CO2 (tons)": 181.59,
    "out:Klimatpaverkan": -37.24,
    "out:Initial Cost/MSEK": 0.603,
    "out:Running cost/(Apt SEK y)": 41878,
    "out:Running Cost over RSP/MSEK": 11.788,
    "out:LCP/MSEK": 2.136,
    "out:ROI% old": 64.92,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 58,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115920,
    "out:DH kr savings": 115920,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 463205,
    "out:% savings (space heating)": 18.44,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.42,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 162.91,
    "out:Primary Energy": 154.46,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.28,
    "out:CO2 Operational/m2": 33.4,
    "out:Total CO2/m2": 42.68,
    "out:Total CO2 (tons)": 137.43,
    "out:Klimatpaverkan": -81.4,
    "out:Initial Cost/MSEK": 0.832,
    "out:Running cost/(Apt SEK y)": 42919,
    "out:Running Cost over RSP/MSEK": 12.089,
    "out:LCP/MSEK": 1.605,
    "out:ROI% old": 41.84,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 38,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130476,
    "out:DH kr savings": 90160,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 484342,
    "out:% savings (space heating)": 14.72,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.85,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 159.6,
    "out:Total Energy Use Post PV": 156.91,
    "out:Primary Energy": 148.71,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.28,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 40.52,
    "out:Total CO2 (tons)": 130.48,
    "out:Klimatpaverkan": -88.35,
    "out:Initial Cost/MSEK": 0.912,
    "out:Running cost/(Apt SEK y)": 41298,
    "out:Running Cost over RSP/MSEK": 11.633,
    "out:LCP/MSEK": 1.98,
    "out:ROI% old": 45.29,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 152,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 41,
    "out:Return/kSEK/y": 371,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 109480,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 149796,
    "out:DH kr savings": 109480,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 463205,
    "out:% savings (space heating)": 18.44,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 150.42,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 164.6,
    "out:Total Energy Use Post PV": 161.91,
    "out:Primary Energy": 152.36,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.63,
    "out:CO2 Operational/m2": 33.04,
    "out:Total CO2/m2": 42.67,
    "out:Total CO2 (tons)": 137.4,
    "out:Klimatpaverkan": -81.43,
    "out:Initial Cost/MSEK": 0.969,
    "out:Running cost/(Apt SEK y)": 42649,
    "out:Running Cost over RSP/MSEK": 12.013,
    "out:LCP/MSEK": 1.544,
    "out:ROI% old": 37.06,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133696,
    "out:DH kr savings": 93380,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 484342,
    "out:% savings (space heating)": 14.72,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.85,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 157.6,
    "out:Total Energy Use Post PV": 154.91,
    "out:Primary Energy": 146.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.63,
    "out:CO2 Operational/m2": 30.52,
    "out:Total CO2/m2": 40.15,
    "out:Total CO2 (tons)": 129.29,
    "out:Klimatpaverkan": -89.54,
    "out:Initial Cost/MSEK": 1.049,
    "out:Running cost/(Apt SEK y)": 40758,
    "out:Running Cost over RSP/MSEK": 11.481,
    "out:LCP/MSEK": 1.996,
    "out:ROI% old": 41.47,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 37,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156236,
    "out:DH kr savings": 115920,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 463205,
    "out:% savings (space heating)": 18.44,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 158.84,
    "out:Total Energy Use Post PV": 158.85,
    "out:Primary Energy": 164.41,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.31,
    "out:CO2 Operational/m2": 53.8,
    "out:Total CO2/m2": 58.1,
    "out:Total CO2 (tons)": 187.09,
    "out:Klimatpaverkan": -31.74,
    "out:Initial Cost/MSEK": 0.901,
    "out:Running cost/(Apt SEK y)": 41553,
    "out:Running Cost over RSP/MSEK": 11.692,
    "out:LCP/MSEK": 1.933,
    "out:ROI% old": 44.93,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 40,
    "out:Return/kSEK/y": 362,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 88577,
    "out:DH kr savings": 135240,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462733,
    "out:% savings (space heating)": 18.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 137.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 151.84,
    "out:Total Energy Use Post PV": 151.85,
    "out:Primary Energy": 158.67,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.31,
    "out:CO2 Operational/m2": 51.28,
    "out:Total CO2/m2": 55.58,
    "out:Total CO2 (tons)": 178.98,
    "out:Klimatpaverkan": -39.85,
    "out:Initial Cost/MSEK": 0.982,
    "out:Running cost/(Apt SEK y)": 39662,
    "out:Running Cost over RSP/MSEK": 11.159,
    "out:LCP/MSEK": 2.385,
    "out:ROI% old": 49,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 44,
    "out:Return/kSEK/y": 430,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 111117,
    "out:DH kr savings": 157780,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 441596,
    "out:% savings (space heating)": 22.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 156.85,
    "out:Primary Energy": 161.79,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.66,
    "out:CO2 Operational/m2": 53.08,
    "out:Total CO2/m2": 57.73,
    "out:Total CO2 (tons)": 185.9,
    "out:Klimatpaverkan": -32.93,
    "out:Initial Cost/MSEK": 1.038,
    "out:Running cost/(Apt SEK y)": 41010,
    "out:Running Cost over RSP/MSEK": 11.539,
    "out:LCP/MSEK": 1.949,
    "out:ROI% old": 41.12,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 37,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95017,
    "out:DH kr savings": 141680,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 458214,
    "out:% savings (space heating)": 19.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 150.83,
    "out:Total Energy Use Post PV": 150.85,
    "out:Primary Energy": 156.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.66,
    "out:CO2 Operational/m2": 50.92,
    "out:Total CO2/m2": 55.57,
    "out:Total CO2 (tons)": 178.94,
    "out:Klimatpaverkan": -39.89,
    "out:Initial Cost/MSEK": 1.118,
    "out:Running cost/(Apt SEK y)": 39390,
    "out:Running Cost over RSP/MSEK": 11.083,
    "out:LCP/MSEK": 2.325,
    "out:ROI% old": 43.99,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 39,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114337,
    "out:DH kr savings": 161000,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437080,
    "out:% savings (space heating)": 23.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 158.84,
    "out:Total Energy Use Post PV": 154.5,
    "out:Primary Energy": 156.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.54,
    "out:CO2 Operational/m2": 37.6,
    "out:Total CO2/m2": 50.15,
    "out:Total CO2 (tons)": 161.47,
    "out:Klimatpaverkan": -57.36,
    "out:Initial Cost/MSEK": 1.348,
    "out:Running cost/(Apt SEK y)": 40359,
    "out:Running Cost over RSP/MSEK": 11.365,
    "out:LCP/MSEK": 1.814,
    "out:ROI% old": 33.51,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 405,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.43,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 131562,
    "out:DH kr savings": 135240,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 462733,
    "out:% savings (space heating)": 18.53,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 137.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 151.84,
    "out:Total Energy Use Post PV": 147.5,
    "out:Primary Energy": 150.85,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.54,
    "out:CO2 Operational/m2": 35.08,
    "out:Total CO2/m2": 47.63,
    "out:Total CO2 (tons)": 153.36,
    "out:Klimatpaverkan": -65.47,
    "out:Initial Cost/MSEK": 1.428,
    "out:Running cost/(Apt SEK y)": 38468,
    "out:Running Cost over RSP/MSEK": 10.832,
    "out:LCP/MSEK": 2.266,
    "out:ROI% old": 36.95,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 33,
    "out:Return/kSEK/y": 473,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.45,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.17,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154102,
    "out:DH kr savings": 157780,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 441596,
    "out:% savings (space heating)": 22.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 152.5,
    "out:Primary Energy": 153.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.89,
    "out:CO2 Operational/m2": 36.87,
    "out:Total CO2/m2": 49.76,
    "out:Total CO2 (tons)": 160.24,
    "out:Klimatpaverkan": -58.59,
    "out:Initial Cost/MSEK": 1.484,
    "out:Running cost/(Apt SEK y)": 39817,
    "out:Running Cost over RSP/MSEK": 11.212,
    "out:LCP/MSEK": 1.83,
    "out:ROI% old": 31.9,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 26.8,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 29,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.25,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.37,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138012,
    "out:DH kr savings": 141680,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 458214,
    "out:% savings (space heating)": 19.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 150.83,
    "out:Total Energy Use Post PV": 146.5,
    "out:Primary Energy": 148.23,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.89,
    "out:CO2 Operational/m2": 34.71,
    "out:Total CO2/m2": 47.6,
    "out:Total CO2 (tons)": 153.29,
    "out:Klimatpaverkan": -65.54,
    "out:Initial Cost/MSEK": 1.565,
    "out:Running cost/(Apt SEK y)": 38196,
    "out:Running Cost over RSP/MSEK": 10.756,
    "out:LCP/MSEK": 2.205,
    "out:ROI% old": 34.42,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 31,
    "out:Return/kSEK/y": 483,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.46,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.16,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157332,
    "out:DH kr savings": 161000,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437080,
    "out:% savings (space heating)": 23.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 156.85,
    "out:Primary Energy": 160.91,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.31,
    "out:CO2 Operational/m2": 53.07,
    "out:Total CO2/m2": 57.38,
    "out:Total CO2 (tons)": 184.77,
    "out:Klimatpaverkan": -34.06,
    "out:Initial Cost/MSEK": 0.996,
    "out:Running cost/(Apt SEK y)": 41010,
    "out:Running Cost over RSP/MSEK": 11.539,
    "out:LCP/MSEK": 1.991,
    "out:ROI% old": 42.84,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 38,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95017,
    "out:DH kr savings": 141680,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 456724,
    "out:% savings (space heating)": 19.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 149.85,
    "out:Primary Energy": 155.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.31,
    "out:CO2 Operational/m2": 50.55,
    "out:Total CO2/m2": 54.86,
    "out:Total CO2 (tons)": 176.65,
    "out:Klimatpaverkan": -42.18,
    "out:Initial Cost/MSEK": 1.077,
    "out:Running cost/(Apt SEK y)": 39119,
    "out:Running Cost over RSP/MSEK": 11.006,
    "out:LCP/MSEK": 2.443,
    "out:ROI% old": 46.7,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 42,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117557,
    "out:DH kr savings": 164220,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 435593,
    "out:% savings (space heating)": 23.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 140.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 155.81,
    "out:Total Energy Use Post PV": 155.85,
    "out:Primary Energy": 158.81,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.66,
    "out:CO2 Operational/m2": 52.71,
    "out:Total CO2/m2": 57.37,
    "out:Total CO2 (tons)": 184.73,
    "out:Klimatpaverkan": -34.1,
    "out:Initial Cost/MSEK": 1.133,
    "out:Running cost/(Apt SEK y)": 40736,
    "out:Running Cost over RSP/MSEK": 11.462,
    "out:LCP/MSEK": 1.931,
    "out:ROI% old": 38.64,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 35,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98237,
    "out:DH kr savings": 144900,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 453197,
    "out:% savings (space heating)": 20.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 148.81,
    "out:Total Energy Use Post PV": 148.85,
    "out:Primary Energy": 153.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.66,
    "out:CO2 Operational/m2": 50.19,
    "out:Total CO2/m2": 54.85,
    "out:Total CO2 (tons)": 176.61,
    "out:Klimatpaverkan": -42.22,
    "out:Initial Cost/MSEK": 1.213,
    "out:Running cost/(Apt SEK y)": 38845,
    "out:Running Cost over RSP/MSEK": 10.929,
    "out:LCP/MSEK": 2.383,
    "out:ROI% old": 42.35,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 38,
    "out:Return/kSEK/y": 459,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 120777,
    "out:DH kr savings": 167440,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 432077,
    "out:% savings (space heating)": 23.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 152.5,
    "out:Primary Energy": 153.09,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.54,
    "out:CO2 Operational/m2": 36.87,
    "out:Total CO2/m2": 49.41,
    "out:Total CO2 (tons)": 159.09,
    "out:Klimatpaverkan": -59.74,
    "out:Initial Cost/MSEK": 1.443,
    "out:Running cost/(Apt SEK y)": 39816,
    "out:Running Cost over RSP/MSEK": 11.212,
    "out:LCP/MSEK": 1.871,
    "out:ROI% old": 32.82,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 26.8,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 29,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.49,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138017,
    "out:DH kr savings": 141680,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 456724,
    "out:% savings (space heating)": 19.58,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.28,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 145.5,
    "out:Primary Energy": 147.35,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.54,
    "out:CO2 Operational/m2": 34.35,
    "out:Total CO2/m2": 46.89,
    "out:Total CO2 (tons)": 150.98,
    "out:Klimatpaverkan": -67.85,
    "out:Initial Cost/MSEK": 1.523,
    "out:Running cost/(Apt SEK y)": 37925,
    "out:Running Cost over RSP/MSEK": 10.679,
    "out:LCP/MSEK": 2.323,
    "out:ROI% old": 36.08,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 32,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.84,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.78,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160557,
    "out:DH kr savings": 164220,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 435593,
    "out:% savings (space heating)": 23.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 140.74,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 155.81,
    "out:Total Energy Use Post PV": 151.5,
    "out:Primary Energy": 150.99,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.89,
    "out:CO2 Operational/m2": 36.49,
    "out:Total CO2/m2": 49.38,
    "out:Total CO2 (tons)": 159,
    "out:Klimatpaverkan": -59.83,
    "out:Initial Cost/MSEK": 1.579,
    "out:Running cost/(Apt SEK y)": 39543,
    "out:Running Cost over RSP/MSEK": 11.135,
    "out:LCP/MSEK": 1.812,
    "out:ROI% old": 30.67,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 27,
    "out:Return/kSEK/y": 434,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.03,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.59,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141253,
    "out:DH kr savings": 144900,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 453197,
    "out:% savings (space heating)": 20.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 150.42,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 148.81,
    "out:Total Energy Use Post PV": 144.5,
    "out:Primary Energy": 145.25,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.89,
    "out:CO2 Operational/m2": 33.97,
    "out:Total CO2/m2": 46.86,
    "out:Total CO2 (tons)": 150.88,
    "out:Klimatpaverkan": -67.95,
    "out:Initial Cost/MSEK": 1.66,
    "out:Running cost/(Apt SEK y)": 37652,
    "out:Running Cost over RSP/MSEK": 10.602,
    "out:LCP/MSEK": 2.264,
    "out:ROI% old": 33.77,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.56,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 502,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.62,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 163794,
    "out:DH kr savings": 167440,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 432077,
    "out:% savings (space heating)": 23.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.85,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.75,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 153.6,
    "out:Total Energy Use Post PV": 153.6,
    "out:Primary Energy": 150.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.56,
    "out:CO2 Operational/m2": 53.56,
    "out:Total CO2/m2": 57.12,
    "out:Total CO2 (tons)": 183.93,
    "out:Klimatpaverkan": -34.9,
    "out:Initial Cost/MSEK": 0.898,
    "out:Running cost/(Apt SEK y)": 40797,
    "out:Running Cost over RSP/MSEK": 11.483,
    "out:LCP/MSEK": 2.145,
    "out:ROI% old": 48.42,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 43,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 128800,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128800,
    "out:DH kr savings": 128800,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440346,
    "out:% savings (space heating)": 22.47,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.81,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 147.6,
    "out:Primary Energy": 145.64,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.56,
    "out:CO2 Operational/m2": 51.4,
    "out:Total CO2/m2": 54.96,
    "out:Total CO2 (tons)": 176.98,
    "out:Klimatpaverkan": -41.85,
    "out:Initial Cost/MSEK": 0.978,
    "out:Running cost/(Apt SEK y)": 39177,
    "out:Running Cost over RSP/MSEK": 11.027,
    "out:LCP/MSEK": 2.521,
    "out:ROI% old": 51.1,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 46,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148120,
    "out:DH kr savings": 148120,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 421208,
    "out:% savings (space heating)": 25.84,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.75,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 152.6,
    "out:Total Energy Use Post PV": 152.6,
    "out:Primary Energy": 148.21,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.91,
    "out:CO2 Operational/m2": 53.2,
    "out:Total CO2/m2": 57.11,
    "out:Total CO2 (tons)": 183.9,
    "out:Klimatpaverkan": -34.93,
    "out:Initial Cost/MSEK": 1.034,
    "out:Running cost/(Apt SEK y)": 40527,
    "out:Running Cost over RSP/MSEK": 11.407,
    "out:LCP/MSEK": 2.084,
    "out:ROI% old": 43.08,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 26.8,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 39,
    "out:Return/kSEK/y": 399,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 132020,
    "out:DH kr savings": 132020,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440346,
    "out:% savings (space heating)": 22.47,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.81,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 146.6,
    "out:Primary Energy": 143.01,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.91,
    "out:CO2 Operational/m2": 51.04,
    "out:Total CO2/m2": 54.95,
    "out:Total CO2 (tons)": 176.95,
    "out:Klimatpaverkan": -41.88,
    "out:Initial Cost/MSEK": 1.115,
    "out:Running cost/(Apt SEK y)": 38907,
    "out:Running Cost over RSP/MSEK": 10.951,
    "out:LCP/MSEK": 2.46,
    "out:ROI% old": 45.81,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 41,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 151340,
    "out:DH kr savings": 151340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 421208,
    "out:% savings (space heating)": 25.84,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.75,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 153.6,
    "out:Total Energy Use Post PV": 150.91,
    "out:Primary Energy": 146,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 29.08,
    "out:Total CO2/m2": 40.88,
    "out:Total CO2 (tons)": 131.63,
    "out:Klimatpaverkan": -87.2,
    "out:Initial Cost/MSEK": 1.344,
    "out:Running cost/(Apt SEK y)": 39678,
    "out:Running Cost over RSP/MSEK": 11.177,
    "out:LCP/MSEK": 2.005,
    "out:ROI% old": 35.6,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 32,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 128800,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169116,
    "out:DH kr savings": 128800,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440346,
    "out:% savings (space heating)": 22.47,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.81,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 144.91,
    "out:Primary Energy": 140.8,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 26.92,
    "out:Total CO2/m2": 38.72,
    "out:Total CO2 (tons)": 124.67,
    "out:Klimatpaverkan": -94.16,
    "out:Initial Cost/MSEK": 1.425,
    "out:Running cost/(Apt SEK y)": 38057,
    "out:Running Cost over RSP/MSEK": 10.72,
    "out:LCP/MSEK": 2.381,
    "out:ROI% old": 38.16,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 34,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188436,
    "out:DH kr savings": 148120,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 421208,
    "out:% savings (space heating)": 25.84,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.75,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 152.6,
    "out:Total Energy Use Post PV": 149.91,
    "out:Primary Energy": 143.38,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.15,
    "out:CO2 Operational/m2": 28.72,
    "out:Total CO2/m2": 40.87,
    "out:Total CO2 (tons)": 131.6,
    "out:Klimatpaverkan": -87.23,
    "out:Initial Cost/MSEK": 1.481,
    "out:Running cost/(Apt SEK y)": 39407,
    "out:Running Cost over RSP/MSEK": 11.101,
    "out:LCP/MSEK": 1.945,
    "out:ROI% old": 33.05,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 145,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 30,
    "out:Return/kSEK/y": 439,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 132020,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172336,
    "out:DH kr savings": 132020,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440346,
    "out:% savings (space heating)": 22.47,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.81,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 143.91,
    "out:Primary Energy": 138.18,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.15,
    "out:CO2 Operational/m2": 26.56,
    "out:Total CO2/m2": 38.71,
    "out:Total CO2 (tons)": 124.64,
    "out:Klimatpaverkan": -94.19,
    "out:Initial Cost/MSEK": 1.561,
    "out:Running cost/(Apt SEK y)": 37787,
    "out:Running Cost over RSP/MSEK": 10.644,
    "out:LCP/MSEK": 2.32,
    "out:ROI% old": 35.52,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 32,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 151340,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 421208,
    "out:% savings (space heating)": 25.84,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.75,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 151.6,
    "out:Primary Energy": 147.34,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.56,
    "out:CO2 Operational/m2": 52.84,
    "out:Total CO2/m2": 56.4,
    "out:Total CO2 (tons)": 181.61,
    "out:Klimatpaverkan": -37.22,
    "out:Initial Cost/MSEK": 0.993,
    "out:Running cost/(Apt SEK y)": 40257,
    "out:Running Cost over RSP/MSEK": 11.331,
    "out:LCP/MSEK": 2.202,
    "out:ROI% old": 45.98,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 41,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135240,
    "out:DH kr savings": 135240,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440346,
    "out:% savings (space heating)": 22.47,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.81,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 145.6,
    "out:Primary Energy": 142.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.56,
    "out:CO2 Operational/m2": 50.68,
    "out:Total CO2/m2": 54.24,
    "out:Total CO2 (tons)": 174.66,
    "out:Klimatpaverkan": -44.17,
    "out:Initial Cost/MSEK": 1.073,
    "out:Running cost/(Apt SEK y)": 38636,
    "out:Running Cost over RSP/MSEK": 10.875,
    "out:LCP/MSEK": 2.578,
    "out:ROI% old": 48.6,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 43,
    "out:Return/kSEK/y": 467,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154560,
    "out:DH kr savings": 154560,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 421208,
    "out:% savings (space heating)": 25.84,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.75,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 150.6,
    "out:Primary Energy": 145.24,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.91,
    "out:CO2 Operational/m2": 52.48,
    "out:Total CO2/m2": 56.39,
    "out:Total CO2 (tons)": 181.58,
    "out:Klimatpaverkan": -37.25,
    "out:Initial Cost/MSEK": 1.129,
    "out:Running cost/(Apt SEK y)": 39987,
    "out:Running Cost over RSP/MSEK": 11.255,
    "out:LCP/MSEK": 2.141,
    "out:ROI% old": 41.38,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 37,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138460,
    "out:DH kr savings": 138460,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440346,
    "out:% savings (space heating)": 22.47,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.81,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 144.6,
    "out:Primary Energy": 140.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.91,
    "out:CO2 Operational/m2": 50.32,
    "out:Total CO2/m2": 54.23,
    "out:Total CO2 (tons)": 174.63,
    "out:Klimatpaverkan": -44.2,
    "out:Initial Cost/MSEK": 1.21,
    "out:Running cost/(Apt SEK y)": 38366,
    "out:Running Cost over RSP/MSEK": 10.799,
    "out:LCP/MSEK": 2.517,
    "out:ROI% old": 44.01,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 39,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157780,
    "out:DH kr savings": 157780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 421208,
    "out:% savings (space heating)": 25.84,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.75,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 148.91,
    "out:Primary Energy": 142.5,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 28.36,
    "out:Total CO2/m2": 40.16,
    "out:Total CO2 (tons)": 129.31,
    "out:Klimatpaverkan": -89.52,
    "out:Initial Cost/MSEK": 1.439,
    "out:Running cost/(Apt SEK y)": 39137,
    "out:Running Cost over RSP/MSEK": 11.024,
    "out:LCP/MSEK": 2.062,
    "out:ROI% old": 34.76,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 31,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175556,
    "out:DH kr savings": 135240,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440346,
    "out:% savings (space heating)": 22.47,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.81,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 142.91,
    "out:Primary Energy": 137.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.8,
    "out:CO2 Operational/m2": 26.2,
    "out:Total CO2/m2": 38,
    "out:Total CO2 (tons)": 122.36,
    "out:Klimatpaverkan": -96.47,
    "out:Initial Cost/MSEK": 1.52,
    "out:Running cost/(Apt SEK y)": 37517,
    "out:Running Cost over RSP/MSEK": 10.568,
    "out:LCP/MSEK": 2.438,
    "out:ROI% old": 37.21,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194876,
    "out:DH kr savings": 154560,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 421208,
    "out:% savings (space heating)": 25.84,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.75,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 147.91,
    "out:Primary Energy": 140.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.15,
    "out:CO2 Operational/m2": 28,
    "out:Total CO2/m2": 40.15,
    "out:Total CO2 (tons)": 129.28,
    "out:Klimatpaverkan": -89.55,
    "out:Initial Cost/MSEK": 1.576,
    "out:Running cost/(Apt SEK y)": 38867,
    "out:Running Cost over RSP/MSEK": 10.948,
    "out:LCP/MSEK": 2.002,
    "out:ROI% old": 32.43,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 29,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178776,
    "out:DH kr savings": 138460,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 440346,
    "out:% savings (space heating)": 22.47,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.81,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 141.91,
    "out:Primary Energy": 135.2,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.15,
    "out:CO2 Operational/m2": 25.84,
    "out:Total CO2/m2": 37.99,
    "out:Total CO2 (tons)": 122.32,
    "out:Klimatpaverkan": -96.51,
    "out:Initial Cost/MSEK": 1.656,
    "out:Running cost/(Apt SEK y)": 37246,
    "out:Running Cost over RSP/MSEK": 10.492,
    "out:LCP/MSEK": 2.378,
    "out:ROI% old": 34.79,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 31,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198096,
    "out:DH kr savings": 157780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 421208,
    "out:% savings (space heating)": 25.84,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 144.84,
    "out:Total Energy Use Post PV": 144.85,
    "out:Primary Energy": 152.46,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.82,
    "out:CO2 Operational/m2": 48.76,
    "out:Total CO2/m2": 55.58,
    "out:Total CO2 (tons)": 178.97,
    "out:Klimatpaverkan": -39.86,
    "out:Initial Cost/MSEK": 1.508,
    "out:Running cost/(Apt SEK y)": 37771,
    "out:Running Cost over RSP/MSEK": 10.627,
    "out:LCP/MSEK": 2.391,
    "out:ROI% old": 36.93,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 498,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 180320,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133657,
    "out:DH kr savings": 180320,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418737,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 138.84,
    "out:Total Energy Use Post PV": 138.85,
    "out:Primary Energy": 147.26,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.82,
    "out:CO2 Operational/m2": 46.6,
    "out:Total CO2/m2": 53.42,
    "out:Total CO2 (tons)": 172.02,
    "out:Klimatpaverkan": -46.81,
    "out:Initial Cost/MSEK": 1.589,
    "out:Running cost/(Apt SEK y)": 36150,
    "out:Running Cost over RSP/MSEK": 10.171,
    "out:LCP/MSEK": 2.767,
    "out:ROI% old": 39.16,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 35,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 152977,
    "out:DH kr savings": 199640,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 399600,
    "out:% savings (space heating)": 29.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 143.83,
    "out:Total Energy Use Post PV": 143.85,
    "out:Primary Energy": 149.83,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.17,
    "out:CO2 Operational/m2": 48.4,
    "out:Total CO2/m2": 55.57,
    "out:Total CO2 (tons)": 178.94,
    "out:Klimatpaverkan": -39.89,
    "out:Initial Cost/MSEK": 1.645,
    "out:Running cost/(Apt SEK y)": 37499,
    "out:Running Cost over RSP/MSEK": 10.55,
    "out:LCP/MSEK": 2.331,
    "out:ROI% old": 34.53,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 31,
    "out:Return/kSEK/y": 508,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136877,
    "out:DH kr savings": 183540,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 414222,
    "out:% savings (space heating)": 27.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 137.83,
    "out:Total Energy Use Post PV": 137.85,
    "out:Primary Energy": 144.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.17,
    "out:CO2 Operational/m2": 46.24,
    "out:Total CO2/m2": 53.41,
    "out:Total CO2 (tons)": 171.98,
    "out:Klimatpaverkan": -46.85,
    "out:Initial Cost/MSEK": 1.725,
    "out:Running cost/(Apt SEK y)": 35878,
    "out:Running Cost over RSP/MSEK": 10.094,
    "out:LCP/MSEK": 2.707,
    "out:ROI% old": 36.7,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 33,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156197,
    "out:DH kr savings": 202860,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 395088,
    "out:% savings (space heating)": 30.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 144.84,
    "out:Total Energy Use Post PV": 140.5,
    "out:Primary Energy": 144.64,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.06,
    "out:CO2 Operational/m2": 32.56,
    "out:Total CO2/m2": 47.62,
    "out:Total CO2 (tons)": 153.35,
    "out:Klimatpaverkan": -65.48,
    "out:Initial Cost/MSEK": 1.955,
    "out:Running cost/(Apt SEK y)": 36577,
    "out:Running Cost over RSP/MSEK": 10.3,
    "out:LCP/MSEK": 2.271,
    "out:ROI% old": 30.88,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 130,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 28,
    "out:Return/kSEK/y": 541,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.48,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.14,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 180320,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 176642,
    "out:DH kr savings": 180320,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418737,
    "out:% savings (space heating)": 26.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 138.84,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 139.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.06,
    "out:CO2 Operational/m2": 30.4,
    "out:Total CO2/m2": 45.46,
    "out:Total CO2 (tons)": 146.4,
    "out:Klimatpaverkan": -72.43,
    "out:Initial Cost/MSEK": 2.035,
    "out:Running cost/(Apt SEK y)": 34956,
    "out:Running Cost over RSP/MSEK": 9.843,
    "out:LCP/MSEK": 2.647,
    "out:ROI% old": 32.86,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 29,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.5,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 195962,
    "out:DH kr savings": 199640,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 399600,
    "out:% savings (space heating)": 29.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 143.83,
    "out:Total Energy Use Post PV": 139.5,
    "out:Primary Energy": 142.01,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.41,
    "out:CO2 Operational/m2": 32.19,
    "out:Total CO2/m2": 47.6,
    "out:Total CO2 (tons)": 153.28,
    "out:Klimatpaverkan": -65.55,
    "out:Initial Cost/MSEK": 2.092,
    "out:Running cost/(Apt SEK y)": 36305,
    "out:Running Cost over RSP/MSEK": 10.223,
    "out:LCP/MSEK": 2.211,
    "out:ROI% old": 29.39,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 26,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.49,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179872,
    "out:DH kr savings": 183540,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 414222,
    "out:% savings (space heating)": 27.07,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 137.83,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 136.81,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.41,
    "out:CO2 Operational/m2": 30.03,
    "out:Total CO2/m2": 45.44,
    "out:Total CO2 (tons)": 146.32,
    "out:Klimatpaverkan": -72.51,
    "out:Initial Cost/MSEK": 2.172,
    "out:Running cost/(Apt SEK y)": 34684,
    "out:Running Cost over RSP/MSEK": 9.767,
    "out:LCP/MSEK": 2.587,
    "out:ROI% old": 31.3,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 28,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.7,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13972.92,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199193,
    "out:DH kr savings": 202860,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 395088,
    "out:% savings (space heating)": 30.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 142.85,
    "out:Primary Energy": 148.96,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.82,
    "out:CO2 Operational/m2": 48.04,
    "out:Total CO2/m2": 54.86,
    "out:Total CO2 (tons)": 176.65,
    "out:Klimatpaverkan": -42.18,
    "out:Initial Cost/MSEK": 1.603,
    "out:Running cost/(Apt SEK y)": 37228,
    "out:Running Cost over RSP/MSEK": 10.474,
    "out:LCP/MSEK": 2.448,
    "out:ROI% old": 36.1,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 32,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140097,
    "out:DH kr savings": 186760,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 412734,
    "out:% savings (space heating)": 27.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 136.82,
    "out:Total Energy Use Post PV": 136.85,
    "out:Primary Energy": 143.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.82,
    "out:CO2 Operational/m2": 45.88,
    "out:Total CO2/m2": 52.7,
    "out:Total CO2 (tons)": 169.69,
    "out:Klimatpaverkan": -49.14,
    "out:Initial Cost/MSEK": 1.684,
    "out:Running cost/(Apt SEK y)": 35607,
    "out:Running Cost over RSP/MSEK": 10.018,
    "out:LCP/MSEK": 2.824,
    "out:ROI% old": 38.25,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 34,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159417,
    "out:DH kr savings": 206080,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 393603,
    "out:% savings (space heating)": 30.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 141.81,
    "out:Total Energy Use Post PV": 141.85,
    "out:Primary Energy": 146.86,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.17,
    "out:CO2 Operational/m2": 47.67,
    "out:Total CO2/m2": 54.85,
    "out:Total CO2 (tons)": 176.61,
    "out:Klimatpaverkan": -42.22,
    "out:Initial Cost/MSEK": 1.74,
    "out:Running cost/(Apt SEK y)": 36954,
    "out:Running Cost over RSP/MSEK": 10.397,
    "out:LCP/MSEK": 2.389,
    "out:ROI% old": 33.9,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 30,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143317,
    "out:DH kr savings": 189980,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409213,
    "out:% savings (space heating)": 27.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 135.81,
    "out:Total Energy Use Post PV": 135.85,
    "out:Primary Energy": 141.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.17,
    "out:CO2 Operational/m2": 45.51,
    "out:Total CO2/m2": 52.69,
    "out:Total CO2 (tons)": 169.65,
    "out:Klimatpaverkan": -49.18,
    "out:Initial Cost/MSEK": 1.82,
    "out:Running cost/(Apt SEK y)": 35333,
    "out:Running Cost over RSP/MSEK": 9.941,
    "out:LCP/MSEK": 2.765,
    "out:ROI% old": 35.98,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1036.4,
    "out:Return %": 32,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162637,
    "out:DH kr savings": 209300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 390093,
    "out:% savings (space heating)": 31.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 128.18,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 141.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.06,
    "out:CO2 Operational/m2": 31.83,
    "out:Total CO2/m2": 46.89,
    "out:Total CO2 (tons)": 150.97,
    "out:Klimatpaverkan": -67.86,
    "out:Initial Cost/MSEK": 2.05,
    "out:Running cost/(Apt SEK y)": 36034,
    "out:Running Cost over RSP/MSEK": 10.147,
    "out:LCP/MSEK": 2.329,
    "out:ROI% old": 30.52,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 27,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.82,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.79,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183097,
    "out:DH kr savings": 186760,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 412734,
    "out:% savings (space heating)": 27.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 122.24,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 136.82,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 135.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.06,
    "out:CO2 Operational/m2": 29.67,
    "out:Total CO2/m2": 44.73,
    "out:Total CO2 (tons)": 144.02,
    "out:Klimatpaverkan": -74.81,
    "out:Initial Cost/MSEK": 2.13,
    "out:Running cost/(Apt SEK y)": 34413,
    "out:Running Cost over RSP/MSEK": 9.69,
    "out:LCP/MSEK": 2.705,
    "out:ROI% old": 32.42,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 29,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.18,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.44,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202418,
    "out:DH kr savings": 206080,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 393603,
    "out:% savings (space heating)": 30.7,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 141.81,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 139.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.41,
    "out:CO2 Operational/m2": 31.45,
    "out:Total CO2/m2": 46.86,
    "out:Total CO2 (tons)": 150.88,
    "out:Klimatpaverkan": -67.95,
    "out:Initial Cost/MSEK": 2.187,
    "out:Running cost/(Apt SEK y)": 35761,
    "out:Running Cost over RSP/MSEK": 10.07,
    "out:LCP/MSEK": 2.269,
    "out:ROI% old": 29.11,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.73,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.88,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186334,
    "out:DH kr savings": 189980,
    "out:El kr savings": -18698,
    "out:El kr sold": 15052,
    "out:El kr saved": 27918,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409213,
    "out:% savings (space heating)": 27.95,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 136.75,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 135.81,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 133.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.41,
    "out:CO2 Operational/m2": 29.29,
    "out:Total CO2/m2": 44.7,
    "out:Total CO2 (tons)": 143.92,
    "out:Klimatpaverkan": -74.91,
    "out:Initial Cost/MSEK": 2.267,
    "out:Running cost/(Apt SEK y)": 34140,
    "out:Running Cost over RSP/MSEK": 9.613,
    "out:LCP/MSEK": 2.645,
    "out:ROI% old": 30.96,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 28,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.75,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.87,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205655,
    "out:DH kr savings": 209300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27916,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 390093,
    "out:% savings (space heating)": 31.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.81,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.64,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 150.6,
    "out:Primary Energy": 148.12,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.47,
    "out:CO2 Operational/m2": 52.48,
    "out:Total CO2/m2": 62.95,
    "out:Total CO2 (tons)": 202.69,
    "out:Klimatpaverkan": -16.14,
    "out:Initial Cost/MSEK": 2.385,
    "out:Running cost/(Apt SEK y)": 39987,
    "out:Running Cost over RSP/MSEK": 11.255,
    "out:LCP/MSEK": 0.886,
    "out:ROI% old": 19.59,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 418,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138460,
    "out:DH kr savings": 138460,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 430322,
    "out:% savings (space heating)": 24.23,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.84,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 144.6,
    "out:Primary Energy": 143.04,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.47,
    "out:CO2 Operational/m2": 50.32,
    "out:Total CO2/m2": 60.79,
    "out:Total CO2 (tons)": 195.74,
    "out:Klimatpaverkan": -23.09,
    "out:Initial Cost/MSEK": 2.465,
    "out:Running cost/(Apt SEK y)": 38366,
    "out:Running Cost over RSP/MSEK": 10.799,
    "out:LCP/MSEK": 1.262,
    "out:ROI% old": 21.6,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157780,
    "out:DH kr savings": 157780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411640,
    "out:% savings (space heating)": 27.52,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.64,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 149.6,
    "out:Primary Energy": 145.49,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.82,
    "out:CO2 Operational/m2": 52.12,
    "out:Total CO2/m2": 62.94,
    "out:Total CO2 (tons)": 202.66,
    "out:Klimatpaverkan": -16.17,
    "out:Initial Cost/MSEK": 2.521,
    "out:Running cost/(Apt SEK y)": 39717,
    "out:Running Cost over RSP/MSEK": 11.179,
    "out:LCP/MSEK": 0.826,
    "out:ROI% old": 18.96,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141680,
    "out:DH kr savings": 141680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 430322,
    "out:% savings (space heating)": 24.23,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.84,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 143.6,
    "out:Primary Energy": 140.41,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.82,
    "out:CO2 Operational/m2": 49.96,
    "out:Total CO2/m2": 60.78,
    "out:Total CO2 (tons)": 195.71,
    "out:Klimatpaverkan": -23.12,
    "out:Initial Cost/MSEK": 2.602,
    "out:Running cost/(Apt SEK y)": 38096,
    "out:Running Cost over RSP/MSEK": 10.723,
    "out:LCP/MSEK": 1.201,
    "out:ROI% old": 20.88,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161000,
    "out:DH kr savings": 161000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411640,
    "out:% savings (space heating)": 27.52,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.64,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 150.6,
    "out:Total Energy Use Post PV": 147.91,
    "out:Primary Energy": 143.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.7,
    "out:CO2 Operational/m2": 28,
    "out:Total CO2/m2": 46.7,
    "out:Total CO2 (tons)": 150.39,
    "out:Klimatpaverkan": -68.44,
    "out:Initial Cost/MSEK": 2.831,
    "out:Running cost/(Apt SEK y)": 38867,
    "out:Running Cost over RSP/MSEK": 10.948,
    "out:LCP/MSEK": 0.746,
    "out:ROI% old": 18.05,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 458,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 178776,
    "out:DH kr savings": 138460,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 430322,
    "out:% savings (space heating)": 24.23,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.84,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 141.91,
    "out:Primary Energy": 138.2,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.7,
    "out:CO2 Operational/m2": 25.84,
    "out:Total CO2/m2": 44.54,
    "out:Total CO2 (tons)": 143.43,
    "out:Klimatpaverkan": -75.4,
    "out:Initial Cost/MSEK": 2.912,
    "out:Running cost/(Apt SEK y)": 37246,
    "out:Running Cost over RSP/MSEK": 10.492,
    "out:LCP/MSEK": 1.122,
    "out:ROI% old": 19.79,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198096,
    "out:DH kr savings": 157780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411640,
    "out:% savings (space heating)": 27.52,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.64,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 146.91,
    "out:Primary Energy": 140.65,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.05,
    "out:CO2 Operational/m2": 27.64,
    "out:Total CO2/m2": 46.7,
    "out:Total CO2 (tons)": 150.36,
    "out:Klimatpaverkan": -68.47,
    "out:Initial Cost/MSEK": 2.968,
    "out:Running cost/(Apt SEK y)": 38597,
    "out:Running Cost over RSP/MSEK": 10.872,
    "out:LCP/MSEK": 0.686,
    "out:ROI% old": 17.59,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181996,
    "out:DH kr savings": 141680,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 430322,
    "out:% savings (space heating)": 24.23,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.84,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 140.91,
    "out:Primary Energy": 135.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.05,
    "out:CO2 Operational/m2": 25.48,
    "out:Total CO2/m2": 44.54,
    "out:Total CO2 (tons)": 143.4,
    "out:Klimatpaverkan": -75.43,
    "out:Initial Cost/MSEK": 3.048,
    "out:Running cost/(Apt SEK y)": 36976,
    "out:Running Cost over RSP/MSEK": 10.416,
    "out:LCP/MSEK": 1.062,
    "out:ROI% old": 19.26,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201316,
    "out:DH kr savings": 161000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411640,
    "out:% savings (space heating)": 27.52,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.64,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 148.6,
    "out:Total Energy Use Post PV": 148.6,
    "out:Primary Energy": 144.62,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.47,
    "out:CO2 Operational/m2": 51.76,
    "out:Total CO2/m2": 62.23,
    "out:Total CO2 (tons)": 200.38,
    "out:Klimatpaverkan": -18.45,
    "out:Initial Cost/MSEK": 2.48,
    "out:Running cost/(Apt SEK y)": 39447,
    "out:Running Cost over RSP/MSEK": 11.103,
    "out:LCP/MSEK": 0.943,
    "out:ROI% old": 19.72,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 438,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144900,
    "out:DH kr savings": 144900,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 430322,
    "out:% savings (space heating)": 24.23,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.84,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 143.6,
    "out:Primary Energy": 139.54,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.47,
    "out:CO2 Operational/m2": 49.96,
    "out:Total CO2/m2": 60.43,
    "out:Total CO2 (tons)": 194.58,
    "out:Klimatpaverkan": -24.25,
    "out:Initial Cost/MSEK": 2.56,
    "out:Running cost/(Apt SEK y)": 38096,
    "out:Running Cost over RSP/MSEK": 10.723,
    "out:LCP/MSEK": 1.243,
    "out:ROI% old": 21.22,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 486,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 161000,
    "out:DH kr savings": 161000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411640,
    "out:% savings (space heating)": 27.52,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.64,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 147.6,
    "out:Primary Energy": 142.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.82,
    "out:CO2 Operational/m2": 51.4,
    "out:Total CO2/m2": 62.22,
    "out:Total CO2 (tons)": 200.34,
    "out:Klimatpaverkan": -18.49,
    "out:Initial Cost/MSEK": 2.616,
    "out:Running cost/(Apt SEK y)": 39177,
    "out:Running Cost over RSP/MSEK": 11.027,
    "out:LCP/MSEK": 0.883,
    "out:ROI% old": 19.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148120,
    "out:DH kr savings": 148120,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 430322,
    "out:% savings (space heating)": 24.23,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.84,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 141.6,
    "out:Total Energy Use Post PV": 141.6,
    "out:Primary Energy": 137.44,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.82,
    "out:CO2 Operational/m2": 49.24,
    "out:Total CO2/m2": 60.06,
    "out:Total CO2 (tons)": 193.39,
    "out:Klimatpaverkan": -25.44,
    "out:Initial Cost/MSEK": 2.697,
    "out:Running cost/(Apt SEK y)": 37556,
    "out:Running Cost over RSP/MSEK": 10.571,
    "out:LCP/MSEK": 1.259,
    "out:ROI% old": 20.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167440,
    "out:DH kr savings": 167440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411640,
    "out:% savings (space heating)": 27.52,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00011200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.64,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 148.6,
    "out:Total Energy Use Post PV": 145.91,
    "out:Primary Energy": 139.78,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.7,
    "out:CO2 Operational/m2": 27.28,
    "out:Total CO2/m2": 45.98,
    "out:Total CO2 (tons)": 148.07,
    "out:Klimatpaverkan": -70.76,
    "out:Initial Cost/MSEK": 2.926,
    "out:Running cost/(Apt SEK y)": 38327,
    "out:Running Cost over RSP/MSEK": 10.796,
    "out:LCP/MSEK": 0.804,
    "out:ROI% old": 18.21,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185216,
    "out:DH kr savings": 144900,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 430322,
    "out:% savings (space heating)": 24.23,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.84,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 143.6,
    "out:Total Energy Use Post PV": 140.91,
    "out:Primary Energy": 134.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.7,
    "out:CO2 Operational/m2": 25.48,
    "out:Total CO2/m2": 44.19,
    "out:Total CO2 (tons)": 142.28,
    "out:Klimatpaverkan": -76.55,
    "out:Initial Cost/MSEK": 3.007,
    "out:Running cost/(Apt SEK y)": 36976,
    "out:Running Cost over RSP/MSEK": 10.416,
    "out:LCP/MSEK": 1.103,
    "out:ROI% old": 19.53,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 201316,
    "out:DH kr savings": 161000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411640,
    "out:% savings (space heating)": 27.52,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.64,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 144.91,
    "out:Primary Energy": 137.68,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.05,
    "out:CO2 Operational/m2": 26.92,
    "out:Total CO2/m2": 45.98,
    "out:Total CO2 (tons)": 148.04,
    "out:Klimatpaverkan": -70.79,
    "out:Initial Cost/MSEK": 3.063,
    "out:Running cost/(Apt SEK y)": 38057,
    "out:Running Cost over RSP/MSEK": 10.72,
    "out:LCP/MSEK": 0.743,
    "out:ROI% old": 17.75,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188436,
    "out:DH kr savings": 148120,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 430322,
    "out:% savings (space heating)": 24.23,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.84,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 141.6,
    "out:Total Energy Use Post PV": 138.91,
    "out:Primary Energy": 132.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.05,
    "out:CO2 Operational/m2": 24.76,
    "out:Total CO2/m2": 43.82,
    "out:Total CO2 (tons)": 141.09,
    "out:Klimatpaverkan": -77.74,
    "out:Initial Cost/MSEK": 3.143,
    "out:Running cost/(Apt SEK y)": 36436,
    "out:Running Cost over RSP/MSEK": 10.264,
    "out:LCP/MSEK": 1.119,
    "out:ROI% old": 19.37,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207756,
    "out:DH kr savings": 167440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411640,
    "out:% savings (space heating)": 27.52,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00011201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 141.84,
    "out:Total Energy Use Post PV": 141.85,
    "out:Primary Energy": 149.73,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.73,
    "out:CO2 Operational/m2": 47.68,
    "out:Total CO2/m2": 61.41,
    "out:Total CO2 (tons)": 197.73,
    "out:Klimatpaverkan": -21.1,
    "out:Initial Cost/MSEK": 2.995,
    "out:Running cost/(Apt SEK y)": 36961,
    "out:Running Cost over RSP/MSEK": 10.399,
    "out:LCP/MSEK": 1.132,
    "out:ROI% old": 19.68,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 18,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143317,
    "out:DH kr savings": 189980,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408712,
    "out:% savings (space heating)": 28.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 135.84,
    "out:Total Energy Use Post PV": 135.85,
    "out:Primary Energy": 144.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.73,
    "out:CO2 Operational/m2": 45.52,
    "out:Total CO2/m2": 59.25,
    "out:Total CO2 (tons)": 190.78,
    "out:Klimatpaverkan": -28.05,
    "out:Initial Cost/MSEK": 3.076,
    "out:Running cost/(Apt SEK y)": 35340,
    "out:Running Cost over RSP/MSEK": 9.942,
    "out:LCP/MSEK": 1.508,
    "out:ROI% old": 21.29,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 19,
    "out:Return/kSEK/y": 585,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162637,
    "out:DH kr savings": 209300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 390031,
    "out:% savings (space heating)": 31.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 140.83,
    "out:Total Energy Use Post PV": 140.85,
    "out:Primary Energy": 147.11,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.08,
    "out:CO2 Operational/m2": 47.32,
    "out:Total CO2/m2": 61.4,
    "out:Total CO2 (tons)": 197.7,
    "out:Klimatpaverkan": -21.13,
    "out:Initial Cost/MSEK": 3.132,
    "out:Running cost/(Apt SEK y)": 36689,
    "out:Running Cost over RSP/MSEK": 10.322,
    "out:LCP/MSEK": 1.072,
    "out:ROI% old": 19.18,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146537,
    "out:DH kr savings": 193200,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 404193,
    "out:% savings (space heating)": 28.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 134.83,
    "out:Total Energy Use Post PV": 134.85,
    "out:Primary Energy": 142.03,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.08,
    "out:CO2 Operational/m2": 45.16,
    "out:Total CO2/m2": 59.24,
    "out:Total CO2 (tons)": 190.74,
    "out:Klimatpaverkan": -28.09,
    "out:Initial Cost/MSEK": 3.212,
    "out:Running cost/(Apt SEK y)": 35068,
    "out:Running Cost over RSP/MSEK": 9.866,
    "out:LCP/MSEK": 1.448,
    "out:ROI% old": 20.72,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165857,
    "out:DH kr savings": 212520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385515,
    "out:% savings (space heating)": 32.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 141.84,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 141.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.97,
    "out:CO2 Operational/m2": 31.49,
    "out:Total CO2/m2": 53.45,
    "out:Total CO2 (tons)": 172.11,
    "out:Klimatpaverkan": -46.72,
    "out:Initial Cost/MSEK": 3.442,
    "out:Running cost/(Apt SEK y)": 35767,
    "out:Running Cost over RSP/MSEK": 10.072,
    "out:LCP/MSEK": 1.012,
    "out:ROI% old": 18.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.41,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186302,
    "out:DH kr savings": 189980,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 408712,
    "out:% savings (space heating)": 28.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 135.84,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 136.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.97,
    "out:CO2 Operational/m2": 29.33,
    "out:Total CO2/m2": 51.29,
    "out:Total CO2 (tons)": 165.16,
    "out:Klimatpaverkan": -53.67,
    "out:Initial Cost/MSEK": 3.522,
    "out:Running cost/(Apt SEK y)": 34146,
    "out:Running Cost over RSP/MSEK": 9.615,
    "out:LCP/MSEK": 1.388,
    "out:ROI% old": 19.92,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 628,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.42,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205622,
    "out:DH kr savings": 209300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 390031,
    "out:% savings (space heating)": 31.33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 140.83,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 139.29,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.32,
    "out:CO2 Operational/m2": 31.11,
    "out:Total CO2/m2": 53.43,
    "out:Total CO2 (tons)": 172.04,
    "out:Klimatpaverkan": -46.79,
    "out:Initial Cost/MSEK": 3.578,
    "out:Running cost/(Apt SEK y)": 35495,
    "out:Running Cost over RSP/MSEK": 9.995,
    "out:LCP/MSEK": 0.953,
    "out:ROI% old": 18.09,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 580,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.2,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.42,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189532,
    "out:DH kr savings": 193200,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 404193,
    "out:% savings (space heating)": 28.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 134.83,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 134.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.32,
    "out:CO2 Operational/m2": 28.95,
    "out:Total CO2/m2": 51.27,
    "out:Total CO2 (tons)": 165.09,
    "out:Klimatpaverkan": -53.74,
    "out:Initial Cost/MSEK": 3.659,
    "out:Running cost/(Apt SEK y)": 33874,
    "out:Running Cost over RSP/MSEK": 9.539,
    "out:LCP/MSEK": 1.328,
    "out:ROI% old": 19.47,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 48.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.41,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208852,
    "out:DH kr savings": 212520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385515,
    "out:% savings (space heating)": 32.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 139.85,
    "out:Primary Energy": 146.23,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.73,
    "out:CO2 Operational/m2": 46.96,
    "out:Total CO2/m2": 60.69,
    "out:Total CO2 (tons)": 195.41,
    "out:Klimatpaverkan": -23.42,
    "out:Initial Cost/MSEK": 3.09,
    "out:Running cost/(Apt SEK y)": 36417,
    "out:Running Cost over RSP/MSEK": 10.246,
    "out:LCP/MSEK": 1.19,
    "out:ROI% old": 19.79,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 18,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149757,
    "out:DH kr savings": 196420,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402703,
    "out:% savings (space heating)": 29.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 133.85,
    "out:Primary Energy": 141.16,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.73,
    "out:CO2 Operational/m2": 44.8,
    "out:Total CO2/m2": 58.53,
    "out:Total CO2 (tons)": 188.45,
    "out:Klimatpaverkan": -30.38,
    "out:Initial Cost/MSEK": 3.171,
    "out:Running cost/(Apt SEK y)": 34797,
    "out:Running Cost over RSP/MSEK": 9.789,
    "out:LCP/MSEK": 1.566,
    "out:ROI% old": 21.34,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169077,
    "out:DH kr savings": 215740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 384028,
    "out:% savings (space heating)": 32.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 138.81,
    "out:Total Energy Use Post PV": 138.85,
    "out:Primary Energy": 144.13,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.08,
    "out:CO2 Operational/m2": 46.59,
    "out:Total CO2/m2": 60.67,
    "out:Total CO2 (tons)": 195.37,
    "out:Klimatpaverkan": -23.46,
    "out:Initial Cost/MSEK": 3.227,
    "out:Running cost/(Apt SEK y)": 36144,
    "out:Running Cost over RSP/MSEK": 10.169,
    "out:LCP/MSEK": 1.13,
    "out:ROI% old": 19.29,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152977,
    "out:DH kr savings": 199640,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 399178,
    "out:% savings (space heating)": 29.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 132.81,
    "out:Total Energy Use Post PV": 132.85,
    "out:Primary Energy": 139.06,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.08,
    "out:CO2 Operational/m2": 44.43,
    "out:Total CO2/m2": 58.51,
    "out:Total CO2 (tons)": 188.41,
    "out:Klimatpaverkan": -30.42,
    "out:Initial Cost/MSEK": 3.307,
    "out:Running cost/(Apt SEK y)": 34523,
    "out:Running Cost over RSP/MSEK": 9.712,
    "out:LCP/MSEK": 1.506,
    "out:ROI% old": 20.79,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172297,
    "out:DH kr savings": 218960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 380513,
    "out:% savings (space heating)": 33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00011201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 138.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 21.97,
    "out:CO2 Operational/m2": 30.75,
    "out:Total CO2/m2": 52.71,
    "out:Total CO2 (tons)": 169.74,
    "out:Klimatpaverkan": -49.09,
    "out:Initial Cost/MSEK": 3.537,
    "out:Running cost/(Apt SEK y)": 35224,
    "out:Running Cost over RSP/MSEK": 9.919,
    "out:LCP/MSEK": 1.07,
    "out:ROI% old": 18.61,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.44,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.18,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192757,
    "out:DH kr savings": 196420,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402703,
    "out:% savings (space heating)": 29.1,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 133.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.97,
    "out:CO2 Operational/m2": 28.59,
    "out:Total CO2/m2": 50.55,
    "out:Total CO2 (tons)": 162.78,
    "out:Klimatpaverkan": -56.05,
    "out:Initial Cost/MSEK": 3.617,
    "out:Running cost/(Apt SEK y)": 33603,
    "out:Running Cost over RSP/MSEK": 9.462,
    "out:LCP/MSEK": 1.446,
    "out:ROI% old": 20,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 648,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.79,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.82,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212077,
    "out:DH kr savings": 215740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 384028,
    "out:% savings (space heating)": 32.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.97,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 138.81,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 136.32,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.32,
    "out:CO2 Operational/m2": 30.37,
    "out:Total CO2/m2": 52.68,
    "out:Total CO2 (tons)": 169.64,
    "out:Klimatpaverkan": -49.19,
    "out:Initial Cost/MSEK": 3.673,
    "out:Running cost/(Apt SEK y)": 34951,
    "out:Running Cost over RSP/MSEK": 9.842,
    "out:LCP/MSEK": 1.011,
    "out:ROI% old": 18.22,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195993,
    "out:DH kr savings": 199640,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 399178,
    "out:% savings (space heating)": 29.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 133.64,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00011201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 132.81,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 131.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.32,
    "out:CO2 Operational/m2": 28.21,
    "out:Total CO2/m2": 50.52,
    "out:Total CO2 (tons)": 162.68,
    "out:Klimatpaverkan": -56.15,
    "out:Initial Cost/MSEK": 3.754,
    "out:Running cost/(Apt SEK y)": 33330,
    "out:Running Cost over RSP/MSEK": 9.385,
    "out:LCP/MSEK": 1.387,
    "out:ROI% old": 19.56,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.28,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215314,
    "out:DH kr savings": 218960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 380513,
    "out:% savings (space heating)": 33,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.84,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.62,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 166.6,
    "out:Total Energy Use Post PV": 166.6,
    "out:Primary Energy": 162.1,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.18,
    "out:CO2 Operational/m2": 58.24,
    "out:Total CO2/m2": 59.42,
    "out:Total CO2 (tons)": 191.34,
    "out:Klimatpaverkan": -27.49,
    "out:Initial Cost/MSEK": 0.31,
    "out:Running cost/(Apt SEK y)": 44309,
    "out:Running Cost over RSP/MSEK": 12.472,
    "out:LCP/MSEK": 1.744,
    "out:ROI% old": 94.69,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 167,
    "out:Energy use kWh/m2": 167,
    "out:Energy savings %": 16.2,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 85,
    "out:Return/kSEK/y": 263,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 28,
    "out:Payback Time": 3.571429,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 86940,
    "out:DH kr savings": 86940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481768,
    "out:% savings (space heating)": 15.18,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.09,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 160.6,
    "out:Total Energy Use Post PV": 160.6,
    "out:Primary Energy": 156.38,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.18,
    "out:CO2 Operational/m2": 56.08,
    "out:Total CO2/m2": 57.26,
    "out:Total CO2 (tons)": 184.38,
    "out:Klimatpaverkan": -34.45,
    "out:Initial Cost/MSEK": 0.39,
    "out:Running cost/(Apt SEK y)": 42688,
    "out:Running Cost over RSP/MSEK": 12.016,
    "out:LCP/MSEK": 2.12,
    "out:ROI% old": 91.86,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 82,
    "out:Return/kSEK/y": 321,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 27,
    "out:Payback Time": 3.703704,
    "out:DH kWh savings": 106260,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 106260,
    "out:DH kr savings": 106260,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460747,
    "out:% savings (space heating)": 18.88,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.62,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 165.6,
    "out:Primary Energy": 159.47,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.53,
    "out:CO2 Operational/m2": 57.88,
    "out:Total CO2/m2": 59.41,
    "out:Total CO2 (tons)": 191.31,
    "out:Klimatpaverkan": -27.52,
    "out:Initial Cost/MSEK": 0.446,
    "out:Running cost/(Apt SEK y)": 44039,
    "out:Running Cost over RSP/MSEK": 12.396,
    "out:LCP/MSEK": 1.683,
    "out:ROI% old": 68.15,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 166,
    "out:Energy use kWh/m2": 166,
    "out:Energy savings %": 16.9,
    "out:Op energy cost/MSEK": 91,
    "out:El price/MSEK": 55,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 61,
    "out:Return/kSEK/y": 272,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 20,
    "out:Payback Time": 5,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 90160,
    "out:DH kr savings": 90160,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481768,
    "out:% savings (space heating)": 15.18,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.09,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 158.6,
    "out:Primary Energy": 153.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.53,
    "out:CO2 Operational/m2": 55.36,
    "out:Total CO2/m2": 56.89,
    "out:Total CO2 (tons)": 183.19,
    "out:Klimatpaverkan": -35.64,
    "out:Initial Cost/MSEK": 0.527,
    "out:Running cost/(Apt SEK y)": 42148,
    "out:Running Cost over RSP/MSEK": 11.864,
    "out:LCP/MSEK": 2.135,
    "out:ROI% old": 72.17,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 65,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 21,
    "out:Payback Time": 4.761905,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112700,
    "out:DH kr savings": 112700,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460747,
    "out:% savings (space heating)": 18.88,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.62,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 166.6,
    "out:Total Energy Use Post PV": 163.91,
    "out:Primary Energy": 157.26,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 33.76,
    "out:Total CO2/m2": 43.18,
    "out:Total CO2 (tons)": 139.03,
    "out:Klimatpaverkan": -79.8,
    "out:Initial Cost/MSEK": 0.756,
    "out:Running cost/(Apt SEK y)": 43189,
    "out:Running Cost over RSP/MSEK": 12.165,
    "out:LCP/MSEK": 1.604,
    "out:ROI% old": 44.58,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 18.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 159,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 40,
    "out:Return/kSEK/y": 303,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 17,
    "out:Payback Time": 5.882353,
    "out:DH kWh savings": 86940,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 127256,
    "out:DH kr savings": 86940,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481768,
    "out:% savings (space heating)": 15.18,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.09,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 160.6,
    "out:Total Energy Use Post PV": 157.91,
    "out:Primary Energy": 151.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 41.02,
    "out:Total CO2 (tons)": 132.08,
    "out:Klimatpaverkan": -86.75,
    "out:Initial Cost/MSEK": 0.837,
    "out:Running cost/(Apt SEK y)": 41568,
    "out:Running Cost over RSP/MSEK": 11.709,
    "out:LCP/MSEK": 1.98,
    "out:ROI% old": 48.08,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 153,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 43,
    "out:Return/kSEK/y": 361,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 106260,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 146576,
    "out:DH kr savings": 106260,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460747,
    "out:% savings (space heating)": 18.88,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.62,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 165.6,
    "out:Total Energy Use Post PV": 162.91,
    "out:Primary Energy": 154.63,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 33.4,
    "out:Total CO2/m2": 43.17,
    "out:Total CO2 (tons)": 139,
    "out:Klimatpaverkan": -79.83,
    "out:Initial Cost/MSEK": 0.893,
    "out:Running cost/(Apt SEK y)": 42919,
    "out:Running Cost over RSP/MSEK": 12.089,
    "out:LCP/MSEK": 1.544,
    "out:ROI% old": 38.98,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 163,
    "out:Energy use kWh/m2": 163,
    "out:Energy savings %": 19,
    "out:Op energy cost/MSEK": 89,
    "out:El price/MSEK": 53,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 158,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 35,
    "out:Return/kSEK/y": 313,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 90160,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 130476,
    "out:DH kr savings": 90160,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481768,
    "out:% savings (space heating)": 15.18,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.09,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 155.91,
    "out:Primary Energy": 148.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 40.65,
    "out:Total CO2 (tons)": 130.89,
    "out:Klimatpaverkan": -87.94,
    "out:Initial Cost/MSEK": 0.973,
    "out:Running cost/(Apt SEK y)": 41028,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": 1.995,
    "out:ROI% old": 43.57,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 39,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153016,
    "out:DH kr savings": 112700,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460747,
    "out:% savings (space heating)": 18.88,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.62,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 164.6,
    "out:Total Energy Use Post PV": 164.6,
    "out:Primary Energy": 158.6,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.18,
    "out:CO2 Operational/m2": 57.52,
    "out:Total CO2/m2": 58.7,
    "out:Total CO2 (tons)": 189.02,
    "out:Klimatpaverkan": -29.81,
    "out:Initial Cost/MSEK": 0.405,
    "out:Running cost/(Apt SEK y)": 43769,
    "out:Running Cost over RSP/MSEK": 12.32,
    "out:LCP/MSEK": 1.801,
    "out:ROI% old": 77.83,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 165,
    "out:Energy use kWh/m2": 165,
    "out:Energy savings %": 17.6,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 70,
    "out:Return/kSEK/y": 282,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 23,
    "out:Payback Time": 4.347826,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 93380,
    "out:DH kr savings": 93380,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481768,
    "out:% savings (space heating)": 15.18,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.09,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 158.6,
    "out:Primary Energy": 152.88,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.18,
    "out:CO2 Operational/m2": 55.36,
    "out:Total CO2/m2": 56.54,
    "out:Total CO2 (tons)": 182.06,
    "out:Klimatpaverkan": -36.77,
    "out:Initial Cost/MSEK": 0.485,
    "out:Running cost/(Apt SEK y)": 42148,
    "out:Running Cost over RSP/MSEK": 11.864,
    "out:LCP/MSEK": 2.177,
    "out:ROI% old": 78.36,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 159,
    "out:Energy use kWh/m2": 159,
    "out:Energy savings %": 22,
    "out:Op energy cost/MSEK": 87,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 70,
    "out:Return/kSEK/y": 340,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 23,
    "out:Payback Time": 4.347826,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 112700,
    "out:DH kr savings": 112700,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460747,
    "out:% savings (space heating)": 18.88,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.62,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 163.6,
    "out:Total Energy Use Post PV": 163.6,
    "out:Primary Energy": 156.5,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.53,
    "out:CO2 Operational/m2": 57.16,
    "out:Total CO2/m2": 58.69,
    "out:Total CO2 (tons)": 188.99,
    "out:Klimatpaverkan": -29.84,
    "out:Initial Cost/MSEK": 0.541,
    "out:Running cost/(Apt SEK y)": 43499,
    "out:Running Cost over RSP/MSEK": 12.244,
    "out:LCP/MSEK": 1.74,
    "out:ROI% old": 60.2,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 164,
    "out:Energy use kWh/m2": 164,
    "out:Energy savings %": 18.3,
    "out:Op energy cost/MSEK": 90,
    "out:El price/MSEK": 54,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 54,
    "out:Return/kSEK/y": 292,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 18,
    "out:Payback Time": 5.555556,
    "out:DH kWh savings": 96600,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 96600,
    "out:DH kr savings": 96600,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481768,
    "out:% savings (space heating)": 15.18,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.09,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 157.6,
    "out:Total Energy Use Post PV": 157.6,
    "out:Primary Energy": 150.78,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 1.53,
    "out:CO2 Operational/m2": 55,
    "out:Total CO2/m2": 56.53,
    "out:Total CO2 (tons)": 182.03,
    "out:Klimatpaverkan": -36.8,
    "out:Initial Cost/MSEK": 0.622,
    "out:Running cost/(Apt SEK y)": 41878,
    "out:Running Cost over RSP/MSEK": 11.788,
    "out:LCP/MSEK": 2.116,
    "out:ROI% old": 62.89,
    "out:Payback discounted": 2,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 86,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 56,
    "out:Return/kSEK/y": 350,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 19,
    "out:Payback Time": 5.263158,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 115920,
    "out:DH kr savings": 115920,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460747,
    "out:% savings (space heating)": 18.88,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.62,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 164.6,
    "out:Total Energy Use Post PV": 161.91,
    "out:Primary Energy": 153.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 33.04,
    "out:Total CO2/m2": 42.46,
    "out:Total CO2 (tons)": 136.72,
    "out:Klimatpaverkan": -82.11,
    "out:Initial Cost/MSEK": 0.851,
    "out:Running cost/(Apt SEK y)": 42649,
    "out:Running Cost over RSP/MSEK": 12.013,
    "out:LCP/MSEK": 1.661,
    "out:ROI% old": 42.16,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 162,
    "out:Energy use kWh/m2": 162,
    "out:Energy savings %": 19.8,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 157,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 38,
    "out:Return/kSEK/y": 322,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 93380,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 133696,
    "out:DH kr savings": 93380,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481768,
    "out:% savings (space heating)": 15.18,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.09,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 158.6,
    "out:Total Energy Use Post PV": 155.91,
    "out:Primary Energy": 148.05,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.42,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 40.3,
    "out:Total CO2 (tons)": 129.76,
    "out:Klimatpaverkan": -89.07,
    "out:Initial Cost/MSEK": 0.932,
    "out:Running cost/(Apt SEK y)": 41028,
    "out:Running Cost over RSP/MSEK": 11.557,
    "out:LCP/MSEK": 2.037,
    "out:ROI% old": 45.51,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 151,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 41,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 16,
    "out:Payback Time": 6.25,
    "out:DH kWh savings": 112700,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 153016,
    "out:DH kr savings": 112700,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460747,
    "out:% savings (space heating)": 18.88,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 149.62,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 163.6,
    "out:Total Energy Use Post PV": 160.91,
    "out:Primary Energy": 151.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 32.68,
    "out:Total CO2/m2": 42.45,
    "out:Total CO2 (tons)": 136.68,
    "out:Klimatpaverkan": -82.15,
    "out:Initial Cost/MSEK": 0.988,
    "out:Running cost/(Apt SEK y)": 42379,
    "out:Running Cost over RSP/MSEK": 11.937,
    "out:LCP/MSEK": 1.601,
    "out:ROI% old": 37.43,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 161,
    "out:Energy use kWh/m2": 161,
    "out:Energy savings %": 20.5,
    "out:Op energy cost/MSEK": 88,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 31,
    "out:DH+ DHW/kWh/m2": 156,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 34,
    "out:Return/kSEK/y": 332,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 96600,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136916,
    "out:DH kr savings": 96600,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 481768,
    "out:% savings (space heating)": 15.18,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 143.09,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 157.6,
    "out:Total Energy Use Post PV": 154.91,
    "out:Primary Energy": 145.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 9.77,
    "out:CO2 Operational/m2": 30.52,
    "out:Total CO2/m2": 40.29,
    "out:Total CO2 (tons)": 129.73,
    "out:Klimatpaverkan": -89.1,
    "out:Initial Cost/MSEK": 1.068,
    "out:Running cost/(Apt SEK y)": 40758,
    "out:Running Cost over RSP/MSEK": 11.481,
    "out:LCP/MSEK": 1.977,
    "out:ROI% old": 40.71,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 30,
    "out:DH+ DHW/kWh/m2": 150,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 37,
    "out:Return/kSEK/y": 390,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 115920,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156236,
    "out:DH kr savings": 115920,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460747,
    "out:% savings (space heating)": 18.88,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 157.84,
    "out:Total Energy Use Post PV": 157.85,
    "out:Primary Energy": 163.71,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.44,
    "out:CO2 Operational/m2": 53.44,
    "out:Total CO2/m2": 57.88,
    "out:Total CO2 (tons)": 186.38,
    "out:Klimatpaverkan": -32.45,
    "out:Initial Cost/MSEK": 0.921,
    "out:Running cost/(Apt SEK y)": 41283,
    "out:Running Cost over RSP/MSEK": 11.616,
    "out:LCP/MSEK": 1.99,
    "out:ROI% old": 45.16,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 158,
    "out:Energy use kWh/m2": 158,
    "out:Energy savings %": 22.8,
    "out:Op energy cost/MSEK": 85,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 40,
    "out:Return/kSEK/y": 372,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 91797,
    "out:DH kr savings": 138460,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460159,
    "out:% savings (space heating)": 18.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 150.84,
    "out:Total Energy Use Post PV": 150.85,
    "out:Primary Energy": 158,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.44,
    "out:CO2 Operational/m2": 50.92,
    "out:Total CO2/m2": 55.36,
    "out:Total CO2 (tons)": 178.26,
    "out:Klimatpaverkan": -40.57,
    "out:Initial Cost/MSEK": 1.001,
    "out:Running cost/(Apt SEK y)": 39392,
    "out:Running Cost over RSP/MSEK": 11.083,
    "out:LCP/MSEK": 2.441,
    "out:ROI% old": 49.13,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 44,
    "out:Return/kSEK/y": 440,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 114337,
    "out:DH kr savings": 161000,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439139,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 156.85,
    "out:Primary Energy": 161.09,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.79,
    "out:CO2 Operational/m2": 53.08,
    "out:Total CO2/m2": 57.87,
    "out:Total CO2 (tons)": 186.34,
    "out:Klimatpaverkan": -32.49,
    "out:Initial Cost/MSEK": 1.057,
    "out:Running cost/(Apt SEK y)": 41010,
    "out:Running Cost over RSP/MSEK": 11.539,
    "out:LCP/MSEK": 1.93,
    "out:ROI% old": 40.36,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 157,
    "out:Energy use kWh/m2": 157,
    "out:Energy savings %": 23.6,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 52,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 36,
    "out:Return/kSEK/y": 381,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 95017,
    "out:DH kr savings": 141680,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 455640,
    "out:% savings (space heating)": 19.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 149.85,
    "out:Primary Energy": 155.37,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.79,
    "out:CO2 Operational/m2": 50.56,
    "out:Total CO2/m2": 55.35,
    "out:Total CO2 (tons)": 178.23,
    "out:Klimatpaverkan": -40.6,
    "out:Initial Cost/MSEK": 1.138,
    "out:Running cost/(Apt SEK y)": 39120,
    "out:Running Cost over RSP/MSEK": 11.007,
    "out:LCP/MSEK": 2.382,
    "out:ROI% old": 44.19,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 40,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117557,
    "out:DH kr savings": 164220,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 434623,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 142.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 157.84,
    "out:Total Energy Use Post PV": 153.5,
    "out:Primary Energy": 155.9,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.68,
    "out:CO2 Operational/m2": 37.24,
    "out:Total CO2/m2": 49.92,
    "out:Total CO2 (tons)": 160.76,
    "out:Klimatpaverkan": -58.07,
    "out:Initial Cost/MSEK": 1.367,
    "out:Running cost/(Apt SEK y)": 40089,
    "out:Running Cost over RSP/MSEK": 11.289,
    "out:LCP/MSEK": 1.87,
    "out:ROI% old": 33.83,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 143,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 414,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.43,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 138460,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 134782,
    "out:DH kr savings": 138460,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 460159,
    "out:% savings (space heating)": 18.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 136.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 150.84,
    "out:Total Energy Use Post PV": 146.5,
    "out:Primary Energy": 150.18,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.68,
    "out:CO2 Operational/m2": 34.72,
    "out:Total CO2/m2": 47.4,
    "out:Total CO2 (tons)": 152.64,
    "out:Klimatpaverkan": -66.19,
    "out:Initial Cost/MSEK": 1.448,
    "out:Running cost/(Apt SEK y)": 38198,
    "out:Running Cost over RSP/MSEK": 10.756,
    "out:LCP/MSEK": 2.322,
    "out:ROI% old": 37.2,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 136,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 33,
    "out:Return/kSEK/y": 483,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.45,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.17,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 161000,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157322,
    "out:DH kr savings": 161000,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 439139,
    "out:% savings (space heating)": 22.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 156.83,
    "out:Total Energy Use Post PV": 152.5,
    "out:Primary Energy": 153.27,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.03,
    "out:CO2 Operational/m2": 36.87,
    "out:Total CO2/m2": 49.9,
    "out:Total CO2 (tons)": 160.69,
    "out:Klimatpaverkan": -58.14,
    "out:Initial Cost/MSEK": 1.504,
    "out:Running cost/(Apt SEK y)": 39817,
    "out:Running Cost over RSP/MSEK": 11.212,
    "out:LCP/MSEK": 1.81,
    "out:ROI% old": 31.48,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 153,
    "out:Energy use kWh/m2": 153,
    "out:Energy savings %": 26.8,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 28,
    "out:Return/kSEK/y": 424,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.25,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.37,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 138012,
    "out:DH kr savings": 141680,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 455640,
    "out:% savings (space heating)": 19.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 145.5,
    "out:Primary Energy": 147.56,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.03,
    "out:CO2 Operational/m2": 34.35,
    "out:Total CO2/m2": 47.38,
    "out:Total CO2 (tons)": 152.57,
    "out:Klimatpaverkan": -66.26,
    "out:Initial Cost/MSEK": 1.584,
    "out:Running cost/(Apt SEK y)": 37926,
    "out:Running Cost over RSP/MSEK": 10.68,
    "out:LCP/MSEK": 2.262,
    "out:ROI% old": 34.69,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 31,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.46,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.16,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160552,
    "out:DH kr savings": 164220,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 434623,
    "out:% savings (space heating)": 23.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 155.83,
    "out:Total Energy Use Post PV": 155.85,
    "out:Primary Energy": 160.21,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.44,
    "out:CO2 Operational/m2": 52.71,
    "out:Total CO2/m2": 57.16,
    "out:Total CO2 (tons)": 184.05,
    "out:Klimatpaverkan": -34.78,
    "out:Initial Cost/MSEK": 1.016,
    "out:Running cost/(Apt SEK y)": 40739,
    "out:Running Cost over RSP/MSEK": 11.463,
    "out:LCP/MSEK": 2.047,
    "out:ROI% old": 43.09,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 156,
    "out:Energy use kWh/m2": 156,
    "out:Energy savings %": 24.4,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 39,
    "out:Return/kSEK/y": 391,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 98237,
    "out:DH kr savings": 144900,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 454150,
    "out:% savings (space heating)": 20.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 149.85,
    "out:Primary Energy": 154.5,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.44,
    "out:CO2 Operational/m2": 50.55,
    "out:Total CO2/m2": 55,
    "out:Total CO2 (tons)": 177.1,
    "out:Klimatpaverkan": -41.73,
    "out:Initial Cost/MSEK": 1.096,
    "out:Running cost/(Apt SEK y)": 39119,
    "out:Running Cost over RSP/MSEK": 11.006,
    "out:LCP/MSEK": 2.423,
    "out:ROI% old": 45.87,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 41,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 117557,
    "out:DH kr savings": 164220,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 433136,
    "out:% savings (space heating)": 23.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 139.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 154.81,
    "out:Total Energy Use Post PV": 154.85,
    "out:Primary Energy": 158.11,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.79,
    "out:CO2 Operational/m2": 52.35,
    "out:Total CO2/m2": 57.15,
    "out:Total CO2 (tons)": 184.01,
    "out:Klimatpaverkan": -34.82,
    "out:Initial Cost/MSEK": 1.152,
    "out:Running cost/(Apt SEK y)": 40466,
    "out:Running Cost over RSP/MSEK": 11.386,
    "out:LCP/MSEK": 1.988,
    "out:ROI% old": 38.93,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 155,
    "out:Energy use kWh/m2": 155,
    "out:Energy savings %": 25.2,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 35,
    "out:Return/kSEK/y": 401,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 101457,
    "out:DH kr savings": 148120,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 450623,
    "out:% savings (space heating)": 20.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 147.81,
    "out:Total Energy Use Post PV": 147.85,
    "out:Primary Energy": 152.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.79,
    "out:CO2 Operational/m2": 49.83,
    "out:Total CO2/m2": 54.63,
    "out:Total CO2 (tons)": 175.9,
    "out:Klimatpaverkan": -42.93,
    "out:Initial Cost/MSEK": 1.233,
    "out:Running cost/(Apt SEK y)": 38575,
    "out:Running Cost over RSP/MSEK": 10.853,
    "out:LCP/MSEK": 2.44,
    "out:ROI% old": 42.56,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 38,
    "out:Return/kSEK/y": 469,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 123997,
    "out:DH kr savings": 170660,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429619,
    "out:% savings (space heating)": 24.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 141.04,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 155.83,
    "out:Total Energy Use Post PV": 151.5,
    "out:Primary Energy": 152.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.68,
    "out:CO2 Operational/m2": 36.51,
    "out:Total CO2/m2": 49.19,
    "out:Total CO2 (tons)": 158.38,
    "out:Klimatpaverkan": -60.45,
    "out:Initial Cost/MSEK": 1.462,
    "out:Running cost/(Apt SEK y)": 39546,
    "out:Running Cost over RSP/MSEK": 11.136,
    "out:LCP/MSEK": 1.928,
    "out:ROI% old": 33.12,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 30,
    "out:Return/kSEK/y": 434,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.49,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141237,
    "out:DH kr savings": 144900,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 454150,
    "out:% savings (space heating)": 20.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 134.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 149.83,
    "out:Total Energy Use Post PV": 145.5,
    "out:Primary Energy": 146.68,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.68,
    "out:CO2 Operational/m2": 34.35,
    "out:Total CO2/m2": 47.03,
    "out:Total CO2 (tons)": 151.42,
    "out:Klimatpaverkan": -67.41,
    "out:Initial Cost/MSEK": 1.543,
    "out:Running cost/(Apt SEK y)": 37925,
    "out:Running Cost over RSP/MSEK": 10.679,
    "out:LCP/MSEK": 2.304,
    "out:ROI% old": 35.62,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 32,
    "out:Return/kSEK/y": 492,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.84,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.78,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 160557,
    "out:DH kr savings": 164220,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 433136,
    "out:% savings (space heating)": 23.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 139.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 154.81,
    "out:Total Energy Use Post PV": 150.5,
    "out:Primary Energy": 150.3,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.03,
    "out:CO2 Operational/m2": 36.13,
    "out:Total CO2/m2": 49.16,
    "out:Total CO2 (tons)": 158.29,
    "out:Klimatpaverkan": -60.54,
    "out:Initial Cost/MSEK": 1.599,
    "out:Running cost/(Apt SEK y)": 39273,
    "out:Running Cost over RSP/MSEK": 11.059,
    "out:LCP/MSEK": 1.868,
    "out:ROI% old": 30.98,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 28,
    "out:Return/kSEK/y": 444,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.03,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.59,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144473,
    "out:DH kr savings": 148120,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 450623,
    "out:% savings (space heating)": 20.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 149.62,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 133.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 147.81,
    "out:Total Energy Use Post PV": 143.5,
    "out:Primary Energy": 144.58,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.03,
    "out:CO2 Operational/m2": 33.61,
    "out:Total CO2/m2": 46.64,
    "out:Total CO2 (tons)": 150.17,
    "out:Klimatpaverkan": -68.66,
    "out:Initial Cost/MSEK": 1.679,
    "out:Running cost/(Apt SEK y)": 37382,
    "out:Running Cost over RSP/MSEK": 10.526,
    "out:LCP/MSEK": 2.32,
    "out:ROI% old": 34.03,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.55,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 512,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.62,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167014,
    "out:DH kr savings": 170660,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 429619,
    "out:% savings (space heating)": 24.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 143.09,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.93,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 153.6,
    "out:Total Energy Use Post PV": 153.6,
    "out:Primary Energy": 150.12,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.7,
    "out:CO2 Operational/m2": 53.56,
    "out:Total CO2/m2": 57.26,
    "out:Total CO2 (tons)": 184.38,
    "out:Klimatpaverkan": -34.45,
    "out:Initial Cost/MSEK": 0.917,
    "out:Running cost/(Apt SEK y)": 40797,
    "out:Running Cost over RSP/MSEK": 11.483,
    "out:LCP/MSEK": 2.125,
    "out:ROI% old": 47.39,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 154,
    "out:Energy use kWh/m2": 154,
    "out:Energy savings %": 26,
    "out:Op energy cost/MSEK": 84,
    "out:El price/MSEK": 51,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 42,
    "out:Return/kSEK/y": 389,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 128800,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 128800,
    "out:DH kr savings": 128800,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437687,
    "out:% savings (space heating)": 22.94,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.02,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 147.6,
    "out:Primary Energy": 144.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.7,
    "out:CO2 Operational/m2": 51.4,
    "out:Total CO2/m2": 55.1,
    "out:Total CO2 (tons)": 177.42,
    "out:Klimatpaverkan": -41.41,
    "out:Initial Cost/MSEK": 0.998,
    "out:Running cost/(Apt SEK y)": 39177,
    "out:Running Cost over RSP/MSEK": 11.027,
    "out:LCP/MSEK": 2.501,
    "out:ROI% old": 50.1,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 45,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 15,
    "out:Payback Time": 6.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 148120,
    "out:DH kr savings": 148120,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418670,
    "out:% savings (space heating)": 26.28,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.93,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 151.6,
    "out:Primary Energy": 147.49,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.05,
    "out:CO2 Operational/m2": 52.84,
    "out:Total CO2/m2": 56.89,
    "out:Total CO2 (tons)": 183.19,
    "out:Klimatpaverkan": -35.64,
    "out:Initial Cost/MSEK": 1.054,
    "out:Running cost/(Apt SEK y)": 40257,
    "out:Running Cost over RSP/MSEK": 11.331,
    "out:LCP/MSEK": 2.141,
    "out:ROI% old": 43.31,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 39,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135240,
    "out:DH kr savings": 135240,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437687,
    "out:% savings (space heating)": 22.94,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.02,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 145.6,
    "out:Primary Energy": 142.32,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.05,
    "out:CO2 Operational/m2": 50.68,
    "out:Total CO2/m2": 54.73,
    "out:Total CO2 (tons)": 176.23,
    "out:Klimatpaverkan": -42.6,
    "out:Initial Cost/MSEK": 1.134,
    "out:Running cost/(Apt SEK y)": 38636,
    "out:Running Cost over RSP/MSEK": 10.875,
    "out:LCP/MSEK": 2.517,
    "out:ROI% old": 45.99,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 41,
    "out:Return/kSEK/y": 467,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154560,
    "out:DH kr savings": 154560,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418670,
    "out:% savings (space heating)": 26.28,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.93,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 153.6,
    "out:Total Energy Use Post PV": 150.91,
    "out:Primary Energy": 145.28,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.93,
    "out:CO2 Operational/m2": 29.08,
    "out:Total CO2/m2": 41.02,
    "out:Total CO2 (tons)": 132.07,
    "out:Klimatpaverkan": -86.76,
    "out:Initial Cost/MSEK": 1.364,
    "out:Running cost/(Apt SEK y)": 39678,
    "out:Running Cost over RSP/MSEK": 11.177,
    "out:LCP/MSEK": 1.986,
    "out:ROI% old": 35.09,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 151,
    "out:Energy use kWh/m2": 151,
    "out:Energy savings %": 28.5,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 29,
    "out:DH+ DHW/kWh/m2": 146,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 31,
    "out:Return/kSEK/y": 429,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 128800,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 169116,
    "out:DH kr savings": 128800,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437687,
    "out:% savings (space heating)": 22.94,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.02,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 144.91,
    "out:Primary Energy": 140.11,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.93,
    "out:CO2 Operational/m2": 26.92,
    "out:Total CO2/m2": 38.86,
    "out:Total CO2 (tons)": 125.12,
    "out:Klimatpaverkan": -93.71,
    "out:Initial Cost/MSEK": 1.444,
    "out:Running cost/(Apt SEK y)": 38057,
    "out:Running Cost over RSP/MSEK": 10.72,
    "out:LCP/MSEK": 2.361,
    "out:ROI% old": 37.65,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 34,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188436,
    "out:DH kr savings": 148120,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418670,
    "out:% savings (space heating)": 26.28,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.93,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 148.91,
    "out:Primary Energy": 142.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.28,
    "out:CO2 Operational/m2": 28.36,
    "out:Total CO2/m2": 40.65,
    "out:Total CO2 (tons)": 130.88,
    "out:Klimatpaverkan": -87.95,
    "out:Initial Cost/MSEK": 1.5,
    "out:Running cost/(Apt SEK y)": 39137,
    "out:Running Cost over RSP/MSEK": 11.024,
    "out:LCP/MSEK": 2.001,
    "out:ROI% old": 33.34,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 30,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175556,
    "out:DH kr savings": 135240,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437687,
    "out:% savings (space heating)": 22.94,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.02,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 142.91,
    "out:Primary Energy": 137.49,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.28,
    "out:CO2 Operational/m2": 26.2,
    "out:Total CO2/m2": 38.49,
    "out:Total CO2 (tons)": 123.93,
    "out:Klimatpaverkan": -94.9,
    "out:Initial Cost/MSEK": 1.581,
    "out:Running cost/(Apt SEK y)": 37517,
    "out:Running Cost over RSP/MSEK": 10.568,
    "out:LCP/MSEK": 2.377,
    "out:ROI% old": 35.77,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 32,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194876,
    "out:DH kr savings": 154560,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418670,
    "out:% savings (space heating)": 26.28,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.93,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 151.6,
    "out:Primary Energy": 146.62,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.7,
    "out:CO2 Operational/m2": 52.84,
    "out:Total CO2/m2": 56.54,
    "out:Total CO2 (tons)": 182.06,
    "out:Klimatpaverkan": -36.77,
    "out:Initial Cost/MSEK": 1.012,
    "out:Running cost/(Apt SEK y)": 40257,
    "out:Running Cost over RSP/MSEK": 11.331,
    "out:LCP/MSEK": 2.182,
    "out:ROI% old": 45.09,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 152,
    "out:Energy use kWh/m2": 152,
    "out:Energy savings %": 27.6,
    "out:Op energy cost/MSEK": 83,
    "out:El price/MSEK": 50,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 40,
    "out:Return/kSEK/y": 408,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 135240,
    "out:DH kr savings": 135240,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437687,
    "out:% savings (space heating)": 22.94,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.02,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 145.6,
    "out:Primary Energy": 141.45,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 3.7,
    "out:CO2 Operational/m2": 50.68,
    "out:Total CO2/m2": 54.38,
    "out:Total CO2 (tons)": 175.1,
    "out:Klimatpaverkan": -43.73,
    "out:Initial Cost/MSEK": 1.093,
    "out:Running cost/(Apt SEK y)": 38636,
    "out:Running Cost over RSP/MSEK": 10.875,
    "out:LCP/MSEK": 2.558,
    "out:ROI% old": 47.74,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 43,
    "out:Return/kSEK/y": 467,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 14,
    "out:Payback Time": 7.142857,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 154560,
    "out:DH kr savings": 154560,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418670,
    "out:% savings (space heating)": 26.28,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.93,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 149.6,
    "out:Primary Energy": 144.52,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.05,
    "out:CO2 Operational/m2": 52.12,
    "out:Total CO2/m2": 56.17,
    "out:Total CO2 (tons)": 180.87,
    "out:Klimatpaverkan": -37.96,
    "out:Initial Cost/MSEK": 1.149,
    "out:Running cost/(Apt SEK y)": 39717,
    "out:Running Cost over RSP/MSEK": 11.179,
    "out:LCP/MSEK": 2.198,
    "out:ROI% old": 41.62,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 37,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141680,
    "out:DH kr savings": 141680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437687,
    "out:% savings (space heating)": 22.94,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.02,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 144.6,
    "out:Primary Energy": 139.35,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 4.05,
    "out:CO2 Operational/m2": 50.32,
    "out:Total CO2/m2": 54.37,
    "out:Total CO2 (tons)": 175.07,
    "out:Klimatpaverkan": -43.76,
    "out:Initial Cost/MSEK": 1.229,
    "out:Running cost/(Apt SEK y)": 38366,
    "out:Running Cost over RSP/MSEK": 10.799,
    "out:LCP/MSEK": 2.498,
    "out:ROI% old": 43.32,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 39,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157780,
    "out:DH kr savings": 157780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418670,
    "out:% savings (space heating)": 26.28,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.93,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 151.6,
    "out:Total Energy Use Post PV": 148.91,
    "out:Primary Energy": 141.78,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.93,
    "out:CO2 Operational/m2": 28.36,
    "out:Total CO2/m2": 40.3,
    "out:Total CO2 (tons)": 129.75,
    "out:Klimatpaverkan": -89.08,
    "out:Initial Cost/MSEK": 1.459,
    "out:Running cost/(Apt SEK y)": 39137,
    "out:Running Cost over RSP/MSEK": 11.024,
    "out:LCP/MSEK": 2.043,
    "out:ROI% old": 34.29,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 144,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 31,
    "out:Return/kSEK/y": 449,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 135240,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175556,
    "out:DH kr savings": 135240,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437687,
    "out:% savings (space heating)": 22.94,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.02,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 145.6,
    "out:Total Energy Use Post PV": 142.91,
    "out:Primary Energy": 136.61,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 11.93,
    "out:CO2 Operational/m2": 26.2,
    "out:Total CO2/m2": 38.14,
    "out:Total CO2 (tons)": 122.8,
    "out:Klimatpaverkan": -96.03,
    "out:Initial Cost/MSEK": 1.539,
    "out:Running cost/(Apt SEK y)": 37517,
    "out:Running Cost over RSP/MSEK": 10.568,
    "out:LCP/MSEK": 2.419,
    "out:ROI% old": 36.73,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 138,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 507,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 13,
    "out:Payback Time": 7.692308,
    "out:DH kWh savings": 154560,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194876,
    "out:DH kr savings": 154560,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418670,
    "out:% savings (space heating)": 26.28,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 135.93,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 146.91,
    "out:Primary Energy": 139.68,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 12.28,
    "out:CO2 Operational/m2": 27.64,
    "out:Total CO2/m2": 39.93,
    "out:Total CO2 (tons)": 128.56,
    "out:Klimatpaverkan": -90.27,
    "out:Initial Cost/MSEK": 1.595,
    "out:Running cost/(Apt SEK y)": 38597,
    "out:Running Cost over RSP/MSEK": 10.872,
    "out:LCP/MSEK": 2.058,
    "out:ROI% old": 32.72,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 29,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 11,
    "out:Payback Time": 9.090909,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181996,
    "out:DH kr savings": 141680,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 437687,
    "out:% savings (space heating)": 22.94,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 130.02,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 141.91,
    "out:Primary Energy": 134.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 12.28,
    "out:CO2 Operational/m2": 25.84,
    "out:Total CO2/m2": 38.13,
    "out:Total CO2 (tons)": 122.77,
    "out:Klimatpaverkan": -96.06,
    "out:Initial Cost/MSEK": 1.676,
    "out:Running cost/(Apt SEK y)": 37246,
    "out:Running Cost over RSP/MSEK": 10.492,
    "out:LCP/MSEK": 2.358,
    "out:ROI% old": 34.39,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 31,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 12,
    "out:Payback Time": 8.333333,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198096,
    "out:DH kr savings": 157780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 418670,
    "out:% savings (space heating)": 26.28,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 143.84,
    "out:Total Energy Use Post PV": 143.85,
    "out:Primary Energy": 151.73,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.96,
    "out:CO2 Operational/m2": 48.4,
    "out:Total CO2/m2": 55.36,
    "out:Total CO2 (tons)": 178.26,
    "out:Klimatpaverkan": -40.57,
    "out:Initial Cost/MSEK": 1.528,
    "out:Running cost/(Apt SEK y)": 37501,
    "out:Running Cost over RSP/MSEK": 10.551,
    "out:LCP/MSEK": 2.447,
    "out:ROI% old": 37.17,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 33,
    "out:Return/kSEK/y": 508,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 136877,
    "out:DH kr savings": 183540,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 416079,
    "out:% savings (space heating)": 26.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 137.84,
    "out:Total Energy Use Post PV": 137.85,
    "out:Primary Energy": 146.57,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.96,
    "out:CO2 Operational/m2": 46.24,
    "out:Total CO2/m2": 53.2,
    "out:Total CO2 (tons)": 171.3,
    "out:Klimatpaverkan": -47.53,
    "out:Initial Cost/MSEK": 1.608,
    "out:Running cost/(Apt SEK y)": 35880,
    "out:Running Cost over RSP/MSEK": 10.095,
    "out:LCP/MSEK": 2.823,
    "out:ROI% old": 39.36,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 35,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 156197,
    "out:DH kr savings": 202860,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 397062,
    "out:% savings (space heating)": 30.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 142.85,
    "out:Primary Energy": 149.11,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 48.04,
    "out:Total CO2/m2": 55.35,
    "out:Total CO2 (tons)": 178.22,
    "out:Klimatpaverkan": -40.61,
    "out:Initial Cost/MSEK": 1.664,
    "out:Running cost/(Apt SEK y)": 37229,
    "out:Running Cost over RSP/MSEK": 10.474,
    "out:LCP/MSEK": 2.387,
    "out:ROI% old": 34.78,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 31,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 140097,
    "out:DH kr savings": 186760,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411563,
    "out:% savings (space heating)": 27.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 136.83,
    "out:Total Energy Use Post PV": 136.85,
    "out:Primary Energy": 143.94,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 45.88,
    "out:Total CO2/m2": 53.19,
    "out:Total CO2 (tons)": 171.27,
    "out:Klimatpaverkan": -47.56,
    "out:Initial Cost/MSEK": 1.745,
    "out:Running cost/(Apt SEK y)": 35608,
    "out:Running Cost over RSP/MSEK": 10.018,
    "out:LCP/MSEK": 2.763,
    "out:ROI% old": 36.91,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 33,
    "out:Return/kSEK/y": 576,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 159417,
    "out:DH kr savings": 206080,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 392550,
    "out:% savings (space heating)": 30.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 129.22,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 143.84,
    "out:Total Energy Use Post PV": 139.5,
    "out:Primary Energy": 143.92,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.2,
    "out:CO2 Operational/m2": 32.2,
    "out:Total CO2/m2": 47.4,
    "out:Total CO2 (tons)": 152.64,
    "out:Klimatpaverkan": -66.19,
    "out:Initial Cost/MSEK": 1.974,
    "out:Running cost/(Apt SEK y)": 36307,
    "out:Running Cost over RSP/MSEK": 10.224,
    "out:LCP/MSEK": 2.328,
    "out:ROI% old": 31.13,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 28,
    "out:Return/kSEK/y": 551,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.48,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.14,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 179862,
    "out:DH kr savings": 183540,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 416079,
    "out:% savings (space heating)": 26.74,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.31,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 137.84,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 138.75,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.2,
    "out:CO2 Operational/m2": 30.04,
    "out:Total CO2/m2": 45.24,
    "out:Total CO2 (tons)": 145.68,
    "out:Klimatpaverkan": -73.15,
    "out:Initial Cost/MSEK": 2.055,
    "out:Running cost/(Apt SEK y)": 34686,
    "out:Running Cost over RSP/MSEK": 9.767,
    "out:LCP/MSEK": 2.704,
    "out:ROI% old": 33.08,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 30,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.5,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 199182,
    "out:DH kr savings": 202860,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 397062,
    "out:% savings (space heating)": 30.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 142.83,
    "out:Total Energy Use Post PV": 138.5,
    "out:Primary Energy": 141.29,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.55,
    "out:CO2 Operational/m2": 31.83,
    "out:Total CO2/m2": 47.38,
    "out:Total CO2 (tons)": 152.56,
    "out:Klimatpaverkan": -66.27,
    "out:Initial Cost/MSEK": 2.111,
    "out:Running cost/(Apt SEK y)": 36035,
    "out:Running Cost over RSP/MSEK": 10.147,
    "out:LCP/MSEK": 2.268,
    "out:ROI% old": 29.63,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 27,
    "out:Return/kSEK/y": 560,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.49,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 183092,
    "out:DH kr savings": 186760,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 411563,
    "out:% savings (space heating)": 27.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.91,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 136.83,
    "out:Total Energy Use Post PV": 132.5,
    "out:Primary Energy": 136.12,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.55,
    "out:CO2 Operational/m2": 29.67,
    "out:Total CO2/m2": 45.22,
    "out:Total CO2 (tons)": 145.61,
    "out:Klimatpaverkan": -73.22,
    "out:Initial Cost/MSEK": 2.191,
    "out:Running cost/(Apt SEK y)": 34414,
    "out:Running Cost over RSP/MSEK": 9.691,
    "out:LCP/MSEK": 2.644,
    "out:ROI% old": 31.52,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 122,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 28,
    "out:Return/kSEK/y": 619,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.7,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13972.92,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 206080,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 202413,
    "out:DH kr savings": 206080,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 392550,
    "out:% savings (space heating)": 30.88,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 141.83,
    "out:Total Energy Use Post PV": 141.85,
    "out:Primary Energy": 148.23,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.96,
    "out:CO2 Operational/m2": 47.68,
    "out:Total CO2/m2": 54.64,
    "out:Total CO2 (tons)": 175.93,
    "out:Klimatpaverkan": -42.9,
    "out:Initial Cost/MSEK": 1.623,
    "out:Running cost/(Apt SEK y)": 36958,
    "out:Running Cost over RSP/MSEK": 10.398,
    "out:LCP/MSEK": 2.505,
    "out:ROI% old": 36.34,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 32,
    "out:Return/kSEK/y": 527,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 143317,
    "out:DH kr savings": 189980,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410075,
    "out:% savings (space heating)": 27.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 135.82,
    "out:Total Energy Use Post PV": 135.85,
    "out:Primary Energy": 143.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 6.96,
    "out:CO2 Operational/m2": 45.52,
    "out:Total CO2/m2": 52.48,
    "out:Total CO2 (tons)": 168.98,
    "out:Klimatpaverkan": -49.85,
    "out:Initial Cost/MSEK": 1.703,
    "out:Running cost/(Apt SEK y)": 35337,
    "out:Running Cost over RSP/MSEK": 9.942,
    "out:LCP/MSEK": 2.881,
    "out:ROI% old": 38.45,
    "out:Payback discounted": 3,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 34,
    "out:Return/kSEK/y": 586,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 162637,
    "out:DH kr savings": 209300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 391065,
    "out:% savings (space heating)": 31.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 140.81,
    "out:Total Energy Use Post PV": 140.85,
    "out:Primary Energy": 146.13,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 47.31,
    "out:Total CO2/m2": 54.62,
    "out:Total CO2 (tons)": 175.89,
    "out:Klimatpaverkan": -42.94,
    "out:Initial Cost/MSEK": 1.759,
    "out:Running cost/(Apt SEK y)": 36684,
    "out:Running Cost over RSP/MSEK": 10.321,
    "out:LCP/MSEK": 2.445,
    "out:ROI% old": 34.14,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 31,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146537,
    "out:DH kr savings": 193200,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 406554,
    "out:% savings (space heating)": 28.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 134.81,
    "out:Total Energy Use Post PV": 134.85,
    "out:Primary Energy": 140.97,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 7.31,
    "out:CO2 Operational/m2": 45.15,
    "out:Total CO2/m2": 52.46,
    "out:Total CO2 (tons)": 168.94,
    "out:Klimatpaverkan": -49.89,
    "out:Initial Cost/MSEK": 1.84,
    "out:Running cost/(Apt SEK y)": 35063,
    "out:Running Cost over RSP/MSEK": 9.865,
    "out:LCP/MSEK": 2.821,
    "out:ROI% old": 36.19,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1036.4,
    "out:Return %": 32,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165857,
    "out:DH kr savings": 212520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 387555,
    "out:% savings (space heating)": 31.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 141.83,
    "out:Total Energy Use Post PV": 137.5,
    "out:Primary Energy": 140.42,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.2,
    "out:CO2 Operational/m2": 31.47,
    "out:Total CO2/m2": 46.66,
    "out:Total CO2 (tons)": 150.26,
    "out:Klimatpaverkan": -68.57,
    "out:Initial Cost/MSEK": 2.069,
    "out:Running cost/(Apt SEK y)": 35764,
    "out:Running Cost over RSP/MSEK": 10.071,
    "out:LCP/MSEK": 2.386,
    "out:ROI% old": 30.76,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 28,
    "out:Return/kSEK/y": 570,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.82,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.79,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 186317,
    "out:DH kr savings": 189980,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 410075,
    "out:% savings (space heating)": 27.8,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 121.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 135.82,
    "out:Total Energy Use Post PV": 131.5,
    "out:Primary Energy": 135.25,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.2,
    "out:CO2 Operational/m2": 29.31,
    "out:Total CO2/m2": 44.5,
    "out:Total CO2 (tons)": 143.3,
    "out:Klimatpaverkan": -75.53,
    "out:Initial Cost/MSEK": 2.15,
    "out:Running cost/(Apt SEK y)": 34143,
    "out:Running Cost over RSP/MSEK": 9.614,
    "out:LCP/MSEK": 2.762,
    "out:ROI% old": 32.64,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 29,
    "out:Return/kSEK/y": 629,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.18,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.44,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 10,
    "out:Payback Time": 10,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 205638,
    "out:DH kr savings": 209300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 391065,
    "out:% savings (space heating)": 31.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.26,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 140.81,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 138.32,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.55,
    "out:CO2 Operational/m2": 31.09,
    "out:Total CO2/m2": 46.63,
    "out:Total CO2 (tons)": 150.16,
    "out:Klimatpaverkan": -68.67,
    "out:Initial Cost/MSEK": 2.206,
    "out:Running cost/(Apt SEK y)": 35491,
    "out:Running Cost over RSP/MSEK": 9.994,
    "out:LCP/MSEK": 2.326,
    "out:ROI% old": 29.35,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 26,
    "out:Return/kSEK/y": 580,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.73,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.88,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189554,
    "out:DH kr savings": 193200,
    "out:El kr savings": -18698,
    "out:El kr sold": 15052,
    "out:El kr saved": 27918,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 406554,
    "out:% savings (space heating)": 28.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 135.93,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.36,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 134.81,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 133.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.55,
    "out:CO2 Operational/m2": 28.93,
    "out:Total CO2/m2": 44.47,
    "out:Total CO2 (tons)": 143.2,
    "out:Klimatpaverkan": -75.63,
    "out:Initial Cost/MSEK": 2.286,
    "out:Running cost/(Apt SEK y)": 33870,
    "out:Running Cost over RSP/MSEK": 9.537,
    "out:LCP/MSEK": 2.702,
    "out:ROI% old": 31.17,
    "out:Payback discounted": 4,
    "out:Atemp": 3220,
    "out:Umean": 1.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 48.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 28,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.75,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.87,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 9,
    "out:Payback Time": 11.111111,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208875,
    "out:DH kr savings": 212520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27916,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 387555,
    "out:% savings (space heating)": 31.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 130.02,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.81,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 149.6,
    "out:Primary Energy": 147.39,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.61,
    "out:CO2 Operational/m2": 52.12,
    "out:Total CO2/m2": 62.73,
    "out:Total CO2 (tons)": 201.98,
    "out:Klimatpaverkan": -16.85,
    "out:Initial Cost/MSEK": 2.404,
    "out:Running cost/(Apt SEK y)": 39717,
    "out:Running Cost over RSP/MSEK": 11.179,
    "out:LCP/MSEK": 0.943,
    "out:ROI% old": 19.89,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 150,
    "out:Energy use kWh/m2": 150,
    "out:Energy savings %": 29.3,
    "out:Op energy cost/MSEK": 82,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 428,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 141680,
    "out:DH kr savings": 141680,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427643,
    "out:% savings (space heating)": 24.7,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.04,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 144.6,
    "out:Primary Energy": 142.34,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.61,
    "out:CO2 Operational/m2": 50.32,
    "out:Total CO2/m2": 60.93,
    "out:Total CO2 (tons)": 196.18,
    "out:Klimatpaverkan": -22.65,
    "out:Initial Cost/MSEK": 2.484,
    "out:Running cost/(Apt SEK y)": 38366,
    "out:Running Cost over RSP/MSEK": 10.799,
    "out:LCP/MSEK": 1.243,
    "out:ROI% old": 21.43,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 476,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 157780,
    "out:DH kr savings": 157780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409083,
    "out:% savings (space heating)": 27.97,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.81,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 148.6,
    "out:Total Energy Use Post PV": 148.6,
    "out:Primary Energy": 144.76,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.96,
    "out:CO2 Operational/m2": 51.76,
    "out:Total CO2/m2": 62.72,
    "out:Total CO2 (tons)": 201.95,
    "out:Klimatpaverkan": -16.88,
    "out:Initial Cost/MSEK": 2.541,
    "out:Running cost/(Apt SEK y)": 39447,
    "out:Running Cost over RSP/MSEK": 11.103,
    "out:LCP/MSEK": 0.882,
    "out:ROI% old": 19.25,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 149,
    "out:Energy use kWh/m2": 149,
    "out:Energy savings %": 30.2,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 438,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 144900,
    "out:DH kr savings": 144900,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427643,
    "out:% savings (space heating)": 24.7,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.04,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 142.6,
    "out:Primary Energy": 139.72,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.96,
    "out:CO2 Operational/m2": 49.6,
    "out:Total CO2/m2": 60.56,
    "out:Total CO2 (tons)": 194.99,
    "out:Klimatpaverkan": -23.84,
    "out:Initial Cost/MSEK": 2.621,
    "out:Running cost/(Apt SEK y)": 37826,
    "out:Running Cost over RSP/MSEK": 10.647,
    "out:LCP/MSEK": 1.258,
    "out:ROI% old": 21.14,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164220,
    "out:DH kr savings": 164220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409083,
    "out:% savings (space heating)": 27.97,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.81,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 149.6,
    "out:Total Energy Use Post PV": 146.91,
    "out:Primary Energy": 142.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 27.64,
    "out:Total CO2/m2": 46.48,
    "out:Total CO2 (tons)": 149.67,
    "out:Klimatpaverkan": -69.16,
    "out:Initial Cost/MSEK": 2.851,
    "out:Running cost/(Apt SEK y)": 38597,
    "out:Running Cost over RSP/MSEK": 10.872,
    "out:LCP/MSEK": 0.803,
    "out:ROI% old": 18.31,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 142,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 468,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 141680,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181996,
    "out:DH kr savings": 141680,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427643,
    "out:% savings (space heating)": 24.7,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.04,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 144.6,
    "out:Total Energy Use Post PV": 141.91,
    "out:Primary Energy": 137.51,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 25.84,
    "out:Total CO2/m2": 44.68,
    "out:Total CO2 (tons)": 143.88,
    "out:Klimatpaverkan": -74.95,
    "out:Initial Cost/MSEK": 2.931,
    "out:Running cost/(Apt SEK y)": 37246,
    "out:Running Cost over RSP/MSEK": 10.492,
    "out:LCP/MSEK": 1.103,
    "out:ROI% old": 19.66,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 137,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 517,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 157780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198096,
    "out:DH kr savings": 157780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409083,
    "out:% savings (space heating)": 27.97,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.81,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 148.6,
    "out:Total Energy Use Post PV": 145.91,
    "out:Primary Energy": 139.93,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.19,
    "out:CO2 Operational/m2": 27.28,
    "out:Total CO2/m2": 46.47,
    "out:Total CO2 (tons)": 149.64,
    "out:Klimatpaverkan": -69.19,
    "out:Initial Cost/MSEK": 2.987,
    "out:Running cost/(Apt SEK y)": 38327,
    "out:Running Cost over RSP/MSEK": 10.796,
    "out:LCP/MSEK": 0.742,
    "out:ROI% old": 17.84,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 146,
    "out:Energy use kWh/m2": 146,
    "out:Energy savings %": 32.9,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 141,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 478,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 144900,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 185216,
    "out:DH kr savings": 144900,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427643,
    "out:% savings (space heating)": 24.7,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.04,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 139.91,
    "out:Primary Energy": 134.88,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.19,
    "out:CO2 Operational/m2": 25.12,
    "out:Total CO2/m2": 44.31,
    "out:Total CO2 (tons)": 142.69,
    "out:Klimatpaverkan": -76.14,
    "out:Initial Cost/MSEK": 3.068,
    "out:Running cost/(Apt SEK y)": 36706,
    "out:Running Cost over RSP/MSEK": 10.34,
    "out:LCP/MSEK": 1.118,
    "out:ROI% old": 19.49,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204536,
    "out:DH kr savings": 164220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409083,
    "out:% savings (space heating)": 27.97,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.81,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 147.6,
    "out:Primary Energy": 143.89,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.61,
    "out:CO2 Operational/m2": 51.4,
    "out:Total CO2/m2": 62.01,
    "out:Total CO2 (tons)": 199.66,
    "out:Klimatpaverkan": -19.17,
    "out:Initial Cost/MSEK": 2.499,
    "out:Running cost/(Apt SEK y)": 39177,
    "out:Running Cost over RSP/MSEK": 11.027,
    "out:LCP/MSEK": 1,
    "out:ROI% old": 20,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 148,
    "out:Energy use kWh/m2": 148,
    "out:Energy savings %": 31.1,
    "out:Op energy cost/MSEK": 81,
    "out:El price/MSEK": 49,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 447,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 148120,
    "out:DH kr savings": 148120,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427643,
    "out:% savings (space heating)": 24.7,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.04,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 142.6,
    "out:Primary Energy": 138.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.61,
    "out:CO2 Operational/m2": 49.6,
    "out:Total CO2/m2": 60.21,
    "out:Total CO2 (tons)": 193.86,
    "out:Klimatpaverkan": -24.97,
    "out:Initial Cost/MSEK": 2.58,
    "out:Running cost/(Apt SEK y)": 37826,
    "out:Running Cost over RSP/MSEK": 10.647,
    "out:LCP/MSEK": 1.3,
    "out:ROI% old": 21.48,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 164220,
    "out:DH kr savings": 164220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409083,
    "out:% savings (space heating)": 27.97,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.81,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 146.6,
    "out:Primary Energy": 141.79,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.96,
    "out:CO2 Operational/m2": 51.04,
    "out:Total CO2/m2": 62,
    "out:Total CO2 (tons)": 199.63,
    "out:Klimatpaverkan": -19.2,
    "out:Initial Cost/MSEK": 2.636,
    "out:Running cost/(Apt SEK y)": 38907,
    "out:Running Cost over RSP/MSEK": 10.951,
    "out:LCP/MSEK": 0.939,
    "out:ROI% old": 19.38,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 147,
    "out:Energy use kWh/m2": 147,
    "out:Energy savings %": 32,
    "out:Op energy cost/MSEK": 80,
    "out:El price/MSEK": 48,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 457,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 151340,
    "out:DH kr savings": 151340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427643,
    "out:% savings (space heating)": 24.7,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.04,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 141.6,
    "out:Total Energy Use Post PV": 141.6,
    "out:Primary Energy": 136.74,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 10.96,
    "out:CO2 Operational/m2": 49.24,
    "out:Total CO2/m2": 60.2,
    "out:Total CO2 (tons)": 193.83,
    "out:Klimatpaverkan": -25,
    "out:Initial Cost/MSEK": 2.716,
    "out:Running cost/(Apt SEK y)": 37556,
    "out:Running Cost over RSP/MSEK": 10.571,
    "out:LCP/MSEK": 1.239,
    "out:ROI% old": 20.8,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 142,
    "out:Energy use kWh/m2": 142,
    "out:Energy savings %": 36.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 19,
    "out:Return/kSEK/y": 506,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 167440,
    "out:DH kr savings": 167440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409083,
    "out:% savings (space heating)": 27.97,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z00012200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.81,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 147.6,
    "out:Total Energy Use Post PV": 144.91,
    "out:Primary Energy": 139.05,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 26.92,
    "out:Total CO2/m2": 45.76,
    "out:Total CO2 (tons)": 147.36,
    "out:Klimatpaverkan": -71.47,
    "out:Initial Cost/MSEK": 2.946,
    "out:Running cost/(Apt SEK y)": 38057,
    "out:Running Cost over RSP/MSEK": 10.72,
    "out:LCP/MSEK": 0.86,
    "out:ROI% old": 18.46,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 145,
    "out:Energy use kWh/m2": 145,
    "out:Energy savings %": 33.8,
    "out:Op energy cost/MSEK": 79,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 28,
    "out:DH+ DHW/kWh/m2": 140,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 488,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 148120,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 188436,
    "out:DH kr savings": 148120,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427643,
    "out:% savings (space heating)": 24.7,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.04,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 139.91,
    "out:Primary Energy": 134.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.84,
    "out:CO2 Operational/m2": 25.12,
    "out:Total CO2/m2": 43.96,
    "out:Total CO2 (tons)": 141.56,
    "out:Klimatpaverkan": -77.27,
    "out:Initial Cost/MSEK": 3.026,
    "out:Running cost/(Apt SEK y)": 36706,
    "out:Running Cost over RSP/MSEK": 10.34,
    "out:LCP/MSEK": 1.16,
    "out:ROI% old": 19.76,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 204536,
    "out:DH kr savings": 164220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409083,
    "out:% savings (space heating)": 27.97,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 132.81,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 146.6,
    "out:Total Energy Use Post PV": 143.91,
    "out:Primary Energy": 136.95,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 19.19,
    "out:CO2 Operational/m2": 26.56,
    "out:Total CO2/m2": 45.75,
    "out:Total CO2 (tons)": 147.33,
    "out:Klimatpaverkan": -71.5,
    "out:Initial Cost/MSEK": 3.082,
    "out:Running cost/(Apt SEK y)": 37787,
    "out:Running Cost over RSP/MSEK": 10.644,
    "out:LCP/MSEK": 0.8,
    "out:ROI% old": 17.99,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 144,
    "out:Energy use kWh/m2": 144,
    "out:Energy savings %": 34.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 139,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 497,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 151340,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 191656,
    "out:DH kr savings": 151340,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 427643,
    "out:% savings (space heating)": 24.7,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 127.04,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 141.6,
    "out:Total Energy Use Post PV": 138.91,
    "out:Primary Energy": 131.91,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.19,
    "out:CO2 Operational/m2": 24.76,
    "out:Total CO2/m2": 43.95,
    "out:Total CO2 (tons)": 141.53,
    "out:Klimatpaverkan": -77.3,
    "out:Initial Cost/MSEK": 3.163,
    "out:Running cost/(Apt SEK y)": 36436,
    "out:Running Cost over RSP/MSEK": 10.264,
    "out:LCP/MSEK": 1.099,
    "out:ROI% old": 19.25,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 134,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 546,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 167440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 207756,
    "out:DH kr savings": 167440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 409083,
    "out:% savings (space heating)": 27.97,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z00012201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 140.84,
    "out:Total Energy Use Post PV": 140.85,
    "out:Primary Energy": 149,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.87,
    "out:CO2 Operational/m2": 47.32,
    "out:Total CO2/m2": 61.19,
    "out:Total CO2 (tons)": 197.02,
    "out:Klimatpaverkan": -21.81,
    "out:Initial Cost/MSEK": 3.015,
    "out:Running cost/(Apt SEK y)": 36691,
    "out:Running Cost over RSP/MSEK": 10.323,
    "out:LCP/MSEK": 1.189,
    "out:ROI% old": 19.92,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 18,
    "out:Return/kSEK/y": 537,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 146537,
    "out:DH kr savings": 193200,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 406033,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.33,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 134.84,
    "out:Total Energy Use Post PV": 134.85,
    "out:Primary Energy": 143.96,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.87,
    "out:CO2 Operational/m2": 45.16,
    "out:Total CO2/m2": 59.03,
    "out:Total CO2 (tons)": 190.06,
    "out:Klimatpaverkan": -28.77,
    "out:Initial Cost/MSEK": 3.095,
    "out:Running cost/(Apt SEK y)": 35070,
    "out:Running Cost over RSP/MSEK": 9.866,
    "out:LCP/MSEK": 1.564,
    "out:ROI% old": 21.51,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 19,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 165857,
    "out:DH kr savings": 212520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 387473,
    "out:% savings (space heating)": 31.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 139.85,
    "out:Primary Energy": 146.38,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 46.96,
    "out:Total CO2/m2": 61.17,
    "out:Total CO2 (tons)": 196.98,
    "out:Klimatpaverkan": -21.85,
    "out:Initial Cost/MSEK": 3.151,
    "out:Running cost/(Apt SEK y)": 36418,
    "out:Running Cost over RSP/MSEK": 10.246,
    "out:LCP/MSEK": 1.128,
    "out:ROI% old": 19.4,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 547,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 149757,
    "out:DH kr savings": 196420,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 401514,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 133.85,
    "out:Primary Energy": 141.34,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 44.8,
    "out:Total CO2/m2": 59.01,
    "out:Total CO2 (tons)": 190.03,
    "out:Klimatpaverkan": -28.8,
    "out:Initial Cost/MSEK": 3.232,
    "out:Running cost/(Apt SEK y)": 34798,
    "out:Running Cost over RSP/MSEK": 9.79,
    "out:LCP/MSEK": 1.504,
    "out:ROI% old": 20.94,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 19,
    "out:Return/kSEK/y": 605,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 169077,
    "out:DH kr savings": 215740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 382958,
    "out:% savings (space heating)": 32.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 126.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 140.84,
    "out:Total Energy Use Post PV": 136.5,
    "out:Primary Energy": 141.19,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.1,
    "out:CO2 Operational/m2": 31.13,
    "out:Total CO2/m2": 53.23,
    "out:Total CO2 (tons)": 171.4,
    "out:Klimatpaverkan": -47.43,
    "out:Initial Cost/MSEK": 3.461,
    "out:Running cost/(Apt SEK y)": 35497,
    "out:Running Cost over RSP/MSEK": 9.996,
    "out:LCP/MSEK": 1.069,
    "out:ROI% old": 18.7,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 580,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.41,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 189522,
    "out:DH kr savings": 193200,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 406033,
    "out:% savings (space heating)": 28.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 120.33,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 134.84,
    "out:Total Energy Use Post PV": 130.5,
    "out:Primary Energy": 136.14,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.1,
    "out:CO2 Operational/m2": 28.97,
    "out:Total CO2/m2": 51.07,
    "out:Total CO2 (tons)": 164.44,
    "out:Klimatpaverkan": -54.39,
    "out:Initial Cost/MSEK": 3.542,
    "out:Running cost/(Apt SEK y)": 33876,
    "out:Running Cost over RSP/MSEK": 9.539,
    "out:LCP/MSEK": 1.445,
    "out:ROI% old": 20.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 48.1,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 120,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 638,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.42,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 212520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 208842,
    "out:DH kr savings": 212520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 387473,
    "out:% savings (space heating)": 31.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 139.83,
    "out:Total Energy Use Post PV": 135.5,
    "out:Primary Energy": 138.56,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 30.75,
    "out:Total CO2/m2": 53.21,
    "out:Total CO2 (tons)": 171.33,
    "out:Klimatpaverkan": -47.5,
    "out:Initial Cost/MSEK": 3.598,
    "out:Running cost/(Apt SEK y)": 35225,
    "out:Running Cost over RSP/MSEK": 9.919,
    "out:LCP/MSEK": 1.009,
    "out:ROI% old": 18.29,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 125,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 590,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.2,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.42,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 196420,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 192752,
    "out:DH kr savings": 196420,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 401514,
    "out:% savings (space heating)": 29.31,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.93,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 133.83,
    "out:Total Energy Use Post PV": 129.5,
    "out:Primary Energy": 133.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 28.59,
    "out:Total CO2/m2": 51.05,
    "out:Total CO2 (tons)": 164.37,
    "out:Klimatpaverkan": -54.46,
    "out:Initial Cost/MSEK": 3.678,
    "out:Running cost/(Apt SEK y)": 33604,
    "out:Running Cost over RSP/MSEK": 9.463,
    "out:LCP/MSEK": 1.385,
    "out:ROI% old": 19.66,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 648,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.41,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.21,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 212072,
    "out:DH kr savings": 215740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 382958,
    "out:% savings (space heating)": 32.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 138.83,
    "out:Total Energy Use Post PV": 138.85,
    "out:Primary Energy": 145.5,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.87,
    "out:CO2 Operational/m2": 46.6,
    "out:Total CO2/m2": 60.46,
    "out:Total CO2 (tons)": 194.69,
    "out:Klimatpaverkan": -24.14,
    "out:Initial Cost/MSEK": 3.11,
    "out:Running cost/(Apt SEK y)": 36147,
    "out:Running Cost over RSP/MSEK": 10.17,
    "out:LCP/MSEK": 1.246,
    "out:ROI% old": 20.01,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 139,
    "out:Energy use kWh/m2": 139,
    "out:Energy savings %": 39.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 18,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 152977,
    "out:DH kr savings": 199640,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 400024,
    "out:% savings (space heating)": 29.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 132.83,
    "out:Total Energy Use Post PV": 132.85,
    "out:Primary Energy": 140.46,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 13.87,
    "out:CO2 Operational/m2": 44.44,
    "out:Total CO2/m2": 58.3,
    "out:Total CO2 (tons)": 187.74,
    "out:Klimatpaverkan": -31.09,
    "out:Initial Cost/MSEK": 3.19,
    "out:Running cost/(Apt SEK y)": 34527,
    "out:Running Cost over RSP/MSEK": 9.713,
    "out:LCP/MSEK": 1.622,
    "out:ROI% old": 21.55,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 19,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 172297,
    "out:DH kr savings": 218960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 381470,
    "out:% savings (space heating)": 32.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 137.81,
    "out:Total Energy Use Post PV": 137.85,
    "out:Primary Energy": 143.4,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 46.23,
    "out:Total CO2/m2": 60.45,
    "out:Total CO2 (tons)": 194.65,
    "out:Klimatpaverkan": -24.18,
    "out:Initial Cost/MSEK": 3.246,
    "out:Running cost/(Apt SEK y)": 35874,
    "out:Running Cost over RSP/MSEK": 10.093,
    "out:LCP/MSEK": 1.187,
    "out:ROI% old": 19.51,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 1036.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 566,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 156197,
    "out:DH kr savings": 202860,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 396499,
    "out:% savings (space heating)": 30.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 131.81,
    "out:Total Energy Use Post PV": 131.85,
    "out:Primary Energy": 138.36,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.22,
    "out:CO2 Operational/m2": 44.07,
    "out:Total CO2/m2": 58.29,
    "out:Total CO2 (tons)": 187.7,
    "out:Klimatpaverkan": -31.13,
    "out:Initial Cost/MSEK": 3.327,
    "out:Running cost/(Apt SEK y)": 34253,
    "out:Running Cost over RSP/MSEK": 9.636,
    "out:LCP/MSEK": 1.563,
    "out:ROI% old": 21,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 19,
    "out:Return/kSEK/y": 625,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 175517,
    "out:DH kr savings": 222180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 377956,
    "out:% savings (space heating)": 33.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z00012201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 124.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 138.83,
    "out:Total Energy Use Post PV": 134.5,
    "out:Primary Energy": 137.69,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.1,
    "out:CO2 Operational/m2": 30.39,
    "out:Total CO2/m2": 52.49,
    "out:Total CO2 (tons)": 169.02,
    "out:Klimatpaverkan": -49.81,
    "out:Initial Cost/MSEK": 3.556,
    "out:Running cost/(Apt SEK y)": 34954,
    "out:Running Cost over RSP/MSEK": 9.843,
    "out:LCP/MSEK": 1.127,
    "out:ROI% old": 18.81,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 124,
    "out:El bought/kWh/m2": 741.4,
    "out:Return %": 17,
    "out:Return/kSEK/y": 599,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.44,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13970.18,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 199640,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 195977,
    "out:DH kr savings": 199640,
    "out:El kr savings": -18698,
    "out:El kr sold": 15035,
    "out:El kr saved": 27940,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 400024,
    "out:% savings (space heating)": 29.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 132.83,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 132.64,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.1,
    "out:CO2 Operational/m2": 28.23,
    "out:Total CO2/m2": 50.33,
    "out:Total CO2 (tons)": 162.06,
    "out:Klimatpaverkan": -56.77,
    "out:Initial Cost/MSEK": 3.637,
    "out:Running cost/(Apt SEK y)": 33333,
    "out:Running Cost over RSP/MSEK": 9.386,
    "out:LCP/MSEK": 1.503,
    "out:ROI% old": 20.19,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.79,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.82,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 215297,
    "out:DH kr savings": 218960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 381470,
    "out:% savings (space heating)": 32.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 123.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 137.81,
    "out:Total Energy Use Post PV": 133.5,
    "out:Primary Energy": 135.59,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 30.01,
    "out:Total CO2/m2": 52.46,
    "out:Total CO2 (tons)": 168.93,
    "out:Klimatpaverkan": -49.9,
    "out:Initial Cost/MSEK": 3.693,
    "out:Running cost/(Apt SEK y)": 34681,
    "out:Running Cost over RSP/MSEK": 9.766,
    "out:LCP/MSEK": 1.067,
    "out:ROI% old": 18.41,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 123,
    "out:El bought/kWh/m2": 740.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 609,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10034.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13959.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 202860,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 199213,
    "out:DH kr savings": 202860,
    "out:El kr savings": -18698,
    "out:El kr sold": 15051,
    "out:El kr saved": 27919,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 396499,
    "out:% savings (space heating)": 30.19,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 132.81,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00012201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.38,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 131.81,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 130.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.45,
    "out:CO2 Operational/m2": 27.85,
    "out:Total CO2/m2": 50.3,
    "out:Total CO2 (tons)": 161.97,
    "out:Klimatpaverkan": -56.86,
    "out:Initial Cost/MSEK": 3.773,
    "out:Running cost/(Apt SEK y)": 33060,
    "out:Running Cost over RSP/MSEK": 9.309,
    "out:LCP/MSEK": 1.443,
    "out:ROI% old": 19.75,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 1.27,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 18,
    "out:Return/kSEK/y": 668,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.09,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 1.93,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 218534,
    "out:DH kr savings": 222180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 377956,
    "out:% savings (space heating)": 33.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 127.04,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 142.6,
    "out:Primary Energy": 140.67,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 49.6,
    "out:Total CO2/m2": 64.26,
    "out:Total CO2 (tons)": 206.91,
    "out:Klimatpaverkan": -11.92,
    "out:Initial Cost/MSEK": 3.42,
    "out:Running cost/(Apt SEK y)": 37826,
    "out:Running Cost over RSP/MSEK": 10.647,
    "out:LCP/MSEK": 0.459,
    "out:ROI% old": 16.2,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 15,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164220,
    "out:DH kr savings": 164220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 136.6,
    "out:Total Energy Use Post PV": 136.6,
    "out:Primary Energy": 135.93,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 47.44,
    "out:Total CO2/m2": 62.1,
    "out:Total CO2 (tons)": 199.96,
    "out:Klimatpaverkan": -18.87,
    "out:Initial Cost/MSEK": 3.501,
    "out:Running cost/(Apt SEK y)": 36205,
    "out:Running Cost over RSP/MSEK": 10.19,
    "out:LCP/MSEK": 0.835,
    "out:ROI% old": 17.69,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183540,
    "out:DH kr savings": 183540,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 140.6,
    "out:Primary Energy": 138.05,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.01,
    "out:CO2 Operational/m2": 48.88,
    "out:Total CO2/m2": 63.89,
    "out:Total CO2 (tons)": 205.72,
    "out:Klimatpaverkan": -13.11,
    "out:Initial Cost/MSEK": 3.557,
    "out:Running cost/(Apt SEK y)": 37286,
    "out:Running Cost over RSP/MSEK": 10.495,
    "out:LCP/MSEK": 0.474,
    "out:ROI% old": 16.19,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 14,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170660,
    "out:DH kr savings": 170660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 135.6,
    "out:Total Energy Use Post PV": 135.6,
    "out:Primary Energy": 133.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.01,
    "out:CO2 Operational/m2": 47.08,
    "out:Total CO2/m2": 62.09,
    "out:Total CO2 (tons)": 199.92,
    "out:Klimatpaverkan": -18.91,
    "out:Initial Cost/MSEK": 3.637,
    "out:Running cost/(Apt SEK y)": 35935,
    "out:Running Cost over RSP/MSEK": 10.114,
    "out:LCP/MSEK": 0.774,
    "out:ROI% old": 17.33,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 564,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186760,
    "out:DH kr savings": 186760,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 139.91,
    "out:Primary Energy": 135.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.89,
    "out:CO2 Operational/m2": 25.12,
    "out:Total CO2/m2": 48.01,
    "out:Total CO2 (tons)": 154.61,
    "out:Klimatpaverkan": -64.22,
    "out:Initial Cost/MSEK": 3.867,
    "out:Running cost/(Apt SEK y)": 36706,
    "out:Running Cost over RSP/MSEK": 10.34,
    "out:LCP/MSEK": 0.319,
    "out:ROI% old": 15.46,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204536,
    "out:DH kr savings": 164220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 136.6,
    "out:Total Energy Use Post PV": 133.91,
    "out:Primary Energy": 131.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.89,
    "out:CO2 Operational/m2": 22.96,
    "out:Total CO2/m2": 45.85,
    "out:Total CO2 (tons)": 147.65,
    "out:Klimatpaverkan": -71.18,
    "out:Initial Cost/MSEK": 3.947,
    "out:Running cost/(Apt SEK y)": 35085,
    "out:Running Cost over RSP/MSEK": 9.884,
    "out:LCP/MSEK": 0.695,
    "out:ROI% old": 16.8,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223856,
    "out:DH kr savings": 183540,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 137.91,
    "out:Primary Energy": 133.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.24,
    "out:CO2 Operational/m2": 24.4,
    "out:Total CO2/m2": 47.64,
    "out:Total CO2 (tons)": 153.42,
    "out:Klimatpaverkan": -65.41,
    "out:Initial Cost/MSEK": 4.003,
    "out:Running cost/(Apt SEK y)": 36166,
    "out:Running Cost over RSP/MSEK": 10.188,
    "out:LCP/MSEK": 0.335,
    "out:ROI% old": 15.48,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210976,
    "out:DH kr savings": 170660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 135.6,
    "out:Total Energy Use Post PV": 132.91,
    "out:Primary Energy": 128.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.24,
    "out:CO2 Operational/m2": 22.6,
    "out:Total CO2/m2": 45.84,
    "out:Total CO2 (tons)": 147.62,
    "out:Klimatpaverkan": -71.21,
    "out:Initial Cost/MSEK": 4.084,
    "out:Running cost/(Apt SEK y)": 34815,
    "out:Running Cost over RSP/MSEK": 9.807,
    "out:LCP/MSEK": 0.634,
    "out:ROI% old": 16.51,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227076,
    "out:DH kr savings": 186760,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 140.6,
    "out:Primary Energy": 137.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 48.88,
    "out:Total CO2/m2": 63.54,
    "out:Total CO2 (tons)": 204.59,
    "out:Klimatpaverkan": -14.24,
    "out:Initial Cost/MSEK": 3.515,
    "out:Running cost/(Apt SEK y)": 37286,
    "out:Running Cost over RSP/MSEK": 10.495,
    "out:LCP/MSEK": 0.516,
    "out:ROI% old": 16.38,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 15,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170660,
    "out:DH kr savings": 170660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 134.6,
    "out:Total Energy Use Post PV": 134.6,
    "out:Primary Energy": 132.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 46.72,
    "out:Total CO2/m2": 61.38,
    "out:Total CO2 (tons)": 197.64,
    "out:Klimatpaverkan": -21.19,
    "out:Initial Cost/MSEK": 3.596,
    "out:Running cost/(Apt SEK y)": 35665,
    "out:Running Cost over RSP/MSEK": 10.038,
    "out:LCP/MSEK": 0.892,
    "out:ROI% old": 17.83,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189980,
    "out:DH kr savings": 189980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 139.6,
    "out:Total Energy Use Post PV": 139.6,
    "out:Primary Energy": 135.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.01,
    "out:CO2 Operational/m2": 48.52,
    "out:Total CO2/m2": 63.53,
    "out:Total CO2 (tons)": 204.56,
    "out:Klimatpaverkan": -14.27,
    "out:Initial Cost/MSEK": 3.652,
    "out:Running cost/(Apt SEK y)": 37016,
    "out:Running Cost over RSP/MSEK": 10.419,
    "out:LCP/MSEK": 0.455,
    "out:ROI% old": 16.07,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 14,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 173880,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173880,
    "out:DH kr savings": 173880,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 133.6,
    "out:Total Energy Use Post PV": 133.6,
    "out:Primary Energy": 130.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.01,
    "out:CO2 Operational/m2": 46.36,
    "out:Total CO2/m2": 61.37,
    "out:Total CO2 (tons)": 197.61,
    "out:Klimatpaverkan": -21.22,
    "out:Initial Cost/MSEK": 3.732,
    "out:Running cost/(Apt SEK y)": 35395,
    "out:Running Cost over RSP/MSEK": 9.962,
    "out:LCP/MSEK": 0.831,
    "out:ROI% old": 17.47,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193200,
    "out:DH kr savings": 193200,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 137.91,
    "out:Primary Energy": 132.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.89,
    "out:CO2 Operational/m2": 24.4,
    "out:Total CO2/m2": 47.29,
    "out:Total CO2 (tons)": 152.29,
    "out:Klimatpaverkan": -66.54,
    "out:Initial Cost/MSEK": 3.962,
    "out:Running cost/(Apt SEK y)": 36166,
    "out:Running Cost over RSP/MSEK": 10.188,
    "out:LCP/MSEK": 0.376,
    "out:ROI% old": 15.64,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210976,
    "out:DH kr savings": 170660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 134.6,
    "out:Total Energy Use Post PV": 131.91,
    "out:Primary Energy": 127.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.89,
    "out:CO2 Operational/m2": 22.24,
    "out:Total CO2/m2": 45.13,
    "out:Total CO2 (tons)": 145.33,
    "out:Klimatpaverkan": -73.5,
    "out:Initial Cost/MSEK": 4.042,
    "out:Running cost/(Apt SEK y)": 34545,
    "out:Running Cost over RSP/MSEK": 9.731,
    "out:LCP/MSEK": 0.752,
    "out:ROI% old": 16.94,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 230296,
    "out:DH kr savings": 189980,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 139.6,
    "out:Total Energy Use Post PV": 136.91,
    "out:Primary Energy": 130.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.24,
    "out:CO2 Operational/m2": 24.04,
    "out:Total CO2/m2": 47.28,
    "out:Total CO2 (tons)": 152.26,
    "out:Klimatpaverkan": -66.57,
    "out:Initial Cost/MSEK": 4.098,
    "out:Running cost/(Apt SEK y)": 35896,
    "out:Running Cost over RSP/MSEK": 10.112,
    "out:LCP/MSEK": 0.316,
    "out:ROI% old": 15.39,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 173880,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214196,
    "out:DH kr savings": 173880,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 133.6,
    "out:Total Energy Use Post PV": 130.91,
    "out:Primary Energy": 125.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.24,
    "out:CO2 Operational/m2": 21.88,
    "out:Total CO2/m2": 45.12,
    "out:Total CO2 (tons)": 145.3,
    "out:Klimatpaverkan": -73.53,
    "out:Initial Cost/MSEK": 4.179,
    "out:Running cost/(Apt SEK y)": 34275,
    "out:Running Cost over RSP/MSEK": 9.655,
    "out:LCP/MSEK": 0.692,
    "out:ROI% old": 16.65,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 48.1,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233516,
    "out:DH kr savings": 193200,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 132.84,
    "out:Total Energy Use Post PV": 132.85,
    "out:Primary Energy": 142.29,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 44.44,
    "out:Total CO2/m2": 62.36,
    "out:Total CO2 (tons)": 200.79,
    "out:Klimatpaverkan": -18.04,
    "out:Initial Cost/MSEK": 4.031,
    "out:Running cost/(Apt SEK y)": 34530,
    "out:Running Cost over RSP/MSEK": 9.714,
    "out:LCP/MSEK": 0.781,
    "out:ROI% old": 17.05,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 15,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172297,
    "out:DH kr savings": 218960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 381320,
    "out:% savings (space heating)": 32.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 127.84,
    "out:Total Energy Use Post PV": 127.85,
    "out:Primary Energy": 137.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 42.64,
    "out:Total CO2/m2": 60.56,
    "out:Total CO2 (tons)": 194.99,
    "out:Klimatpaverkan": -23.84,
    "out:Initial Cost/MSEK": 4.111,
    "out:Running cost/(Apt SEK y)": 33179,
    "out:Running Cost over RSP/MSEK": 9.334,
    "out:LCP/MSEK": 1.081,
    "out:ROI% old": 18.04,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 16,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188397,
    "out:DH kr savings": 235060,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 363883,
    "out:% savings (space heating)": 35.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 131.83,
    "out:Total Energy Use Post PV": 131.85,
    "out:Primary Energy": 139.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.27,
    "out:CO2 Operational/m2": 44.08,
    "out:Total CO2/m2": 62.35,
    "out:Total CO2 (tons)": 200.75,
    "out:Klimatpaverkan": -18.08,
    "out:Initial Cost/MSEK": 4.168,
    "out:Running cost/(Apt SEK y)": 34257,
    "out:Running Cost over RSP/MSEK": 9.638,
    "out:LCP/MSEK": 0.721,
    "out:ROI% old": 16.76,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175517,
    "out:DH kr savings": 222180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 376803,
    "out:% savings (space heating)": 33.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 126.83,
    "out:Total Energy Use Post PV": 126.85,
    "out:Primary Energy": 134.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.27,
    "out:CO2 Operational/m2": 42.28,
    "out:Total CO2/m2": 60.55,
    "out:Total CO2 (tons)": 194.96,
    "out:Klimatpaverkan": -23.87,
    "out:Initial Cost/MSEK": 4.248,
    "out:Running cost/(Apt SEK y)": 32907,
    "out:Running Cost over RSP/MSEK": 9.257,
    "out:LCP/MSEK": 1.021,
    "out:ROI% old": 17.72,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 238280,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191617,
    "out:DH kr savings": 238280,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 359370,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 132.84,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 134.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 28.25,
    "out:Total CO2/m2": 54.4,
    "out:Total CO2 (tons)": 175.17,
    "out:Klimatpaverkan": -43.66,
    "out:Initial Cost/MSEK": 4.478,
    "out:Running cost/(Apt SEK y)": 33336,
    "out:Running Cost over RSP/MSEK": 9.387,
    "out:LCP/MSEK": 0.661,
    "out:ROI% old": 16.4,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.36,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.26,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215282,
    "out:DH kr savings": 218960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27961,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 381320,
    "out:% savings (space heating)": 32.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 127.84,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 129.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 26.45,
    "out:Total CO2/m2": 52.6,
    "out:Total CO2 (tons)": 169.37,
    "out:Klimatpaverkan": -49.46,
    "out:Initial Cost/MSEK": 4.558,
    "out:Running cost/(Apt SEK y)": 31985,
    "out:Running Cost over RSP/MSEK": 9.007,
    "out:LCP/MSEK": 0.961,
    "out:ROI% old": 17.3,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.37,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.25,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231382,
    "out:DH kr savings": 235060,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 363883,
    "out:% savings (space heating)": 35.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 131.83,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 131.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 27.87,
    "out:Total CO2/m2": 54.38,
    "out:Total CO2 (tons)": 175.1,
    "out:Klimatpaverkan": -43.73,
    "out:Initial Cost/MSEK": 4.614,
    "out:Running cost/(Apt SEK y)": 33064,
    "out:Running Cost over RSP/MSEK": 9.31,
    "out:LCP/MSEK": 0.601,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.27,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.34,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218512,
    "out:DH kr savings": 222180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 376803,
    "out:% savings (space heating)": 33.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 126.83,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 127.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 26.07,
    "out:Total CO2/m2": 52.58,
    "out:Total CO2 (tons)": 169.3,
    "out:Klimatpaverkan": -49.53,
    "out:Initial Cost/MSEK": 4.695,
    "out:Running cost/(Apt SEK y)": 31713,
    "out:Running Cost over RSP/MSEK": 8.93,
    "out:LCP/MSEK": 0.901,
    "out:ROI% old": 17.03,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 716,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.5,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 238280,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234612,
    "out:DH kr savings": 238280,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 359370,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 131.83,
    "out:Total Energy Use Post PV": 131.85,
    "out:Primary Energy": 138.79,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 44.08,
    "out:Total CO2/m2": 61.99,
    "out:Total CO2 (tons)": 199.62,
    "out:Klimatpaverkan": -19.21,
    "out:Initial Cost/MSEK": 4.126,
    "out:Running cost/(Apt SEK y)": 34256,
    "out:Running Cost over RSP/MSEK": 9.637,
    "out:LCP/MSEK": 0.763,
    "out:ROI% old": 16.93,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175517,
    "out:DH kr savings": 222180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 375316,
    "out:% savings (space heating)": 33.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 125.82,
    "out:Total Energy Use Post PV": 125.85,
    "out:Primary Energy": 134.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 41.92,
    "out:Total CO2/m2": 59.83,
    "out:Total CO2 (tons)": 192.67,
    "out:Klimatpaverkan": -26.16,
    "out:Initial Cost/MSEK": 4.207,
    "out:Running cost/(Apt SEK y)": 32636,
    "out:Running Cost over RSP/MSEK": 9.181,
    "out:LCP/MSEK": 1.138,
    "out:ROI% old": 18.15,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 54,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 683,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194837,
    "out:DH kr savings": 241500,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357886,
    "out:% savings (space heating)": 36.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 129.81,
    "out:Total Energy Use Post PV": 129.85,
    "out:Primary Energy": 136.69,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.27,
    "out:CO2 Operational/m2": 43.35,
    "out:Total CO2/m2": 61.62,
    "out:Total CO2 (tons)": 198.43,
    "out:Klimatpaverkan": -20.4,
    "out:Initial Cost/MSEK": 4.263,
    "out:Running cost/(Apt SEK y)": 33713,
    "out:Running Cost over RSP/MSEK": 9.484,
    "out:LCP/MSEK": 0.779,
    "out:ROI% old": 16.9,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 228620,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181957,
    "out:DH kr savings": 228620,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 371804,
    "out:% savings (space heating)": 34.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 124.81,
    "out:Total Energy Use Post PV": 124.85,
    "out:Primary Energy": 131.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.27,
    "out:CO2 Operational/m2": 41.55,
    "out:Total CO2/m2": 59.82,
    "out:Total CO2 (tons)": 192.63,
    "out:Klimatpaverkan": -26.2,
    "out:Initial Cost/MSEK": 4.343,
    "out:Running cost/(Apt SEK y)": 32362,
    "out:Running Cost over RSP/MSEK": 9.104,
    "out:LCP/MSEK": 1.079,
    "out:ROI% old": 17.83,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1036.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 244720,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198057,
    "out:DH kr savings": 244720,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 354387,
    "out:% savings (space heating)": 37.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 131.83,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 130.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 27.87,
    "out:Total CO2/m2": 54.02,
    "out:Total CO2 (tons)": 173.95,
    "out:Klimatpaverkan": -44.88,
    "out:Initial Cost/MSEK": 4.573,
    "out:Running cost/(Apt SEK y)": 33063,
    "out:Running Cost over RSP/MSEK": 9.31,
    "out:LCP/MSEK": 0.643,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.68,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.93,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218517,
    "out:DH kr savings": 222180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 375316,
    "out:% savings (space heating)": 33.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 125.82,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 126.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 25.71,
    "out:Total CO2/m2": 51.86,
    "out:Total CO2 (tons)": 166.99,
    "out:Klimatpaverkan": -51.84,
    "out:Initial Cost/MSEK": 4.653,
    "out:Running cost/(Apt SEK y)": 31442,
    "out:Running Cost over RSP/MSEK": 8.854,
    "out:LCP/MSEK": 1.019,
    "out:ROI% old": 17.41,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.09,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237838,
    "out:DH kr savings": 241500,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357886,
    "out:% savings (space heating)": 36.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 129.81,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 128.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 27.13,
    "out:Total CO2/m2": 53.63,
    "out:Total CO2 (tons)": 172.7,
    "out:Klimatpaverkan": -46.13,
    "out:Initial Cost/MSEK": 4.709,
    "out:Running cost/(Apt SEK y)": 32519,
    "out:Running Cost over RSP/MSEK": 9.157,
    "out:LCP/MSEK": 0.66,
    "out:ROI% old": 16.29,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 54,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 687,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.11,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.5,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 228620,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224974,
    "out:DH kr savings": 228620,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 371804,
    "out:% savings (space heating)": 34.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 124.81,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 124.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 25.32,
    "out:Total CO2/m2": 51.83,
    "out:Total CO2 (tons)": 166.89,
    "out:Klimatpaverkan": -51.94,
    "out:Initial Cost/MSEK": 4.79,
    "out:Running cost/(Apt SEK y)": 31168,
    "out:Running Cost over RSP/MSEK": 8.777,
    "out:LCP/MSEK": 0.959,
    "out:ROI% old": 17.15,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10036.24,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.38,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 244720,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241076,
    "out:DH kr savings": 244720,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27915,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 354387,
    "out:% savings (space heating)": 37.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 128.6,
    "out:Total Energy Use Post PV": 128.6,
    "out:Primary Energy": 128.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 44.56,
    "out:Total CO2/m2": 61.74,
    "out:Total CO2 (tons)": 198.79,
    "out:Klimatpaverkan": -20.04,
    "out:Initial Cost/MSEK": 4.028,
    "out:Running cost/(Apt SEK y)": 34044,
    "out:Running Cost over RSP/MSEK": 9.582,
    "out:LCP/MSEK": 0.917,
    "out:ROI% old": 17.54,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 632,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209300,
    "out:DH kr savings": 209300,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 123.6,
    "out:Total Energy Use Post PV": 123.6,
    "out:Primary Energy": 124.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 42.76,
    "out:Total CO2/m2": 59.94,
    "out:Total CO2 (tons)": 192.99,
    "out:Klimatpaverkan": -25.84,
    "out:Initial Cost/MSEK": 4.108,
    "out:Running cost/(Apt SEK y)": 32694,
    "out:Running Cost over RSP/MSEK": 9.202,
    "out:LCP/MSEK": 1.216,
    "out:ROI% old": 18.52,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 225400,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225400,
    "out:DH kr savings": 225400,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 126.6,
    "out:Primary Energy": 125.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 43.84,
    "out:Total CO2/m2": 61.37,
    "out:Total CO2 (tons)": 197.6,
    "out:Klimatpaverkan": -21.23,
    "out:Initial Cost/MSEK": 4.164,
    "out:Running cost/(Apt SEK y)": 33504,
    "out:Running Cost over RSP/MSEK": 9.43,
    "out:LCP/MSEK": 0.932,
    "out:ROI% old": 17.48,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215740,
    "out:DH kr savings": 215740,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 121.6,
    "out:Primary Energy": 121.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 42.04,
    "out:Total CO2/m2": 59.57,
    "out:Total CO2 (tons)": 191.8,
    "out:Klimatpaverkan": -27.03,
    "out:Initial Cost/MSEK": 4.245,
    "out:Running cost/(Apt SEK y)": 32154,
    "out:Running Cost over RSP/MSEK": 9.049,
    "out:LCP/MSEK": 1.232,
    "out:ROI% old": 18.43,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231840,
    "out:DH kr savings": 231840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 128.6,
    "out:Total Energy Use Post PV": 125.91,
    "out:Primary Energy": 123.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.41,
    "out:CO2 Operational/m2": 20.08,
    "out:Total CO2/m2": 45.49,
    "out:Total CO2 (tons)": 146.49,
    "out:Klimatpaverkan": -72.34,
    "out:Initial Cost/MSEK": 4.474,
    "out:Running cost/(Apt SEK y)": 32924,
    "out:Running Cost over RSP/MSEK": 9.275,
    "out:LCP/MSEK": 0.777,
    "out:ROI% old": 16.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 54,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249616,
    "out:DH kr savings": 209300,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 123.6,
    "out:Total Energy Use Post PV": 120.91,
    "out:Primary Energy": 119.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.41,
    "out:CO2 Operational/m2": 18.28,
    "out:Total CO2/m2": 43.69,
    "out:Total CO2 (tons)": 140.69,
    "out:Klimatpaverkan": -78.14,
    "out:Initial Cost/MSEK": 4.555,
    "out:Running cost/(Apt SEK y)": 31574,
    "out:Running Cost over RSP/MSEK": 8.895,
    "out:LCP/MSEK": 1.077,
    "out:ROI% old": 17.66,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 225400,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265716,
    "out:DH kr savings": 225400,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 123.91,
    "out:Primary Energy": 120.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.76,
    "out:CO2 Operational/m2": 19.36,
    "out:Total CO2/m2": 45.12,
    "out:Total CO2 (tons)": 145.3,
    "out:Klimatpaverkan": -73.53,
    "out:Initial Cost/MSEK": 4.611,
    "out:Running cost/(Apt SEK y)": 32384,
    "out:Running Cost over RSP/MSEK": 9.123,
    "out:LCP/MSEK": 0.792,
    "out:ROI% old": 16.74,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 692,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256056,
    "out:DH kr savings": 215740,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 118.91,
    "out:Primary Energy": 116.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.76,
    "out:CO2 Operational/m2": 17.56,
    "out:Total CO2/m2": 43.32,
    "out:Total CO2 (tons)": 139.5,
    "out:Klimatpaverkan": -79.33,
    "out:Initial Cost/MSEK": 4.691,
    "out:Running cost/(Apt SEK y)": 31034,
    "out:Running Cost over RSP/MSEK": 8.743,
    "out:LCP/MSEK": 1.092,
    "out:ROI% old": 17.61,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 740,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272156,
    "out:DH kr savings": 231840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 126.6,
    "out:Primary Energy": 124.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 43.84,
    "out:Total CO2/m2": 61.02,
    "out:Total CO2 (tons)": 196.47,
    "out:Klimatpaverkan": -22.36,
    "out:Initial Cost/MSEK": 4.123,
    "out:Running cost/(Apt SEK y)": 33504,
    "out:Running Cost over RSP/MSEK": 9.43,
    "out:LCP/MSEK": 0.974,
    "out:ROI% old": 17.66,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215740,
    "out:DH kr savings": 215740,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 121.6,
    "out:Primary Energy": 120.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 42.04,
    "out:Total CO2/m2": 59.22,
    "out:Total CO2 (tons)": 190.68,
    "out:Klimatpaverkan": -28.15,
    "out:Initial Cost/MSEK": 4.203,
    "out:Running cost/(Apt SEK y)": 32154,
    "out:Running Cost over RSP/MSEK": 9.049,
    "out:LCP/MSEK": 1.273,
    "out:ROI% old": 18.61,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231840,
    "out:DH kr savings": 231840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 124.6,
    "out:Total Energy Use Post PV": 124.6,
    "out:Primary Energy": 122.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 43.12,
    "out:Total CO2/m2": 60.65,
    "out:Total CO2 (tons)": 195.28,
    "out:Klimatpaverkan": -23.55,
    "out:Initial Cost/MSEK": 4.259,
    "out:Running cost/(Apt SEK y)": 32964,
    "out:Running Cost over RSP/MSEK": 9.278,
    "out:LCP/MSEK": 0.989,
    "out:ROI% old": 17.6,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222180,
    "out:DH kr savings": 222180,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 120.6,
    "out:Total Energy Use Post PV": 120.6,
    "out:Primary Energy": 118.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 41.68,
    "out:Total CO2/m2": 59.21,
    "out:Total CO2 (tons)": 190.65,
    "out:Klimatpaverkan": -28.18,
    "out:Initial Cost/MSEK": 4.34,
    "out:Running cost/(Apt SEK y)": 31883,
    "out:Running Cost over RSP/MSEK": 8.973,
    "out:LCP/MSEK": 1.213,
    "out:ROI% old": 18.28,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 235060,
    "out:DH kr savings": 235060,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 123.91,
    "out:Primary Energy": 119.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.41,
    "out:CO2 Operational/m2": 19.36,
    "out:Total CO2/m2": 44.77,
    "out:Total CO2 (tons)": 144.17,
    "out:Klimatpaverkan": -74.66,
    "out:Initial Cost/MSEK": 4.569,
    "out:Running cost/(Apt SEK y)": 32384,
    "out:Running Cost over RSP/MSEK": 9.123,
    "out:LCP/MSEK": 0.834,
    "out:ROI% old": 16.89,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 692,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256056,
    "out:DH kr savings": 215740,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 118.91,
    "out:Primary Energy": 115.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.41,
    "out:CO2 Operational/m2": 17.56,
    "out:Total CO2/m2": 42.97,
    "out:Total CO2 (tons)": 138.37,
    "out:Klimatpaverkan": -80.46,
    "out:Initial Cost/MSEK": 4.65,
    "out:Running cost/(Apt SEK y)": 31034,
    "out:Running Cost over RSP/MSEK": 8.743,
    "out:LCP/MSEK": 1.134,
    "out:ROI% old": 17.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 740,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272156,
    "out:DH kr savings": 231840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 124.6,
    "out:Total Energy Use Post PV": 121.91,
    "out:Primary Energy": 117.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.76,
    "out:CO2 Operational/m2": 18.64,
    "out:Total CO2/m2": 44.4,
    "out:Total CO2 (tons)": 142.98,
    "out:Klimatpaverkan": -75.85,
    "out:Initial Cost/MSEK": 4.706,
    "out:Running cost/(Apt SEK y)": 31844,
    "out:Running Cost over RSP/MSEK": 8.971,
    "out:LCP/MSEK": 0.849,
    "out:ROI% old": 16.86,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 711,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262496,
    "out:DH kr savings": 222180,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 120.6,
    "out:Total Energy Use Post PV": 117.91,
    "out:Primary Energy": 113.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.76,
    "out:CO2 Operational/m2": 17.2,
    "out:Total CO2/m2": 42.96,
    "out:Total CO2 (tons)": 138.34,
    "out:Klimatpaverkan": -80.49,
    "out:Initial Cost/MSEK": 4.786,
    "out:Running cost/(Apt SEK y)": 30764,
    "out:Running Cost over RSP/MSEK": 8.667,
    "out:LCP/MSEK": 1.073,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 64.4,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 750,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275376,
    "out:DH kr savings": 235060,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 118.84,
    "out:Total Energy Use Post PV": 118.85,
    "out:Primary Energy": 129.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.44,
    "out:CO2 Operational/m2": 39.4,
    "out:Total CO2/m2": 59.84,
    "out:Total CO2 (tons)": 192.67,
    "out:Klimatpaverkan": -26.16,
    "out:Initial Cost/MSEK": 4.638,
    "out:Running cost/(Apt SEK y)": 30748,
    "out:Running Cost over RSP/MSEK": 8.649,
    "out:LCP/MSEK": 1.238,
    "out:ROI% old": 18.1,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217377,
    "out:DH kr savings": 264040,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 335507,
    "out:% savings (space heating)": 40.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 113.84,
    "out:Total Energy Use Post PV": 113.85,
    "out:Primary Energy": 125.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.44,
    "out:CO2 Operational/m2": 37.6,
    "out:Total CO2/m2": 58.04,
    "out:Total CO2 (tons)": 186.87,
    "out:Klimatpaverkan": -31.96,
    "out:Initial Cost/MSEK": 4.719,
    "out:Running cost/(Apt SEK y)": 29397,
    "out:Running Cost over RSP/MSEK": 8.269,
    "out:LCP/MSEK": 1.538,
    "out:ROI% old": 18.94,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233477,
    "out:DH kr savings": 280140,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 320153,
    "out:% savings (space heating)": 43.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 117.83,
    "out:Total Energy Use Post PV": 117.85,
    "out:Primary Energy": 127.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.79,
    "out:CO2 Operational/m2": 39.04,
    "out:Total CO2/m2": 59.82,
    "out:Total CO2 (tons)": 192.63,
    "out:Klimatpaverkan": -26.2,
    "out:Initial Cost/MSEK": 4.775,
    "out:Running cost/(Apt SEK y)": 30475,
    "out:Running Cost over RSP/MSEK": 8.573,
    "out:LCP/MSEK": 1.178,
    "out:ROI% old": 17.81,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 64.4,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 761,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220597,
    "out:DH kr savings": 267260,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331002,
    "out:% savings (space heating)": 41.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 112.83,
    "out:Total Energy Use Post PV": 112.85,
    "out:Primary Energy": 123.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.79,
    "out:CO2 Operational/m2": 37.24,
    "out:Total CO2/m2": 58.02,
    "out:Total CO2 (tons)": 186.84,
    "out:Klimatpaverkan": -31.99,
    "out:Initial Cost/MSEK": 4.855,
    "out:Running cost/(Apt SEK y)": 29125,
    "out:Running Cost over RSP/MSEK": 8.192,
    "out:LCP/MSEK": 1.478,
    "out:ROI% old": 18.64,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 71.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1038,
    "out:Return %": 17,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236697,
    "out:DH kr savings": 283360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 315652,
    "out:% savings (space heating)": 44.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 118.84,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 122.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.67,
    "out:CO2 Operational/m2": 23.21,
    "out:Total CO2/m2": 51.88,
    "out:Total CO2 (tons)": 167.05,
    "out:Klimatpaverkan": -51.78,
    "out:Initial Cost/MSEK": 5.085,
    "out:Running cost/(Apt SEK y)": 29554,
    "out:Running Cost over RSP/MSEK": 8.322,
    "out:LCP/MSEK": 1.119,
    "out:ROI% old": 17.43,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 68.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.54,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.08,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260362,
    "out:DH kr savings": 264040,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 335507,
    "out:% savings (space heating)": 40.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 113.84,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 117.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.67,
    "out:CO2 Operational/m2": 21.41,
    "out:Total CO2/m2": 50.08,
    "out:Total CO2 (tons)": 161.25,
    "out:Klimatpaverkan": -57.58,
    "out:Initial Cost/MSEK": 5.165,
    "out:Running cost/(Apt SEK y)": 28203,
    "out:Running Cost over RSP/MSEK": 7.942,
    "out:LCP/MSEK": 1.419,
    "out:ROI% old": 18.21,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.55,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.06,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276462,
    "out:DH kr savings": 280140,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 320153,
    "out:% savings (space heating)": 43.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 117.83,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 119.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 22.83,
    "out:Total CO2/m2": 51.86,
    "out:Total CO2 (tons)": 166.97,
    "out:Klimatpaverkan": -51.86,
    "out:Initial Cost/MSEK": 5.221,
    "out:Running cost/(Apt SEK y)": 29282,
    "out:Running Cost over RSP/MSEK": 8.246,
    "out:LCP/MSEK": 1.059,
    "out:ROI% old": 17.18,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 804,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.17,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.45,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263593,
    "out:DH kr savings": 267260,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331002,
    "out:% savings (space heating)": 41.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 112.83,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 115.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 21.03,
    "out:Total CO2/m2": 50.05,
    "out:Total CO2 (tons)": 161.18,
    "out:Klimatpaverkan": -57.65,
    "out:Initial Cost/MSEK": 5.302,
    "out:Running cost/(Apt SEK y)": 27931,
    "out:Running Cost over RSP/MSEK": 7.865,
    "out:LCP/MSEK": 1.359,
    "out:ROI% old": 17.95,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.43,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279694,
    "out:DH kr savings": 283360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27944,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 315652,
    "out:% savings (space heating)": 44.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 116.82,
    "out:Total Energy Use Post PV": 116.85,
    "out:Primary Energy": 126.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.44,
    "out:CO2 Operational/m2": 38.68,
    "out:Total CO2/m2": 59.11,
    "out:Total CO2 (tons)": 190.34,
    "out:Klimatpaverkan": -28.49,
    "out:Initial Cost/MSEK": 4.733,
    "out:Running cost/(Apt SEK y)": 30204,
    "out:Running Cost over RSP/MSEK": 8.496,
    "out:LCP/MSEK": 1.296,
    "out:ROI% old": 18.2,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 65.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 770,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223817,
    "out:DH kr savings": 270480,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 329522,
    "out:% savings (space heating)": 41.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 112.82,
    "out:Total Energy Use Post PV": 112.85,
    "out:Primary Energy": 122.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.44,
    "out:CO2 Operational/m2": 37.24,
    "out:Total CO2/m2": 57.67,
    "out:Total CO2 (tons)": 185.71,
    "out:Klimatpaverkan": -33.12,
    "out:Initial Cost/MSEK": 4.814,
    "out:Running cost/(Apt SEK y)": 29124,
    "out:Running Cost over RSP/MSEK": 8.192,
    "out:LCP/MSEK": 1.52,
    "out:ROI% old": 18.8,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 71.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236697,
    "out:DH kr savings": 283360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 314175,
    "out:% savings (space heating)": 44.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 115.8,
    "out:Total Energy Use Post PV": 115.85,
    "out:Primary Energy": 124.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.79,
    "out:CO2 Operational/m2": 38.31,
    "out:Total CO2/m2": 59.1,
    "out:Total CO2 (tons)": 190.3,
    "out:Klimatpaverkan": -28.53,
    "out:Initial Cost/MSEK": 4.87,
    "out:Running cost/(Apt SEK y)": 29930,
    "out:Running Cost over RSP/MSEK": 8.419,
    "out:LCP/MSEK": 1.237,
    "out:ROI% old": 17.91,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 67.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1036.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 780,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227037,
    "out:DH kr savings": 273700,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 326032,
    "out:% savings (space heating)": 42.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 110.8,
    "out:Total Energy Use Post PV": 110.85,
    "out:Primary Energy": 120.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.79,
    "out:CO2 Operational/m2": 36.51,
    "out:Total CO2/m2": 57.3,
    "out:Total CO2 (tons)": 184.51,
    "out:Klimatpaverkan": -34.32,
    "out:Initial Cost/MSEK": 4.95,
    "out:Running cost/(Apt SEK y)": 28579,
    "out:Running Cost over RSP/MSEK": 8.039,
    "out:LCP/MSEK": 1.537,
    "out:ROI% old": 18.72,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1036.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 829,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243137,
    "out:DH kr savings": 289800,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 310699,
    "out:% savings (space heating)": 45.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 116.82,
    "out:Total Energy Use Post PV": 112.5,
    "out:Primary Energy": 118.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.67,
    "out:CO2 Operational/m2": 22.46,
    "out:Total CO2/m2": 51.14,
    "out:Total CO2 (tons)": 164.67,
    "out:Klimatpaverkan": -54.16,
    "out:Initial Cost/MSEK": 5.18,
    "out:Running cost/(Apt SEK y)": 29010,
    "out:Running Cost over RSP/MSEK": 8.169,
    "out:LCP/MSEK": 1.177,
    "out:ROI% old": 17.53,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 71.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 813,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.97,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.64,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266819,
    "out:DH kr savings": 270480,
    "out:El kr savings": -18698,
    "out:El kr sold": 15037,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 329522,
    "out:% savings (space heating)": 41.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 112.82,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 114.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.67,
    "out:CO2 Operational/m2": 21.02,
    "out:Total CO2/m2": 49.7,
    "out:Total CO2 (tons)": 160.03,
    "out:Klimatpaverkan": -58.8,
    "out:Initial Cost/MSEK": 5.26,
    "out:Running cost/(Apt SEK y)": 27930,
    "out:Running Cost over RSP/MSEK": 7.865,
    "out:LCP/MSEK": 1.401,
    "out:ROI% old": 18.09,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.42,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.2,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279700,
    "out:DH kr savings": 283360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27936,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 314175,
    "out:% savings (space heating)": 44.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 115.8,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 116.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 22.08,
    "out:Total CO2/m2": 51.11,
    "out:Total CO2 (tons)": 164.56,
    "out:Klimatpaverkan": -54.27,
    "out:Initial Cost/MSEK": 5.316,
    "out:Running cost/(Apt SEK y)": 28737,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 1.117,
    "out:ROI% old": 17.29,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 73.2,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 740,
    "out:Return %": 15,
    "out:Return/kSEK/y": 823,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10037.68,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13955.94,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270058,
    "out:DH kr savings": 273700,
    "out:El kr savings": -18698,
    "out:El kr sold": 15057,
    "out:El kr saved": 27912,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 326032,
    "out:% savings (space heating)": 42.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 110.8,
    "out:Total Energy Use Post PV": 106.5,
    "out:Primary Energy": 112.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 20.28,
    "out:Total CO2/m2": 49.3,
    "out:Total CO2 (tons)": 158.76,
    "out:Klimatpaverkan": -60.07,
    "out:Initial Cost/MSEK": 5.397,
    "out:Running cost/(Apt SEK y)": 27386,
    "out:Running Cost over RSP/MSEK": 7.712,
    "out:LCP/MSEK": 1.417,
    "out:ROI% old": 18.04,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 872,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10038.89,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954.73,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286160,
    "out:DH kr savings": 289800,
    "out:El kr savings": -18698,
    "out:El kr sold": 15058,
    "out:El kr saved": 27909,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 310699,
    "out:% savings (space heating)": 45.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 124.6,
    "out:Total Energy Use Post PV": 124.6,
    "out:Primary Energy": 125.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.08,
    "out:CO2 Operational/m2": 43.12,
    "out:Total CO2/m2": 67.2,
    "out:Total CO2 (tons)": 216.39,
    "out:Klimatpaverkan": -2.44,
    "out:Initial Cost/MSEK": 5.514,
    "out:Running cost/(Apt SEK y)": 32964,
    "out:Running Cost over RSP/MSEK": 9.278,
    "out:LCP/MSEK": -0.266,
    "out:ROI% old": 13.6,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 12,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222180,
    "out:DH kr savings": 222180,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 120.6,
    "out:Total Energy Use Post PV": 120.6,
    "out:Primary Energy": 121.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.08,
    "out:CO2 Operational/m2": 41.68,
    "out:Total CO2/m2": 65.76,
    "out:Total CO2 (tons)": 211.76,
    "out:Klimatpaverkan": -7.07,
    "out:Initial Cost/MSEK": 5.595,
    "out:Running cost/(Apt SEK y)": 31883,
    "out:Running Cost over RSP/MSEK": 8.973,
    "out:LCP/MSEK": -0.042,
    "out:ROI% old": 14.18,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 235060,
    "out:DH kr savings": 235060,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 123.6,
    "out:Total Energy Use Post PV": 123.6,
    "out:Primary Energy": 122.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.43,
    "out:CO2 Operational/m2": 42.76,
    "out:Total CO2/m2": 67.19,
    "out:Total CO2 (tons)": 216.36,
    "out:Klimatpaverkan": -2.47,
    "out:Initial Cost/MSEK": 5.651,
    "out:Running cost/(Apt SEK y)": 32694,
    "out:Running Cost over RSP/MSEK": 9.202,
    "out:LCP/MSEK": -0.327,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 12,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 225400,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225400,
    "out:DH kr savings": 225400,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 118.6,
    "out:Total Energy Use Post PV": 118.6,
    "out:Primary Energy": 118.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.43,
    "out:CO2 Operational/m2": 40.96,
    "out:Total CO2/m2": 65.39,
    "out:Total CO2 (tons)": 210.56,
    "out:Klimatpaverkan": -8.27,
    "out:Initial Cost/MSEK": 5.732,
    "out:Running cost/(Apt SEK y)": 31343,
    "out:Running Cost over RSP/MSEK": 8.821,
    "out:LCP/MSEK": -0.027,
    "out:ROI% old": 14.22,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 729,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241500,
    "out:DH kr savings": 241500,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 124.6,
    "out:Total Energy Use Post PV": 121.91,
    "out:Primary Energy": 120.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.32,
    "out:CO2 Operational/m2": 18.64,
    "out:Total CO2/m2": 50.96,
    "out:Total CO2 (tons)": 164.09,
    "out:Klimatpaverkan": -54.74,
    "out:Initial Cost/MSEK": 5.961,
    "out:Running cost/(Apt SEK y)": 31844,
    "out:Running Cost over RSP/MSEK": 8.971,
    "out:LCP/MSEK": -0.406,
    "out:ROI% old": 13.31,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 711,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262496,
    "out:DH kr savings": 222180,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 120.6,
    "out:Total Energy Use Post PV": 117.91,
    "out:Primary Energy": 116.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.32,
    "out:CO2 Operational/m2": 17.2,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 159.45,
    "out:Klimatpaverkan": -59.38,
    "out:Initial Cost/MSEK": 6.041,
    "out:Running cost/(Apt SEK y)": 30764,
    "out:Running Cost over RSP/MSEK": 8.667,
    "out:LCP/MSEK": -0.182,
    "out:ROI% old": 13.86,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 64.4,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 750,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275376,
    "out:DH kr savings": 235060,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 123.6,
    "out:Total Energy Use Post PV": 120.91,
    "out:Primary Energy": 117.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.67,
    "out:CO2 Operational/m2": 18.28,
    "out:Total CO2/m2": 50.95,
    "out:Total CO2 (tons)": 164.06,
    "out:Klimatpaverkan": -54.77,
    "out:Initial Cost/MSEK": 6.098,
    "out:Running cost/(Apt SEK y)": 31574,
    "out:Running Cost over RSP/MSEK": 8.895,
    "out:LCP/MSEK": -0.466,
    "out:ROI% old": 13.19,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 225400,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265716,
    "out:DH kr savings": 225400,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 118.6,
    "out:Total Energy Use Post PV": 115.91,
    "out:Primary Energy": 113.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.67,
    "out:CO2 Operational/m2": 16.48,
    "out:Total CO2/m2": 49.15,
    "out:Total CO2 (tons)": 158.26,
    "out:Klimatpaverkan": -60.57,
    "out:Initial Cost/MSEK": 6.178,
    "out:Running cost/(Apt SEK y)": 30223,
    "out:Running Cost over RSP/MSEK": 8.514,
    "out:LCP/MSEK": -0.167,
    "out:ROI% old": 13.9,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 67.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 770,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281816,
    "out:DH kr savings": 241500,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 122.6,
    "out:Total Energy Use Post PV": 122.6,
    "out:Primary Energy": 121.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.08,
    "out:CO2 Operational/m2": 42.4,
    "out:Total CO2/m2": 66.48,
    "out:Total CO2 (tons)": 214.07,
    "out:Klimatpaverkan": -4.76,
    "out:Initial Cost/MSEK": 5.609,
    "out:Running cost/(Apt SEK y)": 32424,
    "out:Running Cost over RSP/MSEK": 9.125,
    "out:LCP/MSEK": -0.209,
    "out:ROI% old": 13.75,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 12,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 228620,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228620,
    "out:DH kr savings": 228620,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 118.6,
    "out:Total Energy Use Post PV": 118.6,
    "out:Primary Energy": 117.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.08,
    "out:CO2 Operational/m2": 40.96,
    "out:Total CO2/m2": 65.04,
    "out:Total CO2 (tons)": 209.44,
    "out:Klimatpaverkan": -9.39,
    "out:Initial Cost/MSEK": 5.69,
    "out:Running cost/(Apt SEK y)": 31343,
    "out:Running Cost over RSP/MSEK": 8.821,
    "out:LCP/MSEK": 0.015,
    "out:ROI% old": 14.32,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 729,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241500,
    "out:DH kr savings": 241500,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 121.6,
    "out:Primary Energy": 119.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.43,
    "out:CO2 Operational/m2": 42.04,
    "out:Total CO2/m2": 66.47,
    "out:Total CO2 (tons)": 214.04,
    "out:Klimatpaverkan": -4.79,
    "out:Initial Cost/MSEK": 5.746,
    "out:Running cost/(Apt SEK y)": 32154,
    "out:Running Cost over RSP/MSEK": 9.049,
    "out:LCP/MSEK": -0.27,
    "out:ROI% old": 13.62,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 12,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231840,
    "out:DH kr savings": 231840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 116.6,
    "out:Total Energy Use Post PV": 116.6,
    "out:Primary Energy": 115.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.43,
    "out:CO2 Operational/m2": 40.24,
    "out:Total CO2/m2": 64.67,
    "out:Total CO2 (tons)": 208.25,
    "out:Klimatpaverkan": -10.58,
    "out:Initial Cost/MSEK": 5.827,
    "out:Running cost/(Apt SEK y)": 30803,
    "out:Running Cost over RSP/MSEK": 8.669,
    "out:LCP/MSEK": 0.03,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 65.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 749,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247940,
    "out:DH kr savings": 247940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01000200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 122.6,
    "out:Total Energy Use Post PV": 119.91,
    "out:Primary Energy": 116.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.32,
    "out:CO2 Operational/m2": 17.92,
    "out:Total CO2/m2": 50.24,
    "out:Total CO2 (tons)": 161.77,
    "out:Klimatpaverkan": -57.06,
    "out:Initial Cost/MSEK": 6.056,
    "out:Running cost/(Apt SEK y)": 31304,
    "out:Running Cost over RSP/MSEK": 8.819,
    "out:LCP/MSEK": -0.349,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 61.7,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 731,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 228620,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268936,
    "out:DH kr savings": 228620,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 118.6,
    "out:Total Energy Use Post PV": 115.91,
    "out:Primary Energy": 112.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.32,
    "out:CO2 Operational/m2": 16.48,
    "out:Total CO2/m2": 48.8,
    "out:Total CO2 (tons)": 157.13,
    "out:Klimatpaverkan": -61.7,
    "out:Initial Cost/MSEK": 6.136,
    "out:Running cost/(Apt SEK y)": 30223,
    "out:Running Cost over RSP/MSEK": 8.514,
    "out:LCP/MSEK": -0.125,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 67.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 770,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281816,
    "out:DH kr savings": 241500,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 118.91,
    "out:Primary Energy": 114.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.67,
    "out:CO2 Operational/m2": 17.56,
    "out:Total CO2/m2": 50.23,
    "out:Total CO2 (tons)": 161.74,
    "out:Klimatpaverkan": -57.09,
    "out:Initial Cost/MSEK": 6.193,
    "out:Running cost/(Apt SEK y)": 31034,
    "out:Running Cost over RSP/MSEK": 8.743,
    "out:LCP/MSEK": -0.409,
    "out:ROI% old": 13.34,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 740,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272156,
    "out:DH kr savings": 231840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 116.6,
    "out:Total Energy Use Post PV": 113.91,
    "out:Primary Energy": 110.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.67,
    "out:CO2 Operational/m2": 15.76,
    "out:Total CO2/m2": 48.43,
    "out:Total CO2 (tons)": 155.94,
    "out:Klimatpaverkan": -62.89,
    "out:Initial Cost/MSEK": 6.273,
    "out:Running cost/(Apt SEK y)": 29683,
    "out:Running Cost over RSP/MSEK": 8.362,
    "out:LCP/MSEK": -0.109,
    "out:ROI% old": 14.04,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 789,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 247940,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288256,
    "out:DH kr savings": 247940,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01000201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 115.84,
    "out:Total Energy Use Post PV": 115.85,
    "out:Primary Energy": 126.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 38.32,
    "out:Total CO2/m2": 65.66,
    "out:Total CO2 (tons)": 211.43,
    "out:Klimatpaverkan": -7.4,
    "out:Initial Cost/MSEK": 6.125,
    "out:Running cost/(Apt SEK y)": 29937,
    "out:Running Cost over RSP/MSEK": 8.421,
    "out:LCP/MSEK": -0.02,
    "out:ROI% old": 14.24,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 67.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 780,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227037,
    "out:DH kr savings": 273700,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 324644,
    "out:% savings (space heating)": 42.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 110.84,
    "out:Total Energy Use Post PV": 110.85,
    "out:Primary Energy": 122.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 36.52,
    "out:Total CO2/m2": 63.86,
    "out:Total CO2 (tons)": 205.63,
    "out:Klimatpaverkan": -13.2,
    "out:Initial Cost/MSEK": 6.206,
    "out:Running cost/(Apt SEK y)": 28587,
    "out:Running Cost over RSP/MSEK": 8.041,
    "out:LCP/MSEK": 0.279,
    "out:ROI% old": 14.93,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 829,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243137,
    "out:DH kr savings": 289800,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309783,
    "out:% savings (space heating)": 45.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 113.83,
    "out:Total Energy Use Post PV": 113.85,
    "out:Primary Energy": 124.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 37.6,
    "out:Total CO2/m2": 65.29,
    "out:Total CO2 (tons)": 210.24,
    "out:Klimatpaverkan": -8.59,
    "out:Initial Cost/MSEK": 6.262,
    "out:Running cost/(Apt SEK y)": 29395,
    "out:Running Cost over RSP/MSEK": 8.268,
    "out:LCP/MSEK": -0.004,
    "out:ROI% old": 14.28,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233477,
    "out:DH kr savings": 280140,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 320136,
    "out:% savings (space heating)": 43.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 109.83,
    "out:Total Energy Use Post PV": 109.85,
    "out:Primary Energy": 120.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 36.16,
    "out:Total CO2/m2": 63.85,
    "out:Total CO2 (tons)": 205.6,
    "out:Klimatpaverkan": -13.23,
    "out:Initial Cost/MSEK": 6.342,
    "out:Running cost/(Apt SEK y)": 28314,
    "out:Running Cost over RSP/MSEK": 7.964,
    "out:LCP/MSEK": 0.22,
    "out:ROI% old": 14.78,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293020,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 246357,
    "out:DH kr savings": 293020,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 305280,
    "out:% savings (space heating)": 46.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 115.84,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 119.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.58,
    "out:CO2 Operational/m2": 22.13,
    "out:Total CO2/m2": 57.7,
    "out:Total CO2 (tons)": 185.81,
    "out:Klimatpaverkan": -33.02,
    "out:Initial Cost/MSEK": 6.572,
    "out:Running cost/(Apt SEK y)": 28744,
    "out:Running Cost over RSP/MSEK": 8.094,
    "out:LCP/MSEK": -0.14,
    "out:ROI% old": 13.98,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 73.2,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 823,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.48,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.14,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270022,
    "out:DH kr savings": 273700,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 324644,
    "out:% savings (space heating)": 42.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 110.84,
    "out:Total Energy Use Post PV": 106.5,
    "out:Primary Energy": 115.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.58,
    "out:CO2 Operational/m2": 20.33,
    "out:Total CO2/m2": 55.9,
    "out:Total CO2 (tons)": 180.01,
    "out:Klimatpaverkan": -38.82,
    "out:Initial Cost/MSEK": 6.652,
    "out:Running cost/(Apt SEK y)": 27393,
    "out:Running Cost over RSP/MSEK": 7.714,
    "out:LCP/MSEK": 0.16,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 872,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.5,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286122,
    "out:DH kr savings": 289800,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309783,
    "out:% savings (space heating)": 45.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 113.83,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 116.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.93,
    "out:CO2 Operational/m2": 21.39,
    "out:Total CO2/m2": 57.32,
    "out:Total CO2 (tons)": 184.58,
    "out:Klimatpaverkan": -34.25,
    "out:Initial Cost/MSEK": 6.708,
    "out:Running cost/(Apt SEK y)": 28201,
    "out:Running Cost over RSP/MSEK": 7.941,
    "out:LCP/MSEK": -0.124,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.97,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.65,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276473,
    "out:DH kr savings": 280140,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 320136,
    "out:% savings (space heating)": 43.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 109.83,
    "out:Total Energy Use Post PV": 105.5,
    "out:Primary Energy": 112.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.93,
    "out:CO2 Operational/m2": 19.95,
    "out:Total CO2/m2": 55.88,
    "out:Total CO2 (tons)": 179.94,
    "out:Klimatpaverkan": -38.89,
    "out:Initial Cost/MSEK": 6.789,
    "out:Running cost/(Apt SEK y)": 27121,
    "out:Running Cost over RSP/MSEK": 7.637,
    "out:LCP/MSEK": 0.1,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 881,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.23,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.39,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293020,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289354,
    "out:DH kr savings": 293020,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 305280,
    "out:% savings (space heating)": 46.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 113.82,
    "out:Total Energy Use Post PV": 113.85,
    "out:Primary Energy": 123.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 37.6,
    "out:Total CO2/m2": 64.94,
    "out:Total CO2 (tons)": 209.1,
    "out:Klimatpaverkan": -9.73,
    "out:Initial Cost/MSEK": 6.22,
    "out:Running cost/(Apt SEK y)": 29394,
    "out:Running Cost over RSP/MSEK": 8.268,
    "out:LCP/MSEK": 0.038,
    "out:ROI% old": 14.37,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233477,
    "out:DH kr savings": 280140,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 318656,
    "out:% savings (space heating)": 43.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 108.82,
    "out:Total Energy Use Post PV": 108.85,
    "out:Primary Energy": 119.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 35.8,
    "out:Total CO2/m2": 63.14,
    "out:Total CO2 (tons)": 203.31,
    "out:Klimatpaverkan": -15.52,
    "out:Initial Cost/MSEK": 6.301,
    "out:Running cost/(Apt SEK y)": 28043,
    "out:Running Cost over RSP/MSEK": 7.888,
    "out:LCP/MSEK": 0.338,
    "out:ROI% old": 15.05,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 848,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296240,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249577,
    "out:DH kr savings": 296240,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303803,
    "out:% savings (space heating)": 46.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 112.8,
    "out:Total Energy Use Post PV": 112.85,
    "out:Primary Energy": 121.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 37.23,
    "out:Total CO2/m2": 64.93,
    "out:Total CO2 (tons)": 209.06,
    "out:Klimatpaverkan": -9.77,
    "out:Initial Cost/MSEK": 6.357,
    "out:Running cost/(Apt SEK y)": 29120,
    "out:Running Cost over RSP/MSEK": 8.191,
    "out:LCP/MSEK": -0.022,
    "out:ROI% old": 14.24,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 71.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1036.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236697,
    "out:DH kr savings": 283360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 315164,
    "out:% savings (space heating)": 44.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 107.8,
    "out:Total Energy Use Post PV": 107.85,
    "out:Primary Energy": 117.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 35.43,
    "out:Total CO2/m2": 63.13,
    "out:Total CO2 (tons)": 203.27,
    "out:Klimatpaverkan": -15.56,
    "out:Initial Cost/MSEK": 6.437,
    "out:Running cost/(Apt SEK y)": 27769,
    "out:Running Cost over RSP/MSEK": 7.811,
    "out:LCP/MSEK": 0.278,
    "out:ROI% old": 14.9,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 79.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1036.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 299460,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 252797,
    "out:DH kr savings": 299460,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 300324,
    "out:% savings (space heating)": 47.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01000201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 113.82,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 115.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.58,
    "out:CO2 Operational/m2": 21.39,
    "out:Total CO2/m2": 56.96,
    "out:Total CO2 (tons)": 183.43,
    "out:Klimatpaverkan": -35.4,
    "out:Initial Cost/MSEK": 6.667,
    "out:Running cost/(Apt SEK y)": 28200,
    "out:Running Cost over RSP/MSEK": 7.941,
    "out:LCP/MSEK": -0.082,
    "out:ROI% old": 14.11,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.73,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.89,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276479,
    "out:DH kr savings": 280140,
    "out:El kr savings": -18698,
    "out:El kr sold": 15037,
    "out:El kr saved": 27938,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 318656,
    "out:% savings (space heating)": 43.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 108.82,
    "out:Total Energy Use Post PV": 104.5,
    "out:Primary Energy": 111.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.58,
    "out:CO2 Operational/m2": 19.58,
    "out:Total CO2/m2": 55.16,
    "out:Total CO2 (tons)": 177.63,
    "out:Klimatpaverkan": -41.2,
    "out:Initial Cost/MSEK": 6.747,
    "out:Running cost/(Apt SEK y)": 26849,
    "out:Running Cost over RSP/MSEK": 7.561,
    "out:LCP/MSEK": 0.218,
    "out:ROI% old": 14.75,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 891,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.17,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.45,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296240,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 292579,
    "out:DH kr savings": 296240,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303803,
    "out:% savings (space heating)": 46.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 112.8,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 113.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.93,
    "out:CO2 Operational/m2": 21,
    "out:Total CO2/m2": 56.93,
    "out:Total CO2 (tons)": 183.32,
    "out:Klimatpaverkan": -35.51,
    "out:Initial Cost/MSEK": 6.803,
    "out:Running cost/(Apt SEK y)": 27927,
    "out:Running Cost over RSP/MSEK": 7.864,
    "out:LCP/MSEK": -0.141,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 740.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10037.32,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13956.3,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279718,
    "out:DH kr savings": 283360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15056,
    "out:El kr saved": 27913,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 315164,
    "out:% savings (space heating)": 44.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01000201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 107.8,
    "out:Total Energy Use Post PV": 103.5,
    "out:Primary Energy": 109.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.93,
    "out:CO2 Operational/m2": 19.2,
    "out:Total CO2/m2": 55.13,
    "out:Total CO2 (tons)": 177.52,
    "out:Klimatpaverkan": -41.31,
    "out:Initial Cost/MSEK": 6.884,
    "out:Running cost/(Apt SEK y)": 26576,
    "out:Running Cost over RSP/MSEK": 7.484,
    "out:LCP/MSEK": 0.159,
    "out:ROI% old": 14.61,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 740,
    "out:Return %": 13,
    "out:Return/kSEK/y": 901,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10038.54,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13955.08,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 299460,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 295819,
    "out:DH kr savings": 299460,
    "out:El kr savings": -18698,
    "out:El kr sold": 15058,
    "out:El kr saved": 27910,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 300324,
    "out:% savings (space heating)": 47.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.28,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 111.6,
    "out:Total Energy Use Post PV": 111.6,
    "out:Primary Energy": 113.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.45,
    "out:CO2 Operational/m2": 38.44,
    "out:Total CO2/m2": 53.89,
    "out:Total CO2 (tons)": 173.53,
    "out:Klimatpaverkan": -45.3,
    "out:Initial Cost/MSEK": 3.566,
    "out:Running cost/(Apt SEK y)": 29452,
    "out:Running Cost over RSP/MSEK": 8.289,
    "out:LCP/MSEK": 2.671,
    "out:ROI% old": 24.99,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 73.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 797,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 264040,
    "out:DH kr savings": 264040,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303570,
    "out:% savings (space heating)": 46.55,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.26,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 107.6,
    "out:Total Energy Use Post PV": 107.6,
    "out:Primary Energy": 110.16,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.45,
    "out:CO2 Operational/m2": 37,
    "out:Total CO2/m2": 52.45,
    "out:Total CO2 (tons)": 168.89,
    "out:Klimatpaverkan": -49.94,
    "out:Initial Cost/MSEK": 3.646,
    "out:Running cost/(Apt SEK y)": 28372,
    "out:Running Cost over RSP/MSEK": 7.985,
    "out:LCP/MSEK": 2.895,
    "out:ROI% old": 25.63,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 79.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 836,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 276920,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276920,
    "out:DH kr savings": 276920,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 290650,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.28,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 109.6,
    "out:Total Energy Use Post PV": 109.6,
    "out:Primary Energy": 111.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.8,
    "out:CO2 Operational/m2": 37.72,
    "out:Total CO2/m2": 53.52,
    "out:Total CO2 (tons)": 172.34,
    "out:Klimatpaverkan": -46.49,
    "out:Initial Cost/MSEK": 3.702,
    "out:Running cost/(Apt SEK y)": 28912,
    "out:Running Cost over RSP/MSEK": 8.137,
    "out:LCP/MSEK": 2.687,
    "out:ROI% old": 24.65,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270480,
    "out:DH kr savings": 270480,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303570,
    "out:% savings (space heating)": 46.55,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.26,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 105.6,
    "out:Total Energy Use Post PV": 105.6,
    "out:Primary Energy": 107.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.8,
    "out:CO2 Operational/m2": 36.28,
    "out:Total CO2/m2": 52.08,
    "out:Total CO2 (tons)": 167.7,
    "out:Klimatpaverkan": -51.13,
    "out:Initial Cost/MSEK": 3.783,
    "out:Running cost/(Apt SEK y)": 27832,
    "out:Running Cost over RSP/MSEK": 7.832,
    "out:LCP/MSEK": 2.911,
    "out:ROI% old": 25.28,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283360,
    "out:DH kr savings": 283360,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 290650,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.28,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 111.6,
    "out:Total Energy Use Post PV": 108.91,
    "out:Primary Energy": 108.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.68,
    "out:CO2 Operational/m2": 13.96,
    "out:Total CO2/m2": 37.65,
    "out:Total CO2 (tons)": 121.23,
    "out:Klimatpaverkan": -97.6,
    "out:Initial Cost/MSEK": 4.012,
    "out:Running cost/(Apt SEK y)": 28332,
    "out:Running Cost over RSP/MSEK": 7.982,
    "out:LCP/MSEK": 2.532,
    "out:ROI% old": 23.3,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304356,
    "out:DH kr savings": 264040,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303570,
    "out:% savings (space heating)": 46.55,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.26,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 107.6,
    "out:Total Energy Use Post PV": 104.91,
    "out:Primary Energy": 105.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.68,
    "out:CO2 Operational/m2": 12.52,
    "out:Total CO2/m2": 36.21,
    "out:Total CO2 (tons)": 116.59,
    "out:Klimatpaverkan": -102.24,
    "out:Initial Cost/MSEK": 4.093,
    "out:Running cost/(Apt SEK y)": 27252,
    "out:Running Cost over RSP/MSEK": 7.678,
    "out:LCP/MSEK": 2.755,
    "out:ROI% old": 23.9,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 276920,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317236,
    "out:DH kr savings": 276920,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 290650,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.28,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 109.6,
    "out:Total Energy Use Post PV": 106.91,
    "out:Primary Energy": 106.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.04,
    "out:CO2 Operational/m2": 13.24,
    "out:Total CO2/m2": 37.28,
    "out:Total CO2 (tons)": 120.03,
    "out:Klimatpaverkan": -98.8,
    "out:Initial Cost/MSEK": 4.149,
    "out:Running cost/(Apt SEK y)": 27792,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": 2.547,
    "out:ROI% old": 23.06,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 857,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310796,
    "out:DH kr savings": 270480,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303570,
    "out:% savings (space heating)": 46.55,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.26,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 105.6,
    "out:Total Energy Use Post PV": 102.91,
    "out:Primary Energy": 102.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.04,
    "out:CO2 Operational/m2": 11.8,
    "out:Total CO2/m2": 35.84,
    "out:Total CO2 (tons)": 115.4,
    "out:Klimatpaverkan": -103.43,
    "out:Initial Cost/MSEK": 4.229,
    "out:Running cost/(Apt SEK y)": 26712,
    "out:Running Cost over RSP/MSEK": 7.526,
    "out:LCP/MSEK": 2.771,
    "out:ROI% old": 23.65,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 88.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 896,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323676,
    "out:DH kr savings": 283360,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 290650,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.28,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 109.6,
    "out:Total Energy Use Post PV": 109.6,
    "out:Primary Energy": 110.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.45,
    "out:CO2 Operational/m2": 37.72,
    "out:Total CO2/m2": 53.17,
    "out:Total CO2 (tons)": 171.21,
    "out:Klimatpaverkan": -47.62,
    "out:Initial Cost/MSEK": 3.661,
    "out:Running cost/(Apt SEK y)": 28912,
    "out:Running Cost over RSP/MSEK": 8.137,
    "out:LCP/MSEK": 2.729,
    "out:ROI% old": 24.93,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270480,
    "out:DH kr savings": 270480,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303570,
    "out:% savings (space heating)": 46.55,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.26,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 105.6,
    "out:Total Energy Use Post PV": 105.6,
    "out:Primary Energy": 106.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.45,
    "out:CO2 Operational/m2": 36.28,
    "out:Total CO2/m2": 51.73,
    "out:Total CO2 (tons)": 166.57,
    "out:Klimatpaverkan": -52.26,
    "out:Initial Cost/MSEK": 3.741,
    "out:Running cost/(Apt SEK y)": 27832,
    "out:Running Cost over RSP/MSEK": 7.832,
    "out:LCP/MSEK": 2.952,
    "out:ROI% old": 25.56,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283360,
    "out:DH kr savings": 283360,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 290650,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.28,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 108.6,
    "out:Total Energy Use Post PV": 108.6,
    "out:Primary Energy": 108.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.8,
    "out:CO2 Operational/m2": 37.36,
    "out:Total CO2/m2": 53.16,
    "out:Total CO2 (tons)": 171.18,
    "out:Klimatpaverkan": -47.65,
    "out:Initial Cost/MSEK": 3.797,
    "out:Running cost/(Apt SEK y)": 28642,
    "out:Running Cost over RSP/MSEK": 8.061,
    "out:LCP/MSEK": 2.668,
    "out:ROI% old": 24.32,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273700,
    "out:DH kr savings": 273700,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303570,
    "out:% savings (space heating)": 46.55,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.26,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 104.6,
    "out:Total Energy Use Post PV": 104.6,
    "out:Primary Energy": 104.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.8,
    "out:CO2 Operational/m2": 35.92,
    "out:Total CO2/m2": 51.72,
    "out:Total CO2 (tons)": 166.54,
    "out:Klimatpaverkan": -52.29,
    "out:Initial Cost/MSEK": 3.878,
    "out:Running cost/(Apt SEK y)": 27561,
    "out:Running Cost over RSP/MSEK": 7.756,
    "out:LCP/MSEK": 2.892,
    "out:ROI% old": 24.94,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 865,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 286580,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286580,
    "out:DH kr savings": 286580,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 290650,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.28,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 109.6,
    "out:Total Energy Use Post PV": 106.91,
    "out:Primary Energy": 105.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.68,
    "out:CO2 Operational/m2": 13.24,
    "out:Total CO2/m2": 36.93,
    "out:Total CO2 (tons)": 118.91,
    "out:Klimatpaverkan": -99.92,
    "out:Initial Cost/MSEK": 4.107,
    "out:Running cost/(Apt SEK y)": 27792,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": 2.589,
    "out:ROI% old": 23.29,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 857,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310796,
    "out:DH kr savings": 270480,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303570,
    "out:% savings (space heating)": 46.55,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.26,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 105.6,
    "out:Total Energy Use Post PV": 102.91,
    "out:Primary Energy": 101.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.68,
    "out:CO2 Operational/m2": 11.8,
    "out:Total CO2/m2": 35.49,
    "out:Total CO2 (tons)": 114.27,
    "out:Klimatpaverkan": -104.56,
    "out:Initial Cost/MSEK": 4.188,
    "out:Running cost/(Apt SEK y)": 26712,
    "out:Running Cost over RSP/MSEK": 7.526,
    "out:LCP/MSEK": 2.813,
    "out:ROI% old": 23.88,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 88.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 896,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323676,
    "out:DH kr savings": 283360,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 290650,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.28,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 108.6,
    "out:Total Energy Use Post PV": 105.91,
    "out:Primary Energy": 103.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.04,
    "out:CO2 Operational/m2": 12.88,
    "out:Total CO2/m2": 36.92,
    "out:Total CO2 (tons)": 118.88,
    "out:Klimatpaverkan": -99.95,
    "out:Initial Cost/MSEK": 4.244,
    "out:Running cost/(Apt SEK y)": 27522,
    "out:Running Cost over RSP/MSEK": 7.754,
    "out:LCP/MSEK": 2.528,
    "out:ROI% old": 22.8,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 867,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314016,
    "out:DH kr savings": 273700,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303570,
    "out:% savings (space heating)": 46.55,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 90.26,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 104.6,
    "out:Total Energy Use Post PV": 101.91,
    "out:Primary Energy": 99.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.04,
    "out:CO2 Operational/m2": 11.44,
    "out:Total CO2/m2": 35.48,
    "out:Total CO2 (tons)": 114.24,
    "out:Klimatpaverkan": -104.59,
    "out:Initial Cost/MSEK": 4.324,
    "out:Running cost/(Apt SEK y)": 26442,
    "out:Running Cost over RSP/MSEK": 7.45,
    "out:LCP/MSEK": 2.752,
    "out:ROI% old": 23.38,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 90.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 286580,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 326896,
    "out:DH kr savings": 286580,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 290650,
    "out:% savings (space heating)": 48.83,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 102.84,
    "out:Total Energy Use Post PV": 102.85,
    "out:Primary Energy": 115.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.71,
    "out:CO2 Operational/m2": 33.64,
    "out:Total CO2/m2": 52.35,
    "out:Total CO2 (tons)": 168.57,
    "out:Klimatpaverkan": -50.26,
    "out:Initial Cost/MSEK": 4.176,
    "out:Running cost/(Apt SEK y)": 26426,
    "out:Running Cost over RSP/MSEK": 7.432,
    "out:LCP/MSEK": 2.917,
    "out:ROI% old": 24.26,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 88.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 22,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268897,
    "out:DH kr savings": 315560,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 281960,
    "out:% savings (space heating)": 50.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 98.84,
    "out:Total Energy Use Post PV": 98.85,
    "out:Primary Energy": 111.78,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.71,
    "out:CO2 Operational/m2": 32.2,
    "out:Total CO2/m2": 50.91,
    "out:Total CO2 (tons)": 163.93,
    "out:Klimatpaverkan": -54.9,
    "out:Initial Cost/MSEK": 4.257,
    "out:Running cost/(Apt SEK y)": 25345,
    "out:Running Cost over RSP/MSEK": 7.128,
    "out:LCP/MSEK": 3.141,
    "out:ROI% old": 24.83,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 96,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 22,
    "out:Return/kSEK/y": 945,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281777,
    "out:DH kr savings": 328440,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 269040,
    "out:% savings (space heating)": 52.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 100.83,
    "out:Total Energy Use Post PV": 100.85,
    "out:Primary Energy": 112.66,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.06,
    "out:CO2 Operational/m2": 32.92,
    "out:Total CO2/m2": 51.98,
    "out:Total CO2 (tons)": 167.37,
    "out:Klimatpaverkan": -51.46,
    "out:Initial Cost/MSEK": 4.313,
    "out:Running cost/(Apt SEK y)": 25884,
    "out:Running Cost over RSP/MSEK": 7.28,
    "out:LCP/MSEK": 2.933,
    "out:ROI% old": 24,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 92.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 926,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 275337,
    "out:DH kr savings": 322000,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 277444,
    "out:% savings (space heating)": 51.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 96.83,
    "out:Total Energy Use Post PV": 96.85,
    "out:Primary Energy": 109.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.06,
    "out:CO2 Operational/m2": 31.48,
    "out:Total CO2/m2": 50.54,
    "out:Total CO2 (tons)": 162.74,
    "out:Klimatpaverkan": -56.09,
    "out:Initial Cost/MSEK": 4.393,
    "out:Running cost/(Apt SEK y)": 24803,
    "out:Running Cost over RSP/MSEK": 6.975,
    "out:LCP/MSEK": 3.157,
    "out:ROI% old": 24.55,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 965,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288217,
    "out:DH kr savings": 334880,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 264528,
    "out:% savings (space heating)": 53.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 102.84,
    "out:Total Energy Use Post PV": 98.5,
    "out:Primary Energy": 107.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.95,
    "out:CO2 Operational/m2": 17.45,
    "out:Total CO2/m2": 44.39,
    "out:Total CO2 (tons)": 142.95,
    "out:Klimatpaverkan": -75.88,
    "out:Initial Cost/MSEK": 4.623,
    "out:Running cost/(Apt SEK y)": 25232,
    "out:Running Cost over RSP/MSEK": 7.105,
    "out:LCP/MSEK": 2.798,
    "out:ROI% old": 22.93,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 96,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.37,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.25,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 311882,
    "out:DH kr savings": 315560,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 281960,
    "out:% savings (space heating)": 50.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 98.84,
    "out:Total Energy Use Post PV": 94.5,
    "out:Primary Energy": 103.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.95,
    "out:CO2 Operational/m2": 16.01,
    "out:Total CO2/m2": 42.95,
    "out:Total CO2 (tons)": 138.31,
    "out:Klimatpaverkan": -80.52,
    "out:Initial Cost/MSEK": 4.703,
    "out:Running cost/(Apt SEK y)": 24152,
    "out:Running Cost over RSP/MSEK": 6.801,
    "out:LCP/MSEK": 3.022,
    "out:ROI% old": 23.46,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 988,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.39,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.23,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 324762,
    "out:DH kr savings": 328440,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 269040,
    "out:% savings (space heating)": 52.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.16,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 100.83,
    "out:Total Energy Use Post PV": 96.5,
    "out:Primary Energy": 104.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.3,
    "out:CO2 Operational/m2": 16.71,
    "out:Total CO2/m2": 44.01,
    "out:Total CO2 (tons)": 141.72,
    "out:Klimatpaverkan": -77.11,
    "out:Initial Cost/MSEK": 4.759,
    "out:Running cost/(Apt SEK y)": 24690,
    "out:Running Cost over RSP/MSEK": 6.953,
    "out:LCP/MSEK": 2.814,
    "out:ROI% old": 22.73,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 969,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.36,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.26,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318332,
    "out:DH kr savings": 322000,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 277444,
    "out:% savings (space heating)": 51.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 96.83,
    "out:Total Energy Use Post PV": 92.5,
    "out:Primary Energy": 101.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.3,
    "out:CO2 Operational/m2": 15.27,
    "out:Total CO2/m2": 42.57,
    "out:Total CO2 (tons)": 137.08,
    "out:Klimatpaverkan": -81.75,
    "out:Initial Cost/MSEK": 4.84,
    "out:Running cost/(Apt SEK y)": 23609,
    "out:Running Cost over RSP/MSEK": 6.648,
    "out:LCP/MSEK": 3.038,
    "out:ROI% old": 23.25,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.59,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.03,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331213,
    "out:DH kr savings": 334880,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 264528,
    "out:% savings (space heating)": 53.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 100.83,
    "out:Total Energy Use Post PV": 100.85,
    "out:Primary Energy": 111.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.71,
    "out:CO2 Operational/m2": 32.92,
    "out:Total CO2/m2": 51.63,
    "out:Total CO2 (tons)": 166.24,
    "out:Klimatpaverkan": -52.59,
    "out:Initial Cost/MSEK": 4.271,
    "out:Running cost/(Apt SEK y)": 25883,
    "out:Running Cost over RSP/MSEK": 7.279,
    "out:LCP/MSEK": 2.975,
    "out:ROI% old": 24.24,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 92.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 22,
    "out:Return/kSEK/y": 926,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 275337,
    "out:DH kr savings": 322000,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 275958,
    "out:% savings (space heating)": 51.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 96.82,
    "out:Total Energy Use Post PV": 96.85,
    "out:Primary Energy": 108.28,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.71,
    "out:CO2 Operational/m2": 31.48,
    "out:Total CO2/m2": 50.19,
    "out:Total CO2 (tons)": 161.6,
    "out:Klimatpaverkan": -57.23,
    "out:Initial Cost/MSEK": 4.352,
    "out:Running cost/(Apt SEK y)": 24802,
    "out:Running Cost over RSP/MSEK": 6.975,
    "out:LCP/MSEK": 3.199,
    "out:ROI% old": 24.79,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 22,
    "out:Return/kSEK/y": 965,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288217,
    "out:DH kr savings": 334880,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 263045,
    "out:% savings (space heating)": 53.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 99.81,
    "out:Total Energy Use Post PV": 99.85,
    "out:Primary Energy": 109.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.06,
    "out:CO2 Operational/m2": 32.55,
    "out:Total CO2/m2": 51.62,
    "out:Total CO2 (tons)": 166.2,
    "out:Klimatpaverkan": -52.63,
    "out:Initial Cost/MSEK": 4.408,
    "out:Running cost/(Apt SEK y)": 25609,
    "out:Running Cost over RSP/MSEK": 7.202,
    "out:LCP/MSEK": 2.916,
    "out:ROI% old": 23.73,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 936,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278557,
    "out:DH kr savings": 325220,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 272449,
    "out:% savings (space heating)": 52.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 95.8,
    "out:Total Energy Use Post PV": 95.85,
    "out:Primary Energy": 106.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.06,
    "out:CO2 Operational/m2": 31.11,
    "out:Total CO2/m2": 50.18,
    "out:Total CO2 (tons)": 161.57,
    "out:Klimatpaverkan": -57.26,
    "out:Initial Cost/MSEK": 4.488,
    "out:Running cost/(Apt SEK y)": 24528,
    "out:Running Cost over RSP/MSEK": 6.898,
    "out:LCP/MSEK": 3.139,
    "out:ROI% old": 24.28,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1036.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 975,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291437,
    "out:DH kr savings": 338100,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 259548,
    "out:% savings (space heating)": 54.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 100.83,
    "out:Total Energy Use Post PV": 96.5,
    "out:Primary Energy": 103.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.95,
    "out:CO2 Operational/m2": 16.71,
    "out:Total CO2/m2": 43.65,
    "out:Total CO2 (tons)": 140.57,
    "out:Klimatpaverkan": -78.26,
    "out:Initial Cost/MSEK": 4.718,
    "out:Running cost/(Apt SEK y)": 24689,
    "out:Running Cost over RSP/MSEK": 6.952,
    "out:LCP/MSEK": 2.856,
    "out:ROI% old": 22.93,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 969,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.81,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.81,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318337,
    "out:DH kr savings": 322000,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 275958,
    "out:% savings (space heating)": 51.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 96.82,
    "out:Total Energy Use Post PV": 92.5,
    "out:Primary Energy": 100.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.95,
    "out:CO2 Operational/m2": 15.27,
    "out:Total CO2/m2": 42.21,
    "out:Total CO2 (tons)": 135.93,
    "out:Klimatpaverkan": -82.9,
    "out:Initial Cost/MSEK": 4.798,
    "out:Running cost/(Apt SEK y)": 23608,
    "out:Running Cost over RSP/MSEK": 6.648,
    "out:LCP/MSEK": 3.079,
    "out:ROI% old": 23.45,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.22,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.4,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331218,
    "out:DH kr savings": 334880,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 263045,
    "out:% savings (space heating)": 53.69,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 99.81,
    "out:Total Energy Use Post PV": 95.5,
    "out:Primary Energy": 101.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.3,
    "out:CO2 Operational/m2": 16.33,
    "out:Total CO2/m2": 43.62,
    "out:Total CO2 (tons)": 140.47,
    "out:Klimatpaverkan": -78.36,
    "out:Initial Cost/MSEK": 4.854,
    "out:Running cost/(Apt SEK y)": 24416,
    "out:Running Cost over RSP/MSEK": 6.875,
    "out:LCP/MSEK": 2.796,
    "out:ROI% old": 22.51,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.4,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13958.22,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 321575,
    "out:DH kr savings": 325220,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27916,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 272449,
    "out:% savings (space heating)": 52.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 94.28,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 95.8,
    "out:Total Energy Use Post PV": 91.5,
    "out:Primary Energy": 98.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.3,
    "out:CO2 Operational/m2": 14.88,
    "out:Total CO2/m2": 42.18,
    "out:Total CO2 (tons)": 135.83,
    "out:Klimatpaverkan": -83,
    "out:Initial Cost/MSEK": 4.935,
    "out:Running cost/(Apt SEK y)": 23335,
    "out:Running Cost over RSP/MSEK": 6.571,
    "out:LCP/MSEK": 3.02,
    "out:ROI% old": 23.03,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 740.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1018,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10036.53,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.08,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334457,
    "out:DH kr savings": 338100,
    "out:El kr savings": -18698,
    "out:El kr sold": 15055,
    "out:El kr saved": 27914,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 259548,
    "out:% savings (space heating)": 54.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 90.26,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.16,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 96.6,
    "out:Total Energy Use Post PV": 96.6,
    "out:Primary Energy": 100.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.97,
    "out:CO2 Operational/m2": 33.04,
    "out:Total CO2/m2": 51.01,
    "out:Total CO2 (tons)": 164.25,
    "out:Klimatpaverkan": -54.58,
    "out:Initial Cost/MSEK": 4.173,
    "out:Running cost/(Apt SEK y)": 25400,
    "out:Running Cost over RSP/MSEK": 7.148,
    "out:LCP/MSEK": 3.205,
    "out:ROI% old": 25.26,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 943,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 312340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 312340,
    "out:DH kr savings": 312340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254908,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.84,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 92.6,
    "out:Primary Energy": 97.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.97,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 49.57,
    "out:Total CO2 (tons)": 159.61,
    "out:Klimatpaverkan": -59.22,
    "out:Initial Cost/MSEK": 4.253,
    "out:Running cost/(Apt SEK y)": 24320,
    "out:Running Cost over RSP/MSEK": 6.844,
    "out:LCP/MSEK": 3.429,
    "out:ROI% old": 25.8,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325220,
    "out:DH kr savings": 325220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 244200,
    "out:% savings (space heating)": 57,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.16,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 94.6,
    "out:Primary Energy": 97.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.32,
    "out:CO2 Operational/m2": 32.32,
    "out:Total CO2/m2": 50.64,
    "out:Total CO2 (tons)": 163.06,
    "out:Klimatpaverkan": -55.77,
    "out:Initial Cost/MSEK": 4.31,
    "out:Running cost/(Apt SEK y)": 24860,
    "out:Running Cost over RSP/MSEK": 6.996,
    "out:LCP/MSEK": 3.221,
    "out:ROI% old": 24.96,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 963,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318780,
    "out:DH kr savings": 318780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254908,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.84,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 91.6,
    "out:Primary Energy": 94.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.32,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 49.56,
    "out:Total CO2 (tons)": 159.58,
    "out:Klimatpaverkan": -59.25,
    "out:Initial Cost/MSEK": 4.39,
    "out:Running cost/(Apt SEK y)": 24050,
    "out:Running Cost over RSP/MSEK": 6.768,
    "out:LCP/MSEK": 3.368,
    "out:ROI% old": 25.25,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 992,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328440,
    "out:DH kr savings": 328440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 244200,
    "out:% savings (space heating)": 57,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.16,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 96.6,
    "out:Total Energy Use Post PV": 93.91,
    "out:Primary Energy": 95.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.2,
    "out:CO2 Operational/m2": 8.56,
    "out:Total CO2/m2": 34.77,
    "out:Total CO2 (tons)": 111.95,
    "out:Klimatpaverkan": -106.88,
    "out:Initial Cost/MSEK": 4.619,
    "out:Running cost/(Apt SEK y)": 24281,
    "out:Running Cost over RSP/MSEK": 6.841,
    "out:LCP/MSEK": 3.065,
    "out:ROI% old": 23.77,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 984,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 312340,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 352656,
    "out:DH kr savings": 312340,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254908,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.84,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 89.91,
    "out:Primary Energy": 92.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.2,
    "out:CO2 Operational/m2": 7.12,
    "out:Total CO2/m2": 33.33,
    "out:Total CO2 (tons)": 107.31,
    "out:Klimatpaverkan": -111.52,
    "out:Initial Cost/MSEK": 4.7,
    "out:Running cost/(Apt SEK y)": 23200,
    "out:Running Cost over RSP/MSEK": 6.537,
    "out:LCP/MSEK": 3.289,
    "out:ROI% old": 24.28,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1022,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 365536,
    "out:DH kr savings": 325220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 244200,
    "out:% savings (space heating)": 57,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.16,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 91.91,
    "out:Primary Energy": 92.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.55,
    "out:CO2 Operational/m2": 7.84,
    "out:Total CO2/m2": 34.4,
    "out:Total CO2 (tons)": 110.76,
    "out:Klimatpaverkan": -108.07,
    "out:Initial Cost/MSEK": 4.756,
    "out:Running cost/(Apt SEK y)": 23740,
    "out:Running Cost over RSP/MSEK": 6.689,
    "out:LCP/MSEK": 3.081,
    "out:ROI% old": 23.54,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 359096,
    "out:DH kr savings": 318780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254908,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.84,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 88.91,
    "out:Primary Energy": 90.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.55,
    "out:CO2 Operational/m2": 6.76,
    "out:Total CO2/m2": 33.32,
    "out:Total CO2 (tons)": 107.28,
    "out:Klimatpaverkan": -111.55,
    "out:Initial Cost/MSEK": 4.837,
    "out:Running cost/(Apt SEK y)": 22930,
    "out:Running Cost over RSP/MSEK": 6.461,
    "out:LCP/MSEK": 3.229,
    "out:ROI% old": 23.82,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1032,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368756,
    "out:DH kr savings": 328440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 244200,
    "out:% savings (space heating)": 57,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.16,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 94.6,
    "out:Primary Energy": 96.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.97,
    "out:CO2 Operational/m2": 32.32,
    "out:Total CO2/m2": 50.29,
    "out:Total CO2 (tons)": 161.93,
    "out:Klimatpaverkan": -56.9,
    "out:Initial Cost/MSEK": 4.268,
    "out:Running cost/(Apt SEK y)": 24860,
    "out:Running Cost over RSP/MSEK": 6.996,
    "out:LCP/MSEK": 3.262,
    "out:ROI% old": 25.21,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 963,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318780,
    "out:DH kr savings": 318780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254908,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.84,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 91.6,
    "out:Primary Energy": 94.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.97,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 49.21,
    "out:Total CO2 (tons)": 158.45,
    "out:Klimatpaverkan": -60.38,
    "out:Initial Cost/MSEK": 4.348,
    "out:Running cost/(Apt SEK y)": 24050,
    "out:Running Cost over RSP/MSEK": 6.768,
    "out:LCP/MSEK": 3.41,
    "out:ROI% old": 25.49,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 992,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328440,
    "out:DH kr savings": 328440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 244200,
    "out:% savings (space heating)": 57,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.16,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 93.6,
    "out:Primary Energy": 94.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.32,
    "out:CO2 Operational/m2": 31.96,
    "out:Total CO2/m2": 50.28,
    "out:Total CO2 (tons)": 161.9,
    "out:Klimatpaverkan": -56.93,
    "out:Initial Cost/MSEK": 4.405,
    "out:Running cost/(Apt SEK y)": 24590,
    "out:Running Cost over RSP/MSEK": 6.92,
    "out:LCP/MSEK": 3.202,
    "out:ROI% old": 24.67,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 972,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322000,
    "out:DH kr savings": 322000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254908,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.84,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 89.6,
    "out:Primary Energy": 91.94,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.32,
    "out:CO2 Operational/m2": 30.52,
    "out:Total CO2/m2": 48.84,
    "out:Total CO2 (tons)": 157.26,
    "out:Klimatpaverkan": -61.57,
    "out:Initial Cost/MSEK": 4.485,
    "out:Running cost/(Apt SEK y)": 23510,
    "out:Running Cost over RSP/MSEK": 6.615,
    "out:LCP/MSEK": 3.425,
    "out:ROI% old": 25.2,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334880,
    "out:DH kr savings": 334880,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 244200,
    "out:% savings (space heating)": 57,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.16,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 91.91,
    "out:Primary Energy": 92.11,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.2,
    "out:CO2 Operational/m2": 7.84,
    "out:Total CO2/m2": 34.05,
    "out:Total CO2 (tons)": 109.63,
    "out:Klimatpaverkan": -109.2,
    "out:Initial Cost/MSEK": 4.714,
    "out:Running cost/(Apt SEK y)": 23740,
    "out:Running Cost over RSP/MSEK": 6.689,
    "out:LCP/MSEK": 3.123,
    "out:ROI% old": 23.75,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 359096,
    "out:DH kr savings": 318780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254908,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.84,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 88.91,
    "out:Primary Energy": 89.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.2,
    "out:CO2 Operational/m2": 6.76,
    "out:Total CO2/m2": 32.97,
    "out:Total CO2 (tons)": 106.15,
    "out:Klimatpaverkan": -112.68,
    "out:Initial Cost/MSEK": 4.795,
    "out:Running cost/(Apt SEK y)": 22930,
    "out:Running Cost over RSP/MSEK": 6.461,
    "out:LCP/MSEK": 3.27,
    "out:ROI% old": 24.03,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1032,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368756,
    "out:DH kr savings": 328440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 244200,
    "out:% savings (space heating)": 57,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.16,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 90.91,
    "out:Primary Energy": 90.01,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.55,
    "out:CO2 Operational/m2": 7.48,
    "out:Total CO2/m2": 34.04,
    "out:Total CO2 (tons)": 109.6,
    "out:Klimatpaverkan": -109.23,
    "out:Initial Cost/MSEK": 4.851,
    "out:Running cost/(Apt SEK y)": 23470,
    "out:Running Cost over RSP/MSEK": 6.613,
    "out:LCP/MSEK": 3.062,
    "out:ROI% old": 23.3,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1013,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 362316,
    "out:DH kr savings": 322000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254908,
    "out:% savings (space heating)": 55.12,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.84,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 86.91,
    "out:Primary Energy": 87.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.55,
    "out:CO2 Operational/m2": 6.04,
    "out:Total CO2/m2": 32.6,
    "out:Total CO2 (tons)": 104.96,
    "out:Klimatpaverkan": -113.87,
    "out:Initial Cost/MSEK": 4.932,
    "out:Running cost/(Apt SEK y)": 22390,
    "out:Running Cost over RSP/MSEK": 6.309,
    "out:LCP/MSEK": 3.286,
    "out:ROI% old": 23.8,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 375196,
    "out:DH kr savings": 334880,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 244200,
    "out:% savings (space heating)": 57,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 86.84,
    "out:Total Energy Use Post PV": 86.85,
    "out:Primary Energy": 102.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 21.23,
    "out:CO2 Operational/m2": 27.88,
    "out:Total CO2/m2": 49.11,
    "out:Total CO2 (tons)": 158.13,
    "out:Klimatpaverkan": -60.7,
    "out:Initial Cost/MSEK": 4.784,
    "out:Running cost/(Apt SEK y)": 22104,
    "out:Running Cost over RSP/MSEK": 6.215,
    "out:LCP/MSEK": 3.527,
    "out:ROI% old": 24.82,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1062,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 367080,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320417,
    "out:DH kr savings": 367080,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233302,
    "out:% savings (space heating)": 58.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 83.84,
    "out:Total Energy Use Post PV": 83.85,
    "out:Primary Energy": 99.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.23,
    "out:CO2 Operational/m2": 26.8,
    "out:Total CO2/m2": 48.03,
    "out:Total CO2 (tons)": 154.65,
    "out:Klimatpaverkan": -64.18,
    "out:Initial Cost/MSEK": 4.864,
    "out:Running cost/(Apt SEK y)": 21294,
    "out:Running Cost over RSP/MSEK": 5.987,
    "out:LCP/MSEK": 3.675,
    "out:ROI% old": 25.08,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330077,
    "out:DH kr savings": 376740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 222593,
    "out:% savings (space heating)": 60.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 85.83,
    "out:Total Energy Use Post PV": 85.85,
    "out:Primary Energy": 99.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.58,
    "out:CO2 Operational/m2": 27.52,
    "out:Total CO2/m2": 49.1,
    "out:Total CO2 (tons)": 158.09,
    "out:Klimatpaverkan": -60.74,
    "out:Initial Cost/MSEK": 4.92,
    "out:Running cost/(Apt SEK y)": 21832,
    "out:Running Cost over RSP/MSEK": 6.139,
    "out:LCP/MSEK": 3.467,
    "out:ROI% old": 24.35,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1072,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323637,
    "out:DH kr savings": 370300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 228799,
    "out:% savings (space heating)": 59.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 82.83,
    "out:Total Energy Use Post PV": 82.85,
    "out:Primary Energy": 96.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.58,
    "out:CO2 Operational/m2": 26.44,
    "out:Total CO2/m2": 48.02,
    "out:Total CO2 (tons)": 154.62,
    "out:Klimatpaverkan": -64.21,
    "out:Initial Cost/MSEK": 5.001,
    "out:Running cost/(Apt SEK y)": 21021,
    "out:Running Cost over RSP/MSEK": 5.91,
    "out:LCP/MSEK": 3.615,
    "out:ROI% old": 24.61,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1038,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 333297,
    "out:DH kr savings": 379960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 218095,
    "out:% savings (space heating)": 61.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 86.84,
    "out:Total Energy Use Post PV": 82.5,
    "out:Primary Energy": 94.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.47,
    "out:CO2 Operational/m2": 11.69,
    "out:Total CO2/m2": 41.15,
    "out:Total CO2 (tons)": 132.51,
    "out:Klimatpaverkan": -86.32,
    "out:Initial Cost/MSEK": 5.23,
    "out:Running cost/(Apt SEK y)": 20910,
    "out:Running Cost over RSP/MSEK": 5.888,
    "out:LCP/MSEK": 3.408,
    "out:ROI% old": 23.59,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1105,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.57,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.05,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 367080,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 363402,
    "out:DH kr savings": 367080,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233302,
    "out:% savings (space heating)": 58.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 83.84,
    "out:Total Energy Use Post PV": 79.5,
    "out:Primary Energy": 91.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.47,
    "out:CO2 Operational/m2": 10.61,
    "out:Total CO2/m2": 40.07,
    "out:Total CO2 (tons)": 129.03,
    "out:Klimatpaverkan": -89.8,
    "out:Initial Cost/MSEK": 5.311,
    "out:Running cost/(Apt SEK y)": 20100,
    "out:Running Cost over RSP/MSEK": 5.66,
    "out:LCP/MSEK": 3.555,
    "out:ROI% old": 23.85,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.59,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.03,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 373062,
    "out:DH kr savings": 376740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 222593,
    "out:% savings (space heating)": 60.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 85.83,
    "out:Total Energy Use Post PV": 81.5,
    "out:Primary Energy": 91.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.82,
    "out:CO2 Operational/m2": 11.31,
    "out:Total CO2/m2": 41.13,
    "out:Total CO2 (tons)": 132.43,
    "out:Klimatpaverkan": -86.4,
    "out:Initial Cost/MSEK": 5.367,
    "out:Running cost/(Apt SEK y)": 20638,
    "out:Running Cost over RSP/MSEK": 5.812,
    "out:LCP/MSEK": 3.348,
    "out:ROI% old": 23.2,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1115,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.34,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.28,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 366634,
    "out:DH kr savings": 370300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 228799,
    "out:% savings (space heating)": 59.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.73,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 82.83,
    "out:Total Energy Use Post PV": 78.5,
    "out:Primary Energy": 88.71,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.82,
    "out:CO2 Operational/m2": 10.23,
    "out:Total CO2/m2": 40.05,
    "out:Total CO2 (tons)": 128.95,
    "out:Klimatpaverkan": -89.88,
    "out:Initial Cost/MSEK": 5.447,
    "out:Running cost/(Apt SEK y)": 19827,
    "out:Running Cost over RSP/MSEK": 5.583,
    "out:LCP/MSEK": 3.495,
    "out:ROI% old": 23.45,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 741.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.61,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.01,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 376294,
    "out:DH kr savings": 379960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27944,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 218095,
    "out:% savings (space heating)": 61.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 85.82,
    "out:Total Energy Use Post PV": 85.85,
    "out:Primary Energy": 98.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.23,
    "out:CO2 Operational/m2": 27.52,
    "out:Total CO2/m2": 48.75,
    "out:Total CO2 (tons)": 156.96,
    "out:Klimatpaverkan": -61.87,
    "out:Initial Cost/MSEK": 4.879,
    "out:Running cost/(Apt SEK y)": 21830,
    "out:Running Cost over RSP/MSEK": 6.138,
    "out:LCP/MSEK": 3.509,
    "out:ROI% old": 24.56,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1072,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323637,
    "out:DH kr savings": 370300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 227321,
    "out:% savings (space heating)": 59.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 81.82,
    "out:Total Energy Use Post PV": 81.85,
    "out:Primary Energy": 95.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.23,
    "out:CO2 Operational/m2": 26.08,
    "out:Total CO2/m2": 47.31,
    "out:Total CO2 (tons)": 152.33,
    "out:Klimatpaverkan": -66.5,
    "out:Initial Cost/MSEK": 4.959,
    "out:Running cost/(Apt SEK y)": 20750,
    "out:Running Cost over RSP/MSEK": 5.834,
    "out:LCP/MSEK": 3.733,
    "out:ROI% old": 25.04,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1111,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 336517,
    "out:DH kr savings": 383180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216620,
    "out:% savings (space heating)": 61.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 84.8,
    "out:Total Energy Use Post PV": 84.85,
    "out:Primary Energy": 96.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.58,
    "out:CO2 Operational/m2": 27.15,
    "out:Total CO2/m2": 48.73,
    "out:Total CO2 (tons)": 156.92,
    "out:Klimatpaverkan": -61.91,
    "out:Initial Cost/MSEK": 5.015,
    "out:Running cost/(Apt SEK y)": 21556,
    "out:Running Cost over RSP/MSEK": 6.061,
    "out:LCP/MSEK": 3.449,
    "out:ROI% old": 24.11,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 1036.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1082,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 326857,
    "out:DH kr savings": 373520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 223836,
    "out:% savings (space heating)": 60.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 80.8,
    "out:Total Energy Use Post PV": 80.85,
    "out:Primary Energy": 93.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.58,
    "out:CO2 Operational/m2": 25.71,
    "out:Total CO2/m2": 47.29,
    "out:Total CO2 (tons)": 152.28,
    "out:Klimatpaverkan": -66.55,
    "out:Initial Cost/MSEK": 5.096,
    "out:Running cost/(Apt SEK y)": 20476,
    "out:Running Cost over RSP/MSEK": 5.757,
    "out:LCP/MSEK": 3.673,
    "out:ROI% old": 24.58,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 1036,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1121,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 339737,
    "out:DH kr savings": 386400,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 213149,
    "out:% savings (space heating)": 62.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 85.82,
    "out:Total Energy Use Post PV": 81.5,
    "out:Primary Energy": 90.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.47,
    "out:CO2 Operational/m2": 11.3,
    "out:Total CO2/m2": 40.77,
    "out:Total CO2 (tons)": 131.28,
    "out:Klimatpaverkan": -87.55,
    "out:Initial Cost/MSEK": 5.325,
    "out:Running cost/(Apt SEK y)": 20637,
    "out:Running Cost over RSP/MSEK": 5.811,
    "out:LCP/MSEK": 3.389,
    "out:ROI% old": 23.38,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1115,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.23,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.39,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 366640,
    "out:DH kr savings": 370300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 227321,
    "out:% savings (space heating)": 59.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 81.82,
    "out:Total Energy Use Post PV": 77.5,
    "out:Primary Energy": 87.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.47,
    "out:CO2 Operational/m2": 9.86,
    "out:Total CO2/m2": 39.33,
    "out:Total CO2 (tons)": 126.64,
    "out:Klimatpaverkan": -92.19,
    "out:Initial Cost/MSEK": 5.406,
    "out:Running cost/(Apt SEK y)": 19556,
    "out:Running Cost over RSP/MSEK": 5.507,
    "out:LCP/MSEK": 3.613,
    "out:ROI% old": 23.83,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1154,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.67,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13967.94,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 379520,
    "out:DH kr savings": 383180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15039,
    "out:El kr saved": 27936,
    "out:El kr return": 42974,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216620,
    "out:% savings (space heating)": 61.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.51,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 84.8,
    "out:Total Energy Use Post PV": 80.5,
    "out:Primary Energy": 88.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.82,
    "out:CO2 Operational/m2": 10.92,
    "out:Total CO2/m2": 40.74,
    "out:Total CO2 (tons)": 131.18,
    "out:Klimatpaverkan": -87.65,
    "out:Initial Cost/MSEK": 5.462,
    "out:Running cost/(Apt SEK y)": 20363,
    "out:Running Cost over RSP/MSEK": 5.734,
    "out:LCP/MSEK": 3.33,
    "out:ROI% old": 23,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 740,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1125,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10038.2,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13955.42,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369879,
    "out:DH kr savings": 373520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15057,
    "out:El kr saved": 27911,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 223836,
    "out:% savings (space heating)": 60.59,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 79.16,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 80.8,
    "out:Total Energy Use Post PV": 76.5,
    "out:Primary Energy": 85.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.82,
    "out:CO2 Operational/m2": 9.48,
    "out:Total CO2/m2": 39.3,
    "out:Total CO2 (tons)": 126.53,
    "out:Klimatpaverkan": -92.3,
    "out:Initial Cost/MSEK": 5.542,
    "out:Running cost/(Apt SEK y)": 19282,
    "out:Running Cost over RSP/MSEK": 5.43,
    "out:LCP/MSEK": 3.554,
    "out:ROI% old": 23.45,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 151.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1164,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10039.44,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954.18,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382761,
    "out:DH kr savings": 386400,
    "out:El kr savings": -18698,
    "out:El kr sold": 15059,
    "out:El kr saved": 27908,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 213149,
    "out:% savings (space heating)": 62.47,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.84,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.56,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 92.6,
    "out:Primary Energy": 97.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.87,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 56.48,
    "out:Total CO2 (tons)": 181.85,
    "out:Klimatpaverkan": -36.98,
    "out:Initial Cost/MSEK": 5.66,
    "out:Running cost/(Apt SEK y)": 24320,
    "out:Running Cost over RSP/MSEK": 6.844,
    "out:LCP/MSEK": 2.022,
    "out:ROI% old": 19.39,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325220,
    "out:DH kr savings": 325220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 243292,
    "out:% savings (space heating)": 57.16,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.39,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 89.6,
    "out:Primary Energy": 94.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.87,
    "out:CO2 Operational/m2": 30.52,
    "out:Total CO2/m2": 55.4,
    "out:Total CO2 (tons)": 178.37,
    "out:Klimatpaverkan": -40.46,
    "out:Initial Cost/MSEK": 5.74,
    "out:Running cost/(Apt SEK y)": 23510,
    "out:Running Cost over RSP/MSEK": 6.615,
    "out:LCP/MSEK": 2.17,
    "out:ROI% old": 19.69,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334880,
    "out:DH kr savings": 334880,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233112,
    "out:% savings (space heating)": 58.96,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.56,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 91.6,
    "out:Primary Energy": 94.67,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.22,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 56.47,
    "out:Total CO2 (tons)": 181.82,
    "out:Klimatpaverkan": -37.01,
    "out:Initial Cost/MSEK": 5.796,
    "out:Running cost/(Apt SEK y)": 24050,
    "out:Running Cost over RSP/MSEK": 6.768,
    "out:LCP/MSEK": 1.962,
    "out:ROI% old": 19.12,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 992,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328440,
    "out:DH kr savings": 328440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 243292,
    "out:% savings (space heating)": 57.16,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.39,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 88.6,
    "out:Primary Energy": 91.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.22,
    "out:CO2 Operational/m2": 30.16,
    "out:Total CO2/m2": 55.39,
    "out:Total CO2 (tons)": 178.34,
    "out:Klimatpaverkan": -40.49,
    "out:Initial Cost/MSEK": 5.877,
    "out:Running cost/(Apt SEK y)": 23239,
    "out:Running Cost over RSP/MSEK": 6.539,
    "out:LCP/MSEK": 2.11,
    "out:ROI% old": 19.41,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1021,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338100,
    "out:DH kr savings": 338100,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233112,
    "out:% savings (space heating)": 58.96,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.56,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 89.91,
    "out:Primary Energy": 92.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 7.12,
    "out:Total CO2/m2": 40.23,
    "out:Total CO2 (tons)": 129.55,
    "out:Klimatpaverkan": -89.28,
    "out:Initial Cost/MSEK": 6.106,
    "out:Running cost/(Apt SEK y)": 23200,
    "out:Running Cost over RSP/MSEK": 6.537,
    "out:LCP/MSEK": 1.883,
    "out:ROI% old": 18.69,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1022,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 365536,
    "out:DH kr savings": 325220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 243292,
    "out:% savings (space heating)": 57.16,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.39,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 86.91,
    "out:Primary Energy": 89.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 6.04,
    "out:Total CO2/m2": 39.15,
    "out:Total CO2 (tons)": 126.07,
    "out:Klimatpaverkan": -92.76,
    "out:Initial Cost/MSEK": 6.187,
    "out:Running cost/(Apt SEK y)": 22390,
    "out:Running Cost over RSP/MSEK": 6.309,
    "out:LCP/MSEK": 2.03,
    "out:ROI% old": 18.97,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 375196,
    "out:DH kr savings": 334880,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233112,
    "out:% savings (space heating)": 58.96,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.56,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 88.91,
    "out:Primary Energy": 89.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.46,
    "out:CO2 Operational/m2": 6.76,
    "out:Total CO2/m2": 40.22,
    "out:Total CO2 (tons)": 129.52,
    "out:Klimatpaverkan": -89.31,
    "out:Initial Cost/MSEK": 6.243,
    "out:Running cost/(Apt SEK y)": 22930,
    "out:Running Cost over RSP/MSEK": 6.461,
    "out:LCP/MSEK": 1.822,
    "out:ROI% old": 18.46,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1032,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 368756,
    "out:DH kr savings": 328440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 243292,
    "out:% savings (space heating)": 57.16,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.39,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 85.91,
    "out:Primary Energy": 87.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.46,
    "out:CO2 Operational/m2": 5.68,
    "out:Total CO2/m2": 39.14,
    "out:Total CO2 (tons)": 126.04,
    "out:Klimatpaverkan": -92.79,
    "out:Initial Cost/MSEK": 6.323,
    "out:Running cost/(Apt SEK y)": 22120,
    "out:Running Cost over RSP/MSEK": 6.233,
    "out:LCP/MSEK": 1.97,
    "out:ROI% old": 18.74,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1061,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378416,
    "out:DH kr savings": 338100,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233112,
    "out:% savings (space heating)": 58.96,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.56,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 90.6,
    "out:Primary Energy": 93.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.87,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 55.76,
    "out:Total CO2 (tons)": 179.53,
    "out:Klimatpaverkan": -39.3,
    "out:Initial Cost/MSEK": 5.755,
    "out:Running cost/(Apt SEK y)": 23780,
    "out:Running Cost over RSP/MSEK": 6.691,
    "out:LCP/MSEK": 2.08,
    "out:ROI% old": 19.45,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1002,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 331660,
    "out:DH kr savings": 331660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 243292,
    "out:% savings (space heating)": 57.16,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.39,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 87.6,
    "out:Total Energy Use Post PV": 87.6,
    "out:Primary Energy": 91.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.87,
    "out:CO2 Operational/m2": 29.8,
    "out:Total CO2/m2": 54.68,
    "out:Total CO2 (tons)": 176.06,
    "out:Klimatpaverkan": -42.77,
    "out:Initial Cost/MSEK": 5.835,
    "out:Running cost/(Apt SEK y)": 22969,
    "out:Running Cost over RSP/MSEK": 6.463,
    "out:LCP/MSEK": 2.227,
    "out:ROI% old": 19.74,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1031,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 341320,
    "out:DH kr savings": 341320,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233112,
    "out:% savings (space heating)": 58.96,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.56,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 89.6,
    "out:Primary Energy": 91.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.22,
    "out:CO2 Operational/m2": 30.52,
    "out:Total CO2/m2": 55.75,
    "out:Total CO2 (tons)": 179.5,
    "out:Klimatpaverkan": -39.33,
    "out:Initial Cost/MSEK": 5.891,
    "out:Running cost/(Apt SEK y)": 23510,
    "out:Running Cost over RSP/MSEK": 6.615,
    "out:LCP/MSEK": 2.019,
    "out:ROI% old": 19.18,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 334880,
    "out:DH kr savings": 334880,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 243292,
    "out:% savings (space heating)": 57.16,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.39,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 86.6,
    "out:Total Energy Use Post PV": 86.6,
    "out:Primary Energy": 88.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.22,
    "out:CO2 Operational/m2": 29.44,
    "out:Total CO2/m2": 54.67,
    "out:Total CO2 (tons)": 176.02,
    "out:Klimatpaverkan": -42.81,
    "out:Initial Cost/MSEK": 5.972,
    "out:Running cost/(Apt SEK y)": 22699,
    "out:Running Cost over RSP/MSEK": 6.387,
    "out:LCP/MSEK": 2.167,
    "out:ROI% old": 19.47,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1041,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 344540,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 344540,
    "out:DH kr savings": 344540,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233112,
    "out:% savings (space heating)": 58.96,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01001200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.56,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 87.91,
    "out:Primary Energy": 88.96,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 6.4,
    "out:Total CO2/m2": 39.51,
    "out:Total CO2 (tons)": 127.23,
    "out:Klimatpaverkan": -91.6,
    "out:Initial Cost/MSEK": 6.201,
    "out:Running cost/(Apt SEK y)": 22660,
    "out:Running Cost over RSP/MSEK": 6.385,
    "out:LCP/MSEK": 1.94,
    "out:ROI% old": 18.75,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 371976,
    "out:DH kr savings": 331660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 243292,
    "out:% savings (space heating)": 57.16,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.39,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 87.6,
    "out:Total Energy Use Post PV": 84.91,
    "out:Primary Energy": 86.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 5.32,
    "out:Total CO2/m2": 38.43,
    "out:Total CO2 (tons)": 123.75,
    "out:Klimatpaverkan": -95.08,
    "out:Initial Cost/MSEK": 6.282,
    "out:Running cost/(Apt SEK y)": 21849,
    "out:Running Cost over RSP/MSEK": 6.157,
    "out:LCP/MSEK": 2.088,
    "out:ROI% old": 19.03,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1071,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 381636,
    "out:DH kr savings": 341320,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233112,
    "out:% savings (space heating)": 58.96,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 75.56,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 86.91,
    "out:Primary Energy": 86.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.46,
    "out:CO2 Operational/m2": 6.04,
    "out:Total CO2/m2": 39.5,
    "out:Total CO2 (tons)": 127.2,
    "out:Klimatpaverkan": -91.63,
    "out:Initial Cost/MSEK": 6.338,
    "out:Running cost/(Apt SEK y)": 22390,
    "out:Running Cost over RSP/MSEK": 6.309,
    "out:LCP/MSEK": 1.879,
    "out:ROI% old": 18.52,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 375196,
    "out:DH kr savings": 334880,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 243292,
    "out:% savings (space heating)": 57.16,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.39,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 86.6,
    "out:Total Energy Use Post PV": 83.91,
    "out:Primary Energy": 84.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.46,
    "out:CO2 Operational/m2": 4.96,
    "out:Total CO2/m2": 38.42,
    "out:Total CO2 (tons)": 123.72,
    "out:Klimatpaverkan": -95.11,
    "out:Initial Cost/MSEK": 6.418,
    "out:Running cost/(Apt SEK y)": 21579,
    "out:Running Cost over RSP/MSEK": 6.08,
    "out:LCP/MSEK": 2.027,
    "out:ROI% old": 18.8,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1081,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 344540,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384856,
    "out:DH kr savings": 344540,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233112,
    "out:% savings (space heating)": 58.96,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01001201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 83.84,
    "out:Total Energy Use Post PV": 83.85,
    "out:Primary Energy": 98.91,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.14,
    "out:CO2 Operational/m2": 26.8,
    "out:Total CO2/m2": 54.93,
    "out:Total CO2 (tons)": 176.89,
    "out:Klimatpaverkan": -41.94,
    "out:Initial Cost/MSEK": 6.27,
    "out:Running cost/(Apt SEK y)": 21294,
    "out:Running Cost over RSP/MSEK": 5.987,
    "out:LCP/MSEK": 2.268,
    "out:ROI% old": 19.45,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330077,
    "out:DH kr savings": 376740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 221685,
    "out:% savings (space heating)": 60.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 80.84,
    "out:Total Energy Use Post PV": 80.85,
    "out:Primary Energy": 96.14,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.14,
    "out:CO2 Operational/m2": 25.72,
    "out:Total CO2/m2": 53.85,
    "out:Total CO2 (tons)": 173.41,
    "out:Klimatpaverkan": -45.42,
    "out:Initial Cost/MSEK": 6.351,
    "out:Running cost/(Apt SEK y)": 20483,
    "out:Running Cost over RSP/MSEK": 5.759,
    "out:LCP/MSEK": 2.416,
    "out:ROI% old": 19.72,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1120,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 339737,
    "out:DH kr savings": 386400,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 211504,
    "out:% savings (space heating)": 62.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 81.83,
    "out:Total Energy Use Post PV": 81.85,
    "out:Primary Energy": 96.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.49,
    "out:CO2 Operational/m2": 26.08,
    "out:Total CO2/m2": 54.56,
    "out:Total CO2 (tons)": 175.69,
    "out:Klimatpaverkan": -43.14,
    "out:Initial Cost/MSEK": 6.407,
    "out:Running cost/(Apt SEK y)": 20751,
    "out:Running Cost over RSP/MSEK": 5.834,
    "out:LCP/MSEK": 2.284,
    "out:ROI% old": 19.38,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1111,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336517,
    "out:DH kr savings": 383180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 217180,
    "out:% savings (space heating)": 61.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 78.83,
    "out:Total Energy Use Post PV": 78.85,
    "out:Primary Energy": 93.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.49,
    "out:CO2 Operational/m2": 25,
    "out:Total CO2/m2": 53.48,
    "out:Total CO2 (tons)": 172.22,
    "out:Klimatpaverkan": -46.61,
    "out:Initial Cost/MSEK": 6.488,
    "out:Running cost/(Apt SEK y)": 19941,
    "out:Running Cost over RSP/MSEK": 5.606,
    "out:LCP/MSEK": 2.432,
    "out:ROI% old": 19.64,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1038,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1140,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346177,
    "out:DH kr savings": 392840,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 207004,
    "out:% savings (space heating)": 63.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.85,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 83.84,
    "out:Total Energy Use Post PV": 79.5,
    "out:Primary Energy": 91.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 10.61,
    "out:Total CO2/m2": 46.98,
    "out:Total CO2 (tons)": 151.27,
    "out:Klimatpaverkan": -67.56,
    "out:Initial Cost/MSEK": 6.717,
    "out:Running cost/(Apt SEK y)": 20100,
    "out:Running Cost over RSP/MSEK": 5.66,
    "out:LCP/MSEK": 2.149,
    "out:ROI% old": 18.86,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.52,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.1,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373062,
    "out:DH kr savings": 376740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 221685,
    "out:% savings (space heating)": 60.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.68,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 80.84,
    "out:Total Energy Use Post PV": 76.5,
    "out:Primary Energy": 88.33,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 9.53,
    "out:Total CO2/m2": 45.9,
    "out:Total CO2 (tons)": 147.79,
    "out:Klimatpaverkan": -71.04,
    "out:Initial Cost/MSEK": 6.797,
    "out:Running cost/(Apt SEK y)": 19289,
    "out:Running Cost over RSP/MSEK": 5.432,
    "out:LCP/MSEK": 2.297,
    "out:ROI% old": 19.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 151.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1163,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.54,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.08,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382722,
    "out:DH kr savings": 386400,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 211504,
    "out:% savings (space heating)": 62.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.45,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 81.83,
    "out:Total Energy Use Post PV": 77.5,
    "out:Primary Energy": 88.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.72,
    "out:CO2 Operational/m2": 9.87,
    "out:Total CO2/m2": 46.59,
    "out:Total CO2 (tons)": 150.03,
    "out:Klimatpaverkan": -68.8,
    "out:Initial Cost/MSEK": 6.854,
    "out:Running cost/(Apt SEK y)": 19557,
    "out:Running Cost over RSP/MSEK": 5.507,
    "out:LCP/MSEK": 2.165,
    "out:ROI% old": 18.8,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1154,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.16,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.46,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 379513,
    "out:DH kr savings": 383180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 217180,
    "out:% savings (space heating)": 61.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 78.83,
    "out:Total Energy Use Post PV": 74.5,
    "out:Primary Energy": 85.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.72,
    "out:CO2 Operational/m2": 8.79,
    "out:Total CO2/m2": 45.51,
    "out:Total CO2 (tons)": 146.56,
    "out:Klimatpaverkan": -72.27,
    "out:Initial Cost/MSEK": 6.934,
    "out:Running cost/(Apt SEK y)": 18747,
    "out:Running Cost over RSP/MSEK": 5.279,
    "out:LCP/MSEK": 2.313,
    "out:ROI% old": 19.05,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1183,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.42,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.2,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389174,
    "out:DH kr savings": 392840,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27944,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 207004,
    "out:% savings (space heating)": 63.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.99,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 81.82,
    "out:Total Energy Use Post PV": 81.85,
    "out:Primary Energy": 95.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.14,
    "out:CO2 Operational/m2": 26.08,
    "out:Total CO2/m2": 54.21,
    "out:Total CO2 (tons)": 174.56,
    "out:Klimatpaverkan": -44.27,
    "out:Initial Cost/MSEK": 6.365,
    "out:Running cost/(Apt SEK y)": 20750,
    "out:Running Cost over RSP/MSEK": 5.834,
    "out:LCP/MSEK": 2.326,
    "out:ROI% old": 19.51,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1111,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336517,
    "out:DH kr savings": 383180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 215701,
    "out:% savings (space heating)": 62.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 78.82,
    "out:Total Energy Use Post PV": 78.85,
    "out:Primary Energy": 92.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.14,
    "out:CO2 Operational/m2": 25,
    "out:Total CO2/m2": 53.13,
    "out:Total CO2 (tons)": 171.09,
    "out:Klimatpaverkan": -47.74,
    "out:Initial Cost/MSEK": 6.446,
    "out:Running cost/(Apt SEK y)": 19939,
    "out:Running Cost over RSP/MSEK": 5.606,
    "out:LCP/MSEK": 2.474,
    "out:ROI% old": 19.77,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1140,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346177,
    "out:DH kr savings": 392840,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 205528,
    "out:% savings (space heating)": 63.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 80.8,
    "out:Total Energy Use Post PV": 80.85,
    "out:Primary Energy": 93.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.49,
    "out:CO2 Operational/m2": 25.71,
    "out:Total CO2/m2": 54.2,
    "out:Total CO2 (tons)": 174.52,
    "out:Klimatpaverkan": -44.31,
    "out:Initial Cost/MSEK": 6.502,
    "out:Running cost/(Apt SEK y)": 20476,
    "out:Running Cost over RSP/MSEK": 5.757,
    "out:LCP/MSEK": 2.267,
    "out:ROI% old": 19.27,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 1036.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1121,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 339737,
    "out:DH kr savings": 386400,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 212214,
    "out:% savings (space heating)": 62.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 77.8,
    "out:Total Energy Use Post PV": 77.85,
    "out:Primary Energy": 90.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.49,
    "out:CO2 Operational/m2": 24.63,
    "out:Total CO2/m2": 53.12,
    "out:Total CO2 (tons)": 171.05,
    "out:Klimatpaverkan": -47.78,
    "out:Initial Cost/MSEK": 6.583,
    "out:Running cost/(Apt SEK y)": 19665,
    "out:Running Cost over RSP/MSEK": 5.529,
    "out:LCP/MSEK": 2.415,
    "out:ROI% old": 19.53,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 1036,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1150,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 396060,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 349397,
    "out:DH kr savings": 396060,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 202055,
    "out:% savings (space heating)": 64.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01001201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.99,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 81.82,
    "out:Total Energy Use Post PV": 77.5,
    "out:Primary Energy": 87.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 9.87,
    "out:Total CO2/m2": 46.24,
    "out:Total CO2 (tons)": 148.88,
    "out:Klimatpaverkan": -69.95,
    "out:Initial Cost/MSEK": 6.812,
    "out:Running cost/(Apt SEK y)": 19556,
    "out:Running Cost over RSP/MSEK": 5.507,
    "out:LCP/MSEK": 2.207,
    "out:ROI% old": 18.91,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1154,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.62,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 379519,
    "out:DH kr savings": 383180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15037,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 215701,
    "out:% savings (space heating)": 62.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.83,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 78.82,
    "out:Total Energy Use Post PV": 74.5,
    "out:Primary Energy": 84.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 8.78,
    "out:Total CO2/m2": 45.16,
    "out:Total CO2 (tons)": 145.4,
    "out:Klimatpaverkan": -73.43,
    "out:Initial Cost/MSEK": 6.892,
    "out:Running cost/(Apt SEK y)": 18746,
    "out:Running Cost over RSP/MSEK": 5.279,
    "out:LCP/MSEK": 2.355,
    "out:ROI% old": 19.17,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1183,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.45,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.17,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389180,
    "out:DH kr savings": 392840,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27936,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 205528,
    "out:% savings (space heating)": 63.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.9,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 80.8,
    "out:Total Energy Use Post PV": 76.5,
    "out:Primary Energy": 85.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.72,
    "out:CO2 Operational/m2": 9.48,
    "out:Total CO2/m2": 46.21,
    "out:Total CO2 (tons)": 148.78,
    "out:Klimatpaverkan": -70.05,
    "out:Initial Cost/MSEK": 6.949,
    "out:Running cost/(Apt SEK y)": 19282,
    "out:Running Cost over RSP/MSEK": 5.43,
    "out:LCP/MSEK": 2.147,
    "out:ROI% old": 18.7,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 151.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 740,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1164,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10037.88,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13955.74,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 382759,
    "out:DH kr savings": 386400,
    "out:El kr savings": -18698,
    "out:El kr sold": 15057,
    "out:El kr saved": 27911,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 212214,
    "out:% savings (space heating)": 62.64,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 75.56,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01001201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 62.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 77.8,
    "out:Total Energy Use Post PV": 73.5,
    "out:Primary Energy": 82.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.72,
    "out:CO2 Operational/m2": 8.4,
    "out:Total CO2/m2": 45.12,
    "out:Total CO2 (tons)": 145.3,
    "out:Klimatpaverkan": -73.53,
    "out:Initial Cost/MSEK": 7.029,
    "out:Running cost/(Apt SEK y)": 18472,
    "out:Running Cost over RSP/MSEK": 5.202,
    "out:LCP/MSEK": 2.295,
    "out:ROI% old": 18.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 162.2,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1193,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10039.12,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954.49,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 270.82,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 396060,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 392420,
    "out:DH kr savings": 396060,
    "out:El kr savings": -18698,
    "out:El kr sold": 15059,
    "out:El kr saved": 27909,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 202055,
    "out:% savings (space heating)": 64.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.39,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.6,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 108.6,
    "out:Total Energy Use Post PV": 108.6,
    "out:Primary Energy": 111.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 37.36,
    "out:Total CO2/m2": 53.19,
    "out:Total CO2 (tons)": 171.28,
    "out:Klimatpaverkan": -47.55,
    "out:Initial Cost/MSEK": 3.606,
    "out:Running cost/(Apt SEK y)": 28642,
    "out:Running Cost over RSP/MSEK": 8.061,
    "out:LCP/MSEK": 2.859,
    "out:ROI% old": 25.61,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 273700,
    "out:DH kr savings": 273700,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 294960,
    "out:% savings (space heating)": 48.07,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.71,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 104.6,
    "out:Total Energy Use Post PV": 104.6,
    "out:Primary Energy": 107.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 35.92,
    "out:Total CO2/m2": 51.75,
    "out:Total CO2 (tons)": 166.64,
    "out:Klimatpaverkan": -52.19,
    "out:Initial Cost/MSEK": 3.686,
    "out:Running cost/(Apt SEK y)": 27561,
    "out:Running Cost over RSP/MSEK": 7.756,
    "out:LCP/MSEK": 3.083,
    "out:ROI% old": 26.23,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 865,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 286580,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286580,
    "out:DH kr savings": 286580,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282431,
    "out:% savings (space heating)": 50.27,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.6,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 107.6,
    "out:Total Energy Use Post PV": 107.6,
    "out:Primary Energy": 108.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.18,
    "out:CO2 Operational/m2": 37,
    "out:Total CO2/m2": 53.18,
    "out:Total CO2 (tons)": 171.25,
    "out:Klimatpaverkan": -47.58,
    "out:Initial Cost/MSEK": 3.743,
    "out:Running cost/(Apt SEK y)": 28372,
    "out:Running Cost over RSP/MSEK": 7.985,
    "out:LCP/MSEK": 2.799,
    "out:ROI% old": 24.97,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 79.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 836,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 276920,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276920,
    "out:DH kr savings": 276920,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 294960,
    "out:% savings (space heating)": 48.07,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.71,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 103.6,
    "out:Total Energy Use Post PV": 103.6,
    "out:Primary Energy": 105.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.18,
    "out:CO2 Operational/m2": 35.56,
    "out:Total CO2/m2": 51.74,
    "out:Total CO2 (tons)": 166.61,
    "out:Klimatpaverkan": -52.22,
    "out:Initial Cost/MSEK": 3.823,
    "out:Running cost/(Apt SEK y)": 27291,
    "out:Running Cost over RSP/MSEK": 7.68,
    "out:LCP/MSEK": 3.023,
    "out:ROI% old": 25.58,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 875,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289800,
    "out:DH kr savings": 289800,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282431,
    "out:% savings (space heating)": 50.27,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.6,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 108.6,
    "out:Total Energy Use Post PV": 105.91,
    "out:Primary Energy": 106.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 12.88,
    "out:Total CO2/m2": 36.95,
    "out:Total CO2 (tons)": 118.98,
    "out:Klimatpaverkan": -99.85,
    "out:Initial Cost/MSEK": 4.052,
    "out:Running cost/(Apt SEK y)": 27522,
    "out:Running Cost over RSP/MSEK": 7.754,
    "out:LCP/MSEK": 2.72,
    "out:ROI% old": 23.87,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 867,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 314016,
    "out:DH kr savings": 273700,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 294960,
    "out:% savings (space heating)": 48.07,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.71,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 104.6,
    "out:Total Energy Use Post PV": 101.91,
    "out:Primary Energy": 103.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 11.44,
    "out:Total CO2/m2": 35.51,
    "out:Total CO2 (tons)": 114.34,
    "out:Klimatpaverkan": -104.49,
    "out:Initial Cost/MSEK": 4.133,
    "out:Running cost/(Apt SEK y)": 26442,
    "out:Running Cost over RSP/MSEK": 7.45,
    "out:LCP/MSEK": 2.943,
    "out:ROI% old": 24.46,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 90.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 97,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 286580,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 326896,
    "out:DH kr savings": 286580,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282431,
    "out:% savings (space heating)": 50.27,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.6,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 107.6,
    "out:Total Energy Use Post PV": 104.91,
    "out:Primary Energy": 103.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.42,
    "out:CO2 Operational/m2": 12.52,
    "out:Total CO2/m2": 36.94,
    "out:Total CO2 (tons)": 118.94,
    "out:Klimatpaverkan": -99.89,
    "out:Initial Cost/MSEK": 4.189,
    "out:Running cost/(Apt SEK y)": 27252,
    "out:Running Cost over RSP/MSEK": 7.678,
    "out:LCP/MSEK": 2.659,
    "out:ROI% old": 23.35,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 276920,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 317236,
    "out:DH kr savings": 276920,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 294960,
    "out:% savings (space heating)": 48.07,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.71,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 103.6,
    "out:Total Energy Use Post PV": 100.91,
    "out:Primary Energy": 100.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.42,
    "out:CO2 Operational/m2": 11.08,
    "out:Total CO2/m2": 35.5,
    "out:Total CO2 (tons)": 114.31,
    "out:Klimatpaverkan": -104.52,
    "out:Initial Cost/MSEK": 4.27,
    "out:Running cost/(Apt SEK y)": 26171,
    "out:Running Cost over RSP/MSEK": 7.374,
    "out:LCP/MSEK": 2.883,
    "out:ROI% old": 23.93,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 92.1,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 916,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330116,
    "out:DH kr savings": 289800,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282431,
    "out:% savings (space heating)": 50.27,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.6,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 106.6,
    "out:Primary Energy": 107.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 36.64,
    "out:Total CO2/m2": 52.47,
    "out:Total CO2 (tons)": 168.96,
    "out:Klimatpaverkan": -49.87,
    "out:Initial Cost/MSEK": 3.701,
    "out:Running cost/(Apt SEK y)": 28102,
    "out:Running Cost over RSP/MSEK": 7.908,
    "out:LCP/MSEK": 2.916,
    "out:ROI% old": 25.54,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 280140,
    "out:DH kr savings": 280140,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 294960,
    "out:% savings (space heating)": 48.07,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.71,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 102.6,
    "out:Total Energy Use Post PV": 102.6,
    "out:Primary Energy": 104.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.83,
    "out:CO2 Operational/m2": 35.2,
    "out:Total CO2/m2": 51.03,
    "out:Total CO2 (tons)": 164.32,
    "out:Klimatpaverkan": -54.51,
    "out:Initial Cost/MSEK": 3.781,
    "out:Running cost/(Apt SEK y)": 27021,
    "out:Running Cost over RSP/MSEK": 7.604,
    "out:LCP/MSEK": 3.14,
    "out:ROI% old": 26.15,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 88.3,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 885,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 293020,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 293020,
    "out:DH kr savings": 293020,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282431,
    "out:% savings (space heating)": 50.27,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.6,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 105.6,
    "out:Total Energy Use Post PV": 105.6,
    "out:Primary Energy": 105.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.18,
    "out:CO2 Operational/m2": 36.28,
    "out:Total CO2/m2": 52.46,
    "out:Total CO2 (tons)": 168.93,
    "out:Klimatpaverkan": -49.9,
    "out:Initial Cost/MSEK": 3.838,
    "out:Running cost/(Apt SEK y)": 27832,
    "out:Running Cost over RSP/MSEK": 7.832,
    "out:LCP/MSEK": 2.856,
    "out:ROI% old": 24.92,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 283360,
    "out:DH kr savings": 283360,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 294960,
    "out:% savings (space heating)": 48.07,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.71,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 101.6,
    "out:Total Energy Use Post PV": 101.6,
    "out:Primary Energy": 102.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 16.18,
    "out:CO2 Operational/m2": 34.84,
    "out:Total CO2/m2": 51.02,
    "out:Total CO2 (tons)": 164.29,
    "out:Klimatpaverkan": -54.54,
    "out:Initial Cost/MSEK": 3.918,
    "out:Running cost/(Apt SEK y)": 26751,
    "out:Running Cost over RSP/MSEK": 7.528,
    "out:LCP/MSEK": 3.08,
    "out:ROI% old": 25.51,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 90.2,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 895,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 296240,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 296240,
    "out:DH kr savings": 296240,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282431,
    "out:% savings (space heating)": 50.27,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.6,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 103.91,
    "out:Primary Energy": 103,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 12.16,
    "out:Total CO2/m2": 36.23,
    "out:Total CO2 (tons)": 116.66,
    "out:Klimatpaverkan": -102.17,
    "out:Initial Cost/MSEK": 4.147,
    "out:Running cost/(Apt SEK y)": 26982,
    "out:Running Cost over RSP/MSEK": 7.602,
    "out:LCP/MSEK": 2.777,
    "out:ROI% old": 23.85,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320456,
    "out:DH kr savings": 280140,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 294960,
    "out:% savings (space heating)": 48.07,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.71,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 102.6,
    "out:Total Energy Use Post PV": 99.91,
    "out:Primary Energy": 99.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.07,
    "out:CO2 Operational/m2": 10.72,
    "out:Total CO2/m2": 34.79,
    "out:Total CO2 (tons)": 112.02,
    "out:Klimatpaverkan": -106.81,
    "out:Initial Cost/MSEK": 4.228,
    "out:Running cost/(Apt SEK y)": 25901,
    "out:Running Cost over RSP/MSEK": 7.297,
    "out:LCP/MSEK": 3.001,
    "out:ROI% old": 24.42,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 925,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 293020,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 333336,
    "out:DH kr savings": 293020,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282431,
    "out:% savings (space heating)": 50.27,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.6,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 105.6,
    "out:Total Energy Use Post PV": 102.91,
    "out:Primary Energy": 100.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.42,
    "out:CO2 Operational/m2": 11.8,
    "out:Total CO2/m2": 36.22,
    "out:Total CO2 (tons)": 116.63,
    "out:Klimatpaverkan": -102.2,
    "out:Initial Cost/MSEK": 4.284,
    "out:Running cost/(Apt SEK y)": 26712,
    "out:Running Cost over RSP/MSEK": 7.526,
    "out:LCP/MSEK": 2.716,
    "out:ROI% old": 23.34,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 88.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 896,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323676,
    "out:DH kr savings": 283360,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 294960,
    "out:% savings (space heating)": 48.07,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.71,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 101.6,
    "out:Total Energy Use Post PV": 98.91,
    "out:Primary Energy": 97.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.42,
    "out:CO2 Operational/m2": 10.36,
    "out:Total CO2/m2": 34.78,
    "out:Total CO2 (tons)": 111.99,
    "out:Klimatpaverkan": -106.84,
    "out:Initial Cost/MSEK": 4.365,
    "out:Running cost/(Apt SEK y)": 25631,
    "out:Running Cost over RSP/MSEK": 7.221,
    "out:LCP/MSEK": 2.94,
    "out:ROI% old": 23.91,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 96,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 935,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 296240,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 336556,
    "out:DH kr savings": 296240,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282431,
    "out:% savings (space heating)": 50.27,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 99.84,
    "out:Total Energy Use Post PV": 99.85,
    "out:Primary Energy": 112.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.09,
    "out:CO2 Operational/m2": 32.56,
    "out:Total CO2/m2": 51.65,
    "out:Total CO2 (tons)": 166.32,
    "out:Klimatpaverkan": -52.51,
    "out:Initial Cost/MSEK": 4.217,
    "out:Running cost/(Apt SEK y)": 25616,
    "out:Running Cost over RSP/MSEK": 7.204,
    "out:LCP/MSEK": 3.105,
    "out:ROI% old": 24.81,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 22,
    "out:Return/kSEK/y": 936,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 278557,
    "out:DH kr savings": 325220,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 273350,
    "out:% savings (space heating)": 51.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 95.84,
    "out:Total Energy Use Post PV": 95.85,
    "out:Primary Energy": 109.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.09,
    "out:CO2 Operational/m2": 31.12,
    "out:Total CO2/m2": 50.21,
    "out:Total CO2 (tons)": 161.68,
    "out:Klimatpaverkan": -57.15,
    "out:Initial Cost/MSEK": 4.297,
    "out:Running cost/(Apt SEK y)": 24535,
    "out:Running Cost over RSP/MSEK": 6.9,
    "out:LCP/MSEK": 3.329,
    "out:ROI% old": 25.35,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 23,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 291437,
    "out:DH kr savings": 338100,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 260821,
    "out:% savings (space heating)": 54.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 97.83,
    "out:Total Energy Use Post PV": 97.85,
    "out:Primary Energy": 110.32,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.44,
    "out:CO2 Operational/m2": 31.84,
    "out:Total CO2/m2": 51.28,
    "out:Total CO2 (tons)": 165.12,
    "out:Klimatpaverkan": -53.71,
    "out:Initial Cost/MSEK": 4.353,
    "out:Running cost/(Apt SEK y)": 25073,
    "out:Running Cost over RSP/MSEK": 7.051,
    "out:LCP/MSEK": 3.121,
    "out:ROI% old": 24.53,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 98,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 22,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284997,
    "out:DH kr savings": 331660,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 268835,
    "out:% savings (space heating)": 52.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 94.83,
    "out:Total Energy Use Post PV": 94.85,
    "out:Primary Energy": 106.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.44,
    "out:CO2 Operational/m2": 30.76,
    "out:Total CO2/m2": 50.2,
    "out:Total CO2 (tons)": 161.65,
    "out:Klimatpaverkan": -57.18,
    "out:Initial Cost/MSEK": 4.434,
    "out:Running cost/(Apt SEK y)": 24263,
    "out:Running Cost over RSP/MSEK": 6.823,
    "out:LCP/MSEK": 3.269,
    "out:ROI% old": 24.82,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 984,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 294657,
    "out:DH kr savings": 341320,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 256310,
    "out:% savings (space heating)": 54.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 99.84,
    "out:Total Energy Use Post PV": 95.5,
    "out:Primary Energy": 105.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 16.37,
    "out:Total CO2/m2": 43.69,
    "out:Total CO2 (tons)": 140.7,
    "out:Klimatpaverkan": -78.13,
    "out:Initial Cost/MSEK": 4.663,
    "out:Running cost/(Apt SEK y)": 24422,
    "out:Running Cost over RSP/MSEK": 6.877,
    "out:LCP/MSEK": 2.986,
    "out:ROI% old": 23.43,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.39,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.23,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 321542,
    "out:DH kr savings": 325220,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 273350,
    "out:% savings (space heating)": 51.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 95.84,
    "out:Total Energy Use Post PV": 91.5,
    "out:Primary Energy": 101.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 14.93,
    "out:Total CO2/m2": 42.25,
    "out:Total CO2 (tons)": 136.06,
    "out:Klimatpaverkan": -82.77,
    "out:Initial Cost/MSEK": 4.744,
    "out:Running cost/(Apt SEK y)": 23341,
    "out:Running Cost over RSP/MSEK": 6.573,
    "out:LCP/MSEK": 3.21,
    "out:ROI% old": 23.95,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1017,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.4,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.22,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334422,
    "out:DH kr savings": 338100,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 260821,
    "out:% savings (space heating)": 54.08,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 97.83,
    "out:Total Energy Use Post PV": 93.5,
    "out:Primary Energy": 102.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.68,
    "out:CO2 Operational/m2": 15.63,
    "out:Total CO2/m2": 43.31,
    "out:Total CO2 (tons)": 139.47,
    "out:Klimatpaverkan": -79.36,
    "out:Initial Cost/MSEK": 4.8,
    "out:Running cost/(Apt SEK y)": 23879,
    "out:Running Cost over RSP/MSEK": 6.724,
    "out:LCP/MSEK": 3.002,
    "out:ROI% old": 23.22,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.44,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.17,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327992,
    "out:DH kr savings": 331660,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 268835,
    "out:% savings (space heating)": 52.67,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 94.83,
    "out:Total Energy Use Post PV": 90.5,
    "out:Primary Energy": 99.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.68,
    "out:CO2 Operational/m2": 14.55,
    "out:Total CO2/m2": 42.23,
    "out:Total CO2 (tons)": 135.99,
    "out:Klimatpaverkan": -82.84,
    "out:Initial Cost/MSEK": 4.88,
    "out:Running cost/(Apt SEK y)": 23069,
    "out:Running Cost over RSP/MSEK": 6.496,
    "out:LCP/MSEK": 3.15,
    "out:ROI% old": 23.51,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1027,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.68,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13972.94,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 337653,
    "out:DH kr savings": 341320,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 256310,
    "out:% savings (space heating)": 54.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 97.83,
    "out:Total Energy Use Post PV": 97.85,
    "out:Primary Energy": 109.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.09,
    "out:CO2 Operational/m2": 31.84,
    "out:Total CO2/m2": 50.93,
    "out:Total CO2 (tons)": 163.99,
    "out:Klimatpaverkan": -54.84,
    "out:Initial Cost/MSEK": 4.312,
    "out:Running cost/(Apt SEK y)": 25072,
    "out:Running Cost over RSP/MSEK": 7.051,
    "out:LCP/MSEK": 3.163,
    "out:ROI% old": 24.77,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 98,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 22,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 284997,
    "out:DH kr savings": 331660,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 267350,
    "out:% savings (space heating)": 52.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 93.82,
    "out:Total Energy Use Post PV": 93.85,
    "out:Primary Energy": 106.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.09,
    "out:CO2 Operational/m2": 30.4,
    "out:Total CO2/m2": 49.49,
    "out:Total CO2 (tons)": 159.36,
    "out:Klimatpaverkan": -59.47,
    "out:Initial Cost/MSEK": 4.392,
    "out:Running cost/(Apt SEK y)": 23992,
    "out:Running Cost over RSP/MSEK": 6.747,
    "out:LCP/MSEK": 3.387,
    "out:ROI% old": 25.3,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 23,
    "out:Return/kSEK/y": 994,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 344540,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 297877,
    "out:DH kr savings": 344540,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254828,
    "out:% savings (space heating)": 55.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 96.81,
    "out:Total Energy Use Post PV": 96.85,
    "out:Primary Energy": 107.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.44,
    "out:CO2 Operational/m2": 31.47,
    "out:Total CO2/m2": 50.92,
    "out:Total CO2 (tons)": 163.95,
    "out:Klimatpaverkan": -54.88,
    "out:Initial Cost/MSEK": 4.448,
    "out:Running cost/(Apt SEK y)": 24799,
    "out:Running Cost over RSP/MSEK": 6.974,
    "out:LCP/MSEK": 3.104,
    "out:ROI% old": 24.25,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1036.4,
    "out:Return %": 22,
    "out:Return/kSEK/y": 965,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 288217,
    "out:DH kr savings": 334880,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 263843,
    "out:% savings (space heating)": 53.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 92.8,
    "out:Total Energy Use Post PV": 92.85,
    "out:Primary Energy": 103.94,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 19.44,
    "out:CO2 Operational/m2": 30.03,
    "out:Total CO2/m2": 49.48,
    "out:Total CO2 (tons)": 159.32,
    "out:Klimatpaverkan": -59.51,
    "out:Initial Cost/MSEK": 4.529,
    "out:Running cost/(Apt SEK y)": 23718,
    "out:Running Cost over RSP/MSEK": 6.67,
    "out:LCP/MSEK": 3.327,
    "out:ROI% old": 24.78,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 1036.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1004,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 347760,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 301097,
    "out:DH kr savings": 347760,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 251334,
    "out:% savings (space heating)": 55.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 97.83,
    "out:Total Energy Use Post PV": 93.5,
    "out:Primary Energy": 101.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 15.63,
    "out:Total CO2/m2": 42.96,
    "out:Total CO2 (tons)": 138.32,
    "out:Klimatpaverkan": -80.51,
    "out:Initial Cost/MSEK": 4.758,
    "out:Running cost/(Apt SEK y)": 23878,
    "out:Running Cost over RSP/MSEK": 6.724,
    "out:LCP/MSEK": 3.044,
    "out:ROI% old": 23.42,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.93,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.68,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327998,
    "out:DH kr savings": 331660,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 267350,
    "out:% savings (space heating)": 52.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 79.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 93.82,
    "out:Total Energy Use Post PV": 89.5,
    "out:Primary Energy": 98.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.33,
    "out:CO2 Operational/m2": 14.19,
    "out:Total CO2/m2": 41.52,
    "out:Total CO2 (tons)": 133.68,
    "out:Klimatpaverkan": -85.15,
    "out:Initial Cost/MSEK": 4.839,
    "out:Running cost/(Apt SEK y)": 22798,
    "out:Running Cost over RSP/MSEK": 6.42,
    "out:LCP/MSEK": 3.267,
    "out:ROI% old": 23.93,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1037,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.35,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.27,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 344540,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 340878,
    "out:DH kr savings": 344540,
    "out:El kr savings": -18698,
    "out:El kr sold": 15037,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 254828,
    "out:% savings (space heating)": 55.13,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 96.81,
    "out:Total Energy Use Post PV": 92.5,
    "out:Primary Energy": 99.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.68,
    "out:CO2 Operational/m2": 15.25,
    "out:Total CO2/m2": 42.93,
    "out:Total CO2 (tons)": 138.22,
    "out:Klimatpaverkan": -80.61,
    "out:Initial Cost/MSEK": 4.895,
    "out:Running cost/(Apt SEK y)": 23605,
    "out:Running Cost over RSP/MSEK": 6.647,
    "out:LCP/MSEK": 2.984,
    "out:ROI% old": 23,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.66,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.96,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 331235,
    "out:DH kr savings": 334880,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27916,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 263843,
    "out:% savings (space heating)": 53.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.6,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 78.05,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 92.8,
    "out:Total Energy Use Post PV": 88.5,
    "out:Primary Energy": 96.13,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.68,
    "out:CO2 Operational/m2": 13.8,
    "out:Total CO2/m2": 41.48,
    "out:Total CO2 (tons)": 133.58,
    "out:Klimatpaverkan": -85.25,
    "out:Initial Cost/MSEK": 4.975,
    "out:Running cost/(Apt SEK y)": 22524,
    "out:Running Cost over RSP/MSEK": 6.343,
    "out:LCP/MSEK": 3.208,
    "out:ROI% old": 23.5,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 740.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1047,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10036.81,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13956.81,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 347760,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 344117,
    "out:DH kr savings": 347760,
    "out:El kr savings": -18698,
    "out:El kr sold": 15055,
    "out:El kr saved": 27914,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 251334,
    "out:% savings (space heating)": 55.75,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 87.71,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.41,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 93.6,
    "out:Primary Energy": 98.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.35,
    "out:CO2 Operational/m2": 31.96,
    "out:Total CO2/m2": 50.31,
    "out:Total CO2 (tons)": 162,
    "out:Klimatpaverkan": -56.83,
    "out:Initial Cost/MSEK": 4.213,
    "out:Running cost/(Apt SEK y)": 24590,
    "out:Running Cost over RSP/MSEK": 6.92,
    "out:LCP/MSEK": 3.393,
    "out:ROI% old": 25.79,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 972,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322000,
    "out:DH kr savings": 322000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246034,
    "out:% savings (space heating)": 56.68,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.21,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 90.6,
    "out:Primary Energy": 95.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.35,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 49.23,
    "out:Total CO2 (tons)": 158.52,
    "out:Klimatpaverkan": -60.31,
    "out:Initial Cost/MSEK": 4.294,
    "out:Running cost/(Apt SEK y)": 23780,
    "out:Running Cost over RSP/MSEK": 6.691,
    "out:LCP/MSEK": 3.541,
    "out:ROI% old": 26.07,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1002,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331660,
    "out:DH kr savings": 331660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235729,
    "out:% savings (space heating)": 58.5,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.41,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 92.6,
    "out:Primary Energy": 95.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.7,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 50.3,
    "out:Total CO2 (tons)": 161.97,
    "out:Klimatpaverkan": -56.86,
    "out:Initial Cost/MSEK": 4.35,
    "out:Running cost/(Apt SEK y)": 24320,
    "out:Running Cost over RSP/MSEK": 6.844,
    "out:LCP/MSEK": 3.332,
    "out:ROI% old": 25.23,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325220,
    "out:DH kr savings": 325220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246034,
    "out:% savings (space heating)": 56.68,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.21,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 88.6,
    "out:Primary Energy": 92.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.7,
    "out:CO2 Operational/m2": 30.16,
    "out:Total CO2/m2": 48.86,
    "out:Total CO2 (tons)": 157.33,
    "out:Klimatpaverkan": -61.5,
    "out:Initial Cost/MSEK": 4.43,
    "out:Running cost/(Apt SEK y)": 23239,
    "out:Running Cost over RSP/MSEK": 6.539,
    "out:LCP/MSEK": 3.556,
    "out:ROI% old": 25.75,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1021,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338100,
    "out:DH kr savings": 338100,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235729,
    "out:% savings (space heating)": 58.5,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.41,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 90.91,
    "out:Primary Energy": 93.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.58,
    "out:CO2 Operational/m2": 7.48,
    "out:Total CO2/m2": 34.07,
    "out:Total CO2 (tons)": 109.7,
    "out:Klimatpaverkan": -109.13,
    "out:Initial Cost/MSEK": 4.66,
    "out:Running cost/(Apt SEK y)": 23470,
    "out:Running Cost over RSP/MSEK": 6.613,
    "out:LCP/MSEK": 3.253,
    "out:ROI% old": 24.26,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1013,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 362316,
    "out:DH kr savings": 322000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246034,
    "out:% savings (space heating)": 56.68,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.21,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 87.91,
    "out:Primary Energy": 90.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.58,
    "out:CO2 Operational/m2": 6.4,
    "out:Total CO2/m2": 32.99,
    "out:Total CO2 (tons)": 106.22,
    "out:Klimatpaverkan": -112.61,
    "out:Initial Cost/MSEK": 4.74,
    "out:Running cost/(Apt SEK y)": 22660,
    "out:Running Cost over RSP/MSEK": 6.385,
    "out:LCP/MSEK": 3.401,
    "out:ROI% old": 24.54,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 371976,
    "out:DH kr savings": 331660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235729,
    "out:% savings (space heating)": 58.5,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.41,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 89.91,
    "out:Primary Energy": 90.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.93,
    "out:CO2 Operational/m2": 7.12,
    "out:Total CO2/m2": 34.06,
    "out:Total CO2 (tons)": 109.67,
    "out:Klimatpaverkan": -109.16,
    "out:Initial Cost/MSEK": 4.796,
    "out:Running cost/(Apt SEK y)": 23200,
    "out:Running Cost over RSP/MSEK": 6.537,
    "out:LCP/MSEK": 3.193,
    "out:ROI% old": 23.8,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1022,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 365536,
    "out:DH kr savings": 325220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246034,
    "out:% savings (space heating)": 56.68,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.21,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 85.91,
    "out:Primary Energy": 87.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.93,
    "out:CO2 Operational/m2": 5.68,
    "out:Total CO2/m2": 32.62,
    "out:Total CO2 (tons)": 105.03,
    "out:Klimatpaverkan": -113.8,
    "out:Initial Cost/MSEK": 4.877,
    "out:Running cost/(Apt SEK y)": 22120,
    "out:Running Cost over RSP/MSEK": 6.233,
    "out:LCP/MSEK": 3.416,
    "out:ROI% old": 24.29,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1061,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378416,
    "out:DH kr savings": 338100,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235729,
    "out:% savings (space heating)": 58.5,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.41,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 91.6,
    "out:Primary Energy": 94.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.35,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 49.59,
    "out:Total CO2 (tons)": 159.68,
    "out:Klimatpaverkan": -59.15,
    "out:Initial Cost/MSEK": 4.308,
    "out:Running cost/(Apt SEK y)": 24050,
    "out:Running Cost over RSP/MSEK": 6.768,
    "out:LCP/MSEK": 3.45,
    "out:ROI% old": 25.73,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 992,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328440,
    "out:DH kr savings": 328440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246034,
    "out:% savings (space heating)": 56.68,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.21,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 88.6,
    "out:Primary Energy": 91.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.35,
    "out:CO2 Operational/m2": 30.16,
    "out:Total CO2/m2": 48.51,
    "out:Total CO2 (tons)": 156.2,
    "out:Klimatpaverkan": -62.63,
    "out:Initial Cost/MSEK": 4.389,
    "out:Running cost/(Apt SEK y)": 23239,
    "out:Running Cost over RSP/MSEK": 6.539,
    "out:LCP/MSEK": 3.598,
    "out:ROI% old": 26,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1021,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338100,
    "out:DH kr savings": 338100,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235729,
    "out:% savings (space heating)": 58.5,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.41,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 90.6,
    "out:Primary Energy": 92.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.7,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 49.58,
    "out:Total CO2 (tons)": 159.65,
    "out:Klimatpaverkan": -59.18,
    "out:Initial Cost/MSEK": 4.445,
    "out:Running cost/(Apt SEK y)": 23780,
    "out:Running Cost over RSP/MSEK": 6.691,
    "out:LCP/MSEK": 3.39,
    "out:ROI% old": 25.18,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1002,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 331660,
    "out:DH kr savings": 331660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246034,
    "out:% savings (space heating)": 56.68,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.21,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 87.6,
    "out:Total Energy Use Post PV": 87.6,
    "out:Primary Energy": 89.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 18.7,
    "out:CO2 Operational/m2": 29.8,
    "out:Total CO2/m2": 48.5,
    "out:Total CO2 (tons)": 156.17,
    "out:Klimatpaverkan": -62.66,
    "out:Initial Cost/MSEK": 4.525,
    "out:Running cost/(Apt SEK y)": 22969,
    "out:Running Cost over RSP/MSEK": 6.463,
    "out:LCP/MSEK": 3.537,
    "out:ROI% old": 25.45,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1031,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 341320,
    "out:DH kr savings": 341320,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235729,
    "out:% savings (space heating)": 58.5,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.41,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 88.91,
    "out:Primary Energy": 89.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.58,
    "out:CO2 Operational/m2": 6.76,
    "out:Total CO2/m2": 33.35,
    "out:Total CO2 (tons)": 107.38,
    "out:Klimatpaverkan": -111.45,
    "out:Initial Cost/MSEK": 4.755,
    "out:Running cost/(Apt SEK y)": 22930,
    "out:Running Cost over RSP/MSEK": 6.461,
    "out:LCP/MSEK": 3.31,
    "out:ROI% old": 24.23,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1032,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 368756,
    "out:DH kr savings": 328440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246034,
    "out:% savings (space heating)": 56.68,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.21,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 85.91,
    "out:Primary Energy": 86.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.58,
    "out:CO2 Operational/m2": 5.68,
    "out:Total CO2/m2": 32.27,
    "out:Total CO2 (tons)": 103.9,
    "out:Klimatpaverkan": -114.93,
    "out:Initial Cost/MSEK": 4.835,
    "out:Running cost/(Apt SEK y)": 22120,
    "out:Running Cost over RSP/MSEK": 6.233,
    "out:LCP/MSEK": 3.458,
    "out:ROI% old": 24.5,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1061,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378416,
    "out:DH kr savings": 338100,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235729,
    "out:% savings (space heating)": 58.5,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.41,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 87.91,
    "out:Primary Energy": 87.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.93,
    "out:CO2 Operational/m2": 6.4,
    "out:Total CO2/m2": 33.34,
    "out:Total CO2 (tons)": 107.35,
    "out:Klimatpaverkan": -111.48,
    "out:Initial Cost/MSEK": 4.891,
    "out:Running cost/(Apt SEK y)": 22660,
    "out:Running Cost over RSP/MSEK": 6.385,
    "out:LCP/MSEK": 3.25,
    "out:ROI% old": 23.78,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 371976,
    "out:DH kr savings": 331660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246034,
    "out:% savings (space heating)": 56.68,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.21,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 87.6,
    "out:Total Energy Use Post PV": 84.91,
    "out:Primary Energy": 84.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.93,
    "out:CO2 Operational/m2": 5.32,
    "out:Total CO2/m2": 32.26,
    "out:Total CO2 (tons)": 103.87,
    "out:Klimatpaverkan": -114.96,
    "out:Initial Cost/MSEK": 4.972,
    "out:Running cost/(Apt SEK y)": 21849,
    "out:Running Cost over RSP/MSEK": 6.157,
    "out:LCP/MSEK": 3.398,
    "out:ROI% old": 24.05,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1071,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 8,
    "out:Payback Time": 12.5,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 381636,
    "out:DH kr savings": 341320,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235729,
    "out:% savings (space heating)": 58.5,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 84.84,
    "out:Total Energy Use Post PV": 84.85,
    "out:Primary Energy": 99.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.61,
    "out:CO2 Operational/m2": 27.16,
    "out:Total CO2/m2": 48.77,
    "out:Total CO2 (tons)": 157.04,
    "out:Klimatpaverkan": -61.79,
    "out:Initial Cost/MSEK": 4.824,
    "out:Running cost/(Apt SEK y)": 21564,
    "out:Running Cost over RSP/MSEK": 6.063,
    "out:LCP/MSEK": 3.639,
    "out:ROI% old": 25.06,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1081,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 326857,
    "out:DH kr savings": 373520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224428,
    "out:% savings (space heating)": 60.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 80.84,
    "out:Total Energy Use Post PV": 80.85,
    "out:Primary Energy": 96.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.61,
    "out:CO2 Operational/m2": 25.72,
    "out:Total CO2/m2": 47.33,
    "out:Total CO2 (tons)": 152.4,
    "out:Klimatpaverkan": -66.43,
    "out:Initial Cost/MSEK": 4.904,
    "out:Running cost/(Apt SEK y)": 20483,
    "out:Running Cost over RSP/MSEK": 5.759,
    "out:LCP/MSEK": 3.863,
    "out:ROI% old": 25.54,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1120,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 339737,
    "out:DH kr savings": 386400,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 214123,
    "out:% savings (space heating)": 62.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 82.83,
    "out:Total Energy Use Post PV": 82.85,
    "out:Primary Energy": 97.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.96,
    "out:CO2 Operational/m2": 26.44,
    "out:Total CO2/m2": 48.4,
    "out:Total CO2 (tons)": 155.84,
    "out:Klimatpaverkan": -62.99,
    "out:Initial Cost/MSEK": 4.96,
    "out:Running cost/(Apt SEK y)": 21021,
    "out:Running Cost over RSP/MSEK": 5.91,
    "out:LCP/MSEK": 3.655,
    "out:ROI% old": 24.81,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1038,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 333297,
    "out:DH kr savings": 379960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 219927,
    "out:% savings (space heating)": 61.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 79.83,
    "out:Total Energy Use Post PV": 79.85,
    "out:Primary Energy": 94.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.96,
    "out:CO2 Operational/m2": 25.36,
    "out:Total CO2/m2": 47.32,
    "out:Total CO2 (tons)": 152.37,
    "out:Klimatpaverkan": -66.46,
    "out:Initial Cost/MSEK": 5.041,
    "out:Running cost/(Apt SEK y)": 20211,
    "out:Running Cost over RSP/MSEK": 5.682,
    "out:LCP/MSEK": 3.803,
    "out:ROI% old": 25.06,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 1038,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1130,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342957,
    "out:DH kr savings": 389620,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 209627,
    "out:% savings (space heating)": 63.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 84.84,
    "out:Total Energy Use Post PV": 80.5,
    "out:Primary Energy": 91.84,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.85,
    "out:CO2 Operational/m2": 10.97,
    "out:Total CO2/m2": 40.81,
    "out:Total CO2 (tons)": 131.42,
    "out:Klimatpaverkan": -87.41,
    "out:Initial Cost/MSEK": 5.27,
    "out:Running cost/(Apt SEK y)": 20370,
    "out:Running Cost over RSP/MSEK": 5.736,
    "out:LCP/MSEK": 3.52,
    "out:ROI% old": 23.83,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1124,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.6,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.02,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369842,
    "out:DH kr savings": 373520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224428,
    "out:% savings (space heating)": 60.49,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.5,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 80.84,
    "out:Total Energy Use Post PV": 76.5,
    "out:Primary Energy": 89.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.85,
    "out:CO2 Operational/m2": 9.53,
    "out:Total CO2/m2": 39.37,
    "out:Total CO2 (tons)": 126.78,
    "out:Klimatpaverkan": -92.05,
    "out:Initial Cost/MSEK": 5.351,
    "out:Running cost/(Apt SEK y)": 19289,
    "out:Running Cost over RSP/MSEK": 5.432,
    "out:LCP/MSEK": 3.743,
    "out:ROI% old": 24.28,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 151.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1163,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.62,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382722,
    "out:DH kr savings": 386400,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 214123,
    "out:% savings (space heating)": 62.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.3,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 82.83,
    "out:Total Energy Use Post PV": 78.5,
    "out:Primary Energy": 89.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.2,
    "out:CO2 Operational/m2": 10.23,
    "out:Total CO2/m2": 40.43,
    "out:Total CO2 (tons)": 130.18,
    "out:Klimatpaverkan": -88.65,
    "out:Initial Cost/MSEK": 5.407,
    "out:Running cost/(Apt SEK y)": 19827,
    "out:Running Cost over RSP/MSEK": 5.583,
    "out:LCP/MSEK": 3.536,
    "out:ROI% old": 23.63,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 741.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.5,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 376294,
    "out:DH kr savings": 379960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27944,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 219927,
    "out:% savings (space heating)": 61.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 79.83,
    "out:Total Energy Use Post PV": 75.5,
    "out:Primary Energy": 86.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.2,
    "out:CO2 Operational/m2": 9.15,
    "out:Total CO2/m2": 39.35,
    "out:Total CO2 (tons)": 126.7,
    "out:Klimatpaverkan": -92.13,
    "out:Initial Cost/MSEK": 5.487,
    "out:Running cost/(Apt SEK y)": 19017,
    "out:Running Cost over RSP/MSEK": 5.355,
    "out:LCP/MSEK": 3.683,
    "out:ROI% old": 23.87,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 155.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 741.5,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.77,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13971.85,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385954,
    "out:DH kr savings": 389620,
    "out:El kr savings": -18698,
    "out:El kr sold": 15033,
    "out:El kr saved": 27944,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 209627,
    "out:% savings (space heating)": 63.09,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 82.82,
    "out:Total Energy Use Post PV": 82.85,
    "out:Primary Energy": 96.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.61,
    "out:CO2 Operational/m2": 26.44,
    "out:Total CO2/m2": 48.05,
    "out:Total CO2 (tons)": 154.71,
    "out:Klimatpaverkan": -64.12,
    "out:Initial Cost/MSEK": 4.919,
    "out:Running cost/(Apt SEK y)": 21020,
    "out:Running Cost over RSP/MSEK": 5.91,
    "out:LCP/MSEK": 3.697,
    "out:ROI% old": 25.02,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 333297,
    "out:DH kr savings": 379960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 218450,
    "out:% savings (space heating)": 61.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 79.82,
    "out:Total Energy Use Post PV": 79.85,
    "out:Primary Energy": 93.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.61,
    "out:CO2 Operational/m2": 25.36,
    "out:Total CO2/m2": 46.97,
    "out:Total CO2 (tons)": 151.23,
    "out:Klimatpaverkan": -67.6,
    "out:Initial Cost/MSEK": 4.999,
    "out:Running cost/(Apt SEK y)": 20209,
    "out:Running Cost over RSP/MSEK": 5.682,
    "out:LCP/MSEK": 3.845,
    "out:ROI% old": 25.27,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 1037.5,
    "out:Return %": 23,
    "out:Return/kSEK/y": 1130,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342957,
    "out:DH kr savings": 389620,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 208153,
    "out:% savings (space heating)": 63.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 81.8,
    "out:Total Energy Use Post PV": 81.85,
    "out:Primary Energy": 94.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.96,
    "out:CO2 Operational/m2": 26.07,
    "out:Total CO2/m2": 48.03,
    "out:Total CO2 (tons)": 154.67,
    "out:Klimatpaverkan": -64.16,
    "out:Initial Cost/MSEK": 5.055,
    "out:Running cost/(Apt SEK y)": 20746,
    "out:Running Cost over RSP/MSEK": 5.833,
    "out:LCP/MSEK": 3.637,
    "out:ROI% old": 24.56,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1036.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1111,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336517,
    "out:DH kr savings": 383180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 214969,
    "out:% savings (space heating)": 62.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 78.8,
    "out:Total Energy Use Post PV": 78.85,
    "out:Primary Energy": 91.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.96,
    "out:CO2 Operational/m2": 24.99,
    "out:Total CO2/m2": 46.95,
    "out:Total CO2 (tons)": 151.19,
    "out:Klimatpaverkan": -67.64,
    "out:Initial Cost/MSEK": 5.136,
    "out:Running cost/(Apt SEK y)": 19935,
    "out:Running Cost over RSP/MSEK": 5.605,
    "out:LCP/MSEK": 3.785,
    "out:ROI% old": 24.81,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1035.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1140,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346177,
    "out:DH kr savings": 392840,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 204685,
    "out:% savings (space heating)": 63.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.84,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 82.82,
    "out:Total Energy Use Post PV": 78.5,
    "out:Primary Energy": 88.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.85,
    "out:CO2 Operational/m2": 10.22,
    "out:Total CO2/m2": 40.07,
    "out:Total CO2 (tons)": 129.03,
    "out:Klimatpaverkan": -89.8,
    "out:Initial Cost/MSEK": 5.365,
    "out:Running cost/(Apt SEK y)": 19826,
    "out:Running Cost over RSP/MSEK": 5.583,
    "out:LCP/MSEK": 3.577,
    "out:ROI% old": 23.81,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.45,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.17,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 376300,
    "out:DH kr savings": 379960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27936,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 218450,
    "out:% savings (space heating)": 61.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.64,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 79.82,
    "out:Total Energy Use Post PV": 75.5,
    "out:Primary Energy": 85.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.85,
    "out:CO2 Operational/m2": 9.14,
    "out:Total CO2/m2": 38.99,
    "out:Total CO2 (tons)": 125.55,
    "out:Klimatpaverkan": -93.28,
    "out:Initial Cost/MSEK": 5.446,
    "out:Running cost/(Apt SEK y)": 19016,
    "out:Running Cost over RSP/MSEK": 5.355,
    "out:LCP/MSEK": 3.725,
    "out:ROI% old": 24.06,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 155.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 741.1,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.9,
    "out:PV (% sold (El))": 41.79,
    "out:PV (kWh self-consumed)": 13967.72,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385961,
    "out:DH kr savings": 389620,
    "out:El kr savings": -18698,
    "out:El kr sold": 15039,
    "out:El kr saved": 27935,
    "out:El kr return": 42974,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 208153,
    "out:% savings (space heating)": 63.35,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.76,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 81.8,
    "out:Total Energy Use Post PV": 77.5,
    "out:Primary Energy": 86.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.2,
    "out:CO2 Operational/m2": 9.84,
    "out:Total CO2/m2": 40.04,
    "out:Total CO2 (tons)": 128.93,
    "out:Klimatpaverkan": -89.9,
    "out:Initial Cost/MSEK": 5.502,
    "out:Running cost/(Apt SEK y)": 19552,
    "out:Running Cost over RSP/MSEK": 5.506,
    "out:LCP/MSEK": 3.518,
    "out:ROI% old": 23.42,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1154,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10038.64,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954.98,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 379540,
    "out:DH kr savings": 383180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15058,
    "out:El kr saved": 27910,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 214969,
    "out:% savings (space heating)": 62.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.41,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 78.8,
    "out:Total Energy Use Post PV": 74.5,
    "out:Primary Energy": 83.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 30.2,
    "out:CO2 Operational/m2": 8.76,
    "out:Total CO2/m2": 38.96,
    "out:Total CO2 (tons)": 125.44,
    "out:Klimatpaverkan": -93.39,
    "out:Initial Cost/MSEK": 5.582,
    "out:Running cost/(Apt SEK y)": 18742,
    "out:Running Cost over RSP/MSEK": 5.278,
    "out:LCP/MSEK": 3.666,
    "out:ROI% old": 23.67,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.38,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 739.8,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1183,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10039.9,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13953.72,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389202,
    "out:DH kr savings": 392840,
    "out:El kr savings": -18698,
    "out:El kr sold": 15060,
    "out:El kr saved": 27907,
    "out:El kr return": 42967,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 204685,
    "out:% savings (space heating)": 63.96,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.21,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.77,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 89.6,
    "out:Primary Energy": 94.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.26,
    "out:CO2 Operational/m2": 30.52,
    "out:Total CO2/m2": 55.78,
    "out:Total CO2 (tons)": 179.6,
    "out:Klimatpaverkan": -39.23,
    "out:Initial Cost/MSEK": 5.7,
    "out:Running cost/(Apt SEK y)": 23510,
    "out:Running Cost over RSP/MSEK": 6.615,
    "out:LCP/MSEK": 2.21,
    "out:ROI% old": 19.83,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 334880,
    "out:DH kr savings": 334880,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 234330,
    "out:% savings (space heating)": 58.74,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.74,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 86.6,
    "out:Total Energy Use Post PV": 86.6,
    "out:Primary Energy": 92.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.26,
    "out:CO2 Operational/m2": 29.44,
    "out:Total CO2/m2": 54.7,
    "out:Total CO2 (tons)": 176.12,
    "out:Klimatpaverkan": -42.71,
    "out:Initial Cost/MSEK": 5.781,
    "out:Running cost/(Apt SEK y)": 22699,
    "out:Running Cost over RSP/MSEK": 6.387,
    "out:LCP/MSEK": 2.358,
    "out:ROI% old": 20.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1041,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 344540,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 344540,
    "out:DH kr savings": 344540,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224557,
    "out:% savings (space heating)": 60.46,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.77,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 88.6,
    "out:Primary Energy": 92.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.61,
    "out:CO2 Operational/m2": 30.16,
    "out:Total CO2/m2": 55.77,
    "out:Total CO2 (tons)": 179.57,
    "out:Klimatpaverkan": -39.26,
    "out:Initial Cost/MSEK": 5.837,
    "out:Running cost/(Apt SEK y)": 23239,
    "out:Running Cost over RSP/MSEK": 6.539,
    "out:LCP/MSEK": 2.15,
    "out:ROI% old": 19.55,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1021,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 338100,
    "out:DH kr savings": 338100,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 234330,
    "out:% savings (space heating)": 58.74,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.74,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 85.6,
    "out:Total Energy Use Post PV": 85.6,
    "out:Primary Energy": 89.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.61,
    "out:CO2 Operational/m2": 29.08,
    "out:Total CO2/m2": 54.69,
    "out:Total CO2 (tons)": 176.09,
    "out:Klimatpaverkan": -42.74,
    "out:Initial Cost/MSEK": 5.917,
    "out:Running cost/(Apt SEK y)": 22429,
    "out:Running Cost over RSP/MSEK": 6.311,
    "out:LCP/MSEK": 2.298,
    "out:ROI% old": 19.83,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1050,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 347760,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 347760,
    "out:DH kr savings": 347760,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224557,
    "out:% savings (space heating)": 60.46,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.77,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 86.91,
    "out:Primary Energy": 90.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.49,
    "out:CO2 Operational/m2": 6.04,
    "out:Total CO2/m2": 39.53,
    "out:Total CO2 (tons)": 127.3,
    "out:Klimatpaverkan": -91.53,
    "out:Initial Cost/MSEK": 6.147,
    "out:Running cost/(Apt SEK y)": 22390,
    "out:Running Cost over RSP/MSEK": 6.309,
    "out:LCP/MSEK": 2.071,
    "out:ROI% old": 19.1,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 375196,
    "out:DH kr savings": 334880,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 234330,
    "out:% savings (space heating)": 58.74,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.74,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 86.6,
    "out:Total Energy Use Post PV": 83.91,
    "out:Primary Energy": 87.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.49,
    "out:CO2 Operational/m2": 4.96,
    "out:Total CO2/m2": 38.45,
    "out:Total CO2 (tons)": 123.82,
    "out:Klimatpaverkan": -95.01,
    "out:Initial Cost/MSEK": 6.227,
    "out:Running cost/(Apt SEK y)": 21579,
    "out:Running Cost over RSP/MSEK": 6.08,
    "out:LCP/MSEK": 2.218,
    "out:ROI% old": 19.38,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1081,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 344540,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 384856,
    "out:DH kr savings": 344540,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224557,
    "out:% savings (space heating)": 60.46,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.77,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 85.91,
    "out:Primary Energy": 87.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.84,
    "out:CO2 Operational/m2": 5.68,
    "out:Total CO2/m2": 39.52,
    "out:Total CO2 (tons)": 127.27,
    "out:Klimatpaverkan": -91.56,
    "out:Initial Cost/MSEK": 6.283,
    "out:Running cost/(Apt SEK y)": 22120,
    "out:Running Cost over RSP/MSEK": 6.233,
    "out:LCP/MSEK": 2.01,
    "out:ROI% old": 18.86,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1061,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 378416,
    "out:DH kr savings": 338100,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 234330,
    "out:% savings (space heating)": 58.74,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.74,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 85.6,
    "out:Total Energy Use Post PV": 82.91,
    "out:Primary Energy": 84.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.84,
    "out:CO2 Operational/m2": 4.6,
    "out:Total CO2/m2": 38.44,
    "out:Total CO2 (tons)": 123.79,
    "out:Klimatpaverkan": -95.04,
    "out:Initial Cost/MSEK": 6.364,
    "out:Running cost/(Apt SEK y)": 21309,
    "out:Running Cost over RSP/MSEK": 6.004,
    "out:LCP/MSEK": 2.158,
    "out:ROI% old": 19.13,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 78,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 347760,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 388076,
    "out:DH kr savings": 347760,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224557,
    "out:% savings (space heating)": 60.46,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.77,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 87.6,
    "out:Total Energy Use Post PV": 87.6,
    "out:Primary Energy": 91.36,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.26,
    "out:CO2 Operational/m2": 29.8,
    "out:Total CO2/m2": 55.06,
    "out:Total CO2 (tons)": 177.28,
    "out:Klimatpaverkan": -41.55,
    "out:Initial Cost/MSEK": 5.795,
    "out:Running cost/(Apt SEK y)": 22969,
    "out:Running Cost over RSP/MSEK": 6.463,
    "out:LCP/MSEK": 2.268,
    "out:ROI% old": 19.88,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1031,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 341320,
    "out:DH kr savings": 341320,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 234330,
    "out:% savings (space heating)": 58.74,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.74,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 84.6,
    "out:Total Energy Use Post PV": 84.6,
    "out:Primary Energy": 88.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.26,
    "out:CO2 Operational/m2": 28.72,
    "out:Total CO2/m2": 53.98,
    "out:Total CO2 (tons)": 173.81,
    "out:Klimatpaverkan": -45.02,
    "out:Initial Cost/MSEK": 5.876,
    "out:Running cost/(Apt SEK y)": 22159,
    "out:Running Cost over RSP/MSEK": 6.235,
    "out:LCP/MSEK": 2.415,
    "out:ROI% old": 20.16,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1060,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 350980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 350980,
    "out:DH kr savings": 350980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224557,
    "out:% savings (space heating)": 60.46,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.77,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 86.6,
    "out:Total Energy Use Post PV": 86.6,
    "out:Primary Energy": 89.26,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.61,
    "out:CO2 Operational/m2": 29.44,
    "out:Total CO2/m2": 55.05,
    "out:Total CO2 (tons)": 177.25,
    "out:Klimatpaverkan": -41.58,
    "out:Initial Cost/MSEK": 5.932,
    "out:Running cost/(Apt SEK y)": 22699,
    "out:Running Cost over RSP/MSEK": 6.387,
    "out:LCP/MSEK": 2.207,
    "out:ROI% old": 19.6,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1041,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 344540,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 344540,
    "out:DH kr savings": 344540,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 234330,
    "out:% savings (space heating)": 58.74,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.74,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 83.6,
    "out:Total Energy Use Post PV": 83.6,
    "out:Primary Energy": 86.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.61,
    "out:CO2 Operational/m2": 28.36,
    "out:Total CO2/m2": 53.97,
    "out:Total CO2 (tons)": 173.78,
    "out:Klimatpaverkan": -45.05,
    "out:Initial Cost/MSEK": 6.012,
    "out:Running cost/(Apt SEK y)": 21889,
    "out:Running Cost over RSP/MSEK": 6.159,
    "out:LCP/MSEK": 2.355,
    "out:ROI% old": 19.88,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1070,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 354200,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 354200,
    "out:DH kr savings": 354200,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224557,
    "out:% savings (space heating)": 60.46,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01002200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.77,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 87.6,
    "out:Total Energy Use Post PV": 84.91,
    "out:Primary Energy": 86.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.49,
    "out:CO2 Operational/m2": 5.32,
    "out:Total CO2/m2": 38.81,
    "out:Total CO2 (tons)": 124.98,
    "out:Klimatpaverkan": -93.85,
    "out:Initial Cost/MSEK": 6.242,
    "out:Running cost/(Apt SEK y)": 21849,
    "out:Running Cost over RSP/MSEK": 6.157,
    "out:LCP/MSEK": 2.128,
    "out:ROI% old": 19.16,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1071,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 381636,
    "out:DH kr savings": 341320,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 234330,
    "out:% savings (space heating)": 58.74,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.74,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 84.6,
    "out:Total Energy Use Post PV": 81.91,
    "out:Primary Energy": 83.86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.49,
    "out:CO2 Operational/m2": 4.24,
    "out:Total CO2/m2": 37.73,
    "out:Total CO2 (tons)": 121.5,
    "out:Klimatpaverkan": -97.33,
    "out:Initial Cost/MSEK": 6.322,
    "out:Running cost/(Apt SEK y)": 21039,
    "out:Running Cost over RSP/MSEK": 5.928,
    "out:LCP/MSEK": 2.275,
    "out:ROI% old": 19.43,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1100,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 350980,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 391296,
    "out:DH kr savings": 350980,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224557,
    "out:% savings (space heating)": 60.46,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.77,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 86.6,
    "out:Total Energy Use Post PV": 83.91,
    "out:Primary Energy": 84.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.84,
    "out:CO2 Operational/m2": 4.96,
    "out:Total CO2/m2": 38.8,
    "out:Total CO2 (tons)": 124.95,
    "out:Klimatpaverkan": -93.88,
    "out:Initial Cost/MSEK": 6.378,
    "out:Running cost/(Apt SEK y)": 21579,
    "out:Running Cost over RSP/MSEK": 6.08,
    "out:LCP/MSEK": 2.067,
    "out:ROI% old": 18.92,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 79,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1081,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 344540,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 384856,
    "out:DH kr savings": 344540,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 234330,
    "out:% savings (space heating)": 58.74,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.74,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 83.6,
    "out:Total Energy Use Post PV": 80.91,
    "out:Primary Energy": 81.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.84,
    "out:CO2 Operational/m2": 3.88,
    "out:Total CO2/m2": 37.72,
    "out:Total CO2 (tons)": 121.47,
    "out:Klimatpaverkan": -97.36,
    "out:Initial Cost/MSEK": 6.459,
    "out:Running cost/(Apt SEK y)": 20769,
    "out:Running Cost over RSP/MSEK": 5.852,
    "out:LCP/MSEK": 2.215,
    "out:ROI% old": 19.18,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 76,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1110,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 354200,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 394516,
    "out:DH kr savings": 354200,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224557,
    "out:% savings (space heating)": 60.46,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01002201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 80.84,
    "out:Total Energy Use Post PV": 80.85,
    "out:Primary Energy": 96.47,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": 25.72,
    "out:Total CO2/m2": 54.24,
    "out:Total CO2 (tons)": 174.64,
    "out:Klimatpaverkan": -44.19,
    "out:Initial Cost/MSEK": 6.311,
    "out:Running cost/(Apt SEK y)": 20483,
    "out:Running Cost over RSP/MSEK": 5.759,
    "out:LCP/MSEK": 2.456,
    "out:ROI% old": 19.85,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1120,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 339737,
    "out:DH kr savings": 386400,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 212723,
    "out:% savings (space heating)": 62.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 77.84,
    "out:Total Energy Use Post PV": 77.85,
    "out:Primary Energy": 93.82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": 24.64,
    "out:Total CO2/m2": 53.16,
    "out:Total CO2 (tons)": 171.16,
    "out:Klimatpaverkan": -47.67,
    "out:Initial Cost/MSEK": 6.391,
    "out:Running cost/(Apt SEK y)": 19673,
    "out:Running Cost over RSP/MSEK": 5.531,
    "out:LCP/MSEK": 2.604,
    "out:ROI% old": 20.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1149,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 396060,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 349397,
    "out:DH kr savings": 396060,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 202950,
    "out:% savings (space heating)": 64.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 79.83,
    "out:Total Energy Use Post PV": 79.85,
    "out:Primary Energy": 93.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.87,
    "out:CO2 Operational/m2": 25.36,
    "out:Total CO2/m2": 54.22,
    "out:Total CO2 (tons)": 174.6,
    "out:Klimatpaverkan": -44.23,
    "out:Initial Cost/MSEK": 6.447,
    "out:Running cost/(Apt SEK y)": 20211,
    "out:Running Cost over RSP/MSEK": 5.682,
    "out:LCP/MSEK": 2.396,
    "out:ROI% old": 19.6,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1130,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 342957,
    "out:DH kr savings": 389620,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 208221,
    "out:% savings (space heating)": 63.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 76.83,
    "out:Total Energy Use Post PV": 76.85,
    "out:Primary Energy": 91.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.87,
    "out:CO2 Operational/m2": 24.28,
    "out:Total CO2/m2": 53.14,
    "out:Total CO2 (tons)": 171.13,
    "out:Klimatpaverkan": -47.7,
    "out:Initial Cost/MSEK": 6.528,
    "out:Running cost/(Apt SEK y)": 19400,
    "out:Running Cost over RSP/MSEK": 5.454,
    "out:LCP/MSEK": 2.544,
    "out:ROI% old": 19.85,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 151.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 1038,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1159,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 399280,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 352617,
    "out:DH kr savings": 399280,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 198452,
    "out:% savings (space heating)": 65.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 80.84,
    "out:Total Energy Use Post PV": 76.5,
    "out:Primary Energy": 88.66,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.75,
    "out:CO2 Operational/m2": 9.53,
    "out:Total CO2/m2": 46.28,
    "out:Total CO2 (tons)": 149.02,
    "out:Klimatpaverkan": -69.81,
    "out:Initial Cost/MSEK": 6.757,
    "out:Running cost/(Apt SEK y)": 19289,
    "out:Running Cost over RSP/MSEK": 5.432,
    "out:LCP/MSEK": 2.337,
    "out:ROI% old": 19.23,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 151.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1163,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.55,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.07,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 382722,
    "out:DH kr savings": 386400,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 212723,
    "out:% savings (space heating)": 62.55,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 77.84,
    "out:Total Energy Use Post PV": 73.5,
    "out:Primary Energy": 86,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.75,
    "out:CO2 Operational/m2": 8.45,
    "out:Total CO2/m2": 45.2,
    "out:Total CO2 (tons)": 145.54,
    "out:Klimatpaverkan": -73.29,
    "out:Initial Cost/MSEK": 6.838,
    "out:Running cost/(Apt SEK y)": 18479,
    "out:Running Cost over RSP/MSEK": 5.204,
    "out:LCP/MSEK": 2.485,
    "out:ROI% old": 19.48,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 162.2,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1192,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.57,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.05,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 396060,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 392382,
    "out:DH kr savings": 396060,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 202950,
    "out:% savings (space heating)": 64.27,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 79.83,
    "out:Total Energy Use Post PV": 75.5,
    "out:Primary Energy": 86.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.1,
    "out:CO2 Operational/m2": 9.15,
    "out:Total CO2/m2": 46.26,
    "out:Total CO2 (tons)": 148.94,
    "out:Klimatpaverkan": -69.89,
    "out:Initial Cost/MSEK": 6.894,
    "out:Running cost/(Apt SEK y)": 19017,
    "out:Running Cost over RSP/MSEK": 5.355,
    "out:LCP/MSEK": 2.277,
    "out:ROI% old": 19,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 155.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.32,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.3,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 385954,
    "out:DH kr savings": 389620,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 208221,
    "out:% savings (space heating)": 63.34,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 76.83,
    "out:Total Energy Use Post PV": 72.5,
    "out:Primary Energy": 83.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.1,
    "out:CO2 Operational/m2": 8.07,
    "out:Total CO2/m2": 45.18,
    "out:Total CO2 (tons)": 145.46,
    "out:Klimatpaverkan": -73.37,
    "out:Initial Cost/MSEK": 6.974,
    "out:Running cost/(Apt SEK y)": 18207,
    "out:Running Cost over RSP/MSEK": 5.127,
    "out:LCP/MSEK": 2.425,
    "out:ROI% old": 19.25,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 73,
    "out:Energy use kWh/m2": 73,
    "out:Energy savings %": 165.8,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 741.5,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1202,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.59,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.03,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 399280,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 395614,
    "out:DH kr savings": 399280,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27944,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 198452,
    "out:% savings (space heating)": 65.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 78.82,
    "out:Total Energy Use Post PV": 78.85,
    "out:Primary Energy": 92.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": 25,
    "out:Total CO2/m2": 53.51,
    "out:Total CO2 (tons)": 172.31,
    "out:Klimatpaverkan": -46.52,
    "out:Initial Cost/MSEK": 6.406,
    "out:Running cost/(Apt SEK y)": 19940,
    "out:Running Cost over RSP/MSEK": 5.606,
    "out:LCP/MSEK": 2.514,
    "out:ROI% old": 19.89,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1140,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346177,
    "out:DH kr savings": 392840,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 206743,
    "out:% savings (space heating)": 63.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 75.82,
    "out:Total Energy Use Post PV": 75.85,
    "out:Primary Energy": 90.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.52,
    "out:CO2 Operational/m2": 23.92,
    "out:Total CO2/m2": 52.43,
    "out:Total CO2 (tons)": 168.84,
    "out:Klimatpaverkan": -49.99,
    "out:Initial Cost/MSEK": 6.486,
    "out:Running cost/(Apt SEK y)": 19129,
    "out:Running Cost over RSP/MSEK": 5.378,
    "out:LCP/MSEK": 2.662,
    "out:ROI% old": 20.15,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 155.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1169,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 402500,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 355837,
    "out:DH kr savings": 402500,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 196977,
    "out:% savings (space heating)": 65.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 77.8,
    "out:Total Energy Use Post PV": 77.85,
    "out:Primary Energy": 90.87,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.87,
    "out:CO2 Operational/m2": 24.63,
    "out:Total CO2/m2": 53.5,
    "out:Total CO2 (tons)": 172.27,
    "out:Klimatpaverkan": -46.56,
    "out:Initial Cost/MSEK": 6.542,
    "out:Running cost/(Apt SEK y)": 19666,
    "out:Running Cost over RSP/MSEK": 5.529,
    "out:LCP/MSEK": 2.455,
    "out:ROI% old": 19.65,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 1036.1,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1150,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 396060,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 349397,
    "out:DH kr savings": 396060,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203260,
    "out:% savings (space heating)": 64.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 74.8,
    "out:Total Energy Use Post PV": 74.85,
    "out:Primary Energy": 88.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.87,
    "out:CO2 Operational/m2": 23.55,
    "out:Total CO2/m2": 52.42,
    "out:Total CO2 (tons)": 168.8,
    "out:Klimatpaverkan": -50.03,
    "out:Initial Cost/MSEK": 6.623,
    "out:Running cost/(Apt SEK y)": 18855,
    "out:Running Cost over RSP/MSEK": 5.301,
    "out:LCP/MSEK": 2.603,
    "out:ROI% old": 19.9,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 1036,
    "out:Return %": 18,
    "out:Return/kSEK/y": 1179,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 405720,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 359057,
    "out:DH kr savings": 405720,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 193509,
    "out:% savings (space heating)": 65.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01002201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 78.82,
    "out:Total Energy Use Post PV": 74.5,
    "out:Primary Energy": 85.16,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.75,
    "out:CO2 Operational/m2": 8.79,
    "out:Total CO2/m2": 45.54,
    "out:Total CO2 (tons)": 146.63,
    "out:Klimatpaverkan": -72.2,
    "out:Initial Cost/MSEK": 6.852,
    "out:Running cost/(Apt SEK y)": 18746,
    "out:Running Cost over RSP/MSEK": 5.279,
    "out:LCP/MSEK": 2.395,
    "out:ROI% old": 19.28,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1183,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.23,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.39,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 389180,
    "out:DH kr savings": 392840,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 206743,
    "out:% savings (space heating)": 63.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 61.17,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 75.82,
    "out:Total Energy Use Post PV": 71.5,
    "out:Primary Energy": 82.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.75,
    "out:CO2 Operational/m2": 7.7,
    "out:Total CO2/m2": 44.46,
    "out:Total CO2 (tons)": 143.15,
    "out:Klimatpaverkan": -75.68,
    "out:Initial Cost/MSEK": 6.933,
    "out:Running cost/(Apt SEK y)": 17935,
    "out:Running Cost over RSP/MSEK": 5.051,
    "out:LCP/MSEK": 2.543,
    "out:ROI% old": 19.53,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 169.4,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 61,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1212,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.68,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13967.93,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 402500,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 398840,
    "out:DH kr savings": 402500,
    "out:El kr savings": -18698,
    "out:El kr sold": 15039,
    "out:El kr saved": 27936,
    "out:El kr return": 42974,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 196977,
    "out:% savings (space heating)": 65.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 77.8,
    "out:Total Energy Use Post PV": 73.5,
    "out:Primary Energy": 83.06,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.1,
    "out:CO2 Operational/m2": 8.4,
    "out:Total CO2/m2": 45.51,
    "out:Total CO2 (tons)": 146.53,
    "out:Klimatpaverkan": -72.3,
    "out:Initial Cost/MSEK": 6.989,
    "out:Running cost/(Apt SEK y)": 18472,
    "out:Running Cost over RSP/MSEK": 5.202,
    "out:LCP/MSEK": 2.335,
    "out:ROI% old": 19.06,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 162.2,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 63,
    "out:El bought/kWh/m2": 740,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1193,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10038.34,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13955.27,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 396060,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 392419,
    "out:DH kr savings": 396060,
    "out:El kr savings": -18698,
    "out:El kr sold": 15058,
    "out:El kr saved": 27911,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203260,
    "out:% savings (space heating)": 64.21,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 72.77,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01002201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 60.1,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 74.8,
    "out:Total Energy Use Post PV": 70.5,
    "out:Primary Energy": 80.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.1,
    "out:CO2 Operational/m2": 7.32,
    "out:Total CO2/m2": 44.42,
    "out:Total CO2 (tons)": 143.04,
    "out:Klimatpaverkan": -75.79,
    "out:Initial Cost/MSEK": 7.069,
    "out:Running cost/(Apt SEK y)": 17661,
    "out:Running Cost over RSP/MSEK": 4.973,
    "out:LCP/MSEK": 2.483,
    "out:ROI% old": 19.3,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 173.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1222,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10039.62,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 345.83,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 405720,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 402081,
    "out:DH kr savings": 405720,
    "out:El kr savings": -18698,
    "out:El kr sold": 15059,
    "out:El kr saved": 27908,
    "out:El kr return": 42967,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 193509,
    "out:% savings (space heating)": 65.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 69.74,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 142.6,
    "out:Primary Energy": 140.67,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 49.6,
    "out:Total CO2/m2": 64.26,
    "out:Total CO2 (tons)": 206.91,
    "out:Klimatpaverkan": -11.92,
    "out:Initial Cost/MSEK": 3.42,
    "out:Running cost/(Apt SEK y)": 37826,
    "out:Running Cost over RSP/MSEK": 10.647,
    "out:LCP/MSEK": 0.459,
    "out:ROI% old": 16.2,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 143,
    "out:Energy use kWh/m2": 143,
    "out:Energy savings %": 35.7,
    "out:Op energy cost/MSEK": 78,
    "out:El price/MSEK": 47,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 15,
    "out:Return/kSEK/y": 496,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 164220,
    "out:DH kr savings": 164220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 136.6,
    "out:Total Energy Use Post PV": 136.6,
    "out:Primary Energy": 135.93,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 47.44,
    "out:Total CO2/m2": 62.1,
    "out:Total CO2 (tons)": 199.96,
    "out:Klimatpaverkan": -18.87,
    "out:Initial Cost/MSEK": 3.501,
    "out:Running cost/(Apt SEK y)": 36205,
    "out:Running Cost over RSP/MSEK": 10.19,
    "out:LCP/MSEK": 0.835,
    "out:ROI% old": 17.69,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 554,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 183540,
    "out:DH kr savings": 183540,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 140.6,
    "out:Primary Energy": 138.05,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.01,
    "out:CO2 Operational/m2": 48.88,
    "out:Total CO2/m2": 63.89,
    "out:Total CO2 (tons)": 205.72,
    "out:Klimatpaverkan": -13.11,
    "out:Initial Cost/MSEK": 3.557,
    "out:Running cost/(Apt SEK y)": 37286,
    "out:Running Cost over RSP/MSEK": 10.495,
    "out:LCP/MSEK": 0.474,
    "out:ROI% old": 16.19,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 14,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170660,
    "out:DH kr savings": 170660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 135.6,
    "out:Total Energy Use Post PV": 135.6,
    "out:Primary Energy": 133.31,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.01,
    "out:CO2 Operational/m2": 47.08,
    "out:Total CO2/m2": 62.09,
    "out:Total CO2 (tons)": 199.92,
    "out:Klimatpaverkan": -18.91,
    "out:Initial Cost/MSEK": 3.637,
    "out:Running cost/(Apt SEK y)": 35935,
    "out:Running Cost over RSP/MSEK": 10.114,
    "out:LCP/MSEK": 0.774,
    "out:ROI% old": 17.33,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 136,
    "out:Energy use kWh/m2": 136,
    "out:Energy savings %": 42.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 564,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 186760,
    "out:DH kr savings": 186760,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 142.6,
    "out:Total Energy Use Post PV": 139.91,
    "out:Primary Energy": 135.84,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 22.89,
    "out:CO2 Operational/m2": 25.12,
    "out:Total CO2/m2": 48.01,
    "out:Total CO2 (tons)": 154.61,
    "out:Klimatpaverkan": -64.22,
    "out:Initial Cost/MSEK": 3.867,
    "out:Running cost/(Apt SEK y)": 36706,
    "out:Running Cost over RSP/MSEK": 10.34,
    "out:LCP/MSEK": 0.319,
    "out:ROI% old": 15.46,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 45,
    "out:Heating price/MSEK": 27,
    "out:DH+ DHW/kWh/m2": 135,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 536,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 164220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 204536,
    "out:DH kr savings": 164220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 136.6,
    "out:Total Energy Use Post PV": 133.91,
    "out:Primary Energy": 131.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.89,
    "out:CO2 Operational/m2": 22.96,
    "out:Total CO2/m2": 45.85,
    "out:Total CO2 (tons)": 147.65,
    "out:Klimatpaverkan": -71.18,
    "out:Initial Cost/MSEK": 3.947,
    "out:Running cost/(Apt SEK y)": 35085,
    "out:Running Cost over RSP/MSEK": 9.884,
    "out:LCP/MSEK": 0.695,
    "out:ROI% old": 16.8,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 129,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 595,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 183540,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 223856,
    "out:DH kr savings": 183540,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 137.91,
    "out:Primary Energy": 133.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.24,
    "out:CO2 Operational/m2": 24.4,
    "out:Total CO2/m2": 47.64,
    "out:Total CO2 (tons)": 153.42,
    "out:Klimatpaverkan": -65.41,
    "out:Initial Cost/MSEK": 4.003,
    "out:Running cost/(Apt SEK y)": 36166,
    "out:Running Cost over RSP/MSEK": 10.188,
    "out:LCP/MSEK": 0.335,
    "out:ROI% old": 15.48,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210976,
    "out:DH kr savings": 170660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 135.6,
    "out:Total Energy Use Post PV": 132.91,
    "out:Primary Energy": 128.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.24,
    "out:CO2 Operational/m2": 22.6,
    "out:Total CO2/m2": 45.84,
    "out:Total CO2 (tons)": 147.62,
    "out:Klimatpaverkan": -71.21,
    "out:Initial Cost/MSEK": 4.084,
    "out:Running cost/(Apt SEK y)": 34815,
    "out:Running Cost over RSP/MSEK": 9.807,
    "out:LCP/MSEK": 0.634,
    "out:ROI% old": 16.51,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 128,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 604,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 186760,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 227076,
    "out:DH kr savings": 186760,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 140.6,
    "out:Primary Energy": 137.17,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 48.88,
    "out:Total CO2/m2": 63.54,
    "out:Total CO2 (tons)": 204.59,
    "out:Klimatpaverkan": -14.24,
    "out:Initial Cost/MSEK": 3.515,
    "out:Running cost/(Apt SEK y)": 37286,
    "out:Running Cost over RSP/MSEK": 10.495,
    "out:LCP/MSEK": 0.516,
    "out:ROI% old": 16.38,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 141,
    "out:Energy use kWh/m2": 141,
    "out:Energy savings %": 37.6,
    "out:Op energy cost/MSEK": 77,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 15,
    "out:Return/kSEK/y": 515,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 170660,
    "out:DH kr savings": 170660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 134.6,
    "out:Total Energy Use Post PV": 134.6,
    "out:Primary Energy": 132.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.66,
    "out:CO2 Operational/m2": 46.72,
    "out:Total CO2/m2": 61.38,
    "out:Total CO2 (tons)": 197.64,
    "out:Klimatpaverkan": -21.19,
    "out:Initial Cost/MSEK": 3.596,
    "out:Running cost/(Apt SEK y)": 35665,
    "out:Running Cost over RSP/MSEK": 10.038,
    "out:LCP/MSEK": 0.892,
    "out:ROI% old": 17.83,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 135,
    "out:Energy use kWh/m2": 135,
    "out:Energy savings %": 43.7,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 574,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 189980,
    "out:DH kr savings": 189980,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 139.6,
    "out:Total Energy Use Post PV": 139.6,
    "out:Primary Energy": 135.07,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 15.01,
    "out:CO2 Operational/m2": 48.52,
    "out:Total CO2/m2": 63.53,
    "out:Total CO2 (tons)": 204.56,
    "out:Klimatpaverkan": -14.27,
    "out:Initial Cost/MSEK": 3.652,
    "out:Running cost/(Apt SEK y)": 37016,
    "out:Running Cost over RSP/MSEK": 10.419,
    "out:LCP/MSEK": 0.455,
    "out:ROI% old": 16.07,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 140,
    "out:Energy use kWh/m2": 140,
    "out:Energy savings %": 38.6,
    "out:Op energy cost/MSEK": 76,
    "out:El price/MSEK": 46,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 14,
    "out:Return/kSEK/y": 525,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 173880,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 173880,
    "out:DH kr savings": 173880,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 133.6,
    "out:Total Energy Use Post PV": 133.6,
    "out:Primary Energy": 130.33,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.01,
    "out:CO2 Operational/m2": 46.36,
    "out:Total CO2/m2": 61.37,
    "out:Total CO2 (tons)": 197.61,
    "out:Klimatpaverkan": -21.22,
    "out:Initial Cost/MSEK": 3.732,
    "out:Running cost/(Apt SEK y)": 35395,
    "out:Running Cost over RSP/MSEK": 9.962,
    "out:LCP/MSEK": 0.831,
    "out:ROI% old": 17.47,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 134,
    "out:Energy use kWh/m2": 134,
    "out:Energy savings %": 44.8,
    "out:Op energy cost/MSEK": 73,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 583,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 193200,
    "out:DH kr savings": 193200,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 140.6,
    "out:Total Energy Use Post PV": 137.91,
    "out:Primary Energy": 132.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.89,
    "out:CO2 Operational/m2": 24.4,
    "out:Total CO2/m2": 47.29,
    "out:Total CO2 (tons)": 152.29,
    "out:Klimatpaverkan": -66.54,
    "out:Initial Cost/MSEK": 3.962,
    "out:Running cost/(Apt SEK y)": 36166,
    "out:Running Cost over RSP/MSEK": 10.188,
    "out:LCP/MSEK": 0.376,
    "out:ROI% old": 15.64,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 138,
    "out:Energy use kWh/m2": 138,
    "out:Energy savings %": 40.6,
    "out:Op energy cost/MSEK": 75,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 133,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 556,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 170660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 210976,
    "out:DH kr savings": 170660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 134.6,
    "out:Total Energy Use Post PV": 131.91,
    "out:Primary Energy": 127.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 22.89,
    "out:CO2 Operational/m2": 22.24,
    "out:Total CO2/m2": 45.13,
    "out:Total CO2 (tons)": 145.33,
    "out:Klimatpaverkan": -73.5,
    "out:Initial Cost/MSEK": 4.042,
    "out:Running cost/(Apt SEK y)": 34545,
    "out:Running Cost over RSP/MSEK": 9.731,
    "out:LCP/MSEK": 0.752,
    "out:ROI% old": 16.94,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 72,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 127,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 614,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 189980,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 230296,
    "out:DH kr savings": 189980,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 125.13,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 139.6,
    "out:Total Energy Use Post PV": 136.91,
    "out:Primary Energy": 130.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.24,
    "out:CO2 Operational/m2": 24.04,
    "out:Total CO2/m2": 47.28,
    "out:Total CO2 (tons)": 152.26,
    "out:Klimatpaverkan": -66.57,
    "out:Initial Cost/MSEK": 4.098,
    "out:Running cost/(Apt SEK y)": 35896,
    "out:Running Cost over RSP/MSEK": 10.112,
    "out:LCP/MSEK": 0.316,
    "out:ROI% old": 15.39,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 137,
    "out:Energy use kWh/m2": 137,
    "out:Energy savings %": 41.6,
    "out:Op energy cost/MSEK": 74,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 26,
    "out:DH+ DHW/kWh/m2": 132,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 565,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 173880,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 214196,
    "out:DH kr savings": 173880,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 402930,
    "out:% savings (space heating)": 29.06,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 119.72,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 133.6,
    "out:Total Energy Use Post PV": 130.91,
    "out:Primary Energy": 125.5,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.24,
    "out:CO2 Operational/m2": 21.88,
    "out:Total CO2/m2": 45.12,
    "out:Total CO2 (tons)": 145.3,
    "out:Klimatpaverkan": -73.53,
    "out:Initial Cost/MSEK": 4.179,
    "out:Running cost/(Apt SEK y)": 34275,
    "out:Running Cost over RSP/MSEK": 9.655,
    "out:LCP/MSEK": 0.692,
    "out:ROI% old": 16.65,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 131,
    "out:Energy use kWh/m2": 131,
    "out:Energy savings %": 48.1,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 25,
    "out:DH+ DHW/kWh/m2": 126,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 193200,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233516,
    "out:DH kr savings": 193200,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 385494,
    "out:% savings (space heating)": 32.13,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 132.84,
    "out:Total Energy Use Post PV": 132.85,
    "out:Primary Energy": 142.29,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 44.44,
    "out:Total CO2/m2": 62.36,
    "out:Total CO2 (tons)": 200.79,
    "out:Klimatpaverkan": -18.04,
    "out:Initial Cost/MSEK": 4.031,
    "out:Running cost/(Apt SEK y)": 34530,
    "out:Running Cost over RSP/MSEK": 9.714,
    "out:LCP/MSEK": 0.781,
    "out:ROI% old": 17.05,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 133,
    "out:Energy use kWh/m2": 133,
    "out:Energy savings %": 45.9,
    "out:Op energy cost/MSEK": 71,
    "out:El price/MSEK": 44,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 15,
    "out:Return/kSEK/y": 615,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 172297,
    "out:DH kr savings": 218960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 381320,
    "out:% savings (space heating)": 32.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 127.84,
    "out:Total Energy Use Post PV": 127.85,
    "out:Primary Energy": 137.55,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 42.64,
    "out:Total CO2/m2": 60.56,
    "out:Total CO2 (tons)": 194.99,
    "out:Klimatpaverkan": -23.84,
    "out:Initial Cost/MSEK": 4.111,
    "out:Running cost/(Apt SEK y)": 33179,
    "out:Running Cost over RSP/MSEK": 9.334,
    "out:LCP/MSEK": 1.081,
    "out:ROI% old": 18.04,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 16,
    "out:Return/kSEK/y": 663,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 188397,
    "out:DH kr savings": 235060,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 363883,
    "out:% savings (space heating)": 35.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 131.83,
    "out:Total Energy Use Post PV": 131.85,
    "out:Primary Energy": 139.66,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.27,
    "out:CO2 Operational/m2": 44.08,
    "out:Total CO2/m2": 62.35,
    "out:Total CO2 (tons)": 200.75,
    "out:Klimatpaverkan": -18.08,
    "out:Initial Cost/MSEK": 4.168,
    "out:Running cost/(Apt SEK y)": 34257,
    "out:Running Cost over RSP/MSEK": 9.638,
    "out:LCP/MSEK": 0.721,
    "out:ROI% old": 16.76,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175517,
    "out:DH kr savings": 222180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 376803,
    "out:% savings (space heating)": 33.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 126.83,
    "out:Total Energy Use Post PV": 126.85,
    "out:Primary Energy": 134.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.27,
    "out:CO2 Operational/m2": 42.28,
    "out:Total CO2/m2": 60.55,
    "out:Total CO2 (tons)": 194.96,
    "out:Klimatpaverkan": -23.87,
    "out:Initial Cost/MSEK": 4.248,
    "out:Running cost/(Apt SEK y)": 32907,
    "out:Running Cost over RSP/MSEK": 9.257,
    "out:LCP/MSEK": 1.021,
    "out:ROI% old": 17.72,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 673,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 238280,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 191617,
    "out:DH kr savings": 238280,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 359370,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 118.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 132.84,
    "out:Total Energy Use Post PV": 128.5,
    "out:Primary Energy": 134.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 28.25,
    "out:Total CO2/m2": 54.4,
    "out:Total CO2 (tons)": 175.17,
    "out:Klimatpaverkan": -43.66,
    "out:Initial Cost/MSEK": 4.478,
    "out:Running cost/(Apt SEK y)": 33336,
    "out:Running Cost over RSP/MSEK": 9.387,
    "out:LCP/MSEK": 0.661,
    "out:ROI% old": 16.4,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 118,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 658,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.36,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.26,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 218960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215282,
    "out:DH kr savings": 218960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27961,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 381320,
    "out:% savings (space heating)": 32.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 113.01,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 127.84,
    "out:Total Energy Use Post PV": 123.5,
    "out:Primary Energy": 129.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 26.45,
    "out:Total CO2/m2": 52.6,
    "out:Total CO2 (tons)": 169.37,
    "out:Klimatpaverkan": -49.46,
    "out:Initial Cost/MSEK": 4.558,
    "out:Running cost/(Apt SEK y)": 31985,
    "out:Running Cost over RSP/MSEK": 9.007,
    "out:LCP/MSEK": 0.961,
    "out:ROI% old": 17.3,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 706,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.37,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.25,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231382,
    "out:DH kr savings": 235060,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 363883,
    "out:% savings (space heating)": 35.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 117.02,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 131.83,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 131.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 27.87,
    "out:Total CO2/m2": 54.38,
    "out:Total CO2 (tons)": 175.1,
    "out:Klimatpaverkan": -43.73,
    "out:Initial Cost/MSEK": 4.614,
    "out:Running cost/(Apt SEK y)": 33064,
    "out:Running Cost over RSP/MSEK": 9.31,
    "out:LCP/MSEK": 0.601,
    "out:ROI% old": 16.15,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 14,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.27,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.34,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218512,
    "out:DH kr savings": 222180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 376803,
    "out:% savings (space heating)": 33.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 126.83,
    "out:Total Energy Use Post PV": 122.5,
    "out:Primary Energy": 127.11,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 26.07,
    "out:Total CO2/m2": 52.58,
    "out:Total CO2 (tons)": 169.3,
    "out:Klimatpaverkan": -49.53,
    "out:Initial Cost/MSEK": 4.695,
    "out:Running cost/(Apt SEK y)": 31713,
    "out:Running Cost over RSP/MSEK": 8.93,
    "out:LCP/MSEK": 0.901,
    "out:ROI% old": 17.03,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 112,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 716,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.5,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 238280,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 234612,
    "out:DH kr savings": 238280,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 359370,
    "out:% savings (space heating)": 36.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 131.83,
    "out:Total Energy Use Post PV": 131.85,
    "out:Primary Energy": 138.79,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 44.08,
    "out:Total CO2/m2": 61.99,
    "out:Total CO2 (tons)": 199.62,
    "out:Klimatpaverkan": -19.21,
    "out:Initial Cost/MSEK": 4.126,
    "out:Running cost/(Apt SEK y)": 34256,
    "out:Running Cost over RSP/MSEK": 9.637,
    "out:LCP/MSEK": 0.763,
    "out:ROI% old": 16.93,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 132,
    "out:Energy use kWh/m2": 132,
    "out:Energy savings %": 47,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 15,
    "out:Return/kSEK/y": 624,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 175517,
    "out:DH kr savings": 222180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 375316,
    "out:% savings (space heating)": 33.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 125.82,
    "out:Total Energy Use Post PV": 125.85,
    "out:Primary Energy": 134.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.92,
    "out:CO2 Operational/m2": 41.92,
    "out:Total CO2/m2": 59.83,
    "out:Total CO2 (tons)": 192.67,
    "out:Klimatpaverkan": -26.16,
    "out:Initial Cost/MSEK": 4.207,
    "out:Running cost/(Apt SEK y)": 32636,
    "out:Running Cost over RSP/MSEK": 9.181,
    "out:LCP/MSEK": 1.138,
    "out:ROI% old": 18.15,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 54,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 16,
    "out:Return/kSEK/y": 683,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 194837,
    "out:DH kr savings": 241500,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357886,
    "out:% savings (space heating)": 36.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 129.81,
    "out:Total Energy Use Post PV": 129.85,
    "out:Primary Energy": 136.69,
    "out:Energy Class": "F",
    "out:CO2 Embodied/m2": 18.27,
    "out:CO2 Operational/m2": 43.35,
    "out:Total CO2/m2": 61.62,
    "out:Total CO2 (tons)": 198.43,
    "out:Klimatpaverkan": -20.4,
    "out:Initial Cost/MSEK": 4.263,
    "out:Running cost/(Apt SEK y)": 33713,
    "out:Running Cost over RSP/MSEK": 9.484,
    "out:LCP/MSEK": 0.779,
    "out:ROI% old": 16.9,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 130,
    "out:Energy use kWh/m2": 130,
    "out:Energy savings %": 49.2,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 43,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 15,
    "out:Return/kSEK/y": 644,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 228620,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 181957,
    "out:DH kr savings": 228620,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 371804,
    "out:% savings (space heating)": 34.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 124.81,
    "out:Total Energy Use Post PV": 124.85,
    "out:Primary Energy": 131.95,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.27,
    "out:CO2 Operational/m2": 41.55,
    "out:Total CO2/m2": 59.82,
    "out:Total CO2 (tons)": 192.63,
    "out:Klimatpaverkan": -26.2,
    "out:Initial Cost/MSEK": 4.343,
    "out:Running cost/(Apt SEK y)": 32362,
    "out:Running Cost over RSP/MSEK": 9.104,
    "out:LCP/MSEK": 1.079,
    "out:ROI% old": 17.83,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 1036.4,
    "out:Return %": 16,
    "out:Return/kSEK/y": 693,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 244720,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 198057,
    "out:DH kr savings": 244720,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 354387,
    "out:% savings (space heating)": 37.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 116.56,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 131.83,
    "out:Total Energy Use Post PV": 127.5,
    "out:Primary Energy": 130.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 27.87,
    "out:Total CO2/m2": 54.02,
    "out:Total CO2 (tons)": 173.95,
    "out:Klimatpaverkan": -44.88,
    "out:Initial Cost/MSEK": 4.573,
    "out:Running cost/(Apt SEK y)": 33063,
    "out:Running Cost over RSP/MSEK": 9.31,
    "out:LCP/MSEK": 0.643,
    "out:ROI% old": 16.3,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 128,
    "out:Energy use kWh/m2": 128,
    "out:Energy savings %": 51.6,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 667,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.68,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.93,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 218517,
    "out:DH kr savings": 222180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 375316,
    "out:% savings (space heating)": 33.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 111.14,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 125.82,
    "out:Total Energy Use Post PV": 121.5,
    "out:Primary Energy": 126.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.16,
    "out:CO2 Operational/m2": 25.71,
    "out:Total CO2/m2": 51.86,
    "out:Total CO2 (tons)": 166.99,
    "out:Klimatpaverkan": -51.84,
    "out:Initial Cost/MSEK": 4.653,
    "out:Running cost/(Apt SEK y)": 31442,
    "out:Running Cost over RSP/MSEK": 8.854,
    "out:LCP/MSEK": 1.019,
    "out:ROI% old": 17.41,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 726,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.09,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.53,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 237838,
    "out:DH kr savings": 241500,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357886,
    "out:% savings (space heating)": 36.99,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 115.47,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 129.81,
    "out:Total Energy Use Post PV": 125.5,
    "out:Primary Energy": 128.87,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 27.13,
    "out:Total CO2/m2": 53.63,
    "out:Total CO2 (tons)": 172.7,
    "out:Klimatpaverkan": -46.13,
    "out:Initial Cost/MSEK": 4.709,
    "out:Running cost/(Apt SEK y)": 32519,
    "out:Running Cost over RSP/MSEK": 9.157,
    "out:LCP/MSEK": 0.66,
    "out:ROI% old": 16.29,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 54,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 15,
    "out:Return/kSEK/y": 687,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.11,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.5,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 228620,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 224974,
    "out:DH kr savings": 228620,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 371804,
    "out:% savings (space heating)": 34.54,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 125.13,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 124.81,
    "out:Total Energy Use Post PV": 120.5,
    "out:Primary Energy": 124.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.51,
    "out:CO2 Operational/m2": 25.32,
    "out:Total CO2/m2": 51.83,
    "out:Total CO2 (tons)": 166.89,
    "out:Klimatpaverkan": -51.94,
    "out:Initial Cost/MSEK": 4.79,
    "out:Running cost/(Apt SEK y)": 31168,
    "out:Running Cost over RSP/MSEK": 8.777,
    "out:LCP/MSEK": 0.959,
    "out:ROI% old": 17.15,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 1.02,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 110,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 736,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10036.24,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.38,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 244720,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241076,
    "out:DH kr savings": 244720,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27915,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 354387,
    "out:% savings (space heating)": 37.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 119.72,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 128.6,
    "out:Total Energy Use Post PV": 128.6,
    "out:Primary Energy": 128.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 44.56,
    "out:Total CO2/m2": 61.74,
    "out:Total CO2 (tons)": 198.79,
    "out:Klimatpaverkan": -20.04,
    "out:Initial Cost/MSEK": 4.028,
    "out:Running cost/(Apt SEK y)": 34044,
    "out:Running Cost over RSP/MSEK": 9.582,
    "out:LCP/MSEK": 0.917,
    "out:ROI% old": 17.54,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 129,
    "out:Energy use kWh/m2": 129,
    "out:Energy savings %": 50.4,
    "out:Op energy cost/MSEK": 70,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 632,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 209300,
    "out:DH kr savings": 209300,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 123.6,
    "out:Total Energy Use Post PV": 123.6,
    "out:Primary Energy": 124.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 42.76,
    "out:Total CO2/m2": 59.94,
    "out:Total CO2 (tons)": 192.99,
    "out:Klimatpaverkan": -25.84,
    "out:Initial Cost/MSEK": 4.108,
    "out:Running cost/(Apt SEK y)": 32694,
    "out:Running Cost over RSP/MSEK": 9.202,
    "out:LCP/MSEK": 1.216,
    "out:ROI% old": 18.52,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 225400,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 225400,
    "out:DH kr savings": 225400,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 126.6,
    "out:Primary Energy": 125.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 43.84,
    "out:Total CO2/m2": 61.37,
    "out:Total CO2 (tons)": 197.6,
    "out:Klimatpaverkan": -21.23,
    "out:Initial Cost/MSEK": 4.164,
    "out:Running cost/(Apt SEK y)": 33504,
    "out:Running Cost over RSP/MSEK": 9.43,
    "out:LCP/MSEK": 0.932,
    "out:ROI% old": 17.48,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215740,
    "out:DH kr savings": 215740,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 121.6,
    "out:Primary Energy": 121.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 42.04,
    "out:Total CO2/m2": 59.57,
    "out:Total CO2 (tons)": 191.8,
    "out:Klimatpaverkan": -27.03,
    "out:Initial Cost/MSEK": 4.245,
    "out:Running cost/(Apt SEK y)": 32154,
    "out:Running Cost over RSP/MSEK": 9.049,
    "out:LCP/MSEK": 1.232,
    "out:ROI% old": 18.43,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231840,
    "out:DH kr savings": 231840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 128.6,
    "out:Total Energy Use Post PV": 125.91,
    "out:Primary Energy": 123.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.41,
    "out:CO2 Operational/m2": 20.08,
    "out:Total CO2/m2": 45.49,
    "out:Total CO2 (tons)": 146.49,
    "out:Klimatpaverkan": -72.34,
    "out:Initial Cost/MSEK": 4.474,
    "out:Running cost/(Apt SEK y)": 32924,
    "out:Running Cost over RSP/MSEK": 9.275,
    "out:LCP/MSEK": 0.777,
    "out:ROI% old": 16.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 126,
    "out:Energy use kWh/m2": 126,
    "out:Energy savings %": 54,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 24,
    "out:DH+ DHW/kWh/m2": 121,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 672,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 209300,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 249616,
    "out:DH kr savings": 209300,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 123.6,
    "out:Total Energy Use Post PV": 120.91,
    "out:Primary Energy": 119.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.41,
    "out:CO2 Operational/m2": 18.28,
    "out:Total CO2/m2": 43.69,
    "out:Total CO2 (tons)": 140.69,
    "out:Klimatpaverkan": -78.14,
    "out:Initial Cost/MSEK": 4.555,
    "out:Running cost/(Apt SEK y)": 31574,
    "out:Running Cost over RSP/MSEK": 8.895,
    "out:LCP/MSEK": 1.077,
    "out:ROI% old": 17.66,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 225400,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 265716,
    "out:DH kr savings": 225400,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 123.91,
    "out:Primary Energy": 120.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.76,
    "out:CO2 Operational/m2": 19.36,
    "out:Total CO2/m2": 45.12,
    "out:Total CO2 (tons)": 145.3,
    "out:Klimatpaverkan": -73.53,
    "out:Initial Cost/MSEK": 4.611,
    "out:Running cost/(Apt SEK y)": 32384,
    "out:Running Cost over RSP/MSEK": 9.123,
    "out:LCP/MSEK": 0.792,
    "out:ROI% old": 16.74,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 692,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256056,
    "out:DH kr savings": 215740,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 118.91,
    "out:Primary Energy": 116.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.76,
    "out:CO2 Operational/m2": 17.56,
    "out:Total CO2/m2": 43.32,
    "out:Total CO2 (tons)": 139.5,
    "out:Klimatpaverkan": -79.33,
    "out:Initial Cost/MSEK": 4.691,
    "out:Running cost/(Apt SEK y)": 31034,
    "out:Running Cost over RSP/MSEK": 8.743,
    "out:LCP/MSEK": 1.092,
    "out:ROI% old": 17.61,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 740,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272156,
    "out:DH kr savings": 231840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 126.6,
    "out:Primary Energy": 124.72,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 43.84,
    "out:Total CO2/m2": 61.02,
    "out:Total CO2 (tons)": 196.47,
    "out:Klimatpaverkan": -22.36,
    "out:Initial Cost/MSEK": 4.123,
    "out:Running cost/(Apt SEK y)": 33504,
    "out:Running Cost over RSP/MSEK": 9.43,
    "out:LCP/MSEK": 0.974,
    "out:ROI% old": 17.66,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 127,
    "out:Energy use kWh/m2": 127,
    "out:Energy savings %": 52.8,
    "out:Op energy cost/MSEK": 69,
    "out:El price/MSEK": 42,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 652,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 215740,
    "out:DH kr savings": 215740,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 121.6,
    "out:Primary Energy": 120.55,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.17,
    "out:CO2 Operational/m2": 42.04,
    "out:Total CO2/m2": 59.22,
    "out:Total CO2 (tons)": 190.68,
    "out:Klimatpaverkan": -28.15,
    "out:Initial Cost/MSEK": 4.203,
    "out:Running cost/(Apt SEK y)": 32154,
    "out:Running Cost over RSP/MSEK": 9.049,
    "out:LCP/MSEK": 1.273,
    "out:ROI% old": 18.61,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 231840,
    "out:DH kr savings": 231840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 124.6,
    "out:Total Energy Use Post PV": 124.6,
    "out:Primary Energy": 122.62,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 43.12,
    "out:Total CO2/m2": 60.65,
    "out:Total CO2 (tons)": 195.28,
    "out:Klimatpaverkan": -23.55,
    "out:Initial Cost/MSEK": 4.259,
    "out:Running cost/(Apt SEK y)": 32964,
    "out:Running Cost over RSP/MSEK": 9.278,
    "out:LCP/MSEK": 0.989,
    "out:ROI% old": 17.6,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222180,
    "out:DH kr savings": 222180,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 120.6,
    "out:Total Energy Use Post PV": 120.6,
    "out:Primary Energy": 118.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.53,
    "out:CO2 Operational/m2": 41.68,
    "out:Total CO2/m2": 59.21,
    "out:Total CO2 (tons)": 190.65,
    "out:Klimatpaverkan": -28.18,
    "out:Initial Cost/MSEK": 4.34,
    "out:Running cost/(Apt SEK y)": 31883,
    "out:Running Cost over RSP/MSEK": 8.973,
    "out:LCP/MSEK": 1.213,
    "out:ROI% old": 18.28,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 235060,
    "out:DH kr savings": 235060,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 126.6,
    "out:Total Energy Use Post PV": 123.91,
    "out:Primary Energy": 119.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.41,
    "out:CO2 Operational/m2": 19.36,
    "out:Total CO2/m2": 44.77,
    "out:Total CO2 (tons)": 144.17,
    "out:Klimatpaverkan": -74.66,
    "out:Initial Cost/MSEK": 4.569,
    "out:Running cost/(Apt SEK y)": 32384,
    "out:Running Cost over RSP/MSEK": 9.123,
    "out:LCP/MSEK": 0.834,
    "out:ROI% old": 16.89,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 119,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 692,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 215740,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 256056,
    "out:DH kr savings": 215740,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 118.91,
    "out:Primary Energy": 115.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.41,
    "out:CO2 Operational/m2": 17.56,
    "out:Total CO2/m2": 42.97,
    "out:Total CO2 (tons)": 138.37,
    "out:Klimatpaverkan": -80.46,
    "out:Initial Cost/MSEK": 4.65,
    "out:Running cost/(Apt SEK y)": 31034,
    "out:Running Cost over RSP/MSEK": 8.743,
    "out:LCP/MSEK": 1.134,
    "out:ROI% old": 17.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 740,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 272156,
    "out:DH kr savings": 231840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 110.9,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 124.6,
    "out:Total Energy Use Post PV": 121.91,
    "out:Primary Energy": 117.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.76,
    "out:CO2 Operational/m2": 18.64,
    "out:Total CO2/m2": 44.4,
    "out:Total CO2 (tons)": 142.98,
    "out:Klimatpaverkan": -75.85,
    "out:Initial Cost/MSEK": 4.706,
    "out:Running cost/(Apt SEK y)": 31844,
    "out:Running Cost over RSP/MSEK": 8.971,
    "out:LCP/MSEK": 0.849,
    "out:ROI% old": 16.86,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 711,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262496,
    "out:DH kr savings": 222180,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 357114,
    "out:% savings (space heating)": 37.12,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 106.14,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 120.6,
    "out:Total Energy Use Post PV": 117.91,
    "out:Primary Energy": 113.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 25.76,
    "out:CO2 Operational/m2": 17.2,
    "out:Total CO2/m2": 42.96,
    "out:Total CO2 (tons)": 138.34,
    "out:Klimatpaverkan": -80.49,
    "out:Initial Cost/MSEK": 4.786,
    "out:Running cost/(Apt SEK y)": 30764,
    "out:Running Cost over RSP/MSEK": 8.667,
    "out:LCP/MSEK": 1.073,
    "out:ROI% old": 17.49,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 64.4,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 750,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275376,
    "out:DH kr savings": 235060,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 341760,
    "out:% savings (space heating)": 39.83,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 118.84,
    "out:Total Energy Use Post PV": 118.85,
    "out:Primary Energy": 129.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.44,
    "out:CO2 Operational/m2": 39.4,
    "out:Total CO2/m2": 59.84,
    "out:Total CO2 (tons)": 192.67,
    "out:Klimatpaverkan": -26.16,
    "out:Initial Cost/MSEK": 4.638,
    "out:Running cost/(Apt SEK y)": 30748,
    "out:Running Cost over RSP/MSEK": 8.649,
    "out:LCP/MSEK": 1.238,
    "out:ROI% old": 18.1,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 751,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 217377,
    "out:DH kr savings": 264040,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 335507,
    "out:% savings (space heating)": 40.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 113.84,
    "out:Total Energy Use Post PV": 113.85,
    "out:Primary Energy": 125.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.44,
    "out:CO2 Operational/m2": 37.6,
    "out:Total CO2/m2": 58.04,
    "out:Total CO2 (tons)": 186.87,
    "out:Klimatpaverkan": -31.96,
    "out:Initial Cost/MSEK": 4.719,
    "out:Running cost/(Apt SEK y)": 29397,
    "out:Running Cost over RSP/MSEK": 8.269,
    "out:LCP/MSEK": 1.538,
    "out:ROI% old": 18.94,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 233477,
    "out:DH kr savings": 280140,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 320153,
    "out:% savings (space heating)": 43.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 117.83,
    "out:Total Energy Use Post PV": 117.85,
    "out:Primary Energy": 127.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.79,
    "out:CO2 Operational/m2": 39.04,
    "out:Total CO2/m2": 59.82,
    "out:Total CO2 (tons)": 192.63,
    "out:Klimatpaverkan": -26.2,
    "out:Initial Cost/MSEK": 4.775,
    "out:Running cost/(Apt SEK y)": 30475,
    "out:Running Cost over RSP/MSEK": 8.573,
    "out:LCP/MSEK": 1.178,
    "out:ROI% old": 17.81,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 64.4,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 761,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 220597,
    "out:DH kr savings": 267260,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331002,
    "out:% savings (space heating)": 41.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 112.83,
    "out:Total Energy Use Post PV": 112.85,
    "out:Primary Energy": 123.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.79,
    "out:CO2 Operational/m2": 37.24,
    "out:Total CO2/m2": 58.02,
    "out:Total CO2 (tons)": 186.84,
    "out:Klimatpaverkan": -31.99,
    "out:Initial Cost/MSEK": 4.855,
    "out:Running cost/(Apt SEK y)": 29125,
    "out:Running Cost over RSP/MSEK": 8.192,
    "out:LCP/MSEK": 1.478,
    "out:ROI% old": 18.64,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 71.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1038,
    "out:Return %": 17,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236697,
    "out:DH kr savings": 283360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 315652,
    "out:% savings (space heating)": 44.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 104.19,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 118.84,
    "out:Total Energy Use Post PV": 114.5,
    "out:Primary Energy": 122.02,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.67,
    "out:CO2 Operational/m2": 23.21,
    "out:Total CO2/m2": 51.88,
    "out:Total CO2 (tons)": 167.05,
    "out:Klimatpaverkan": -51.78,
    "out:Initial Cost/MSEK": 5.085,
    "out:Running cost/(Apt SEK y)": 29554,
    "out:Running Cost over RSP/MSEK": 8.322,
    "out:LCP/MSEK": 1.119,
    "out:ROI% old": 17.43,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 115,
    "out:Energy use kWh/m2": 115,
    "out:Energy savings %": 68.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 794,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.54,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.08,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260362,
    "out:DH kr savings": 264040,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 335507,
    "out:% savings (space heating)": 40.93,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 113.84,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 117.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.67,
    "out:CO2 Operational/m2": 21.41,
    "out:Total CO2/m2": 50.08,
    "out:Total CO2 (tons)": 161.25,
    "out:Klimatpaverkan": -57.58,
    "out:Initial Cost/MSEK": 5.165,
    "out:Running cost/(Apt SEK y)": 28203,
    "out:Running Cost over RSP/MSEK": 7.942,
    "out:LCP/MSEK": 1.419,
    "out:ROI% old": 18.21,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.55,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.06,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276462,
    "out:DH kr savings": 280140,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 320153,
    "out:% savings (space heating)": 43.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.8,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 117.83,
    "out:Total Energy Use Post PV": 113.5,
    "out:Primary Energy": 119.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 22.83,
    "out:Total CO2/m2": 51.86,
    "out:Total CO2 (tons)": 166.97,
    "out:Klimatpaverkan": -51.86,
    "out:Initial Cost/MSEK": 5.221,
    "out:Running cost/(Apt SEK y)": 29282,
    "out:Running Cost over RSP/MSEK": 8.246,
    "out:LCP/MSEK": 1.059,
    "out:ROI% old": 17.18,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 15,
    "out:Return/kSEK/y": 804,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.17,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.45,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 263593,
    "out:DH kr savings": 267260,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331002,
    "out:% savings (space heating)": 41.72,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 112.83,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 115.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 21.03,
    "out:Total CO2/m2": 50.05,
    "out:Total CO2 (tons)": 161.18,
    "out:Klimatpaverkan": -57.65,
    "out:Initial Cost/MSEK": 5.302,
    "out:Running cost/(Apt SEK y)": 27931,
    "out:Running Cost over RSP/MSEK": 7.865,
    "out:LCP/MSEK": 1.359,
    "out:ROI% old": 17.95,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.43,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.19,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279694,
    "out:DH kr savings": 283360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27944,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 315652,
    "out:% savings (space heating)": 44.42,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 116.82,
    "out:Total Energy Use Post PV": 116.85,
    "out:Primary Energy": 126.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.44,
    "out:CO2 Operational/m2": 38.68,
    "out:Total CO2/m2": 59.11,
    "out:Total CO2 (tons)": 190.34,
    "out:Klimatpaverkan": -28.49,
    "out:Initial Cost/MSEK": 4.733,
    "out:Running cost/(Apt SEK y)": 30204,
    "out:Running Cost over RSP/MSEK": 8.496,
    "out:LCP/MSEK": 1.296,
    "out:ROI% old": 18.2,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 65.8,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 770,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 223817,
    "out:DH kr savings": 270480,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 329522,
    "out:% savings (space heating)": 41.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 112.82,
    "out:Total Energy Use Post PV": 112.85,
    "out:Primary Energy": 122.17,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.44,
    "out:CO2 Operational/m2": 37.24,
    "out:Total CO2/m2": 57.67,
    "out:Total CO2 (tons)": 185.71,
    "out:Klimatpaverkan": -33.12,
    "out:Initial Cost/MSEK": 4.814,
    "out:Running cost/(Apt SEK y)": 29124,
    "out:Running Cost over RSP/MSEK": 8.192,
    "out:LCP/MSEK": 1.52,
    "out:ROI% old": 18.8,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 71.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 236697,
    "out:DH kr savings": 283360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 314175,
    "out:% savings (space heating)": 44.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 115.8,
    "out:Total Energy Use Post PV": 115.85,
    "out:Primary Energy": 124.24,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.79,
    "out:CO2 Operational/m2": 38.31,
    "out:Total CO2/m2": 59.1,
    "out:Total CO2 (tons)": 190.3,
    "out:Klimatpaverkan": -28.53,
    "out:Initial Cost/MSEK": 4.87,
    "out:Running cost/(Apt SEK y)": 29930,
    "out:Running Cost over RSP/MSEK": 8.419,
    "out:LCP/MSEK": 1.237,
    "out:ROI% old": 17.91,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 67.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1036.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 780,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227037,
    "out:DH kr savings": 273700,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 326032,
    "out:% savings (space heating)": 42.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 110.8,
    "out:Total Energy Use Post PV": 110.85,
    "out:Primary Energy": 120.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.79,
    "out:CO2 Operational/m2": 36.51,
    "out:Total CO2/m2": 57.3,
    "out:Total CO2 (tons)": 184.51,
    "out:Klimatpaverkan": -34.32,
    "out:Initial Cost/MSEK": 4.95,
    "out:Running cost/(Apt SEK y)": 28579,
    "out:Running Cost over RSP/MSEK": 8.039,
    "out:LCP/MSEK": 1.537,
    "out:ROI% old": 18.72,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1036.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 829,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243137,
    "out:DH kr savings": 289800,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 310699,
    "out:% savings (space heating)": 45.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.34,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 116.82,
    "out:Total Energy Use Post PV": 112.5,
    "out:Primary Energy": 118.52,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.67,
    "out:CO2 Operational/m2": 22.46,
    "out:Total CO2/m2": 51.14,
    "out:Total CO2 (tons)": 164.67,
    "out:Klimatpaverkan": -54.16,
    "out:Initial Cost/MSEK": 5.18,
    "out:Running cost/(Apt SEK y)": 29010,
    "out:Running Cost over RSP/MSEK": 8.169,
    "out:LCP/MSEK": 1.177,
    "out:ROI% old": 17.53,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 71.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 813,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.97,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.64,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 266819,
    "out:DH kr savings": 270480,
    "out:El kr savings": -18698,
    "out:El kr sold": 15037,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 329522,
    "out:% savings (space heating)": 41.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 112.82,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 114.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 28.67,
    "out:CO2 Operational/m2": 21.02,
    "out:Total CO2/m2": 49.7,
    "out:Total CO2 (tons)": 160.03,
    "out:Klimatpaverkan": -58.8,
    "out:Initial Cost/MSEK": 5.26,
    "out:Running cost/(Apt SEK y)": 27930,
    "out:Running Cost over RSP/MSEK": 7.865,
    "out:LCP/MSEK": 1.401,
    "out:ROI% old": 18.09,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.42,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.2,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 279700,
    "out:DH kr savings": 283360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27936,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 314175,
    "out:% savings (space heating)": 44.68,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 101.25,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 115.8,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 116.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 22.08,
    "out:Total CO2/m2": 51.11,
    "out:Total CO2 (tons)": 164.56,
    "out:Klimatpaverkan": -54.27,
    "out:Initial Cost/MSEK": 5.316,
    "out:Running cost/(Apt SEK y)": 28737,
    "out:Running Cost over RSP/MSEK": 8.092,
    "out:LCP/MSEK": 1.117,
    "out:ROI% old": 17.29,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 73.2,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 740,
    "out:Return %": 15,
    "out:Return/kSEK/y": 823,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10037.68,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13955.94,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270058,
    "out:DH kr savings": 273700,
    "out:El kr savings": -18698,
    "out:El kr sold": 15057,
    "out:El kr saved": 27912,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 326032,
    "out:% savings (space heating)": 42.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 110.9,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 110.8,
    "out:Total Energy Use Post PV": 106.5,
    "out:Primary Energy": 112.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 29.02,
    "out:CO2 Operational/m2": 20.28,
    "out:Total CO2/m2": 49.3,
    "out:Total CO2 (tons)": 158.76,
    "out:Klimatpaverkan": -60.07,
    "out:Initial Cost/MSEK": 5.397,
    "out:Running cost/(Apt SEK y)": 27386,
    "out:Running Cost over RSP/MSEK": 7.712,
    "out:LCP/MSEK": 1.417,
    "out:ROI% old": 18.04,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.8,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 872,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10038.89,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954.73,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286160,
    "out:DH kr savings": 289800,
    "out:El kr savings": -18698,
    "out:El kr sold": 15058,
    "out:El kr saved": 27909,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 310699,
    "out:% savings (space heating)": 45.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 106.14,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 124.6,
    "out:Total Energy Use Post PV": 124.6,
    "out:Primary Energy": 125.27,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.08,
    "out:CO2 Operational/m2": 43.12,
    "out:Total CO2/m2": 67.2,
    "out:Total CO2 (tons)": 216.39,
    "out:Klimatpaverkan": -2.44,
    "out:Initial Cost/MSEK": 5.514,
    "out:Running cost/(Apt SEK y)": 32964,
    "out:Running Cost over RSP/MSEK": 9.278,
    "out:LCP/MSEK": -0.266,
    "out:ROI% old": 13.6,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 125,
    "out:Energy use kWh/m2": 125,
    "out:Energy savings %": 55.2,
    "out:Op energy cost/MSEK": 68,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 12,
    "out:Return/kSEK/y": 671,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 222180,
    "out:DH kr savings": 222180,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 120.6,
    "out:Total Energy Use Post PV": 120.6,
    "out:Primary Energy": 121.23,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.08,
    "out:CO2 Operational/m2": 41.68,
    "out:Total CO2/m2": 65.76,
    "out:Total CO2 (tons)": 211.76,
    "out:Klimatpaverkan": -7.07,
    "out:Initial Cost/MSEK": 5.595,
    "out:Running cost/(Apt SEK y)": 31883,
    "out:Running Cost over RSP/MSEK": 8.973,
    "out:LCP/MSEK": -0.042,
    "out:ROI% old": 14.18,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 710,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 235060,
    "out:DH kr savings": 235060,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 123.6,
    "out:Total Energy Use Post PV": 123.6,
    "out:Primary Energy": 122.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.43,
    "out:CO2 Operational/m2": 42.76,
    "out:Total CO2/m2": 67.19,
    "out:Total CO2 (tons)": 216.36,
    "out:Klimatpaverkan": -2.47,
    "out:Initial Cost/MSEK": 5.651,
    "out:Running cost/(Apt SEK y)": 32694,
    "out:Running Cost over RSP/MSEK": 9.202,
    "out:LCP/MSEK": -0.327,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 124,
    "out:Energy use kWh/m2": 124,
    "out:Energy savings %": 56.5,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 41,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 12,
    "out:Return/kSEK/y": 681,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 225400,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 225400,
    "out:DH kr savings": 225400,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 118.6,
    "out:Total Energy Use Post PV": 118.6,
    "out:Primary Energy": 118.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.43,
    "out:CO2 Operational/m2": 40.96,
    "out:Total CO2/m2": 65.39,
    "out:Total CO2 (tons)": 210.56,
    "out:Klimatpaverkan": -8.27,
    "out:Initial Cost/MSEK": 5.732,
    "out:Running cost/(Apt SEK y)": 31343,
    "out:Running Cost over RSP/MSEK": 8.821,
    "out:LCP/MSEK": -0.027,
    "out:ROI% old": 14.22,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 729,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241500,
    "out:DH kr savings": 241500,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 124.6,
    "out:Total Energy Use Post PV": 121.91,
    "out:Primary Energy": 120.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.32,
    "out:CO2 Operational/m2": 18.64,
    "out:Total CO2/m2": 50.96,
    "out:Total CO2 (tons)": 164.09,
    "out:Klimatpaverkan": -54.74,
    "out:Initial Cost/MSEK": 5.961,
    "out:Running cost/(Apt SEK y)": 31844,
    "out:Running Cost over RSP/MSEK": 8.971,
    "out:LCP/MSEK": -0.406,
    "out:ROI% old": 13.31,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 117,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 711,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 222180,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262496,
    "out:DH kr savings": 222180,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 120.6,
    "out:Total Energy Use Post PV": 117.91,
    "out:Primary Energy": 116.4,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.32,
    "out:CO2 Operational/m2": 17.2,
    "out:Total CO2/m2": 49.52,
    "out:Total CO2 (tons)": 159.45,
    "out:Klimatpaverkan": -59.38,
    "out:Initial Cost/MSEK": 6.041,
    "out:Running cost/(Apt SEK y)": 30764,
    "out:Running Cost over RSP/MSEK": 8.667,
    "out:LCP/MSEK": -0.182,
    "out:ROI% old": 13.86,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 118,
    "out:Energy use kWh/m2": 118,
    "out:Energy savings %": 64.4,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 113,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 750,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 235060,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 275376,
    "out:DH kr savings": 235060,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 123.6,
    "out:Total Energy Use Post PV": 120.91,
    "out:Primary Energy": 117.81,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.67,
    "out:CO2 Operational/m2": 18.28,
    "out:Total CO2/m2": 50.95,
    "out:Total CO2 (tons)": 164.06,
    "out:Klimatpaverkan": -54.77,
    "out:Initial Cost/MSEK": 6.098,
    "out:Running cost/(Apt SEK y)": 31574,
    "out:Running Cost over RSP/MSEK": 8.895,
    "out:LCP/MSEK": -0.466,
    "out:ROI% old": 13.19,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 121,
    "out:Energy use kWh/m2": 121,
    "out:Energy savings %": 60.3,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 116,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 721,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 225400,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265716,
    "out:DH kr savings": 225400,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 118.6,
    "out:Total Energy Use Post PV": 115.91,
    "out:Primary Energy": 113.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.67,
    "out:CO2 Operational/m2": 16.48,
    "out:Total CO2/m2": 49.15,
    "out:Total CO2 (tons)": 158.26,
    "out:Klimatpaverkan": -60.57,
    "out:Initial Cost/MSEK": 6.178,
    "out:Running cost/(Apt SEK y)": 30223,
    "out:Running Cost over RSP/MSEK": 8.514,
    "out:LCP/MSEK": -0.167,
    "out:ROI% old": 13.9,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 67.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 770,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281816,
    "out:DH kr savings": 241500,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 122.6,
    "out:Total Energy Use Post PV": 122.6,
    "out:Primary Energy": 121.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.08,
    "out:CO2 Operational/m2": 42.4,
    "out:Total CO2/m2": 66.48,
    "out:Total CO2 (tons)": 214.07,
    "out:Klimatpaverkan": -4.76,
    "out:Initial Cost/MSEK": 5.609,
    "out:Running cost/(Apt SEK y)": 32424,
    "out:Running Cost over RSP/MSEK": 9.125,
    "out:LCP/MSEK": -0.209,
    "out:ROI% old": 13.75,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 123,
    "out:Energy use kWh/m2": 123,
    "out:Energy savings %": 57.7,
    "out:Op energy cost/MSEK": 67,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 12,
    "out:Return/kSEK/y": 690,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 228620,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 228620,
    "out:DH kr savings": 228620,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 118.6,
    "out:Total Energy Use Post PV": 118.6,
    "out:Primary Energy": 117.73,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.08,
    "out:CO2 Operational/m2": 40.96,
    "out:Total CO2/m2": 65.04,
    "out:Total CO2 (tons)": 209.44,
    "out:Klimatpaverkan": -9.39,
    "out:Initial Cost/MSEK": 5.69,
    "out:Running cost/(Apt SEK y)": 31343,
    "out:Running Cost over RSP/MSEK": 8.821,
    "out:LCP/MSEK": 0.015,
    "out:ROI% old": 14.32,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 39,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 729,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 241500,
    "out:DH kr savings": 241500,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 121.6,
    "out:Primary Energy": 119.67,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.43,
    "out:CO2 Operational/m2": 42.04,
    "out:Total CO2/m2": 66.47,
    "out:Total CO2 (tons)": 214.04,
    "out:Klimatpaverkan": -4.79,
    "out:Initial Cost/MSEK": 5.746,
    "out:Running cost/(Apt SEK y)": 32154,
    "out:Running Cost over RSP/MSEK": 9.049,
    "out:LCP/MSEK": -0.27,
    "out:ROI% old": 13.62,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 122,
    "out:Energy use kWh/m2": 122,
    "out:Energy savings %": 59,
    "out:Op energy cost/MSEK": 66,
    "out:El price/MSEK": 40,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 12,
    "out:Return/kSEK/y": 700,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 231840,
    "out:DH kr savings": 231840,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 116.6,
    "out:Total Energy Use Post PV": 116.6,
    "out:Primary Energy": 115.63,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 24.43,
    "out:CO2 Operational/m2": 40.24,
    "out:Total CO2/m2": 64.67,
    "out:Total CO2 (tons)": 208.25,
    "out:Klimatpaverkan": -10.58,
    "out:Initial Cost/MSEK": 5.827,
    "out:Running cost/(Apt SEK y)": 30803,
    "out:Running Cost over RSP/MSEK": 8.669,
    "out:LCP/MSEK": 0.03,
    "out:ROI% old": 14.36,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 117,
    "out:Energy use kWh/m2": 117,
    "out:Energy savings %": 65.8,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 13,
    "out:Return/kSEK/y": 749,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 247940,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 247940,
    "out:DH kr savings": 247940,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01010200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 122.6,
    "out:Total Energy Use Post PV": 119.91,
    "out:Primary Energy": 116.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.32,
    "out:CO2 Operational/m2": 17.92,
    "out:Total CO2/m2": 50.24,
    "out:Total CO2 (tons)": 161.77,
    "out:Klimatpaverkan": -57.06,
    "out:Initial Cost/MSEK": 6.056,
    "out:Running cost/(Apt SEK y)": 31304,
    "out:Running Cost over RSP/MSEK": 8.819,
    "out:LCP/MSEK": -0.349,
    "out:ROI% old": 13.46,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 120,
    "out:Energy use kWh/m2": 120,
    "out:Energy savings %": 61.7,
    "out:Op energy cost/MSEK": 65,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 23,
    "out:DH+ DHW/kWh/m2": 115,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 731,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 228620,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268936,
    "out:DH kr savings": 228620,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 118.6,
    "out:Total Energy Use Post PV": 115.91,
    "out:Primary Energy": 112.9,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.32,
    "out:CO2 Operational/m2": 16.48,
    "out:Total CO2/m2": 48.8,
    "out:Total CO2 (tons)": 157.13,
    "out:Klimatpaverkan": -61.7,
    "out:Initial Cost/MSEK": 6.136,
    "out:Running cost/(Apt SEK y)": 30223,
    "out:Running Cost over RSP/MSEK": 8.514,
    "out:LCP/MSEK": -0.125,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 67.2,
    "out:Op energy cost/MSEK": 63,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 111,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 770,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 241500,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281816,
    "out:DH kr savings": 241500,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 107.53,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 121.6,
    "out:Total Energy Use Post PV": 118.91,
    "out:Primary Energy": 114.83,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.67,
    "out:CO2 Operational/m2": 17.56,
    "out:Total CO2/m2": 50.23,
    "out:Total CO2 (tons)": 161.74,
    "out:Klimatpaverkan": -57.09,
    "out:Initial Cost/MSEK": 6.193,
    "out:Running cost/(Apt SEK y)": 31034,
    "out:Running Cost over RSP/MSEK": 8.743,
    "out:LCP/MSEK": -0.409,
    "out:ROI% old": 13.34,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 119,
    "out:Energy use kWh/m2": 119,
    "out:Energy savings %": 63,
    "out:Op energy cost/MSEK": 64,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 22,
    "out:DH+ DHW/kWh/m2": 114,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 12,
    "out:Return/kSEK/y": 740,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 231840,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272156,
    "out:DH kr savings": 231840,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 346252,
    "out:% savings (space heating)": 39.04,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 102.92,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 116.6,
    "out:Total Energy Use Post PV": 113.91,
    "out:Primary Energy": 110.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 32.67,
    "out:CO2 Operational/m2": 15.76,
    "out:Total CO2/m2": 48.43,
    "out:Total CO2 (tons)": 155.94,
    "out:Klimatpaverkan": -62.89,
    "out:Initial Cost/MSEK": 6.273,
    "out:Running cost/(Apt SEK y)": 29683,
    "out:Running Cost over RSP/MSEK": 8.362,
    "out:LCP/MSEK": -0.109,
    "out:ROI% old": 14.04,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 109,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 789,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 247940,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288256,
    "out:DH kr savings": 247940,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 331391,
    "out:% savings (space heating)": 41.65,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01010201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 115.84,
    "out:Total Energy Use Post PV": 115.85,
    "out:Primary Energy": 126.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 38.32,
    "out:Total CO2/m2": 65.66,
    "out:Total CO2 (tons)": 211.43,
    "out:Klimatpaverkan": -7.4,
    "out:Initial Cost/MSEK": 6.125,
    "out:Running cost/(Apt SEK y)": 29937,
    "out:Running Cost over RSP/MSEK": 8.421,
    "out:LCP/MSEK": -0.02,
    "out:ROI% old": 14.24,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 116,
    "out:Energy use kWh/m2": 116,
    "out:Energy savings %": 67.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 38,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 780,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 227037,
    "out:DH kr savings": 273700,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 324644,
    "out:% savings (space heating)": 42.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 110.84,
    "out:Total Energy Use Post PV": 110.85,
    "out:Primary Energy": 122.85,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 36.52,
    "out:Total CO2/m2": 63.86,
    "out:Total CO2 (tons)": 205.63,
    "out:Klimatpaverkan": -13.2,
    "out:Initial Cost/MSEK": 6.206,
    "out:Running cost/(Apt SEK y)": 28587,
    "out:Running Cost over RSP/MSEK": 8.041,
    "out:LCP/MSEK": 0.279,
    "out:ROI% old": 14.93,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 13,
    "out:Return/kSEK/y": 829,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 243137,
    "out:DH kr savings": 289800,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309783,
    "out:% savings (space heating)": 45.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 113.83,
    "out:Total Energy Use Post PV": 113.85,
    "out:Primary Energy": 124.26,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 37.6,
    "out:Total CO2/m2": 65.29,
    "out:Total CO2 (tons)": 210.24,
    "out:Klimatpaverkan": -8.59,
    "out:Initial Cost/MSEK": 6.262,
    "out:Running cost/(Apt SEK y)": 29395,
    "out:Running Cost over RSP/MSEK": 8.268,
    "out:LCP/MSEK": -0.004,
    "out:ROI% old": 14.28,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 799,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233477,
    "out:DH kr savings": 280140,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 320136,
    "out:% savings (space heating)": 43.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 109.83,
    "out:Total Energy Use Post PV": 109.85,
    "out:Primary Energy": 120.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 36.16,
    "out:Total CO2/m2": 63.85,
    "out:Total CO2 (tons)": 205.6,
    "out:Klimatpaverkan": -13.23,
    "out:Initial Cost/MSEK": 6.342,
    "out:Running cost/(Apt SEK y)": 28314,
    "out:Running Cost over RSP/MSEK": 7.964,
    "out:LCP/MSEK": 0.22,
    "out:ROI% old": 14.78,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293020,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 246357,
    "out:DH kr savings": 293020,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 305280,
    "out:% savings (space heating)": 46.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 100.82,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 115.84,
    "out:Total Energy Use Post PV": 111.5,
    "out:Primary Energy": 119.07,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.58,
    "out:CO2 Operational/m2": 22.13,
    "out:Total CO2/m2": 57.7,
    "out:Total CO2 (tons)": 185.81,
    "out:Klimatpaverkan": -33.02,
    "out:Initial Cost/MSEK": 6.572,
    "out:Running cost/(Apt SEK y)": 28744,
    "out:Running Cost over RSP/MSEK": 8.094,
    "out:LCP/MSEK": -0.14,
    "out:ROI% old": 13.98,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 73.2,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 823,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.48,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.14,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270022,
    "out:DH kr savings": 273700,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 324644,
    "out:% savings (space heating)": 42.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 110.84,
    "out:Total Energy Use Post PV": 106.5,
    "out:Primary Energy": 115.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.58,
    "out:CO2 Operational/m2": 20.33,
    "out:Total CO2/m2": 55.9,
    "out:Total CO2 (tons)": 180.01,
    "out:Klimatpaverkan": -38.82,
    "out:Initial Cost/MSEK": 6.652,
    "out:Running cost/(Apt SEK y)": 27393,
    "out:Running Cost over RSP/MSEK": 7.714,
    "out:LCP/MSEK": 0.16,
    "out:ROI% old": 14.63,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 96,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 13,
    "out:Return/kSEK/y": 872,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.5,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.12,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 289800,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 286122,
    "out:DH kr savings": 289800,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309783,
    "out:% savings (space heating)": 45.46,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 99.42,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 113.83,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 116.45,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.93,
    "out:CO2 Operational/m2": 21.39,
    "out:Total CO2/m2": 57.32,
    "out:Total CO2 (tons)": 184.58,
    "out:Klimatpaverkan": -34.25,
    "out:Initial Cost/MSEK": 6.708,
    "out:Running cost/(Apt SEK y)": 28201,
    "out:Running Cost over RSP/MSEK": 7.941,
    "out:LCP/MSEK": -0.124,
    "out:ROI% old": 14.02,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.97,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.65,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276473,
    "out:DH kr savings": 280140,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 320136,
    "out:% savings (space heating)": 43.63,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.81,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 109.83,
    "out:Total Energy Use Post PV": 105.5,
    "out:Primary Energy": 112.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.93,
    "out:CO2 Operational/m2": 19.95,
    "out:Total CO2/m2": 55.88,
    "out:Total CO2 (tons)": 179.94,
    "out:Klimatpaverkan": -38.89,
    "out:Initial Cost/MSEK": 6.789,
    "out:Running cost/(Apt SEK y)": 27121,
    "out:Running Cost over RSP/MSEK": 7.637,
    "out:LCP/MSEK": 0.1,
    "out:ROI% old": 14.5,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 95,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 881,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.23,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.39,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 293020,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 289354,
    "out:DH kr savings": 293020,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 305280,
    "out:% savings (space heating)": 46.25,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 113.82,
    "out:Total Energy Use Post PV": 113.85,
    "out:Primary Energy": 123.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 37.6,
    "out:Total CO2/m2": 64.94,
    "out:Total CO2 (tons)": 209.1,
    "out:Klimatpaverkan": -9.73,
    "out:Initial Cost/MSEK": 6.22,
    "out:Running cost/(Apt SEK y)": 29394,
    "out:Running Cost over RSP/MSEK": 8.268,
    "out:LCP/MSEK": 0.038,
    "out:ROI% old": 14.37,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 13,
    "out:Return/kSEK/y": 800,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 233477,
    "out:DH kr savings": 280140,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 318656,
    "out:% savings (space heating)": 43.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 108.82,
    "out:Total Energy Use Post PV": 108.85,
    "out:Primary Energy": 119.35,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.34,
    "out:CO2 Operational/m2": 35.8,
    "out:Total CO2/m2": 63.14,
    "out:Total CO2 (tons)": 203.31,
    "out:Klimatpaverkan": -15.52,
    "out:Initial Cost/MSEK": 6.301,
    "out:Running cost/(Apt SEK y)": 28043,
    "out:Running Cost over RSP/MSEK": 7.888,
    "out:LCP/MSEK": 0.338,
    "out:ROI% old": 15.05,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 13,
    "out:Return/kSEK/y": 848,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296240,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 249577,
    "out:DH kr savings": 296240,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303803,
    "out:% savings (space heating)": 46.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 112.8,
    "out:Total Energy Use Post PV": 112.85,
    "out:Primary Energy": 121.29,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 37.23,
    "out:Total CO2/m2": 64.93,
    "out:Total CO2 (tons)": 209.06,
    "out:Klimatpaverkan": -9.77,
    "out:Initial Cost/MSEK": 6.357,
    "out:Running cost/(Apt SEK y)": 29120,
    "out:Running Cost over RSP/MSEK": 8.191,
    "out:LCP/MSEK": -0.022,
    "out:ROI% old": 14.24,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 71.7,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 1036.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 809,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 236697,
    "out:DH kr savings": 283360,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 315164,
    "out:% savings (space heating)": 44.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 107.8,
    "out:Total Energy Use Post PV": 107.85,
    "out:Primary Energy": 117.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 27.69,
    "out:CO2 Operational/m2": 35.43,
    "out:Total CO2/m2": 63.13,
    "out:Total CO2 (tons)": 203.27,
    "out:Klimatpaverkan": -15.56,
    "out:Initial Cost/MSEK": 6.437,
    "out:Running cost/(Apt SEK y)": 27769,
    "out:Running Cost over RSP/MSEK": 7.811,
    "out:LCP/MSEK": 0.278,
    "out:ROI% old": 14.9,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 79.6,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 1036.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 858,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 299460,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 252797,
    "out:DH kr savings": 299460,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 300324,
    "out:% savings (space heating)": 47.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01010201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 98.96,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 113.82,
    "out:Total Energy Use Post PV": 109.5,
    "out:Primary Energy": 115.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.58,
    "out:CO2 Operational/m2": 21.39,
    "out:Total CO2/m2": 56.96,
    "out:Total CO2 (tons)": 183.43,
    "out:Klimatpaverkan": -35.4,
    "out:Initial Cost/MSEK": 6.667,
    "out:Running cost/(Apt SEK y)": 28200,
    "out:Running Cost over RSP/MSEK": 7.941,
    "out:LCP/MSEK": -0.082,
    "out:ROI% old": 14.11,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 842,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.73,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.89,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 276479,
    "out:DH kr savings": 280140,
    "out:El kr savings": -18698,
    "out:El kr sold": 15037,
    "out:El kr saved": 27938,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 318656,
    "out:% savings (space heating)": 43.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 94.35,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 108.82,
    "out:Total Energy Use Post PV": 104.5,
    "out:Primary Energy": 111.53,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.58,
    "out:CO2 Operational/m2": 19.58,
    "out:Total CO2/m2": 55.16,
    "out:Total CO2 (tons)": 177.63,
    "out:Klimatpaverkan": -41.2,
    "out:Initial Cost/MSEK": 6.747,
    "out:Running cost/(Apt SEK y)": 26849,
    "out:Running Cost over RSP/MSEK": 7.561,
    "out:LCP/MSEK": 0.218,
    "out:ROI% old": 14.75,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 94,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 13,
    "out:Return/kSEK/y": 891,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.17,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.45,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 296240,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 292579,
    "out:DH kr savings": 296240,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 303803,
    "out:% savings (space heating)": 46.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 97.88,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 112.8,
    "out:Total Energy Use Post PV": 108.5,
    "out:Primary Energy": 113.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.93,
    "out:CO2 Operational/m2": 21,
    "out:Total CO2/m2": 56.93,
    "out:Total CO2 (tons)": 183.32,
    "out:Klimatpaverkan": -35.51,
    "out:Initial Cost/MSEK": 6.803,
    "out:Running cost/(Apt SEK y)": 27927,
    "out:Running Cost over RSP/MSEK": 7.864,
    "out:LCP/MSEK": -0.141,
    "out:ROI% old": 13.99,
    "out:Payback discounted": 9,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 740.1,
    "out:Return %": 13,
    "out:Return/kSEK/y": 852,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10037.32,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13956.3,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 279718,
    "out:DH kr savings": 283360,
    "out:El kr savings": -18698,
    "out:El kr sold": 15056,
    "out:El kr saved": 27913,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 315164,
    "out:% savings (space heating)": 44.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 107.53,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01010201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 93.27,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 107.8,
    "out:Total Energy Use Post PV": 103.5,
    "out:Primary Energy": 109.43,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 35.93,
    "out:CO2 Operational/m2": 19.2,
    "out:Total CO2/m2": 55.13,
    "out:Total CO2 (tons)": 177.52,
    "out:Klimatpaverkan": -41.31,
    "out:Initial Cost/MSEK": 6.884,
    "out:Running cost/(Apt SEK y)": 26576,
    "out:Running Cost over RSP/MSEK": 7.484,
    "out:LCP/MSEK": 0.159,
    "out:ROI% old": 14.61,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.74,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 93,
    "out:El bought/kWh/m2": 740,
    "out:Return %": 13,
    "out:Return/kSEK/y": 901,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10038.54,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13955.08,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 2.39,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 299460,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 295819,
    "out:DH kr savings": 299460,
    "out:El kr savings": -18698,
    "out:El kr sold": 15058,
    "out:El kr saved": 27910,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 300324,
    "out:% savings (space heating)": 47.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 102.92,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.24,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 113.6,
    "out:Total Energy Use Post PV": 113.6,
    "out:Primary Energy": 115.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 39.16,
    "out:Total CO2/m2": 54.12,
    "out:Total CO2 (tons)": 174.28,
    "out:Klimatpaverkan": -44.55,
    "out:Initial Cost/MSEK": 3.711,
    "out:Running cost/(Apt SEK y)": 29993,
    "out:Running Cost over RSP/MSEK": 8.441,
    "out:LCP/MSEK": 2.374,
    "out:ROI% old": 23.43,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 114,
    "out:Energy use kWh/m2": 114,
    "out:Energy savings %": 70.2,
    "out:Op energy cost/MSEK": 62,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 778,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 257600,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 257600,
    "out:DH kr savings": 257600,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309904,
    "out:% savings (space heating)": 45.44,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.14,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 109.6,
    "out:Total Energy Use Post PV": 109.6,
    "out:Primary Energy": 111.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 37.72,
    "out:Total CO2/m2": 52.68,
    "out:Total CO2 (tons)": 169.64,
    "out:Klimatpaverkan": -49.19,
    "out:Initial Cost/MSEK": 3.791,
    "out:Running cost/(Apt SEK y)": 28912,
    "out:Running Cost over RSP/MSEK": 8.137,
    "out:LCP/MSEK": 2.598,
    "out:ROI% old": 24.08,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 270480,
    "out:DH kr savings": 270480,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 296695,
    "out:% savings (space heating)": 47.76,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.24,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 111.6,
    "out:Total Energy Use Post PV": 111.6,
    "out:Primary Energy": 112.77,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.31,
    "out:CO2 Operational/m2": 38.44,
    "out:Total CO2/m2": 53.75,
    "out:Total CO2 (tons)": 173.09,
    "out:Klimatpaverkan": -45.74,
    "out:Initial Cost/MSEK": 3.847,
    "out:Running cost/(Apt SEK y)": 29452,
    "out:Running Cost over RSP/MSEK": 8.289,
    "out:LCP/MSEK": 2.39,
    "out:ROI% old": 23.16,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 73.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 797,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 264040,
    "out:DH kr savings": 264040,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309904,
    "out:% savings (space heating)": 45.44,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.14,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 107.6,
    "out:Total Energy Use Post PV": 107.6,
    "out:Primary Energy": 109.18,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.31,
    "out:CO2 Operational/m2": 37,
    "out:Total CO2/m2": 52.31,
    "out:Total CO2 (tons)": 168.45,
    "out:Klimatpaverkan": -50.38,
    "out:Initial Cost/MSEK": 3.928,
    "out:Running cost/(Apt SEK y)": 28372,
    "out:Running Cost over RSP/MSEK": 7.985,
    "out:LCP/MSEK": 2.614,
    "out:ROI% old": 23.79,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 79.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 836,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 276920,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276920,
    "out:DH kr savings": 276920,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 296695,
    "out:% savings (space heating)": 47.76,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.24,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 113.6,
    "out:Total Energy Use Post PV": 110.91,
    "out:Primary Energy": 110.56,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.2,
    "out:CO2 Operational/m2": 14.68,
    "out:Total CO2/m2": 37.88,
    "out:Total CO2 (tons)": 121.98,
    "out:Klimatpaverkan": -96.85,
    "out:Initial Cost/MSEK": 4.157,
    "out:Running cost/(Apt SEK y)": 28873,
    "out:Running Cost over RSP/MSEK": 8.134,
    "out:LCP/MSEK": 2.235,
    "out:ROI% old": 21.96,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 106,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 818,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 257600,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 297916,
    "out:DH kr savings": 257600,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309904,
    "out:% savings (space heating)": 45.44,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.14,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 109.6,
    "out:Total Energy Use Post PV": 106.91,
    "out:Primary Energy": 106.97,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.2,
    "out:CO2 Operational/m2": 13.24,
    "out:Total CO2/m2": 36.44,
    "out:Total CO2 (tons)": 117.34,
    "out:Klimatpaverkan": -101.49,
    "out:Initial Cost/MSEK": 4.238,
    "out:Running cost/(Apt SEK y)": 27792,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": 2.458,
    "out:ROI% old": 22.57,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 857,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 310796,
    "out:DH kr savings": 270480,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 296695,
    "out:% savings (space heating)": 47.76,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.24,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 111.6,
    "out:Total Energy Use Post PV": 108.91,
    "out:Primary Energy": 107.93,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.55,
    "out:CO2 Operational/m2": 13.96,
    "out:Total CO2/m2": 37.51,
    "out:Total CO2 (tons)": 120.78,
    "out:Klimatpaverkan": -98.05,
    "out:Initial Cost/MSEK": 4.294,
    "out:Running cost/(Apt SEK y)": 28332,
    "out:Running Cost over RSP/MSEK": 7.982,
    "out:LCP/MSEK": 2.25,
    "out:ROI% old": 21.77,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304356,
    "out:DH kr savings": 264040,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309904,
    "out:% savings (space heating)": 45.44,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.14,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 107.6,
    "out:Total Energy Use Post PV": 104.91,
    "out:Primary Energy": 104.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.55,
    "out:CO2 Operational/m2": 12.52,
    "out:Total CO2/m2": 36.07,
    "out:Total CO2 (tons)": 116.15,
    "out:Klimatpaverkan": -102.68,
    "out:Initial Cost/MSEK": 4.374,
    "out:Running cost/(Apt SEK y)": 27252,
    "out:Running Cost over RSP/MSEK": 7.678,
    "out:LCP/MSEK": 2.474,
    "out:ROI% old": 22.36,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 276920,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317236,
    "out:DH kr savings": 276920,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 296695,
    "out:% savings (space heating)": 47.76,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.24,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 111.6,
    "out:Total Energy Use Post PV": 111.6,
    "out:Primary Energy": 111.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 38.44,
    "out:Total CO2/m2": 53.4,
    "out:Total CO2 (tons)": 171.96,
    "out:Klimatpaverkan": -46.87,
    "out:Initial Cost/MSEK": 3.806,
    "out:Running cost/(Apt SEK y)": 29452,
    "out:Running Cost over RSP/MSEK": 8.289,
    "out:LCP/MSEK": 2.431,
    "out:ROI% old": 23.41,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 112,
    "out:Energy use kWh/m2": 112,
    "out:Energy savings %": 73.2,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 797,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 264040,
    "out:DH kr savings": 264040,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309904,
    "out:% savings (space heating)": 45.44,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.14,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 107.6,
    "out:Total Energy Use Post PV": 107.6,
    "out:Primary Energy": 108.3,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 14.96,
    "out:CO2 Operational/m2": 37,
    "out:Total CO2/m2": 51.96,
    "out:Total CO2 (tons)": 167.32,
    "out:Klimatpaverkan": -51.51,
    "out:Initial Cost/MSEK": 3.886,
    "out:Running cost/(Apt SEK y)": 28372,
    "out:Running Cost over RSP/MSEK": 7.985,
    "out:LCP/MSEK": 2.655,
    "out:ROI% old": 24.05,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 79.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 836,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 276920,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 276920,
    "out:DH kr savings": 276920,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 296695,
    "out:% savings (space heating)": 47.76,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.24,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 110.6,
    "out:Total Energy Use Post PV": 110.6,
    "out:Primary Energy": 109.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.31,
    "out:CO2 Operational/m2": 38.08,
    "out:Total CO2/m2": 53.39,
    "out:Total CO2 (tons)": 171.93,
    "out:Klimatpaverkan": -46.9,
    "out:Initial Cost/MSEK": 3.942,
    "out:Running cost/(Apt SEK y)": 29182,
    "out:Running Cost over RSP/MSEK": 8.213,
    "out:LCP/MSEK": 2.371,
    "out:ROI% old": 22.88,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 20,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267260,
    "out:DH kr savings": 267260,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309904,
    "out:% savings (space heating)": 45.44,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.14,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 106.6,
    "out:Primary Energy": 106.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.31,
    "out:CO2 Operational/m2": 36.64,
    "out:Total CO2/m2": 51.95,
    "out:Total CO2 (tons)": 167.29,
    "out:Klimatpaverkan": -51.54,
    "out:Initial Cost/MSEK": 4.023,
    "out:Running cost/(Apt SEK y)": 28102,
    "out:Running Cost over RSP/MSEK": 7.908,
    "out:LCP/MSEK": 2.595,
    "out:ROI% old": 23.5,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280140,
    "out:DH kr savings": 280140,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 296695,
    "out:% savings (space heating)": 47.76,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.24,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 111.6,
    "out:Total Energy Use Post PV": 108.91,
    "out:Primary Energy": 107.06,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.2,
    "out:CO2 Operational/m2": 13.96,
    "out:Total CO2/m2": 37.16,
    "out:Total CO2 (tons)": 119.66,
    "out:Klimatpaverkan": -99.17,
    "out:Initial Cost/MSEK": 4.252,
    "out:Running cost/(Apt SEK y)": 28332,
    "out:Running Cost over RSP/MSEK": 7.982,
    "out:LCP/MSEK": 2.292,
    "out:ROI% old": 21.99,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 104,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 838,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 264040,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 304356,
    "out:DH kr savings": 264040,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309904,
    "out:% savings (space heating)": 45.44,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.14,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 107.6,
    "out:Total Energy Use Post PV": 104.91,
    "out:Primary Energy": 103.47,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.2,
    "out:CO2 Operational/m2": 12.52,
    "out:Total CO2/m2": 35.72,
    "out:Total CO2 (tons)": 115.02,
    "out:Klimatpaverkan": -103.81,
    "out:Initial Cost/MSEK": 4.333,
    "out:Running cost/(Apt SEK y)": 27252,
    "out:Running Cost over RSP/MSEK": 7.678,
    "out:LCP/MSEK": 2.515,
    "out:ROI% old": 22.58,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 100,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 877,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 276920,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 317236,
    "out:DH kr savings": 276920,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 296695,
    "out:% savings (space heating)": 47.76,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 96.24,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 110.6,
    "out:Total Energy Use Post PV": 107.91,
    "out:Primary Energy": 104.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.55,
    "out:CO2 Operational/m2": 13.6,
    "out:Total CO2/m2": 37.15,
    "out:Total CO2 (tons)": 119.63,
    "out:Klimatpaverkan": -99.2,
    "out:Initial Cost/MSEK": 4.389,
    "out:Running cost/(Apt SEK y)": 28062,
    "out:Running Cost over RSP/MSEK": 7.906,
    "out:LCP/MSEK": 2.231,
    "out:ROI% old": 21.55,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 79.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 847,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307576,
    "out:DH kr savings": 267260,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 309904,
    "out:% savings (space heating)": 45.44,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 92.14,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 103.91,
    "out:Primary Energy": 101.37,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.55,
    "out:CO2 Operational/m2": 12.16,
    "out:Total CO2/m2": 35.71,
    "out:Total CO2 (tons)": 114.99,
    "out:Klimatpaverkan": -103.84,
    "out:Initial Cost/MSEK": 4.469,
    "out:Running cost/(Apt SEK y)": 26982,
    "out:Running Cost over RSP/MSEK": 7.602,
    "out:LCP/MSEK": 2.455,
    "out:ROI% old": 22.13,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320456,
    "out:DH kr savings": 280140,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 296695,
    "out:% savings (space heating)": 47.76,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 104.84,
    "out:Total Energy Use Post PV": 104.85,
    "out:Primary Energy": 117.01,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.23,
    "out:CO2 Operational/m2": 34.36,
    "out:Total CO2/m2": 52.58,
    "out:Total CO2 (tons)": 169.32,
    "out:Klimatpaverkan": -49.51,
    "out:Initial Cost/MSEK": 4.321,
    "out:Running cost/(Apt SEK y)": 26966,
    "out:Running Cost over RSP/MSEK": 7.584,
    "out:LCP/MSEK": 2.62,
    "out:ROI% old": 22.95,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 105,
    "out:Energy use kWh/m2": 105,
    "out:Energy savings %": 84.8,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 21,
    "out:Return/kSEK/y": 887,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 309120,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 262457,
    "out:DH kr savings": 309120,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 288293,
    "out:% savings (space heating)": 49.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 99.84,
    "out:Total Energy Use Post PV": 99.85,
    "out:Primary Energy": 113.42,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.23,
    "out:CO2 Operational/m2": 32.56,
    "out:Total CO2/m2": 50.78,
    "out:Total CO2 (tons)": 163.52,
    "out:Klimatpaverkan": -55.31,
    "out:Initial Cost/MSEK": 4.402,
    "out:Running cost/(Apt SEK y)": 25616,
    "out:Running Cost over RSP/MSEK": 7.204,
    "out:LCP/MSEK": 2.92,
    "out:ROI% old": 23.76,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 21,
    "out:Return/kSEK/y": 936,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278557,
    "out:DH kr savings": 325220,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 275085,
    "out:% savings (space heating)": 51.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 102.83,
    "out:Total Energy Use Post PV": 102.85,
    "out:Primary Energy": 114.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 33.64,
    "out:Total CO2/m2": 52.21,
    "out:Total CO2 (tons)": 168.12,
    "out:Klimatpaverkan": -50.71,
    "out:Initial Cost/MSEK": 4.458,
    "out:Running cost/(Apt SEK y)": 26424,
    "out:Running Cost over RSP/MSEK": 7.432,
    "out:LCP/MSEK": 2.636,
    "out:ROI% old": 22.73,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 88.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268897,
    "out:DH kr savings": 315560,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 283776,
    "out:% savings (space heating)": 50.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 98.83,
    "out:Total Energy Use Post PV": 98.85,
    "out:Primary Energy": 110.8,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 32.2,
    "out:Total CO2/m2": 50.77,
    "out:Total CO2 (tons)": 163.49,
    "out:Klimatpaverkan": -55.34,
    "out:Initial Cost/MSEK": 4.538,
    "out:Running cost/(Apt SEK y)": 25343,
    "out:Running Cost over RSP/MSEK": 7.128,
    "out:LCP/MSEK": 2.86,
    "out:ROI% old": 23.29,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 96,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 945,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281777,
    "out:DH kr savings": 328440,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 270572,
    "out:% savings (space heating)": 52.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 89.53,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 104.84,
    "out:Total Energy Use Post PV": 100.5,
    "out:Primary Energy": 109.19,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.46,
    "out:CO2 Operational/m2": 18.17,
    "out:Total CO2/m2": 44.63,
    "out:Total CO2 (tons)": 143.7,
    "out:Klimatpaverkan": -75.13,
    "out:Initial Cost/MSEK": 4.768,
    "out:Running cost/(Apt SEK y)": 25772,
    "out:Running Cost over RSP/MSEK": 7.257,
    "out:LCP/MSEK": 2.501,
    "out:ROI% old": 21.78,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 92.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 930,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.36,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.26,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 309120,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 305442,
    "out:DH kr savings": 309120,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27961,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 288293,
    "out:% savings (space heating)": 49.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.43,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 99.84,
    "out:Total Energy Use Post PV": 95.5,
    "out:Primary Energy": 105.6,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.46,
    "out:CO2 Operational/m2": 16.37,
    "out:Total CO2/m2": 42.83,
    "out:Total CO2 (tons)": 137.9,
    "out:Klimatpaverkan": -80.93,
    "out:Initial Cost/MSEK": 4.848,
    "out:Running cost/(Apt SEK y)": 24422,
    "out:Running Cost over RSP/MSEK": 6.877,
    "out:LCP/MSEK": 2.8,
    "out:ROI% old": 22.54,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.37,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.25,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 321542,
    "out:DH kr savings": 325220,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 275085,
    "out:% savings (space heating)": 51.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.13,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 102.83,
    "out:Total Energy Use Post PV": 98.5,
    "out:Primary Energy": 106.57,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": 17.43,
    "out:Total CO2/m2": 44.24,
    "out:Total CO2 (tons)": 142.47,
    "out:Klimatpaverkan": -76.36,
    "out:Initial Cost/MSEK": 4.905,
    "out:Running cost/(Apt SEK y)": 25230,
    "out:Running Cost over RSP/MSEK": 7.105,
    "out:LCP/MSEK": 2.517,
    "out:ROI% old": 21.62,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 96,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 19,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.29,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.33,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 311892,
    "out:DH kr savings": 315560,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 283776,
    "out:% savings (space heating)": 50.04,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 98.83,
    "out:Total Energy Use Post PV": 94.5,
    "out:Primary Energy": 102.98,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": 15.99,
    "out:Total CO2/m2": 42.8,
    "out:Total CO2 (tons)": 137.83,
    "out:Klimatpaverkan": -81,
    "out:Initial Cost/MSEK": 4.985,
    "out:Running cost/(Apt SEK y)": 24150,
    "out:Running Cost over RSP/MSEK": 6.8,
    "out:LCP/MSEK": 2.74,
    "out:ROI% old": 22.14,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 988,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.51,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.1,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 324772,
    "out:DH kr savings": 328440,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 270572,
    "out:% savings (space heating)": 52.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 102.83,
    "out:Total Energy Use Post PV": 102.85,
    "out:Primary Energy": 113.51,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.23,
    "out:CO2 Operational/m2": 33.64,
    "out:Total CO2/m2": 51.86,
    "out:Total CO2 (tons)": 166.99,
    "out:Klimatpaverkan": -51.84,
    "out:Initial Cost/MSEK": 4.416,
    "out:Running cost/(Apt SEK y)": 26423,
    "out:Running Cost over RSP/MSEK": 7.432,
    "out:LCP/MSEK": 2.678,
    "out:ROI% old": 22.95,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 88.3,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 21,
    "out:Return/kSEK/y": 906,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 268897,
    "out:DH kr savings": 315560,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282290,
    "out:% savings (space heating)": 50.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 98.82,
    "out:Total Energy Use Post PV": 98.85,
    "out:Primary Energy": 109.92,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.23,
    "out:CO2 Operational/m2": 32.2,
    "out:Total CO2/m2": 50.42,
    "out:Total CO2 (tons)": 162.35,
    "out:Klimatpaverkan": -56.48,
    "out:Initial Cost/MSEK": 4.497,
    "out:Running cost/(Apt SEK y)": 25342,
    "out:Running Cost over RSP/MSEK": 7.127,
    "out:LCP/MSEK": 2.902,
    "out:ROI% old": 23.5,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 96,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 945,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 281777,
    "out:DH kr savings": 328440,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 269088,
    "out:% savings (space heating)": 52.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 101.81,
    "out:Total Energy Use Post PV": 101.85,
    "out:Primary Energy": 111.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 33.27,
    "out:Total CO2/m2": 51.85,
    "out:Total CO2 (tons)": 166.95,
    "out:Klimatpaverkan": -51.88,
    "out:Initial Cost/MSEK": 4.553,
    "out:Running cost/(Apt SEK y)": 26149,
    "out:Running Cost over RSP/MSEK": 7.355,
    "out:LCP/MSEK": 2.618,
    "out:ROI% old": 22.5,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 90.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 916,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272117,
    "out:DH kr savings": 318780,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 278779,
    "out:% savings (space heating)": 50.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 96.8,
    "out:Total Energy Use Post PV": 96.85,
    "out:Primary Energy": 107.82,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.58,
    "out:CO2 Operational/m2": 31.47,
    "out:Total CO2/m2": 50.05,
    "out:Total CO2 (tons)": 161.16,
    "out:Klimatpaverkan": -57.67,
    "out:Initial Cost/MSEK": 4.634,
    "out:Running cost/(Apt SEK y)": 24798,
    "out:Running Cost over RSP/MSEK": 6.974,
    "out:LCP/MSEK": 2.918,
    "out:ROI% old": 23.28,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1036.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 965,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288217,
    "out:DH kr savings": 334880,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 265590,
    "out:% savings (space heating)": 53.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 102.83,
    "out:Total Energy Use Post PV": 98.5,
    "out:Primary Energy": 105.69,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.46,
    "out:CO2 Operational/m2": 17.43,
    "out:Total CO2/m2": 43.89,
    "out:Total CO2 (tons)": 141.32,
    "out:Klimatpaverkan": -77.51,
    "out:Initial Cost/MSEK": 4.863,
    "out:Running cost/(Apt SEK y)": 25229,
    "out:Running Cost over RSP/MSEK": 7.104,
    "out:LCP/MSEK": 2.559,
    "out:ROI% old": 21.8,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 96,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 949,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.7,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.92,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 311897,
    "out:DH kr savings": 315560,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 282290,
    "out:% savings (space heating)": 50.3,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.57,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 98.82,
    "out:Total Energy Use Post PV": 94.5,
    "out:Primary Energy": 102.1,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.46,
    "out:CO2 Operational/m2": 15.99,
    "out:Total CO2/m2": 42.45,
    "out:Total CO2 (tons)": 136.68,
    "out:Klimatpaverkan": -82.15,
    "out:Initial Cost/MSEK": 4.943,
    "out:Running cost/(Apt SEK y)": 24149,
    "out:Running Cost over RSP/MSEK": 6.8,
    "out:LCP/MSEK": 2.782,
    "out:ROI% old": 22.33,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 988,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.1,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.52,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 324778,
    "out:DH kr savings": 328440,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 269088,
    "out:% savings (space heating)": 52.62,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 101.81,
    "out:Total Energy Use Post PV": 97.5,
    "out:Primary Energy": 103.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": 17.05,
    "out:Total CO2/m2": 43.86,
    "out:Total CO2 (tons)": 141.22,
    "out:Klimatpaverkan": -77.61,
    "out:Initial Cost/MSEK": 5,
    "out:Running cost/(Apt SEK y)": 24956,
    "out:Running Cost over RSP/MSEK": 7.027,
    "out:LCP/MSEK": 2.499,
    "out:ROI% old": 21.43,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 98,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 959,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.17,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.45,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315134,
    "out:DH kr savings": 318780,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 278779,
    "out:% savings (space heating)": 50.92,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 96.24,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 96.8,
    "out:Total Energy Use Post PV": 92.5,
    "out:Primary Energy": 100,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.81,
    "out:CO2 Operational/m2": 15.25,
    "out:Total CO2/m2": 42.06,
    "out:Total CO2 (tons)": 135.42,
    "out:Klimatpaverkan": -83.41,
    "out:Initial Cost/MSEK": 5.08,
    "out:Running cost/(Apt SEK y)": 23605,
    "out:Running Cost over RSP/MSEK": 6.647,
    "out:LCP/MSEK": 2.799,
    "out:ROI% old": 22.16,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.62,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10036.3,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.32,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331236,
    "out:DH kr savings": 334880,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27915,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 265590,
    "out:% savings (space heating)": 53.24,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.14,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.19,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 98.6,
    "out:Total Energy Use Post PV": 98.6,
    "out:Primary Energy": 102.22,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.48,
    "out:CO2 Operational/m2": 33.76,
    "out:Total CO2/m2": 51.24,
    "out:Total CO2 (tons)": 165,
    "out:Klimatpaverkan": -53.83,
    "out:Initial Cost/MSEK": 4.318,
    "out:Running cost/(Apt SEK y)": 25941,
    "out:Running Cost over RSP/MSEK": 7.3,
    "out:LCP/MSEK": 2.908,
    "out:ROI% old": 23.91,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 99,
    "out:Energy use kWh/m2": 99,
    "out:Energy savings %": 96,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 924,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 305900,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 305900,
    "out:DH kr savings": 305900,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 261425,
    "out:% savings (space heating)": 53.97,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 94.6,
    "out:Primary Energy": 99.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.48,
    "out:CO2 Operational/m2": 32.32,
    "out:Total CO2/m2": 49.8,
    "out:Total CO2 (tons)": 160.36,
    "out:Klimatpaverkan": -58.47,
    "out:Initial Cost/MSEK": 4.398,
    "out:Running cost/(Apt SEK y)": 24860,
    "out:Running Cost over RSP/MSEK": 6.996,
    "out:LCP/MSEK": 3.132,
    "out:ROI% old": 24.46,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 963,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 318780,
    "out:DH kr savings": 318780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 250420,
    "out:% savings (space heating)": 55.91,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.19,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 96.6,
    "out:Total Energy Use Post PV": 96.6,
    "out:Primary Energy": 99.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.83,
    "out:CO2 Operational/m2": 33.04,
    "out:Total CO2/m2": 50.87,
    "out:Total CO2 (tons)": 163.81,
    "out:Klimatpaverkan": -55.02,
    "out:Initial Cost/MSEK": 4.455,
    "out:Running cost/(Apt SEK y)": 25400,
    "out:Running Cost over RSP/MSEK": 7.148,
    "out:LCP/MSEK": 2.923,
    "out:ROI% old": 23.66,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 943,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 312340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 312340,
    "out:DH kr savings": 312340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 261425,
    "out:% savings (space heating)": 53.97,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 93.6,
    "out:Primary Energy": 96.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.83,
    "out:CO2 Operational/m2": 31.96,
    "out:Total CO2/m2": 49.79,
    "out:Total CO2 (tons)": 160.33,
    "out:Klimatpaverkan": -58.5,
    "out:Initial Cost/MSEK": 4.535,
    "out:Running cost/(Apt SEK y)": 24590,
    "out:Running Cost over RSP/MSEK": 6.92,
    "out:LCP/MSEK": 3.071,
    "out:ROI% old": 23.96,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 972,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 322000,
    "out:DH kr savings": 322000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 250420,
    "out:% savings (space heating)": 55.91,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.19,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 98.6,
    "out:Total Energy Use Post PV": 95.91,
    "out:Primary Energy": 97.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.72,
    "out:CO2 Operational/m2": 9.28,
    "out:Total CO2/m2": 35,
    "out:Total CO2 (tons)": 112.7,
    "out:Klimatpaverkan": -106.13,
    "out:Initial Cost/MSEK": 4.764,
    "out:Running cost/(Apt SEK y)": 24821,
    "out:Running Cost over RSP/MSEK": 6.993,
    "out:LCP/MSEK": 2.768,
    "out:ROI% old": 22.59,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 91,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 964,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 305900,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 346216,
    "out:DH kr savings": 305900,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 261425,
    "out:% savings (space heating)": 53.97,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 91.91,
    "out:Primary Energy": 94.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.72,
    "out:CO2 Operational/m2": 7.84,
    "out:Total CO2/m2": 33.56,
    "out:Total CO2 (tons)": 108.06,
    "out:Klimatpaverkan": -110.77,
    "out:Initial Cost/MSEK": 4.845,
    "out:Running cost/(Apt SEK y)": 23740,
    "out:Running Cost over RSP/MSEK": 6.689,
    "out:LCP/MSEK": 2.992,
    "out:ROI% old": 23.11,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 359096,
    "out:DH kr savings": 318780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 250420,
    "out:% savings (space heating)": 55.91,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.19,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 96.6,
    "out:Total Energy Use Post PV": 93.91,
    "out:Primary Energy": 94.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.07,
    "out:CO2 Operational/m2": 8.56,
    "out:Total CO2/m2": 34.63,
    "out:Total CO2 (tons)": 111.51,
    "out:Klimatpaverkan": -107.32,
    "out:Initial Cost/MSEK": 4.901,
    "out:Running cost/(Apt SEK y)": 24281,
    "out:Running Cost over RSP/MSEK": 6.841,
    "out:LCP/MSEK": 2.784,
    "out:ROI% old": 22.4,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 984,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 312340,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 352656,
    "out:DH kr savings": 312340,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 261425,
    "out:% savings (space heating)": 53.97,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 90.91,
    "out:Primary Energy": 91.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.07,
    "out:CO2 Operational/m2": 7.48,
    "out:Total CO2/m2": 33.55,
    "out:Total CO2 (tons)": 108.03,
    "out:Klimatpaverkan": -110.8,
    "out:Initial Cost/MSEK": 4.982,
    "out:Running cost/(Apt SEK y)": 23470,
    "out:Running Cost over RSP/MSEK": 6.613,
    "out:LCP/MSEK": 2.931,
    "out:ROI% old": 22.69,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1013,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 362316,
    "out:DH kr savings": 322000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 250420,
    "out:% savings (space heating)": 55.91,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.19,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 96.6,
    "out:Total Energy Use Post PV": 96.6,
    "out:Primary Energy": 98.72,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.48,
    "out:CO2 Operational/m2": 33.04,
    "out:Total CO2/m2": 50.52,
    "out:Total CO2 (tons)": 162.68,
    "out:Klimatpaverkan": -56.15,
    "out:Initial Cost/MSEK": 4.413,
    "out:Running cost/(Apt SEK y)": 25400,
    "out:Running Cost over RSP/MSEK": 7.148,
    "out:LCP/MSEK": 2.965,
    "out:ROI% old": 23.88,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 943,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 312340,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 312340,
    "out:DH kr savings": 312340,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 261425,
    "out:% savings (space heating)": 53.97,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 92.6,
    "out:Primary Energy": 95.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.48,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 49.08,
    "out:Total CO2 (tons)": 158.04,
    "out:Klimatpaverkan": -60.79,
    "out:Initial Cost/MSEK": 4.493,
    "out:Running cost/(Apt SEK y)": 24320,
    "out:Running Cost over RSP/MSEK": 6.844,
    "out:LCP/MSEK": 3.189,
    "out:ROI% old": 24.42,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325220,
    "out:DH kr savings": 325220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 250420,
    "out:% savings (space heating)": 55.91,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.19,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 95.6,
    "out:Total Energy Use Post PV": 95.6,
    "out:Primary Energy": 96.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.83,
    "out:CO2 Operational/m2": 32.68,
    "out:Total CO2/m2": 50.51,
    "out:Total CO2 (tons)": 162.65,
    "out:Klimatpaverkan": -56.18,
    "out:Initial Cost/MSEK": 4.55,
    "out:Running cost/(Apt SEK y)": 25130,
    "out:Running Cost over RSP/MSEK": 7.072,
    "out:LCP/MSEK": 2.905,
    "out:ROI% old": 23.41,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 953,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315560,
    "out:DH kr savings": 315560,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 261425,
    "out:% savings (space heating)": 53.97,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 91.6,
    "out:Primary Energy": 93.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.83,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 49.07,
    "out:Total CO2 (tons)": 158.01,
    "out:Klimatpaverkan": -60.82,
    "out:Initial Cost/MSEK": 4.63,
    "out:Running cost/(Apt SEK y)": 24050,
    "out:Running Cost over RSP/MSEK": 6.768,
    "out:LCP/MSEK": 3.128,
    "out:ROI% old": 23.94,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 992,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328440,
    "out:DH kr savings": 328440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 250420,
    "out:% savings (space heating)": 55.91,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.19,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 96.6,
    "out:Total Energy Use Post PV": 93.91,
    "out:Primary Energy": 93.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.72,
    "out:CO2 Operational/m2": 8.56,
    "out:Total CO2/m2": 34.28,
    "out:Total CO2 (tons)": 110.38,
    "out:Klimatpaverkan": -108.45,
    "out:Initial Cost/MSEK": 4.859,
    "out:Running cost/(Apt SEK y)": 24281,
    "out:Running Cost over RSP/MSEK": 6.841,
    "out:LCP/MSEK": 2.825,
    "out:ROI% old": 22.59,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 984,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 312340,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 352656,
    "out:DH kr savings": 312340,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 261425,
    "out:% savings (space heating)": 53.97,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 89.91,
    "out:Primary Energy": 90.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.72,
    "out:CO2 Operational/m2": 7.12,
    "out:Total CO2/m2": 32.84,
    "out:Total CO2 (tons)": 105.74,
    "out:Klimatpaverkan": -113.09,
    "out:Initial Cost/MSEK": 4.94,
    "out:Running cost/(Apt SEK y)": 23200,
    "out:Running Cost over RSP/MSEK": 6.537,
    "out:LCP/MSEK": 3.049,
    "out:ROI% old": 23.1,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1022,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 365536,
    "out:DH kr savings": 325220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 250420,
    "out:% savings (space heating)": 55.91,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.19,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 95.6,
    "out:Total Energy Use Post PV": 92.91,
    "out:Primary Energy": 91.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.07,
    "out:CO2 Operational/m2": 8.2,
    "out:Total CO2/m2": 34.27,
    "out:Total CO2 (tons)": 110.35,
    "out:Klimatpaverkan": -108.48,
    "out:Initial Cost/MSEK": 4.996,
    "out:Running cost/(Apt SEK y)": 24010,
    "out:Running Cost over RSP/MSEK": 6.765,
    "out:LCP/MSEK": 2.765,
    "out:ROI% old": 22.19,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355876,
    "out:DH kr savings": 315560,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 261425,
    "out:% savings (space heating)": 53.97,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.77,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 88.91,
    "out:Primary Energy": 88.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.07,
    "out:CO2 Operational/m2": 6.76,
    "out:Total CO2/m2": 32.83,
    "out:Total CO2 (tons)": 105.71,
    "out:Klimatpaverkan": -113.12,
    "out:Initial Cost/MSEK": 5.077,
    "out:Running cost/(Apt SEK y)": 22930,
    "out:Running Cost over RSP/MSEK": 6.461,
    "out:LCP/MSEK": 2.989,
    "out:ROI% old": 22.7,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1032,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368756,
    "out:DH kr savings": 328440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 250420,
    "out:% savings (space heating)": 55.91,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 88.84,
    "out:Total Energy Use Post PV": 88.85,
    "out:Primary Energy": 103.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.74,
    "out:CO2 Operational/m2": 28.6,
    "out:Total CO2/m2": 49.34,
    "out:Total CO2 (tons)": 158.88,
    "out:Klimatpaverkan": -59.95,
    "out:Initial Cost/MSEK": 4.929,
    "out:Running cost/(Apt SEK y)": 22644,
    "out:Running Cost over RSP/MSEK": 6.367,
    "out:LCP/MSEK": 3.23,
    "out:ROI% old": 23.65,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 360640,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 313977,
    "out:DH kr savings": 360640,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239818,
    "out:% savings (space heating)": 57.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 85.84,
    "out:Total Energy Use Post PV": 85.85,
    "out:Primary Energy": 100.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.74,
    "out:CO2 Operational/m2": 27.52,
    "out:Total CO2/m2": 48.26,
    "out:Total CO2 (tons)": 155.4,
    "out:Klimatpaverkan": -63.43,
    "out:Initial Cost/MSEK": 5.009,
    "out:Running cost/(Apt SEK y)": 21834,
    "out:Running Cost over RSP/MSEK": 6.139,
    "out:LCP/MSEK": 3.378,
    "out:ROI% old": 23.92,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1072,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323637,
    "out:DH kr savings": 370300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 228814,
    "out:% savings (space heating)": 59.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 87.83,
    "out:Total Energy Use Post PV": 87.85,
    "out:Primary Energy": 101.21,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.09,
    "out:CO2 Operational/m2": 28.24,
    "out:Total CO2/m2": 49.33,
    "out:Total CO2 (tons)": 158.84,
    "out:Klimatpaverkan": -59.99,
    "out:Initial Cost/MSEK": 5.065,
    "out:Running cost/(Apt SEK y)": 22372,
    "out:Running Cost over RSP/MSEK": 6.291,
    "out:LCP/MSEK": 3.17,
    "out:ROI% old": 23.23,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 363860,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 317197,
    "out:DH kr savings": 363860,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235314,
    "out:% savings (space heating)": 58.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 84.83,
    "out:Total Energy Use Post PV": 84.85,
    "out:Primary Energy": 98.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.09,
    "out:CO2 Operational/m2": 27.16,
    "out:Total CO2/m2": 48.25,
    "out:Total CO2 (tons)": 155.37,
    "out:Klimatpaverkan": -63.46,
    "out:Initial Cost/MSEK": 5.146,
    "out:Running cost/(Apt SEK y)": 21561,
    "out:Running Cost over RSP/MSEK": 6.063,
    "out:LCP/MSEK": 3.318,
    "out:ROI% old": 23.5,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 1038,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1081,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 326857,
    "out:DH kr savings": 373520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224314,
    "out:% savings (space heating)": 60.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.48,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 88.84,
    "out:Total Energy Use Post PV": 84.5,
    "out:Primary Energy": 96.02,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.98,
    "out:CO2 Operational/m2": 12.41,
    "out:Total CO2/m2": 41.38,
    "out:Total CO2 (tons)": 133.26,
    "out:Klimatpaverkan": -85.57,
    "out:Initial Cost/MSEK": 5.375,
    "out:Running cost/(Apt SEK y)": 21450,
    "out:Running Cost over RSP/MSEK": 6.04,
    "out:LCP/MSEK": 3.11,
    "out:ROI% old": 22.55,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1085,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.54,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.07,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 360640,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 356962,
    "out:DH kr savings": 360640,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239818,
    "out:% savings (space heating)": 57.78,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 85.84,
    "out:Total Energy Use Post PV": 81.5,
    "out:Primary Energy": 93.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.98,
    "out:CO2 Operational/m2": 11.33,
    "out:Total CO2/m2": 40.3,
    "out:Total CO2 (tons)": 129.78,
    "out:Klimatpaverkan": -89.05,
    "out:Initial Cost/MSEK": 5.456,
    "out:Running cost/(Apt SEK y)": 20640,
    "out:Running Cost over RSP/MSEK": 5.812,
    "out:LCP/MSEK": 3.258,
    "out:ROI% old": 22.82,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1115,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.56,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.06,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 366622,
    "out:DH kr savings": 370300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 228814,
    "out:% savings (space heating)": 59.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.08,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 87.83,
    "out:Total Energy Use Post PV": 83.5,
    "out:Primary Energy": 93.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.33,
    "out:CO2 Operational/m2": 12.03,
    "out:Total CO2/m2": 41.36,
    "out:Total CO2 (tons)": 133.18,
    "out:Klimatpaverkan": -85.65,
    "out:Initial Cost/MSEK": 5.512,
    "out:Running cost/(Apt SEK y)": 21178,
    "out:Running Cost over RSP/MSEK": 5.964,
    "out:LCP/MSEK": 3.05,
    "out:ROI% old": 22.19,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.21,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.41,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 363860,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 360194,
    "out:DH kr savings": 363860,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 235314,
    "out:% savings (space heating)": 58.57,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 84.83,
    "out:Total Energy Use Post PV": 80.5,
    "out:Primary Energy": 90.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.33,
    "out:CO2 Operational/m2": 10.95,
    "out:Total CO2/m2": 40.28,
    "out:Total CO2 (tons)": 129.7,
    "out:Klimatpaverkan": -89.13,
    "out:Initial Cost/MSEK": 5.592,
    "out:Running cost/(Apt SEK y)": 20368,
    "out:Running Cost over RSP/MSEK": 5.735,
    "out:LCP/MSEK": 3.198,
    "out:ROI% old": 22.46,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 741.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1124,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.47,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.15,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 369854,
    "out:DH kr savings": 373520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27944,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 224314,
    "out:% savings (space heating)": 60.51,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 87.82,
    "out:Total Energy Use Post PV": 87.85,
    "out:Primary Energy": 100.34,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.74,
    "out:CO2 Operational/m2": 28.24,
    "out:Total CO2/m2": 48.98,
    "out:Total CO2 (tons)": 157.71,
    "out:Klimatpaverkan": -61.12,
    "out:Initial Cost/MSEK": 5.024,
    "out:Running cost/(Apt SEK y)": 22371,
    "out:Running Cost over RSP/MSEK": 6.29,
    "out:LCP/MSEK": 3.212,
    "out:ROI% old": 23.42,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 363860,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 317197,
    "out:DH kr savings": 363860,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233834,
    "out:% savings (space heating)": 58.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 83.82,
    "out:Total Energy Use Post PV": 83.85,
    "out:Primary Energy": 97.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.74,
    "out:CO2 Operational/m2": 26.8,
    "out:Total CO2/m2": 47.54,
    "out:Total CO2 (tons)": 153.08,
    "out:Klimatpaverkan": -65.75,
    "out:Initial Cost/MSEK": 5.104,
    "out:Running cost/(Apt SEK y)": 21290,
    "out:Running Cost over RSP/MSEK": 5.986,
    "out:LCP/MSEK": 3.435,
    "out:ROI% old": 23.9,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330077,
    "out:DH kr savings": 376740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 222837,
    "out:% savings (space heating)": 60.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 86.8,
    "out:Total Energy Use Post PV": 86.85,
    "out:Primary Energy": 98.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.09,
    "out:CO2 Operational/m2": 27.87,
    "out:Total CO2/m2": 48.97,
    "out:Total CO2 (tons)": 157.67,
    "out:Klimatpaverkan": -61.16,
    "out:Initial Cost/MSEK": 5.16,
    "out:Running cost/(Apt SEK y)": 22097,
    "out:Running Cost over RSP/MSEK": 6.213,
    "out:LCP/MSEK": 3.152,
    "out:ROI% old": 23.01,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 1036.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1062,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 367080,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320417,
    "out:DH kr savings": 367080,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 230346,
    "out:% savings (space heating)": 59.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 82.8,
    "out:Total Energy Use Post PV": 82.85,
    "out:Primary Energy": 95.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.09,
    "out:CO2 Operational/m2": 26.43,
    "out:Total CO2/m2": 47.53,
    "out:Total CO2 (tons)": 153.03,
    "out:Klimatpaverkan": -65.8,
    "out:Initial Cost/MSEK": 5.241,
    "out:Running cost/(Apt SEK y)": 21016,
    "out:Running Cost over RSP/MSEK": 5.909,
    "out:LCP/MSEK": 3.376,
    "out:ROI% old": 23.49,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1036,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 333297,
    "out:DH kr savings": 379960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 219362,
    "out:% savings (space heating)": 61.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.62,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 87.82,
    "out:Total Energy Use Post PV": 83.5,
    "out:Primary Energy": 92.52,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.98,
    "out:CO2 Operational/m2": 12.03,
    "out:Total CO2/m2": 41,
    "out:Total CO2 (tons)": 132.03,
    "out:Klimatpaverkan": -86.8,
    "out:Initial Cost/MSEK": 5.47,
    "out:Running cost/(Apt SEK y)": 21177,
    "out:Running Cost over RSP/MSEK": 5.963,
    "out:LCP/MSEK": 3.092,
    "out:ROI% old": 22.36,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1095,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.04,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.58,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 363860,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 360199,
    "out:DH kr savings": 363860,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 233834,
    "out:% savings (space heating)": 58.83,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.2,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 83.82,
    "out:Total Energy Use Post PV": 79.5,
    "out:Primary Energy": 89.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.98,
    "out:CO2 Operational/m2": 10.58,
    "out:Total CO2/m2": 39.56,
    "out:Total CO2 (tons)": 127.39,
    "out:Klimatpaverkan": -91.44,
    "out:Initial Cost/MSEK": 5.551,
    "out:Running cost/(Apt SEK y)": 20096,
    "out:Running Cost over RSP/MSEK": 5.659,
    "out:LCP/MSEK": 3.316,
    "out:ROI% old": 22.82,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.48,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.14,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 373080,
    "out:DH kr savings": 376740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27936,
    "out:El kr return": 42974,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 222837,
    "out:% savings (space heating)": 60.77,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.54,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 86.8,
    "out:Total Energy Use Post PV": 82.5,
    "out:Primary Energy": 90.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.33,
    "out:CO2 Operational/m2": 11.64,
    "out:Total CO2/m2": 40.97,
    "out:Total CO2 (tons)": 131.93,
    "out:Klimatpaverkan": -86.9,
    "out:Initial Cost/MSEK": 5.607,
    "out:Running cost/(Apt SEK y)": 20903,
    "out:Running Cost over RSP/MSEK": 5.886,
    "out:LCP/MSEK": 3.033,
    "out:ROI% old": 22.01,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 740,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1105,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10037.82,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13955.8,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 367080,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 363438,
    "out:DH kr savings": 367080,
    "out:El kr savings": -18698,
    "out:El kr sold": 15057,
    "out:El kr saved": 27912,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 230346,
    "out:% savings (space heating)": 59.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 81.19,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 82.8,
    "out:Total Energy Use Post PV": 78.5,
    "out:Primary Energy": 87.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.33,
    "out:CO2 Operational/m2": 10.2,
    "out:Total CO2/m2": 39.53,
    "out:Total CO2 (tons)": 127.29,
    "out:Klimatpaverkan": -91.54,
    "out:Initial Cost/MSEK": 5.687,
    "out:Running cost/(Apt SEK y)": 19822,
    "out:Running Cost over RSP/MSEK": 5.582,
    "out:LCP/MSEK": 3.257,
    "out:ROI% old": 22.47,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.4,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10039.04,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954.57,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 376320,
    "out:DH kr savings": 379960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15059,
    "out:El kr saved": 27909,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 219362,
    "out:% savings (space heating)": 61.38,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.77,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.6,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 94.6,
    "out:Primary Energy": 99.08,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.39,
    "out:CO2 Operational/m2": 32.32,
    "out:Total CO2/m2": 56.71,
    "out:Total CO2 (tons)": 182.6,
    "out:Klimatpaverkan": -36.23,
    "out:Initial Cost/MSEK": 5.805,
    "out:Running cost/(Apt SEK y)": 24860,
    "out:Running Cost over RSP/MSEK": 6.996,
    "out:LCP/MSEK": 1.725,
    "out:ROI% old": 18.53,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 963,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318780,
    "out:DH kr savings": 318780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 249862,
    "out:% savings (space heating)": 56.01,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.34,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 91.6,
    "out:Primary Energy": 96.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.39,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 55.63,
    "out:Total CO2 (tons)": 179.12,
    "out:Klimatpaverkan": -39.71,
    "out:Initial Cost/MSEK": 5.885,
    "out:Running cost/(Apt SEK y)": 24050,
    "out:Running Cost over RSP/MSEK": 6.768,
    "out:LCP/MSEK": 1.873,
    "out:ROI% old": 18.83,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 992,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 328440,
    "out:DH kr savings": 328440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239383,
    "out:% savings (space heating)": 57.85,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.6,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 93.6,
    "out:Primary Energy": 96.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.74,
    "out:CO2 Operational/m2": 31.96,
    "out:Total CO2/m2": 56.7,
    "out:Total CO2 (tons)": 182.57,
    "out:Klimatpaverkan": -36.26,
    "out:Initial Cost/MSEK": 5.941,
    "out:Running cost/(Apt SEK y)": 24590,
    "out:Running Cost over RSP/MSEK": 6.92,
    "out:LCP/MSEK": 1.665,
    "out:ROI% old": 18.29,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 972,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322000,
    "out:DH kr savings": 322000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 249862,
    "out:% savings (space heating)": 56.01,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.34,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 90.6,
    "out:Primary Energy": 93.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.74,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 55.62,
    "out:Total CO2 (tons)": 179.09,
    "out:Klimatpaverkan": -39.74,
    "out:Initial Cost/MSEK": 6.022,
    "out:Running cost/(Apt SEK y)": 23780,
    "out:Running Cost over RSP/MSEK": 6.691,
    "out:LCP/MSEK": 1.812,
    "out:ROI% old": 18.59,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1002,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331660,
    "out:DH kr savings": 331660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239383,
    "out:% savings (space heating)": 57.85,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.6,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 91.91,
    "out:Primary Energy": 94.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.62,
    "out:CO2 Operational/m2": 7.84,
    "out:Total CO2/m2": 40.47,
    "out:Total CO2 (tons)": 130.3,
    "out:Klimatpaverkan": -88.53,
    "out:Initial Cost/MSEK": 6.251,
    "out:Running cost/(Apt SEK y)": 23740,
    "out:Running Cost over RSP/MSEK": 6.689,
    "out:LCP/MSEK": 1.586,
    "out:ROI% old": 17.91,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 359096,
    "out:DH kr savings": 318780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 249862,
    "out:% savings (space heating)": 56.01,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.34,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 88.91,
    "out:Primary Energy": 91.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.62,
    "out:CO2 Operational/m2": 6.76,
    "out:Total CO2/m2": 39.39,
    "out:Total CO2 (tons)": 126.82,
    "out:Klimatpaverkan": -92.01,
    "out:Initial Cost/MSEK": 6.332,
    "out:Running cost/(Apt SEK y)": 22930,
    "out:Running Cost over RSP/MSEK": 6.461,
    "out:LCP/MSEK": 1.733,
    "out:ROI% old": 18.2,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1032,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 368756,
    "out:DH kr savings": 328440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239383,
    "out:% savings (space heating)": 57.85,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.6,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 90.91,
    "out:Primary Energy": 91.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.97,
    "out:CO2 Operational/m2": 7.48,
    "out:Total CO2/m2": 40.46,
    "out:Total CO2 (tons)": 130.27,
    "out:Klimatpaverkan": -88.56,
    "out:Initial Cost/MSEK": 6.388,
    "out:Running cost/(Apt SEK y)": 23470,
    "out:Running Cost over RSP/MSEK": 6.613,
    "out:LCP/MSEK": 1.525,
    "out:ROI% old": 17.7,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1013,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 362316,
    "out:DH kr savings": 322000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 249862,
    "out:% savings (space heating)": 56.01,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.34,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 87.91,
    "out:Primary Energy": 88.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.97,
    "out:CO2 Operational/m2": 6.4,
    "out:Total CO2/m2": 39.38,
    "out:Total CO2 (tons)": 126.79,
    "out:Klimatpaverkan": -92.04,
    "out:Initial Cost/MSEK": 6.468,
    "out:Running cost/(Apt SEK y)": 22660,
    "out:Running Cost over RSP/MSEK": 6.385,
    "out:LCP/MSEK": 1.673,
    "out:ROI% old": 17.98,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 371976,
    "out:DH kr savings": 331660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239383,
    "out:% savings (space heating)": 57.85,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.6,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 92.6,
    "out:Primary Energy": 95.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.39,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 55.99,
    "out:Total CO2 (tons)": 180.28,
    "out:Klimatpaverkan": -38.55,
    "out:Initial Cost/MSEK": 5.9,
    "out:Running cost/(Apt SEK y)": 24320,
    "out:Running Cost over RSP/MSEK": 6.844,
    "out:LCP/MSEK": 1.782,
    "out:ROI% old": 18.6,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325220,
    "out:DH kr savings": 325220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 249862,
    "out:% savings (space heating)": 56.01,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.34,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 89.6,
    "out:Primary Energy": 92.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.39,
    "out:CO2 Operational/m2": 30.52,
    "out:Total CO2/m2": 54.91,
    "out:Total CO2 (tons)": 176.81,
    "out:Klimatpaverkan": -42.02,
    "out:Initial Cost/MSEK": 5.98,
    "out:Running cost/(Apt SEK y)": 23510,
    "out:Running Cost over RSP/MSEK": 6.615,
    "out:LCP/MSEK": 1.93,
    "out:ROI% old": 18.9,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334880,
    "out:DH kr savings": 334880,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239383,
    "out:% savings (space heating)": 57.85,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.6,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 91.6,
    "out:Primary Energy": 93.48,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.74,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 55.98,
    "out:Total CO2 (tons)": 180.25,
    "out:Klimatpaverkan": -38.58,
    "out:Initial Cost/MSEK": 6.036,
    "out:Running cost/(Apt SEK y)": 24050,
    "out:Running Cost over RSP/MSEK": 6.768,
    "out:LCP/MSEK": 1.722,
    "out:ROI% old": 18.36,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 992,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328440,
    "out:DH kr savings": 328440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 249862,
    "out:% savings (space heating)": 56.01,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.34,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 88.6,
    "out:Primary Energy": 90.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.74,
    "out:CO2 Operational/m2": 30.16,
    "out:Total CO2/m2": 54.9,
    "out:Total CO2 (tons)": 176.77,
    "out:Klimatpaverkan": -42.06,
    "out:Initial Cost/MSEK": 6.117,
    "out:Running cost/(Apt SEK y)": 23239,
    "out:Running Cost over RSP/MSEK": 6.539,
    "out:LCP/MSEK": 1.87,
    "out:ROI% old": 18.65,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1021,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338100,
    "out:DH kr savings": 338100,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239383,
    "out:% savings (space heating)": 57.85,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01011200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.6,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 89.91,
    "out:Primary Energy": 90.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.62,
    "out:CO2 Operational/m2": 7.12,
    "out:Total CO2/m2": 39.75,
    "out:Total CO2 (tons)": 127.98,
    "out:Klimatpaverkan": -90.85,
    "out:Initial Cost/MSEK": 6.346,
    "out:Running cost/(Apt SEK y)": 23200,
    "out:Running Cost over RSP/MSEK": 6.537,
    "out:LCP/MSEK": 1.643,
    "out:ROI% old": 17.98,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1022,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 365536,
    "out:DH kr savings": 325220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 249862,
    "out:% savings (space heating)": 56.01,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.34,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 86.91,
    "out:Primary Energy": 87.89,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.62,
    "out:CO2 Operational/m2": 6.04,
    "out:Total CO2/m2": 38.67,
    "out:Total CO2 (tons)": 124.5,
    "out:Klimatpaverkan": -94.33,
    "out:Initial Cost/MSEK": 6.427,
    "out:Running cost/(Apt SEK y)": 22390,
    "out:Running Cost over RSP/MSEK": 6.309,
    "out:LCP/MSEK": 1.79,
    "out:ROI% old": 18.27,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 375196,
    "out:DH kr savings": 334880,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239383,
    "out:% savings (space heating)": 57.85,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 77.6,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 88.91,
    "out:Primary Energy": 88.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.97,
    "out:CO2 Operational/m2": 6.76,
    "out:Total CO2/m2": 39.74,
    "out:Total CO2 (tons)": 127.95,
    "out:Klimatpaverkan": -90.88,
    "out:Initial Cost/MSEK": 6.483,
    "out:Running cost/(Apt SEK y)": 22930,
    "out:Running Cost over RSP/MSEK": 6.461,
    "out:LCP/MSEK": 1.582,
    "out:ROI% old": 17.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1032,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 368756,
    "out:DH kr savings": 328440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 249862,
    "out:% savings (space heating)": 56.01,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 74.34,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 85.91,
    "out:Primary Energy": 85.79,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.97,
    "out:CO2 Operational/m2": 5.68,
    "out:Total CO2/m2": 38.66,
    "out:Total CO2 (tons)": 124.47,
    "out:Klimatpaverkan": -94.36,
    "out:Initial Cost/MSEK": 6.563,
    "out:Running cost/(Apt SEK y)": 22120,
    "out:Running Cost over RSP/MSEK": 6.233,
    "out:LCP/MSEK": 1.73,
    "out:ROI% old": 18.05,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1061,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378416,
    "out:DH kr savings": 338100,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 239383,
    "out:% savings (space heating)": 57.85,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01011201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 85.84,
    "out:Total Energy Use Post PV": 85.85,
    "out:Primary Energy": 100.69,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.65,
    "out:CO2 Operational/m2": 27.52,
    "out:Total CO2/m2": 55.17,
    "out:Total CO2 (tons)": 177.64,
    "out:Klimatpaverkan": -41.19,
    "out:Initial Cost/MSEK": 6.415,
    "out:Running cost/(Apt SEK y)": 21834,
    "out:Running Cost over RSP/MSEK": 6.139,
    "out:LCP/MSEK": 1.971,
    "out:ROI% old": 18.68,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1072,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323637,
    "out:DH kr savings": 370300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 228254,
    "out:% savings (space heating)": 59.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 82.84,
    "out:Total Energy Use Post PV": 82.85,
    "out:Primary Energy": 97.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.65,
    "out:CO2 Operational/m2": 26.44,
    "out:Total CO2/m2": 54.09,
    "out:Total CO2 (tons)": 174.16,
    "out:Klimatpaverkan": -44.67,
    "out:Initial Cost/MSEK": 6.496,
    "out:Running cost/(Apt SEK y)": 21023,
    "out:Running Cost over RSP/MSEK": 5.911,
    "out:LCP/MSEK": 2.119,
    "out:ROI% old": 18.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 333297,
    "out:DH kr savings": 379960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 217776,
    "out:% savings (space heating)": 61.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 83.83,
    "out:Total Energy Use Post PV": 83.85,
    "out:Primary Energy": 98.07,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28,
    "out:CO2 Operational/m2": 26.8,
    "out:Total CO2/m2": 54.8,
    "out:Total CO2 (tons)": 176.44,
    "out:Klimatpaverkan": -42.39,
    "out:Initial Cost/MSEK": 6.552,
    "out:Running cost/(Apt SEK y)": 21291,
    "out:Running Cost over RSP/MSEK": 5.987,
    "out:LCP/MSEK": 1.987,
    "out:ROI% old": 18.62,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330077,
    "out:DH kr savings": 376740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 223748,
    "out:% savings (space heating)": 60.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 80.83,
    "out:Total Energy Use Post PV": 80.85,
    "out:Primary Energy": 95.22,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28,
    "out:CO2 Operational/m2": 25.72,
    "out:Total CO2/m2": 53.72,
    "out:Total CO2 (tons)": 172.97,
    "out:Klimatpaverkan": -45.86,
    "out:Initial Cost/MSEK": 6.633,
    "out:Running cost/(Apt SEK y)": 20481,
    "out:Running Cost over RSP/MSEK": 5.758,
    "out:LCP/MSEK": 2.135,
    "out:ROI% old": 18.88,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1120,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 339737,
    "out:DH kr savings": 386400,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 213273,
    "out:% savings (space heating)": 62.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.89,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 85.84,
    "out:Total Energy Use Post PV": 81.5,
    "out:Primary Energy": 92.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.88,
    "out:CO2 Operational/m2": 11.33,
    "out:Total CO2/m2": 47.21,
    "out:Total CO2 (tons)": 152.02,
    "out:Klimatpaverkan": -66.81,
    "out:Initial Cost/MSEK": 6.862,
    "out:Running cost/(Apt SEK y)": 20640,
    "out:Running Cost over RSP/MSEK": 5.812,
    "out:LCP/MSEK": 1.852,
    "out:ROI% old": 18.14,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1115,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.49,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 366622,
    "out:DH kr savings": 370300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 228254,
    "out:% savings (space heating)": 59.81,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.63,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 82.84,
    "out:Total Energy Use Post PV": 78.5,
    "out:Primary Energy": 90.03,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.88,
    "out:CO2 Operational/m2": 10.25,
    "out:Total CO2/m2": 46.13,
    "out:Total CO2 (tons)": 148.54,
    "out:Klimatpaverkan": -70.29,
    "out:Initial Cost/MSEK": 6.943,
    "out:Running cost/(Apt SEK y)": 19830,
    "out:Running Cost over RSP/MSEK": 5.584,
    "out:LCP/MSEK": 1.999,
    "out:ROI% old": 18.4,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.51,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.11,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 376282,
    "out:DH kr savings": 379960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 217776,
    "out:% savings (space heating)": 61.66,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.49,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 83.83,
    "out:Total Energy Use Post PV": 79.5,
    "out:Primary Energy": 90.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.24,
    "out:CO2 Operational/m2": 10.59,
    "out:Total CO2/m2": 46.83,
    "out:Total CO2 (tons)": 150.79,
    "out:Klimatpaverkan": -68.04,
    "out:Initial Cost/MSEK": 6.999,
    "out:Running cost/(Apt SEK y)": 20098,
    "out:Running Cost over RSP/MSEK": 5.659,
    "out:LCP/MSEK": 1.868,
    "out:ROI% old": 18.1,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.02,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.6,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373073,
    "out:DH kr savings": 376740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 223748,
    "out:% savings (space heating)": 60.6,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.23,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 80.83,
    "out:Total Energy Use Post PV": 76.5,
    "out:Primary Energy": 87.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.24,
    "out:CO2 Operational/m2": 9.51,
    "out:Total CO2/m2": 45.75,
    "out:Total CO2 (tons)": 147.31,
    "out:Klimatpaverkan": -71.52,
    "out:Initial Cost/MSEK": 7.079,
    "out:Running cost/(Apt SEK y)": 19287,
    "out:Running Cost over RSP/MSEK": 5.431,
    "out:LCP/MSEK": 2.016,
    "out:ROI% old": 18.35,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 151.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1163,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.28,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.34,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382734,
    "out:DH kr savings": 386400,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 213273,
    "out:% savings (space heating)": 62.45,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 83.82,
    "out:Total Energy Use Post PV": 83.85,
    "out:Primary Energy": 97.19,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.65,
    "out:CO2 Operational/m2": 26.8,
    "out:Total CO2/m2": 54.45,
    "out:Total CO2 (tons)": 175.31,
    "out:Klimatpaverkan": -43.52,
    "out:Initial Cost/MSEK": 6.51,
    "out:Running cost/(Apt SEK y)": 21290,
    "out:Running Cost over RSP/MSEK": 5.986,
    "out:LCP/MSEK": 2.029,
    "out:ROI% old": 18.74,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330077,
    "out:DH kr savings": 376740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 222268,
    "out:% savings (space heating)": 60.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 80.82,
    "out:Total Energy Use Post PV": 80.85,
    "out:Primary Energy": 94.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.65,
    "out:CO2 Operational/m2": 25.72,
    "out:Total CO2/m2": 53.37,
    "out:Total CO2 (tons)": 171.84,
    "out:Klimatpaverkan": -46.99,
    "out:Initial Cost/MSEK": 6.591,
    "out:Running cost/(Apt SEK y)": 20480,
    "out:Running Cost over RSP/MSEK": 5.758,
    "out:LCP/MSEK": 2.177,
    "out:ROI% old": 19,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1120,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 339737,
    "out:DH kr savings": 386400,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 211796,
    "out:% savings (space heating)": 62.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 82.8,
    "out:Total Energy Use Post PV": 82.85,
    "out:Primary Energy": 95.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28,
    "out:CO2 Operational/m2": 26.43,
    "out:Total CO2/m2": 54.43,
    "out:Total CO2 (tons)": 175.27,
    "out:Klimatpaverkan": -43.56,
    "out:Initial Cost/MSEK": 6.647,
    "out:Running cost/(Apt SEK y)": 21016,
    "out:Running Cost over RSP/MSEK": 5.909,
    "out:LCP/MSEK": 1.97,
    "out:ROI% old": 18.52,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1036.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 333297,
    "out:DH kr savings": 379960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 218777,
    "out:% savings (space heating)": 61.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 79.8,
    "out:Total Energy Use Post PV": 79.85,
    "out:Primary Energy": 92.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28,
    "out:CO2 Operational/m2": 25.35,
    "out:Total CO2/m2": 53.35,
    "out:Total CO2 (tons)": 171.8,
    "out:Klimatpaverkan": -47.03,
    "out:Initial Cost/MSEK": 6.728,
    "out:Running cost/(Apt SEK y)": 20206,
    "out:Running Cost over RSP/MSEK": 5.681,
    "out:LCP/MSEK": 2.117,
    "out:ROI% old": 18.78,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 1036.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1130,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342957,
    "out:DH kr savings": 389620,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 208319,
    "out:% savings (space heating)": 63.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01011201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.03,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 83.82,
    "out:Total Energy Use Post PV": 79.5,
    "out:Primary Energy": 89.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.88,
    "out:CO2 Operational/m2": 10.59,
    "out:Total CO2/m2": 46.47,
    "out:Total CO2 (tons)": 149.64,
    "out:Klimatpaverkan": -69.19,
    "out:Initial Cost/MSEK": 6.957,
    "out:Running cost/(Apt SEK y)": 20096,
    "out:Running Cost over RSP/MSEK": 5.659,
    "out:LCP/MSEK": 1.91,
    "out:ROI% old": 18.21,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.8,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.82,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373079,
    "out:DH kr savings": 376740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15037,
    "out:El kr saved": 27938,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 222268,
    "out:% savings (space heating)": 60.87,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 80.82,
    "out:Total Energy Use Post PV": 76.5,
    "out:Primary Energy": 86.53,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 35.88,
    "out:CO2 Operational/m2": 9.51,
    "out:Total CO2/m2": 45.39,
    "out:Total CO2 (tons)": 146.16,
    "out:Klimatpaverkan": -72.67,
    "out:Initial Cost/MSEK": 7.038,
    "out:Running cost/(Apt SEK y)": 19286,
    "out:Running Cost over RSP/MSEK": 5.431,
    "out:LCP/MSEK": 2.057,
    "out:ROI% old": 18.46,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 77,
    "out:Energy use kWh/m2": 77,
    "out:Energy savings %": 151.9,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 66,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1163,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.24,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.38,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 386400,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382740,
    "out:DH kr savings": 386400,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 211796,
    "out:% savings (space heating)": 62.71,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.94,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 82.8,
    "out:Total Energy Use Post PV": 78.5,
    "out:Primary Energy": 87.28,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.24,
    "out:CO2 Operational/m2": 10.2,
    "out:Total CO2/m2": 46.44,
    "out:Total CO2 (tons)": 149.53,
    "out:Klimatpaverkan": -69.3,
    "out:Initial Cost/MSEK": 7.094,
    "out:Running cost/(Apt SEK y)": 19823,
    "out:Running Cost over RSP/MSEK": 5.582,
    "out:LCP/MSEK": 1.85,
    "out:ROI% old": 18.01,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 740.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10037.48,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13956.14,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 376318,
    "out:DH kr savings": 379960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15056,
    "out:El kr saved": 27912,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 218777,
    "out:% savings (space heating)": 61.48,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.6,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01011201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 1,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.7,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 79.8,
    "out:Total Energy Use Post PV": 75.5,
    "out:Primary Energy": 84.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.24,
    "out:CO2 Operational/m2": 9.12,
    "out:Total CO2/m2": 45.36,
    "out:Total CO2 (tons)": 146.05,
    "out:Klimatpaverkan": -72.78,
    "out:Initial Cost/MSEK": 7.174,
    "out:Running cost/(Apt SEK y)": 19012,
    "out:Running Cost over RSP/MSEK": 5.354,
    "out:LCP/MSEK": 1.998,
    "out:ROI% old": 18.26,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.34,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 155.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10038.7,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954.91,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.16,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 541.09,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385980,
    "out:DH kr savings": 389620,
    "out:El kr savings": -18698,
    "out:El kr sold": 15058,
    "out:El kr saved": 27910,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 208319,
    "out:% savings (space heating)": 63.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.34,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012000000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.33,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 112.6,
    "out:Total Energy Use Post PV": 112.6,
    "out:Primary Energy": 114.59,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.1,
    "out:CO2 Operational/m2": 38.8,
    "out:Total CO2/m2": 53.9,
    "out:Total CO2 (tons)": 173.56,
    "out:Klimatpaverkan": -45.27,
    "out:Initial Cost/MSEK": 3.73,
    "out:Running cost/(Apt SEK y)": 29722,
    "out:Running Cost over RSP/MSEK": 8.365,
    "out:LCP/MSEK": 2.431,
    "out:ROI% old": 23.6,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 113,
    "out:Energy use kWh/m2": 113,
    "out:Energy savings %": 71.7,
    "out:Op energy cost/MSEK": 61,
    "out:El price/MSEK": 37,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 788,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 260820,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 260820,
    "out:DH kr savings": 260820,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 306947,
    "out:% savings (space heating)": 45.96,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012000000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.26,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 108.6,
    "out:Total Energy Use Post PV": 108.6,
    "out:Primary Energy": 111.04,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.1,
    "out:CO2 Operational/m2": 37.36,
    "out:Total CO2/m2": 52.46,
    "out:Total CO2 (tons)": 168.93,
    "out:Klimatpaverkan": -49.9,
    "out:Initial Cost/MSEK": 3.811,
    "out:Running cost/(Apt SEK y)": 28642,
    "out:Running Cost over RSP/MSEK": 8.061,
    "out:LCP/MSEK": 2.655,
    "out:ROI% old": 24.24,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 109,
    "out:Energy use kWh/m2": 109,
    "out:Energy savings %": 78,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 827,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 273700,
    "out:DH kr savings": 273700,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 293873,
    "out:% savings (space heating)": 48.26,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012000000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.33,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 110.6,
    "out:Total Energy Use Post PV": 110.6,
    "out:Primary Energy": 111.96,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.45,
    "out:CO2 Operational/m2": 38.08,
    "out:Total CO2/m2": 53.53,
    "out:Total CO2 (tons)": 172.37,
    "out:Klimatpaverkan": -46.46,
    "out:Initial Cost/MSEK": 3.867,
    "out:Running cost/(Apt SEK y)": 29182,
    "out:Running Cost over RSP/MSEK": 8.213,
    "out:LCP/MSEK": 2.446,
    "out:ROI% old": 23.32,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267260,
    "out:DH kr savings": 267260,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 306947,
    "out:% savings (space heating)": 45.96,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012000000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.26,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 106.6,
    "out:Primary Energy": 108.41,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.45,
    "out:CO2 Operational/m2": 36.64,
    "out:Total CO2/m2": 52.09,
    "out:Total CO2 (tons)": 167.74,
    "out:Klimatpaverkan": -51.09,
    "out:Initial Cost/MSEK": 3.947,
    "out:Running cost/(Apt SEK y)": 28102,
    "out:Running Cost over RSP/MSEK": 7.908,
    "out:LCP/MSEK": 2.67,
    "out:ROI% old": 23.95,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280140,
    "out:DH kr savings": 280140,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 293873,
    "out:% savings (space heating)": 48.26,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012000010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.33,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 112.6,
    "out:Total Energy Use Post PV": 109.91,
    "out:Primary Energy": 109.75,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.34,
    "out:CO2 Operational/m2": 14.32,
    "out:Total CO2/m2": 37.66,
    "out:Total CO2 (tons)": 121.26,
    "out:Klimatpaverkan": -97.57,
    "out:Initial Cost/MSEK": 4.177,
    "out:Running cost/(Apt SEK y)": 28603,
    "out:Running Cost over RSP/MSEK": 8.058,
    "out:LCP/MSEK": 2.291,
    "out:ROI% old": 22.12,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 59,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 21,
    "out:DH+ DHW/kWh/m2": 105,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 828,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 260820,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 301136,
    "out:DH kr savings": 260820,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 306947,
    "out:% savings (space heating)": 45.96,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012000010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.26,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 108.6,
    "out:Total Energy Use Post PV": 105.91,
    "out:Primary Energy": 106.2,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.34,
    "out:CO2 Operational/m2": 12.88,
    "out:Total CO2/m2": 36.22,
    "out:Total CO2 (tons)": 116.62,
    "out:Klimatpaverkan": -102.21,
    "out:Initial Cost/MSEK": 4.257,
    "out:Running cost/(Apt SEK y)": 27522,
    "out:Running Cost over RSP/MSEK": 7.754,
    "out:LCP/MSEK": 2.515,
    "out:ROI% old": 22.72,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 101,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 867,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 273700,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 314016,
    "out:DH kr savings": 273700,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 293873,
    "out:% savings (space heating)": 48.26,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012000010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.33,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 110.6,
    "out:Total Energy Use Post PV": 107.91,
    "out:Primary Energy": 107.13,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.69,
    "out:CO2 Operational/m2": 13.6,
    "out:Total CO2/m2": 37.29,
    "out:Total CO2 (tons)": 120.07,
    "out:Klimatpaverkan": -98.76,
    "out:Initial Cost/MSEK": 4.313,
    "out:Running cost/(Apt SEK y)": 28062,
    "out:Running Cost over RSP/MSEK": 7.906,
    "out:LCP/MSEK": 2.307,
    "out:ROI% old": 21.93,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 79.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 847,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307576,
    "out:DH kr savings": 267260,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 306947,
    "out:% savings (space heating)": 45.96,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012000010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.26,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 103.91,
    "out:Primary Energy": 103.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.69,
    "out:CO2 Operational/m2": 12.16,
    "out:Total CO2/m2": 35.85,
    "out:Total CO2 (tons)": 115.43,
    "out:Klimatpaverkan": -103.4,
    "out:Initial Cost/MSEK": 4.394,
    "out:Running cost/(Apt SEK y)": 26982,
    "out:Running Cost over RSP/MSEK": 7.602,
    "out:LCP/MSEK": 2.53,
    "out:ROI% old": 22.51,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320456,
    "out:DH kr savings": 280140,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 293873,
    "out:% savings (space heating)": 48.26,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012000100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.33,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 110.6,
    "out:Total Energy Use Post PV": 110.6,
    "out:Primary Energy": 111.09,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.1,
    "out:CO2 Operational/m2": 38.08,
    "out:Total CO2/m2": 53.18,
    "out:Total CO2 (tons)": 171.25,
    "out:Klimatpaverkan": -47.58,
    "out:Initial Cost/MSEK": 3.825,
    "out:Running cost/(Apt SEK y)": 29182,
    "out:Running Cost over RSP/MSEK": 8.213,
    "out:LCP/MSEK": 2.488,
    "out:ROI% old": 23.58,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 111,
    "out:Energy use kWh/m2": 111,
    "out:Energy savings %": 74.8,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 807,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 267260,
    "out:DH kr savings": 267260,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 306947,
    "out:% savings (space heating)": 45.96,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012000100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.26,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 106.6,
    "out:Primary Energy": 107.54,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.1,
    "out:CO2 Operational/m2": 36.64,
    "out:Total CO2/m2": 51.74,
    "out:Total CO2 (tons)": 166.61,
    "out:Klimatpaverkan": -52.22,
    "out:Initial Cost/MSEK": 3.906,
    "out:Running cost/(Apt SEK y)": 28102,
    "out:Running Cost over RSP/MSEK": 7.908,
    "out:LCP/MSEK": 2.712,
    "out:ROI% old": 24.2,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 846,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 280140,
    "out:DH kr savings": 280140,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 293873,
    "out:% savings (space heating)": 48.26,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012000100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.33,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 109.6,
    "out:Total Energy Use Post PV": 109.6,
    "out:Primary Energy": 108.99,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.45,
    "out:CO2 Operational/m2": 37.72,
    "out:Total CO2/m2": 53.17,
    "out:Total CO2 (tons)": 171.21,
    "out:Klimatpaverkan": -47.62,
    "out:Initial Cost/MSEK": 3.962,
    "out:Running cost/(Apt SEK y)": 28912,
    "out:Running Cost over RSP/MSEK": 8.137,
    "out:LCP/MSEK": 2.427,
    "out:ROI% old": 23.04,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 110,
    "out:Energy use kWh/m2": 110,
    "out:Energy savings %": 76.4,
    "out:Op energy cost/MSEK": 60,
    "out:El price/MSEK": 36,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 817,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 270480,
    "out:DH kr savings": 270480,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 306947,
    "out:% savings (space heating)": 45.96,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012000100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.26,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 105.6,
    "out:Total Energy Use Post PV": 105.6,
    "out:Primary Energy": 105.44,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 15.45,
    "out:CO2 Operational/m2": 36.28,
    "out:Total CO2/m2": 51.73,
    "out:Total CO2 (tons)": 166.58,
    "out:Klimatpaverkan": -52.25,
    "out:Initial Cost/MSEK": 4.042,
    "out:Running cost/(Apt SEK y)": 27832,
    "out:Running Cost over RSP/MSEK": 7.832,
    "out:LCP/MSEK": 2.651,
    "out:ROI% old": 23.66,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 106,
    "out:Energy use kWh/m2": 106,
    "out:Energy savings %": 83,
    "out:Op energy cost/MSEK": 57,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 856,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 283360,
    "out:DH kr savings": 283360,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 293873,
    "out:% savings (space heating)": 48.26,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012000110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.33,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 110.6,
    "out:Total Energy Use Post PV": 107.91,
    "out:Primary Energy": 106.25,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.34,
    "out:CO2 Operational/m2": 13.6,
    "out:Total CO2/m2": 36.94,
    "out:Total CO2 (tons)": 118.94,
    "out:Klimatpaverkan": -99.89,
    "out:Initial Cost/MSEK": 4.272,
    "out:Running cost/(Apt SEK y)": 28062,
    "out:Running Cost over RSP/MSEK": 7.906,
    "out:LCP/MSEK": 2.348,
    "out:ROI% old": 22.14,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 108,
    "out:Energy use kWh/m2": 108,
    "out:Energy savings %": 79.6,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 35,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 103,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 847,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 267260,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 307576,
    "out:DH kr savings": 267260,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 306947,
    "out:% savings (space heating)": 45.96,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012000110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.26,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 106.6,
    "out:Total Energy Use Post PV": 103.91,
    "out:Primary Energy": 102.7,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.34,
    "out:CO2 Operational/m2": 12.16,
    "out:Total CO2/m2": 35.5,
    "out:Total CO2 (tons)": 114.3,
    "out:Klimatpaverkan": -104.53,
    "out:Initial Cost/MSEK": 4.352,
    "out:Running cost/(Apt SEK y)": 26982,
    "out:Running Cost over RSP/MSEK": 7.602,
    "out:LCP/MSEK": 2.572,
    "out:ROI% old": 22.73,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 56,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 99,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 886,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 280140,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 320456,
    "out:DH kr savings": 280140,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 293873,
    "out:% savings (space heating)": 48.26,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012000110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 95.33,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 109.6,
    "out:Total Energy Use Post PV": 106.91,
    "out:Primary Energy": 104.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 23.69,
    "out:CO2 Operational/m2": 13.24,
    "out:Total CO2/m2": 36.93,
    "out:Total CO2 (tons)": 118.91,
    "out:Klimatpaverkan": -99.92,
    "out:Initial Cost/MSEK": 4.408,
    "out:Running cost/(Apt SEK y)": 27792,
    "out:Running Cost over RSP/MSEK": 7.83,
    "out:LCP/MSEK": 2.288,
    "out:ROI% old": 21.7,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 107,
    "out:Energy use kWh/m2": 107,
    "out:Energy savings %": 81.3,
    "out:Op energy cost/MSEK": 58,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 20,
    "out:DH+ DHW/kWh/m2": 102,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 19,
    "out:Return/kSEK/y": 857,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 270480,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 310796,
    "out:DH kr savings": 270480,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 306947,
    "out:% savings (space heating)": 45.96,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012000110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 91.26,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 105.6,
    "out:Total Energy Use Post PV": 102.91,
    "out:Primary Energy": 100.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 23.69,
    "out:CO2 Operational/m2": 11.8,
    "out:Total CO2/m2": 35.49,
    "out:Total CO2 (tons)": 114.27,
    "out:Klimatpaverkan": -104.56,
    "out:Initial Cost/MSEK": 4.489,
    "out:Running cost/(Apt SEK y)": 26712,
    "out:Running Cost over RSP/MSEK": 7.526,
    "out:LCP/MSEK": 2.511,
    "out:ROI% old": 22.28,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 103,
    "out:Energy use kWh/m2": 103,
    "out:Energy savings %": 88.3,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 19,
    "out:DH+ DHW/kWh/m2": 98,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 896,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 283360,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 323676,
    "out:DH kr savings": 283360,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 293873,
    "out:% savings (space heating)": 48.26,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012001000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 103.84,
    "out:Total Energy Use Post PV": 103.85,
    "out:Primary Energy": 116.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.36,
    "out:CO2 Operational/m2": 34,
    "out:Total CO2/m2": 52.36,
    "out:Total CO2 (tons)": 168.6,
    "out:Klimatpaverkan": -50.23,
    "out:Initial Cost/MSEK": 4.341,
    "out:Running cost/(Apt SEK y)": 26696,
    "out:Running Cost over RSP/MSEK": 7.508,
    "out:LCP/MSEK": 2.677,
    "out:ROI% old": 23.09,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 104,
    "out:Energy use kWh/m2": 104,
    "out:Energy savings %": 86.5,
    "out:Op energy cost/MSEK": 55,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 21,
    "out:Return/kSEK/y": 897,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 312340,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 265677,
    "out:DH kr savings": 312340,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 285337,
    "out:% savings (space heating)": 49.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012001000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 99.84,
    "out:Total Energy Use Post PV": 99.85,
    "out:Primary Energy": 112.65,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.36,
    "out:CO2 Operational/m2": 32.56,
    "out:Total CO2/m2": 50.92,
    "out:Total CO2 (tons)": 163.97,
    "out:Klimatpaverkan": -54.86,
    "out:Initial Cost/MSEK": 4.421,
    "out:Running cost/(Apt SEK y)": 25616,
    "out:Running Cost over RSP/MSEK": 7.204,
    "out:LCP/MSEK": 2.9,
    "out:ROI% old": 23.66,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 1039,
    "out:Return %": 21,
    "out:Return/kSEK/y": 936,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 278557,
    "out:DH kr savings": 325220,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 272263,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012001000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 101.83,
    "out:Total Energy Use Post PV": 101.85,
    "out:Primary Energy": 113.58,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.71,
    "out:CO2 Operational/m2": 33.28,
    "out:Total CO2/m2": 51.99,
    "out:Total CO2 (tons)": 167.41,
    "out:Klimatpaverkan": -51.42,
    "out:Initial Cost/MSEK": 4.477,
    "out:Running cost/(Apt SEK y)": 26154,
    "out:Running Cost over RSP/MSEK": 7.356,
    "out:LCP/MSEK": 2.693,
    "out:ROI% old": 22.88,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 90.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1038.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 916,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272117,
    "out:DH kr savings": 318780,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 280820,
    "out:% savings (space heating)": 50.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012001000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 97.83,
    "out:Total Energy Use Post PV": 97.85,
    "out:Primary Energy": 110.03,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.71,
    "out:CO2 Operational/m2": 31.84,
    "out:Total CO2/m2": 50.55,
    "out:Total CO2 (tons)": 162.77,
    "out:Klimatpaverkan": -56.06,
    "out:Initial Cost/MSEK": 4.558,
    "out:Running cost/(Apt SEK y)": 25073,
    "out:Running Cost over RSP/MSEK": 7.051,
    "out:LCP/MSEK": 2.916,
    "out:ROI% old": 23.43,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 98,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284997,
    "out:DH kr savings": 331660,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 267750,
    "out:% savings (space heating)": 52.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012001010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 88.61,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 103.84,
    "out:Total Energy Use Post PV": 99.5,
    "out:Primary Energy": 108.39,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.6,
    "out:CO2 Operational/m2": 17.81,
    "out:Total CO2/m2": 44.4,
    "out:Total CO2 (tons)": 142.98,
    "out:Klimatpaverkan": -75.85,
    "out:Initial Cost/MSEK": 4.787,
    "out:Running cost/(Apt SEK y)": 25502,
    "out:Running Cost over RSP/MSEK": 7.181,
    "out:LCP/MSEK": 2.557,
    "out:ROI% old": 21.92,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 100,
    "out:Energy use kWh/m2": 100,
    "out:Energy savings %": 94,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 89,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 940,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.36,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.26,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 312340,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 308662,
    "out:DH kr savings": 312340,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27961,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 285337,
    "out:% savings (space heating)": 49.76,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012001010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 84.55,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 99.84,
    "out:Total Energy Use Post PV": 95.5,
    "out:Primary Energy": 104.84,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.6,
    "out:CO2 Operational/m2": 16.37,
    "out:Total CO2/m2": 42.96,
    "out:Total CO2 (tons)": 138.35,
    "out:Klimatpaverkan": -80.48,
    "out:Initial Cost/MSEK": 4.868,
    "out:Running cost/(Apt SEK y)": 24422,
    "out:Running Cost over RSP/MSEK": 6.877,
    "out:LCP/MSEK": 2.781,
    "out:ROI% old": 22.45,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 979,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.37,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.24,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 321542,
    "out:DH kr savings": 325220,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42981,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 272263,
    "out:% savings (space heating)": 52.06,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012001010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 87.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 101.83,
    "out:Total Energy Use Post PV": 97.5,
    "out:Primary Energy": 105.76,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.95,
    "out:CO2 Operational/m2": 17.07,
    "out:Total CO2/m2": 44.02,
    "out:Total CO2 (tons)": 141.75,
    "out:Klimatpaverkan": -77.08,
    "out:Initial Cost/MSEK": 4.924,
    "out:Running cost/(Apt SEK y)": 24960,
    "out:Running Cost over RSP/MSEK": 7.029,
    "out:LCP/MSEK": 2.573,
    "out:ROI% old": 21.75,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 98,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 741.7,
    "out:Return %": 19,
    "out:Return/kSEK/y": 959,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.29,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.33,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315112,
    "out:DH kr savings": 318780,
    "out:El kr savings": -18698,
    "out:El kr sold": 15030,
    "out:El kr saved": 27947,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 280820,
    "out:% savings (space heating)": 50.56,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012001010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 83.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 97.83,
    "out:Total Energy Use Post PV": 93.5,
    "out:Primary Energy": 102.21,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.95,
    "out:CO2 Operational/m2": 15.63,
    "out:Total CO2/m2": 42.58,
    "out:Total CO2 (tons)": 137.11,
    "out:Klimatpaverkan": -81.72,
    "out:Initial Cost/MSEK": 5.004,
    "out:Running cost/(Apt SEK y)": 23879,
    "out:Running Cost over RSP/MSEK": 6.724,
    "out:LCP/MSEK": 2.797,
    "out:ROI% old": 22.27,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10020.51,
    "out:PV (% sold (El))": 41.76,
    "out:PV (kWh self-consumed)": 13973.1,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327992,
    "out:DH kr savings": 331660,
    "out:El kr savings": -18698,
    "out:El kr sold": 15031,
    "out:El kr saved": 27946,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 267750,
    "out:% savings (space heating)": 52.86,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012001100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 101.83,
    "out:Total Energy Use Post PV": 101.85,
    "out:Primary Energy": 112.71,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.36,
    "out:CO2 Operational/m2": 33.28,
    "out:Total CO2/m2": 51.64,
    "out:Total CO2 (tons)": 166.28,
    "out:Klimatpaverkan": -52.55,
    "out:Initial Cost/MSEK": 4.436,
    "out:Running cost/(Apt SEK y)": 26153,
    "out:Running Cost over RSP/MSEK": 7.355,
    "out:LCP/MSEK": 2.735,
    "out:ROI% old": 23.09,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 102,
    "out:Energy use kWh/m2": 102,
    "out:Energy savings %": 90.2,
    "out:Op energy cost/MSEK": 54,
    "out:El price/MSEK": 34,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 1037.8,
    "out:Return %": 21,
    "out:Return/kSEK/y": 916,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 272117,
    "out:DH kr savings": 318780,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 279333,
    "out:% savings (space heating)": 50.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012001100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 97.82,
    "out:Total Energy Use Post PV": 97.85,
    "out:Primary Energy": 109.15,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.36,
    "out:CO2 Operational/m2": 31.84,
    "out:Total CO2/m2": 50.2,
    "out:Total CO2 (tons)": 161.64,
    "out:Klimatpaverkan": -57.19,
    "out:Initial Cost/MSEK": 4.516,
    "out:Running cost/(Apt SEK y)": 25072,
    "out:Running Cost over RSP/MSEK": 7.051,
    "out:LCP/MSEK": 2.958,
    "out:ROI% old": 23.64,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 98,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 21,
    "out:Return/kSEK/y": 955,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 284997,
    "out:DH kr savings": 331660,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 266266,
    "out:% savings (space heating)": 53.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012001100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 100.81,
    "out:Total Energy Use Post PV": 100.85,
    "out:Primary Energy": 110.61,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.71,
    "out:CO2 Operational/m2": 32.91,
    "out:Total CO2/m2": 51.63,
    "out:Total CO2 (tons)": 166.24,
    "out:Klimatpaverkan": -52.59,
    "out:Initial Cost/MSEK": 4.572,
    "out:Running cost/(Apt SEK y)": 25879,
    "out:Running Cost over RSP/MSEK": 7.278,
    "out:LCP/MSEK": 2.675,
    "out:ROI% old": 22.64,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 101,
    "out:Energy use kWh/m2": 101,
    "out:Energy savings %": 92.1,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 33,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 1036.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 926,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 275337,
    "out:DH kr savings": 322000,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 275822,
    "out:% savings (space heating)": 51.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012001100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 96.8,
    "out:Total Energy Use Post PV": 96.85,
    "out:Primary Energy": 107.05,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 18.71,
    "out:CO2 Operational/m2": 31.47,
    "out:Total CO2/m2": 50.19,
    "out:Total CO2 (tons)": 161.6,
    "out:Klimatpaverkan": -57.23,
    "out:Initial Cost/MSEK": 4.653,
    "out:Running cost/(Apt SEK y)": 24798,
    "out:Running Cost over RSP/MSEK": 6.974,
    "out:LCP/MSEK": 2.899,
    "out:ROI% old": 23.19,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 1036.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 965,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 288217,
    "out:DH kr savings": 334880,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 262768,
    "out:% savings (space heating)": 53.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012001110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 86.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 101.83,
    "out:Total Energy Use Post PV": 97.5,
    "out:Primary Energy": 104.89,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.6,
    "out:CO2 Operational/m2": 17.07,
    "out:Total CO2/m2": 43.67,
    "out:Total CO2 (tons)": 140.6,
    "out:Klimatpaverkan": -78.23,
    "out:Initial Cost/MSEK": 4.882,
    "out:Running cost/(Apt SEK y)": 24959,
    "out:Running Cost over RSP/MSEK": 7.028,
    "out:LCP/MSEK": 2.615,
    "out:ROI% old": 21.94,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 98,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 959,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10023.7,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.92,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315117,
    "out:DH kr savings": 318780,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27940,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 279333,
    "out:% savings (space heating)": 50.82,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012001110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 82.69,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 97.82,
    "out:Total Energy Use Post PV": 93.5,
    "out:Primary Energy": 101.34,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.6,
    "out:CO2 Operational/m2": 15.63,
    "out:Total CO2/m2": 42.23,
    "out:Total CO2 (tons)": 135.97,
    "out:Klimatpaverkan": -82.86,
    "out:Initial Cost/MSEK": 4.963,
    "out:Running cost/(Apt SEK y)": 23878,
    "out:Running Cost over RSP/MSEK": 6.724,
    "out:LCP/MSEK": 2.839,
    "out:ROI% old": 22.46,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 741.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 998,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.1,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13969.51,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 327998,
    "out:DH kr savings": 331660,
    "out:El kr savings": -18698,
    "out:El kr sold": 15036,
    "out:El kr saved": 27939,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 266266,
    "out:% savings (space heating)": 53.12,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012001110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 85.66,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.81,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 100.81,
    "out:Total Energy Use Post PV": 96.5,
    "out:Primary Energy": 102.79,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 26.95,
    "out:CO2 Operational/m2": 16.69,
    "out:Total CO2/m2": 43.64,
    "out:Total CO2 (tons)": 140.51,
    "out:Klimatpaverkan": -78.32,
    "out:Initial Cost/MSEK": 5.019,
    "out:Running cost/(Apt SEK y)": 24686,
    "out:Running Cost over RSP/MSEK": 6.951,
    "out:LCP/MSEK": 2.556,
    "out:ROI% old": 21.56,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 97,
    "out:Energy use kWh/m2": 97,
    "out:Energy savings %": 100,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 740.3,
    "out:Return %": 19,
    "out:Return/kSEK/y": 969,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10035.17,
    "out:PV (% sold (El))": 41.82,
    "out:PV (kWh self-consumed)": 13958.44,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318354,
    "out:DH kr savings": 322000,
    "out:El kr savings": -18698,
    "out:El kr sold": 15053,
    "out:El kr saved": 27917,
    "out:El kr return": 42970,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 275822,
    "out:% savings (space heating)": 51.44,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 95.33,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012001110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 81.6,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 96.8,
    "out:Total Energy Use Post PV": 92.5,
    "out:Primary Energy": 99.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.95,
    "out:CO2 Operational/m2": 15.25,
    "out:Total CO2/m2": 42.19,
    "out:Total CO2 (tons)": 135.86,
    "out:Klimatpaverkan": -82.97,
    "out:Initial Cost/MSEK": 5.099,
    "out:Running cost/(Apt SEK y)": 23605,
    "out:Running Cost over RSP/MSEK": 6.647,
    "out:LCP/MSEK": 2.779,
    "out:ROI% old": 22.07,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.61,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 740.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1008,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.91,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10036.3,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13957.32,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331236,
    "out:DH kr savings": 334880,
    "out:El kr savings": -18698,
    "out:El kr sold": 15054,
    "out:El kr saved": 27915,
    "out:El kr return": 42969,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 262768,
    "out:% savings (space heating)": 53.73,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 91.26,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012100000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.23,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 97.6,
    "out:Total Energy Use Post PV": 97.6,
    "out:Primary Energy": 101.38,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 17.62,
    "out:CO2 Operational/m2": 33.4,
    "out:Total CO2/m2": 51.02,
    "out:Total CO2 (tons)": 164.28,
    "out:Klimatpaverkan": -54.55,
    "out:Initial Cost/MSEK": 4.337,
    "out:Running cost/(Apt SEK y)": 25671,
    "out:Running Cost over RSP/MSEK": 7.224,
    "out:LCP/MSEK": 2.965,
    "out:ROI% old": 24.05,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 98,
    "out:Energy use kWh/m2": 98,
    "out:Energy savings %": 98,
    "out:Op energy cost/MSEK": 53,
    "out:El price/MSEK": 32,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 934,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 309120,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 309120,
    "out:DH kr savings": 309120,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 258353,
    "out:% savings (space heating)": 54.51,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012100000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.86,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 93.6,
    "out:Primary Energy": 98.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.62,
    "out:CO2 Operational/m2": 31.96,
    "out:Total CO2/m2": 49.58,
    "out:Total CO2 (tons)": 159.65,
    "out:Klimatpaverkan": -59.18,
    "out:Initial Cost/MSEK": 4.418,
    "out:Running cost/(Apt SEK y)": 24590,
    "out:Running Cost over RSP/MSEK": 6.92,
    "out:LCP/MSEK": 3.188,
    "out:ROI% old": 24.6,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 972,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 322000,
    "out:DH kr savings": 322000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 247488,
    "out:% savings (space heating)": 56.42,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012100000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.23,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 95.6,
    "out:Total Energy Use Post PV": 95.6,
    "out:Primary Energy": 98.76,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.97,
    "out:CO2 Operational/m2": 32.68,
    "out:Total CO2/m2": 50.65,
    "out:Total CO2 (tons)": 163.09,
    "out:Klimatpaverkan": -55.74,
    "out:Initial Cost/MSEK": 4.474,
    "out:Running cost/(Apt SEK y)": 25130,
    "out:Running Cost over RSP/MSEK": 7.072,
    "out:LCP/MSEK": 2.98,
    "out:ROI% old": 23.8,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 953,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315560,
    "out:DH kr savings": 315560,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 258353,
    "out:% savings (space heating)": 54.51,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012100000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.86,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 92.6,
    "out:Primary Energy": 95.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.97,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 49.57,
    "out:Total CO2 (tons)": 159.62,
    "out:Klimatpaverkan": -59.21,
    "out:Initial Cost/MSEK": 4.554,
    "out:Running cost/(Apt SEK y)": 24320,
    "out:Running Cost over RSP/MSEK": 6.844,
    "out:LCP/MSEK": 3.128,
    "out:ROI% old": 24.1,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325220,
    "out:DH kr savings": 325220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 247488,
    "out:% savings (space heating)": 56.42,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012100010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.23,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 97.6,
    "out:Total Energy Use Post PV": 94.91,
    "out:Primary Energy": 96.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.85,
    "out:CO2 Operational/m2": 8.92,
    "out:Total CO2/m2": 34.78,
    "out:Total CO2 (tons)": 111.98,
    "out:Klimatpaverkan": -106.85,
    "out:Initial Cost/MSEK": 4.784,
    "out:Running cost/(Apt SEK y)": 24551,
    "out:Running Cost over RSP/MSEK": 6.917,
    "out:LCP/MSEK": 2.825,
    "out:ROI% old": 22.72,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 18,
    "out:DH+ DHW/kWh/m2": 90,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 974,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 309120,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 349436,
    "out:DH kr savings": 309120,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 258353,
    "out:% savings (space heating)": 54.51,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012100010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.86,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 90.91,
    "out:Primary Energy": 93.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.85,
    "out:CO2 Operational/m2": 7.48,
    "out:Total CO2/m2": 33.34,
    "out:Total CO2 (tons)": 107.34,
    "out:Klimatpaverkan": -111.49,
    "out:Initial Cost/MSEK": 4.864,
    "out:Running cost/(Apt SEK y)": 23470,
    "out:Running Cost over RSP/MSEK": 6.613,
    "out:LCP/MSEK": 3.049,
    "out:ROI% old": 23.24,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1013,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 362316,
    "out:DH kr savings": 322000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 247488,
    "out:% savings (space heating)": 56.42,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012100010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.23,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 95.6,
    "out:Total Energy Use Post PV": 92.91,
    "out:Primary Energy": 93.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.2,
    "out:CO2 Operational/m2": 8.2,
    "out:Total CO2/m2": 34.41,
    "out:Total CO2 (tons)": 110.79,
    "out:Klimatpaverkan": -108.04,
    "out:Initial Cost/MSEK": 4.921,
    "out:Running cost/(Apt SEK y)": 24010,
    "out:Running Cost over RSP/MSEK": 6.765,
    "out:LCP/MSEK": 2.84,
    "out:ROI% old": 22.53,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355876,
    "out:DH kr savings": 315560,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 258353,
    "out:% savings (space heating)": 54.51,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012100010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.86,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 89.91,
    "out:Primary Energy": 90.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.2,
    "out:CO2 Operational/m2": 7.12,
    "out:Total CO2/m2": 33.33,
    "out:Total CO2 (tons)": 107.31,
    "out:Klimatpaverkan": -111.52,
    "out:Initial Cost/MSEK": 5.001,
    "out:Running cost/(Apt SEK y)": 23200,
    "out:Running Cost over RSP/MSEK": 6.537,
    "out:LCP/MSEK": 2.988,
    "out:ROI% old": 22.82,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1022,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 365536,
    "out:DH kr savings": 325220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 247488,
    "out:% savings (space heating)": 56.42,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012100100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.23,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 95.6,
    "out:Total Energy Use Post PV": 95.6,
    "out:Primary Energy": 97.88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.62,
    "out:CO2 Operational/m2": 32.68,
    "out:Total CO2/m2": 50.3,
    "out:Total CO2 (tons)": 161.97,
    "out:Klimatpaverkan": -56.86,
    "out:Initial Cost/MSEK": 4.432,
    "out:Running cost/(Apt SEK y)": 25130,
    "out:Running Cost over RSP/MSEK": 7.072,
    "out:LCP/MSEK": 3.022,
    "out:ROI% old": 24.02,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 96,
    "out:Energy use kWh/m2": 96,
    "out:Energy savings %": 102.1,
    "out:Op energy cost/MSEK": 52,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 953,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 315560,
    "out:DH kr savings": 315560,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 258353,
    "out:% savings (space heating)": 54.51,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012100100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.86,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 92.6,
    "out:Primary Energy": 94.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.62,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 49.22,
    "out:Total CO2 (tons)": 158.49,
    "out:Klimatpaverkan": -60.34,
    "out:Initial Cost/MSEK": 4.513,
    "out:Running cost/(Apt SEK y)": 24320,
    "out:Running Cost over RSP/MSEK": 6.844,
    "out:LCP/MSEK": 3.169,
    "out:ROI% old": 24.32,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 325220,
    "out:DH kr savings": 325220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 247488,
    "out:% savings (space heating)": 56.42,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012100100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.23,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 94.6,
    "out:Primary Energy": 95.78,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.97,
    "out:CO2 Operational/m2": 32.32,
    "out:Total CO2/m2": 50.29,
    "out:Total CO2 (tons)": 161.94,
    "out:Klimatpaverkan": -56.89,
    "out:Initial Cost/MSEK": 4.569,
    "out:Running cost/(Apt SEK y)": 24860,
    "out:Running Cost over RSP/MSEK": 6.996,
    "out:LCP/MSEK": 2.961,
    "out:ROI% old": 23.54,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 95,
    "out:Energy use kWh/m2": 95,
    "out:Energy savings %": 104.2,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 21,
    "out:Return/kSEK/y": 963,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 318780,
    "out:DH kr savings": 318780,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 258353,
    "out:% savings (space heating)": 54.51,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012100100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.86,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 90.6,
    "out:Primary Energy": 92.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 17.97,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 48.85,
    "out:Total CO2 (tons)": 157.3,
    "out:Klimatpaverkan": -61.53,
    "out:Initial Cost/MSEK": 4.65,
    "out:Running cost/(Apt SEK y)": 23780,
    "out:Running Cost over RSP/MSEK": 6.691,
    "out:LCP/MSEK": 3.185,
    "out:ROI% old": 24.07,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1002,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331660,
    "out:DH kr savings": 331660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 247488,
    "out:% savings (space heating)": 56.42,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012100110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.23,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 95.6,
    "out:Total Energy Use Post PV": 92.91,
    "out:Primary Energy": 93.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.85,
    "out:CO2 Operational/m2": 8.2,
    "out:Total CO2/m2": 34.06,
    "out:Total CO2 (tons)": 109.66,
    "out:Klimatpaverkan": -109.17,
    "out:Initial Cost/MSEK": 4.879,
    "out:Running cost/(Apt SEK y)": 24010,
    "out:Running Cost over RSP/MSEK": 6.765,
    "out:LCP/MSEK": 2.882,
    "out:ROI% old": 22.72,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 88,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 993,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 315560,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 355876,
    "out:DH kr savings": 315560,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 258353,
    "out:% savings (space heating)": 54.51,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012100110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.86,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 89.91,
    "out:Primary Energy": 90.1,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 25.85,
    "out:CO2 Operational/m2": 7.12,
    "out:Total CO2/m2": 32.98,
    "out:Total CO2 (tons)": 106.19,
    "out:Klimatpaverkan": -112.64,
    "out:Initial Cost/MSEK": 4.959,
    "out:Running cost/(Apt SEK y)": 23200,
    "out:Running Cost over RSP/MSEK": 6.537,
    "out:LCP/MSEK": 3.03,
    "out:ROI% old": 23.01,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1022,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 365536,
    "out:DH kr savings": 325220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 247488,
    "out:% savings (space heating)": 56.42,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012100110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 80.23,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 94.6,
    "out:Total Energy Use Post PV": 91.91,
    "out:Primary Energy": 90.95,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.2,
    "out:CO2 Operational/m2": 7.84,
    "out:Total CO2/m2": 34.05,
    "out:Total CO2 (tons)": 109.63,
    "out:Klimatpaverkan": -109.2,
    "out:Initial Cost/MSEK": 5.016,
    "out:Running cost/(Apt SEK y)": 23740,
    "out:Running Cost over RSP/MSEK": 6.689,
    "out:LCP/MSEK": 2.821,
    "out:ROI% old": 22.32,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 87,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1003,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 318780,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 359096,
    "out:DH kr savings": 318780,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 258353,
    "out:% savings (space heating)": 54.51,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012100110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.86,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 87.91,
    "out:Primary Energy": 88,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 26.2,
    "out:CO2 Operational/m2": 6.4,
    "out:Total CO2/m2": 32.61,
    "out:Total CO2 (tons)": 104.99,
    "out:Klimatpaverkan": -113.84,
    "out:Initial Cost/MSEK": 5.096,
    "out:Running cost/(Apt SEK y)": 22660,
    "out:Running Cost over RSP/MSEK": 6.385,
    "out:LCP/MSEK": 3.045,
    "out:ROI% old": 22.82,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 371976,
    "out:DH kr savings": 331660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 247488,
    "out:% savings (space heating)": 56.42,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012101000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 88.84,
    "out:Total Energy Use Post PV": 88.85,
    "out:Primary Energy": 103,
    "out:Energy Class": "E",
    "out:CO2 Embodied/m2": 20.88,
    "out:CO2 Operational/m2": 28.6,
    "out:Total CO2/m2": 49.48,
    "out:Total CO2 (tons)": 159.32,
    "out:Klimatpaverkan": -59.51,
    "out:Initial Cost/MSEK": 4.948,
    "out:Running cost/(Apt SEK y)": 22644,
    "out:Running Cost over RSP/MSEK": 6.367,
    "out:LCP/MSEK": 3.21,
    "out:ROI% old": 23.55,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 360640,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 313977,
    "out:DH kr savings": 360640,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236746,
    "out:% savings (space heating)": 58.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012101000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 84.84,
    "out:Total Energy Use Post PV": 84.85,
    "out:Primary Energy": 100.05,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.88,
    "out:CO2 Operational/m2": 27.16,
    "out:Total CO2/m2": 48.04,
    "out:Total CO2 (tons)": 154.69,
    "out:Klimatpaverkan": -64.14,
    "out:Initial Cost/MSEK": 5.029,
    "out:Running cost/(Apt SEK y)": 21564,
    "out:Running Cost over RSP/MSEK": 6.063,
    "out:LCP/MSEK": 3.434,
    "out:ROI% old": 24.04,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 22,
    "out:Return/kSEK/y": 1081,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 326857,
    "out:DH kr savings": 373520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 225881,
    "out:% savings (space heating)": 60.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012101000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 86.83,
    "out:Total Energy Use Post PV": 86.85,
    "out:Primary Energy": 100.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.23,
    "out:CO2 Operational/m2": 27.88,
    "out:Total CO2/m2": 49.11,
    "out:Total CO2 (tons)": 158.13,
    "out:Klimatpaverkan": -60.7,
    "out:Initial Cost/MSEK": 5.085,
    "out:Running cost/(Apt SEK y)": 22102,
    "out:Running Cost over RSP/MSEK": 6.215,
    "out:LCP/MSEK": 3.226,
    "out:ROI% old": 23.35,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1062,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 367080,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320417,
    "out:DH kr savings": 367080,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 232242,
    "out:% savings (space heating)": 59.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012101000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 83.83,
    "out:Total Energy Use Post PV": 83.85,
    "out:Primary Energy": 97.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.23,
    "out:CO2 Operational/m2": 26.8,
    "out:Total CO2/m2": 48.03,
    "out:Total CO2 (tons)": 154.65,
    "out:Klimatpaverkan": -64.18,
    "out:Initial Cost/MSEK": 5.165,
    "out:Running cost/(Apt SEK y)": 21291,
    "out:Running Cost over RSP/MSEK": 5.987,
    "out:LCP/MSEK": 3.374,
    "out:ROI% old": 23.62,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 1038,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 330077,
    "out:DH kr savings": 376740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 221381,
    "out:% savings (space heating)": 61.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012101010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 88.84,
    "out:Total Energy Use Post PV": 84.5,
    "out:Primary Energy": 95.18,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": 12.41,
    "out:Total CO2/m2": 41.52,
    "out:Total CO2 (tons)": 133.7,
    "out:Klimatpaverkan": -85.13,
    "out:Initial Cost/MSEK": 5.395,
    "out:Running cost/(Apt SEK y)": 21450,
    "out:Running Cost over RSP/MSEK": 6.04,
    "out:LCP/MSEK": 3.091,
    "out:ROI% old": 22.47,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1085,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.55,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.07,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 360640,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 356962,
    "out:DH kr savings": 360640,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236746,
    "out:% savings (space heating)": 58.32,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012101010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.15,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 84.84,
    "out:Total Energy Use Post PV": 80.5,
    "out:Primary Energy": 92.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": 10.97,
    "out:Total CO2/m2": 40.08,
    "out:Total CO2 (tons)": 129.07,
    "out:Klimatpaverkan": -89.76,
    "out:Initial Cost/MSEK": 5.475,
    "out:Running cost/(Apt SEK y)": 20370,
    "out:Running Cost over RSP/MSEK": 5.736,
    "out:LCP/MSEK": 3.315,
    "out:ROI% old": 22.93,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1124,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.56,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.06,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 369842,
    "out:DH kr savings": 373520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 225881,
    "out:% savings (space heating)": 60.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012101010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 72.12,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 86.83,
    "out:Total Energy Use Post PV": 82.5,
    "out:Primary Energy": 92.56,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.47,
    "out:CO2 Operational/m2": 11.67,
    "out:Total CO2/m2": 41.14,
    "out:Total CO2 (tons)": 132.47,
    "out:Klimatpaverkan": -86.36,
    "out:Initial Cost/MSEK": 5.531,
    "out:Running cost/(Apt SEK y)": 20908,
    "out:Running Cost over RSP/MSEK": 5.888,
    "out:LCP/MSEK": 3.107,
    "out:ROI% old": 22.31,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1105,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.21,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.4,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 367080,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 363414,
    "out:DH kr savings": 367080,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 232242,
    "out:% savings (space heating)": 59.11,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012101010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.75,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 83.83,
    "out:Total Energy Use Post PV": 79.5,
    "out:Primary Energy": 89.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.47,
    "out:CO2 Operational/m2": 10.59,
    "out:Total CO2/m2": 40.06,
    "out:Total CO2 (tons)": 128.99,
    "out:Klimatpaverkan": -89.84,
    "out:Initial Cost/MSEK": 5.612,
    "out:Running cost/(Apt SEK y)": 20097,
    "out:Running Cost over RSP/MSEK": 5.659,
    "out:LCP/MSEK": 3.255,
    "out:ROI% old": 22.57,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 741.5,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.48,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.14,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 373074,
    "out:DH kr savings": 376740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27944,
    "out:El kr return": 42976,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 221381,
    "out:% savings (space heating)": 61.02,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012101100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 86.82,
    "out:Total Energy Use Post PV": 86.85,
    "out:Primary Energy": 99.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.88,
    "out:CO2 Operational/m2": 27.88,
    "out:Total CO2/m2": 48.76,
    "out:Total CO2 (tons)": 157,
    "out:Klimatpaverkan": -61.83,
    "out:Initial Cost/MSEK": 5.043,
    "out:Running cost/(Apt SEK y)": 22101,
    "out:Running Cost over RSP/MSEK": 6.214,
    "out:LCP/MSEK": 3.268,
    "out:ROI% old": 23.54,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1062,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 367080,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 320417,
    "out:DH kr savings": 367080,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 230762,
    "out:% savings (space heating)": 59.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012101100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 82.82,
    "out:Total Energy Use Post PV": 82.85,
    "out:Primary Energy": 96.55,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 20.88,
    "out:CO2 Operational/m2": 26.44,
    "out:Total CO2/m2": 47.32,
    "out:Total CO2 (tons)": 152.36,
    "out:Klimatpaverkan": -66.47,
    "out:Initial Cost/MSEK": 5.124,
    "out:Running cost/(Apt SEK y)": 21020,
    "out:Running Cost over RSP/MSEK": 5.91,
    "out:LCP/MSEK": 3.492,
    "out:ROI% old": 24.02,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 333297,
    "out:DH kr savings": 379960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 219905,
    "out:% savings (space heating)": 61.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012101100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 85.8,
    "out:Total Energy Use Post PV": 85.85,
    "out:Primary Energy": 97.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.23,
    "out:CO2 Operational/m2": 27.51,
    "out:Total CO2/m2": 48.74,
    "out:Total CO2 (tons)": 156.96,
    "out:Klimatpaverkan": -61.87,
    "out:Initial Cost/MSEK": 5.18,
    "out:Running cost/(Apt SEK y)": 21827,
    "out:Running Cost over RSP/MSEK": 6.137,
    "out:LCP/MSEK": 3.209,
    "out:ROI% old": 23.14,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 1036.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1072,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 323637,
    "out:DH kr savings": 370300,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 227274,
    "out:% savings (space heating)": 59.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012101100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 81.8,
    "out:Total Energy Use Post PV": 81.85,
    "out:Primary Energy": 94.45,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 21.23,
    "out:CO2 Operational/m2": 26.07,
    "out:Total CO2/m2": 47.3,
    "out:Total CO2 (tons)": 152.32,
    "out:Klimatpaverkan": -66.51,
    "out:Initial Cost/MSEK": 5.26,
    "out:Running cost/(Apt SEK y)": 20746,
    "out:Running Cost over RSP/MSEK": 5.833,
    "out:LCP/MSEK": 3.433,
    "out:ROI% old": 23.61,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1036,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1111,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 336517,
    "out:DH kr savings": 383180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216430,
    "out:% savings (space heating)": 61.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012101110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 71.67,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 86.82,
    "out:Total Energy Use Post PV": 82.5,
    "out:Primary Energy": 91.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": 11.67,
    "out:Total CO2/m2": 40.78,
    "out:Total CO2 (tons)": 131.32,
    "out:Klimatpaverkan": -87.51,
    "out:Initial Cost/MSEK": 5.49,
    "out:Running cost/(Apt SEK y)": 20907,
    "out:Running Cost over RSP/MSEK": 5.887,
    "out:LCP/MSEK": 3.149,
    "out:ROI% old": 22.48,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 72,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1105,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.04,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.57,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 367080,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 363419,
    "out:DH kr savings": 367080,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 230762,
    "out:% savings (space heating)": 59.37,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012101110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.29,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 82.82,
    "out:Total Energy Use Post PV": 78.5,
    "out:Primary Energy": 88.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.12,
    "out:CO2 Operational/m2": 10.22,
    "out:Total CO2/m2": 39.34,
    "out:Total CO2 (tons)": 126.68,
    "out:Klimatpaverkan": -92.15,
    "out:Initial Cost/MSEK": 5.57,
    "out:Running cost/(Apt SEK y)": 19826,
    "out:Running Cost over RSP/MSEK": 5.583,
    "out:LCP/MSEK": 3.373,
    "out:ROI% old": 22.94,
    "out:Payback discounted": 5,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 21,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.49,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 376300,
    "out:DH kr savings": 379960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27936,
    "out:El kr return": 42974,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 219905,
    "out:% savings (space heating)": 61.28,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012101110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 70.58,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 85.8,
    "out:Total Energy Use Post PV": 81.5,
    "out:Primary Energy": 89.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.47,
    "out:CO2 Operational/m2": 11.28,
    "out:Total CO2/m2": 40.75,
    "out:Total CO2 (tons)": 131.21,
    "out:Klimatpaverkan": -87.62,
    "out:Initial Cost/MSEK": 5.626,
    "out:Running cost/(Apt SEK y)": 20633,
    "out:Running Cost over RSP/MSEK": 5.81,
    "out:LCP/MSEK": 3.089,
    "out:ROI% old": 22.13,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 740,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1115,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10037.83,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13955.79,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 366658,
    "out:DH kr savings": 370300,
    "out:El kr savings": -18698,
    "out:El kr sold": 15057,
    "out:El kr saved": 27912,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 227274,
    "out:% savings (space heating)": 59.98,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 80.23,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012101110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 67.21,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 81.8,
    "out:Total Energy Use Post PV": 77.5,
    "out:Primary Energy": 86.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 29.47,
    "out:CO2 Operational/m2": 9.84,
    "out:Total CO2/m2": 39.31,
    "out:Total CO2 (tons)": 126.57,
    "out:Klimatpaverkan": -92.26,
    "out:Initial Cost/MSEK": 5.707,
    "out:Running cost/(Apt SEK y)": 19552,
    "out:Running Cost over RSP/MSEK": 5.506,
    "out:LCP/MSEK": 3.313,
    "out:ROI% old": 22.58,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.39,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 20,
    "out:Return/kSEK/y": 1154,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10039.05,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954.56,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 7,
    "out:Payback Time": 14.285714,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 379540,
    "out:DH kr savings": 383180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15059,
    "out:El kr saved": 27909,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216430,
    "out:% savings (space heating)": 61.89,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.86,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012200000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 93.6,
    "out:Primary Energy": 98.24,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.52,
    "out:CO2 Operational/m2": 31.96,
    "out:Total CO2/m2": 56.49,
    "out:Total CO2 (tons)": 181.89,
    "out:Klimatpaverkan": -36.94,
    "out:Initial Cost/MSEK": 5.824,
    "out:Running cost/(Apt SEK y)": 24590,
    "out:Running Cost over RSP/MSEK": 6.92,
    "out:LCP/MSEK": 1.782,
    "out:ROI% old": 18.66,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 94,
    "out:Energy use kWh/m2": 94,
    "out:Energy savings %": 106.4,
    "out:Op energy cost/MSEK": 51,
    "out:El price/MSEK": 31,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 972,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 322000,
    "out:DH kr savings": 322000,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246764,
    "out:% savings (space heating)": 56.55,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012200000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 90.6,
    "out:Primary Energy": 95.43,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.52,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 55.41,
    "out:Total CO2 (tons)": 178.41,
    "out:Klimatpaverkan": -40.42,
    "out:Initial Cost/MSEK": 5.905,
    "out:Running cost/(Apt SEK y)": 23780,
    "out:Running Cost over RSP/MSEK": 6.691,
    "out:LCP/MSEK": 1.93,
    "out:ROI% old": 18.95,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1002,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 331660,
    "out:DH kr savings": 331660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236425,
    "out:% savings (space heating)": 58.37,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012200000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 92.6,
    "out:Primary Energy": 95.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.87,
    "out:CO2 Operational/m2": 31.6,
    "out:Total CO2/m2": 56.48,
    "out:Total CO2 (tons)": 181.85,
    "out:Klimatpaverkan": -36.98,
    "out:Initial Cost/MSEK": 5.961,
    "out:Running cost/(Apt SEK y)": 24320,
    "out:Running Cost over RSP/MSEK": 6.844,
    "out:LCP/MSEK": 1.721,
    "out:ROI% old": 18.41,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 93,
    "out:Energy use kWh/m2": 93,
    "out:Energy savings %": 108.6,
    "out:Op energy cost/MSEK": 50,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 16,
    "out:Return/kSEK/y": 982,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 325220,
    "out:DH kr savings": 325220,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246764,
    "out:% savings (space heating)": 56.55,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012200000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 89.6,
    "out:Primary Energy": 92.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.87,
    "out:CO2 Operational/m2": 30.52,
    "out:Total CO2/m2": 55.4,
    "out:Total CO2 (tons)": 178.38,
    "out:Klimatpaverkan": -40.45,
    "out:Initial Cost/MSEK": 6.041,
    "out:Running cost/(Apt SEK y)": 23510,
    "out:Running Cost over RSP/MSEK": 6.615,
    "out:LCP/MSEK": 1.869,
    "out:ROI% old": 18.71,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1011,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334880,
    "out:DH kr savings": 334880,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236425,
    "out:% savings (space heating)": 58.37,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 21.25,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012200010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 93.6,
    "out:Total Energy Use Post PV": 90.91,
    "out:Primary Energy": 93.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.76,
    "out:CO2 Operational/m2": 7.48,
    "out:Total CO2/m2": 40.24,
    "out:Total CO2 (tons)": 129.58,
    "out:Klimatpaverkan": -89.25,
    "out:Initial Cost/MSEK": 6.271,
    "out:Running cost/(Apt SEK y)": 23470,
    "out:Running Cost over RSP/MSEK": 6.613,
    "out:LCP/MSEK": 1.642,
    "out:ROI% old": 18.03,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 86,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1013,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 322000,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 362316,
    "out:DH kr savings": 322000,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246764,
    "out:% savings (space heating)": 56.55,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012200010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 87.91,
    "out:Primary Energy": 90.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.76,
    "out:CO2 Operational/m2": 6.4,
    "out:Total CO2/m2": 39.16,
    "out:Total CO2 (tons)": 126.1,
    "out:Klimatpaverkan": -92.73,
    "out:Initial Cost/MSEK": 6.351,
    "out:Running cost/(Apt SEK y)": 22660,
    "out:Running Cost over RSP/MSEK": 6.385,
    "out:LCP/MSEK": 1.79,
    "out:ROI% old": 18.31,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 371976,
    "out:DH kr savings": 331660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236425,
    "out:% savings (space heating)": 58.37,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012200010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 92.6,
    "out:Total Energy Use Post PV": 89.91,
    "out:Primary Energy": 90.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 7.12,
    "out:Total CO2/m2": 40.23,
    "out:Total CO2 (tons)": 129.55,
    "out:Klimatpaverkan": -89.28,
    "out:Initial Cost/MSEK": 6.407,
    "out:Running cost/(Apt SEK y)": 23200,
    "out:Running Cost over RSP/MSEK": 6.537,
    "out:LCP/MSEK": 1.582,
    "out:ROI% old": 17.81,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 90,
    "out:Energy use kWh/m2": 90,
    "out:Energy savings %": 115.6,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 85,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1022,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 325220,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 365536,
    "out:DH kr savings": 325220,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246764,
    "out:% savings (space heating)": 56.55,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012200010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 89.6,
    "out:Total Energy Use Post PV": 86.91,
    "out:Primary Energy": 87.97,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 6.04,
    "out:Total CO2/m2": 39.15,
    "out:Total CO2 (tons)": 126.07,
    "out:Klimatpaverkan": -92.76,
    "out:Initial Cost/MSEK": 6.488,
    "out:Running cost/(Apt SEK y)": 22390,
    "out:Running Cost over RSP/MSEK": 6.309,
    "out:LCP/MSEK": 1.729,
    "out:ROI% old": 18.09,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 82,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1052,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 334880,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 375196,
    "out:DH kr savings": 334880,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236425,
    "out:% savings (space heating)": 58.37,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 21.25,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012200100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 91.6,
    "out:Primary Energy": 94.74,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.52,
    "out:CO2 Operational/m2": 31.24,
    "out:Total CO2/m2": 55.77,
    "out:Total CO2 (tons)": 179.57,
    "out:Klimatpaverkan": -39.26,
    "out:Initial Cost/MSEK": 5.919,
    "out:Running cost/(Apt SEK y)": 24050,
    "out:Running Cost over RSP/MSEK": 6.768,
    "out:LCP/MSEK": 1.839,
    "out:ROI% old": 18.72,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 92,
    "out:Energy use kWh/m2": 92,
    "out:Energy savings %": 110.9,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 992,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 328440,
    "out:DH kr savings": 328440,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246764,
    "out:% savings (space heating)": 56.55,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012200100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 88.6,
    "out:Primary Energy": 91.93,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.52,
    "out:CO2 Operational/m2": 30.16,
    "out:Total CO2/m2": 54.69,
    "out:Total CO2 (tons)": 176.09,
    "out:Klimatpaverkan": -42.74,
    "out:Initial Cost/MSEK": 6,
    "out:Running cost/(Apt SEK y)": 23239,
    "out:Running Cost over RSP/MSEK": 6.539,
    "out:LCP/MSEK": 1.987,
    "out:ROI% old": 19.02,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1021,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 338100,
    "out:DH kr savings": 338100,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236425,
    "out:% savings (space heating)": 58.37,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 20,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012200100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 90.6,
    "out:Primary Energy": 92.64,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.87,
    "out:CO2 Operational/m2": 30.88,
    "out:Total CO2/m2": 55.76,
    "out:Total CO2 (tons)": 179.54,
    "out:Klimatpaverkan": -39.29,
    "out:Initial Cost/MSEK": 6.056,
    "out:Running cost/(Apt SEK y)": 23780,
    "out:Running Cost over RSP/MSEK": 6.691,
    "out:LCP/MSEK": 1.779,
    "out:ROI% old": 18.48,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 91,
    "out:Energy use kWh/m2": 91,
    "out:Energy savings %": 113.2,
    "out:Op energy cost/MSEK": 49,
    "out:El price/MSEK": 30,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1002,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 331660,
    "out:DH kr savings": 331660,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246764,
    "out:% savings (space heating)": 56.55,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012200100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 7.6,
    "out:Total Energy Use Pre PV": 87.6,
    "out:Total Energy Use Post PV": 87.6,
    "out:Primary Energy": 89.83,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 24.87,
    "out:CO2 Operational/m2": 29.8,
    "out:Total CO2/m2": 54.68,
    "out:Total CO2 (tons)": 176.06,
    "out:Klimatpaverkan": -42.77,
    "out:Initial Cost/MSEK": 6.136,
    "out:Running cost/(Apt SEK y)": 22969,
    "out:Running Cost over RSP/MSEK": 6.463,
    "out:LCP/MSEK": 1.926,
    "out:ROI% old": 18.77,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 532,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1031,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": 0,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 341320,
    "out:DH kr savings": 341320,
    "out:El kr savings": 0,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236425,
    "out:% savings (space heating)": 58.37,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 17,
    "out:Ef": 7.6
  },
  {
    "ID": "Z01012200110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 91.6,
    "out:Total Energy Use Post PV": 88.91,
    "out:Primary Energy": 89.9,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.76,
    "out:CO2 Operational/m2": 6.76,
    "out:Total CO2/m2": 39.52,
    "out:Total CO2 (tons)": 127.26,
    "out:Klimatpaverkan": -91.57,
    "out:Initial Cost/MSEK": 6.366,
    "out:Running cost/(Apt SEK y)": 22930,
    "out:Running Cost over RSP/MSEK": 6.461,
    "out:LCP/MSEK": 1.699,
    "out:ROI% old": 18.1,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 48,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 17,
    "out:DH+ DHW/kWh/m2": 84,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1032,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 328440,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 368756,
    "out:DH kr savings": 328440,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246764,
    "out:% savings (space heating)": 56.55,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012200110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 88.6,
    "out:Total Energy Use Post PV": 85.91,
    "out:Primary Energy": 87.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 32.76,
    "out:CO2 Operational/m2": 5.68,
    "out:Total CO2/m2": 38.44,
    "out:Total CO2 (tons)": 123.79,
    "out:Klimatpaverkan": -95.04,
    "out:Initial Cost/MSEK": 6.446,
    "out:Running cost/(Apt SEK y)": 22120,
    "out:Running Cost over RSP/MSEK": 6.233,
    "out:LCP/MSEK": 1.847,
    "out:ROI% old": 18.38,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 81,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1061,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 338100,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 378416,
    "out:DH kr savings": 338100,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236425,
    "out:% savings (space heating)": 58.37,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 20,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012200110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 76.63,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 90.6,
    "out:Total Energy Use Post PV": 87.91,
    "out:Primary Energy": 87.8,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 6.4,
    "out:Total CO2/m2": 39.51,
    "out:Total CO2 (tons)": 127.23,
    "out:Klimatpaverkan": -91.6,
    "out:Initial Cost/MSEK": 6.502,
    "out:Running cost/(Apt SEK y)": 22660,
    "out:Running Cost over RSP/MSEK": 6.385,
    "out:LCP/MSEK": 1.639,
    "out:ROI% old": 17.89,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 88,
    "out:Energy use kWh/m2": 88,
    "out:Energy savings %": 120.5,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 83,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1042,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 331660,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 371976,
    "out:DH kr savings": 331660,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 246764,
    "out:% savings (space heating)": 56.55,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012200110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 73.42,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 7.6,
    "out:Electricity (inc PV)": 4.91,
    "out:Total Energy Use Pre PV": 87.6,
    "out:Total Energy Use Post PV": 84.91,
    "out:Primary Energy": 84.99,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 33.11,
    "out:CO2 Operational/m2": 5.32,
    "out:Total CO2/m2": 38.43,
    "out:Total CO2 (tons)": 123.76,
    "out:Klimatpaverkan": -95.07,
    "out:Initial Cost/MSEK": 6.583,
    "out:Running cost/(Apt SEK y)": 21849,
    "out:Running Cost over RSP/MSEK": 6.157,
    "out:LCP/MSEK": 1.786,
    "out:ROI% old": 18.16,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "S",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 16,
    "out:DH+ DHW/kWh/m2": 80,
    "out:El bought/kWh/m2": 346.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1071,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 15342.74,
    "out:PV (% sold (El))": 63.95,
    "out:PV (kWh self-consumed)": 8650.88,
    "out:PV (ratio sold/self-consumed)": 1.77,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 6,
    "out:Payback Time": 16.666667,
    "out:DH kWh savings": 341320,
    "out:EL kWh savings": 8651,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 381636,
    "out:DH kr savings": 341320,
    "out:El kr savings": 17302,
    "out:El kr sold": 23014,
    "out:El kr saved": 17302,
    "out:El kr return": 40316,
    "out:% solar/total": 35,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 236425,
    "out:% savings (space heating)": 58.37,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 17,
    "out:Ef": 4.91
  },
  {
    "ID": "Z01012201000000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 84.84,
    "out:Total Energy Use Post PV": 84.85,
    "out:Primary Energy": 99.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.79,
    "out:CO2 Operational/m2": 27.16,
    "out:Total CO2/m2": 54.95,
    "out:Total CO2 (tons)": 176.92,
    "out:Klimatpaverkan": -41.91,
    "out:Initial Cost/MSEK": 6.435,
    "out:Running cost/(Apt SEK y)": 21564,
    "out:Running Cost over RSP/MSEK": 6.063,
    "out:LCP/MSEK": 2.028,
    "out:ROI% old": 18.79,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1081,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 326857,
    "out:DH kr savings": 373520,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 225156,
    "out:% savings (space heating)": 60.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012201000000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 81.84,
    "out:Total Energy Use Post PV": 81.85,
    "out:Primary Energy": 97.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.79,
    "out:CO2 Operational/m2": 26.08,
    "out:Total CO2/m2": 53.87,
    "out:Total CO2 (tons)": 173.45,
    "out:Klimatpaverkan": -45.38,
    "out:Initial Cost/MSEK": 6.515,
    "out:Running cost/(Apt SEK y)": 20753,
    "out:Running Cost over RSP/MSEK": 5.835,
    "out:LCP/MSEK": 2.175,
    "out:ROI% old": 19.06,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1038.9,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1111,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 336517,
    "out:DH kr savings": 383180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 214818,
    "out:% savings (space heating)": 62.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 8.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012201000001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 83.83,
    "out:Total Energy Use Post PV": 83.85,
    "out:Primary Energy": 97.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.14,
    "out:CO2 Operational/m2": 26.8,
    "out:Total CO2/m2": 54.93,
    "out:Total CO2 (tons)": 176.89,
    "out:Klimatpaverkan": -41.94,
    "out:Initial Cost/MSEK": 6.572,
    "out:Running cost/(Apt SEK y)": 21291,
    "out:Running Cost over RSP/MSEK": 5.987,
    "out:LCP/MSEK": 1.968,
    "out:ROI% old": 18.56,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 84,
    "out:Energy use kWh/m2": 84,
    "out:Energy savings %": 131,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1091,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 330077,
    "out:DH kr savings": 376740,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 220649,
    "out:% savings (space heating)": 61.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012201000001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 79.83,
    "out:Total Energy Use Post PV": 79.85,
    "out:Primary Energy": 94.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.14,
    "out:CO2 Operational/m2": 25.36,
    "out:Total CO2/m2": 53.49,
    "out:Total CO2 (tons)": 172.25,
    "out:Klimatpaverkan": -46.58,
    "out:Initial Cost/MSEK": 6.652,
    "out:Running cost/(Apt SEK y)": 20211,
    "out:Running Cost over RSP/MSEK": 5.682,
    "out:LCP/MSEK": 2.192,
    "out:ROI% old": 18.99,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 1038.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1130,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342957,
    "out:DH kr savings": 389620,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 210315,
    "out:% savings (space heating)": 62.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 4.93,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012201010000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 69.92,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 84.84,
    "out:Total Energy Use Post PV": 80.5,
    "out:Primary Energy": 92.04,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.02,
    "out:CO2 Operational/m2": 10.97,
    "out:Total CO2/m2": 46.99,
    "out:Total CO2 (tons)": 151.3,
    "out:Klimatpaverkan": -67.53,
    "out:Initial Cost/MSEK": 6.881,
    "out:Running cost/(Apt SEK y)": 20370,
    "out:Running Cost over RSP/MSEK": 5.736,
    "out:LCP/MSEK": 1.908,
    "out:ROI% old": 18.25,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1124,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.49,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369842,
    "out:DH kr savings": 373520,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 225156,
    "out:% savings (space heating)": 60.36,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012201010000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.71,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.84,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 81.84,
    "out:Total Energy Use Post PV": 77.5,
    "out:Primary Energy": 89.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.02,
    "out:CO2 Operational/m2": 9.89,
    "out:Total CO2/m2": 45.91,
    "out:Total CO2 (tons)": 147.83,
    "out:Klimatpaverkan": -71,
    "out:Initial Cost/MSEK": 6.962,
    "out:Running cost/(Apt SEK y)": 19559,
    "out:Running Cost over RSP/MSEK": 5.508,
    "out:LCP/MSEK": 2.056,
    "out:ROI% old": 18.5,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 742.3,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1154,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 4.94,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10013.51,
    "out:PV (% sold (El))": 41.73,
    "out:PV (kWh self-consumed)": 13980.11,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 379502,
    "out:DH kr savings": 383180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15020,
    "out:El kr saved": 27960,
    "out:El kr return": 42980,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 214818,
    "out:% savings (space heating)": 62.18,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 8.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012201010001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.52,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 83.83,
    "out:Total Energy Use Post PV": 79.5,
    "out:Primary Energy": 89.41,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 10.59,
    "out:Total CO2/m2": 46.97,
    "out:Total CO2 (tons)": 151.23,
    "out:Klimatpaverkan": -67.6,
    "out:Initial Cost/MSEK": 7.018,
    "out:Running cost/(Apt SEK y)": 20098,
    "out:Running Cost over RSP/MSEK": 5.659,
    "out:LCP/MSEK": 1.848,
    "out:ROI% old": 18.05,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 69,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1134,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.02,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.59,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 376740,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373073,
    "out:DH kr savings": 376740,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 220649,
    "out:% savings (space heating)": 61.15,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012201010001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 65.32,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.83,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 79.83,
    "out:Total Energy Use Post PV": 75.5,
    "out:Primary Energy": 86.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 9.15,
    "out:Total CO2/m2": 45.53,
    "out:Total CO2 (tons)": 146.59,
    "out:Klimatpaverkan": -72.24,
    "out:Initial Cost/MSEK": 7.099,
    "out:Running cost/(Apt SEK y)": 19017,
    "out:Running Cost over RSP/MSEK": 5.355,
    "out:LCP/MSEK": 2.072,
    "out:ROI% old": 18.46,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 155.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 741.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 4.93,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10021.28,
    "out:PV (% sold (El))": 41.77,
    "out:PV (kWh self-consumed)": 13972.34,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385954,
    "out:DH kr savings": 389620,
    "out:El kr savings": -18698,
    "out:El kr sold": 15032,
    "out:El kr saved": 27945,
    "out:El kr return": 42977,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 210315,
    "out:% savings (space heating)": 62.97,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 4.93,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012201100000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 82.82,
    "out:Total Energy Use Post PV": 82.85,
    "out:Primary Energy": 96.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.79,
    "out:CO2 Operational/m2": 26.44,
    "out:Total CO2/m2": 54.22,
    "out:Total CO2 (tons)": 174.6,
    "out:Klimatpaverkan": -44.23,
    "out:Initial Cost/MSEK": 6.53,
    "out:Running cost/(Apt SEK y)": 21020,
    "out:Running Cost over RSP/MSEK": 5.91,
    "out:LCP/MSEK": 2.086,
    "out:ROI% old": 18.85,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 1037.7,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1101,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 333297,
    "out:DH kr savings": 379960,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 219169,
    "out:% savings (space heating)": 61.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012201100000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 79.82,
    "out:Total Energy Use Post PV": 79.85,
    "out:Primary Energy": 93.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 27.79,
    "out:CO2 Operational/m2": 25.36,
    "out:Total CO2/m2": 53.14,
    "out:Total CO2 (tons)": 171.12,
    "out:Klimatpaverkan": -47.71,
    "out:Initial Cost/MSEK": 6.61,
    "out:Running cost/(Apt SEK y)": 20210,
    "out:Running Cost over RSP/MSEK": 5.682,
    "out:LCP/MSEK": 2.233,
    "out:ROI% old": 19.11,
    "out:Payback discounted": 6,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 80,
    "out:Energy use kWh/m2": 80,
    "out:Energy savings %": 142.5,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 1037.6,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1130,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 342957,
    "out:DH kr savings": 389620,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 208838,
    "out:% savings (space heating)": 63.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 3.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012201100001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 81.8,
    "out:Total Energy Use Post PV": 81.85,
    "out:Primary Energy": 94.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.14,
    "out:CO2 Operational/m2": 26.07,
    "out:Total CO2/m2": 54.21,
    "out:Total CO2 (tons)": 174.56,
    "out:Klimatpaverkan": -44.27,
    "out:Initial Cost/MSEK": 6.667,
    "out:Running cost/(Apt SEK y)": 20746,
    "out:Running Cost over RSP/MSEK": 5.833,
    "out:LCP/MSEK": 2.026,
    "out:ROI% old": 18.63,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 1036.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1111,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 336517,
    "out:DH kr savings": 383180,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 215679,
    "out:% savings (space heating)": 62.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012201100001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 14.85,
    "out:Total Energy Use Pre PV": 78.8,
    "out:Total Energy Use Post PV": 78.85,
    "out:Primary Energy": 91.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 28.14,
    "out:CO2 Operational/m2": 24.99,
    "out:Total CO2/m2": 53.13,
    "out:Total CO2 (tons)": 171.08,
    "out:Klimatpaverkan": -47.75,
    "out:Initial Cost/MSEK": 6.747,
    "out:Running cost/(Apt SEK y)": 19936,
    "out:Running Cost over RSP/MSEK": 5.605,
    "out:LCP/MSEK": 2.174,
    "out:ROI% old": 18.89,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 1036.1,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1140,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -23332,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 346177,
    "out:DH kr savings": 392840,
    "out:El kr savings": -46663,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 205362,
    "out:% savings (space heating)": 63.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 0.68,
    "out:Ef": 14.85
  },
  {
    "ID": "Z01012201110000000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 68.06,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 82.82,
    "out:Total Energy Use Post PV": 78.5,
    "out:Primary Energy": 88.54,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.02,
    "out:CO2 Operational/m2": 10.23,
    "out:Total CO2/m2": 46.25,
    "out:Total CO2 (tons)": 148.92,
    "out:Klimatpaverkan": -69.91,
    "out:Initial Cost/MSEK": 6.976,
    "out:Running cost/(Apt SEK y)": 19826,
    "out:Running Cost over RSP/MSEK": 5.583,
    "out:LCP/MSEK": 1.966,
    "out:ROI% old": 18.31,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 68,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1144,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10024.8,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.81,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 379960,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 376299,
    "out:DH kr savings": 379960,
    "out:El kr savings": -18698,
    "out:El kr sold": 15037,
    "out:El kr saved": 27938,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 219169,
    "out:% savings (space heating)": 61.41,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012201110000100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 64.86,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.82,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 79.82,
    "out:Total Energy Use Post PV": 75.5,
    "out:Primary Energy": 85.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.02,
    "out:CO2 Operational/m2": 9.15,
    "out:Total CO2/m2": 45.17,
    "out:Total CO2 (tons)": 145.44,
    "out:Klimatpaverkan": -73.39,
    "out:Initial Cost/MSEK": 7.057,
    "out:Running cost/(Apt SEK y)": 19016,
    "out:Running Cost over RSP/MSEK": 5.355,
    "out:LCP/MSEK": 2.114,
    "out:ROI% old": 18.57,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 76,
    "out:Energy use kWh/m2": 76,
    "out:Energy savings %": 155.3,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 65,
    "out:El bought/kWh/m2": 741.2,
    "out:Return %": 17,
    "out:Return/kSEK/y": 1173,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 4.92,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10025.25,
    "out:PV (% sold (El))": 41.78,
    "out:PV (kWh self-consumed)": 13968.37,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 389620,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385960,
    "out:DH kr savings": 389620,
    "out:El kr savings": -18698,
    "out:El kr sold": 15038,
    "out:El kr saved": 27937,
    "out:El kr return": 42975,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 208838,
    "out:% savings (space heating)": 63.23,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 3.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012201110001000",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 66.98,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 81.8,
    "out:Total Energy Use Post PV": 77.5,
    "out:Primary Energy": 86.44,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 9.84,
    "out:Total CO2/m2": 46.22,
    "out:Total CO2 (tons)": 148.82,
    "out:Klimatpaverkan": -70.01,
    "out:Initial Cost/MSEK": 7.113,
    "out:Running cost/(Apt SEK y)": 19553,
    "out:Running Cost over RSP/MSEK": 5.506,
    "out:LCP/MSEK": 1.907,
    "out:ROI% old": 18.12,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 40,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 67,
    "out:El bought/kWh/m2": 740.1,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1154,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10037.49,
    "out:PV (% sold (El))": 41.83,
    "out:PV (kWh self-consumed)": 13956.13,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 383180,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 379538,
    "out:DH kr savings": 383180,
    "out:El kr savings": -18698,
    "out:El kr sold": 15056,
    "out:El kr saved": 27912,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 215679,
    "out:% savings (space heating)": 62.03,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 76.63,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z01012201110001100",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 1,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 1,
    "in:Roof_Insulation_Thickness": 2,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 1,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 0,
    "out:Space Heating (DH)": 63.78,
    "out:DHW (DH)": 0,
    "out:Property Electricity": 14.8,
    "out:Electricity (inc PV)": 10.5,
    "out:Total Energy Use Pre PV": 78.8,
    "out:Total Energy Use Post PV": 74.5,
    "out:Primary Energy": 83.63,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 36.37,
    "out:CO2 Operational/m2": 8.76,
    "out:Total CO2/m2": 45.13,
    "out:Total CO2 (tons)": 145.33,
    "out:Klimatpaverkan": -73.5,
    "out:Initial Cost/MSEK": 7.194,
    "out:Running cost/(Apt SEK y)": 18742,
    "out:Running Cost over RSP/MSEK": 5.278,
    "out:LCP/MSEK": 2.055,
    "out:ROI% old": 18.37,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.33,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 75,
    "out:Energy use kWh/m2": 75,
    "out:Energy savings %": 158.7,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 13,
    "out:DH+ DHW/kWh/m2": 64,
    "out:El bought/kWh/m2": 739.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1183,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 4.9,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 10038.72,
    "out:PV (% sold (El))": 41.84,
    "out:PV (kWh self-consumed)": 13954.9,
    "out:PV (ratio sold/self-consumed)": 0.72,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 0,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 0,
    "out:FTX (%heat recovery efficiency)": 0,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.2,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.11,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 577.34,
    "out:Fasadisolering (kr/m2)": 2195.69,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 392840,
    "out:EL kWh savings": -9349,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 389200,
    "out:DH kr savings": 392840,
    "out:El kr savings": -18698,
    "out:El kr sold": 15058,
    "out:El kr saved": 27910,
    "out:El kr return": 42968,
    "out:% solar/total": 57,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 205362,
    "out:% savings (space heating)": 63.84,
    "out:Total_Water Heating (DH)": 0,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.42,
    "out:Etvv": 0.68,
    "out:Ef": 10.5
  },
  {
    "ID": "Z00000000000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 67.14,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 89.2,
    "out:Total Energy Use Post PV": 89.2,
    "out:Primary Energy": 98.2,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.94,
    "out:CO2 Operational/m2": 29.33,
    "out:Total CO2/m2": 67.27,
    "out:Total CO2 (tons)": 216.62,
    "out:Klimatpaverkan": -2.21,
    "out:Initial Cost/MSEK": 6.654,
    "out:Running cost/(Apt SEK y)": 22982,
    "out:Running Cost over RSP/MSEK": 6.464,
    "out:LCP/MSEK": 1.407,
    "out:ROI% old": 17.31,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 89,
    "out:Energy use kWh/m2": 89,
    "out:Energy savings %": 118,
    "out:Op energy cost/MSEK": 47,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1030,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 350980,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 321363,
    "out:DH kr savings": 350980,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216188,
    "out:% savings (space heating)": 61.94,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.33,
    "out:Etvv": 25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000000000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.22,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 85.2,
    "out:Total Energy Use Post PV": 85.2,
    "out:Primary Energy": 94.77,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.94,
    "out:CO2 Operational/m2": 27.89,
    "out:Total CO2/m2": 65.83,
    "out:Total CO2 (tons)": 211.98,
    "out:Klimatpaverkan": -6.85,
    "out:Initial Cost/MSEK": 6.735,
    "out:Running cost/(Apt SEK y)": 21901,
    "out:Running Cost over RSP/MSEK": 6.16,
    "out:LCP/MSEK": 1.631,
    "out:ROI% old": 17.75,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1069,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 363860,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 334243,
    "out:DH kr savings": 363860,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203553,
    "out:% savings (space heating)": 64.16,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.4,
    "out:Etvv": 25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000000000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 67.14,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 87.2,
    "out:Total Energy Use Post PV": 87.2,
    "out:Primary Energy": 95.58,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.29,
    "out:CO2 Operational/m2": 28.61,
    "out:Total CO2/m2": 66.9,
    "out:Total CO2 (tons)": 215.43,
    "out:Klimatpaverkan": -3.4,
    "out:Initial Cost/MSEK": 6.791,
    "out:Running cost/(Apt SEK y)": 22441,
    "out:Running Cost over RSP/MSEK": 6.312,
    "out:LCP/MSEK": 1.423,
    "out:ROI% old": 17.28,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1050,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 357420,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327803,
    "out:DH kr savings": 357420,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216188,
    "out:% savings (space heating)": 61.94,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.33,
    "out:Etvv": 21.25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000000000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.22,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 83.2,
    "out:Total Energy Use Post PV": 83.2,
    "out:Primary Energy": 92.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.29,
    "out:CO2 Operational/m2": 27.17,
    "out:Total CO2/m2": 65.46,
    "out:Total CO2 (tons)": 210.79,
    "out:Klimatpaverkan": -8.04,
    "out:Initial Cost/MSEK": 6.871,
    "out:Running cost/(Apt SEK y)": 21361,
    "out:Running Cost over RSP/MSEK": 6.008,
    "out:LCP/MSEK": 1.647,
    "out:ROI% old": 17.71,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1089,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 340683,
    "out:DH kr savings": 370300,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203553,
    "out:% savings (space heating)": 64.16,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.4,
    "out:Etvv": 21.25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000000010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 67.14,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 89.2,
    "out:Total Energy Use Post PV": 85.39,
    "out:Primary Energy": 91.35,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.18,
    "out:CO2 Operational/m2": 10.46,
    "out:Total CO2/m2": 56.64,
    "out:Total CO2 (tons)": 182.39,
    "out:Klimatpaverkan": -36.44,
    "out:Initial Cost/MSEK": 7.101,
    "out:Running cost/(Apt SEK y)": 21812,
    "out:Running Cost over RSP/MSEK": 6.143,
    "out:LCP/MSEK": 1.282,
    "out:ROI% old": 16.86,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 85,
    "out:Energy use kWh/m2": 85,
    "out:Energy savings %": 128.2,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 77,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1072,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 350980,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 363483,
    "out:DH kr savings": 350980,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216188,
    "out:% savings (space heating)": 61.94,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.33,
    "out:Etvv": 25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000000010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.22,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 85.2,
    "out:Total Energy Use Post PV": 81.39,
    "out:Primary Energy": 87.92,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.18,
    "out:CO2 Operational/m2": 9.02,
    "out:Total CO2/m2": 55.2,
    "out:Total CO2 (tons)": 177.76,
    "out:Klimatpaverkan": -41.07,
    "out:Initial Cost/MSEK": 7.181,
    "out:Running cost/(Apt SEK y)": 20731,
    "out:Running Cost over RSP/MSEK": 5.839,
    "out:LCP/MSEK": 1.505,
    "out:ROI% old": 17.28,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 81,
    "out:Energy use kWh/m2": 81,
    "out:Energy savings %": 139.5,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 73,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1111,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 363860,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 376363,
    "out:DH kr savings": 363860,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203553,
    "out:% savings (space heating)": 64.16,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.4,
    "out:Etvv": 25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000000010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 67.14,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 87.2,
    "out:Total Energy Use Post PV": 83.39,
    "out:Primary Energy": 88.73,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.53,
    "out:CO2 Operational/m2": 9.74,
    "out:Total CO2/m2": 56.27,
    "out:Total CO2 (tons)": 181.2,
    "out:Klimatpaverkan": -37.63,
    "out:Initial Cost/MSEK": 7.237,
    "out:Running cost/(Apt SEK y)": 21271,
    "out:Running Cost over RSP/MSEK": 5.991,
    "out:LCP/MSEK": 1.297,
    "out:ROI% old": 16.85,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1092,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 357420,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369923,
    "out:DH kr savings": 357420,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216188,
    "out:% savings (space heating)": 61.94,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.33,
    "out:Etvv": 21.25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000000010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.22,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 83.2,
    "out:Total Energy Use Post PV": 79.39,
    "out:Primary Energy": 85.29,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.53,
    "out:CO2 Operational/m2": 8.3,
    "out:Total CO2/m2": 54.83,
    "out:Total CO2 (tons)": 176.57,
    "out:Klimatpaverkan": -42.26,
    "out:Initial Cost/MSEK": 7.318,
    "out:Running cost/(Apt SEK y)": 20191,
    "out:Running Cost over RSP/MSEK": 5.687,
    "out:LCP/MSEK": 1.521,
    "out:ROI% old": 17.25,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1131,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382803,
    "out:DH kr savings": 370300,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203553,
    "out:% savings (space heating)": 64.16,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.4,
    "out:Etvv": 21.25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000000100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 67.14,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 87.2,
    "out:Total Energy Use Post PV": 87.2,
    "out:Primary Energy": 94.7,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.94,
    "out:CO2 Operational/m2": 28.61,
    "out:Total CO2/m2": 66.55,
    "out:Total CO2 (tons)": 214.3,
    "out:Klimatpaverkan": -4.53,
    "out:Initial Cost/MSEK": 6.749,
    "out:Running cost/(Apt SEK y)": 22441,
    "out:Running Cost over RSP/MSEK": 6.312,
    "out:LCP/MSEK": 1.465,
    "out:ROI% old": 17.39,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 87,
    "out:Energy use kWh/m2": 87,
    "out:Energy savings %": 123,
    "out:Op energy cost/MSEK": 46,
    "out:El price/MSEK": 29,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1050,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 357420,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 327803,
    "out:DH kr savings": 357420,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216188,
    "out:% savings (space heating)": 61.94,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.33,
    "out:Etvv": 20,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000000100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.22,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 83.2,
    "out:Total Energy Use Post PV": 83.2,
    "out:Primary Energy": 91.27,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 37.94,
    "out:CO2 Operational/m2": 27.17,
    "out:Total CO2/m2": 65.11,
    "out:Total CO2 (tons)": 209.66,
    "out:Klimatpaverkan": -9.17,
    "out:Initial Cost/MSEK": 6.83,
    "out:Running cost/(Apt SEK y)": 21361,
    "out:Running Cost over RSP/MSEK": 6.008,
    "out:LCP/MSEK": 1.688,
    "out:ROI% old": 17.82,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1089,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 340683,
    "out:DH kr savings": 370300,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203553,
    "out:% savings (space heating)": 64.16,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.4,
    "out:Etvv": 20,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000000100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 67.14,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 86.2,
    "out:Total Energy Use Post PV": 86.2,
    "out:Primary Energy": 92.6,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.29,
    "out:CO2 Operational/m2": 28.25,
    "out:Total CO2/m2": 66.54,
    "out:Total CO2 (tons)": 214.27,
    "out:Klimatpaverkan": -4.56,
    "out:Initial Cost/MSEK": 6.886,
    "out:Running cost/(Apt SEK y)": 22171,
    "out:Running Cost over RSP/MSEK": 6.236,
    "out:LCP/MSEK": 1.404,
    "out:ROI% old": 17.2,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 86,
    "out:Energy use kWh/m2": 86,
    "out:Energy savings %": 125.6,
    "out:Op energy cost/MSEK": 45,
    "out:El price/MSEK": 28,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1060,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 360640,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 331023,
    "out:DH kr savings": 360640,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216188,
    "out:% savings (space heating)": 61.94,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.33,
    "out:Etvv": 17,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000000100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.22,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 82.2,
    "out:Total Energy Use Post PV": 82.2,
    "out:Primary Energy": 89.17,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 38.29,
    "out:CO2 Operational/m2": 26.81,
    "out:Total CO2/m2": 65.1,
    "out:Total CO2 (tons)": 209.63,
    "out:Klimatpaverkan": -9.2,
    "out:Initial Cost/MSEK": 6.966,
    "out:Running cost/(Apt SEK y)": 21091,
    "out:Running Cost over RSP/MSEK": 5.932,
    "out:LCP/MSEK": 1.628,
    "out:ROI% old": 17.62,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1098,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 343903,
    "out:DH kr savings": 373520,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203553,
    "out:% savings (space heating)": 64.16,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.4,
    "out:Etvv": 17,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000000110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 67.14,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 87.2,
    "out:Total Energy Use Post PV": 83.39,
    "out:Primary Energy": 87.85,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.18,
    "out:CO2 Operational/m2": 9.74,
    "out:Total CO2/m2": 55.92,
    "out:Total CO2 (tons)": 180.07,
    "out:Klimatpaverkan": -38.76,
    "out:Initial Cost/MSEK": 7.196,
    "out:Running cost/(Apt SEK y)": 21271,
    "out:Running Cost over RSP/MSEK": 5.991,
    "out:LCP/MSEK": 1.339,
    "out:ROI% old": 16.94,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 83,
    "out:Energy use kWh/m2": 83,
    "out:Energy savings %": 133.7,
    "out:Op energy cost/MSEK": 44,
    "out:El price/MSEK": 27,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 75,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1092,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 357420,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369923,
    "out:DH kr savings": 357420,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216188,
    "out:% savings (space heating)": 61.94,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.33,
    "out:Etvv": 20,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000000110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.22,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 83.2,
    "out:Total Energy Use Post PV": 79.39,
    "out:Primary Energy": 84.42,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.18,
    "out:CO2 Operational/m2": 8.3,
    "out:Total CO2/m2": 54.48,
    "out:Total CO2 (tons)": 175.44,
    "out:Klimatpaverkan": -43.39,
    "out:Initial Cost/MSEK": 7.276,
    "out:Running cost/(Apt SEK y)": 20191,
    "out:Running Cost over RSP/MSEK": 5.687,
    "out:LCP/MSEK": 1.562,
    "out:ROI% old": 17.35,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 79,
    "out:Energy use kWh/m2": 79,
    "out:Energy savings %": 145.6,
    "out:Op energy cost/MSEK": 42,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 71,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1131,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 370300,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 382803,
    "out:DH kr savings": 370300,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203553,
    "out:% savings (space heating)": 64.16,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.4,
    "out:Etvv": 20,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000000110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 67.14,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 86.2,
    "out:Total Energy Use Post PV": 82.39,
    "out:Primary Energy": 85.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.53,
    "out:CO2 Operational/m2": 9.38,
    "out:Total CO2/m2": 55.91,
    "out:Total CO2 (tons)": 180.04,
    "out:Klimatpaverkan": -38.79,
    "out:Initial Cost/MSEK": 7.332,
    "out:Running cost/(Apt SEK y)": 21001,
    "out:Running Cost over RSP/MSEK": 5.915,
    "out:LCP/MSEK": 1.278,
    "out:ROI% old": 16.78,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 82,
    "out:Energy use kWh/m2": 82,
    "out:Energy savings %": 136.6,
    "out:Op energy cost/MSEK": 43,
    "out:El price/MSEK": 26,
    "out:Heating price/MSEK": 15,
    "out:DH+ DHW/kWh/m2": 74,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1102,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 360640,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 373143,
    "out:DH kr savings": 360640,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 216188,
    "out:% savings (space heating)": 61.94,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 92.33,
    "out:Etvv": 17,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000000110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 0,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 63.22,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 82.2,
    "out:Total Energy Use Post PV": 78.39,
    "out:Primary Energy": 82.32,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 46.53,
    "out:CO2 Operational/m2": 7.94,
    "out:Total CO2/m2": 54.47,
    "out:Total CO2 (tons)": 175.41,
    "out:Klimatpaverkan": -43.42,
    "out:Initial Cost/MSEK": 7.413,
    "out:Running cost/(Apt SEK y)": 19921,
    "out:Running Cost over RSP/MSEK": 5.611,
    "out:LCP/MSEK": 1.502,
    "out:ROI% old": 17.18,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.59,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Existing",
    "out:Specific energy use kWh/m2": 78,
    "out:Energy use kWh/m2": 78,
    "out:Energy savings %": 148.7,
    "out:Op energy cost/MSEK": 41,
    "out:El price/MSEK": 25,
    "out:Heating price/MSEK": 14,
    "out:DH+ DHW/kWh/m2": 70,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 15,
    "out:Return/kSEK/y": 1141,
    "out:Windows(kr/m2)": 0,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 2.8,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 373520,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 386023,
    "out:DH kr savings": 373520,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 203553,
    "out:% savings (space heating)": 64.16,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 88.4,
    "out:Etvv": 17,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000100000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.15,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 74.2,
    "out:Total Energy Use Post PV": 74.2,
    "out:Primary Energy": 85.09,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.46,
    "out:CO2 Operational/m2": 23.93,
    "out:Total CO2/m2": 64.39,
    "out:Total CO2 (tons)": 207.34,
    "out:Klimatpaverkan": -11.49,
    "out:Initial Cost/MSEK": 7.262,
    "out:Running cost/(Apt SEK y)": 18930,
    "out:Running Cost over RSP/MSEK": 5.323,
    "out:LCP/MSEK": 1.941,
    "out:ROI% old": 18.1,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 74,
    "out:Energy use kWh/m2": 74,
    "out:Energy savings %": 162.2,
    "out:Op energy cost/MSEK": 39,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1176,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 399280,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 369663,
    "out:DH kr savings": 399280,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 167908,
    "out:% savings (space heating)": 70.44,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.33,
    "out:Etvv": 25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000100000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.9,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 71.2,
    "out:Total Energy Use Post PV": 71.2,
    "out:Primary Energy": 82.25,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.46,
    "out:CO2 Operational/m2": 22.85,
    "out:Total CO2/m2": 63.31,
    "out:Total CO2 (tons)": 203.86,
    "out:Klimatpaverkan": -14.97,
    "out:Initial Cost/MSEK": 7.342,
    "out:Running cost/(Apt SEK y)": 18119,
    "out:Running Cost over RSP/MSEK": 5.095,
    "out:LCP/MSEK": 2.089,
    "out:ROI% old": 18.35,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 173.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1205,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 408940,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 379323,
    "out:DH kr savings": 408940,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 157467,
    "out:% savings (space heating)": 72.27,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000100000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.15,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 72.2,
    "out:Total Energy Use Post PV": 72.2,
    "out:Primary Energy": 82.46,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.81,
    "out:CO2 Operational/m2": 23.21,
    "out:Total CO2/m2": 64.02,
    "out:Total CO2 (tons)": 206.15,
    "out:Klimatpaverkan": -12.68,
    "out:Initial Cost/MSEK": 7.398,
    "out:Running cost/(Apt SEK y)": 18390,
    "out:Running Cost over RSP/MSEK": 5.171,
    "out:LCP/MSEK": 1.957,
    "out:ROI% old": 18.06,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 169.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1196,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 405720,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 376103,
    "out:DH kr savings": 405720,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 167908,
    "out:% savings (space heating)": 70.44,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.33,
    "out:Etvv": 21.25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000100000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.9,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 69.2,
    "out:Total Energy Use Post PV": 69.2,
    "out:Primary Energy": 79.62,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.81,
    "out:CO2 Operational/m2": 22.13,
    "out:Total CO2/m2": 62.94,
    "out:Total CO2 (tons)": 202.67,
    "out:Klimatpaverkan": -16.16,
    "out:Initial Cost/MSEK": 7.479,
    "out:Running cost/(Apt SEK y)": 17579,
    "out:Running Cost over RSP/MSEK": 4.943,
    "out:LCP/MSEK": 2.104,
    "out:ROI% old": 18.31,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 181.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1225,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 415380,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385763,
    "out:DH kr savings": 415380,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 157467,
    "out:% savings (space heating)": 72.27,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 21.25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000100010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.15,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 74.2,
    "out:Total Energy Use Post PV": 70.39,
    "out:Primary Energy": 78.23,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.7,
    "out:CO2 Operational/m2": 5.06,
    "out:Total CO2/m2": 53.76,
    "out:Total CO2 (tons)": 173.11,
    "out:Klimatpaverkan": -45.72,
    "out:Initial Cost/MSEK": 7.708,
    "out:Running cost/(Apt SEK y)": 17760,
    "out:Running Cost over RSP/MSEK": 5.003,
    "out:LCP/MSEK": 1.815,
    "out:ROI% old": 17.65,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 177.1,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 62,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1218,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 399280,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 411783,
    "out:DH kr savings": 399280,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 167908,
    "out:% savings (space heating)": 70.44,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.33,
    "out:Etvv": 25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000100010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.9,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 71.2,
    "out:Total Energy Use Post PV": 67.39,
    "out:Primary Energy": 75.39,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 48.7,
    "out:CO2 Operational/m2": 3.98,
    "out:Total CO2/m2": 52.68,
    "out:Total CO2 (tons)": 169.64,
    "out:Klimatpaverkan": -49.19,
    "out:Initial Cost/MSEK": 7.789,
    "out:Running cost/(Apt SEK y)": 16949,
    "out:Running Cost over RSP/MSEK": 4.774,
    "out:LCP/MSEK": 1.963,
    "out:ROI% old": 17.89,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 189.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1248,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 408940,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 421443,
    "out:DH kr savings": 408940,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 157467,
    "out:% savings (space heating)": 72.27,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000100010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.15,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 72.2,
    "out:Total Energy Use Post PV": 68.39,
    "out:Primary Energy": 75.61,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 49.05,
    "out:CO2 Operational/m2": 4.34,
    "out:Total CO2/m2": 53.39,
    "out:Total CO2 (tons)": 171.92,
    "out:Klimatpaverkan": -46.91,
    "out:Initial Cost/MSEK": 7.845,
    "out:Running cost/(Apt SEK y)": 17220,
    "out:Running Cost over RSP/MSEK": 4.85,
    "out:LCP/MSEK": 1.831,
    "out:ROI% old": 17.62,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 185.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1238,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 405720,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 418223,
    "out:DH kr savings": 405720,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 167908,
    "out:% savings (space heating)": 70.44,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.33,
    "out:Etvv": 21.25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000100010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.9,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 69.2,
    "out:Total Energy Use Post PV": 65.39,
    "out:Primary Energy": 72.77,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.05,
    "out:CO2 Operational/m2": 3.26,
    "out:Total CO2/m2": 52.31,
    "out:Total CO2 (tons)": 168.45,
    "out:Klimatpaverkan": -50.38,
    "out:Initial Cost/MSEK": 7.925,
    "out:Running cost/(Apt SEK y)": 16409,
    "out:Running Cost over RSP/MSEK": 4.622,
    "out:LCP/MSEK": 1.978,
    "out:ROI% old": 17.85,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 198.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1267,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 415380,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 427883,
    "out:DH kr savings": 415380,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 157467,
    "out:% savings (space heating)": 72.27,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 21.25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000100100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.15,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 72.2,
    "out:Total Energy Use Post PV": 72.2,
    "out:Primary Energy": 81.59,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.46,
    "out:CO2 Operational/m2": 23.21,
    "out:Total CO2/m2": 63.67,
    "out:Total CO2 (tons)": 205.02,
    "out:Klimatpaverkan": -13.81,
    "out:Initial Cost/MSEK": 7.357,
    "out:Running cost/(Apt SEK y)": 18390,
    "out:Running Cost over RSP/MSEK": 5.171,
    "out:LCP/MSEK": 1.998,
    "out:ROI% old": 18.17,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 72,
    "out:Energy use kWh/m2": 72,
    "out:Energy savings %": 169.4,
    "out:Op energy cost/MSEK": 38,
    "out:El price/MSEK": 24,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1196,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 405720,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 376103,
    "out:DH kr savings": 405720,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 167908,
    "out:% savings (space heating)": 70.44,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.33,
    "out:Etvv": 20,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000100100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.9,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 69.2,
    "out:Total Energy Use Post PV": 69.2,
    "out:Primary Energy": 78.75,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.46,
    "out:CO2 Operational/m2": 22.13,
    "out:Total CO2/m2": 62.59,
    "out:Total CO2 (tons)": 201.54,
    "out:Klimatpaverkan": -17.29,
    "out:Initial Cost/MSEK": 7.437,
    "out:Running cost/(Apt SEK y)": 17579,
    "out:Running Cost over RSP/MSEK": 4.943,
    "out:LCP/MSEK": 2.146,
    "out:ROI% old": 18.41,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 181.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1225,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 415380,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 385763,
    "out:DH kr savings": 415380,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 157467,
    "out:% savings (space heating)": 72.27,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 20,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000100100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.15,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 71.2,
    "out:Total Energy Use Post PV": 71.2,
    "out:Primary Energy": 79.49,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.81,
    "out:CO2 Operational/m2": 22.85,
    "out:Total CO2/m2": 63.66,
    "out:Total CO2 (tons)": 204.99,
    "out:Klimatpaverkan": -13.84,
    "out:Initial Cost/MSEK": 7.493,
    "out:Running cost/(Apt SEK y)": 18119,
    "out:Running Cost over RSP/MSEK": 5.095,
    "out:LCP/MSEK": 1.938,
    "out:ROI% old": 17.98,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 71,
    "out:Energy use kWh/m2": 71,
    "out:Energy savings %": 173.2,
    "out:Op energy cost/MSEK": 37,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1205,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 408940,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 379323,
    "out:DH kr savings": 408940,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 167908,
    "out:% savings (space heating)": 70.44,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.33,
    "out:Etvv": 17,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000100100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.9,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 67.2,
    "out:Total Energy Use Post PV": 67.2,
    "out:Primary Energy": 76.65,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 40.81,
    "out:CO2 Operational/m2": 21.41,
    "out:Total CO2/m2": 62.22,
    "out:Total CO2 (tons)": 200.35,
    "out:Klimatpaverkan": -18.48,
    "out:Initial Cost/MSEK": 7.574,
    "out:Running cost/(Apt SEK y)": 17039,
    "out:Running Cost over RSP/MSEK": 4.791,
    "out:LCP/MSEK": 2.161,
    "out:ROI% old": 18.36,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 189.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1244,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 421820,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 392203,
    "out:DH kr savings": 421820,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 157467,
    "out:% savings (space heating)": 72.27,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 17,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000100110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.15,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 72.2,
    "out:Total Energy Use Post PV": 68.39,
    "out:Primary Energy": 74.73,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.7,
    "out:CO2 Operational/m2": 4.34,
    "out:Total CO2/m2": 53.04,
    "out:Total CO2 (tons)": 170.8,
    "out:Klimatpaverkan": -48.03,
    "out:Initial Cost/MSEK": 7.803,
    "out:Running cost/(Apt SEK y)": 17220,
    "out:Running Cost over RSP/MSEK": 4.85,
    "out:LCP/MSEK": 1.872,
    "out:ROI% old": 17.71,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 185.3,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 60,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1238,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 405720,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 418223,
    "out:DH kr savings": 405720,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 167908,
    "out:% savings (space heating)": 70.44,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.33,
    "out:Etvv": 20,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000100110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.9,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 69.2,
    "out:Total Energy Use Post PV": 65.39,
    "out:Primary Energy": 71.89,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 48.7,
    "out:CO2 Operational/m2": 3.26,
    "out:Total CO2/m2": 51.96,
    "out:Total CO2 (tons)": 167.32,
    "out:Klimatpaverkan": -51.51,
    "out:Initial Cost/MSEK": 7.884,
    "out:Running cost/(Apt SEK y)": 16409,
    "out:Running Cost over RSP/MSEK": 4.622,
    "out:LCP/MSEK": 2.02,
    "out:ROI% old": 17.95,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 198.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1267,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 415380,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 427883,
    "out:DH kr savings": 415380,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 157467,
    "out:% savings (space heating)": 72.27,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 20,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000100110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 52.15,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 71.2,
    "out:Total Energy Use Post PV": 67.39,
    "out:Primary Energy": 72.63,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.05,
    "out:CO2 Operational/m2": 3.98,
    "out:Total CO2/m2": 53.03,
    "out:Total CO2 (tons)": 170.76,
    "out:Klimatpaverkan": -48.07,
    "out:Initial Cost/MSEK": 7.94,
    "out:Running cost/(Apt SEK y)": 16949,
    "out:Running Cost over RSP/MSEK": 4.774,
    "out:LCP/MSEK": 1.812,
    "out:ROI% old": 17.55,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 189.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 12,
    "out:DH+ DHW/kWh/m2": 59,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1248,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 408940,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 421443,
    "out:DH kr savings": 408940,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 167908,
    "out:% savings (space heating)": 70.44,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 77.33,
    "out:Etvv": 17,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000100110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 1,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.9,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 67.2,
    "out:Total Energy Use Post PV": 63.39,
    "out:Primary Energy": 69.79,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 49.05,
    "out:CO2 Operational/m2": 2.54,
    "out:Total CO2/m2": 51.59,
    "out:Total CO2 (tons)": 166.13,
    "out:Klimatpaverkan": -52.7,
    "out:Initial Cost/MSEK": 8.02,
    "out:Running cost/(Apt SEK y)": 15869,
    "out:Running Cost over RSP/MSEK": 4.47,
    "out:LCP/MSEK": 2.036,
    "out:ROI% old": 17.91,
    "out:Payback discounted": 7,
    "out:Atemp": 3220,
    "out:Umean": 0.37,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "Storm",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 207.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 16,
    "out:Return/kSEK/y": 1286,
    "out:Windows(kr/m2)": 1855.01,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.7,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 421820,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 434323,
    "out:DH kr savings": 421820,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 157467,
    "out:% savings (space heating)": 72.27,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 74.09,
    "out:Etvv": 17,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000200000000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.62,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 70.2,
    "out:Total Energy Use Post PV": 70.2,
    "out:Primary Energy": 82,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.37,
    "out:CO2 Operational/m2": 22.49,
    "out:Total CO2/m2": 69.86,
    "out:Total CO2 (tons)": 224.94,
    "out:Klimatpaverkan": 6.11,
    "out:Initial Cost/MSEK": 8.748,
    "out:Running cost/(Apt SEK y)": 17849,
    "out:Running Cost over RSP/MSEK": 5.019,
    "out:LCP/MSEK": 0.759,
    "out:ROI% old": 15.52,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 70,
    "out:Energy use kWh/m2": 70,
    "out:Energy savings %": 177.1,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1215,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 412160,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 382543,
    "out:DH kr savings": 412160,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 156564,
    "out:% savings (space heating)": 72.43,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.81,
    "out:Etvv": 25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000200000000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.54,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 67.2,
    "out:Total Energy Use Post PV": 67.2,
    "out:Primary Energy": 79.31,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.37,
    "out:CO2 Operational/m2": 21.41,
    "out:Total CO2/m2": 68.78,
    "out:Total CO2 (tons)": 221.46,
    "out:Klimatpaverkan": 2.63,
    "out:Initial Cost/MSEK": 8.829,
    "out:Running cost/(Apt SEK y)": 17039,
    "out:Running Cost over RSP/MSEK": 4.791,
    "out:LCP/MSEK": 0.906,
    "out:ROI% old": 15.75,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 189.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1244,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 421820,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 392203,
    "out:DH kr savings": 421820,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 146639,
    "out:% savings (space heating)": 74.18,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.73,
    "out:Etvv": 25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000200000001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.62,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 69.2,
    "out:Total Energy Use Post PV": 69.2,
    "out:Primary Energy": 79.38,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.72,
    "out:CO2 Operational/m2": 22.13,
    "out:Total CO2/m2": 69.85,
    "out:Total CO2 (tons)": 224.91,
    "out:Klimatpaverkan": 6.08,
    "out:Initial Cost/MSEK": 8.885,
    "out:Running cost/(Apt SEK y)": 17579,
    "out:Running Cost over RSP/MSEK": 4.943,
    "out:LCP/MSEK": 0.698,
    "out:ROI% old": 15.41,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 69,
    "out:Energy use kWh/m2": 69,
    "out:Energy savings %": 181.2,
    "out:Op energy cost/MSEK": 36,
    "out:El price/MSEK": 23,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1225,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 415380,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 385763,
    "out:DH kr savings": 415380,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 156564,
    "out:% savings (space heating)": 72.43,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.81,
    "out:Etvv": 21.25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000200000001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.54,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 66.2,
    "out:Total Energy Use Post PV": 66.2,
    "out:Primary Energy": 76.68,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.72,
    "out:CO2 Operational/m2": 21.05,
    "out:Total CO2/m2": 68.77,
    "out:Total CO2 (tons)": 221.43,
    "out:Klimatpaverkan": 2.6,
    "out:Initial Cost/MSEK": 8.966,
    "out:Running cost/(Apt SEK y)": 16769,
    "out:Running Cost over RSP/MSEK": 4.715,
    "out:LCP/MSEK": 0.846,
    "out:ROI% old": 15.63,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 193.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1254,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 425040,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 395423,
    "out:DH kr savings": 425040,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 146639,
    "out:% savings (space heating)": 74.18,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.73,
    "out:Etvv": 21.25,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000200010000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.62,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 70.2,
    "out:Total Energy Use Post PV": 66.39,
    "out:Primary Energy": 75.15,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 55.6,
    "out:CO2 Operational/m2": 3.62,
    "out:Total CO2/m2": 59.23,
    "out:Total CO2 (tons)": 190.71,
    "out:Klimatpaverkan": -28.12,
    "out:Initial Cost/MSEK": 9.195,
    "out:Running cost/(Apt SEK y)": 16679,
    "out:Running Cost over RSP/MSEK": 4.698,
    "out:LCP/MSEK": 0.633,
    "out:ROI% old": 15.27,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 66,
    "out:Energy use kWh/m2": 66,
    "out:Energy savings %": 193.9,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 58,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1257,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 412160,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 424663,
    "out:DH kr savings": 412160,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 156564,
    "out:% savings (space heating)": 72.43,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.81,
    "out:Etvv": 25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000200010000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.54,
    "out:DHW (DH)": 9.6,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 67.2,
    "out:Total Energy Use Post PV": 63.39,
    "out:Primary Energy": 72.45,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 55.6,
    "out:CO2 Operational/m2": 2.54,
    "out:Total CO2/m2": 58.15,
    "out:Total CO2 (tons)": 187.24,
    "out:Klimatpaverkan": -31.59,
    "out:Initial Cost/MSEK": 9.275,
    "out:Running cost/(Apt SEK y)": 15869,
    "out:Running Cost over RSP/MSEK": 4.47,
    "out:LCP/MSEK": 0.78,
    "out:ROI% old": 15.49,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 207.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1286,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 9.6,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 421820,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 434323,
    "out:DH kr savings": 421820,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 146639,
    "out:% savings (space heating)": 74.18,
    "out:Total_Water Heating (DH)": 30912,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.73,
    "out:Etvv": 25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000200010001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.62,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 69.2,
    "out:Total Energy Use Post PV": 65.39,
    "out:Primary Energy": 72.52,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 55.95,
    "out:CO2 Operational/m2": 3.26,
    "out:Total CO2/m2": 59.22,
    "out:Total CO2 (tons)": 190.68,
    "out:Klimatpaverkan": -28.15,
    "out:Initial Cost/MSEK": 9.332,
    "out:Running cost/(Apt SEK y)": 16409,
    "out:Running Cost over RSP/MSEK": 4.622,
    "out:LCP/MSEK": 0.572,
    "out:ROI% old": 15.16,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 198.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 57,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1267,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 415380,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 427883,
    "out:DH kr savings": 415380,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 156564,
    "out:% savings (space heating)": 72.43,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.81,
    "out:Etvv": 21.25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000200010001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 0,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.54,
    "out:DHW (DH)": 8.16,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 66.2,
    "out:Total Energy Use Post PV": 62.39,
    "out:Primary Energy": 69.83,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 55.95,
    "out:CO2 Operational/m2": 2.18,
    "out:Total CO2/m2": 58.14,
    "out:Total CO2 (tons)": 187.21,
    "out:Klimatpaverkan": -31.62,
    "out:Initial Cost/MSEK": 9.412,
    "out:Running cost/(Apt SEK y)": 15599,
    "out:Running Cost over RSP/MSEK": 4.394,
    "out:LCP/MSEK": 0.72,
    "out:ROI% old": 15.38,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 62,
    "out:Energy use kWh/m2": 62,
    "out:Energy savings %": 212.9,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 54,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1296,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 8.16,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 425040,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 437543,
    "out:DH kr savings": 425040,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 146639,
    "out:% savings (space heating)": 74.18,
    "out:Total_Water Heating (DH)": 26275,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.73,
    "out:Etvv": 21.25,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000200100000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.62,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 68.2,
    "out:Total Energy Use Post PV": 68.2,
    "out:Primary Energy": 78.5,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.37,
    "out:CO2 Operational/m2": 21.77,
    "out:Total CO2/m2": 69.14,
    "out:Total CO2 (tons)": 222.62,
    "out:Klimatpaverkan": 3.79,
    "out:Initial Cost/MSEK": 8.843,
    "out:Running cost/(Apt SEK y)": 17309,
    "out:Running Cost over RSP/MSEK": 4.867,
    "out:LCP/MSEK": 0.816,
    "out:ROI% old": 15.6,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 68,
    "out:Energy use kWh/m2": 68,
    "out:Energy savings %": 185.3,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1235,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 418600,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 388983,
    "out:DH kr savings": 418600,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 156564,
    "out:% savings (space heating)": 72.43,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.81,
    "out:Etvv": 20,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000200100000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.54,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 65.2,
    "out:Total Energy Use Post PV": 65.2,
    "out:Primary Energy": 75.81,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.37,
    "out:CO2 Operational/m2": 20.69,
    "out:Total CO2/m2": 68.06,
    "out:Total CO2 (tons)": 219.15,
    "out:Klimatpaverkan": 0.32,
    "out:Initial Cost/MSEK": 8.924,
    "out:Running cost/(Apt SEK y)": 16499,
    "out:Running Cost over RSP/MSEK": 4.639,
    "out:LCP/MSEK": 0.963,
    "out:ROI% old": 15.83,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 65,
    "out:Energy use kWh/m2": 65,
    "out:Energy savings %": 198.5,
    "out:Op energy cost/MSEK": 34,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1264,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 428260,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 398643,
    "out:DH kr savings": 428260,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 146639,
    "out:% savings (space heating)": 74.18,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.73,
    "out:Etvv": 20,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000200100001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.62,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 67.2,
    "out:Total Energy Use Post PV": 67.2,
    "out:Primary Energy": 76.4,
    "out:Energy Class": "D",
    "out:CO2 Embodied/m2": 47.72,
    "out:CO2 Operational/m2": 21.41,
    "out:Total CO2/m2": 69.13,
    "out:Total CO2 (tons)": 222.59,
    "out:Klimatpaverkan": 3.76,
    "out:Initial Cost/MSEK": 8.98,
    "out:Running cost/(Apt SEK y)": 17039,
    "out:Running Cost over RSP/MSEK": 4.791,
    "out:LCP/MSEK": 0.755,
    "out:ROI% old": 15.49,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 67,
    "out:Energy use kWh/m2": 67,
    "out:Energy savings %": 189.6,
    "out:Op energy cost/MSEK": 35,
    "out:El price/MSEK": 22,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1244,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 421820,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 392203,
    "out:DH kr savings": 421820,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 156564,
    "out:% savings (space heating)": 72.43,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.81,
    "out:Etvv": 17,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000200100001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 0,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.54,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 12.2,
    "out:Total Energy Use Pre PV": 64.2,
    "out:Total Energy Use Post PV": 64.2,
    "out:Primary Energy": 73.71,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 47.72,
    "out:CO2 Operational/m2": 20.33,
    "out:Total CO2/m2": 68.05,
    "out:Total CO2 (tons)": 219.11,
    "out:Klimatpaverkan": 0.28,
    "out:Initial Cost/MSEK": 9.061,
    "out:Running cost/(Apt SEK y)": 16229,
    "out:Running Cost over RSP/MSEK": 4.563,
    "out:LCP/MSEK": 0.903,
    "out:ROI% old": 15.71,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 203.1,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 21,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 853.9,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1273,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 0,
    "out:PV (kWp installed)": 0,
    "out:PV (m2 panels)": 0,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 0,
    "out:PV (kWh sold)": 0,
    "out:PV (% sold (El))": 0,
    "out:PV (kWh self-consumed)": 0,
    "out:PV (ratio sold/self-consumed)": 0,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 4,
    "out:Payback Time": 25,
    "out:DH kWh savings": 431480,
    "out:EL kWh savings": -14809,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 401863,
    "out:DH kr savings": 431480,
    "out:El kr savings": -29618,
    "out:El kr sold": 0,
    "out:El kr saved": 0,
    "out:El kr return": 0,
    "out:% solar/total": 0,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 146639,
    "out:% savings (space heating)": 74.18,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.73,
    "out:Etvv": 17,
    "out:Ef": 12.2
  },
  {
    "ID": "Z00000200110000001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.62,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 68.2,
    "out:Total Energy Use Post PV": 64.39,
    "out:Primary Energy": 71.65,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 55.6,
    "out:CO2 Operational/m2": 2.9,
    "out:Total CO2/m2": 58.51,
    "out:Total CO2 (tons)": 188.4,
    "out:Klimatpaverkan": -30.43,
    "out:Initial Cost/MSEK": 9.29,
    "out:Running cost/(Apt SEK y)": 16139,
    "out:Running Cost over RSP/MSEK": 4.546,
    "out:LCP/MSEK": 0.69,
    "out:ROI% old": 15.35,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 64,
    "out:Energy use kWh/m2": 64,
    "out:Energy savings %": 203.1,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 56,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1277,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 418600,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 431103,
    "out:DH kr savings": 418600,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 156564,
    "out:% savings (space heating)": 72.43,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.81,
    "out:Etvv": 20,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000200110000101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 0,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.54,
    "out:DHW (DH)": 7.68,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 65.2,
    "out:Total Energy Use Post PV": 61.39,
    "out:Primary Energy": 68.95,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 55.6,
    "out:CO2 Operational/m2": 1.82,
    "out:Total CO2/m2": 57.43,
    "out:Total CO2 (tons)": 184.92,
    "out:Klimatpaverkan": -33.91,
    "out:Initial Cost/MSEK": 9.37,
    "out:Running cost/(Apt SEK y)": 15329,
    "out:Running Cost over RSP/MSEK": 4.318,
    "out:LCP/MSEK": 0.837,
    "out:ROI% old": 15.56,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 61,
    "out:Energy use kWh/m2": 61,
    "out:Energy savings %": 218,
    "out:Op energy cost/MSEK": 32,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 53,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1306,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 7.68,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 428260,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 0,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 440763,
    "out:DH kr savings": 428260,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 146639,
    "out:% savings (space heating)": 74.18,
    "out:Total_Water Heating (DH)": 24730,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.73,
    "out:Etvv": 20,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000200110001001",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 0,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 48.62,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 67.2,
    "out:Total Energy Use Post PV": 63.39,
    "out:Primary Energy": 69.55,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 55.95,
    "out:CO2 Operational/m2": 2.54,
    "out:Total CO2/m2": 58.5,
    "out:Total CO2 (tons)": 188.37,
    "out:Klimatpaverkan": -30.46,
    "out:Initial Cost/MSEK": 9.427,
    "out:Running cost/(Apt SEK y)": 15869,
    "out:Running Cost over RSP/MSEK": 4.47,
    "out:LCP/MSEK": 0.629,
    "out:ROI% old": 15.24,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 63,
    "out:Energy use kWh/m2": 63,
    "out:Energy savings %": 207.9,
    "out:Op energy cost/MSEK": 33,
    "out:El price/MSEK": 20,
    "out:Heating price/MSEK": 11,
    "out:DH+ DHW/kWh/m2": 55,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1286,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 421820,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 0,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 10,
    "out:Total besparing (kr)": 434323,
    "out:DH kr savings": 421820,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 156564,
    "out:% savings (space heating)": 72.43,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 73.81,
    "out:Etvv": 17,
    "out:Ef": 8.39
  },
  {
    "ID": "Z00000200110001101",
    "in:Roof_replacement": 0,
    "in:Facade_Insulation_Thickness": 0,
    "in:Facade_Insulation_Type": 0,
    "in:Roof_Insulation_Type": 0,
    "in:Roof_Insulation_Thickness": 0,
    "in:Window_Type": 2,
    "in:Basement  Insulation Thickness": 0,
    "in:EAHP": 0,
    "in:DHW individual metering": 1,
    "in:PV_System_Roof_Coverage": 1,
    "in:DH": 0,
    "in:Improve AHU": 0,
    "in:Balancing ventilation only": 0,
    "in:Sewage heat recovery": 1,
    "in:Injustering av varmesystem": 1,
    "in:FTX only": 0,
    "in:Facade Integrated FTX": 1,
    "out:Space Heating (DH)": 45.54,
    "out:DHW (DH)": 6.53,
    "out:Property Electricity": 12.2,
    "out:Electricity (inc PV)": 8.39,
    "out:Total Energy Use Pre PV": 64.2,
    "out:Total Energy Use Post PV": 60.39,
    "out:Primary Energy": 66.85,
    "out:Energy Class": "C",
    "out:CO2 Embodied/m2": 55.95,
    "out:CO2 Operational/m2": 1.46,
    "out:Total CO2/m2": 57.42,
    "out:Total CO2 (tons)": 184.89,
    "out:Klimatpaverkan": -33.94,
    "out:Initial Cost/MSEK": 9.507,
    "out:Running cost/(Apt SEK y)": 15059,
    "out:Running Cost over RSP/MSEK": 4.242,
    "out:LCP/MSEK": 0.777,
    "out:ROI% old": 15.45,
    "out:Payback discounted": 8,
    "out:Atemp": 3220,
    "out:Umean": 0.31,
    "out:Roof surface/m2": 537,
    "out:Facade surface/m2": 1558,
    "out:Window surface/m2": 327,
    "out:Ventilation system": "FTX",
    "out:Window type": "3glass",
    "out:Specific energy use kWh/m2": 60,
    "out:Energy use kWh/m2": 60,
    "out:Energy savings %": 223.3,
    "out:Op energy cost/MSEK": 31,
    "out:El price/MSEK": 19,
    "out:Heating price/MSEK": 10,
    "out:DH+ DHW/kWh/m2": 52,
    "out:El bought/kWh/m2": 593.2,
    "out:Return %": 14,
    "out:Return/kSEK/y": 1316,
    "out:Windows(kr/m2)": 6397.09,
    "out:Windows (U-value before)": 2.8,
    "out:Windows (U-value after)": 1.2,
    "out:EAHP (Heatpump)": 147900,
    "out:EAHP (1000ltanks)": 83936,
    "out:EAHP (pipework)": 83680,
    "out:EAHP (natural ventilation grills)": 171382,
    "out:EAHP (installation)": 7395,
    "out:EAHP (replacement cost)": 116393,
    "out:EAHP (SCOP of heatpump)": 3.3,
    "out:EAHP (DHW need)": 6.528,
    "out:EAHP (El)": 0,
    "out:EAHP (S to F improvement cost)": 0,
    "out:PV (kr/kWp)": 17461.56,
    "out:PV (kWp installed)": 25.572179,
    "out:PV (m2 panels)": 134,
    "out:PV (kWh/m2)": 178.83433,
    "out:PV (kWh produced)": 23993.62,
    "out:PV (kWh sold)": 11733.84,
    "out:PV (% sold (El))": 48.9,
    "out:PV (kWh self-consumed)": 12259.78,
    "out:PV (ratio sold/self-consumed)": 0.96,
    "out:FTX (AHU)": 310208,
    "out:FTX (Ductwork)": 1190000,
    "out:FTX (Diffusers)": 221914,
    "out:FTX (Dampers)": 65234,
    "out:FTX (Replacement Cost)": 153301,
    "out:FTX (kWh heat recovery efficiency)": 25.187962,
    "out:FTX (%heat recovery efficiency)": 75,
    "out:IMD (kr/apt)": 2639,
    "out:IMD (#apts)": 36,
    "out:IMD (% savings assumed)": 20,
    "out:IMD (kWh savings assumed)": 7.68,
    "out:DH (DH connection)": 218750,
    "out:DH (Radiators)": 444600,
    "out:DH (pipework)": 495000,
    "out:Facade U-value (before)": 1.93,
    "out:Facade U-value (after)": 0.18,
    "out:Roof U-value (before)": 2.39,
    "out:Roof U-value (after)": 0.09,
    "out:FTX (kr/m2 Facade)": 2733.15,
    "out:FTX (kr/m2 Roof)": 844.28,
    "out:FTX (Roof)": 362475.58,
    "out:FTX (Facade)": 4260000,
    "out:Takisolering (kr/m2)": 0,
    "out:Fasadisolering (kr/m2)": 0,
    "out:ROI %": 5,
    "out:Payback Time": 20,
    "out:DH kWh savings": 431480,
    "out:EL kWh savings": -2549,
    "out:Sewage heat recovery cost": 136608,
    "out:Balancing hydronic system cost": 80500.04,
    "out:Regulation losses_default": 10,
    "out:Regulation losses (%)": 5,
    "out:Total besparing (kr)": 443983,
    "out:DH kr savings": 431480,
    "out:El kr savings": -5098,
    "out:El kr sold": 17601,
    "out:El kr saved": 24520,
    "out:El kr return": 42120,
    "out:% solar/total": 50,
    "out:Total_El consumption (without PV)": 24472,
    "out:Total_Space Heating (DH)": 146639,
    "out:% savings (space heating)": 74.18,
    "out:Total_Water Heating (DH)": 21020,
    "out:Uppvarmning (kr/kWh)": 1,
    "out:El (kr/kWh)": 2,
    "out:Sold El (kr/kWh)": 1.5,
    "out:Sewage heat recovery (% savings assumed)": 15,
    "out:number of heat exchangers": 6,
    "out:kr/heat exchanger": 22768,
    "out:cost/m2 injustering": 25,
    "out:cost/m2 additional roof cost": 0,
    "out:raised roof area m2": 0,
    "out:cost_raised roof": 0,
    "out:Eupp": 70.73,
    "out:Etvv": 17,
    "out:Ef": 8.39
  }
]