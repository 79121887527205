import { type ReactElement, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { type Address, uniqueListOfAddresses } from "packages/buildings";
import { useParams } from "react-router-dom";
import { CompanyBuildings } from "packages/company-buldings/companyBuildings";
import { Company, CompanyId } from "packages/companies";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  BuildingData,
  type Dataset,
  type InputMap,
  useFieldValue,
  type Datum,
  type Value,
  filterExtractor,
} from "packages/dataAccess";
import { defaultInstallationFilters } from "packages/configurator";
import {
  type InstallationItem,
  getRawDataForListOfInstallations,
  InstallationItems,
} from "packages/installations";
import { EnergyClass } from "packages/energy-class";
import {
  type SelectChangeEvent,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { formatIntegers as Integer } from "packages/utils";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import { uniqueListOfCompanies } from "packages/companies";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { getComparator, stableSort } from "packages/table-sorting";
import { HeadCells, type TableCompanyAddressModel } from "./addressTable";

const energyClasses = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const AddressSelector = (): ReactElement => {
  const { company: companyId } = useParams<{ company: CompanyId }>();

  const company = useMemo(() => {
    const c = uniqueListOfCompanies.find(
      ([_company, _companyId]) => _companyId === companyId,
    );
    return c ? c[0] : "";
  }, [companyId]);

  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [targetEnergyClass, setTargetEnergyClass] = useState<
    string | undefined
  >();
  const [
    calculateLowCostByDefaultFilters,
    setCalculateLowCostByDefaultFilters,
  ] = useState(companyId === CompanyId[Company.CompanyC]);

  const createSortHandler = useCallback(
    (property: string) => {
      return () => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
      };
    },
    [orderBy, order],
  );

  const getInstallationFilters = useCallback((address: Address) => {
    return defaultInstallationFilters[address];
  }, []);

  const getEachInstallationFilters = useCallback(
    (
      address: Address,
      installation: InstallationItem,
    ): Record<InstallationItem, InputMap> => {
      const d = getInstallationFilters(address);
      const i = d[installation];
      return {
        [installation]: i,
      } as Record<InstallationItem, InputMap>;
    },
    [getInstallationFilters],
  );

  const getDataset = useCallback((address: Address) => {
    return BuildingData[address];
  }, []);

  const getSelectedInstallationDataset = useCallback(
    (
      dataset: Dataset,
      installations: InstallationItem[],
      filters: Record<string, InputMap>,
    ) => getRawDataForListOfInstallations(dataset, installations, filters),
    [],
  );

  const getFieldValue = useFieldValue();

  const addresses: TableCompanyAddressModel[] = useMemo(() => {
    return companyId !== undefined
      ? (uniqueListOfAddresses
          .filter((a) => CompanyBuildings[companyId].has(a))
          .map((address) => {
            const dataset = getDataset(address);
            const current = getSelectedInstallationDataset(
              dataset,
              [InstallationItems.nuläge],
              getEachInstallationFilters(address, InstallationItems.nuläge),
            )[0][1];
            const uniqueEnergyClasses = getFieldValue(
              current,
              "out:Energy Class",
            );
            const atmosphere = Number(getFieldValue(current, "out:Atemp"));
            const specificEnergy = Number(
              getFieldValue(current, "out:Specific energy use kWh/m2"),
            );

            let targetClassReturns: Value = 0;
            let targetClassInvestment: Value = 0;

            if (targetEnergyClass) {
              const datasetForTargetClass = dataset
                .filter(
                  (item) =>
                    getFieldValue(item, "out:Energy Class") <=
                    targetEnergyClass,
                )
                .sort((a, b) =>
                  getFieldValue(a, "out:Initial Cost/MSEK") <
                  getFieldValue(b, "out:Initial Cost/MSEK")
                    ? -1
                    : 1,
                );

              let currentDatum: Datum | undefined = undefined;
              if (targetEnergyClass < uniqueEnergyClasses) {
                if (calculateLowCostByDefaultFilters) {
                  const installationFilters = getInstallationFilters(address);

                  // biome-ignore lint/complexity/noForEach: <explanation>
                  datasetForTargetClass.forEach((datum: Datum) => {
                    if (!currentDatum) {
                      const condition = Array.from(filterExtractor([datum]))
                        .map((x) => {
                          return [x[0], Array.from(x[1])[0]];
                        })
                        .filter((x) => x[1] !== 0)
                        .every(([fieldName, value]) => {
                          return Object.keys(installationFilters)
                            .flatMap((key) =>
                              Array.from(
                                installationFilters[key as InstallationItem],
                              ),
                            )
                            .filter((x) => x[1] !== 0)
                            .some((x) => x[0] === fieldName && x[1] === value);
                        });

                      if (condition) {
                        currentDatum = datum;
                      }
                    }
                  });
                } else {
                  currentDatum = datasetForTargetClass[0];
                }
              }

              targetClassReturns = currentDatum
                ? getFieldValue(currentDatum, "out:ROI %")
                : 0;
              targetClassInvestment = currentDatum
                ? getFieldValue(currentDatum, "out:Initial Cost/MSEK")
                : 0;
            }

            return {
              name: address,
              uniqueEnergyClass: uniqueEnergyClasses,
              totalConsumption: atmosphere * specificEnergy,
              atmosphere,
              specificEnergy,
              targetClassReturns,
              targetClassInvestment,
            };
          })
          .filter((x) => x) as TableCompanyAddressModel[])
      : [];
  }, [
    companyId,
    getFieldValue,
    getSelectedInstallationDataset,
    getInstallationFilters,
    getEachInstallationFilters,
    getDataset,
    targetEnergyClass,
    calculateLowCostByDefaultFilters,
  ]);

  const sortedAddresses = useMemo(() => {
    return stableSort(addresses, getComparator(order, orderBy));
  }, [order, orderBy, addresses]);

  const onChangeTargetEnergyClass = useCallback(
    (e: SelectChangeEvent<string>) => {
      setTargetEnergyClass(e.target.value);
    },
    [],
  );

  const energyClassByMaxCurrentEnergyClass = useMemo(() => {
    const max = sortedAddresses
      .map((x) => x.uniqueEnergyClass)
      .sort((a, b) => (a > b ? -1 : 1))[0];
    return energyClasses.filter((x) => x <= max);
  }, [sortedAddresses]);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "30px",
            minHeight: "117.02px",
          }}
        >
          <div style={{ marginRight: "15px" }}>
            <Typography variant="h5" fontWeight={500}>
              {company}
            </Typography>
          </div>
        </div>

        <Divider />
      </div>

      <div
        style={{
          maxHeight: "calc(100vh - 160px)",
          display: "flex",
          padding: "30px",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            overflow: "auto",
            width: "60%",
          }}
        >
          <div
            style={{
              paddingTop: 5,
              marginBottom: 10,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              gap: 10,
            }}
          >
            <Typography fontSize={13} color="GrayText">
              *Välj energiklass för att beräkna Avkastning och Investering
            </Typography>

            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  style={{ padding: "2px" }}
                  checked={calculateLowCostByDefaultFilters}
                  onChange={(_e, checked) =>
                    setCalculateLowCostByDefaultFilters(checked)
                  }
                />
              }
              style={{ color: "GrayText", display: "none" }}
              label={
                <span style={{ fontSize: "13px" }}>
                  Beräkna med standardfilter
                </span>
              }
              labelPlacement="bottom"
            />

            <FormControl sx={{ width: 250, padding: 0 }} size="small">
              <InputLabel id="demo-simple-select-label">
                Mål Energiklass
              </InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={targetEnergyClass}
                label="Mål Energiklass"
                onChange={onChangeTargetEnergyClass}
              >
                {energyClassByMaxCurrentEnergyClass.map((energyClass) => (
                  <MenuItem value={energyClass}>{energyClass}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <TableContainer
            component={Paper}
            style={{ boxShadow: "none", border: "1px solid #e0e0e0" }}
          >
            <Table>
              <TableHead>
                <TableRow style={{ fontSize: "1rem" }}>
                  {HeadCells.map((headCell, index) => (
                    <TableCell
                      key={headCell.id}
                      align={index === 0 ? "left" : "center"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      style={{
                        fontSize: "1rem",
                        background: "#f4f5fa",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        // direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                        hideSortIcon={true}
                        IconComponent={() => (
                          <div
                            style={{
                              position: "absolute",
                              right: "-25px",
                              top: "0",
                            }}
                          >
                            {orderBy === headCell.id ? (
                              order === "desc" ? (
                                <ArrowDownwardIcon
                                  style={{ fontSize: "1.2rem" }}
                                />
                              ) : (
                                <ArrowUpwardIcon
                                  style={{ fontSize: "1.2rem" }}
                                />
                              )
                            ) : null}
                          </div>
                        )}
                      >
                        {headCell.label || headCell.component}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAddresses.map((address) => (
                  <TableRow
                    key={address.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      style={{ fontSize: "1rem" }}
                    >
                      <Link to={`/${companyId}/${address.name}`}>
                        {address.name}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "1rem",
                      }}
                    >
                      <EnergyClass
                        size="small"
                        energyClasses={[address.uniqueEnergyClass as string]}
                      />
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "1rem" }}>
                      {Integer(address.totalConsumption)}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "1rem" }}>
                      {Integer(address.atmosphere)}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "1rem" }}>
                      {Integer(address.specificEnergy)}
                    </TableCell>

                    <TableCell align="center" style={{ fontSize: "1rem" }}>
                      {address.targetClassReturns
                        ? `${Integer(address.targetClassReturns)}%`
                        : targetEnergyClass
                          ? "N/A"
                          : ""}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "1rem" }}>
                      {address.targetClassInvestment ? (
                        Integer(address.targetClassInvestment)
                      ) : targetEnergyClass ? (
                        "N/A"
                      ) : (
                        <span
                          style={{
                            fontSize: "13px",
                            color: "GrayText",
                          }}
                        >
                          Välj energiklass
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
