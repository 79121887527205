import { Typography } from "@mui/material";
import { Arrow } from "packages/ui";
import { Fragment, type ReactElement, useMemo } from "react";
import { EnergyClassColorMap } from ".";

export interface EnergyClassProps {
  energyClasses: string[];
  size?: "small" | "normal";
}

export const EnergyClass = ({
  energyClasses,
  size = "normal",
}: EnergyClassProps): ReactElement => {
  const sizeStyle = useMemo(() => {
    return size === "normal"
      ? {
          width: "50px",
          height: "50px",
          fontSize: "x-large",
        }
      : {
          width: "30px",
          height: "30px",
          fontSize: "medium",
        };
  }, [size]);

  return (
    <>
      {energyClasses.map((e, i, all) => (
        <Fragment key={e}>
          <Typography
            style={{
              borderRadius: "2px",
              display: "flex",
              textAlign: "center",
              background: `rgb(${EnergyClassColorMap.get(e)})`,
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
              ...sizeStyle,
            }}
            key={e}
          >
            {e}
          </Typography>
          <span style={{ color: "#dedede", display: "flex", padding: "5px" }}>
            {i < all.length - 1 ? <Arrow /> : ""}
          </span>
        </Fragment>
      ))}
    </>
  );
};
