import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useSetAtom } from "jotai";
import {
  type Address,
  removeInstallationItemsWithNonEffectiveFilters,
} from "packages/buildings";
import {
  InstallationConfigurator,
  defaultInstallationFilters,
} from "packages/configurator";
import { BuildingData, datasetAtom } from "packages/dataAccess";
import {
  EnergyConsumptionChart,
  InstallationBreakdownTable,
} from "packages/installation-details";
import {
  type InstallationItem,
  getRawDataForListOfInstallations,
  installationItems,
} from "packages/installations";
import { type ReactElement, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "./Header";
import { getAvailabilityForDefaultFilter } from "packages/configurator/installationItemIncompatibilityMap";

export const BuildingDashboard = (): ReactElement => {
  const { address } = useParams<{ address: Address }>();

  if (address === undefined) {
    throw new Error("Address is undefined");
  }

  const [installationFilters, setInstallationFilters] = useState<
    (typeof defaultInstallationFilters)[Address]
  >(defaultInstallationFilters[address]);

  const [selectedInstallations, setSelectedInstallations] = useState<
    InstallationItem[]
  >([
    ...installationItems.filter((item) => {
      return (
        getAvailabilityForDefaultFilter(item) &&
        removeInstallationItemsWithNonEffectiveFilters(
          item,
          installationFilters,
        )
      );
    }),
  ]);

  const dataset = useMemo(() => BuildingData[address], [address]);

  const setDataset = useSetAtom(datasetAtom);
  useEffect(() => {
    setDataset(dataset);
  }, [dataset, setDataset]);

  const selectedInstallationDataset = useMemo(() => {
    const v = getRawDataForListOfInstallations(
      dataset,
      selectedInstallations,
      installationFilters,
    );
    return v;
  }, [dataset, installationFilters, selectedInstallations]);

  return (
    <div>
      <div style={{ height: "100vh", overflow: "hidden" }}>
        <div style={{ height: "100%" }}>
          <div>
            <Header
              address={address}
              selectedInstallationDataset={selectedInstallationDataset}
            />
            <Divider />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "15px",
              height: "calc(100% - 160px)",
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <Typography
                marginTop={2}
                variant="h6"
                style={{ textAlign: "center" }}
              >
                Renoveringsalternativ
              </Typography>
              <div
                style={{ overflow: "auto", height: "100%", direction: "rtl" }}
              >
                <div style={{ direction: "ltr" }}>
                  <InstallationConfigurator
                    selectedInstallations={selectedInstallations}
                    installationFilters={installationFilters}
                    dataset={dataset}
                    onSelectedInstallationChange={setSelectedInstallations}
                    onInstallationFiltersChange={setInstallationFilters}
                  />
                </div>
              </div>
            </div>
            <div style={{ flex: 3, overflow: "auto" }}>
              <Typography
                marginTop={2}
                variant="h6"
                style={{ textAlign: "center" }}
              >
                Specifik energi{" "}
                <span style={{ fontWeight: "normal" }}>
                  (kWh/m<sup style={{ fontSize: 14 }}>2</sup>
                  <sub style={{ fontSize: 14 }}>Atemp</sub>)
                </span>
              </Typography>

              <div>
                <EnergyConsumptionChart
                  selectedInstallationDataset={selectedInstallationDataset}
                  selectedInstallations={selectedInstallations}
                />
                <InstallationBreakdownTable
                  rawDataset={selectedInstallationDataset}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
