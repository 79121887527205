import {
  type Dataset,
  type InputMap,
  filterExtractor,
  find,
} from "packages/dataAccess";
import { type ReactElement, useCallback, useMemo } from "react";
import type { InstallationItem } from "../installations";
import { installationItems } from "../installations";
import { Installation } from "./Installation";
import { installationItemIncompatibilityMap } from "./installationItemIncompatibilityMap";

const addSelectedItemAndRemoveIncompatibleItems = (
  installation: InstallationItem,
  selectedInstallations: InstallationItem[],
): InstallationItem[] => {
  const incompatibleItems =
    installationItemIncompatibilityMap.get(installation);

  return incompatibleItems === undefined
    ? [...selectedInstallations, installation]
    : [
        ...selectedInstallations.filter(
          (eachInstallation) => !incompatibleItems.has(eachInstallation),
        ),
        installation,
      ];
};

export interface InstallationConfiguratorProps {
  installationFilters: Record<InstallationItem, InputMap>;
  dataset: Dataset;
  selectedInstallations: InstallationItem[];
  onSelectedInstallationChange: (
    selectedInstallations: InstallationItem[],
  ) => void;
  onInstallationFiltersChange: (filters: Record<string, InputMap>) => void;
}

export const InstallationConfigurator = ({
  installationFilters,
  dataset,
  selectedInstallations,
  onSelectedInstallationChange,
  onInstallationFiltersChange,
}: InstallationConfiguratorProps): ReactElement => {
  const possibleFilters = useMemo(() => filterExtractor(dataset), [dataset]);

  const installationDataset = useMemo(
    () =>
      installationItems.map((label) =>
        find({ dataset, inputMap: installationFilters[label] }),
      ),
    [dataset, installationFilters],
  );

  const handleInstallationChange = useCallback(
    (updatedInstallationFilter: Record<string, InputMap>) => {
      onInstallationFiltersChange({
        ...installationFilters,
        ...updatedInstallationFilter,
      });
    },
    [installationFilters, onInstallationFiltersChange],
  );

  return (
    <>
      {Object.entries(installationFilters).map(
        ([installation, filterMap], index) => {
          const installationData = installationDataset[index];
          const installationItem = installation as InstallationItem;

          if (
            installation !== "Nuläge" &&
            Array.from(filterMap.entries()).every(
              ([_filterName, filterValue]) => filterValue === 0,
            )
          ) {
            return null;
          }

          return (
            <Installation
              key={installation}
              installationName={installationItem}
              selected={selectedInstallations.includes(installationItem)}
              onToggle={(selected) => {
                onSelectedInstallationChange(
                  selected
                    ? addSelectedItemAndRemoveIncompatibleItems(
                        installationItem,
                        selectedInstallations,
                      )
                    : selectedInstallations.filter((l) => l !== installation),
                );
              }}
              installationData={installationData}
              filterMap={filterMap}
              possibleFilters={possibleFilters}
              onChange={handleInstallationChange}
            />
          );
        },
      )}
    </>
  );
};
