import type { DatabaseOverrides, Datum, OverriddenValue } from "../types";
import { calculateOverriddenDatumForFields } from "./calculateOverriddenDatumForFields";
import { calculators } from "./calculators";

interface GetFieldDataParameters {
  updatedValues: DatabaseOverrides;
  originalValues: Datum;
  fieldName: string;
}

export const getFieldData = ({
  updatedValues,
  originalValues,
  fieldName,
}: GetFieldDataParameters): OverriddenValue => {
  const dataOverride = updatedValues
    .get(String(originalValues.ID))
    ?.get(fieldName);
  const originalData = originalValues[fieldName];

  const [fields, calculator] = calculators.get(fieldName) ?? [];

  if (fields && calculator) {
    const overriddenValues = calculateOverriddenDatumForFields({
      fields,
      updatedValues,
      originalValues,
    });

    const calculatedValue = calculator({
      currentValue: dataOverride ?? originalData,
      updatedValues: overriddenValues ?? originalValues,
      originalValues,
    });

    return [calculatedValue ?? dataOverride ?? originalData];
  }

  return [originalData, dataOverride];
};
