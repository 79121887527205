import type { Datum, QueryParameters } from "../types";
import { query } from "./query";

export const findAndDefault = ({
  dataset,
  inputMap,
}: QueryParameters): Datum | undefined => {
  const datum = query({ dataset, inputMap }).find((datum) =>
    Object.keys(datum)
      .filter((key) => key.startsWith("in:") && !inputMap.has(key))
      .every((key) => datum[key] === 0),
  );

  return datum;
};

export const find = ({ dataset, inputMap }: QueryParameters): Datum => {
  const datum = findAndDefault({ dataset, inputMap });

  if (datum === undefined) {
    throw new RangeError(
      `Specific data entry cannot be found with ${JSON.stringify(
        Array.from(inputMap),
      )}`,
    );
  }

  return datum;
};
