import { AddressSelector } from "apps/address-selector";
import { BuildingDashboard } from "apps/building-dashboard";
import { CompanySelector } from "apps/company-selector";
import type { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import "./style.css";

export const App = (): ReactElement => {
  return (
    <Routes>
      <Route
        path={
          process.env.NODE_ENV === "development" ? "/" : "all-the-companies"
        }
        element={<CompanySelector />}
      />

      <Route path={"/:company"} element={<AddressSelector />} />

      <Route path={"/:company/:address"} element={<BuildingDashboard />} />
    </Routes>
  );
};
