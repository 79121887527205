import type { DatabaseOverrides, Datum } from "../types";
import { getFieldData } from "./getFieldData";

export const readFieldValue = (
  updatedValues: DatabaseOverrides,
  originalValues: Datum,
  fieldName: string,
) => {
  const [originalData, dataOverride] = getFieldData({
    updatedValues,
    originalValues,
    fieldName,
  });
  return dataOverride ?? originalData;
};
